var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Paginate", {
    attrs: {
      value: _vm.page,
      "page-count": _vm.total,
      "page-range": 3,
      "margin-pages": 2,
      "click-handler": _vm.paginateHandle,
      "prev-text": "←",
      "next-text": "→",
      "container-class": "pagination",
      "page-class": "page-item"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }