var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      attrs: {
        title: _vm.header,
        id: _vm.id,
        action: "Submit",
        loading: _vm.loading,
        disableAction: _vm.insufficientBalanceForRefund
      },
      on: { cta: _vm.refundOrder, open: _vm.recordAnalytics }
    },
    [
      _vm.insufficientBalanceForRefund
        ? _c("div", { staticClass: "d-flex flex-column gap-15px" }, [
            _c(
              "div",
              {
                staticClass:
                  "w-100 d-flex text-light justify-content-between align-items-center"
              },
              [
                _c("span", { staticClass: "para-2 text-light" }, [
                  _vm._v(
                    "Uh oh! It looks like your account balance is too low to process this refund."
                  )
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-column gap-15px refund-modal-body" },
        [
          _c("div", { staticClass: "d-flex flex-column gap-20px w-100" }, [
            _c("h3", [
              _vm._v("Maximum Refund Amount: " + _vm._s(_vm.max_amount))
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "label",
                { staticClass: "control-label w-100" },
                [
                  _c("span", { staticClass: "mr-1" }, [
                    _vm._v("Partial Refund Amount")
                  ]),
                  _vm._v(" "),
                  _c("AppInput", {
                    attrs: {
                      "input-class": "mb-1",
                      type: "number",
                      hasPrecision: ""
                    },
                    model: {
                      value: _vm.partialRefundAmount,
                      callback: function($$v) {
                        _vm.partialRefundAmount = _vm._n($$v)
                      },
                      expression: "partialRefundAmount"
                    }
                  }),
                  _vm._v(" "),
                  _c("small", { staticClass: "stan-text-danger error" }, [
                    _vm._v(" " + _vm._s(_vm.price_validation_error_message))
                  ])
                ],
                1
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }