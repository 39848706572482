import { render, staticRenderFns } from "./EmptyStorePrompt.vue?vue&type=template&id=3bf744ae&scoped=true"
import script from "./EmptyStorePrompt.vue?vue&type=script&lang=js"
export * from "./EmptyStorePrompt.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bf744ae",
  null
  
)

export default component.exports