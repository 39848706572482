var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "app-floating-button square",
      class: {
        "show-border": _vm.showBorder,
        "show-background": _vm.showBackground
      },
      attrs: { disabled: _vm.disabled },
      on: { click: _vm.handleClick }
    },
    [
      _c("div", { staticClass: "button-content" }, [
        _vm.icon
          ? _c(
              "div",
              { staticClass: "button-icon" },
              [
                _c("InlineSvg", {
                  staticClass: "icon-svg",
                  attrs: { src: _vm.icon }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }