var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("StanleyTyping", { attrs: { text: _vm.text } }, [
        _c(
          "div",
          { staticClass: "d-flex flex-wrap" },
          [
            _c(
              "AppButton",
              {
                staticClass: "mr-2 mb-2",
                attrs: {
                  name: "empty-store-prompt-create-lead-magnet-button",
                  outline: ""
                }
              },
              [_vm._v("Create Lead Magnet")]
            ),
            _vm._v(" "),
            _c(
              "AppButton",
              {
                staticClass: "mr-2 mb-2",
                attrs: {
                  name: "empty-store-prompt-explore-product-types-button",
                  outline: ""
                }
              },
              [_vm._v("Explore Product Types")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }