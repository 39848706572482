var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "animated-check",
      style: { height: this.size, width: this.size },
      attrs: { viewBox: "0 0 24 24" }
    },
    [_c("path", { attrs: { d: "M4.1 12.7L9 17.6 20.3 6.3", fill: "none" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }