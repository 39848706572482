var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ContentPlaceholders",
    { attrs: { rounded: _vm.rounded, centered: true } },
    [
      _vm.heading ? _c("ContentPlaceholdersHeading") : _vm._e(),
      _vm._v(" "),
      _c("ContentPlaceholdersImg", { staticClass: "calendar-skeleton-image" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }