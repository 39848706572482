import { mapActions } from 'vuex'

export default {
	data() {
		return {
			primaryColor: '#5383ff',
			secondaryColor: '#ffffff',
			primaryFont: 'Inter',
			activeTheme: null,
			themes: [
				{
					name: 'Stan Classic',
					key: 'default',
					src: '/images/themes/theme-default-preview.svg',
					fontFamily: 'Plus Jakarta Sans',
					fontWeight: 400,
				},
				{
					name: 'Moderno',
					key: 'moderno',
					src: '/images/themes/theme-moderno-preview.svg',
					fontFamily: 'Poppins',
					fontWeight: 500,
				},
				{
					name: 'The Kels',
					key: 'kels',
					src: '/images/themes/theme-kels-preview.svg',
					fontFamily: 'argent-cf',
					fontWeight: 450,
				},
				{
					name: 'The Tyla',
					key: 'tyla',
					src: '/images/themes/theme-tyla-preview.svg',
					fontFamily: 'Noto Serif Display',
					fontWeight: 700,
				},
				{
					name: 'The Stone',
					key: 'stone',
					src: '/images/themes/theme-stone-preview.svg',
					fontFamily: 'Plus Jakarta Sans',
					fontWeight: 700,
				},
				{
					name: 'Minima',
					key: 'minima',
					src: '/images/themes/theme-minima-preview.svg',
					fontFamily: 'Inter',
					fontWeight: 600,
				},
				{
					name: 'Nightview',
					key: 'nightview',
					src: '/images/themes/theme-nightview-preview.svg',
					fontFamily: 'Plus Jakarta Sans',
					fontWeight: 700,
				},
				{
					name: 'Material',
					key: 'material',
					src: '/images/themes/theme-material-preview.svg',
					fontFamily: 'Plus Jakarta Sans',
					fontWeight: 700,
				},
				{
					name: 'Spotlight',
					key: 'spotlight',
					src: '/images/themes/theme-spotlight-preview.svg',
					fontFamily: 'Fraunces',
					fontWeight: 700,
				},
				{
					name: 'Eclipse',
					key: 'eclipse',
					src: '/images/themes/theme-eclipse-preview.svg',
					fontFamily: 'Urbanist',
					fontWeight: 900,
				},
				{
					name: 'Coach Trish',
					key: 'trish',
					src: '/images/themes/theme-trish-preview.svg',
					fontFamily: 'Libre Baskerville',
					fontWeight: 900,
				},
			],
			testThemes: [],
			themeDefaults: {
				default: {
					primaryColor: '#01685f',
					primaryColorSelection: ['#01685f', '#eaa59c', '#00685f', '#d98800', '#9a9593', '#8a85d3'],
					secondaryColor: '#FFFFFF',
					secondaryColorSelection: ['#FFFFFF'],
					primaryFont: 'Plus Jakarta Sans',
				},
				moderno: {
					primaryColor: '#5383ff',
					primaryColorSelection: ['#5383ff', '#eaa59c', '#00685f', '#d98800', '#9a9593', '#8a85d3'],
					secondaryColor: '#FFFFFF',
					secondaryColorSelection: ['#FFFFFF'],
					primaryFont: 'Poppins',
				},
				kels: {
					primaryColor: '#FADCF0',
					primaryColorSelection: ['#FADCF0', '#E4EFFF', '#FAF0DC', '#5383ff', '#eaa59c', '#00685f'],
					secondaryColor: '#DCE6FA',
					secondaryColorSelection: ['#DCE6FA', '#E6FFE4', '#FADCFA', '#53FF5C', '#EA9CEA', '#4E6800'],
					primaryFont: 'argent-cf',
				},
				tyla: {
					primaryColor: '#927A62',
					primaryColorSelection: ['#927A62', '#3193C4', '#AB49AB', '#5383ff', '#eaa59c', '#00685f'],
					secondaryColor: '#FDFAF7',
					secondaryColorSelection: ['#FDFAF7', '#F5FCFF', '#FEF6FE', '#F5F8FF', '#FFF6F5', '#F5FFFE'],
					primaryFont: 'Noto Serif Display',
				},
				stone: {
					primaryColor: '#FFFFFF',
					primaryColorSelection: [],
					secondaryColor: '#000000',
					secondaryColorSelection: ['#000000', '#5C0A0A', '#0A5C41', '#250D79', '#876733'],
					primaryFont: 'Plus Jakarta Sans',
				},
				minima: {
					primaryColor: '#0037c4',
					primaryColorSelection: ['#0037c4', '#FF7733', '#00CC22', '#5383ff', '#eaa59c', '#00685f'],
					secondaryColor: '#FFFFFF',
					secondaryColorSelection: ['#FFFFFF'],
					primaryFont: 'Inter',
				},
				nightview: {
					primaryColor: '#00ffd1',
					primaryColorSelection: ['#00ffd1', '#FF3399', '#33FF55', '#5383ff', '#eaa59c', '#00685f'],
					secondaryColor: '#13102e',
					secondaryColorSelection: ['#13102e', '#5C0A0A', '#0A5C41', '#250D79', '#876733', '#000000'],
					primaryFont: 'Plus Jakarta Sans',
				},
				material: {
					primaryColor: '#FF66B2',
					primaryColorSelection: ['#FF66B2', '#6680FF', '#0BDB95', '#5383ff', '#eaa59c', '#00685f'],
					secondaryColor: '#FFEBFF',
					secondaryColorSelection: ['#FFEBFF', '#EBF8FF', '#ECFEEF', '#EBFBFF', '#FBEEF3', '#EBFFF3'],
					primaryFont: 'Plus Jakarta Sans',
				},
				spotlight: {
					primaryColor: '#5943ef',
					primaryColorSelection: ['#5943ef', '#6688FF', '#FF66CC', '#5383ff', '#eaa59c', '#00685f'],
					secondaryColor: '#FFF8EB',
					secondaryColorSelection: ['#FFF8EB', '#EBEFFF', '#FFEBF8', '#EBF0FF', '#FBF0EE', '#EBFFFD'],
					primaryFont: 'Fraunces',
				},
				eclipse: {
					primaryColor: '#DEDBFF',
					primaryColorSelection: ['#DEDBFF', '#DBEAFF', '#FFDBDB', '#5383ff', '#eaa59c', '#00685f'],
					secondaryColor: '#8000FF',
					secondaryColorSelection: ['#8000FF', '#0000FF', '#FF6A00', '#2200FF', '#D2852D', '#00AAFF'],
					primaryFont: 'Urbanist',
				},
				trish: {
					primaryColor: '#ff1f77',
					primaryColorSelection: ['#ff1f77', '#3344FF', '#FF6A00', '#00CC66', '#8000FF', '#22194D'],
					secondaryColor: '#ffd6e6',
					secondaryColorSelection: ['#FFD7E6', '#EBECFF', '#FFD7B8', '#85FFC4', '#DCB8FF', '#6B56C8'],
					primaryFont: 'Libre Baskerville',
				},
			},
			fontSelection: [
				{ value: 'Plus Jakarta Sans', label: 'Plus Jakarta Sans' },
				{ value: 'Poppins', label: 'Poppins' },
				{ value: 'argent-cf', label: 'Argent' },
				{ value: 'Noto Serif Display', label: 'Noto Serif Display' },
				{ value: 'Inter', label: 'Inter' },
				{ value: 'Fraunces', label: 'Fraunces' },
				{ value: 'Urbanist', label: 'Urbanist' },
				{ value: 'Libre Baskerville', label: 'Libre Baskerville' },
				{ value: 'Montserrat', label: 'Montserrat' },
				{ value: 'Playfair', label: 'Playfair' },
			],
		}
	},
	methods: {
		...mapActions('Stores', ['fetchStore', 'updatePreviewTheme']),
		changePrimaryColor(color) {
			if (color) {
				this.primaryColor = color
				this.$nextTick(() => {
					this.$commonHelper.addThemeColorsToDocument(this.activeTheme, this.primaryColor, this.secondaryColor, this.primaryFont)
				})
			}
		},
		changeSecondaryColor(color) {
			if (color) {
				this.secondaryColor = color
				this.$nextTick(() => {
					this.$commonHelper.addThemeColorsToDocument(this.activeTheme, this.primaryColor, this.secondaryColor, this.primaryFont)
				})
			}
		},
		changePrimaryFont(font) {
			if (font) {
				this.primaryFont = font
				this.$nextTick(() => {
					this.$commonHelper.addThemeColorsToDocument(this.activeTheme, this.primaryColor, this.secondaryColor, this.primaryFont)
				})
			}
		},
		selectTheme(key) {
			if (this.activeTheme !== key) {
				this.activeTheme = key
				this.updatePreviewTheme(this.activeTheme)
				this.changePrimaryColor(this.themeDefaults[key].primaryColor)
				this.changeSecondaryColor(this.themeDefaults[key].secondaryColor)
				this.changePrimaryFont(this.themeDefaults[key].primaryFont)
			}
		},
	},
	watch: {
		primaryColor() {
			this.$nextTick(() => {
				this.$commonHelper.addThemeColorsToDocument(this.activeTheme, this.primaryColor, this.secondaryColor, this.primaryFont)
			})
		},
		secondaryColor() {
			this.$nextTick(() => {
				this.$commonHelper.addThemeColorsToDocument(this.activeTheme, this.primaryColor, this.secondaryColor, this.primaryFont)
			})
		},
		primaryFont() {
			this.$nextTick(() => {
				this.$commonHelper.addThemeColorsToDocument(this.activeTheme, this.primaryColor, this.secondaryColor, this.primaryFont)
			})
		},
	},
}
