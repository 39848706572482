<template>
	<AppModal
		:title="header"
		:id="id"
		action="Submit"
		@cta="refundOrder"
		:loading="loading"
		:disableAction="insufficientBalanceForRefund"
		@open="recordAnalytics"
	>
		<div v-if="insufficientBalanceForRefund" class="d-flex flex-column gap-15px">
			<div class="w-100 d-flex text-light justify-content-between align-items-center">
				<span class="para-2 text-light">Uh oh! It looks like your account balance is too low to process this refund.</span>
			</div>
		</div>
		<div class="d-flex flex-column gap-15px refund-modal-body">
			<div class="d-flex flex-column gap-20px  w-100">
				<h3>Maximum Refund Amount: {{ max_amount }}</h3>
				<div>
					<label class="control-label w-100">
						<span class="mr-1">Partial Refund Amount</span>
						<AppInput input-class="mb-1" v-model.number="partialRefundAmount" type="number" hasPrecision />
						<small class="stan-text-danger error"> {{ price_validation_error_message }}</small>
					</label>
				</div>
			</div>
		</div>
	</AppModal>
</template>
<script>
	import { mapActions, mapGetters } from 'vuex'
	import axios from 'axios'

	import helpers from '~/global_helper/helpers.js'

	export default {
		props: {
			orderId: {
				type: Number,
			},
			orderAmount: {
				type: Number,
			},
			posCurrency: {
				type: String,
			},
			id: {
				type: String,
			},
		},
		data() {
			return {
				loading: false,
				partialRefundAmount: 0,
			}
		},
		computed: {
			...mapGetters('Auth', ['user', 'adminToken']),
			...mapGetters('Transactions', ['balance']),
			max_amount() {
				return helpers.formatCurrency(this.orderAmount, this.posCurrency)
			},
			price_validation_error_message() {
				if (this.partialRefundAmount > this.orderAmount) {
					return `Must be less then ${this.max_amount}`
				}
				if (this.partialRefundAmount <= 0) {
					return `Must be greater then 0`
				}
				return null
			},
			netBalanceAfterRefund() {
				const difference = this.balance?.stripe_available?.amount + this.balance?.stripe_pending?.amount - this.partialRefundAmount
				return difference
			},
			insufficientBalanceForRefund() {
				return this.netBalanceAfterRefund < this.$constants.MINIMUM_ALLOWED_BALANCE
			},
			header() {
				return this.insufficientBalanceForRefund ? 'Insufficient Funds Available' : 'Process a Partial Refund'
			},
		},
		mounted() {
			if (!this.balance.stripe_available) {
				this.fetchBalance()
			}
		},
		methods: {
			...mapActions('Transactions', ['fetchBalance']),
			recordAnalytics() {
				this.$stanAnalytics('admin-partial-refund-modal-opened', {
					meta: { user_id: this.user.user_id, username: this.user.username },
					props: {
						payment_id: this.orderId,
						insufficient_balance: this.insufficientBalanceForRefund,
						amount: this.partialRefundAmount,
					},
				})
			},
			refundOrder() {
				if (this.price_validation_error_message) {
					return
				}
				this.loading = true

				const payload = {
					payment_id: this.orderId,
					amount: this.partialRefundAmount,
					user_id: this.user.user_id,
				}

				axios
					.post('v1/payments/partial-refund', payload, { headers: { Authorization: `Bearer ${this.adminToken}` } })
					.then(() => {
						this.$stanNotify({
							type: 'success', // can be 'info', 'warning', 'success', 'error'
							title: this.$t('Transaction Refund'),
							text: this.$t('This transaction has been successfully refunded.'),
							duration: 2000,
						})
						this.$emit('refunded')
						this.fetchBalance()
					})
					.catch(error => {
						this.$logError(error)
						this.$stanNotify({
							type: 'error', // can be 'info', 'warning', 'success', 'error'
							title: this.$t('Transaction Refund'),
							text: error.response.data.message || this.$t('Something went wrong. Please contact our support.'),
							duration: 5000,
						})
					})
					.finally(() => {
						this.loading = false
						$('#refund-modal').modal('hide')
					})
			},
		},
	}
</script>
<style lang="scss">
	h2 {
		font-family: 'Inter';
	}

	.refund-modal-body {
		margin-top: 25px;

		@media (max-width: 575px) {
			margin-top: 0px;
		}
	}
</style>
