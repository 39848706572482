<template>
	<input
		:key="tick"
		type="file"
		:accept="accepts"
		:multiple="multiple"
		style="display: none;"
		ref="fileInput"
		@change="onFilePicked"
		@cancel="$emit('file-selection-cancelled')"
	/>
</template>

<script>
	import axios from 'axios'

	const sizeUnits = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
	function humanFileSize(bytes) {
		let u = 0
		while (parseInt(bytes, 10) >= 1024 && u < sizeUnits.length - 1) {
			bytes /= 1024
			u += 1
		}
		return `${bytes.toFixed(0)}${sizeUnits[u]}`
	}

	function getValueByTag(data, tag) {
		if (data && typeof data.querySelector === 'function') {
			const xmlTag = data.querySelector(tag)
			if (xmlTag !== null) {
				return xmlTag.innerHTML
			}
		}
		return null
	}

	export default {
		props: {
			options: { type: Object },
			accepts: { type: String },
			multiple: {
				type: Boolean,
				default: () => false,
			},
			confirmUpload: { type: Boolean, default: true },
			objectType: { type: String, default: 'unknown' },
		},
		data() {
			return {
				url: null,
				tick: 0,
			}
		},
		methods: {
			onPickFile() {
				this.$refs.fileInput.click()
			},
			onFilePicked(event) {
				const files = event.target.files || event.dataTransfer.files
				if (!files || files.length === 0) {
					return
				}
				this.files = files
				this.files.forEach(f => {
					f.metadata = { ...f.metadata, id: f.name + Date.now() }
				})
				this.$emit('filePicked', this.files)
				if (this.confirmUpload) this.uploadAllFiles()
			},
			uploadAllFiles(filesToUpload) {
				const files = filesToUpload || this.files
				if (!files) return
				files.forEach(f => this.uploadFile(f))
			},
			uploadFile(file) {
				const vm = this
				const payload = { file_type: file.type, file_name: file.name.toLowerCase(), file_size: file.size, object_type: this.objectType }
				const uploadPayload = { src: file.url, name: file.name, metadata: file.metadata }
				let errorSent = false
				let aborted = false
				axios
					.post('v1/generate-presigned-url', payload, { baseURL: this.siteSettings.pythonApiUrl })
					.then(response => {
						file.url = response.data.url
						uploadPayload.src = file.url
						const s3Data = response.data.data

						const xhr = new XMLHttpRequest()

						xhr.upload.addEventListener(
							'progress',
							e => {
								if (aborted === true) {
									return
								}
								uploadPayload.progress = Math.round((event.loaded / event.total) * 100)
								vm.$emit('progress', uploadPayload)
							},
							false
						)
						xhr.addEventListener(
							'load',
							e => {
								if (aborted === true) {
									return
								}
								vm.$emit('assetUp', uploadPayload)
								vm.tick += 1
								vm.automateUpload = false
							},
							false
						)

						xhr.addEventListener(
							'error',
							err => {
								if (aborted === true) {
									return
								}
								aborted = true
								uploadPayload.error = 'Failed upload'
								if (!errorSent) {
									vm.$emit('errorUp', uploadPayload)
								}
								errorSent = true
							},
							false
						)

						xhr.onreadystatechange = ev => {
							if (xhr.readyState < 4) {
								return
							}
							if (xhr.status && xhr.status >= 400) {
								aborted = true
								uploadPayload.error = 'Upload error'
								const parser = new DOMParser()
								const resp = parser.parseFromString(xhr.responseText, 'application/xhtml+xml')
								const code = getValueByTag(resp, 'Code')
								if (code === 'EntityTooLarge') {
									let maxSizeAllowed = getValueByTag(resp, 'MaxSizeAllowed')
									maxSizeAllowed = maxSizeAllowed !== null ? humanFileSize(maxSizeAllowed) : '-'
									// let proposedSize = getValueByTag(resp, 'ProposedSize')
									uploadPayload.error = `Upload file error: entity too large, max size allowed ${maxSizeAllowed}`
								}
								if (!errorSent) {
									vm.$emit('errorUp', uploadPayload)
								}
								errorSent = true
							}
						}

						xhr.open('POST', s3Data.url)
						const postData = new FormData()
						for (const key in s3Data.fields) {
							postData.append(key, s3Data.fields[key])
						}
						postData.append('file', file)
						xhr.send(postData)
					})
					.catch(err => {
						if (err.response && err.response.status === 400) {
							if (err.response.data?.error === 'incorrect file size') {
								const maxSizeAllowed = err.response.data.limits?.max !== null ? humanFileSize(err.response.data.limits.max) : '-'
								uploadPayload.error = `Upload file error: entity too large, max size allowed ${maxSizeAllowed}`
								if (!errorSent) {
									vm.$emit('errorUp', uploadPayload)
								}
								errorSent = true
							}
							return
						}
						throw new Error('Error generate upload url')
					})
			},
			clearFile() {
				this.$refs.fileInput.value = ''
			},
		},
		computed: {
			fileUploadMaxBytes() {
				return 10 ** 9
			},
		},
	}
</script>
