var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "w-100",
      class: { disabled: _vm.disabled },
      style: _vm.scssVars
    },
    [
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        _c("div", [
          _vm.label
            ? _c("label", { staticClass: "control-label" }, [
                _vm._v(_vm._s(_vm.label))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "character-count-container" }, [
          _vm.maxLength
            ? _c(
                "div",
                {
                  staticClass: "character-count para-3",
                  class: {
                    "character-count-error": _vm.characterCount > _vm.maxLength
                  }
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.characterCount) +
                      " / " +
                      _vm._s(_vm.maxLength) +
                      "\n\t\t\t"
                  )
                ]
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.showImage
            ? _c("FileUpload", {
                ref: "editor-file-upload",
                attrs: {
                  accepts:
                    "image/bmp,image/jpeg,image/png,image/gif,img/svg+xml"
                },
                on: { assetUp: _vm.imageUploaded }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showVideoEmbed
            ? _c("FileUpload", {
                ref: "editor-file-upload-video",
                attrs: { accepts: "video/*" },
                on: {
                  assetUp: _vm.videoUploaded,
                  errorUp: _vm.errorUploadingVideo,
                  filePicked: _vm.startedVideoUploading
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "editor-container" }, [
            _vm.editor && !_vm.readOnly && !_vm.hideMenuBar
              ? _c(
                  "div",
                  {
                    staticClass:
                      "menu-bar pr-2 py-2 d-flex flex-wrap align-items-center"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "px-0",
                        class: {
                          "col-auto": _vm.variableItemsData,
                          col: !_vm.variableItemsData
                        }
                      },
                      [
                        _vm.extended
                          ? _c(
                              "label",
                              {
                                staticClass: "menu-button",
                                class: {
                                  "is-active": _vm.editor.isActive("heading", {
                                    level: 3
                                  })
                                },
                                on: {
                                  click: function($event) {
                                    _vm.editor
                                      .chain()
                                      .focus()
                                      .toggleHeading({ level: 3 })
                                      .run()
                                  }
                                }
                              },
                              [
                                _c("InlineSvg", {
                                  attrs: {
                                    src: "/images/icons/ic-font-size.svg"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "menu-button",
                            class: { "is-active": _vm.editor.isActive("bold") },
                            on: {
                              click: function($event) {
                                _vm.editor
                                  .chain()
                                  .focus()
                                  .toggleBold()
                                  .run()
                              }
                            }
                          },
                          [
                            _c("InlineSvg", {
                              attrs: { src: "/images/icons/ic-bold.svg" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "menu-button",
                            class: {
                              "is-active": _vm.editor.isActive("italic")
                            },
                            on: {
                              click: function($event) {
                                _vm.editor
                                  .chain()
                                  .focus()
                                  .toggleItalic()
                                  .run()
                              }
                            }
                          },
                          [
                            _c("InlineSvg", {
                              attrs: { src: "/images/icons/ic-italic.svg" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "menu-button",
                            class: {
                              "is-active": _vm.editor.isActive("bulletList")
                            },
                            on: {
                              click: function($event) {
                                _vm.editor
                                  .chain()
                                  .focus()
                                  .toggleBulletList()
                                  .run()
                              }
                            }
                          },
                          [
                            _c("InlineSvg", {
                              attrs: { src: "/images/icons/ic-list.svg" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.showImage
                          ? _c(
                              "label",
                              {
                                staticClass: "menu-button",
                                on: { click: _vm.addImage }
                              },
                              [
                                _c("InlineSvg", {
                                  attrs: { src: "/images/icons/ic-image.svg" }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showVideoEmbed
                          ? _c(
                              "label",
                              {
                                staticClass: "menu-button",
                                on: { click: _vm.addLoomVideo }
                              },
                              [
                                _c("InlineSvg", {
                                  attrs: { src: "/images/icons/ic-video.svg" }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showLink
                          ? _c(
                              "label",
                              {
                                staticClass: "menu-button",
                                on: { click: _vm.setLink }
                              },
                              [
                                _c("InlineSvg", {
                                  attrs: { src: "/images/icons/ic-link.svg" }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _vm.showPersonalize &&
                    !_vm.showPersonalizeAtBottom &&
                    _vm.variableItemsData
                      ? _c(
                          "AppDropdownMenu",
                          {
                            staticClass: "d-inline col px-0",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "content",
                                  fn: function() {
                                    return _vm._l(
                                      _vm.sortedVariableItemsData,
                                      function(item) {
                                        return _c(
                                          "button",
                                          {
                                            key: item.id,
                                            staticClass: "dropdown-item",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                _vm.editor
                                                  .chain()
                                                  .focus()
                                                  .insertContent(
                                                    `<variable-item id='${item.id}' name='${item.name}' class='para-2'></variable-item>`
                                                  )
                                                  .run()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t" +
                                                _vm._s(item.name) +
                                                "\n\t\t\t\t\t\t"
                                            )
                                          ]
                                        )
                                      }
                                    )
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              509063116
                            )
                          },
                          [
                            _c(
                              "AppButton",
                              {
                                staticClass: "inverse",
                                attrs: {
                                  name: "tiptap-editor-personalize",
                                  size: "sm",
                                  outline: "",
                                  type: "button",
                                  icon: "images/icons/ic-plus.svg"
                                }
                              },
                              [_vm._v("Personalize")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showProductList
                      ? _c(
                          "AppButton",
                          {
                            staticClass: "inverse",
                            attrs: {
                              name: "tiptap-editor-product-link",
                              size: "sm",
                              outline: "",
                              type: "button",
                              icon: "images/icons/ic-link.svg"
                            },
                            on: { click: _vm.openProductSelectionModal }
                          },
                          [_vm._v("Add Product Link")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showGenerateWithAI
                      ? _c(
                          "AppButton",
                          {
                            staticClass: "inverse tiptap-editor-ai-button",
                            attrs: {
                              name: "tiptap-editor-ai-assistance",
                              size: "sm",
                              outline: "",
                              type: "button",
                              icon: "images/icons/ic-ai-generate.svg",
                              loading: _vm.isAISuggestionLoading,
                              disabled: _vm.isAISuggestionLoading
                            },
                            on: { click: _vm.handleGenerateWithAIClick }
                          },
                          [
                            _c("span", { staticClass: "ai-button-text" }, [
                              _vm._v("Generate with AI")
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showAIPrompt
                      ? _c("AIPromptBox", {
                          directives: [
                            {
                              name: "on-click-outside",
                              rawName: "v-on-click-outside",
                              value: _vm.closeAIPrompt,
                              expression: "closeAIPrompt"
                            }
                          ],
                          on: { requestAIAssistance: _vm.requestAIAssistance }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                class: { "p-editor": !_vm.readOnly },
                on: {
                  drop: function($event) {
                    $event.preventDefault()
                    return _vm.drop($event)
                  },
                  click: function($event) {
                    return _vm.focusEditor()
                  }
                }
              },
              [
                _c("EditorContent", {
                  ref: "editor",
                  staticClass: "col column editor__content",
                  attrs: { editor: _vm.editor, id: "editor-content" }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "bottom-options" }, [
            _c(
              "div",
              [
                _vm.showPersonalize &&
                _vm.showPersonalizeAtBottom &&
                _vm.variableItemsData
                  ? _c(
                      "AppDropdownMenu",
                      {
                        staticClass: "d-inline col px-0",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function() {
                                return _vm._l(
                                  _vm.sortedVariableItemsData,
                                  function(item) {
                                    return _c(
                                      "button",
                                      {
                                        key: item.id,
                                        staticClass: "dropdown-item",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            _vm.editor
                                              .chain()
                                              .focus()
                                              .insertContent(
                                                `<variable-item id='${item.id}' name='${item.name}' class='para-2'></variable-item>`
                                              )
                                              .run()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t" +
                                            _vm._s(item.name) +
                                            "\n\t\t\t\t\t\t"
                                        )
                                      ]
                                    )
                                  }
                                )
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          509063116
                        )
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "float-right my-2 restore-button",
                            attrs: { type: "button" }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.$t("+ Personalize")) +
                                "\n\t\t\t\t\t"
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("div", [
              _vm.defaultValue
                ? _c(
                    "a",
                    {
                      staticClass: "float-right my-2 restore-button",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .setContent(_vm.defaultValue, true)
                            .run()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.$t("Restore Default")) +
                          "\n\t\t\t\t"
                      )
                    ]
                  )
                : _vm._e()
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.videoUploading
        ? _c("div", { staticClass: "ai-block" }, [
            !_vm.videoUploadingError
              ? _c("div", { staticClass: "loading" }, [
                  _c(
                    "div",
                    { staticClass: "icon mr-2" },
                    [_c("AppRipple", { attrs: { size: "sm" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "text para-2 text-bold" }, [
                    _vm._v("Video Uploading...")
                  ])
                ])
              : _vm.videoUploadingError
              ? _c("div", { staticClass: "error" }, [
                  _c(
                    "div",
                    { staticClass: "icon mr-2" },
                    [
                      _c("InlineSvg", {
                        staticClass: "image ic-danger",
                        attrs: { src: "images/icons/ic-warning-circle.svg" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "text para-2 text-bold" }, [
                    _vm._v("Failed to upload video.")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "try-again para-2 text-bold",
                      on: { click: _vm.addVideo }
                    },
                    [_vm._v("Try Again")]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isAIAssistanceRequested
        ? _c(
            "div",
            {
              staticClass: "ai-block",
              class: [
                { error: _vm.aiError },
                { "h-0": _vm.aiSuggestionAcceptedTransition }
              ],
              attrs: { id: "ai-suggestion-block" }
            },
            [
              _vm.isAISuggestionLoading
                ? _c("div", { staticClass: "loading" }, [
                    _c(
                      "div",
                      { staticClass: "icon mr-2" },
                      [_c("AppRipple", { attrs: { size: "sm" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "text para-2 text-bold" }, [
                      _vm._v("Generating...")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "cancel para-2 text-bold",
                        on: { click: _vm.cancelAIRequest }
                      },
                      [_vm._v("Cancel")]
                    )
                  ])
                : _vm.aiError
                ? _c("div", { staticClass: "error" }, [
                    _c(
                      "div",
                      { staticClass: "icon mr-2" },
                      [
                        _c("InlineSvg", {
                          staticClass: "image ic-danger",
                          attrs: { src: "images/icons/ic-warning-circle.svg" }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "text para-2 text-bold" }, [
                      _vm._v("Failed to reach Stanley.")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "try-again para-2 text-bold",
                        on: { click: _vm.requestAIAssistance }
                      },
                      [_vm._v("Try Again")]
                    )
                  ])
                : _c("div", { staticClass: "suggestion" }, [
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [
                        _c("InlineSvg", {
                          staticClass: "image",
                          attrs: { src: "/images/icons/ic-stanley-profile.svg" }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _c("div", {
                        staticClass: "para-2",
                        domProps: { innerHTML: _vm._s(_vm.AISuggestion) }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "buttons" },
                        [
                          _c(
                            "AppButton",
                            {
                              attrs: {
                                name: "tiptap-editor-ai-accept",
                                size: "sm",
                                outline: "",
                                type: "button",
                                icon: "/images/icons/ic-thumbs-up-outline.svg"
                              },
                              on: { click: _vm.acceptAISuggestion }
                            },
                            [_vm._v("Replace\n\t\t\t\t\t")]
                          ),
                          _vm._v(" "),
                          _c(
                            "AppButton",
                            {
                              attrs: {
                                name: "tiptap-editor-ai-cancel",
                                size: "sm",
                                color: "gray",
                                outline: "",
                                type: "button",
                                icon: "images/icons/ic-thumbs-down-outline.svg"
                              },
                              on: { click: _vm.cancelAIRequest }
                            },
                            [_vm._v("Dismiss")]
                          )
                        ],
                        1
                      )
                    ])
                  ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.editor
        ? _c("LinkWindow", {
            ref: "link-window",
            attrs: { editor: _vm.editor, attributes: _vm.linkWindowAttributes }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.editor
        ? _c("InsertVideoModal", {
            ref: "insert-video-modal",
            attrs: {
              editor: _vm.editor,
              attributes: _vm.linkWindowAttributes,
              id: "insert-video-modal"
            },
            on: { uploadVideo: _vm.addVideo }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showProductList
        ? _c("ProductSelectionModal", {
            on: { "confirm-selection": _vm.confirmProductSelection }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }