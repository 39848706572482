<template>
	<div>
		<AppModal
			large
			frame
			isVideo
			:id="id"
			className="block-modal fade"
			:closeButton="false"
			:backdrop="false"
			@close="closeModal"
			style="max-height: 100vh; max-width: 100vw;"
		>
			<div id="video-play-wrapper" class="embed-responsive embed-responsive-16by9" v-if="isPlayingVideo">
				<iframe
					class="embed-responsive-item"
					:src="selectedVideoUri"
					frameborder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
				></iframe>
			</div>
		</AppModal>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'

	export default {
		props: {
			id: {
				type: String,
				default: 'video-play-modal',
			},
		},
		data() {
			return {
				selectedVideoUri: null,
				isPlayingVideo: false,
				start: undefined,
			}
		},
		methods: {
			playVideo(url) {
				$('#video-play-modal').modal('show')
				this.selectedVideoUri = url
				this.isPlayingVideo = true
				this.start = Date.now()
				this.$stanAnalytics('video-player-start', {
					meta: { user_id: this.user.user_id, username: this.user.username },
					props: this.getVideoPlayer,
				})
			},
			closeModal() {
				$('#video-play-modal').modal('hide')
				this.selectedVideoUri = null
				this.isPlayingVideo = false
				const secondsSpent = Math.floor((Date.now() - this.start) / 1000) // seconds elapsed
				const payload = this.getVideoPlayer
				payload.secondsSpent = secondsSpent
				this.$stanAnalytics('video-player-stop', {
					meta: { user_id: this.user.user_id, username: this.user.username },
					props: payload,
				})
			},
		},
		computed: {
			...mapGetters('SiteSettingsStore', ['getVideoPlayer']),
			...mapGetters('Auth', ['user']),
		},
		watch: {
			getVideoPlayer(videoPlayerData) {
				if (videoPlayerData) {
					this.playVideo(videoPlayerData.uri)
				}
			},
		},
	}
</script>

<style lang="scss" scoped>
	#video-play-wrapper {
		width: 90%;
		margin: auto;
		border-radius: 10px;
	}
</style>
