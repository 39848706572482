<template>
	<div>
		<div class="mobile-apps-wrapper flex-column d-none" :class="{ 'd-flex': showNavigation }">
			<div class="mobile-apps-header h3 text-bold" v-if="filterAppItems.length">
				Stan Apps
			</div>
			<div class="mobile-apps" v-if="filterAppItems.length">
				<div class="app" v-for="app in filterAppItems" :key="app.slug" :class="{ open: activeRoute == app.route }">
					<RouterLink
						:to="app.href ? app.href : { name: app.route }"
						:data-test-id="`left-menu-${app.title}`"
						class="cursor-pointer"
						:target="app.href ? '_blank' : ''"
						@click.native="resetMenu"
					>
						<div class="app-icon">
							<InlineSvg :id="app.icon" :src="'/images/left-menu/' + app.icon + '.svg'" class="menu-item-icon" />
						</div>
						<div class="app-text para-2 text-bold">{{ app.title }}</div>
					</RouterLink>
				</div>
			</div>
			<div class="mobile-apps-header h3 text-bold mobile-apps-more-header">
				More
			</div>
			<div class="mobile-admin-apps">
				<div class="app" v-for="app in filterAdminAppItems" :key="app.slug" :class="{ open: activeRoute == app.route }">
					<RouterLink
						:to="app.href ? app.href : { name: app.route }"
						:data-test-id="`left-menu-${app.title}`"
						class="cursor-pointer"
						:target="app.href ? '_blank' : ''"
						@click.native="resetMenu"
					>
						<div class="app-icon">
							<InlineSvg :id="app.icon" :src="'/images/icons/' + app.icon + '.svg'" class="menu-item-icon" />
						</div>
						<div class="app-text para-2 text-bold">{{ app.title }}</div>
					</RouterLink>
				</div>
				<div class="app" v-for="app in filterAcconutAppItems" :key="app.slug" :class="{ open: activeRoute == app.route }">
					<RouterLink
						:to="app.href ? app.href : { name: app.route }"
						:data-test-id="`left-menu-${app.title}`"
						class="cursor-pointer"
						:target="app.href ? '_blank' : ''"
						@click.native="resetMenu"
					>
						<div class="app-icon">
							<InlineSvg :id="app.icon" :src="'/images/icons/' + app.icon + '.svg'" class="menu-item-icon" />
						</div>
						<div class="app-text para-2 text-bold">{{ renderAppTitle(app.title) }}</div>
					</RouterLink>
				</div>
				<!-- Account Switcher-->
				<AccountMenu class="w-100" />
			</div>
		</div>
		<div class="desktop-apps-wrapper d-none" :class="{ 'd-lg-flex': showDesktop }">
			<div class="stan-apps">
				<div class="app" v-for="app in filterAppItems" :key="app.slug">
					<RouterLink
						:to="app.href ? app.href : { name: app.route }"
						:class="{ open: activeRoute == app.route }"
						:data-test-id="`left-menu-${app.title}`"
						class="cursor-pointer"
						:target="app.href ? '_blank' : ''"
						@click.native="resetMenu"
					>
						<div class="app-icon-text">
							<div class="app-icon">
								<InlineSvg :id="app.icon" :src="'/images/left-menu/' + app.icon + '.svg'" class="menu-item-icon" />
							</div>
							<div class="app-text">
								<div class="h3 text-bold text-dark">
									{{ app.title }}
									<InlineSvg
										style="width: 16px; height: 16px;"
										src="/images/icons/ic-pin-filled.svg"
										v-if="isCurrentItemPinned(app)"
									/>
									<span class="new para-3 text-bold" v-if="app.new">NEW</span>
								</div>
								<div class="para-2 text-light">{{ app.subTitle }}</div>
							</div>
						</div>
					</RouterLink>
					<div class="app-dropdown">
						<AppToggle
							:val="isCurrentItemPinned(app)"
							@valueChange="toggleApp(app)"
							:disabled="getToggleDisabled(app)"
							:key="toggleKey"
							class="mr-2"
						/>
					</div>
				</div>
			</div>
			<div class="admin-apps-header h2 text-bold" v-if="filterAdminAppItems.length > 0">
				Stan Admin
			</div>
			<div class="admin-apps">
				<div class="app" v-for="app in filterAdminAppItems" :key="app.slug">
					<RouterLink
						:to="app.href ? app.href : { name: app.route }"
						:class="{ open: activeRoute == app.route }"
						:data-test-id="`left-menu-${app.title}`"
						class="cursor-pointer"
						:target="app.href ? '_blank' : ''"
						@click.native="resetMenu"
					>
						<div class="app-icon-text">
							<div class="app-icon">
								<InlineSvg :id="app.icon" :src="'/images/left-menu/' + app.icon + '.svg'" class="menu-item-icon" />
							</div>
							<div class="app-text">
								<div class="h3 text-bold text-dark d-flex align-items-center">
									{{ app.title }} <InlineSvg src="/images/icons/ic-pin-filled.svg" v-if="isCurrentItemPinned(app)" />
								</div>
								<div class="para-2 text-light">{{ app.subTitle }}</div>
							</div>
						</div>
					</RouterLink>
					<div class="app-dropdown">
						<AppToggle
							:val="isCurrentItemPinned(app)"
							@valueChange="toggleApp(app)"
							:disabled="getToggleDisabled(app)"
							:key="toggleKey"
							class="mr-2"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapGetters, mapActions, mapMutations } from 'vuex'
	import axios from 'axios'
	import AccountMenu from './AccountMenu'

	export default {
		name: 'NavigationApps',
		props: {
			showDesktop: {
				type: Boolean,
				default: false,
			},
		},
		components: { AccountMenu },
		data() {
			return {
				activeRoute: 'dashboard',
				menuItems: [],
				previewCurrentPage: 0,
				hasPreviewPrevious: false,
				hasPreviewNext: false,
				toggleDisabled: {},
				toggleKey: 0,
			}
		},
		created() {
			if (this.user.subscription_tier === this.$constants.TIER.AFFILIATE) {
				this.menuItems = this.$constants.AFFILIATE_MENUITEMS
				this.setActiveRoute()
				this.reloadStore()
			} else {
				this.menuItems = this.$constants.MENUITEMS
				this.setActiveRoute()
				this.reloadStore()
			}
		},
		computed: {
			...mapGetters('Auth', ['user']),
			...mapGetters('Global', ['showNavigation', 'showPreview']),
			...mapGetters('Stores', ['getStorePublishedPages', 'getStoreType', 'getStore', 'getStoreId', 'getStoreFeatures', 'getStoreSections']),
			...mapGetters('ResellRights', ['resellProducts']),
			isFunnelAllowed() {
				return this.user.subscription_tier_allowed_modules?.indexOf('funnels') !== -1
			},
			allowedOptionalMenus() {
				const optional = []
				for (let i = 0; i < Object.keys(this.user.tags).length; i++) {
					if (this.user.tags?.inbox) {
						optional.push('inbox')
					}
				}
				return optional
			},
			filterAppItems() {
				return this.menuItems.filter(menu => {
					let showMenu = false
					const userRoles = this.user.list_of_roles
					const menuRoles = Object.values(menu.roles)
					// Restrict menu by roles
					showMenu = userRoles.some(v => menuRoles.indexOf(v) !== -1)
					// Restrict menu to enrolled users
					if (menu.optional && !this.allowedOptionalMenus.includes(menu.route)) {
						showMenu = false
					}

					if (menu.experiments && menu.experiments.length > 0) {
						showMenu = false
						for (let i = 0, len = menu.experiments.length; i < len; i++) {
							if (this.user.experiments[menu.experiments[i]] === 1) {
								showMenu = true
							}
						}
					}
					if (menu.type !== 'app') {
						showMenu = false
					}

					const availableModules = this.user.subscription_tier_allowed_modules

					if (menu.moduleName && availableModules.indexOf(menu.moduleName) !== -1) {
						showMenu = true
					} else if (availableModules.indexOf('general-modules') === -1) {
						showMenu = false
					}

					if (!this.showDesktop && menu.mobileType === 'app') showMenu = true

					if (menu.route === 'affiliate')
						if (this.resellProducts.length) {
							showMenu = true
						} else showMenu = false
					return showMenu
				})
			},
			filterAdminAppItems() {
				return this.menuItems.filter(menu => {
					let showMenu = false
					const userRoles = this.user.list_of_roles
					const menuRoles = Object.values(menu.roles)
					// Restrict menu by roles
					showMenu = userRoles.some(v => menuRoles.indexOf(v) !== -1)
					// Restrict menu to enrolled users
					if (menu.optional && !this.allowedOptionalMenus.includes(menu.route)) {
						showMenu = false
					}

					if (menu.experiments && menu.experiments.length > 0) {
						showMenu = false
						for (let i = 0, len = menu.experiments.length; i < len; i++) {
							if (this.user.experiments[menu.experiments[i]] === 1) {
								showMenu = true
							}
						}
					}

					if (menu.type !== 'admin') {
						showMenu = false
					}

					return showMenu
				})
			},
			filterAcconutAppItems() {
				return this.menuItems.filter(menu => {
					let showMenu = false
					const userRoles = this.user.list_of_roles
					const menuRoles = Object.values(menu.roles)
					// Restrict menu by roles
					showMenu = userRoles.some(v => menuRoles.indexOf(v) !== -1)
					// Restrict menu to enrolled users
					if (menu.optional && !this.allowedOptionalMenus.includes(menu.route)) {
						showMenu = false
					}

					if (menu.experiments && menu.experiments.length > 0) {
						showMenu = false
						for (let i = 0, len = menu.experiments.length; i < len; i++) {
							if (this.user.experiments[menu.experiments[i]] === 1) {
								showMenu = true
							}
						}
					}

					if (menu.type !== 'menu' || menu.position !== 'bottom') {
						showMenu = false
					}

					return showMenu
				})
			},
		},
		watch: {
			'$route.name': {
				handler() {
					this.setActiveRoute()
					this.reloadStore()
				},
			},
			getStore: {
				immediate: true,
				handler() {
					this.previewCurrentPage = 0
					this.setNavigationButtonVisibility()
				},
			},
			previewCurrentPage: {
				immediate: true,
				handler() {
					this.setNavigationButtonVisibility()
				},
			},
		},
		methods: {
			...mapMutations('Global', ['setShowNavigation', 'setShowPreview']),
			...mapActions('Auth', ['fetchUser']),
			...mapActions('Stores', ['fetchStore']),
			getToggleDisabled(item) {
				return !!this.toggleDisabled[item.id]
			},
			renderAppTitle(title) {
				if (title === 'Get Help') {
					return 'Ask Stanley'
				}

				return title
			},
			async toggleApp(item) {
				if (this.isCurrentItemPinned(item)) {
					await this.unpinItem(item)
				} else {
					await this.pinItem(item)
				}
			},
			async dropdownSelected(event, item) {
				if (event.value === 'launch') {
					this.$router.push({ name: item.route })
				}
				if (event.value === 'pin') {
					await this.pinItem(item)
				}
				if (event.value === 'remove-pin') {
					await this.unpinItem(item)
				}
			},
			async pinItem(item) {
				this.toggleDisabled[item.id] = true
				let currentPinItems = this.user.data.pinned_navigation
				if (!currentPinItems) currentPinItems = []
				currentPinItems.push({ id: item.id })
				try {
					await axios.post('v1/users/navigation-update', { pinned_navigation: currentPinItems }).then(() => {})
					await this.fetchUser()
				} catch (error) {
					this.$stanNotify({
						type: 'warning',
						title: this.$t('Failed!'),
						text: this.$t('Unable to pin an app. Please try again later'),
					})
					this.$logError(error)
				}
				this.toggleDisabled[item.id] = false
				this.toggleKey += 1
			},
			async unpinItem(item) {
				this.toggleDisabled[item.id] = true
				const currentPinItems = this.user.data.pinned_navigation
				if (!currentPinItems || currentPinItems?.map(e => e.id).indexOf(item.id) === -1) return
				currentPinItems.splice(currentPinItems?.map(e => e.id)?.indexOf(item.id), 1)
				try {
					await axios.post('v1/users/navigation-update', { pinned_navigation: currentPinItems }).then(() => {})
				} catch (error) {
					this.$stanNotify({
						type: 'warning',
						title: this.$t('Failed!'),
						text: this.$t('Unable to remove an app from sidebar. Please try again later'),
					})
					this.$logError(error)
				}
				this.toggleDisabled[item.id] = false
				this.toggleKey += 1
			},
			reloadStore() {
				if (!this.getStoreLoading) {
					let storeId = 'self'
					let storeType = 'linksite'
					if (this.$route.name.startsWith('funnel') && this.$route.params.storeId) {
						storeId = this.$route.params.storeId
						storeType = 'funnels'
					}
					if (!this.getStoreType || this.getStoreType !== storeType || (this.getStoreType === 'funnels' && storeId !== this.getStoreId)) {
						this.fetchStore({ storeId, storeType })
					}
				}
			},
			setActiveRoute() {
				for (let i = 0; i < this.menuItems.length; i++) {
					const menuItem = this.menuItems[i]
					const baseRouteName = this.$route?.matched[0].name ?? ''
					if (baseRouteName === menuItem.routeName) {
						this.activeRoute = menuItem.route
						return
					}
				}
			},
			gotToSettings() {
				this.$router.push({ name: 'account_settings' })
				this.resetMenu()
			},
			async logout() {
				$('body').removeClass('menu-active')
				clearInterval(this.t)
				await this.$store.dispatch('Auth/logout')
				this.$router.push({ name: 'login_page' }).catch(() => {})
			},

			closeMenu() {
				this.setShowNavigation(false)
			},
			resetMenu() {
				this.closeMenu()
				this.setShowPreview(false)
			},
			togglePreview() {
				const isNavVisible = this.showNavigation
				this.closeMenu()
				setTimeout(() => this.setShowPreview(!this.showPreview), isNavVisible ? 250 : 0)
			},
			prevPage() {
				this.previewCurrentPage -= 1
			},
			nextPage() {
				this.previewCurrentPage += 1
			},
			isCurrentItemPinned(item) {
				return this.user.data.pinned_navigation && this.user.data.pinned_navigation.map(e => e.id).indexOf(item.id) !== -1
			},
			dropdownOptions(item) {
				if (this.isCurrentItemPinned(item)) {
					return [
						{ label: 'Launch', value: 'launch', image: 'images/icons/ic-launch.svg' },
						{ label: 'Unpin', value: 'remove-pin', image: 'images/icons/ic-pin-filled.svg' },
					]
				}
				return [
					{ label: 'Launch', value: 'launch', image: 'images/icons/ic-launch.svg' },
					{ label: 'Pin to Sidebar', value: 'pin', image: 'images/icons/ic-pin.svg' },
				]
			},
			setNavigationButtonVisibility() {
				if (
					this.getStorePublishedPages &&
					this.getStorePublishedPages.length &&
					this.getStorePublishedPages.length > 0 &&
					this.previewCurrentPage > 0
				) {
					this.hasPreviewPrevious = true
				} else {
					this.hasPreviewPrevious = false
				}

				if (
					this.getStorePublishedPages &&
					this.getStorePublishedPages.length &&
					this.previewCurrentPage < this.getStorePublishedPages.length - 1
				) {
					this.hasPreviewNext = true
				} else {
					this.hasPreviewNext = false
				}
			},
		},
	}
</script>

<style lang="scss" scoped>
	.mobile-apps-wrapper {
		width: 100%;
		padding: 25px;
		height: 100vh;
		@supports (height: 100dvh) {
			height: 100dvh;
		}
		padding-bottom: 100px;
		overflow-y: scroll;
		background-color: #fff;
		.mobile-apps-header {
			margin-bottom: 20px;
			margin-top: 40px;
			&:first-child {
				margin-top: 0;
			}
			&.mobile-apps-more-header:not(:first-child) {
				margin-top: 60px;
			}
		}

		.mobile-apps {
			display: flex;
			flex-direction: row;
			gap: 30px 20px;
			flex-wrap: wrap;
			justify-content: center;

			.app {
				margin-top: 10px;
				.app-icon {
					padding: 12px;
					border-radius: 15px;
					background: var(--stan-primary-light-color);
					box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);
				}
				.app-text {
					width: 100%;
					text-align: center;
					padding-top: 12px;
					margin-top: 5px;
					color: var(--stan-text-dark-color);
				}
				&.open {
					.app-text {
						color: var(--stan-primary-primary-color);
					}

					.app-icon {
						svg::v-deep {
							path:not(.no-fill),
							rect:not(.no-fill) {
								fill: var(--stan-primary-primary-color);
							}
						}
					}
				}
			}
		}
	}

	.mobile-admin-apps {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0px;
		gap: 15px;
		.app {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;

			background: #ffffff;
			box-shadow: 0px 15px 50px rgba(0, 16, 109, 0.07);
			border-radius: 10px;
			a {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 10px;
				gap: 13px;
				width: 100%;

				.app-icon {
					padding: 8px;
					display: flex;
					width: 36px;
					height: 36px;
					background: var(--stan-primary-light-color);
					box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
					border-radius: 8px;
					svg {
						width: 20px;
						height: 20px;
						padding: 0px;
					}
				}
			}
			.app-text {
				color: var(--stan-text-dark-color);
			}
			&.open {
				background: var(--stan-primary-primary-color);
				.app-icon {
					background: var(--stan-primary-strike-color);

					svg::v-deep {
						path:not(.no-fill) {
							fill: var(--stan-white);
						}
					}
				}
				.app-text {
					color: var(--stan-white);
				}
			}
		}
	}

	.desktop-apps-wrapper {
		width: 100%;
		flex-direction: column;
		gap: 35px;
		.stan-apps,
		.admin-apps {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 30px;
			.app {
				width: 47%;

				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				border-radius: 20px;

				background: #ffffff;
				/* Default */

				box-shadow: 0px 15px 50px rgba(0, 16, 109, 0.07), inset 0px -5px 10px rgba(0, 0, 0, 0.02);
				a {
					width: 100%;
					padding: 20px;
				}
				.app-icon-text {
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					padding: 0px;
					gap: 20px;
					.app-icon {
						display: flex;
						flex-direction: row;
						align-items: flex-start;
						padding: 10px;
						gap: 10px;

						width: 80px;
						height: 80px;

						background: var(--stan-primary-light-color);
						border-radius: 10px;
						align-items: center;
					}
					.app-text {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						padding: 0px;
						gap: 10px;
						margin-top: 5px;
						color: var(--stan-text-dark-color);
						div {
							display: flex;
							align-items: center;
							gap: 5px;
						}
						svg {
							width: 18px;
							height: 18px;
						}
						.new {
							background-color: var(--stan-primary-primary-color);
							color: var(--stan-white);
							padding: 5px 7px;
							border-radius: 5px;
						}
					}
				}

				.app-dropdown {
					padding-right: 20px;
				}
			}
		}
		.admin-apps-header {
			margin-top: 15px;
		}
	}
</style>
