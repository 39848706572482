<template>
	<div @blur="blurTimeZone" tabindex="0">
		<div class="d-flex align-items-center dropdown-item time-zone-wrapper" @click.stop="toggleGroup">
			<div class="time-zone-iso">{{ option.iso }}&nbsp;-</div>
			<div class="time-zone-name">{{ option.showName }}</div>
			<div class="time-zone-offset">|&nbsp;UTC&nbsp;{{ offset }}</div>
		</div>
		<div v-if="selected">
			<div
				class="d-flex align-items-center dropdown-item time-zone-wrapper"
				v-for="(zone, id) in optionZones"
				:key="`zone-${option.id}-${id}`"
				@click.stop="selectTimeZone(zone.value)"
			>
				<div class="time-zone-name selector">
					{{ zone.name }} <span class="time-zone-offset">&nbsp;|&nbsp;UTC&nbsp;{{ offset }}</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'AppTimeZoneRow',
		props: {
			option: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				selected: false,
			}
		},
		mounted() {},
		methods: {
			blurTimeZone(ev) {
				ev.stopPropagation()
				this.selected = false
			},
			toggleGroup(ev) {
				ev.stopPropagation()
				this.selected = !this.selected
			},
			selectTimeZone(zone) {
				this.$emit('click', { id: this.option.id, label: this.option.name, name: zone })
			},
		},
		computed: {
			optionZones() {
				return this.option.zones.map(z => {
					const zoneElm = z.split('/')
					const name = zoneElm.length > 0 ? zoneElm[zoneElm.length - 1] : z
					return { name: name.replace(/_/g, ' '), value: z }
				})
			},
			offset() {
				if (this.option.offset > 0) {
					return `+${this.option.offset}`
				}
				// return this.option.offset < 0 ? ` -${this.option.offset}` : ` ${this.option.offset}`
				return `${this.option.offset}`
			},
		},
	}
</script>

<style lang="scss" scoped>
	.time-zone {
		&-wrapper {
			cursor: pointer;
			min-height: 38px;
			padding: 10px 12px;
			gap: 5px;
			font-size: 14px;
			line-height: 18px;
			letter-spacing: -0.02em;
		}
		&-iso {
			color: var(--stan-text-dark-color);
			font-weight: 600;
		}
		&-name {
			max-width: 261px;
			text-overflow: ellipsis;
			overflow: hidden;
			color: var(--stan-text-dark-color);
			&.selector {
				padding: 4px 12px 4px 36px;
			}
		}
		&-offset {
			color: var(--stan-text-light-color);
		}
	}
</style>
