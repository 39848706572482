var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "AppModal",
        {
          ref: "start-free-trial-affiliate-only",
          attrs: {
            id: "start-free-trial-affiliate-only",
            title: "Save $100s with Stan as your All-In-One Store"
          }
        },
        [
          _c("div", { staticClass: "trial-disclaimer mt-0" }, [
            _c("div", { staticClass: "para-2 text-dark text-bold mb-2" }, [
              _vm._v(
                "\n\t\t\t\tYou won't be charged for your free trial!\n\t\t\t"
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "para-3 text-light" }, [
              _vm._v(
                "\n\t\t\t\tWe ask for your credit card information for a seamless subscription experience, but you can cancel anytime and we’ll send you a\n\t\t\t\theads-up email in advance.\n\t\t\t"
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "subcription-form" },
            [
              _c("SubscriptionForm", {
                ref: "subscription-form",
                attrs: {
                  "trial-days": _vm.trialDays,
                  "price-id": _vm.subscriptionPriceId,
                  "promo-id": _vm.promoId,
                  "credit-card-required": _vm.creditCardRequired,
                  mustApplyTrial: true
                },
                on: {
                  loading: function($event) {
                    _vm.loading = true
                  },
                  loaded: function($event) {
                    _vm.loading = false
                  },
                  success: _vm.onCCSuccess
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "my-4" },
                [
                  _c(
                    "AppCheckbox",
                    {
                      model: {
                        value: _vm.termsAgree,
                        callback: function($$v) {
                          _vm.termsAgree = $$v
                        },
                        expression: "termsAgree"
                      }
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\tI acknowledge that I have read and understand the\n\t\t\t\t\t"
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://assets.stanwith.me/legal/terms-of-service.pdf",
                            target: "_new"
                          }
                        },
                        [_vm._v("Terms of Service")]
                      ),
                      _vm._v(" and the\n\t\t\t\t\t"),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://assets.stanwith.me/legal/privacy-policy.pdf"
                          }
                        },
                        [_vm._v("Privacy Policy")]
                      ),
                      _vm._v(".\n\t\t\t\t")
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { margin: "20px auto 20px auto" } },
            [
              _c(
                "AppButton",
                {
                  staticClass: "w-100",
                  attrs: {
                    name: "subscribe-button",
                    size: "large",
                    loading: _vm.loading,
                    disabled: !_vm.termsAgree
                  },
                  on: { click: _vm.submit }
                },
                [
                  _c("span", { staticClass: "mx-2" }, [
                    _vm._v(
                      "Start Your " + _vm._s(_vm.trialDays) + " Day Free Trial"
                    )
                  ])
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }