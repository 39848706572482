var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `stan-banner ${_vm.severity} ${
        !_vm.buttons.length ? "cursor-pointer" : ""
      }`,
      on: {
        click: function($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.fullClick.apply(null, arguments)
        }
      }
    },
    [
      _c("div", { staticClass: "banner-content" }, [
        _vm.icon
          ? _c(
              "span",
              { staticClass: "banner-icon", on: { click: _vm.fullClick } },
              [
                _c("InlineSvg", {
                  staticClass: "icon-svg",
                  attrs: { src: _vm.icon }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-container", on: { click: _vm.fullClick } },
          [
            _vm.title
              ? _c("span", { staticClass: "title h4 text-bold" }, [
                  _vm._v("\n\t\t\t\t" + _vm._s(_vm.title) + "\n\t\t\t")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.subtitle
              ? _c("span", { staticClass: "subtitle para-2" }, [
                  _vm._v("\n\t\t\t\t" + _vm._s(_vm.subtitle) + "\n\t\t\t")
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("div", { attrs: { id: "slot-container" } }, [_vm._t("default")], 2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "button-container" },
          [
            _vm._l(_vm.buttons, function(button, index) {
              return _c(
                "AppButton",
                _vm._b(
                  {
                    key: index,
                    staticClass: "button",
                    attrs: { loading: _vm.ctaLoading },
                    on: { click: button.click }
                  },
                  "AppButton",
                  button,
                  false
                ),
                [_vm._v(_vm._s(button.name))]
              )
            }),
            _vm._v(" "),
            !_vm.buttons.length && _vm.rightIcon
              ? _c(
                  "div",
                  { staticClass: "action-icon", on: { click: _vm.fullClick } },
                  [
                    _c("InlineSvg", {
                      staticClass: "icon-svg",
                      attrs: { src: _vm.rightIcon }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }