<template>
	<AppModal
		id="upgrade-funnel-theme-modal"
		:title="$t('Upgrade Funnel Design')"
		ariaLabelledby="upgradeFunnel"
		:subheading="
			$t(
				'Heads up! The old funnel design you’re using now has custom colors set. Switching to the new design will permanently set your funnel to your store theme.'
			)
		"
		:action="$t('Upgrade Funnel Design')"
		:loading="isLoading"
		:disableAction="isLoading"
		@cta="upgradeFunnel"
	></AppModal>
</template>

<script>
	import axios from 'axios'

	export default {
		props: {
			storeId: { type: Number, required: true },
		},
		data() {
			return {
				isLoading: false,
			}
		},
		methods: {
			upgradeFunnel() {
				this.isLoading = true
				axios
					.put(`v1/stores/${this.storeId}/change-theme`)
					.then(() => {
						this.$stanNotify({
							type: 'success',
							title: this.$t('Saved!'),
							text: this.$t('Your funnel has been upgraded to your store theme.'),
							duration: 5000,
						})
						this.$emit('upgraded')
					})
					.catch(err => {
						this.$stanNotify({
							type: 'error',
							title: this.$t('Oops.'),
							text: this.$t('Something went wrong. Please try again later.'),
							duration: 5000,
						})
						this.$logError(err)
					})
					.finally(() => {
						this.isLoading = false
						$('#upgrade-funnel-theme-modal').modal('hide')
					})
			},
		},
	}
</script>
