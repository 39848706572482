<template>
	<div>
		<label v-if="label" class="control-label">{{ label }}</label>
		<div class="color-input form-control" @focusout="$v.$touch">
			<span class="color-preview-size">
				<AppDropdownMenu ref="color-picker-dropdown" class="color-picker-dropdown" keepOpen>
					<div class="color-preview" :style="{ background: previewColor }"></div>

					<template v-slot:content>
						<ColorPicker v-model="previewColor" :presets="colorSuggestions" />
					</template>
				</AppDropdownMenu>
			</span>

			<input maxlength="7" v-model="previewColor" @focus="handleFocus" @blur="handleBlur" />
		</div>
		<div v-show="!isInputFocused && $v.previewColor.$error" class="form-error-text para-3 stan-text-danger mb-3 mt-2">
			<InlineSvg class="error-icon" src="/images/icons/ic-warning-circle.svg" />{{ $t('Please provide a valid hex code') }}
		</div>
		<div class="color-suggestions">
			<div
				v-for="color in colorSuggestions"
				:key="color"
				class="color-suggestion"
				:style="{ background: color }"
				:class="{ selected: isColorSelected(color) }"
				@click="() => setPreviewColour(color)"
			/>
		</div>
	</div>
</template>

<script>
	import commonHelpers from '~/global_helper/helpers.js'
	import ColorPicker from '~/pages/stores/components/ColorPicker'
	import AppDropdownMenu from '~/components/shared/AppDropdownMenu'

	export default {
		name: 'ColorInput',
		components: { ColorPicker, AppDropdownMenu },
		data() {
			return {
				isInputFocused: false,
			}
		},
		props: {
			colorSuggestions: {
				type: Array,
				default: () => [],
			},
			label: {
				type: String,
				default: null,
			},
			color: {
				type: String,
				default: '#FFFFFF',
			},
		},
		model: {
			prop: 'color',
			event: 'valueChange',
		},
		emits: ['valueChange'],
		computed: {
			previewColor: {
				get() {
					return this.color
				},
				set(value) {
					this.setPreviewColour(value)
				},
			},
		},
		methods: {
			isColorSelected(color) {
				return color === this.previewColor
			},
			setPreviewColour(color) {
				this.$emit('valueChange', color)
			},
			handleFocus() {
				this.isInputFocused = true
			},
			handleBlur() {
				this.isInputFocused = false
				this.$v.$touch()
			},
		},
		validations() {
			return {
				previewColor: {
					required: true,
					isValidHexCode: commonHelpers.isValidHexColor,
				},
			}
		},
	}
</script>

<style scoped lang="scss">
	@mixin color-circle {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		border: 0.5px solid var(--stan-boulder-6);
	}

	.color-input {
		display: flex;
		gap: 8px;

		align-items: center;
		justify-content: flex-start;

		margin-bottom: 1rem;

		input:focus {
			box-shadow: none;
			outline: none;
		}
		input:disabled {
			cursor: not-allowed;
			color: var(--stan-text-light-color) !important;
			background: var(--stan-primary-light-color) !important;
			-webkit-text-fill-color: var(--stan-text-light-color);
			-webkit-opacity: 1;
		}
	}

	.color-preview {
		@include color-circle;
	}
	.color-preview-size {
		width: 20px;
		height: 20px;
	}
	.color-suggestions {
		display: flex;
		gap: 8px;
		padding-left: 0.25rem;

		.color-suggestion {
			@include color-circle;

			cursor: pointer;

			&.selected {
				border: 2px solid var(--stan-primary-primary-color);
			}
		}
	}

	.form-error-text::v-deep {
		display: flex;
		align-items: center;
		gap: 0.25rem;

		color: var(--stan-danger);

		svg {
			fill: var(--stan-danger);
		}
	}
</style>
