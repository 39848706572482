var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.bannerImage
        ? _c("Banner", {
            attrs: {
              title: _vm.headingText,
              image: _vm.bannerImage,
              "background-color": _vm.userPrimaryColor
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("h4", { staticClass: "title" }, [
        _vm._v("\n\t\t" + _vm._s(_vm.titleText) + "\n\t")
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "products-list" },
        [
          _vm._l(_vm.products, function(product, index) {
            return _c("li", { key: index, staticClass: "relative" }, [
              _c("div", { staticClass: "amount-wrapper" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center divide-bar w-100"
                  },
                  [
                    _c("span", { staticClass: "product-title pr-16" }, [
                      _vm._v(_vm._s(product.title) + " :")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "total-am pl-2" }, [
                      _vm._v(_vm._s(_vm.getAmountText(product.price)))
                    ])
                  ]
                )
              ])
            ])
          }),
          _vm._v(" "),
          _c("li", { staticClass: "relative" }, [
            _c("div", { staticClass: "amount-wrapper" }, [
              _c(
                "span",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center divide-bar w-100"
                },
                [
                  _c("span", { staticClass: "total pr-16" }, [
                    _vm._v("Total :")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "total-am pl-2" }, [
                    _vm._v(_vm._s(_vm.formattedTotal))
                  ])
                ]
              )
            ])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "purchase-confirmation-box" }, [
        _vm._v("\n\t\t" + _vm._s(_vm.buttonText) + "\n\t")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }