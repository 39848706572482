var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "cta-header",
      class: { "full-width": _vm.fullWidth, "danger-color": _vm.dangerColor },
      attrs: { id: "cta-header" }
    },
    [
      _c("div", { staticClass: "cta-header-body" }, [
        _c("div", { staticClass: "cta-header-text" }, [
          _vm.iconLeft
            ? _c(
                "div",
                { staticClass: "icon" },
                [
                  _c("InlineSvg", {
                    staticClass: "icon d-none d-md-block",
                    attrs: { src: _vm.iconLeft }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "cursor-pointer d-flex align-items-center",
              on: {
                click: function($event) {
                  return _vm.$emit("click")
                }
              }
            },
            [
              _vm._t("default"),
              _vm._v(" "),
              _vm.iconRight
                ? _c(
                    "span",
                    { staticClass: "icon icon-right" },
                    [_c("InlineSvg", { attrs: { src: _vm.iconRight } })],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ]),
        _vm._v(" "),
        _vm.showCloseIcon
          ? _c(
              "div",
              {
                staticClass: "cursor-pointer close-icon",
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_c("InlineSvg", { attrs: { src: "/images/close.svg" } })],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.buttonText
          ? _c(
              "div",
              {
                staticClass: "cursor-pointer button-text h4 text-bold",
                on: {
                  click: function($event) {
                    return _vm.$emit("click")
                  }
                }
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.buttonText) + "\n\t\t")]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }