import { Node, mergeAttributes } from '@tiptap/vue-2'

export default Node.create({
	name: 'variableItem',
	group: 'inline',
	inline: true,
	content: 'text*',
	atom: true,
	draggable: true,

	addAttributes() {
		return {
			id: {
				default: 'unknown',
			},
			name: {
				default: 'unknown',
			},
		}
	},

	parseHTML() {
		return [
			{
				tag: 'variable-item',
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return ['variable-item', mergeAttributes(HTMLAttributes), `{${HTMLAttributes.id}}`]
	},

	addNodeView() {
		return ({ node }) => {
			const dom = document.createElement('span')
			dom.classList.add('variable-item')
			dom.setAttribute('contenteditable', 'false')

			const content = document.createElement('div')
			content.classList.add('content')
			content.classList.add('p-0')
			content.classList.add('m-0')

			content.innerHTML = node.attrs.name
			dom.append(content)

			return {
				dom,
			}
		}
	},
})
