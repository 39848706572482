<template>
	<div class="example-questions" style="margin-top: 7%;">
		<h3 class="d-md-flex d-none text-bold text-center align-items-center justify-content-center gap mb-5">
			<InlineSvg src="/images/icons/ic-lightning.svg" />Example Questions
		</h3>
		<h4 class="d-md-none text-bold text-center d-flex align-items-center justify-content-center mb-4">
			<InlineSvg src="/images/icons/ic-lightning.svg" />Example Questions
		</h4>
		<div class="question-chooser mb-4 mb-md-5">
			<button class="question-choice" v-for="(question, index) in options" :key="index" @click="userPrompt(question)">
				<span class="para-3 text-bold">{{ question.text }}</span>
				<InlineSvg class="question-arrow-image" src="/images/icons/ic-arrow-right.svg" />
			</button>
		</div>
		<div class="alpha-note para-3 mb-4 mb-md-5 d-flex flex-column gap-8px">
			<span>
				Need more information about the platform? Visit our
				<a @click="clickMoreInformationLink('https://help.stan.store/')" class="mailto">Help Center</a> or email
				<a @click="clickMoreInformationLink('mailto:friends@stanwith.me')" class="mailto">friends@stanwith.me</a>
			</span>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				showOptions: false,
				options: [
					{
						text: 'What can Stanley do for me?',
					},
					{
						text: 'How can I earn more money on Stan?',
					},
					{
						text: 'How can I setup my direct deposit through Stan with Stripe?',
					},
					{
						text: 'Create a detailed 10 step guide to get started as a content creator on TikTok!',
					},
				],
			}
		},
		methods: {
			userPrompt(option) {
				this.$emit('userPrompt', option)
			},
		},
	}
</script>

<style lang="scss" scoped>
	.example-questions {
		flex: 0 0 auto;
		height: auto;
		max-width: 500px;
		margin: auto;
		h4,
		h3 {
			gap: 8px;
			svg {
				width: 20px;
				height: 20px;
			}
		}
		.question-chooser {
			display: flex;
			flex-wrap: wrap;
			gap: 15px;
			.question-choice {
				padding: 15px 20px;
				width: calc(50% - 7.5px);
				background-color: var(--stan-gray-primary-color);
				border-radius: 10px;
				display: flex;
				gap: 5px;
				text-align: left;
				color: var(--stan-text-dark-color);
				flex-direction: column;
				justify-content: space-between;
				@media (min-width: 1200px) {
					justify-content: flex-start;
					gap: 10px;
					align-items: flex-end;
					height: fit-content;
					padding: 20px 25px;
					flex-direction: row;
				}
				.question-arrow-image {
					flex: 0 0 auto;
					width: 16px;
					height: 16px;
					align-self: flex-end;
				}
				&:hover {
					background-color: var(--stan-gray-strike-color);
				}
			}
		}
		.alpha-note {
			background-color: var(--stan-gray-soft-color);
			color: var(--stan-text-light-color);
			padding: 20px;
			border-radius: 10px;
			width: 85%;
			margin: auto;
		}
	}
</style>
