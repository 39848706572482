<template>
	<div class="d-flex flex-row bg-white align-items-center pill-container">
		<!-- Icon -->
		<div
			class="d-flex align-items-center icon-container justify-content-center"
			:class="{
				'creator-pro-icon': isPro,
				'creator-icon': !isPro,
			}"
		>
			<span class="para-2">{{ icon }}</span>
		</div>

		<!-- Text -->
		<div class="d-flex flex-column">
			<span class="text-dark para-3 text-bold">{{ header }}</span>
			<span class="text-light para-4">{{ description }}</span>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PlanFeaturePill',
		props: {
			icon: {
				default: '',
				type: String,
				required: true,
			},
			header: {
				default: '',
				type: String,
				required: true,
			},
			description: {
				default: '',
				type: String,
				required: true,
			},
			isPro: {
				default: false,
				type: Boolean,
			},
		},
	}
</script>

<style lang="scss">
	.icon-container {
		border-radius: 100%;
		justify-content: center;
		height: 28px;
		width: 28px;

		&.creator-icon {
			background-color: var(--stan-primary-light-color);
		}

		&.creator-pro-icon {
			background-color: var(--stan-primary-primary-color);
		}
	}

	.pill-container {
		box-shadow: var(--stan-box-shadow);
		gap: 8px;
		padding: 10px;
		border-radius: 25px;
	}
</style>
