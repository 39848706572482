import userflow from 'userflow.js'

export default {
	InitializeUserflow() {
		userflow.init('ct_gn77pva5ajcwthimoqmhgcmdee')
	},
	UpdateUserflowIdentityData(user) {
		if (user) {
			const userData = {
				name: user.full_name,
				username: user.username,
				email: user.email,
				userId: user.user_id,
				signed_up_at: new Date(user.created_at * 1000).toISOString().split('T')[0],
			}
			const acquisition = user.data?.acquisition
			if (acquisition?.utm_source) {
				userData.utm_source = acquisition.utm_source
			}
			if (acquisition?.utm_medium) {
				userData.utm_medium = acquisition.utm_medium
			}
			if (acquisition?.utm_campaign) {
				userData.utm_campaign = acquisition.utm_campaign
			}
			userflow.identify(user.user_id, userData)
		}
	},
	UserflowCustomUpdate(event, options) {
		const obj = {}
		obj[event] = options
		userflow.updateUser(obj)
	},
	UserflowCustomBulkUpdate(obj) {
		// obj = { key: value }
		userflow.updateUser(obj)
	},

	TrackUserflowEvent(event, options) {
		if (userflow.isIdentified()) {
			userflow.track(event, options)
		}
	},
}
