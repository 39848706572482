<template>
	<AppModal id="modal-audience-additional-details" title="Additional Details" @close="closeModal">
		<div v-if="additionalDetails.questions">
			<div class="color-gray">
				<div class="mb-3 w-100" v-for="row in additionalDetails.questions" :key="row.id">
					<h4>{{ row.question }}</h4>
					<p>{{ row.answer }}</p>
				</div>
			</div>
		</div>
	</AppModal>
</template>

<script>
	export default {
		props: ['additionalDetails'],
		methods: {
			closeModal() {
				$('#modal-audience-additional-details').modal('hide')
				this.$emit('close-modal')
			},
		},
	}
</script>
