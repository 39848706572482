<template>
	<AppModal
		id="delete-customer-modal"
		@close="closeModal"
		action="Delete Customer"
		secondary-action="Keep them!"
		@secondary-cta="closeModal"
		title="Are you sure?"
		@cta="deleteCustomer"
		actionColor="danger"
		:loading="loading"
		secondaryActionColor="light"
	>
		<div class="modal-subheading para-2">{{ this.bodyText }}</div>
	</AppModal>
</template>

<script>
	import axios from 'axios'
	import { mapGetters } from 'vuex'

	export default {
		props: {
			userFanId: { type: Number },
			fanName: { type: String },
			hasActiveSubscription: { type: Boolean },
			hasUpcomingAppointments: { type: Boolean },
		},
		data() {
			return {
				loading: false,
			}
		},
		computed: {
			...mapGetters('Auth', ['user']),
			bodyText() {
				if (this.hasActiveSubscription) {
					return `${this.fanName} has active subscription(s). Are you sure you want to delete them? You cannot undo this action.`
				}
				return `Are you sure you want to delete ${this.fanName}? You cannot undo this action.`
			},
		},
		methods: {
			async deleteCustomer() {
				this.loading = true
				try {
					await axios.delete(`v1/users/fans/${this.userFanId}`)
					this.$stanAnalytics('creator-customer-deleted', {
						meta: { user_id: this.userId, username: this.username },
						props: {
							userFanId: this.userFanId,
						},
					})
					this.$stanNotify({
						type: 'success',
						title: 'Success',
						text: `The customer was deleted successfully`,
						duration: 5000,
					})
					this.closeModal()
				} catch (err) {
					this.$logError(err)
					this.$stanNotify({
						type: 'error',
						title: this.$t('Something went wrong'),
						text: this.$t('Please contact us at friends@stanwith.me'),
						duration: 5000,
					})
				}
				this.loading = false
			},
			closeModal() {
				$('#delete-customer-modal').modal('hide')
				this.$emit('close')
			},
		},
	}
</script>
