var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "loading-spinner-ripple", class: _vm.sizeClass },
    [
      _c("div", { staticClass: "ripple", class: _vm.sizeClass }, [
        _c("div"),
        _vm._v(" "),
        _c("div")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }