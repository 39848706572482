<template>
	<div class="progress" :style="{ 'background-color': barColor }">
		<div class="progress-bar" :style="{ width: `${barWidth}%`, 'background-color': progressColor }" role="progressbar"></div>
	</div>
</template>
<script>
	export default {
		name: 'AppProgress',
		props: {
			min: { type: Number, default: 0 },
			max: { type: Number, default: 100 },
			value: { type: Number, default: 0 },
			progressColor: { type: String, default: '#6355ff' },
			barColor: { type: String, default: '#e9ecef' },
		},
		computed: {
			barWidth() {
				return Math.max(0, Math.min(100, (100 * (this.value - this.min)) / (this.max - this.min)))
			},
		},
	}
</script>
<style lang="scss" scoped>
	.progress {
		border-radius: 1rem;
	}
</style>
