<template>
	<div>
		<AppModal id="username-update-warning" ariaLabelledby="userUpdateWarningModal" :title="$t('Let\'s confirm!')">
			<div class="px-3 pb-3">
				<div class="d-flex flex-wrap justify-content-center">
					<p class="subheader mx-auto" align="center" style="max-width: 400px;">
						{{ $t("You're about to change your username.") }} <br />{{
							$t('Make sure to update your Stan Store URL and your Affiliate Link:')
						}}
					</p>
				</div>
				<div>
					<div>
						<div class="d-flex flex-wrap justify-content-center mt-3">
							<b>{{ $t('Stan Store') }}</b>
						</div>
						<div class="d-flex flex-wrap justify-content-center">
							<span class="link">{{ storeLink }}</span>
						</div>
						<div class="d-flex flex-wrap justify-content-center mt-2">
							<b>{{ $t('Affiliate Link') }}</b>
						</div>
						<div class="d-flex flex-wrap justify-content-center">
							<span class="link">{{ affiliateLink }}</span>
						</div>
					</div>
				</div>
				<div class="mt-5">
					<AppButton name="save-username-change-modal" class="w-100" size="large" type="submit" @click="cta" data-test-id="submit-button">{{
						$t('Update')
					}}</AppButton>
				</div>
			</div>
		</AppModal>
	</div>
</template>
<script>
	export default {
		props: ['username'],
		methods: {
			cta() {
				this.$emit('cta')
				$('#username-update-warning').modal('hide')
			},
		},
		computed: {
			storeLink() {
				return `${this.siteSettings.noHttpsDomain}${this.username}`
			},
			affiliateLink() {
				return `join.${this.siteSettings.noHttpsDomain}${this.username}`
			},
		},
	}
</script>

<style lang="scss" scoped>
	.subheader {
		font-weight: 500;
		color: var(--stan-blue-6);
	}

	.link {
		cursor: pointer;
		color: var(--stan-primary-primary-color);
	}
</style>
