<template>
	<div class="graph-skeleton row mx-0 justify-content-between align-items-end">
		<span class="animate" style="height: 30px"></span>
		<span class="animate" style="height: 60px"></span>
		<span class="animate" style="height: 150px"></span>
		<span class="animate" style="height: 300px"></span>
		<span class="animate" style="height: 40px"></span>
		<span class="animate" style="height: 160px"></span>
		<span class="animate" style="height: 140px"></span>
		<span class="animate" style="height: 30px"></span>
		<span class="animate" style="height: 10px"></span>
		<span class="animate" style="height: 40px"></span>
		<span class="animate" style="height: 160px"></span>
		<span class="animate" style="height: 140px"></span>
		<span class="animate" style="height: 30px"></span>
		<span class="animate" style="height: 10px"></span>
		<span class="animate" style="height: 200px"></span>
		<span class="animate" style="height: 50px"></span>
		<span class="animate" style="height: 60px"></span>
		<span class="animate" style="height: 20px"></span>
	</div>
</template>

<script>
	export default {
		name: 'GraphSkel',
	}
</script>
<style lang="scss" scoped>
	.skeleton {
		.br {
			border-radius: 8px;
		}
		.w80 {
			width: 80%;
		}
		.card {
			border: 2px solid var(--stan-white);
			box-shadow: 0px 0px 10px 0 var(--stan-boulder-2);
			padding: 30px 40px;
			width: 80%;
			margin: 50px auto;
		}
		.wrapper {
			width: 0px;
			animation: fullView 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
		}
		.profilePic {
			height: 30px;
			width: 30px;
			border-radius: 50%;
		}
		.conSkel {
			height: 10px;
			background: var(--stan-boulder-3);
		}

		@keyframes fullView {
			100% {
				width: 100%;
			}
		}
	}
	.animate {
		animation: shimmer 2s infinite linear;
		background: linear-gradient(to right, var(--stan-blue-8) 4%, var(--stan-blue-8) 25%, #f7f7f7 36%);
		background-size: 1000px 100%;
	}

	@keyframes shimmer {
		0% {
			background-position: -1000px 0;
		}
		100% {
			background-position: 1000px 0;
		}
	}

	.graph-skeleton {
		min-height: 300px;
		@media (max-width: 767px) {
			display: none;
		}
		span {
			// background: var(--stan-boulder-4);
			width: 15px;
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;
			display: block;
		}
		.h10 {
			height: 100px;
		}
		.h20 {
			height: 120px;
		}
		.h30 {
			height: 130px;
		}
		.h40 {
			height: 140px;
		}
		.h50 {
			height: 150px;
		}
		.h60 {
			height: 160px;
		}
		.h70 {
			height: 170px;
		}
		.h80 {
			height: 180px;
		}
		.h90 {
			height: 190px;
		}
		.h100 {
			height: 200px;
		}
	}
</style>
