// eslint-disable-next-line import/extensions
import store from '~/store'

export default async (to, from, next) => {
	if (!store.getters['Auth/check'] && Object.keys(store.getters['Auth/accounts']).length > 0) {
		// Their is no user logged in, try to switch to an existing logged in user
		next({ name: 'switch_account', params: { accountKey: Object.keys(store.getters['Auth/accounts'])[0] } })
	} else if (!store.getters['Auth/check']) {
		sessionStorage.setItem('redirectTo', to.fullPath)
		next({ name: 'login' })
	} else {
		next()
	}
}
