var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      key: _vm.nextTick,
      attrs: {
        id: "edit-funnel-modal",
        title: _vm.$t("Funnel Details"),
        ariaLabelledby: "editFunnel",
        subheading: _vm.$t("Customize the name and URL of your Funnel"),
        action: _vm.$t("Save"),
        loading: _vm.isLoading,
        disableAction: _vm.disabled
      },
      on: { cta: _vm.saveStore, close: _vm.reset }
    },
    [
      _c(
        "div",
        { staticClass: "pb-3" },
        [
          _c("AppInput", {
            staticClass: "form-group",
            attrs: {
              label: _vm.$t("Name"),
              placeholder: _vm.$t("What would you like to name your Funnel?"),
              name: "funnelName"
            },
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = $$v
              },
              expression: "name"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("AppInput", {
                staticClass: "form-group mb-1",
                attrs: {
                  label: _vm.$t("Funnel URL"),
                  error: _vm.urlError,
                  id: "slug",
                  inputClass: "text-truncate",
                  placeholder: _vm.funnelSlug
                },
                on: { blur: _vm.checkURL },
                model: {
                  value: _vm.slug,
                  callback: function($$v) {
                    _vm.slug = $$v
                  },
                  expression: "slug"
                }
              }),
              _vm._v(" "),
              _c("small", { staticClass: "funnel-url para-3" }, [
                _vm._v(
                  _vm._s(`${_vm.siteSettings.frontDomain}${_vm.username}/`)
                ),
                _c("span", [_vm._v(_vm._s(_vm.slug))])
              ])
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }