import axios from 'axios'
import lodash from 'lodash'
import { configSettings } from '~/plugins/config-settings.js'
import stanAnalytics from '~/plugins/stan-analytics.js'
// import sampleCourse from '~/assets/sampleCourse'

export const state = {
	messages: {},
	answers: {},
	productDescriptions: {},
	chatId: null,
}

export const getters = {
	getMessages: state => state.messages,
	getChatId: state => state.chatId,
	getAnswers: state => state.answers,
	getProductDescriptions: state => state.productDescriptions,
}

export const mutations = {
	addMessage(state, payload) {
		const timestampId = payload.timestampId
		if (!timestampId) {
			throw new Error(`timestampId is required: ${timestampId}`)
		}

		state.messages[timestampId] = {
			question: payload,
			answer: { status: 'waiting', role: 'assistant' },
		}
	},
	setAnswers(state, payload) {
		const timestampId = payload.timestampId
		const answers = lodash.cloneDeep(state.answers) // we need this to trigger vuex update. Otherwise, updates by reference don't trigger listeners
		answers[timestampId] = payload
		state.answers = answers
	},
	setProductDescriptions(state, payload) {
		const timestampId = payload.timestampId
		const productDescriptions = lodash.cloneDeep(state.productDescriptions) // we need this to trigger vuex update. Otherwise, updates by reference don't trigger listeners
		productDescriptions[timestampId] = payload
		state.productDescriptions = productDescriptions
	},
	setChatId(state, payload) {
		state.chatId = payload
	},
}

export const actions = {
	actionSendMessage({ commit, rootState, getters, dispatch }, payload) {
		const inputQuestion = payload.inputQuestion.trim()
		const user = rootState.Auth.user
		const userId = user.user_id
		const username = user.username
		const timestampId = payload.timestampId
		const handler = payload.handler
		const pollInterval = payload.pollInterval || 1000
		let chatId = getters.getChatId

		if (inputQuestion.trim() === '') {
			console.error('inputQuestion is empty')
			return
		}

		const message = {
			role: 'user',
			content: inputQuestion,
			timestampId,
		}

		if (handler) message.handler = handler

		commit('addMessage', message)

		try {
			if (chatId === -1) chatId = null

			const payload = {
				chat_id: chatId,
				user_id: userId,
				question: `${inputQuestion}`,
			}
			if (handler) payload.handler = handler
			axios.post('/v1/chat-ai/ask-question', payload, { baseURL: configSettings.chatAIApiUrl }).then(response => {
				const currentRequestUUID = response.data.request_uuid
				stanAnalytics('stanley-question-asked', {
					meta: { user_id: userId, username },
					props: { ...payload, ...{ question_uuid: currentRequestUUID } },
				})
				let pollingCounter = 0
				const pollGetResponse = setInterval(() => {
					if (pollingCounter > 250) {
						// Requst timed out
						const answer = { timestampId, status: 'timeout' }
						commit('setAnswers', answer)
						clearInterval(pollGetResponse)
						return
					}

					if (getters.getAnswers[message.timestampId]) {
						// Received the response - stop polling
						clearInterval(pollGetResponse)
						return
					}
					dispatch('actionPollAnswer', { currentRequestUUID, userId, timestampId: message.timestampId })
					pollingCounter++
				}, pollInterval)
			})
		} catch (e) {
			console.error(e)
			clearInterval(this.pollGetResponse)
		}
	},
	actionPollAnswer({ commit }, payload) {
		const timestampId = payload.timestampId
		const userId = payload.userId
		const currentRequestUUID = payload.currentRequestUUID
		const apiPayload = {
			request_uuid: currentRequestUUID,
			user_id: userId,
		}
		const answer = {
			timestampId,
		}
		axios
			.post('/v1/chat-ai/get-response', apiPayload, {
				baseURL: configSettings.chatAIApiUrl,
			})
			.then(response => {
				if (response && response.data && response.data.status === 'Completed') {
					if (!response.data?.result?.answer) {
						// Case when request completes but answer is not ready due to lambda errors
						answer.status = 'error'
						commit('setAnswers', answer)
					}
					stanAnalytics('stanley-question-answered', {
						meta: { user_id: userId },
						props: { question_uuid: currentRequestUUID },
					})
					const data = response.data.result
					if (data.response_id && data.answer) {
						answer.status = 'success'
						answer.response_id = data.response_id
						answer.content = data.answer
						answer.feedback_status = data.feedback_status

						// // TESTING TO BE REMOVED
						// data.handler = {
						// 	name: 'course-builder',
						// 	params: {
						// 		course: sampleCourse,
						// 	},
						// }
						// // TESTING TO BE REMOVED // END

						if (data.handler) answer.handler = data.handler

						commit('setChatId', data.chat_id)
						commit('setAnswers', answer)
					}
				} else if (response && response.data && response.data.status === 'Error') {
					answer.status = 'error'
					commit('setAnswers', answer)
				}
			})
			.catch(error => {
				console.error(error)
			})
	},
	actionGenerateProductDescription({ commit, rootState, getters, dispatch }, payload) {
		const user = rootState.Auth.user
		const userId = user.user_id
		const username = user.username
		const productTitle = payload.productTitle
		const timestampId = payload.timestampId
		const pollInterval = payload.pollInterval || 1000
		const prompt = payload.prompt || ''

		try {
			const payload = {
				user_id: userId,
				product_title: productTitle,
				user_prompt: prompt,
			}
			axios.post('/v1/chat-ai/get-product-description', payload, { baseURL: configSettings.chatAIApiUrl }).then(response => {
				const currentRequestUUID = response.data.request_uuid
				stanAnalytics('stanley-product-description-requested', {
					meta: { user_id: userId, username },
					props: { ...payload, ...{ question_uuid: currentRequestUUID } },
				})
				let pollingCounter = 0
				const pollGetResponse = setInterval(() => {
					if (pollingCounter > 250) {
						// Requst timed out
						const productDescription = { timestampId, status: 'timeout' }
						commit('setProductDescription', productDescription)
						clearInterval(pollGetResponse)
						return
					}

					if (getters.getProductDescriptions[timestampId]) {
						// Received the response - stop polling
						clearInterval(pollGetResponse)
						return
					}
					dispatch('actionPollProductDescription', { currentRequestUUID, userId, timestampId })
					pollingCounter++
				}, pollInterval)
			})
		} catch (e) {
			console.error(e)
			clearInterval(this.pollGetResponse)
		}
	},
	actionPollProductDescription({ commit }, payload) {
		const timestampId = payload.timestampId
		const userId = payload.userId
		const currentRequestUUID = payload.currentRequestUUID
		const apiPayload = {
			request_uuid: currentRequestUUID,
			user_id: userId,
		}
		const productDescription = {
			timestampId,
		}
		axios
			.post('/v1/chat-ai/get-response', apiPayload, {
				baseURL: configSettings.chatAIApiUrl,
			})
			.then(response => {
				if (response && response.data && response.data.status === 'Completed') {
					if (!response.data?.result?.product_description) {
						// Case when request completes but answer is not ready due to lambda errors
						productDescription.status = 'error'
						commit('setProductDescription', productDescription)
					}
					stanAnalytics('stanley-product-description-viewed', {
						meta: { user_id: userId },
						props: { ...payload, ...{ generation_id: this.aiGenerationId } },
					})
					const data = response.data.result
					if (data.generation_id && data.product_description) {
						productDescription.status = 'success'
						productDescription.product_description = data.product_description
						productDescription.generation_id = data.generation_id
						commit('setProductDescriptions', productDescription)
					}
				} else if (response && response.data && response.data.status === 'Error') {
					productDescription.status = 'error'
					commit('setProductDescriptions', productDescription)
				}
			})
			.catch(error => {
				console.error(error)
			})
	},
}
