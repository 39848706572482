<template>
	<div class="saving-status" :class="{ saved: saveState.isSaving }">
		<div class="saving-icon">
			<InlineSvg v-if="saveState.isSaving" class="saving rotating" src="/images/icons/ic-reload.svg"></InlineSvg>
			<InlineSvg v-else-if="saveState.isSaved" class="saved" src="/images/icons/ic-check-circle.svg"></InlineSvg>
		</div>
		<div class="saving-text">
			<p v-if="saveState.isSaving" class="saving para-2">Saving...</p>
			<p v-else-if="saveState.isSaved" class="saved para-2">Saved</p>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({}),
		props: {
			saveState: {
				type: Object,
				required: true,
				validator(value) {
					if (typeof value !== 'object') {
						return false
					}

					const propKeys = Object.keys(value)
					return propKeys.includes('isSaving') && propKeys.includes('isSaved')
				},
			},
		},
	}
</script>

<style scoped lang="scss">
	$fadeInDuration: 0.3s;

	.saving-status {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 0.25rem;

		p {
			margin: 0;
		}

		.saved {
			opacity: 0;
			animation: opacityOnThenOff 2s ease-in-out;
		}

		.saving {
			opacity: 1;
			animation: opacityOn $fadeInDuration ease-in-out;
		}
	}

	.saving-text {
		.saving {
			color: var(--stan-grey-1);
		}

		.saved {
			color: var(--stan-primary-primary-color);
		}
	}

	.saving-icon {
		width: 20px;
		height: auto;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 100%;
			height: auto;
		}

		.saved::v-deep path {
			fill: var(--stan-primary-primary-color);
		}

		.rotating {
			animation: rotate 2s ease-in-out infinite;
			animation-delay: calc($fadeInDuration / 2);
		}
	}

	@keyframes rotate {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}

	@keyframes opacityOn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes opacityOnThenOff {
		0%,
		95% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}
</style>
