var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "progress" }, [
    _c("div", {
      staticClass: "progress-bar",
      style: { width: `${_vm.progress}%` },
      attrs: { role: "progressbar" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }