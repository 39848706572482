<template>
	<div>
		<StanleyTyping :text="text">
			<div class="mt-2">
				<AppButton name="profile-image-prompt-update-profile-image-button" outline @click="action" size="md">Update Profile Image</AppButton>
			</div>
		</StanleyTyping>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'

	export default {
		data() {
			return {
				textO: '',
			}
		},
		methods: {
			action() {
				this.$router.push({ path: '/profile' })
			},
		},
		computed: {
			...mapGetters('Auth', ['user']),
			text() {
				return `First things first. Why don't we start by updating your profile picture?`
			},
		},
	}
</script>

<style lang="scss" scoped></style>
