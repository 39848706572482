var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "position-relative" }, [
    _c("div", { staticClass: "order-bump-section" }, [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center justify-content-start enable-reminders"
        },
        [
          _c(
            "div",
            { staticClass: "reminder-toggle" },
            [
              _c("AppToggle", {
                model: {
                  value: _vm.value.email_reminder_enabled,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "email_reminder_enabled", $$v)
                  },
                  expression: "value.email_reminder_enabled"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [_c("InlineSvg", { attrs: { src: "/images/email-icon.svg" } })],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c("label", { staticClass: "text-bold mr-2 mb-0" }, [
              _vm._v(_vm._s(_vm.$t("Email Reminders")))
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "w-100 d-block mb-2 hint-text" }, [
              _vm._v(" Send a reminder email before specified times.")
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.value.email_reminder_enabled
        ? _c("div", [
            _c(
              "div",
              { staticClass: "d-flex justify-content-start email-settings" },
              [
                _c(
                  "div",
                  {
                    staticClass: "form-group mb-3 w-100",
                    class: { "form-error": _vm.validation.email_subject.$error }
                  },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("Subject")))
                    ]),
                    _vm._v(" "),
                    _c("TipTapEditor", {
                      key: _vm.editorTick,
                      staticClass: "tiptap-editor",
                      attrs: {
                        height: 28,
                        hideMenuBar: true,
                        variableItemsData: _vm.variablesSubject,
                        defaultValue: _vm.templateSubject,
                        enableDrop: false,
                        enableYoutube: false
                      },
                      model: {
                        value: _vm.value.email_subject,
                        callback: function($$v) {
                          _vm.$set(_vm.value, "email_subject", $$v)
                        },
                        expression: "value.email_subject"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "form-group mb-3 w-100",
                    class: { "form-error": _vm.validation.email_body.$error }
                  },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("Body")))
                    ]),
                    _vm._v(" "),
                    _c("TipTapEditor", {
                      key: _vm.editorTick,
                      staticClass: "tiptap-editor flexible",
                      attrs: {
                        extended: true,
                        showImage: false,
                        enableYoutube: false,
                        variableItemsData: _vm.variablesBody,
                        defaultValue: _vm.templateBody
                      },
                      model: {
                        value: _vm.value.email_body,
                        callback: function($$v) {
                          _vm.$set(_vm.value, "email_body", $$v)
                        },
                        expression: "value.email_body"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex email-timings" },
              [
                _c(
                  "div",
                  { class: { "form-error": _vm.validation.timings.$error } },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("Timing")))
                    ])
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.value.timings, function(timing, i) {
                  return _c(
                    "div",
                    { key: i, staticClass: "timing" },
                    [
                      _c("AppInput", {
                        class: {
                          "form-error":
                            _vm.validation.timings.$each[i].offset.$error
                        },
                        attrs: { type: "number" },
                        on: { blur: _vm.validation.$touch },
                        model: {
                          value: _vm.value.timings[i].offset,
                          callback: function($$v) {
                            _vm.$set(_vm.value.timings[i], "offset", $$v)
                          },
                          expression: "value.timings[i].offset"
                        }
                      }),
                      _vm._v(" "),
                      _c("AppDropdown", {
                        staticClass: "form-control",
                        class: {
                          "form-error":
                            _vm.validation.timings.$each[i].unit.$error
                        },
                        attrs: {
                          searchable: false,
                          options: [
                            { label: "minute(s) before", value: "minutes" },
                            { label: "hour(s) before", value: "hours" },
                            { label: "day(s) before", value: "days" }
                          ],
                          reduce: "value"
                        },
                        on: { blur: _vm.validation.$touch },
                        model: {
                          value: _vm.value.timings[i].unit,
                          callback: function($$v) {
                            _vm.$set(_vm.value.timings[i], "unit", $$v)
                          },
                          expression: "value.timings[i].unit"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "p-0",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.removeTiming(i)
                              }
                            }
                          },
                          [
                            _c("InlineSvg", {
                              staticClass: "cursor-pointer delete-icon",
                              attrs: { src: "/images/form-fields/delete.svg" }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _c(
                  "AppButton",
                  {
                    staticClass: "w-100",
                    attrs: {
                      name: "add-reminder-meeting-reminder-form",
                      outline: ""
                    },
                    on: { click: _vm.addNewTiming }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.$t("+ Add Reminder")) +
                        "\n\t\t\t\t"
                    )
                  ]
                )
              ],
              2
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }