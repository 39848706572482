<template>
	<div v-if="!isMobile" class="container">
		<div class="body flex-grow-1 col-12 col-md-5 px-0">
			<div>
				<div class="modal-body w-full py-0 d-flex flex-column align-items-center">
					<h3 class="modal-heading">Yay! FREE month applied! ✅</h3>
					<div class="modal-subheading">
						You now have an extra 30 days to see the full value of Stan. Check your email for details.
					</div>
				</div>
				<div class="d-flex flex-column  align-items-center  gap v-gap pt-1">
					<AppButton name="go-back-one-free-month-accepted-modal" size="large" @click="nextStep">Go back to account</AppButton>
				</div>
			</div>
		</div>

		<div class="body flex-grow-1 col-12 col-md-5 px-0">
			<div v-if="!imageReady" class="image-placeholder" />
			<img v-show="imageReady" src="/images/cancellation/calendar.svg" @load="imageReady = true" />
		</div>
	</div>
	<div v-else class="container mobile-container d-flex justify-content-center align-items-center">
		<div class="body flex-column flex-gap-50 h-100">
			<div class="body flex-shrink-1 px-0">
				<div v-if="!imageReady" class="image-placeholder" />
				<img v-show="imageReady" src="/images/cancellation/calendar.svg" @load="imageReady = true" />
			</div>
			<div class="body px-0">
				<div>
					<div class="modal-body w-full py-0 d-flex flex-column align-items-center">
						<h3 class="modal-heading">Yay! FREE month applied! ✅</h3>
						<div class="modal-subheading">
							You now have an extra 30 days to see the full value of Stan. Check your email for details.
						</div>
					</div>
					<div class="d-flex flex-column  align-items-center  gap v-gap pt-1">
						<AppButton name="go-back-one-free-month-accepted-modal" size="large" @click="nextStep">Go back to account</AppButton>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'

	export default {
		components: {},
		props: {},
		data() {
			return {
				stepName: 'offer one free month accepted',
				offerName: 'connect with stan team member',
				imageReady: false,
			}
		},
		mounted() {
			this.start()
		},
		computed: {
			...mapGetters('Auth', ['user']),
			isMobile() {
				return this.$commonHelper.isMobile()
			},
		},
		methods: {
			start() {
				this.$emit('start', {
					eventName: 'free-one-month-offer-confirmation-viewed',
					props: { step_name: this.stepName, offer_name: this.offerName },
				})
			},
			nextStep() {
				this.$emit('next-step')
			},
			reset() {
				this.imageReady = false
			},
		},
	}
</script>

<style lang="scss" scoped></style>
