<template>
	<AppModal id="dripSettingModuleModal" title="Drip Module" @close="closeModal">
		<div class="mb-2">
			<div class="sub-heading">
				<span>{{ $t('Your customers will be given access to published modules after a set number of days') }}</span>
			</div>
		</div>
		<div class="form-group mb-4">
			<AppInput
				:label="$t('Drip Days After Enrollment')"
				v-model="temp_drip_settings.days"
				:placeholder="$t('Ex. 4')"
				:id="`dripDays`"
				type="number"
				:class="{ 'form-error': $v.temp_drip_settings.days.$invalid }"
			/>
			<span v-if="!$v.temp_drip_settings.days.validDateRange" class="stan-text-danger para-3">
				Please enter a number between 0 and 60
			</span>
		</div>

		<div class="mb-2 d-flex align-items-top">
			<label class="control-label mr-2">{{ $t('Update Customers About Drip Module') }}</label>
			<AppToggle v-model="temp_drip_settings.email_notification" />
		</div>
		<div class="form-group mb-2" v-if="temp_drip_settings.email_notification">
			<label class="control-label">{{ $t('Subject') }}</label>
			<TipTapEditor
				v-model="temp_drip_settings.subject"
				class="tiptap-editor mb-3"
				:height="28"
				:hideMenuBar="true"
				:variableItemsData="variables_subject"
				:enableDrop="false"
				:enableYoutube="false"
				:class="{ 'form-error': $v.temp_drip_settings.subject.$invalid }"
			/>
		</div>
		<div class="form-group mb-2" v-if="temp_drip_settings.email_notification">
			<label class="control-label">{{ $t('Body') }}</label>
			<TipTapEditor
				v-model="temp_drip_settings.email_template"
				class="tiptap-editor flexible"
				:extended="true"
				:showImage="false"
				:enableYoutube="false"
				:showLink="false"
				:variableItemsData="variables_body"
				:class="{ 'form-error': $v.temp_drip_settings.email_template.$invalid }"
			/>
		</div>
		<div class="d-flex flex-wrap justify-content-end mt-3">
			<AppButton name="save-drip-setting-modal" class="py-2 md-flat ml-2 mt-1" style="width: 100px" @click="update">{{
				$t('Confirm')
			}}</AppButton>
		</div>
	</AppModal>
</template>

<script>
	// eslint-disable-next-line import/extensions
	import { required, between, or } from 'vuelidate/lib/validators'
	import TipTapEditor from '~/components/shared/TipTapEditor'

	export default {
		components: {
			TipTapEditor,
		},
		props: {
			module: {
				type: Object,
			},
			variables_subject: {
				type: Array,
				default: () => [],
			},
			variables_body: {
				type: Array,
				default: () => [],
			},
		},
		mounted() {
			$('#dripSettingModuleModal').on('shown.bs.modal', () => {
				this.temp_drip_settings = JSON.parse(JSON.stringify(this.module.data.drip_settings))
			})
		},
		watch: {
			'module.data.drip_settings': {
				deep: true,
				immediate: true,
				handler(newVal) {
					if (newVal) {
						this.drip_settings = newVal
						this.resetTempDripSettings()
					}
				},
			},
		},
		methods: {
			update() {
				if (this.$v.temp_drip_settings.$invalid) {
					this.$stanNotify({
						type: 'error', // can be 'info', 'warning', 'success', 'error'
						title: 'Wait up!',
						text: 'Please provide all valid information.',
						duration: 5000,
					})

					return
				}
				this.drip_settings = { ...this.temp_drip_settings }
				this.$emit('drip-confirmed', this.drip_settings)
				$('#dripSettingModuleModal').modal('hide')
			},
			closeModal() {
				this.resetTempDripSettings()
				$('#dripSettingModuleModal').modal('hide')
			},
			resetTempDripSettings() {
				this.temp_drip_settings = { ...this.drip_settings }
			},
		},
		data() {
			return {
				drip_settings: {
					days: 1,
					email_notification: false,
					subject: '',
					email_template: '',
				},
				temp_drip_settings: {
					days: 1,
					email_notification: false,
					subject: '',
					email_template: '',
				},
			}
		},
		validations() {
			return {
				temp_drip_settings: {
					days: {
						required,
						validDateRange: or(between(0, 60)),
					},
					email_notification: {
						required,
					},
					subject: {
						required,
					},
					email_template: {
						required,
					},
				},
			}
		},
	}
</script>

<style lang="scss" scoped>
	.sub-heading {
		width: 100%;
		font-size: 14px;
		font-weight: 400;
		line-height: 19px;
		letter-spacing: 0em;
		text-align: center;
		color: var(--stan-blue-5);
	}
	.footnote {
		font-size: 10px;
		font-weight: 400;
		line-height: 14px;
		letter-spacing: 0em;
		text-align: left;
		color: var(--stan-blue-5);
	}
</style>
