<template>
	<tbody>
		<tr v-for="(row, index) in lines" :key="index">
			<td colspan="7">
				<Skeleton class="skeleton-row" :heading="false" :lines="1" />
			</td>
		</tr>
	</tbody>
</template>

<script>
	import Skeleton from '~/components/skeletons/Skeleton'

	export default {
		components: {
			Skeleton,
		},
		props: {
			lines: { type: Number, default: 10 },
			colspan: { type: Number, default: 0 },
		},
	}
</script>
<style lang="scss" scoped>
	tr {
		height: 50px;
	}
	td {
		padding: 0.25rem;
	}
	.skeleton-row::v-deep {
		.vue-content-placeholders-text__line:nth-child(4n + 1) {
			width: 100% !important;
		}
	}
</style>
