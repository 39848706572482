// eslint-disable-next-line import/extensions
import store from '~/store'

export default (to, from, next) => {
	if (store.getters['Auth/check'] && store.getters['Auth/user'].list_of_roles.indexOf(1) === -1) {
		next({ name: 'dashboard' })
	} else {
		next()
	}
}
