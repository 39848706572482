var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("notifications", {
    style: _vm.notificationStyle,
    attrs: {
      position: _vm.notificationPosition,
      "animation-type": "css",
      "animation-name": "v-fade-top",
      width: "auto"
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function({ item, close }) {
          return [
            _c("StanNotification", {
              attrs: { data: item },
              on: { close: close }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }