var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ai-prompt-box" },
    [
      _c("div", [
        _c("div", { staticClass: "ai-prompt-heading" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.$t("Generate with AI")) + "\n\t\t")
        ])
      ]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.prompt,
            expression: "prompt"
          }
        ],
        staticClass: "ai-prompt-textarea",
        attrs: {
          maxlength: "800",
          id: "ai-prompt-textarea",
          placeholder:
            "Briefly describe your product and what makes it special to your audience..."
        },
        domProps: { value: _vm.prompt },
        on: {
          input: function($event) {
            if ($event.target.composing) return
            _vm.prompt = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "ai-prompt-text-count" }, [
        _c("div", [_vm._v(_vm._s(_vm.promptCount) + "/800")])
      ]),
      _vm._v(" "),
      _c(
        "AppButton",
        {
          staticClass: "ai-prompt-button",
          attrs: {
            disabled: _vm.promptCount < 1,
            type: "button",
            icon: "images/icons/ic-ai-generate.svg"
          },
          on: { click: _vm.requestAIAssistance }
        },
        [_vm._v(_vm._s(_vm.$t("Generate")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }