<template>
	<AppModal
		id="store-customizing-modal"
		@close="closeModal"
		:action="$t('Start Customizing')"
		@cta="closeModal"
		:title="$t('It’s time to start customizing your products.')"
		:subheading="$t('Select one of your pre-built products to edit the details, or, +Add Product to explore other product types.')"
	>
		<img src="/images/customize-products.png" class="img-fluid mb-2" />
	</AppModal>
</template>

<script>
	export default {
		methods: {
			closeModal() {
				$('#store-customizing-modal').modal('hide')
				this.$emit('close')
			},
		},
	}
</script>
