var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { preview: _vm.preview },
      attrs: { id: "course-preview-wrapper" }
    },
    [
      _c(
        "div",
        { staticClass: "content-list" },
        [
          _vm._l(_vm.toc, function(item) {
            return _c("div", { staticClass: "content-item" }, [
              !item.drip
                ? _c(
                    "div",
                    { staticClass: "item-icon" },
                    [
                      _c("Icon", {
                        staticClass: "icon-svg",
                        attrs: { name: "play", scale: 0.5 }
                      })
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "item-icon" },
                    [
                      _c("Icon", {
                        staticClass: "icon-svg",
                        attrs: { name: "lock-solid", scale: 0.5 }
                      })
                    ],
                    1
                  ),
              _vm._v(" "),
              _c("div", { staticClass: "item-title" }, [
                _vm._v(_vm._s(item.title))
              ])
            ])
          }),
          _vm._v(" "),
          _vm.tocCount > 3
            ? _c("div", { staticClass: "content-item" }, [
                _c(
                  "div",
                  { staticClass: "item-icon" },
                  [
                    _c("Icon", {
                      staticClass: "icon-svg",
                      attrs: { name: "lock-solid", scale: 0.5 }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-title" }, [
                  _vm._v("+ " + _vm._s(_vm.tocCount - 3) + " More")
                ])
              ])
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }