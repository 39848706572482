var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reviews" },
    _vm._l(_vm.reviews, function(review, index) {
      return _c(
        "div",
        { key: index, staticClass: "item" },
        [
          _c("star-rating", {
            attrs: {
              "star-size": 12,
              increment: 0.5,
              "max-rating": 5,
              "show-rating": false,
              "read-only": true,
              "active-color": "#FEC337",
              animate: true
            },
            model: {
              value: review.rating,
              callback: function($$v) {
                _vm.$set(review, "rating", $$v)
              },
              expression: "review.rating"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "image-star-rating d-none",
              style: { "--avg-rating": review.rating }
            },
            [
              _vm._l(Math.ceil(review.rating), function(index) {
                return _c(
                  "svg",
                  {
                    attrs: {
                      width: "13",
                      height: "13",
                      viewBox: "0 0 13 13",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M6.6574 0L8.75579 4.11181L13.3148 4.83688L10.0527 8.10319L10.7719 12.6631L6.6574 10.57L2.5429 12.6631L3.26212 8.10319L0 4.83688L4.559 4.11181L6.6574 0Z",
                        fill: "#FEC337"
                      }
                    })
                  ]
                )
              }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(review.rating))])
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "heading" },
            [
              review.image
                ? _c("image-preview", {
                    attrs: {
                      source: review.image,
                      width: 35,
                      height: 35,
                      staticClass: "image",
                      alt: "review image"
                    }
                  })
                : _c("div", { staticClass: "image" }, [
                    _c("img", {
                      staticClass: "image",
                      attrs: {
                        src:
                          "https://assets.stanwith.me/graphics/icons/new/icon-review-user.jpg",
                        alt: "reviewer frame"
                      }
                    })
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "title-rating-wrapper" }, [
                _c("p", [_vm._v(_vm._s(review.name))])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "review-description" }, [
            _vm._v("\n\t\t\t" + _vm._s(review.description) + "\n\t\t")
          ])
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }