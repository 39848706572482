var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.offValue && _vm.amount && _vm.saleAmount
    ? _c("div", { staticClass: "product-price-off" }, [
        _c("span", [_vm._v(_vm._s(_vm.offValue))]),
        _vm._v(" "),
        _c("span", [_vm._v("OFF")])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }