<template>
	<div class="loading-spinner-ripple" :class="sizeClass">
		<div class="ripple" :class="sizeClass">
			<div></div>
			<div></div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'AppRipple',
		props: {
			size: { type: String, default: 'lg' }, // sm/small, lg/large
		},
		computed: {
			sizeClass() {
				return this.size === 'sm' ? 'ripple-sm' : 'ripple-lg'
			},
		},
	}
</script>
<style lang="scss" scoped>
	@keyframes ripple {
		0% {
			top: 51.6px;
			left: 51.6px;
			width: 0;
			height: 0;
			opacity: 1;
		}
		100% {
			top: 1.8px;
			left: 1.8px;
			width: 99.6px;
			height: 99.6px;
			opacity: 0;
		}
	}

	.loading-spinner-ripple {
		width: 120px;
		height: 120px;
		display: inline-block;
		overflow: hidden;
		background: none;
		&.ripple-sm {
			zoom: 0.3;
		}
	}

	.ripple {
		width: 100%;
		height: 100%;
		position: relative;
		-webkit-transform: translateZ(0) scale(1);
		transform: translateZ(0) scale(1);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	.ripple div {
		-webkit-box-sizing: content-box;
		box-sizing: content-box;
		position: absolute;
		border-width: 8.4px;
		border-style: solid;
		opacity: 1;
		border-radius: 50%;
		-webkit-animation: ripple 1.2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
		animation: ripple 1.2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	}

	.ripple div:nth-child(1) {
		border-color: #6355ff;
		-webkit-animation-delay: 0s;
		animation-delay: 0s;
	}

	.ripple div:nth-child(2) {
		border-color: #6355ff;
		-webkit-animation-delay: -0.6s;
		animation-delay: -0.6s;
	}
</style>
