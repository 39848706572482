<template>
	<div class="cta-header" :class="{ 'full-width': fullWidth, 'danger-color': dangerColor }" id="cta-header">
		<div class="cta-header-body">
			<div class="cta-header-text">
				<div v-if="iconLeft" class="icon"><InlineSvg class="icon d-none d-md-block" :src="iconLeft" /></div>
				<div class="cursor-pointer d-flex align-items-center" @click="$emit('click')">
					<slot></slot>
					<span v-if="iconRight" class="icon icon-right"><InlineSvg :src="iconRight"/></span>
				</div>
			</div>
			<div v-if="showCloseIcon" class="cursor-pointer close-icon" @click="$emit('close')">
				<InlineSvg src="/images/close.svg" />
			</div>
			<div v-if="buttonText" class="cursor-pointer button-text h4 text-bold " @click="$emit('click')">
				{{ buttonText }}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			userId: {
				type: Number,
			},
			iconLeft: String,
			iconRight: String,
			showCloseIcon: Boolean,
			buttonText: String,
			fullWidth: Boolean,
			dangerColor: Boolean,
		},
	}
</script>

<style lang="scss" scoped>
	.icon::v-deep {
		height: 22px;
		margin-right: 0.25rem;
		svg {
			path {
				fill: var(--stan-white);
			}
		}
	}
	.close-icon {
		margin-right: 5px;
		margin-top: -2px;
	}
	.cta-header {
		position: relative;
		left: 0;
		right: 0;
		background: var(--stan-primary-light-color);
		min-height: 60px;
		z-index: 100;

		&.full-width {
			position: fixed;
			z-index: 101;
		}

		.cta-header-body .cta-header-text {
			color: var(--stan-text-dark-color);
		}

		.icon::v-deep {
			svg {
				path {
					fill: var(--stan-primary-primary-color);
				}
			}
		}

		.button-text {
			color: var(--stan-primary-primary-color);
			margin-right: 0.5rem;
		}

		a {
			color: inital;
		}

		a:link {
			color: var(--stan-primary-primary-color);
		}

		&.danger-color {
			background: var(--stan-negative-light-color);
			position: fixed;
			z-index: 101;

			.cta-header-body .cta-header-text {
				color: var(--stan-negative-primary-color);
			}

			.icon::v-deep {
				svg {
					path {
						fill: var(--stan-negative-primary-color);
					}
				}
			}

			.button-text {
				color: var(--stan-negative-primary-color);
			}
		}
	}
	.cta-header-body {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		padding: 15px 15px 16px 15px;

		@media (min-width: 992px) {
			padding-bottom: 15px;
		}

		.cta-header-text {
			display: flex;
			justify-content: flex-start;
			margin: 0;
			font-style: normal;
			font-size: 16px;
			letter-spacing: -0.005em;
			color: var(--stan-text-dark-color);
			text-align: left;
			flex-grow: 1;
			padding: 0.25rem;
			line-height: 1.3;
		}
		&.account-disable-warning {
			background: var(--stan-yellow-4);
			border: 1px solid var(--stan-yellow-3);
		}
		&.account-disable-danger {
			background: var(--stan-white) 0f5;
			border: 1px solid var(--stan-danger);
		}
		span {
			cursor: pointer;
		}
	}
</style>
