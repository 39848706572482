<template>
	<button
		:name="name"
		:aria-label="name"
		class="app-button button-wrap"
		:class="[
			sizeClass,
			{ rounded: rounded },
			{ disabled: disabled },
			{ outline: outline || link },
			{ loading: loading },
			{ 'hide-outline': link },
			`button-color-${color}`,
			{ 'fit-content': fitContent },
		]"
		:disabled="disabled"
		@click="handleClick"
	>
		<CustomHollowDotsSpinner v-if="loading" :animation-duration="1000" :dot-size="dotSize" :color="loaderColor" class="loader" />
		<div class="button-content" :class="[contentSizeClass, { hide: loading }]">
			<div v-if="icon" class="button-icon">
				<InlineSvg class="icon-svg" :src="icon" />
			</div>
			<slot class="no-wrap"></slot>
		</div>
	</button>
</template>
<script>
	import { mapGetters } from 'vuex'
	import CustomHollowDotsSpinner from '~/stan-vue-shared/components/HollowDotsSpinner'

	export default {
		name: 'AppButton',
		components: { CustomHollowDotsSpinner },
		props: {
			name: { type: String, default: '' }, // For Analytics Purpose
			disabled: { type: Boolean, default: false },
			outline: { type: Boolean, default: false },
			link: { type: Boolean, default: false },
			rounded: { type: Boolean, default: false },
			size: { type: String, default: 'md' }, // sm/small, md/medium, lg/large, xl
			loading: { type: Boolean, default: false },
			icon: { type: String, default: '' },
			color: {
				type: String,
				default: 'primary',
				validator: value => ['primary', 'dark', 'warning', 'danger', 'gray', 'light'].includes(value),
			},
			fitContent: { type: Boolean, default: false },
		},
		data() {
			return {
				contentWidth: 10,
				sizeMapping: {
					default: {
						dotSize: 10,
						sizeClass: 'button-md',
						contentSizeClass: 'h5 text-bold',
					},
					xs: {
						dotSize: 5,
						sizeClass: 'button-xs',
						contentSizeClass: 'para-3 text-bold',
					},
					xtrasmall: {
						dotSize: 5,
						sizeClass: 'button-xs',
						contentSizeClass: 'para-3 text-bold',
					},
					sm: {
						dotSize: 7,
						sizeClass: 'button-sm',
						contentSizeClass: 'para-3 text-bold',
					},
					small: {
						dotSize: 7,
						sizeClass: 'button-sm',
						contentSizeClass: 'para-3 text-bold',
					},
					md: {
						dotSize: 10,
						sizeClass: 'button-md',
						contentSizeClass: 'h5 text-bold',
					},
					medium: {
						dotSize: 10,
						sizeClass: 'button-md',
						contentSizeClass: 'h5 text-bold',
					},
					lg: {
						dotSize: 12,
						sizeClass: 'button-lg',
						contentSizeClass: 'h4 text-bold',
					},
					large: {
						dotSize: 12,
						sizeClass: 'button-lg',
						contentSizeClass: 'h4 text-bold',
					},
					xl: {
						dotSize: 14,
						sizeClass: 'button-xl',
						contentSizeClass: 'h3 text-bold',
					},
				},
			}
		},
		computed: {
			...mapGetters('Auth', ['user']),
			loaderColor() {
				if (!this.outline) return 'var(--stan-white)'

				switch (this.color) {
					case 'danger':
						return 'var(--stan-negative-primary-color)'
					case 'dark':
						return 'var(--stan-primary-dark-color)'
					case 'light':
						return 'var(--stan-text-light-color)'
					default:
						return 'var(--stan-primary-primary-color)'
				}
			},
			dotSize() {
				return this.sizeMapping[this.size]?.dotSize ?? this.sizeMapping.default.dotSize
			},
			sizeClass() {
				return this.sizeMapping[this.size]?.sizeClass ?? this.sizeMapping.default.sizeClass
			},
			contentSizeClass() {
				return this.sizeMapping[this.size]?.contentSizeClass ?? this.sizeMapping.default.contentSizeClass
			},
		},
		mounted() {},
		methods: {
			handleClick() {
				if (!this.disabled && !this.loading) {
					if (this.name) {
						this.$stanAnalytics('admin-button-click', {
							meta: { user_id: this.user?.user_id, username: this.user?.username },
							props: {
								button_name: this.name,
							},
						})
					}

					this.$emit('click')
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	@mixin outlineButton($mainColor, $hoverColor, $disabledColor) {
		background-color: transparent;
		color: $mainColor;
		border-color: $mainColor;

		&:hover {
			background-color: $hoverColor;
		}

		&.disabled {
			pointer-events: none;
			color: $disabledColor;
			border-color: $disabledColor;
			.button-content {
				.button-icon {
					::v-deep {
						.icon-svg {
							path {
								fill: $disabledColor !important;
							}
						}
					}
				}
			}
		}

		.button-content {
			.button-icon {
				::v-deep {
					.icon-svg {
						path {
							fill: $mainColor;
						}
					}
				}
			}
		}

		&.hide-outline {
			border: none;
			.button-content {
				line-height: 1.125rem;
			}

			&:hover {
				background: transparent;
			}
		}
	}

	.button-wrap {
		position: relative;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		border: 1px solid var(--stan-primary-primary-color);
		background-color: var(--stan-primary-primary-color);
		color: var(--stan-white);
		cursor: pointer;

		&.loading {
			pointer-events: none;
		}

		&:hover {
			border-color: var(--stan-primary-strike-color);
			background-color: var(--stan-primary-strike-color);
		}

		&.disabled {
			pointer-events: none;
			border-color: var(--stan-primary-soft-color);
			background-color: var(--stan-primary-soft-color);
			.button-content {
				.button-icon {
					::v-deep {
						.icon-svg {
							path {
								fill: var(--stan-white) !important;
							}
						}
					}
				}
			}
		}

		.button-content {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			line-height: 1em;

			&.hide {
				opacity: 0%;
			}

			.button-icon {
				transform: scale(0.875);
				margin-right: 8px;
				aspect-ratio: 1;

				::v-deep {
					.icon-svg {
						path {
							fill: white;
						}
					}
				}
			}
		}

		&.outline {
			@include outlineButton(var(--stan-primary-primary-color), var(--stan-primary-light-color), var(--stan-primary-soft-color));
		}

		&.button-sm {
			min-width: 100px;
			height: 32px;
			padding: 8px 11px 8px 10px;
			gap: 5px;
			&.rounded {
				border-radius: 16px !important;
			}
			.button-icon {
				::v-deep {
					.icon-svg {
						height: 16px;
						width: 16px;
					}
				}
			}
		}

		&.button-xs {
			height: 32px;
			padding: 8px 11px 8px 10px;
			gap: 5px;
			&.rounded {
				border-radius: 8px !important;
			}
			.button-icon {
				::v-deep {
					.icon-svg {
						height: 15px;
						width: 15px;
					}
				}
			}
		}

		&.button-md {
			min-width: 133px;
			height: 42px;
			padding: 15px;
			gap: 5px;
			&.rounded {
				border-radius: 21px !important;
			}
			.button-icon {
				::v-deep {
					.icon-svg {
						height: 20px;
						width: 20px;
					}
				}
			}
		}

		&.button-lg {
			min-width: 135px;
			height: 50px;
			padding: 15px 20px 15px 18px;
			gap: 8px;
			&.rounded {
				border-radius: 25px !important;
			}
			.button-icon {
				height: 24px;
				width: 24px;
				::v-deep {
					.icon-svg {
						height: 24px;
						width: 24px;
					}
				}
			}
		}

		&.button-xl {
			min-width: 183px;
			height: 60px;
			padding: 20px 25px;
			gap: 5px;
			&.rounded {
				border-radius: 30px !important;
			}
			.button-icon {
				::v-deep {
					.icon-svg {
						height: 28px;
						width: 28px;
					}
				}
			}
		}

		&.fit-content {
			width: fit-content;
			min-width: unset;
		}

		&.button-color-warning {
			background-color: var(--stan-yellow-1);
			border-color: var(--stan-yellow-1);

			&:hover {
				background-color: var(--stan-yellow-1);
				color: #fff;
			}

			&.disabled {
				color: var(--stan-yellow-3);
				border-color: var(--stan-yellow-3);
			}

			.button-content {
				.button-icon {
					::v-deep {
						.icon-svg {
							path {
								fill: var(--stan-yellow-2);
							}
						}
					}
				}
			}
			&.outline {
				@include outlineButton(var(--stan-yellow-2), var(--stan-yellow-4), var(--stan-yellow-3));
			}
		}

		&.button-color-danger {
			background-color: var(--stan-negative-primary-color);
			border-color: var(--stan-negative-primary-color);

			&:hover {
				background-color: var(--stan-negative-strike-color);
				color: #fff;
			}

			&.disabled {
				color: var(--stan-negative-soft-color);
				border-color: var(--stan-negative-soft-color);
			}

			.button-content {
				.button-icon {
					::v-deep {
						.icon-svg {
							path {
								fill: var(--stan-negative-primary-color);
							}
						}
					}
				}
			}
			&.outline {
				@include outlineButton(var(--stan-negative-primary-color), var(--stan-negative-light-color), var(--stan-negative-soft-color));
			}
		}

		&.button-color-dark {
			background-color: var(--stan-primary-dark-color);
			border-color: var(--stan-primary-dark-color);

			&:hover {
				background-color: var(--stan-primary-strike-color);
				border-color: var(--stan-primary-strike-color);
				color: #fff;
			}

			&.disabled {
				color: var(--stan-primary-soft-color);
				border-color: var(--stan-primary-soft-color);
			}

			.button-content {
				.button-icon {
					::v-deep {
						.icon-svg {
							path {
								fill: var(--stan-primary-dark-color);
							}
						}
					}
				}
			}
			&.outline {
				@include outlineButton(var(--stan-primary-dark-color), var(--stan-primary-light-color), var(--stan-primary-soft-color));
			}
		}
		&.button-color-light {
			background-color: var(--stan-text-light-color);
			border-color: var(--stan-text-light-color);

			&:hover {
				background-color: var(--stan-primary-strike-color);
				border-color: var(--stan-primary-strike-color);
				color: #fff;
			}

			&.disabled {
				color: var(--stan-primary-soft-color);
				border-color: var(--stan-primary-soft-color);
			}

			.button-content {
				.button-icon {
					::v-deep {
						.icon-svg {
							path {
								fill: var(--stan-text-light-color);
							}
						}
					}
				}
			}
			&.outline {
				@include outlineButton(var(--stan-text-light-color), var(--stan-primary-light-color), var(--stan-primary-soft-color));
			}
		}
		.loader {
			position: absolute;
		}

		&.button-color-gray {
			background-color: var(--stan-text-light-color);
			border-color: var(--stan-text-light-color);

			&:hover {
				background-color: var(--stan-gray-primary-color);
				border-color: var(--stan-gray-primary-color);
				color: #fff;
			}

			&.disabled {
				color: var(--stan-gray-soft-color);
				border-color: var(--stan-gray-soft-color);
			}

			.button-content {
				.button-icon {
					::v-deep {
						.icon-svg {
							path {
								fill: var(--stan-text-light-color);
							}
						}
					}
				}
			}
			&.outline {
				@include outlineButton(var(--stan-text-light-color), var(--stan-gray-primary-color), var(--stan-gray-soft-color));
			}
		}
	}
</style>
