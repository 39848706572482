<template>
	<AppModal
		ref="modal"
		:title="modalTitle"
		:subheading="modalSubheading"
		aria-labelledby="audienceBulkTaggingModal"
		:overflow="true"
		:loading="loading"
		:action="buttonText"
		secondary-action="Cancel"
		@cta="submitModalForm"
		@secondary-cta="hide"
	>
		<Transition name="fade" mode="out-in">
			<div v-if="loading" key="loading">
				<div class="importing-animation">
					<InlineSvg src="/images/icons/ic-customer.svg" />
					<InlineSvg src="/images/icons/ic-customer.svg" />
					<InlineSvg src="/images/icons/ic-customer.svg" />
				</div>
				<AppProgress :min="0" :max="totalCustomers" :value="processedCount" />
			</div>
			<div v-else key="content">
				<form id="audience_bulk_tagging_form">
					<div class="overflow-visible d-flex flex-column gap-4px">
						<AppNewMultiSelect
							placeholder="Select a tag"
							:items="availableFanTags"
							:addNewItemPrefixCopy="'+ Press Enter to create a new tag'"
							:canAddNewItem="this.mode !== 'delete'"
							:singleItemSelect="true"
							:characterLimit="50"
							:selectedItems="audienceTag"
							label="name"
							trackBy="name"
						/>
						<p class="para-3 text-danger mb-2" v-show="formErrors && formErrors.audienceTag">
							{{ formErrors.audienceTag }}
						</p>
					</div>
				</form>
			</div>
		</Transition>
	</AppModal>
</template>
<script>
	import { mapGetters, mapActions } from 'vuex'

	export default {
		props: {
			mode: {
				type: String,
				default: 'add',
				validator(value) {
					return ['add', 'delete'].includes(value)
				},
			},
			tableTotalCustomers: {
				type: Number,
				default: 0,
			},
			filterArray: {
				type: Array,
				default: () => [],
			},
		},
		mounted() {
			this.fetchFanTagList()
		},
		data() {
			return {
				audienceTag: [],
				formErrors: {},
				availableFanTags: [],
				ajax_warnings: {},
				loading: false,
				numberOfRowsToProcess: 500,
				processedCount: 0,
				userFanIds: [],
			}
		},
		created() {
			this.resetForm()
		},
		computed: {
			...mapGetters('Auth', ['user']),
			requestMethod() {
				switch (this.mode) {
					case 'delete':
						return 'delete'
					default:
						return 'put'
				}
			},
			actionPastTense() {
				switch (this.mode) {
					case 'delete':
						return 'deleted'
					default:
						return 'added'
				}
			},
			buttonText() {
				switch (this.mode) {
					case 'delete':
						return 'Remove'
					default:
						return 'Add'
				}
			},
			modalTitle() {
				if (this.loading) {
					return 'Processing...'
				}

				if (this.mode === 'delete') {
					return `Remove Tag`
				}

				return `Add Tag`
			},
			modalSubheading() {
				if (this.mode === 'delete') {
					return `Tag will be removed from ${this.tableTotalCustomers} ${this.customerText}`
				}

				return `Tag will be applied to ${this.tableTotalCustomers} ${this.customerText}`
			},
			totalCustomers() {
				return this.userFanIds.length
			},
			customerText() {
				return `customer${this.tableTotalCustomers > 1 ? 's' : ''}`
			},
		},
		methods: {
			...mapActions('Auth', ['fetchUser']),
			show() {
				this.resetForm()
				$(this.$refs.modal.$el).modal('show')
			},
			hide() {
				$(this.$refs.modal.$el).modal('hide')
				this.$emit('close')

				setTimeout(this.resetForm, 250)
			},
			fetchFanTagList() {
				this.$axios.get('/v1/tags/fan-tags-list').then(list => {
					list.data.tags.forEach(tag => {
						this.availableFanTags.push({ name: tag })
					})
				})
				this.$emit('fan-tag-list-loaded')
			},
			validateForm() {
				let formStatus = true
				const errorObject = {}

				if (!this.audienceTag.length) {
					errorObject.audienceTag = 'Please enter at least 1 tag!'
					formStatus = false
				}

				Object.assign(this.formErrors, errorObject)
				return formStatus
			},

			async submitModalForm() {
				this.loading = true

				const status = this.validateForm()
				if (status) {
					try {
						const response = await this.$axios.get('v1/users/fans/bulk/user_fan_ids', {
							params: {
								page: '1',
								timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
								filter_array: JSON.stringify(this.filterArray),
							},
						})
						this.userFanIds = response.data.user_fan_ids
						this.handleBatch()
					} catch (error) {
						console.error(error)
					}
				} else {
					this.loading = false
				}
			},
			async handleBatch() {
				if (this.processedCount === this.totalCustomers) {
					setTimeout(() => {
						this.hide()

						this.$stanNotify({
							type: 'success', // can be 'info', 'warning', 'success', 'error'
							title: 'Success',
							text: `The tags have been ${this.actionPastTense}.`,
							duration: 10000,
						})
					}, 250)
					return
				}
				const data = {
					tag: this.audienceTag[0].name,
					user_fan_ids: this.userFanIds.slice(this.processedCount, this.processedCount + this.numberOfRowsToProcess),
				}
				try {
					await this.$axios({ method: this.requestMethod, url: `v1/users/fans/tags/bulk`, data })
					this.processedCount = Math.min(this.totalCustomers, this.processedCount + this.numberOfRowsToProcess)
					this.$stanAnalytics(`creator-customers-tag-${this.actionPastTense}`, {
						meta: { user_id: this.userId, username: this.username },
						props: data,
					})
					this.handleBatch()
				} catch (error) {
					Object.assign(this.formErrors, error.response.data)
					this.$stanNotify({
						type: 'error', // can be 'info', 'warning', 'success', 'error'
						title: this.$t('Tags could not be created, let us help you with that!'),
						text: error.response.data.message || this.$t('Please email friends@stanwith.me'),
						duration: 4000,
					})
					this.hide()
					this.$emit('close')
				}
			},
			resetForm() {
				this.formErrors = {}
				this.ajax_warnings = {}
				this.audienceTag = []
				this.loading = false
				this.processedCount = 0
				this.userFanIds = []
			},
		},
	}
</script>

<style lang="css" scoped>
	.importing-animation {
		padding: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		svg::v-deep {
			path {
				fill: var(--stan-primary-soft-color);
			}
		}
		svg:first-child {
			width: 78px;
			height: 78px;
			transform: translateX(30px) rotate(8.88deg);
			animation: float1 4s ease-in-out infinite;
		}
		svg:nth-child(2) {
			width: 118px;
			height: 118px;
			animation: float2 6s ease-in-out infinite;
		}
		svg:last-child {
			width: 65px;
			height: 65px;
			transform: translateX(-30px) rotate(-10deg);
			animation: float3 5s ease-in-out infinite;
		}
	}
	@keyframes float1 {
		0% {
			transform: translateX(30px) rotate(8.88deg) translatey(-10px);
		}
		50% {
			transform: translateX(30px) rotate(8.88deg) translatey(0px);
		}
		100% {
			transform: translateX(30px) rotate(8.88deg) translatey(-10px);
		}
	}
	@keyframes float2 {
		0% {
			transform: translatey(0px);
		}
		50% {
			transform: translatey(-20px);
		}
		100% {
			transform: translatey(0px);
		}
	}
	@keyframes float3 {
		0% {
			transform: translateX(-30px) rotate(-10deg) translatey(0px);
		}
		50% {
			transform: translateX(-30px) rotate(-10deg) translatey(-10px);
		}
		100% {
			transform: translateX(-30px) rotate(-10deg) translatey(0px);
		}
	}
</style>
