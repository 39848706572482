var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("DiscountCodeAdditionalOptionsModal", {
        ref: "discount-code-additonal-options",
        attrs: { enableDuration: _vm.enableDuration },
        on: { onChange: _vm.handleChange }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex align-items-center justify-content-start mb-2" },
        [
          _c("AppToggle", {
            staticClass: "mr-2",
            attrs: { size: "sm" },
            model: {
              value: _vm.value.discount_codes_available,
              callback: function($$v) {
                _vm.$set(_vm.value, "discount_codes_available", $$v)
              },
              expression: "value.discount_codes_available"
            }
          }),
          _vm._v(" "),
          _c("label", { staticClass: "para-3 mb-0" }, [
            _vm._v("Add Discount Code")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.value.discount_codes_available
        ? _c(
            "div",
            [
              _vm._l(_vm.value.discount_codes, function(discount_code, i) {
                return _c(
                  "div",
                  {
                    key: `discount_code-${i}`,
                    staticClass:
                      "discount-code-wrapper funnel-row mb-5 mb-sm-2",
                    class: { "with-duration": _vm.enableDuration }
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-12 col-sm",
                          class: {
                            "form-error":
                              _vm.validation.discount_codes.$each[i].code.$error
                          }
                        },
                        [
                          _c("AppInput", {
                            attrs: {
                              id: `discount_code_code_${i}`,
                              label: "Discount Code",
                              "input-class": "mb-1",
                              placeholder: "ex. FRI30"
                            },
                            model: {
                              value: discount_code.code,
                              callback: function($$v) {
                                _vm.$set(discount_code, "code", $$v)
                              },
                              expression: "discount_code.code"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.validation.discount_codes.$each[i]
                                    .code.alphaNum,
                                  expression:
                                    "!validation.discount_codes.$each[i].code.alphaNum"
                                }
                              ],
                              staticClass: "form-group"
                            },
                            [
                              _c("small", { staticClass: "stan-text-danger" }, [
                                _vm._v(
                                  "Discount code can only contain a-z, A-Z, 0-9 "
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-12 col-sm",
                          class: {
                            "form-error":
                              _vm.validation.discount_codes.$each[i].value
                                .$error
                          }
                        },
                        [
                          _c("AppInput", {
                            attrs: {
                              label: "Discount %",
                              "input-class": "mb-1",
                              type: "number",
                              placeholder: "30%",
                              id: `discount_code_value_${i}`
                            },
                            model: {
                              value: discount_code.value,
                              callback: function($$v) {
                                _vm.$set(discount_code, "value", _vm._n($$v))
                              },
                              expression: "discount_code.value"
                            }
                          }),
                          _vm._v(" "),
                          !_vm.validation.discount_codes.$each[i].value
                            .in_range_membership_if_enabled ||
                          !_vm.validation.discount_codes.$each[i].value
                            .in_price_range_membership_if_enabled
                            ? _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "small",
                                  { staticClass: "stan-text-danger" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.membershipMinimumAmountError)
                                    )
                                  ]
                                )
                              ])
                            : !_vm.validation.discount_codes.$each[i].value
                                .in_range_if_enabled
                            ? _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "small",
                                  { staticClass: "stan-text-danger" },
                                  [_vm._v(_vm._s(_vm.minimumAmountError))]
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isCodeExpired(
                        discount_code.valid_until,
                        discount_code.timezone
                      )
                        ? _c(
                            "div",
                            { staticClass: "col-12 col-sm form-error" },
                            [
                              _c("label", { staticClass: "control-label" }, [
                                _vm._v("Expiration ")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-control expired" },
                                [_vm._v("\n\t\t\t\t\t\tEXPIRED\n\t\t\t\t\t")]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-center ml-0"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "pt-3 ml-4" },
                            [
                              _c("InlineSvg", {
                                staticClass: "settings_color",
                                attrs: { src: "/images/icons/ic-options.svg" },
                                on: {
                                  click: function($event) {
                                    return _vm.addtionalDiscountCodeSettings(i)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "pt-3 ml-4" },
                            [
                              _c("InlineSvg", {
                                attrs: { src: "/images/icons/ic-trash.svg" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("removeDiscountCode", i)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  ]
                )
              }),
              _vm._v(" "),
              !_vm.validation.discount_codes.uniqueCodes
                ? _c("div", { staticClass: "form-group my-2" }, [
                    _c("small", { staticClass: "stan-text-danger" }, [
                      _vm._v("Codes are case-insensitive and must be unique!")
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "AppButton",
                {
                  staticClass: "w-100",
                  attrs: { name: "add-discount-code", outline: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("addDiscountCode")
                    }
                  }
                },
                [
                  _vm._v("+ Add"),
                  _vm.value.discount_codes.length
                    ? _c("span", [_vm._v(" Another")])
                    : _vm._e(),
                  _vm._v(" Discount Code\n\t\t")
                ]
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }