var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "media-preview" }, [
    _c("div", { staticClass: "media-preview-container" }, [
      !_vm.urlError && _vm.embedUrl
        ? _c("div", { staticClass: "media-preview-content" }, [
            _c("iframe", {
              ref: "embedIframe",
              class: _vm.embedType,
              style: _vm.embedStyle,
              attrs: {
                src: _vm.embedUrl,
                frameborder: "0",
                allow:
                  "autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture",
                loading: "lazy"
              }
            })
          ])
        : _c(
            "div",
            { staticClass: "media-preview-content", class: [_vm.embedType] },
            [_vm._m(0)]
          )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "default-outer" }, [
      _c("div", { staticClass: "default-text-container" }, [
        _c("img", {
          staticClass: "default-icon",
          attrs: { src: "/images/media-preview-default.svg" }
        }),
        _vm._v(" "),
        _c("p", { staticClass: "default-text" }, [
          _vm._v("Your media content will be displayed here")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }