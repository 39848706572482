<template>
	<div>
		<div class="d-flex w-100 flex-wrap mb-2">
			<div class="bar-column flex-grow-1">
				<div class="chart-row d-flex flex-wrap" v-for="row in rows" :key="row.id">
					<div class="d-flex w-100">
						<div class="upper-label text-truncate mb-1" style="max-width: 100%;">{{ row.label }}</div>
					</div>
					<div class="d-flex w-100 align-items-center" style="overflow: hide;">
						<span class="bar" :style="row.style">
							<span class="mx-2 d-block">{{ row.formattedValue }}</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			totals: {
				type: Object,
				required: true,
				default: () => ({}),
			},
			formatter: {
				type: Function,
				default: value => value,
			},
			showTopN: {
				type: Number,
				default: 0,
			},
			color: {
				type: String,
				default: '#2C727C',
			},
			otherLabel: {
				type: String,
				default: 'Other',
			},
			loading: {
				type: Boolean,
				default: false,
			},
		},
		mounted() {
			this.resetLocalTotals()
		},
		beforeDestroy() {
			clearInterval(this.placeholderInterval)
		},
		data() {
			return {
				localTotals: {},
			}
		},
		methods: {
			resetLocalTotals() {
				this.localTotals = this.showTopN > 0 ? this.combineIntoOther(this.totals, this.showTopN) : this.totals
			},
			combineIntoOther(totals, topN = 5) {
				const sortedEntries = Object.entries(totals).sort((a, b) => b[1] - a[1])
				const topEntries = sortedEntries.slice(0, topN)
				const otherEntries = sortedEntries.slice(topN)
				const othersTotal = otherEntries.reduce((acc, entry) => acc + entry[1], 0)
				const newTotals = Object.fromEntries(topEntries)
				if (othersTotal > 0) {
					newTotals[this.otherLabel] = othersTotal
				}

				return newTotals
			},
		},
		computed: {
			maxKey() {
				let maxKey = null
				let maxValue = -Infinity
				for (const [key, value] of Object.entries(this.localTotals)) {
					if (value > maxValue) {
						maxValue = value
						maxKey = key
					}
				}
				return maxKey
			},
			maxValue() {
				return this.localTotals[this.maxKey]
			},
			totalSum() {
				return Object.values(this.localTotals).reduce((acc, val) => acc + val, 0)
			},
			rows() {
				const out = []
				for (const [key, value] of Object.entries(this.localTotals)) {
					if (value !== 0) {
						const percentageOfMax = Math.floor((value / this.maxValue) * 100)
						out.push({
							label: key,
							value,
							formattedValue: this.formatter(value),
							percentageOfTotal: (value / this.totalSum) * 100,
							percentageOfMax,
							style: {
								'min-width': `${percentageOfMax}%`,
								'background-color': this.color,
							},
						})
					}
				}
				out.sort((a, b) => b.value - a.value) // sort descending
				return out
			},
		},
		watch: {
			totals: {
				handler() {
					this.resetLocalTotals()
				},
				deep: true,
			},
		},
	}
</script>

<style lang="scss" scoped>
	.bar-column {
		position: relative;
	}
	.bar {
		border-radius: 0px 5px 5px 0px;
		color: white;
		padding-left: 5px;
		text-align: end;
		transition: min-width 0.5s ease-in-out; /* This will make the width change smoothly */
	}
	.chart-row {
		margin-bottom: 7px;
	}
	.upper-label {
		font-size: 0.75rem;
		font-weight: 600;
	}
</style>
