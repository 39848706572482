<template>
	<div class="form-group" v-if="!hidePaymentPlans">
		<div class="d-flex align-items-center justify-content-start mb-0">
			<AppToggle size="sm" class="mr-2" v-model="value.enabled" :disabled="paymentPlanDisable" />
			<label class="para-3 mb-0 d-inline-flex align-items-center">Add Payment Plan </label>
		</div>

		<div v-if="value.enabled" class="sub-options">
			<div class="mr-2 mt-1">
				<span class="para-3 mb-0 d-inline-flex align-items-center text-light"
					>Stan automatically includes Afterpay and Klarna for your fans. You can also offer your own custom payment plan below. Remember,
					that you would have to take the risk if they don't pay. Tip: Creators often charge more for payment plans to encourage upfront
					payment.</span
				>
			</div>
			<div class="price-input-section mt-3">
				<div class="price-wrapper" :class="{ 'form-error': validation.amount.$error }">
					<AppInput
						:label="`${$t('Total Price')}(${getCurrencySymbol(currency)}) *`"
						input-class="mb-1"
						placeholder=""
						v-model.number="value.amount"
						type="number"
						@blur="validation.$touch"
						hasPrecision
					/>
					<small class="stan-text-danger error">{{ price_validation_error_message }}</small>
				</div>
				<div class="price-wrapper mb-3" :class="{ 'form-error': validation.interval.$error }">
					<div>
						<label class="control-label mr-1">{{ $t('Repeat every') }}</label>
					</div>
					<AppDropdown
						:searchable="false"
						:options="[
							{ label: 'Yearly', value: 'year' },
							{ label: 'Monthly', value: 'month' },
							{ label: 'Weekly', value: 'week' },
							{ label: 'Bi-weekly', value: 'bi-week' },
							{ label: 'Daily', value: 'day' },
						]"
						reduce="value"
						v-model="value.interval"
						@blur="validation.interval.$touch"
						class="form-control h-auto"
					/>
				</div>
			</div>
			<div class="price-input-section">
				<div class="price-wrapper">
					<div>
						<label class="control-label">
							<span class="mr-1">{{ $t('# Payments') }}</span>
						</label>
					</div>
					<AppDropdown
						:searchable="false"
						:options="durationOptions"
						reduce="value"
						v-model="value.duration"
						class="form-control h-auto px-2"
						@blur="validation.$touch"
						:disabled="validation.amount.$error"
					/>
				</div>
			</div>
			<span v-if="!validation.amount.$error && this.value.duration" class="color-gray para-3 d-inline-flex align-items-center mb-3 mt-1">
				Your customer will pay {{ this.value.duration }} payments of {{ this.onePaymentAmount }} each.
			</span>
		</div>
	</div>
</template>
<script>
	import StripeConnectMixin from '~/components/stripeConnectMixin.js'
	import commonHelper from '~/global_helper/helpers.js'

	export default {
		name: 'PaymentPlanOption',
		mixins: [StripeConnectMixin],
		props: {
			value: {
				type: Object,
				default: () => {},
			},
			currency: {
				type: String,
				default: 'USD',
			},
			validation: {
				type: Object,
				default: () => {},
			},
			oneTimePrice: {
				type: Number,
				default: 0,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
		},
		computed: {
			price_validation_error_message() {
				const fiftyCents = this.formatCurrency(0.5, this.currency, 'en-US', true)
				const twentyFiveThousand = this.formatCurrency(25000, this.currency, 'en-US', true)

				return `Must be between ${fiftyCents} and ${twentyFiveThousand}`
			},
			durationOptions() {
				// Smallest payment is $0.50 so reduce options to ensure each one is > $0.50
				const options = []
				for (let i = 1; i <= Math.min(12, Math.floor(this.value.amount / 0.5)); i++) {
					options.push({ label: `${i} ${this.value.interval}${i === 1 ? '' : 's'}`, value: i })
				}
				return options
			},
			hidePaymentPlans() {
				return (
					this.user.subscription_tier_allowed_modules?.indexOf('advanced-pricing') !== -1 &&
					this.user.subscription_tier_allowed_modules?.indexOf('payment-plans') === -1
				)
			},
			onePaymentAmount() {
				return this.formatCurrency((this.value.amount / this.value.duration).toFixed(2), this.currency, 'en-US', true)
			},
			paymentPlanDisable() {
				return this.disabled
			},
		},
		mounted() {},
		methods: {
			getCurrencySymbol: commonHelper.getCurrencySymbol,
			formatCurrency: commonHelper.formatCurrency,
			toggleDiscountAvailable() {
				if (!('sale_amount_available' in this.value)) {
					this.$set(this.value, 'sale_amount_available', false)
				}
				this.value.sale_amount_available = !this.value.sale_amount_available
				if (!this.value.sale_amount_available) {
					this.value.sale_amount = 0
				}
			},
			toggleDurationAvailable() {
				if (!('membership_duration_available' in this.value)) {
					this.$set(this.value, 'membership_duration_available', false)
				}
				this.value.membership_duration_available = !this.value.membership_duration_available
				if (!this.value.membership_duration_available) {
					this.value.membership_duration = 0
				} else if (this.value.membership_duration_available && !this.value.membership_duration) {
					this.value.membership_duration = 3
				}
			},
		},
		directives: {
			'two-dp': {
				bind: function twoDp(el) {
					el.addEventListener('keyup', e => {
						// evolved from https://stackoverflow.com/a/52685887/9614402
						const keyCode = e.keyCode ? e.keyCode : e.which
						const value = e.target.value
						const i = value.indexOf('.')

						if ((keyCode < 48 || keyCode > 57) && !(keyCode === 46 && i === -1)) {
							// 46 is dot
							// only allow numbers and one dot
							e.preventDefault()
						} else {
							const splits = e.target.value.split('.')
							if (splits.length > 1 && splits[1].length > 1) {
								e.target.value = Number(`${splits[0]}.${splits[1].substr(0, 2)}`).toFixed(2)
								e.target.dispatchEvent(new Event('input'))
							}
						}
					})
					// modify the initial value if it's not 2dp
					const inputElement = el.nodeName === 'INPUT' ? el : el.querySelector('input')
					const stringValue = inputElement.value
					const splits = stringValue.split('.')
					if (splits.length > 1 && splits[1]?.length > 2) {
						const newValue = Number(`${splits[0]}.${splits[1].substr(0, 2)}`).toFixed(2)
						if (inputElement.value !== newValue) {
							inputElement.value = newValue
							inputElement.dispatchEvent(new Event('input'))
						}
					}
				},
			},
		},
		watch: {
			oneTimePrice: {
				immediate: true,
				handler(newVal) {
					if (!this.value.amount) {
						this.value.amount = newVal
					}
				},
			},
			paymentPlanDisable: {
				immediate: true,
				handler(newVal) {
					if (newVal) {
						this.value.enabled = false
					}
				},
			},
			durationOptions: {
				immediate: true,
				handler(newVal, oldVal) {
					// Ensures that the duration for the payment plan is always in the valid range
					if (!newVal?.length) {
						this.value.duration = 0
					} else if (newVal?.length !== oldVal?.length) {
						if (newVal.find(opt => opt.value === this.value.duration)) {
							return
						}
						this.value.duration = newVal[newVal.length - 1].value
					}
					// Since we are using v-model this triggers the update
					this.$emit('input', this.value)
				},
			},
		},
	}
</script>
<style lang="scss" scoped>
	.sub-options {
		margin-left: 2rem !important;
	}
	.price-input-section {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 18px;
		align-items: center;
		@media (max-width: 575px) {
			grid-template-columns: auto;
		}
		.error {
			opacity: 0;
			display: block;
			font-size: 70%;
			@media (max-width: 767px) {
				font-size: 10px;
			}
		}
		.form-error {
			.error {
				opacity: 1;
			}
		}
		.price-wrapper {
			display: flex;
			flex-direction: column;
		}
	}
</style>
