<template>
	<AppModal
		id="delete-store-modal"
		@close="closeModal"
		:action="$t('Yes, Delete Funnel')"
		:title="$t('Just Confirming!')"
		:subheading="$t('Are you sure you want to delete this funnel?')"
		@cta="deleteFunnel"
	>
	</AppModal>
</template>
<script>
	import axios from 'axios'

	export default {
		props: {
			storeId: Number,
		},
		methods: {
			deleteFunnel() {
				this.closeModal()
				axios
					.delete(`v1/stores/${this.storeId}`)
					.then(() => {
						this.$stanNotify({
							type: 'success',
							title: this.$t('Done!'),
							text: this.$t('Your funnel has been deleted.'),
						})
						this.$emit('deleted')
					})
					.catch(error => {
						this.$stanNotify({
							type: 'error',
							title: this.$t('Something went wrong'),
							text: this.$t('Please contact us at friends@stanwith.me'),
						})
						this.$logError(error)
					})
			},
			closeModal() {
				$('#delete-store-modal').modal('hide')
				this.$emit('close')
			},
		},
	}
</script>

<style lang="scss" scoped>
	.icon {
		color: var(--stan-primary-primary-color);
		width: 36px;
		min-width: 36px;
		height: 36px;
		font-size: 36px !important;
		margin-bottom: 0.75rem;
	}
	.title {
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 27px;
		letter-spacing: 0.04em;
		color: var(--stan-primary-dark-color);
	}
	.fan-name {
		font-weight: bold;
		color: var(--stan-primary-primary-color);
	}
	.details {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 19px;
		letter-spacing: 0.04em;
		color: var(--stan-primary-dark-color);
	}
	.button {
		min-width: 35%;
	}
	td.bolded {
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 19px;
		/* identical to box height */

		letter-spacing: 0.04em;
		&.order-number {
			color: var(--stan-primary-primary-color);
		}
		&.amount {
			color: var(--stan-persian-green-2);
		}
	}

	// RADIO INPUT
	// hide default radio input
	/* Customize the label (the label-container) */
	/* The label-container */
	.label-container {
		display: flex;
		position: relative;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* Hide the browser's default radio button */
	.label-container input {
		position: absolute;
		left: 9px;
		opacity: 0;
		cursor: pointer;
	}

	/* Create a custom radio button */
	.radio-btn {
		position: relative;
		margin: auto;
		height: 26px;
		width: 26px;
		background-color: var(--stan-boulder-4);
		border-radius: 50%;
	}

	/* On mouse-over, add a grey background color */
	.label-container:hover input ~ .radio-btn {
		background-color: var(--stan-boulder-3);
	}

	/* When the radio button is checked, add a blue background */
	.label-container input:checked ~ .radio-btn {
		background-color: var(--stan-primary-primary-color);
	}

	/* Create the indicator (the dot/circle - hidden when not checked) */
	.radio-btn:after {
		content: '';
		position: absolute;
		display: none;
	}

	/* Show the indicator (dot/circle) when checked */
	.label-container input:checked ~ .radio-btn:after {
		display: block;
	}

	/* Style the indicator (dot/circle) */
	.label-container .radio-btn:after {
		top: 9px;
		left: 9px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: white;
	}
</style>

<style>
	.md-overlay {
		z-index: 13;
	}
</style>
