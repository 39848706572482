<template>
	<AppSection :number="step" title="Add text">
		<div class="c" v-if="['callout', 'preview'].includes(value.layout)">
			<AppInput
				class="form-group mb-3 pb-2"
				label="Title"
				maxlength="50"
				:placeholder="$t('Input Heading Here')"
				v-model="value.heading"
				required=""
			/>
			<AppInput
				class="form-group mb-3 pb-2"
				label="Subtitle"
				maxlength="100"
				placeholder="Enter a description for your followers here"
				v-model="value.tagline"
			/>
		</div>
		<AppInput
			class="form-group mb-0"
			:class="{ 'form-error': validation.title.$error }"
			label="Button*"
			maxlength="30"
			placeholder="Button Text Here..."
			v-model="value.title"
		/>
	</AppSection>
</template>
<script>
	export default {
		props: {
			step: {
				type: String,
				required: true,
			},
			value: {
				type: Object,
				default: () => {},
			},
			validation: {
				type: Object,
				default: () => {},
			},
		},
	}
</script>
<style lang="scss"></style>
