<template>
	<div class="wrapper">
		<h1 class="title mb-4">{{ questionText }}</h1>
		<div class="option my-3">
			<div v-if="isSelect">
				<div v-for="option in options" :key="option.id" @click="selectOption(option)">
					<div class="card" :class="{ selected: isOptionSelected(option.dbMap) }">
						{{ option.displayName }}
					</div>
				</div>
			</div>
			<div v-if="isMultiSelect">
				<div
					v-for="option in options"
					class="card"
					:class="{ selected: isOptionSelected(option.dbMap) }"
					:key="option.id"
					@click="selectOption(option)"
				>
					{{ option.displayName }}
				</div>
			</div>
			<div v-if="isText">
				<textarea
					class="form-control mb-3"
					maxlength="1000"
					:placeholder="options[0].displayName"
					v-model="selectedValues[0]"
					style="height: 80px;"
				/>
			</div>

			<textarea v-if="isOptionSelected('other')" class="form-control mb-3" rows="4" v-model="otherInput" placeholder="Type here..."></textarea>
			<small class="stan-text-danger error" v-if="requiredError && requiredErrorMessage">
				{{ requiredErrorMessage }}
			</small>
			<AppButton name="next-app-form-question" v-if="shouldShowNextButton" class="w-100 mt-4" @click="submit" size="lg">Next</AppButton>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			questionId: {
				type: String,
				default: '',
			},
			questionText: {
				type: String,
				default: '',
			},
			required: {
				type: Boolean,
				default: false,
			},
			options: {
				type: Array,
				default: () => [],
			},
			type: {
				type: String,
				default: '',
			},
		},
		data() {
			return {
				requiredError: false,
				selectedValues: [],
				otherInput: '',
			}
		},
		computed: {
			isSelect() {
				return this.type.toLowerCase() === 'select'
			},
			isMultiSelect() {
				return this.type.toLowerCase() === 'multiselect'
			},
			isText() {
				return this.type.toLowerCase() === 'text'
			},
			shouldShowNextButton() {
				return this.isOptionSelected('other') || !this.isSelect
			},
			requiredErrorMessage() {
				if (this.isMultiSelect) {
					return 'Please select at least one option.'
				}
				if (this.isText) {
					return 'Please enter your response above.'
				}
				return ''
			},
		},
		methods: {
			selectedOptionIndex(optionDbMapToCheck) {
				return this.selectedValues.findIndex(s => s.toLowerCase() === optionDbMapToCheck.toLowerCase())
			},
			isOptionSelected(optionDbMapToCheck) {
				return this.selectedOptionIndex(optionDbMapToCheck) >= 0
			},
			selectOption(selected) {
				const selectedDbMap = selected.dbMap
				if (this.isOptionSelected(selectedDbMap)) {
					this.selectedValues.splice(this.selectedOptionIndex(selectedDbMap), 1)
				} else {
					this.selectedValues.push(selectedDbMap)
					if (this.isSelect) {
						this.autoSubmitTypeSelect()
					}
				}
				if (this.required) {
					this.requiredError = false
				}
			},
			autoSubmitTypeSelect() {
				// Can not auto submit if the type other is selected
				if (this.isOptionSelected('other')) {
					return
				}
				this.submit()
			},
			preFillValues() {},
			submit() {
				if (this.required && this.selectedValues.length <= 0) {
					this.requiredError = true
					return
				}
				this.$emit('next', this.selectedValues, this.otherInput)
				this.reset()
			},
			reset() {
				this.selectedValues = []
				this.otherInput = ''
				this.requiredError = false
			},
		},
	}
</script>
<style lang="scss" scoped>
	textarea {
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 130%; /* 18.2px */
		letter-spacing: -0.28px;
		padding: 12px;
	}

	.card {
		border: 1px solid var(--stan-gray-strike-color);
		border-radius: 8px;
		padding: 12px;
		margin-bottom: 10px;
		cursor: pointer;
		transition: ease 0.2s;
		color: var(--stan-text-dark-color);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 130%; /* 18.2px */
		letter-spacing: -0.2px;
	}

	.card:hover {
		opacity: 100%;
		background-color: white;
		border-color: var(--stan-primary-primary-color);
		background-color: var(--stan-primary-light-color);
	}

	.selected {
		opacity: 100%;
		border-color: var(--stan-primary-primary-color);
		background-color: var(--stan-primary-light-color);
	}
</style>
