var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "position-relative" },
    [
      _c(
        "div",
        {
          class: {
            "creator-pro-feature-not-allowed": !_vm.isAffiliateShareAllowed
          }
        },
        [
          _c("div", { staticClass: "affiliate-link-resell-rights-section" }, [
            _c(
              "div",
              { staticClass: "header d-flex justify-content-between w-100" },
              [
                _c(
                  "div",
                  { staticClass: "toggle" },
                  [
                    _c("AppToggle", {
                      staticClass: "ml-2 mb-0",
                      model: {
                        value: _vm.value.enabled,
                        callback: function($$v) {
                          _vm.$set(_vm.value, "enabled", $$v)
                        },
                        expression: "value.enabled"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "toggle-label" }, [
                      _c(
                        "div",
                        { staticClass: "toggle-label-header" },
                        [
                          _c("InlineSvg", {
                            staticClass: "menu-item-icon",
                            attrs: { src: "/images/icons/ic-logout.svg" }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "para-2 text-bold" }, [
                            _vm._v("Resell Rights")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "new-highlighter para-4 text-bold text-white"
                            },
                            [_vm._v("NEW")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "toggle-label-subtext para-3 text-light"
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\tAllow anyone who purchases to immediately resell your product\n\t\t\t\t\t\t"
                          )
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ml-2" },
                  [
                    _c(
                      "AppButton",
                      {
                        attrs: { size: "sm", outline: "" },
                        on: { click: _vm.navigateToAffiliateAnalytics }
                      },
                      [_vm._v("\n\t\t\t\t\t\tAffiliate Analytics\n\t\t\t\t\t")]
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _vm.value.enabled
              ? _c("div", { staticClass: "toggle-expand" }, [
                  _c("div", { staticClass: "revenue-share" }, [
                    _c("div", { staticClass: "para-4 text-bold" }, [
                      _vm._v("Enter Your Desired Resell / Affiliate %")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _c("AppInput", {
                          staticClass: "mb-1 affiliate_share_percent_input",
                          class: {
                            "form-error": _vm.validation.percentage.$error
                          },
                          attrs: {
                            id: "affiliate_share_resell_percent",
                            type: "number",
                            placeholder: _vm.$t("ex. 100"),
                            suffix: "%",
                            min: _vm.percent_min,
                            max: _vm.percent_max
                          },
                          model: {
                            value: _vm.value.percentage,
                            callback: function($$v) {
                              _vm.$set(_vm.value, "percentage", $$v)
                            },
                            expression: "value.percentage"
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "para-3" }, [
                          _vm._v("of sales revenue will go to your reseller")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _vm.validation.percentage.$error
                        ? _c(
                            "small",
                            { staticClass: "stan-text-danger error" },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\tPercentage must be between " +
                                  _vm._s(_vm.percent_min) +
                                  "% and " +
                                  _vm._s(_vm.percent_max) +
                                  "%\n\t\t\t\t\t\t"
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "affiliate-share-message" }, [
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [
                        _c("InlineSvg", {
                          attrs: { src: "/images/icons/ic-info.svg" }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "para-3" }, [
                      _c("span", { staticClass: "text-bold" }, [
                        _vm._v("You've turned on Resale Rights.")
                      ]),
                      _vm._v(
                        " Upon purchase, your buyer will receive a unique affiliate\n\t\t\t\t\t\tlink to be able to resell your product at " +
                          _vm._s(_vm.value.percentage) +
                          "% profit share. Learn More\n\t\t\t\t\t"
                      )
                    ])
                  ])
                ])
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      !_vm.isAffiliateShareAllowed
        ? _c("AppUpgrade", {
            attrs: {
              title: "Want to distribute Resell Rights for your product?"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }