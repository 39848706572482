var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "progress", style: { "background-color": _vm.barColor } },
    [
      _c("div", {
        staticClass: "progress-bar",
        style: {
          width: `${_vm.barWidth}%`,
          "background-color": _vm.progressColor
        },
        attrs: { role: "progressbar" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }