var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "video-wrapper",
      attrs: { id: "video-wrapper" },
      on: { click: _vm.startPlaying }
    },
    [
      _c(
        "div",
        {
          staticClass: "artplayer-app art-auto-size",
          attrs: { id: "artplayer-app" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "art-video-player art-subtitle-show art-layer-show art-control-show art-mask-show"
            },
            [
              _vm.isSupportedType
                ? _c("video", {
                    staticClass: "art-video",
                    attrs: {
                      id: _vm.videoId,
                      preload: "metadata",
                      controls: _vm.isPlayStarted,
                      controlslist: "nodownload"
                    },
                    on: { play: _vm.onPlay, pause: _vm.onPause }
                  })
                : _c("div", { staticClass: "video-issue" }, [
                    _c("p", { staticClass: "header" }, [
                      _vm._v("Unable to Play Video ⚠️")
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "subheader" }, [
                      _vm._v(
                        "This video cannot be played because the file format is unsupported."
                      )
                    ])
                  ]),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isPlayStarted && _vm.showMask,
                    expression: "!isPlayStarted && showMask"
                  }
                ],
                staticClass: "art-poster",
                style: _vm.videoPosterStyle,
                attrs: { id: "video-poster-src" }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.isVideoReady &&
                        !_vm.isPlayStarted &&
                        _vm.isSupportedType,
                      expression:
                        "isVideoReady && !isPlayStarted && isSupportedType"
                    }
                  ],
                  staticClass: "art-mask",
                  attrs: { id: "video-mask-src" }
                },
                [
                  _c("div", { staticClass: "art-state" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "28",
                          height: "36",
                          viewBox: "0 0 28 36",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M27.2107 18.9707L2.47975 35.4581C2.30406 35.575 2.09995 35.6421 1.88916 35.6521C1.67837 35.6622 1.46879 35.6149 1.28275 35.5153C1.0967 35.4157 0.941156 35.2675 0.83268 35.0865C0.724203 34.9055 0.666857 34.6984 0.666748 34.4874V1.51274C0.666857 1.30171 0.724203 1.09466 0.83268 0.913645C0.941156 0.732628 1.0967 0.584423 1.28275 0.484816C1.46879 0.385209 1.67837 0.337929 1.88916 0.348013C2.09995 0.358096 2.30406 0.425166 2.47975 0.542078L27.2107 17.0294C27.3705 17.136 27.5015 17.2803 27.5922 17.4496C27.6828 17.619 27.7302 17.808 27.7302 18.0001C27.7302 18.1921 27.6828 18.3812 27.5922 18.5505C27.5015 18.7199 27.3705 18.8642 27.2107 18.9707Z",
                            fill: "white"
                          }
                        })
                      ]
                    )
                  ])
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }