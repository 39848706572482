import axios from 'axios'

/** 
  Set all variable here
*/
export const state = {
	hasDispute: false,
	balance: {},
	transactions: {},
	transactionsRefreshing: false,
	revenueChart: {},
}

export const getters = {
	hasDispute: state => state.hasDispute,
	balance: state => state.balance,
	transactions: state => state.transactions,
	transactionsRefreshing: state => state.transactionsRefreshing,
	revenueChart: state => state.revenueChart,
}

export const mutations = {
	setHasDispute(state, payload) {
		state.hasDispute = payload
	},

	setBalance(state, payload) {
		state.balance = payload
	},

	setTransactions(state, payload) {
		state.transactions = payload
	},

	setRevenueChart(state, payload) {
		state.revenueChart = payload
	},

	setTransactionsRefreshing(state, payload) {
		state.transactionsRefreshing = payload
	},
}

export const actions = {
	fetchHasDisputeStatus: ({ commit }) => {
		const payload = {
			page: 1,
			per_page: 1,
			filterArray: [
				{
					fieldName: 'orders-status',
					value: 'DISPUTED',
				},
			],
		}

		axios.post('v1/payments/orders', payload).then(response => {
			const hasDispute = response?.data?.all?.total && response?.data?.all?.total > 0
			commit('setHasDispute', hasDispute)
		})
	},
	fetchBalance: ({ commit }) => {
		axios.get('v1/payments/balance').then(response => {
			commit('setBalance', response.data)
		})
	},
	fetchTransactions: ({ commit }, payload) => {
		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
		const cacheKey = JSON.stringify(payload)
		axios
			.post('v1/payments/orders', {
				page: payload.page,
				keyword: payload.keyword,
				sortBy: payload.sortBy,
				filter: payload.filter,
				filter_array: JSON.stringify(payload.filterArray),
				per_page: payload.per_page ?? 15,
				timezone,
				user_fan_id: payload.userFanId,
			})
			.then(response => {
				const transactions = response.data
				transactions.cacheKey = cacheKey
				commit('setTransactions', transactions)
				commit('setTransactionsRefreshing', false)
			})
			.catch(() => {
				commit('setTransactionsRefreshing', false)
			})
	},
	fetchRevenueChart({ commit }) {
		axios
			.get('v1/analytics/chart/cumulative-revenue', {
				params: {
					timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					day_range: 30,
					period_length: 1,
					show_sum_series: 0,
				},
			})
			.then(response => {
				const dataSet = response.data.values
				const labels = response.data.labels

				const productNames = Object.keys(dataSet)
				const seriesData = []

				if (productNames.length) {
					const totalRevenue = { 'Total Revenue': [] }
					productNames.forEach(productName => {
						const pRevenue = dataSet[productName]
						pRevenue.forEach((val, index) => {
							totalRevenue['Total Revenue'][index] = (totalRevenue['Total Revenue'][index] ?? 0) + val
						})
					})
					const productData = {
						name: 'Total Revenue',
						data: totalRevenue['Total Revenue'].map(val => parseFloat(val.toFixed(2))),
					}
					seriesData.push(productData)
				} else {
					seriesData.push({
						name: 'Total Revenue',
						data: labels.map(() => null),
					})
				}
				const out = {
					series: seriesData,
					options: {
						yaxis: {
							min: Math.max(0, seriesData[0].data[0] - (seriesData[0].data[29] - seriesData[0].data[0]) / 2), // Can't be less than 0
						},
						xaxis: {
							categories: labels,
						},
					},
				}
				commit('setRevenueChart', out)
			})
	},
}
