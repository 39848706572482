var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("StanleyTyping", { attrs: { text: _vm.text } }, [
        _c(
          "div",
          { staticClass: "d-flex flex-wrap mt-2" },
          [
            _c(
              "AppButton",
              {
                staticClass: "mr-3",
                attrs: {
                  name: "learn-vs-creator-product-prompt-monetization-button",
                  size: "md",
                  outline: ""
                },
                on: { click: _vm.action }
              },
              [_vm._v("Learn about Monetization")]
            ),
            _vm._v(" "),
            _c(
              "AppButton",
              {
                staticClass: "mr-3",
                attrs: {
                  name: "learn-vs-creator-product-prompt-create-product-button",
                  size: "md",
                  outline: ""
                },
                on: { click: _vm.action }
              },
              [_vm._v("Create Product")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }