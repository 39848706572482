var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "account-disable-alert account-disable-warning" },
    [
      _c(
        "h5",
        { staticClass: "account-disable-alert-text" },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }