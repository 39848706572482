var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "store-header" }, [
    _c("div", { staticClass: "store-header__content" }, [
      _c(
        "div",
        { staticClass: "store-header__image" },
        [
          _c("image-preview", {
            attrs: {
              source: _vm.user_image,
              width: 433,
              staticClass: "",
              alt: "user image"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "store-header__profile" },
        [
          _c("div", { staticClass: "store-header__name-bio" }, [
            _c("div", { staticClass: "store-header__names" }, [
              _c("div", { staticClass: "store-header__fullname" }, [
                _vm._v(_vm._s(_vm.full_name))
              ])
            ]),
            _vm._v(" "),
            _vm.userBio
              ? _c("div", { staticClass: "store-header__bio" }, [
                  _vm._v(_vm._s(_vm.userBio))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("SocialIcons", { attrs: { "user-social-icons": _vm.socialIcons } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }