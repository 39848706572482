var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pb-3", class: _vm.className }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "funnel_body" }, [
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("div", { staticClass: "funnel-thumnail" }, [
        _c(
          "svg",
          {
            attrs: {
              width: "2560",
              height: "1690",
              viewBox: "0 0 256 169",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("rect", {
              attrs: {
                x: "0.243652",
                y: "0.447266",
                width: "254.966",
                height: "168.072",
                rx: "20",
                fill: "#F3F6FD"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M163.742 83.9115L110.577 114.607L110.577 53.2163L163.742 83.9115Z",
                fill: "#09BB71"
              }
            })
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _vm._m(3)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "funnel-skeleton_top",
        staticStyle: {
          "background-image": "url('/images/device/placeholder-new.svg')"
        }
      },
      [_c("div", { staticClass: "title" }, [_c("div"), _vm._v(" "), _c("div")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list-text-skeleton li-none-style" }, [
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list-text-skeleton" }, [
      _c("ul", [_c("li"), _vm._v(" "), _c("li"), _vm._v(" "), _c("li")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "button-section" }, [
      _c("a", [_vm._v("  ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }