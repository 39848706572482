const MainContainer = () => import('~/components/layouts/MainContainer').then(m => m.default || m)

const moduleRoutes = {
	path: '/appointments',
	name: 'BookingIndex',
	component: MainContainer,
	children: [
		{
			path: '',
			name: 'appointments',
			component: () => import('./Appointments').then(m => m.default || m),
			meta: {
				pageTitle: 'Appointments',
				breadcrumbs: [{ title: 'Appointments', active: true }],
			},
		},
		{
			path: 'details/:bookingId',
			name: 'appointment_details',
			component: () => import('./AppointmentDetails').then(m => m.default || m),
			meta: {
				pageTitle: 'Overview',
				breadcrumbs: [
					{ title: 'Appointments', to: 'appointments' },
					{ title: 'Overview', active: true },
				],
			},
		},
	],
}

export default moduleRoutes
