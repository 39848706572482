var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AppModal", {
    attrs: {
      id: "upgrade-funnel-theme-modal",
      title: _vm.$t("Upgrade Funnel Design"),
      ariaLabelledby: "upgradeFunnel",
      subheading: _vm.$t(
        "Heads up! The old funnel design you’re using now has custom colors set. Switching to the new design will permanently set your funnel to your store theme."
      ),
      action: _vm.$t("Upgrade Funnel Design"),
      loading: _vm.isLoading,
      disableAction: _vm.isLoading
    },
    on: { cta: _vm.upgradeFunnel }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }