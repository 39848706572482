<template>
	<label class="toggle-switch" :class="[sizeClass, { disabled: disabled }]">
		<input :id="toggleId" type="checkbox" v-model="localValue" :disabled="disabled" />
		<span class="toggle-slider round"></span>
	</label>
</template>
<script>
	export default {
		name: 'AppToggle',
		props: {
			val: {
				type: Boolean,
				default: false,
			},
			size: {
				type: String,
				default: 'md',
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			toggleId: {
				type: String,
				default: 'toggle-id',
			},
		},
		data() {
			return {
				sizeMapping: {
					default: {
						sizeClass: '',
					},
					sm: {
						sizeClass: 'ts-sm',
					},
					md: {
						sizeClass: '',
					},
					lg: {
						sizeClass: 'ts-lg',
					},
				},
			}
		},
		model: {
			prop: 'val',
			event: 'valueChange',
		},
		computed: {
			localValue: {
				get: function getter() {
					return this.val
				},
				set: function setter(value) {
					this.$emit('valueChange', value)
				},
			},
			active() {
				return !!this.val
			},
			sizeClass() {
				return this.sizeMapping[this.size]?.sizeClass ?? this.sizeMapping.default.sizeClass
			},
		},
	}
</script>
<style lang="scss" scoped>
	.toggle-switch {
		position: relative;
		display: inline-block;
		width: 30.22px;
		height: 17px;
		margin: 0;
		&.disabled {
			cursor: not-allowed;
			.toggle-slider {
				pointer-events: none;
				opacity: 0.5;
			}
		}
		input {
			opacity: 0;
			width: 0;
			height: 0;
			&:focus {
				outline: none;
				box-shadow: none;
			}
		}

		.toggle-slider {
			left: 0;
			top: 0;
			width: auto;
			height: auto;
			position: absolute;
			cursor: pointer;
			right: 0;
			bottom: 0;
			background-color: var(--stan-gray-dark-color);
			transition: 0.4s;
			&:before {
				position: absolute;
				left: 2px;
				top: 2px;
				width: 13px;
				height: 13px;
				content: '';
				background-color: white;
				transition: 0.4s;
			}
		}

		input:focus + .toggle-slider {
			box-shadow: none;
			outline: none;
		}

		input:checked + .toggle-slider {
			background-color: var(--stan-primary-primary-color);
		}

		&.disabled {
			input:checked + .toggle-slider {
				background-color: var(--stan-primary-soft-color);
				opacity: 0.5;
			}
		}

		input:checked + .toggle-slider:before {
			transform: translateX(13.22px);
		}

		.toggle-slider.round {
			border-radius: 40px;
			&:before {
				border-radius: 50%;
			}
		}

		&.ts-lg {
			width: 35.56px;
			height: 20px;
			.toggle-slider {
				&:before {
					position: absolute;
					left: 2px;
					top: 2px;
					width: 16px;
					height: 16px;
				}
			}
			input:checked + .toggle-slider:before {
				transform: translateX(15.56px);
			}
		}
		&.ts-sm {
			width: 24.71px;
			height: 14px;
			.toggle-slider {
				&:before {
					position: absolute;
					left: 2px;
					width: 10px;
					height: 10px;
					top: 2px;
				}
			}
			input:checked + .toggle-slider:before {
				transform: translateX(10.71px);
			}
		}
	}
</style>
