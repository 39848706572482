<template>
	<div class="progress">
		<div class="progress-bar" :style="{ width: `${progress}%` }" role="progressbar"></div>
	</div>
</template>
<script>
	export default {
		name: 'InfiniteProgressBar',
		props: {
			min: { type: Number, default: 0 },
			max: { type: Number, default: 100 },
			estimatedSeconds: { type: Number, default: 60 },
			done: { type: Boolean, default: false },
		},
		mounted() {
			this.initialStep = 100 / this.estimatedSeconds
			this.interval = setInterval(() => {
				if (this.done) this.progress = 100
				this.progress += this.stepSize
				if (this.progress >= 99) {
					this.progress = 100
					clearInterval(this.interval)
				}
			}, 500)
		},
		destroyed() {
			clearInterval(this.interval)
		},
		data() {
			return {
				initialStep: 0,
				interval: undefined,
				progress: 0,
			}
		},
		computed: {
			progressRemaining() {
				return 100 - this.progress
			},
			stepSize() {
				return (this.progressRemaining / 100) * this.initialStep
			},
		},
	}
</script>
<style lang="scss" scoped>
	.progress {
		border-radius: 1rem;
		.progress-bar {
			background-color: var(--stan-primary-primary-color);
		}
	}
</style>
