<template>
	<div>
		<AppBanner
			v-if="showEmailFlowBanner"
			class="mb-0"
			title="Add an Email Flow"
			subtitle="Send an automatic email drip to your customers when this product is purchased."
			icon="/images/icons/ic-send-email.svg"
			:buttons="[
				{
					name: 'Add Flow',
					icon: '/images/icons/ic-plus.svg',
					click: () => $emit('add-flow'),
					color: 'primary',
				},
			]"
		/>
		<div class="flows" v-else>
			<div :key="index" v-for="(flow, index) in relatedFlows" class="flow-item para-2 text-bold" @click="$emit('edit-flow', flow.flow_id)">
				<InlineSvg src="/images/icons/ic-flows.svg" class="flow-icon" />
				<p class="flow-name">{{ flow.data.name }}</p>
				<div class="flow-action ">
					<InlineSvg src="/images/icons/ic-edit.svg" />
					<span>Edit</span>
				</div>
			</div>
			<AppButton name="add-flow-email-flow-form" icon="/images/icons/ic-plus.svg" outline @click="$emit('add-flow')">Add Flow</AppButton>
		</div>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'

	export default {
		name: 'EmailFlowForm',
		props: {
			store: {
				type: Object,
				default: undefined,
			},
			pageId: {
				type: Number,
				default: undefined,
			},
		},
		data() {
			return {}
		},
		computed: {
			...mapGetters('Flows', ['getFlows']),
			...mapGetters('Auth', ['user']),
			relatedFlows() {
				if (this.pageId === -1 || this.isFlowLoading) return []
				const flows = this.getFlows.filter(flow => flow.trigger === 'checkout')
				return flows.filter(flow => flow.data.filters?.page_id_list.filter(pageId => pageId === this.pageId || pageId === 0).length)
			},
			showEmailFlowBanner() {
				return this.relatedFlows.length === 0 || !this.isEmailFlowsAllowed
			},
			isEmailFlowsAllowed() {
				return this.user.subscription_tier_allowed_modules?.indexOf('email-flows') !== -1
			},
		},
	}
</script>
<style lang="scss" scoped>
	.flows {
		display: flex;
		flex-direction: column;
		gap: 20px;
		.flow-item {
			padding: 20px;
			background-color: var(--stan-gray-light-color);
			display: flex;
			align-items: center;
			border-radius: 15px;
			display: flex;
			gap: 8px;
			&:hover {
				cursor: pointer;
			}
			.flow-icon {
				width: 24px;
				height: 24px;
				&::v-deep {
					path:not(.no-fill),
					rect:not(.no-fill) {
						fill: var(--stan-primary-dark-color);
					}
				}
			}
			.flow-name {
				margin: 0;
				flex: 1 0 0;
				max-width: 100%;
			}
			.flow-action {
				color: var(--stan-primary-primary-color);
				svg {
					width: 14px;
					height: 14px;
					&::v-deep {
						path:not(.no-fill),
						rect:not(.no-fill) {
							fill: var(--stan-primary-primary-color);
						}
					}
				}
			}
		}
	}
</style>
