var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { short: _vm.short } },
    [
      _c(
        "div",
        { staticClass: "calendar-container header" },
        _vm._l(_vm.days, function(day) {
          return _c("div", { key: day, staticClass: "calendar-cell para-3" }, [
            _vm._v("\n\t\t\t" + _vm._s(day) + "\n\t\t")
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("Transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.loading
          ? _c(
              "div",
              {
                key: `${_vm.loading}-load`,
                staticClass: "calendar-container body"
              },
              [
                _c(
                  "div",
                  { staticClass: "loading-cell" },
                  [
                    _c("HollowDotsSpinner", {
                      attrs: {
                        "dot-size": 18,
                        "dots-num": 3,
                        color: "var(--stan-primary-primary-color)"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _c(
              "div",
              {
                key: `${_vm.loading}-cal`,
                staticClass: "calendar-container body"
              },
              _vm._l(_vm.calendarGridData, function(data, i) {
                return _c(
                  "div",
                  {
                    key: data.yyyymmddDate,
                    ref: `${i}-${data.yyyymmddDate}-day`,
                    refInFor: true,
                    staticClass: "calendar-cell",
                    class: {
                      clickable: _vm.isThisMonth(data.yyyymmddDate)
                    },
                    on: {
                      click: event =>
                        _vm.emptySpaceClicked(
                          event,
                          data.yyyymmddDate,
                          data.popupRef
                        )
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "date-number",
                        class: {
                          "cur-date": _vm.isToday(data.yyyymmddDate),
                          "this-month": _vm.isThisMonth(data.yyyymmddDate)
                        }
                      },
                      [
                        _c("p", { staticClass: "para-3" }, [
                          _vm._v(_vm._s(data.day))
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isThisMonth(data.yyyymmddDate)
                      ? _c(
                          "div",
                          { staticClass: "events-container para-4" },
                          _vm._l(
                            _vm.getEventsStats(data.yyyymmddDate),
                            function(event, i) {
                              return _c(
                                "div",
                                { key: i },
                                [
                                  _c(
                                    "CalendarCellEvent",
                                    _vm._b(
                                      {
                                        attrs: {
                                          dateYYYYMMDD: data.yyyymmddDate
                                        }
                                      },
                                      "CalendarCellEvent",
                                      event,
                                      false
                                    )
                                  )
                                ],
                                1
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "AppPopup",
                      {
                        ref: data.popupRef,
                        refInFor: true,
                        attrs: {
                          triggerRef: `${i}-${data.yyyymmddDate}-day`,
                          "aria-labelledby": "calendar-events-popup"
                        }
                      },
                      [
                        _c("div", { staticClass: "events-popup para-3" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between pb-3 align-items-start popup-header"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-baseline" },
                                [
                                  _c("div", { staticClass: "month" }, [
                                    _c("b", [_vm._v(_vm._s(data.monthDay))])
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "day" }, [
                                    _vm._v(" " + _vm._s(data.dayOfWeek))
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _vm.isPresentFuture(data.yyyymmddDate)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "blockTimeButton d-flex align-items-center h5 text-bold",
                                          on: {
                                            click: function($event) {
                                              return _vm.blockTimeClicked(
                                                data.yyyymmddDate,
                                                data.popupRef
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("InlineSvg", {
                                            attrs: {
                                              src: "/images/icons/ic-plus.svg"
                                            }
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tBlock Time\n\t\t\t\t\t\t\t\t"
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "close-wrapper" },
                                    [
                                      _c("InlineSvg", {
                                        staticClass: "close-icon",
                                        attrs: {
                                          src: "/images/icons/ic-close.svg"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.closePopup(data.popupRef)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "events-popup-container d-flex flex-column"
                            },
                            _vm._l(
                              _vm.allDisplayEventsForDay(data.yyyymmddDate),
                              function(popUpEvent, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass: "event-wrapper",
                                    class: popUpEvent.type,
                                    on: {
                                      click: function($event) {
                                        return _vm.onPopupEventClick(
                                          data.popupRef,
                                          data.yyyymmddDate,
                                          popUpEvent
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "event-dot",
                                      class: {
                                        cancelled: popUpEvent.status == 0
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "event-display-time" },
                                      [
                                        _vm._v(
                                          _vm._s(popUpEvent.displayTimeFull)
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "event-name" }, [
                                      _vm._v(_vm._s(popUpEvent.title))
                                    ])
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              }),
              0
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }