<template>
	<div class="ai-prompt-box">
		<div>
			<div class="ai-prompt-heading">
				{{ $t('Generate with AI') }}
			</div>
		</div>
		<textarea
			v-model="prompt"
			maxlength="800"
			id="ai-prompt-textarea"
			class="ai-prompt-textarea"
			placeholder="Briefly describe your product and what makes it special to your audience..."
		></textarea>
		<div class="ai-prompt-text-count">
			<div>{{ promptCount }}/800</div>
		</div>
		<AppButton
			@click="requestAIAssistance"
			class="ai-prompt-button"
			:disabled="promptCount < 1"
			type="button"
			icon="images/icons/ic-ai-generate.svg"
			>{{ $t('Generate') }}</AppButton
		>
	</div>
</template>

<script>
	export default {
		props: {
			value: { type: String },
		},
		data() {
			return {
				prompt: '',
			}
		},
		mounted() {
			const textarea = document.getElementById('ai-prompt-textarea')
			if (textarea) {
				textarea.focus()
				textarea.scrollIntoView({ behavior: 'smooth', block: 'center' })
			}
		},
		computed: {
			promptCount() {
				return this.prompt.length
			},
		},
		methods: {
			requestAIAssistance() {
				this.$emit('requestAIAssistance', this.prompt)
			},
		},
	}
</script>

<style lang="scss" scoped>
	.ai-prompt-box {
		width: 320px;
		height: auto;
		position: absolute;
		top: 44px;
		right: 6px;
		padding: 24px;
		background: var(--stan-white);
		box-shadow: 0px 8px 40px rgba(173, 182, 209, 0.25);
		border-radius: 16px;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 16px;
		display: inline-flex;
		color: var(--stan-text-dark-color);

		.ai-prompt-heading {
			flex: 1 1 0;
			font-size: 16px;
			font-weight: 600;
			line-height: 22px;
			word-wrap: break-word;
		}

		.ai-prompt-textarea {
			font-size: 14px;
			font-weight: 500;
			line-height: 21px;
			width: 100%;
			height: 120px;
			border-radius: 10px;
			padding: 16px;
			background-color: var(--stan-gray-light-color);
			outline: none;
			resize: none;
		}

		.ai-prompt-text-count {
			align-self: stretch;
			justify-content: flex-end;
			display: inline-flex;
			color: var(--stan-text-light-color);
			font-size: 12px;
			font-weight: 500;
			line-height: 18px;
			word-wrap: break-word;
			margin-top: -8px;
		}

		.ai-prompt-button {
			::v-deep {
				.button-icon {
					transform: none !important;
				}
				.icon-svg {
					width: 24px !important;
					height: 24px !important;
					path {
						fill: var(--stan-white) !important;
					}
				}
			}
		}
	}
</style>
