<template>
	<div class="table-wrapper">
		<div class="table-container">
			<table>
				<slot></slot>
			</table>
		</div>
		<div class="paginate-container" :class="{ 'bg-white border-top': $slots.paginate }">
			<slot name="paginate"></slot>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'AppTable',
	}
</script>
<style lang="scss" scoped>
	tbody .md-table-row td {
		border-color: var(--stan-gray-dark-color) !important;
	}
	th,
	td {
		border-color: var(--stan-gray-dark-color);
	}

	th {
		color: var(--stan-primary-dark-color);
	}

	tr:nth-child(even) {
		background: var(--stan-blue-8);
	}
	tr:nth-child(odd) {
		background: var(--stan-white);
	}

	thead th {
		border-width: 1px;
	}

	.table-wrapper {
		border: 1px solid var(--stan-gray-dark-color);
		border-radius: 12px;
	}

	.table-container {
		width: 100%;
		overflow: inherit;
		overflow-x: auto;
		border-radius: 12px;
	}

	table {
		width: 100%;
		font-size: 14px;
		border-radius: 12px;
		overflow: hidden;
		thead {
			th {
				padding: 0.25rem 0.75rem;
				color: var(--stan-blue-5);
				height: 50px;
				background: var(--stan-blue-8);
			}
		}
		tbody {
			td {
				padding: 0.25rem 0.75rem;
				color: var(--stan-primary-dark-color);
				height: 50px;
			}
		}
	}

	.paginate-container {
		border-radius: 0 0 12px 12px;
	}
</style>
