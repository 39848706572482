<template>
	<div class="radio-button-group d-flex justify-self-end col-auto p-0">
		<div
			v-for="(option, index) in options"
			class="radio-button left-radio-button text-nowrap"
			v-bind:key="index"
			v-bind:class="{ selected: selectedValue === option.value, disabled }"
			v-on:click="select(option.value)"
		>
			<span class="d-none d-sm-block">{{ labelText(option) }}</span>
			<span class="d-sm-none d-xs-block">{{ mobileLabelText(option) }}</span>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'AppButtonGroup',
		props: {
			options: { type: Array, default: () => [] },
			initialValue: { type: String, default: undefined },
			disabled: { type: Boolean, default: false },
		},
		mounted() {
			this.$nextTick(() =>
				this.options.forEach(o => {
					if (o.value === this.initialValue) {
						this.selectedValue = o.value
					}
				})
			)
		},
		data() {
			return {
				selectedValue: null,
			}
		},
		methods: {
			select(value) {
				if (!this.disabled && this.initialValue !== value) {
					this.selectedValue = value
					this.$emit('selected', value)
				}
			},
			labelText(option) {
				return option?.label ? this.$t(option.label) : ''
			},
			mobileLabelText(option) {
				return option?.mobileLabel ? this.$t(option.mobileLabel) : ''
			},
		},
	}
</script>
<style lang="scss" scoped>
	.radio-button-group {
		.radio-button {
			border: 1px solid var(--stan-primary-primary-color);
			box-sizing: border-box;

			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			text-align: center;
			letter-spacing: 0.04em;
			padding: 5px 10px;
			color: var(--stan-primary-primary-color);
			cursor: pointer;

			&.disabled {
				cursor: not-allowed;
				opacity: 0.3;
			}
		}
		:first-child {
			border-radius: 4px 0px 0px 4px;
		}
		:last-child {
			border-radius: 0px 4px 4px 0px;
		}
		.selected {
			background: var(--stan-primary-primary-color);
			color: var(--stan-white);
		}
	}
</style>
