var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product-editor-wrapper" }, [
    _c("div", { staticClass: "row d-flex" }, [
      _c(
        "div",
        {
          staticClass: "col-md-12 order-1 order-xl-1",
          class: { "col-xl-7": _vm.showPreview }
        },
        [
          _c(
            "div",
            [
              _vm.loading
                ? _c(
                    "div",
                    { staticClass: "col pt-5" },
                    [
                      _c("Skeleton", {
                        staticClass: "mb-5",
                        attrs: { lines: 2 }
                      }),
                      _vm._v(" "),
                      _c("Skeleton", {
                        staticClass: "mb-5",
                        attrs: { lines: 2 }
                      }),
                      _vm._v(" "),
                      _c("Skeleton", {
                        staticClass: "mb-5",
                        attrs: { lines: 2 }
                      }),
                      _vm._v(" "),
                      _c("Skeleton", {
                        staticClass: "mb-5",
                        attrs: { lines: 2 }
                      })
                    ],
                    1
                  )
                : _vm._t("product-details")
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _vm.showPreview
        ? _c(
            "div",
            { staticClass: "col-md-12 col-xl-5 order-2 order-xl-2 relative" },
            [
              _c("div", { staticClass: "preview-group-wrapper sticky-top" }, [
                _c(
                  "div",
                  {
                    staticClass: "text-center preview-button-wrapper d-xl-none",
                    on: { click: _vm.togglePreview }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _c("InlineSvg", {
                          staticClass: "icon-eye mr-2",
                          attrs: { src: "/images/icons/ic-show.svg" }
                        }),
                        _vm._v("Preview")
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("InlineSvg", {
                          staticClass: "icon-close",
                          attrs: { src: "/images/icons/ic-close.svg" }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "preview-wrapper" },
                  [
                    _vm.loading
                      ? _c(
                          "div",
                          { staticClass: "col pt-5" },
                          [
                            _c("Skeleton", {
                              staticClass: "mb-5",
                              attrs: { lines: 0, img: true }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "site-preview-section mx-auto" },
                              [
                                _c("CalendarSkeleton", {
                                  staticClass: "mb-5",
                                  attrs: { heading: false }
                                }),
                                _vm._v(" "),
                                _c("Skeleton", {
                                  staticClass: "mb-5",
                                  attrs: { lines: 2 }
                                }),
                                _vm._v(" "),
                                _c("Skeleton", {
                                  staticClass: "mb-5",
                                  attrs: { lines: 2 }
                                }),
                                _vm._v(" "),
                                _c("Skeleton", {
                                  staticClass: "mb-5",
                                  attrs: { lines: 2 }
                                }),
                                _vm._v(" "),
                                _c("Skeleton", {
                                  staticClass: "mb-5",
                                  attrs: { lines: 2 }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._t("preview")
                  ],
                  2
                )
              ])
            ]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "sticky-wrap" }, [_vm._t("control")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }