<template>
	<AppModal
		id="manage-payment-plan-modal"
		class="manage-payment-plan-modal"
		action="Cancel Payment Plan"
		actionColor="danger"
		secondaryAction="Keep It!"
		secondaryActionColor="light"
		:title="modalTitle"
		@cta="updatePaymentPlan"
		@secondaryCtaClick="$emit('close')"
		:loading="loading"
	>
		<div class="d-flex my-2">
			<div class="col-12">
				<p class="para-2 text-center text-light">
					Cancelling this plan will stop all future payments. This does not affect any previous payments.
				</p>
			</div>
		</div>
	</AppModal>
</template>

<script>
	export default {
		props: {
			paymentPlanId: { type: [String, Number], required: true },
			action: { type: String, required: true },
		},
		data() {
			return {
				loading: false,
				modalTitle: 'Cancel Payment Plan',
			}
		},
		computed: {
			successMessage() {
				// eslint-disable-next-line quotes
				return `Successfully cancelled Payment Plan.`
			},
		},
		methods: {
			updatePaymentPlan() {
				this.loading = true
				const payload = {
					action: this.action,
					payment_plan_id: this.paymentPlanId,
				}
				this.$axios
					.post('v1/payment-plan/manage', payload)
					.then(() => {
						this.$stanNotify({
							type: 'success',
							title: this.$t('Great!'),
							text: this.successMessage,
						})
					})
					.catch(err => {
						const errorMessage = err.response.data.message.concat(' ', this.$t('Please contact us at friends@stanwith.me'))
						this.$stanNotify({
							type: 'error',
							title: this.$t('Something went wrong'),
							text: errorMessage,
						})
						this.$logError(err)
					})
					.finally(() => {
						this.$emit('updated')
						$('#manage-payment-plan-modal').modal('hide')
						this.loading = false
					})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.bold-text {
		font-weight: bold;
		color: var(--stan-primary-primary-color);
	}
</style>
