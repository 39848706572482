var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.wrapperClasses,
      attrs: {
        id: _vm.elementId,
        "data-toggle": "tooltip",
        "data-placement": _vm.direction,
        "data-delay": _vm.delay
      }
    },
    [
      _vm.$slots.default
        ? _vm._t("default")
        : _c("InlineSvg", {
            staticClass: "info-icon",
            attrs: { src: "images/info.svg" }
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }