<template>
	<div>
		<div class="d-flex justify-content-start align-items-center" id="lesson-back-button" @click="reset">
			<img src="/images/back-arrow-chev.svg" alt="" class="pr-2 cursor-pointer" />
			<h2>{{ $t('Course Home') }}</h2>
		</div>
		<div class="editor-wrapper">
			<AppSection number="1" title="Page Description" subtext="Start by giving your course a title." subtext-placement="bottom">
				<div class="offset-section">
					<div class="form-group">
						<div class="col px-0">
							<label class="control-label">{{ $t('Image') }}</label>
						</div>
						<FileDropZone
							class="py-0 mb-4 new"
							:image="imageUrl"
							drop-zone-class="header-image-drop-zone"
							@start-upload="uploadStarted()"
							@uploaded-all="uploadCompleted()"
							@uploaded="setImage"
							@delete-file="resetFile"
							@cancel-file="resetFile"
							data-test-id="upload-header-image-button"
							accept="image"
							:crop-aspect-ratio="16 / 9"
							expected-dimension="1920 x 1080"
						/>
					</div>
					<div class="form-group mb-3">
						<AppInput
							v-model="title"
							:label="$t('Title')"
							:placeholder="$t('Title')"
							maxlength="100"
							ref="input-title"
							@change="$v.title.$touch"
						/>
						<div v-show="$v.title.$error" class="form-error-text para-3 stan-text-danger mb-3 mt-2">
							<InlineSvg class="error-icon" src="/images/icons/ic-warning-circle.svg" />{{ $t('Please provide a title') }}
						</div>
					</div>
					<div class="form-group mb-3">
						<div class="col px-0">
							<label class="control-label">{{ $t('Description') }}</label>
						</div>
						<TipTapEditor
							:key="descriptionEditorCounter"
							v-model="description"
							class="tiptap-editor flexible"
							@focusout="$v.description.$touch"
						/>
						<div v-show="$v.description.$error" class="form-error-text para-3 stan-text-danger mb-3 mt-2">
							<InlineSvg class="error-icon" src="/images/icons/ic-warning-circle.svg" />{{ $t('Please provide a description') }}
						</div>
					</div>
				</div>
			</AppSection>
			<AppSection
				number="2"
				title="Customize Branding"
				subtext="Your store’s branding is automatically applied to your course, but you can create course-specific branding here."
				subtext-placement="bottom"
			>
				<div class="offset-section">
					<div class="form-group mb-3">
						<label class="control-label">{{ $t('Title Font') }}</label>
						<AppDropdown class="form-control" v-model="chosenTitleFont" :options="fontOptions" :clearable="false" :searchable="false" />
					</div>
					<div class="color-inputs">
						<ColorInput v-model="backgroundColor" label="Background" :color-suggestions="backgroundColors" />
						<ColorInput v-model="highlightColor" label="Highlight" :color-suggestions="highlightColors" />
					</div>
				</div>
			</AppSection>
			<div class="d-flex flex-row w-100 justify-content-end">
				<AppButton name="course-homepage-save-button" class="mb-2 ml-2" outline :disabled="isUploading" @click="reset">
					Cancel
				</AppButton>
				<AppButton name="course-homepage-save-button" class="mb-2 ml-2" :disabled="isUploading" :loading="loading" @click="savePage">
					Save
				</AppButton>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import { required } from 'vuelidate/lib/validators' // eslint-disable-line import/extensions
	import TipTapEditor from '~/components/shared/TipTapEditor'
	import FileDropZone from '~/components/uploads/FileDropZone'
	import StoreThemeMixin from '~/pages/stores/components/StoreThemeMixin.js'
	import ColorInput from '~/components/ColorInput'
	import PageUploadMixin from '~/pages/stores/components/PageUploadMixin.js'

	const DEFAULT_COURSE_IMAGE = 'https://assets.stanwith.me/graphics/device/course.jpg'

	const BACKGROUND_COLORS = ['#FFFFFF', '#FFF3B2', '#FFE7E7', '#FFDCFE', '#DEE5FF', '#D7FFE0']
	const HIGHLIGHT_COLORS = ['#000000', '#FF5C02', '#FF52DA', '#7E41FF', '#2AB4FF', '#00DCCD']

	export default {
		name: 'CourseDetailsForm',
		mixins: [StoreThemeMixin, PageUploadMixin],
		components: {
			ColorInput,
			FileDropZone,
			TipTapEditor,
		},
		props: {
			inputPageData: {
				type: Object,
				required: true,
			},
			courseId: {
				type: Number,
			},
		},
		model: {
			prop: 'inputPageData',
			event: 'valueChange',
		},
		emit: ['navigate-back', 'save-changes', 'valueChange'],
		data: () => ({
			imageUrl: null,
			title: '',
			description: '',
			chosenTitleFont: null,
			backgroundColor: BACKGROUND_COLORS[0],
			highlightColor: HIGHLIGHT_COLORS[0],

			backgroundColors: BACKGROUND_COLORS,
			highlightColors: HIGHLIGHT_COLORS,

			// Used to refresh the editor when the value has been fully initialized
			descriptionEditorCounter: 0,
			beforeUpdateCopy: {},
			loading: false,
		}),
		validations() {
			return {
				title: {
					required,
				},
				description: {
					required,
				},
				backgroundColor: {
					required,
				},
				highlightColor: {
					required,
				},
			}
		},
		mounted() {
			// Original Copy of the data
			this.beforeUpdateCopy = { ...this.inputPageData }
			this.initializeFieldsFromData(this.inputPageData)
		},
		computed: {
			fontOptions() {
				return this.fontSelection.map(f => ({ value: f.value, label: f.label, labelStyle: `font-family: ${f.value}` }))
			},
			pageData() {
				return {
					banner_image_url: this.imageUrl,
					title: this.title,
					description: this.description,
					theme: {
						title_font: this.chosenTitleFont?.value,
						background_color: this.backgroundColor,
						highlight_color: this.highlightColor,
					},
				}
			},
		},
		methods: {
			hasErrors() {
				const formHasErrors = this.$v.$anyError
				const colorPickersHaveErrors =
					!this.$commonHelper.isValidHexColor(this.backgroundColor) || !this.$commonHelper.isValidHexColor(this.highlightColor)

				return formHasErrors || colorPickersHaveErrors
			},
			setImage(payload) {
				this.imageUrl = payload.src
			},
			resetFile() {
				this.imageUrl = ''
			},
			updateValue() {
				this.$emit('valueChange', this.pageData)
			},
			initializeFieldsFromData(inputData) {
				const { banner_image_url: bannerImageUrl, title, description, theme } = inputData

				this.imageUrl = bannerImageUrl || DEFAULT_COURSE_IMAGE
				this.title = title || ''
				this.description = description || ''
				this.chosenTitleFont = this.fontOptions.find(font => font.value === theme?.title_font) || this.fontOptions[0]
				this.backgroundColor = theme?.background_color || BACKGROUND_COLORS[0]
				this.highlightColor = theme?.highlight_color || HIGHLIGHT_COLORS[0]

				// Will force the tiptap editor to restart with initialized description
				this.descriptionEditorCounter++
			},
			touch() {
				this.$v.$touch()
			},
			reset() {
				this.initializeFieldsFromData(this.beforeUpdateCopy)
				this.$nextTick(() => {
					this.touch()
					this.$emit('navigate-back')
				})
			},
			async savePage() {
				this.loading = true
				if (this.hasErrors()) {
					this.touch()
					this.$stanNotify({
						type: 'error', // can be 'info', 'warning', 'success', 'error'
						title: this.$t('Wait up!'),
						text: this.$t('Please provide all required information.'),
						duration: 5000,
					})
					this.loading = false
					return
				}
				if (this.courseId && this.courseId > 0) {
					try {
						await axios.put(`v1/stores/${this.courseId}/homepage`, { ...this.pageData })
					} catch (error) {
						console.error(error)
						this.$stanNotify({
							type: 'error', // can be 'info', 'warning', 'success', 'error'
							title: this.$t('Wait up!'),
							text: this.$t('Something went wrong, please try again later.'),
							duration: 5000,
						})
						this.loading = false
						return
					}
				}
				this.loading = false
				this.$nextTick(() => {
					this.$emit('navigate-back')
				})
			},
		},
		watch: {
			pageData: {
				handler() {
					this.touch()
					if (!this.hasErrors()) {
						this.updateValue()
					}
				},
				deep: true,
			},
		},
	}
</script>

<style scoped lang="scss">
	.offset-section {
		padding-left: 45px;
	}

	.color-inputs {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(2, 1fr);
		gap: 16px;

		@media (min-width: 768px) {
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: 1fr;
			gap: 2rem;
		}
	}

	.restore-default {
		color: var(--stan-primary-primary-color);
		cursor: pointer;

		padding-top: 2rem;
	}

	.form-error-text::v-deep {
		display: flex;
		align-items: center;
		gap: 0.25rem;

		color: var(--stan-danger);

		svg {
			fill: var(--stan-danger);
		}
	}
</style>
