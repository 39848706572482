var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Draggable",
    _vm._b(
      {
        attrs: {
          delay: 250,
          delayOnTouchOnly: true,
          draggable: ".draggable-section-node",
          handle: ".drag-handle"
        },
        model: {
          value: _vm.reviews,
          callback: function($$v) {
            _vm.reviews = $$v
          },
          expression: "reviews"
        }
      },
      "Draggable",
      { animation: 0, ghostClass: "ghost" },
      false
    ),
    [
      _vm._l(_vm.reviews, function(review, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "d-flex review-wrapper draggable-section-node"
          },
          [
            _c("div", { staticClass: "col px-0" }, [
              _c("div", { staticClass: "d-flex flex-wrap" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                      width: "100%",
                      gap: "20px"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "drag-icon" },
                      [
                        _c("InlineSvg", {
                          staticClass: "user-select-none drag-handle",
                          attrs: { src: "/images/six-dots.svg" }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-100 px-0 d-flex justify-content-between mb-4"
                      },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "form-error": _vm.v.$each[index].rating.$error
                            }
                          },
                          [
                            _c("StarRating", {
                              attrs: {
                                "star-size": 24,
                                increment: 0.5,
                                "max-rating": 5,
                                "show-rating": false,
                                "active-color": "#FEC337"
                              },
                              model: {
                                value: review.rating,
                                callback: function($$v) {
                                  _vm.$set(review, "rating", $$v)
                                },
                                expression: "review.rating"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "AppButton",
                              {
                                staticClass:
                                  "d-none d-md-block btn-remove-discount",
                                attrs: {
                                  size: "xs",
                                  name: "remove-review",
                                  outline: "",
                                  color: "gray",
                                  icon: "/images/icons/ic-trash.svg"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.removeReview(index)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\tDelete\n\t\t\t\t\t\t\t"
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "d-md-none btn-remove-discount",
                                on: {
                                  click: function($event) {
                                    return _vm.removeReview(index)
                                  }
                                }
                              },
                              [
                                _c("InlineSvg", {
                                  attrs: {
                                    width: "24px",
                                    height: "24px",
                                    src: "/images/icons/ic-trash.svg"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 px-0" }, [
                  _c("div", { staticClass: "review-input-layout" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col px-0 d-flex flex-column v-gap",
                        class: { "order-2": _vm.newDesign }
                      },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "form-error": _vm.v.$each[index].name.$error
                            }
                          },
                          [
                            _c("AppInput", {
                              staticClass: "mb-2",
                              attrs: {
                                label: _vm.nameLabel,
                                maxlength: "40",
                                "show-character-count": false,
                                placeholder: _vm.$t("Name")
                              },
                              on: { blur: _vm.v.$each[index].name.$touch },
                              model: {
                                value: review.name,
                                callback: function($$v) {
                                  _vm.$set(review, "name", $$v)
                                },
                                expression: "review.name"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: {
                              "form-error":
                                _vm.v.$each[index].description.$error
                            }
                          },
                          [
                            !_vm.newDesign
                              ? _c("label", { staticClass: "control-label" }, [
                                  _vm._v(_vm._s(_vm.$t("Review Text")) + " *")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: review.description,
                                  expression: "review.description"
                                }
                              ],
                              staticClass: "form-control mb-2 mb-xl-0",
                              attrs: { placeholder: _vm.$t("Text"), rows: "5" },
                              domProps: { value: review.description },
                              on: {
                                blur: _vm.v.$each[index].description.$touch,
                                input: function($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    review,
                                    "description",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "px-0",
                        class: {
                          "col-xl-8 ": !_vm.newDesign,
                          "col-auto order-1": _vm.newDesign
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "mb-2" },
                          [
                            _c("FileDropZone", {
                              staticClass: "user-img-drop-zone",
                              class: { "compact new": _vm.newDesign },
                              attrs: {
                                title: _vm.fileDropTitle,
                                image: review.image,
                                hideProgress: true,
                                "crop-aspect-ratio": 1,
                                accept: "image"
                              },
                              on: {
                                "start-upload": _vm.fileUploadStarted,
                                uploaded: payload =>
                                  _vm.reviewImageUploaded(payload, index),
                                "delete-file": payload =>
                                  _vm.deleteReviewFileImage(payload, index),
                                "cancel-file": payload =>
                                  _vm.$emit("cancel-file", payload)
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ])
              ])
            ])
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "AppButton",
        {
          staticClass: "w-100",
          attrs: { name: "add-review", outline: "", size: "medium" },
          on: { click: _vm.addReview }
        },
        [_vm._v(_vm._s(_vm.$t("+ Add customer review")))]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }