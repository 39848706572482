<template>
	<div>
		<StanleyTyping :text="text">
			<div class="d-flex flex-wrap">
				<AppButton name="connect-google-calendar-prompt-button" outline class="mr-2 mb-2">Connect Google Calendar</AppButton>
			</div>
		</StanleyTyping>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				text: "I've noticed you have a Booking Product on your Store. Would you like to connect your Google Calendar to it?",
			}
		},
	}
</script>

<style lang="scss" scoped></style>
