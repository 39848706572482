var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      ref: "instagramPostsModal",
      staticClass: "post-picker-modal",
      attrs: {
        title: "Select Your Posts",
        ariaLabelledby: "modal-instagram-post-options"
      },
      on: {
        close: function($event) {
          return _vm.closeModal(true)
        }
      }
    },
    [
      _c("div", { staticClass: "modal-content-wrapper" }, [
        _c("div", { staticClass: "instagram-post-grid" }, [
          _c(
            "ul",
            { staticClass: "instagram-post-list" },
            _vm._l(_vm.instagramPosts, function(post) {
              return _c(
                "li",
                {
                  key: post.id,
                  staticClass: "instagram-post-item",
                  class: { selected: _vm.isPostSelected(post.id) },
                  on: {
                    click: function($event) {
                      return _vm.toggleSelection(post)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "thumbnail-container" },
                    [
                      _c("img", {
                        staticClass: "post-thumbnail",
                        attrs: {
                          src:
                            post.media_type === "VIDEO"
                              ? post.thumbnail_url
                              : post.media_url,
                          alt: "Instagram Post Preview"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "selected-overlay" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "thumbnail-overlay" }, [
                        _c("p", { staticClass: "post-caption" }, [
                          _vm._v(_vm._s(post.caption || "No Caption"))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("Transition", { attrs: { name: "fade" } }, [
                        _vm.isPostSelected(post.id)
                          ? _c(
                              "div",
                              { staticClass: "checkmark" },
                              [
                                _c("InlineSvg", {
                                  attrs: {
                                    src: "images/icons/ic-check-purple.svg"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ]
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "submit-button-wrapper" },
          [
            _c(
              "AppButton",
              {
                staticClass: "clear-button",
                attrs: {
                  name: "select-instagram-posts",
                  disabled: _vm.selectedPosts.length === 0,
                  outline: ""
                },
                on: { click: _vm.clearSelection }
              },
              [_vm._v("\n\t\t\t\tClear\n\t\t\t")]
            ),
            _vm._v(" "),
            _c(
              "AppButton",
              {
                staticClass: "cta-button",
                attrs: { name: "select-instagram-posts" },
                on: { click: _vm.saveInstagramPosts }
              },
              [_vm._v("\n\t\t\t\t" + _vm._s(_vm.ctaText) + "\n\t\t\t")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }