<template>
	<div class="new">
		<div class="py-3 my-3 lesson-form-wrapper">
			<div class="form-group col-md-12 mb-1" :class="{ 'form-error': validation.data.title.$error }">
				<AppInput
					:label="$t('Lesson Title')"
					v-model="value.data.title"
					:placeholder="$t('Lesson Name')"
					:id="`title-${index}`"
					maxlength="100"
					@blur="validation.data.title.$touch"
					ref="input-title"
				/>
			</div>
			<div class="form-group col-md-12 mb-4">
				<label class="control-label mr-3 mb-1">
					{{ $t('Description') }}
				</label>
				<TipTapEditor v-model="value.data.description" :extended="true" show-video-embed class="tiptap-editor flexible mb-3" />
			</div>
			<div class="form-group col-md-12 mb-4">
				<FileDropZone
					class="py-0 mb-3 new"
					:image="value.data.video"
					:title="$t('Video')"
					:enableDownloadFile="!!value.data.video"
					@download-file="downloadLessonVideo"
					@start-upload="() => uploadStarted('videoUploader')"
					@uploaded-all="() => uploadCompleted('videoUploader')"
					@uploaded="setVideo"
					@cancel-file="resetVideo"
					@delete-file="resetVideo"
					:show-button-image="false"
					drop-zone-placeholder="Upload a lesson video here"
					button-text="Upload"
					accept="video"
				/>
			</div>
			<div class="form-group col-md-12 mb-0" :class="{ 'form-error': validation.data.digital_assets.$error }">
				<label class="control-label mb-0">
					{{ $t('Supporting Materials') }}
				</label>
				<UploadFile
					class="new"
					:isRequired="true"
					:fileData="value.data"
					:showTypeChooser="false"
					:customSubheading="$t('Upload any files to help your students complete this module')"
					objectType="Lesson"
					@start-upload="() => uploadStarted('supportingMaterialsUploader')"
					@uploaded-all="() => uploadCompleted('supportingMaterialsUploader')"
				/>
			</div>
		</div>

		<PageControlButtons
			class="p-0 border-0"
			:status="value.status"
			:publishing="loading"
			:ready="!isUploading"
			@save-page="savePage"
			@delete-page="deletelesson"
			@save-draft="saveDraft"
			variant-to-use="lesson"
		/>
	</div>
</template>
<script>
	import axios from 'axios'
	import UploadFile from '~/components/uploads/UploadFile'
	import TipTapEditor from '~/components/shared/TipTapEditor'
	import FileDropZone from '~/components/uploads/FileDropZone'
	import PageControlButtons from '~/pages/stores/components/PageControlButtons'
	import PageUploadMixin from '~/pages/stores/components/PageUploadMixin.js'

	export default {
		name: 'LessonForm',
		components: {
			UploadFile,
			TipTapEditor,
			FileDropZone,
			PageControlButtons,
		},
		mixins: [PageUploadMixin],
		props: {
			value: {
				type: Object,
				default: () => {},
			},
			validation: {
				type: Object,
				default: () => {},
			},
			index: {
				type: Number,
				require: true,
			},
			isNewFunnelPage: {
				type: Boolean,
				require: true,
			},
			moduleTitle: {
				type: String,
				default: 'Module',
			},
			selectedModuleId: {
				type: [Number, String],
			},
			user: {
				type: Object,
				default: () => {},
			},
		},
		methods: {
			setVideo(payload) {
				this.value.data.video = payload.src
			},
			resetVideo() {
				this.value.data.video = ''
			},
			async savePage() {
				this.validation.$touch()
				if (this.validation.$error) {
					this.$stanNotify({
						type: 'error', // can be 'info', 'warning', 'success', 'error'
						title: this.$t('Wait up!'),
						text: this.$t('Please provide all required information.'),
						duration: 5000,
					})
				} else {
					this.loading = true
					this.value.status = 1
					await this.saveLesson()
				}
			},
			async deletelesson() {
				this.value.status = 0
				await this.saveLesson()
			},
			async saveDraft() {
				this.validation.$touch()
				if (this.validation.$error) {
					this.$stanNotify({
						type: 'error', // can be 'info', 'warning', 'success', 'error'
						title: this.$t('Wait up!'),
						text: this.$t('Please provide all required information.'),
						duration: 5000,
					})
				} else {
					this.value.status = 2
					await this.saveLesson()
				}
			},
			async saveLesson() {
				if (this.value.status === 0) {
					this.$emit('removeLesson')
					return
				}

				if (isNaN(this.value.lesson_id)) {
					this.$delete(this.value, 'lesson_id')
				}

				if (this.isNewFunnelPage) {
					this.$emit('savePageAsDraft')
				} else {
					await axios.put(`v1/stores/${this.selectedModuleId}/lesson`, { ...this.value })
					this.$emit('lessonUpdated')
				}
			},
			downloadLessonVideo() {
				if (!this.value?.data?.video) {
					return
				}

				const a = document.createElement('a')
				a.href = this.value.data.video
				a.download = 'download'

				const clickHandler = () => {
					setTimeout(() => {
						removeEventListener('click', clickHandler)
					}, 100)
				}

				a.addEventListener('click', clickHandler, false)

				a.click()
			},
		},
		data() {
			return {
				loading: false,
			}
		},
	}
</script>
<style lang="scss" scoped>
	small {
		color: var(--stan-blue-5);
	}
	:not(.new) {
		.lesson-form-wrapper {
			box-shadow: var(--stan-box-shadow);
			border-radius: 10px;
		}
	}
	.remove-module-wrapper {
		button {
			width: 100%;
			@media (min-width: 576px) {
				width: auto;
			}
		}
	}
	.btn-off-gray {
		background-color: var(--stan-blue-8);
		color: var(--stan-blue-6);
		line-height: 1;
		&::v-deep {
			svg {
				path {
					fill: var(--stan-blue-6);
				}
			}
		}
	}
</style>
