<template>
	<div>
		<label v-if="label" class="control-label">{{ label }}</label>
		<div class="d-flex h-100 px-1 control-background align-items-center">
			<AppDropdown
				:key="dropdownKey"
				v-model="country"
				class="col-4 p-0 rounded-left"
				:options="countries"
				:searchable="false"
				:placeholder="$t('Country')"
				image-width="18px"
				:border="false"
				:right-align="false"
				display-label="dialCode"
				label="name"
				:small="true"
				:rounded-icons="true"
				@input="updatePhoneNumber"
				:dropdownRowComponent="phonenumberMenuItem"
			/>
			<div class="col-8 p-0 rounded-right">
				<input
					v-model="phoneNumber"
					class="form-control border-0 rounded-right"
					:placeholder="$t('Phone Number')"
					@input="updatePhoneNumber"
				/>
			</div>
		</div>
		<div class="para-3">
			<span v-if="hasError" class="text-bold error-message"><i></i>{{ error[0] }}</span>
			<span v-if="warning" style="color: #00cfcc"><br v-if="hasError" /><i></i>{{ warning }}</span>
		</div>
	</div>
</template>
<script>
	import { parsePhoneNumber } from 'libphonenumber-js'
	import countryCodes from '../assets/CountryCodes.js'
	import PhoneNumberDropdownItem from './PhoneNumberDropdownItem'

	export default {
		name: 'AppPhoneInput',
		props: {
			label: { type: String, default: '' },
			autoDetectedCountry: { type: String, default: null },
			currentPhone: { type: String, default: '' },
			error: { type: [Array, Object], default: () => [] },
			warning: { type: String, default: '' },
		},
		data() {
			return {
				country: null,
				phoneNumber: '',
				countries: countryCodes,
				dropdownKey: 0,
				phonenumberMenuItem: PhoneNumberDropdownItem,
			}
		},
		mounted() {
			// Fetch country related to their locale country code + list popular country codes
			this.userLocale = navigator.language
			const countryCode = this.userLocale.split('-')[1]

			// Find the object with the specified code
			const updatedCountries = [...countryCodes]
			const index = updatedCountries.findIndex(country => country.code === countryCode)

			if (index !== -1) {
				// Popular codes filter if our country is one of these then push
				const popularCountryCodes = ['AU', 'CA', 'GB', 'US'].filter(code => code !== updatedCountries[index].code)
				popularCountryCodes.push(updatedCountries[index].code)

				// Push all popular countries + the divider
				popularCountryCodes.forEach(code => {
					const country = { ...updatedCountries.find(country => country.code === code) }
					if (country) {
						updatedCountries.unshift(country)
					}
				})

				updatedCountries.splice(popularCountryCodes.length, 0, { type: 'divider' })
				this.countries = updatedCountries
			}
		},
		computed: {
			hasError() {
				return this.error && this.error.length
			},
		},
		beforeMount() {
			if (this.autoDetectedCountry) {
				this.setCountry(this.autoDetectedCountry)
			} else {
				this.setCountry('US')
			}
			if (this.currentPhone) {
				this.setPhoneNumber(this.currentPhone)
			}
		},
		methods: {
			setCountry(code) {
				if (!code || code.length !== 2) return
				const newCountry = this.countries.find(c => c.code === code)
				if (newCountry) {
					this.country = newCountry
				}
			},
			updatePhoneNumber() {
				const internationalNumber = `${this.country?.dialCode}${this.phoneNumber}`
				try {
					let parsedPhoneNumber
					if (this.country) {
						parsedPhoneNumber = parsePhoneNumber(this.phoneNumber, this.country.code)
					} else {
						parsedPhoneNumber = parsePhoneNumber(internationalNumber)
					}
					parsedPhoneNumber.valid = parsedPhoneNumber.isValid()
					this.$emit('updatePhone', parsedPhoneNumber)
					if (parsedPhoneNumber.valid) {
						this.phoneNumber = parsedPhoneNumber.formatNational()
						if (parsedPhoneNumber.country) {
							this.country = this.countries.find(c => c.code === parsedPhoneNumber.country)
							this.dropdownKey += 1
						}
					}
				} catch (error) {
					this.$emit('updatePhone')
				}
			},
			setPhoneNumber(phone) {
				if (phone) {
					try {
						const userPhone = parsePhoneNumber(phone)
						this.setCountry(userPhone.country)
						this.phoneNumber = userPhone.formatNational()
					} catch (error) {
						if (error.name !== 'ParseError') {
							this.$logError(error)
						}
					}
				}
			},
		},
	}
</script>

<style lang="scss" scoped>
	.error-message {
		color: var(--stan-text-negative-color);
	}
	.control-background {
		border-radius: 8px;
		background: var(--stan-gray-light-color);
	}

	.dropdown-wrapper::v-deep {
		.app-dropdown-menu {
			.menu-content {
				font-size: inherit;
				width: 320px;
				max-height: 30vh;
			}
		}
	}
</style>
