<template>
	<AppModal id="renameModule" title="Module Name" @close="closeModalResetData" @open="createDataBackup">
		<div class="form-group mb-4">
			<AppInput
				:label="$t('Module Title')"
				v-model="newTitleValue"
				:placeholder="$t('Module Name')"
				:id="`title`"
				maxlength="100"
				:class="{ 'form-error': $v.newTitleValue.$invalid }"
			/>
		</div>

		<div class="d-flex flex-wrap justify-content-end mt-3">
			<AppButton name="save-rename-module-modal" class="py-2 md-flat ml-2 mt-1" style="width: 100px" @click="updateModuleNameAndCloseModal">{{
				$t('Confirm')
			}}</AppButton>
		</div>
	</AppModal>
</template>
<script>
	import axios from 'axios'
	// eslint-disable-next-line import/extensions
	import { required } from 'vuelidate/lib/validators'

	export default {
		props: {
			value: {
				type: Object,
				default: () => {},
			},
			validation: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				newTitleValue: undefined,
			}
		},
		validations() {
			return {
				newTitleValue: { required },
			}
		},
		methods: {
			closeModal() {
				$('#moduleRenameModal').modal('hide')
				this.$emit('close-modal')
			},
			createDataBackup() {
				this.newTitleValue = this.value.data.title
			},
			closeModalResetData() {
				this.createDataBackup()
				this.closeModal()
			},
			async updateModuleNameAndCloseModal() {
				const moduleId = this.value.module_id
				this.$v.$touch()
				if (this.$v.$invalid) {
					return
				}
				if (isNaN(moduleId) || moduleId < 0) {
					const newValue = { ...this.value }
					newValue.data.title = this.newTitleValue

					this.$emit('valueChange', newValue)
					this.closeModal()
					return
				}

				let response = {}
				try {
					response = await axios.put(`v1/courses/modules/${moduleId}`, { data: { title: this.newTitleValue } })
					if (this.newTitleValue) {
						const newValue = { ...this.value }
						newValue.data.title = this.newTitleValue

						this.$emit('valueChange', newValue)
						this.newTitleValue = undefined
					}
					let statusObj = {}
					if (response.status !== 200) {
						console.error(response)
						statusObj = {
							type: 'error', // can be 'info', 'warning', 'success', 'error'
							title: this.$t('Renaming failed!'),
							text: this.$t('Something went wrong, please try again later.'),
							duration: 5000,
						}
						return
					}
					statusObj = {
						type: 'success',
						title: 'Updated!',
						text: 'Module name updated.',
						duration: 5000,
					}

					this.$stanNotify(statusObj)
					this.closeModal()
				} catch (error) {
					console.error(error)
					this.$stanNotify({
						type: 'error', // can be 'info', 'warning', 'success', 'error'
						title: this.$t('Wait up!'),
						text: this.$t('Something went wrong, please try again later.'),
						duration: 5000,
					})
				}
			},
		},
		watch: {
			value: {
				deep: true,
				handler(newVal) {
					this.newTitleValue = newVal.data.title
				},
			},
		},
	}
</script>

<style lang="scss" scoped>
	label {
		color: var(--stan-primary-dark-color);
		font-size: 14px;
	}
</style>
