<template>
	<div>
		<span class="stanley-typing-text">{{ typedText }}</span>
	</div>
</template>

<script>
	export default {
		props: {
			text: {
				type: String,
				required: true,
			},
			typingSpeed: {
				type: Number,
				default: 100,
			},
			typing: {
				type: Boolean,
				default: true,
			},
		},
		mounted() {
			this.typeText(this.text)
		},
		data() {
			return {
				typedText: '',
			}
		},
		methods: {
			typeText(text) {
				if (!this.typing) {
					// If typing is false, just set the text
					this.typedText = text
					return
				}
				const words = text.trim().split(' ')
				let currentIndex = 0
				const intervalId = setInterval(() => {
					this.typedText = `${this.typedText} ${words[currentIndex]}`.trim()
					currentIndex++
					if (currentIndex === words.length) {
						clearInterval(intervalId)
					}
				}, this.typingSpeed)
			},
		},
		computed: {
			match() {
				return this.typedText.trim().localeCompare(this.text.trim())
			},
		},
		watch: {
			typedText() {
				if (this.match === 0) {
					this.$emit('doneTyping')
				}
			},
		},
	}
</script>

<style lang="scss" scoped>
	.stanley-typing-text {
		overflow: hidden;
	}
</style>
