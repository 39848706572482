var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      attrs: {
        id: "booking-block-time-modal",
        action: _vm.actionText,
        title: _vm.title,
        subheading: _vm.subheading,
        actionColor: _vm.primaryActionColor,
        "secondary-action": _vm.secondaryActionText,
        secondaryActionColor: _vm.secondaryActionColor,
        overflow: true,
        loading: _vm.loading,
        transitionKey: _vm.transitionKey,
        hideDataBackdrop: _vm.hideDataBackdrop
      },
      on: {
        close: _vm.closeModal,
        cta: _vm.nextStep,
        "secondary-cta": _vm.secondaryNextStep
      }
    },
    [
      _c(
        "div",
        { key: _vm.resetKey, staticClass: "pt-4 pb-4" },
        [
          _vm.showExistingView || _vm.showDeleteView
            ? [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex w-100 justify-content-center align-items-center para-3 existing-date-container"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-50 d-flex justify-content-end" },
                      [
                        _c("div", { staticClass: "existing-date-wrapper" }, [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Starting")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "date" },
                            [
                              _c("InlineSvg", {
                                staticClass: "mr-2",
                                attrs: {
                                  src: "/images/calendar-input-icon.svg"
                                }
                              }),
                              _vm._v(
                                _vm._s(
                                  _vm.formatExistingDate(
                                    _vm.startDate,
                                    _vm.endDate
                                  )
                                ) + "\n\t\t\t\t\t\t"
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "time" },
                            [
                              _c("InlineSvg", {
                                staticClass: "mr-2",
                                attrs: { src: "/images/balance-time.svg" }
                              }),
                              _vm._v(_vm._s(_vm.formatTime(_vm.startTimeValue)))
                            ],
                            1
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "divider" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-50 d-flex justify-content-start" },
                      [
                        _c("div", { staticClass: "existing-date-wrapper" }, [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Ending")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "date" },
                            [
                              _c("InlineSvg", {
                                staticClass: "mr-2",
                                attrs: {
                                  src: "/images/calendar-input-icon.svg"
                                }
                              }),
                              _vm._v(
                                _vm._s(
                                  _vm.formatExistingDate(
                                    _vm.endDate,
                                    _vm.startDate
                                  )
                                ) + "\n\t\t\t\t\t\t"
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "time" },
                            [
                              _c("InlineSvg", {
                                staticClass: "mr-2",
                                attrs: { src: "/images/balance-time.svg" }
                              }),
                              _vm._v(_vm._s(_vm.formatTime(_vm.endTimeValue)))
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  ]
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.showUpdateCreateView
            ? [
                _c(
                  "div",
                  {
                    staticClass:
                      "slot-section d-flex flex-wrap gap w-100 justify-content-start align-items-start"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-50" },
                      [
                        _c("label", { staticClass: "control-label mb-1" }, [
                          _vm._v("From")
                        ]),
                        _vm._v(" "),
                        _c("AppFlatPickr", {
                          ref: "calendar",
                          attrs: {
                            custom_class: "form-control",
                            placeholder: "Start Date",
                            disableMobile: true,
                            min_date: _vm.minDateStart
                          },
                          on: { onDateSelect: _vm.onDateTimeSelected },
                          model: {
                            value: _vm.startDate,
                            callback: function($$v) {
                              _vm.startDate = $$v
                            },
                            expression: "startDate"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "para-3",
                            staticStyle: { "min-width": "85px" }
                          },
                          [
                            _vm.errors.startDate
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "var(--stan-text-negative-color)"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.errors.startDate))]
                                )
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "datetime-dropdown time mt-3" },
                          [
                            _c("img", {
                              staticClass: "input-icon mr-2",
                              attrs: { src: "/images/balance-time.svg" }
                            }),
                            _vm._v(" "),
                            _c("AppDropdown", {
                              staticClass: "form-control",
                              attrs: {
                                placeholder: "Start Time",
                                options: _vm.startTimeOptions,
                                searchable: false,
                                label: "name",
                                reduce: "value",
                                rightAlign: false,
                                disabled: _vm.startTimeOptions.length < 1
                              },
                              on: { select: _vm.onDateTimeSelected },
                              model: {
                                value: _vm.startTimeValue,
                                callback: function($$v) {
                                  _vm.startTimeValue = $$v
                                },
                                expression: "startTimeValue"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "para-3",
                            staticStyle: { "min-width": "85px" }
                          },
                          [
                            _vm.errors.startTimeValue
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "var(--stan-text-negative-color)"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.errors.startTimeValue))]
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-50" },
                      [
                        _c("label", { staticClass: "control-label mb-1" }, [
                          _vm._v("To")
                        ]),
                        _vm._v(" "),
                        _c("AppFlatPickr", {
                          ref: "calendar",
                          attrs: {
                            custom_class: "form-control",
                            placeholder: "End Date",
                            disableMobile: true,
                            min_date: _vm.minDateEnd
                          },
                          on: { onDateSelect: _vm.onDateTimeSelected },
                          model: {
                            value: _vm.endDate,
                            callback: function($$v) {
                              _vm.endDate = $$v
                            },
                            expression: "endDate"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "para-3",
                            staticStyle: { "min-width": "85px" }
                          },
                          [
                            _vm.errors.endDate
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "var(--stan-text-negative-color)"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.errors.endDate))]
                                )
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "datetime-dropdown time mt-3" },
                          [
                            _c("img", {
                              staticClass: "input-icon mr-2",
                              attrs: { src: "/images/balance-time.svg" }
                            }),
                            _vm._v(" "),
                            _c("AppDropdown", {
                              staticClass: "form-control",
                              attrs: {
                                placeholder: "End Time",
                                options: _vm.endTimeOptions,
                                searchable: false,
                                label: "name",
                                reduce: "value",
                                rightAlign: false,
                                disabled: _vm.endTimeOptions.length < 1
                              },
                              on: { select: _vm.onDateTimeSelected },
                              model: {
                                value: _vm.endTimeValue,
                                callback: function($$v) {
                                  _vm.endTimeValue = $$v
                                },
                                expression: "endTimeValue"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "para-3",
                            staticStyle: { "min-width": "85px" }
                          },
                          [
                            _vm.errors.endTimeValue
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "var(--stan-text-negative-color)"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.errors.endTimeValue))]
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }