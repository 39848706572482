<template>
	<Draggable
		v-model="reviews"
		v-bind="{ animation: 0, ghostClass: 'ghost' }"
		:delay="250"
		:delayOnTouchOnly="true"
		draggable=".draggable-section-node"
		handle=".drag-handle"
	>
		<div class="d-flex review-wrapper draggable-section-node" v-for="(review, index) in reviews" :key="index">
			<div class="col px-0">
				<div class="d-flex flex-wrap">
					<div style="display: flex; justify-content: space-between; width: 100%; gap: 20px;">
						<div class="drag-icon ">
							<InlineSvg class="user-select-none drag-handle" src="/images/six-dots.svg" />
						</div>
						<div class="w-100 px-0 d-flex justify-content-between mb-4">
							<div :class="{ 'form-error': v.$each[index].rating.$error }">
								<StarRating
									v-model="review.rating"
									:star-size="24"
									:increment="0.5"
									:max-rating="5"
									:show-rating="false"
									active-color="#FEC337"
								></StarRating>
							</div>
							<div>
								<AppButton
									size="xs"
									name="remove-review"
									class="d-none d-md-block btn-remove-discount"
									outline
									color="gray"
									@click="removeReview(index)"
									icon="/images/icons/ic-trash.svg"
								>
									Delete
								</AppButton>
								<button class="d-md-none btn-remove-discount" @click="removeReview(index)">
									<InlineSvg width="24px" height="24px" src="/images/icons/ic-trash.svg" />
								</button>
							</div>
						</div>
					</div>
					<div class="col-12 px-0">
						<div class="review-input-layout">
							<div class="col px-0 d-flex flex-column v-gap" :class="{ 'order-2': newDesign }">
								<div :class="{ 'form-error': v.$each[index].name.$error }">
									<AppInput
										:label="nameLabel"
										class="mb-2"
										maxlength="40"
										:show-character-count="false"
										:placeholder="$t('Name')"
										v-model="review.name"
										@blur="v.$each[index].name.$touch"
									/>
								</div>
								<div :class="{ 'form-error': v.$each[index].description.$error }">
									<label v-if="!newDesign" class="control-label">{{ $t('Review Text') }} *</label>
									<textarea
										class="form-control mb-2 mb-xl-0"
										:placeholder="$t('Text')"
										rows="5"
										v-model="review.description"
										@blur="v.$each[index].description.$touch"
									/>
								</div>
							</div>
							<div class="px-0" :class="{ 'col-xl-8 ': !newDesign, 'col-auto order-1': newDesign }">
								<div class="mb-2">
									<FileDropZone
										:title="fileDropTitle"
										:image="review.image"
										:hideProgress="true"
										:crop-aspect-ratio="1"
										@start-upload="fileUploadStarted"
										@uploaded="payload => reviewImageUploaded(payload, index)"
										@delete-file="payload => deleteReviewFileImage(payload, index)"
										@cancel-file="payload => $emit('cancel-file', payload)"
										class="user-img-drop-zone"
										accept="image"
										:class="{ 'compact new': newDesign }"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<AppButton name="add-review" outline @click="addReview" size="medium" class="w-100">{{ $t('+ Add customer review') }}</AppButton>
	</Draggable>
</template>
<script>
	import FileDropZone from '~/components/uploads/FileDropZone'

	export default {
		name: 'ReviewForm',
		components: {
			FileDropZone,
		},
		props: {
			value: {
				type: Array,
				default: () => [],
			},
			v: {
				type: Object,
				required: true,
			},
			newDesign: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				reviewTemplate: { name: '', description: '', image: this.$constants.PUBLIC_RESOURCES.DEFAULT_REVIEW_IMAGE, rating: 5 },
			}
		},
		computed: {
			fileDropTitle() {
				return this.newDesign ? '' : this.$t('User Photo')
			},
			nameLabel() {
				return this.newDesign ? '' : `${this.$t('Customer Name')} *`
			},
			reviews: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit('input', val)
				},
			},
		},
		methods: {
			addReview() {
				const cf = this.$lodash.cloneDeep(this.reviewTemplate)
				this.reviews.push(cf)
			},
			removeReview(index) {
				if (this.reviews) {
					this.reviews.splice(index, 1)
				}
			},
			fileUploadStarted() {
				this.$emit('uploadingImage')
			},
			reviewImageUploaded(payload, index) {
				this.$emit('imageUploaded', payload)
				this.reviews[index].image = payload.src
			},
			deleteReviewFileImage(payload, index) {
				this.reviews[index].image = this.$constants.PUBLIC_RESOURCES.DEFAULT_REVIEW_IMAGE
				this.$emit('imageDeleted', payload)
			},
		},
	}
</script>
<style lang="scss" scoped>
	.review-wrapper {
		border: 1px solid var(--stan-primary-light-color);
		padding: 20px;
		border-radius: 15px;
		margin-bottom: 1rem;
		background-color: var(--stan-white);
		gap: 15px;
		.drag-icon {
			svg {
				width: 15px;
				height: auto;
			}
		}
	}
	.rounded-close-button {
		width: 25px;
		height: 25px;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		min-width: 25px;
		font-weight: bold;
		font-size: 20px;
		background: var(--stan-danger);
		@media (min-width: 392px) {
			margin-left: -12px;
		}
	}
	.btn-remove-discount {
		color: var(--stan-gray-dark-color);
		::v-deep {
			svg {
				path {
					fill: var(--stan-text-light-color);
				}
			}
		}
	}
	.vue-star-rating::v-deep {
		.vue-star-rating-star {
			display: block;
			svg linearGradient {
				stop:nth-child(1) {
					stop-color: rgb(254, 195, 55) !important;
				}

				stop:nth-child(2) {
					stop-color: rgb(216, 216, 216) !important;
				}
			}
		}
	}
	.drag-handle {
		&:hover {
			cursor: pointer;
		}
	}

	.review-input-layout {
		display: flex;
		flex-direction: row;
		gap: 15px;

		@media (max-width: 575px) {
			align-items: center;
			flex-direction: column;
		}
	}
</style>
