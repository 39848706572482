<template>
	<div>
		<div
			class="feedback-wrapper d-flex"
			v-if="message.response_id && message.response_id !== 'def'"
			:class="{ 'has-feedback': message.feedback_status === -1 || message.feedback_status === 1 }"
		>
			<div class="feedback-button mr-1" :class="{ selected: message.feedback_status === 1 }" @click="sendMessageFeedback(message, 1)">
				<InlineSvg class="no-feedback" src="/images/icons/ic-thumbs-up-outline.svg" />
				<InlineSvg class="chosen" src="/images/icons/ic-thumbs-up-solid.svg" />
			</div>
			<div class="feedback-button mr-1" :class="{ selected: message.feedback_status === -1 }" @click="sendMessageFeedback(message, -1)">
				<InlineSvg class="no-feedback" src="/images/icons/ic-thumbs-down-outline.svg" />
				<InlineSvg class="chosen" src="/images/icons/ic-thumbs-down-solid.svg" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			message: {
				type: Object,
				required: true,
			},
		},
		methods: {
			sendMessageFeedback(response, feedback) {
				if ([-1, 1].includes(response.feedback_status)) {
					return
				}
				this.message.feedback_status = feedback // for instant response
				if (response.response_id && [-1, 1].includes(feedback)) {
					const payload = {
						response_id: parseInt(response.response_id, 10),
						feedback_status: feedback,
					}
					this.$axios.post('/v1/chat-ai/give-feedback', payload, { baseURL: this.$configSettings.chatAIApiUrl }).catch(() => {
						this.message.feedback_status = 0
					})
				}
			},
		},
	}
</script>

<style lang="scss" scoped>
	.feedback-wrapper {
		margin-top: 5px;
		.feedback-button {
			svg {
				display: none;
				width: 20px;
				height: 20px;
			}
			.no-feedback {
				display: block;
			}
			cursor: pointer;
		}
		&.has-feedback {
			.feedback-button {
				cursor: unset;
				.no-feedback {
					opacity: 0.5;
				}
				&.selected {
					.chosen {
						display: block;
					}
					.no-feedback {
						display: none;
					}
				}
			}
		}
	}
</style>
