var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AppModal", {
    attrs: {
      id: "grid-switch-warning-modal",
      title: "Heads up!",
      subheading:
        "Unfortunately, Email Magnets cannot yet be placed inside of Grid sections.",
      action: "OK"
    },
    on: { cta: _vm.closeModal, close: _vm.closeModal }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }