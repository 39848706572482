<template>
	<div class="position-relative cursor-pointer d-flex flex-row justify-content-between align-self-center plan-options-container">
		<div
			class="plan-option-selected bg-white position-absolute d-flex justify-content-center align-items-center h-100"
			:class="{
				'yearly-plan': isYearlyPlan,
				'monthly-plan': !isYearlyPlan,
			}"
		>
			<span class="para-2 text-bold text-light">
				{{ isYearlyPlan ? $t('Annual (Save 20%)') : $t('Monthly') }}
			</span>
		</div>
		<span class="para-2 text-bold text-light monthly-plan-unselected" @click="handleToggleInterval($constants.TIER_INTERVAL.MONTH)">{{
			$t('Monthly')
		}}</span>
		<span class="para-2 text-bold text-light yearly-plan-unselected" @click="handleToggleInterval($constants.TIER_INTERVAL.YEAR)">{{
			$t('Annual (Save 20%)')
		}}</span>
	</div>
</template>
<script>
	export default {
		name: 'PlanIntervalSelector',
		props: {
			interval: {
				default: '',
				type: String,
			},
		},
		computed: {
			currentInterval() {
				return this.interval
			},
			isYearlyPlan() {
				return this.interval === this.$constants.TIER_INTERVAL.YEAR
			},
		},
		methods: {
			handleToggleInterval(newInterval) {
				if (newInterval !== this.interval) {
					this.$emit('toggleInterval', newInterval)
				}
			},
		},
	}
</script>

<style lang="scss" scoped>
	.plan-options-container {
		background-color: var(--stan-gray-soft-color);
		border-radius: 50px;
		width: 270px;
	}

	.monthly-plan-unselected {
		padding: 12px 0px 12px 23px;
	}

	.yearly-plan-unselected {
		padding: 12px 23px 12px 0px;
	}

	.plan-option-selected {
		top: 0;
		left: 0;
		transition: transform 0.3s ease;
		border-radius: 25px;
		box-shadow: 0px 5px 20px 0px #00106d1a, 0px -5px 10px 0px #00000005 inset; // Not --stan-box-shadow

		&.monthly-plan {
			width: 100px;
		}

		&.yearly-plan {
			width: 180px;
			transform: translateX(90px);
		}
	}
</style>
