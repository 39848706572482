<template>
	<AppBanner class="w-100 position-relative overflow-hidden pop-animation" :title="title" :icon="icon" :rightIcon="null" severity="stan-info">
		<AppProgress
			class="position-absolute"
			style="max-height: 4px;  bottom:0; left:0; right:0"
			barColor="var(--stan-primary-soft-color)"
			progressColor="var(--stan-primary-primary-color)"
			:value="progress"
		/>
	</AppBanner>
</template>
<script>
	export default {
		props: {
			progress: {
				type: Number,
				default: 0,
			},
			title: {
				type: String,
			},
			icon: {
				type: String,
			},
		},
	}
</script>

<style scoped lang="scss">
	.amount {
		color: var(--stan-text-positive-color);
		font-weight: 600;
	}

	.desktop::v-deep {
		th:first-child {
			width: 20%;
		}

		th:nth-child(2) {
			width: 20%;
		}

		th:nth-child(3) {
			width: 15%;
		}

		td:nth-child(4),
		th:nth-child(4) {
			width: 10%;
			text-align: right;
		}

		td:nth-child(5),
		th:nth-child(5) {
			width: 15%;
			text-align: right;
		}

		th:nth-child(6),
		td:nth-child(6) {
			width: 20%;
			text-align: right;
		}
	}

	@media screen and (max-width: 745px) {
		.mobile-column {
			display: none;
		}
	}

	@keyframes pop {
		0% {
			transform: scale(0); /* Start from nothing */
			opacity: 0; /* Invisible */
		}
		50% {
			transform: scale(1.01);
			opacity: 1; /* Invisible */
		}
		100% {
			transform: scale(1); /* Settle to original size */
			opacity: 1; /* Invisible */
		}
	}

	/* Applying Animation */
	.pop-animation {
		animation: pop 0.5s cubic-bezier(0.4, 0, 0.2, 1); /* Smooth ease-out effect */
		transform-origin: center; /* Ensure scaling happens from the center */
	}
</style>
