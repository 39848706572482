<template>
	<svg class="animated-check" viewBox="0 0 24 24" :style="{ height: this.size, width: this.size }">
		<path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
	</svg>
</template>

<script>
	export default {
		name: 'AppCheck',
		props: {
			size: { type: String, default: '18px' },
		},
	}
</script>

<style lang="scss" scoped>
	.animated-check path {
		fill: none;
		stroke: var(--stan-persian-green-2);
		stroke-width: 4;
		stroke-dasharray: 23;
		stroke-dashoffset: 23;
		animation: draw 0.5s linear forwards;
		stroke-linecap: round;
		stroke-linejoin: round;
	}

	@keyframes draw {
		to {
			stroke-dashoffset: 0;
		}
	}
</style>
