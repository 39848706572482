var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("NodeViewWrapper", { attrs: { as: "span" } }, [
    _c(
      "div",
      {
        ref: "resizableImageNode",
        staticStyle: { position: "relative" },
        style: _vm.appliedStyleForMenu
      },
      [
        _vm.isModifyingImage && _vm.showResize && !_vm.isMobile
          ? _c(
              "div",
              { staticClass: "d-block", style: _vm.appliedStyleForMenu },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "menu-bar pl-3 pr-2 py-2 d-flex flex-wrap align-items-center resize-panel justify-content-end"
                  },
                  [
                    _vm.showAlignOptions
                      ? _c(
                          "div",
                          { staticClass: "panel-element panel-element-align" },
                          [
                            _c("InlineSvg", {
                              staticClass: "menu-icon",
                              attrs: {
                                src: "/images/icons/ic-align-left.svg",
                                fill: "white"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.updateAlign(_vm.alignLeft, true)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showAlignOptions
                      ? _c(
                          "div",
                          { staticClass: "panel-element panel-element-align" },
                          [
                            _c("InlineSvg", {
                              staticClass: "menu-icon",
                              attrs: {
                                src: "/images/icons/ic-align-center.svg",
                                fill: "white"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.updateAlign(_vm.alignCenter, true)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showAlignOptions
                      ? _c(
                          "div",
                          { staticClass: "panel-element panel-element-align" },
                          [
                            _c("InlineSvg", {
                              staticClass: "menu-icon",
                              attrs: {
                                src: "/images/icons/ic-align-right.svg",
                                fill: "white"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.updateAlign(_vm.alignRight, true)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "img",
          _vm._b(
            {
              key: _vm.node.attrs.src,
              ref: "resizableImg",
              attrs: {
                width: _vm.displayWidth,
                draggable: _vm.isDraggable,
                "data-drag-handle": _vm.isDraggable
              }
            },
            "img",
            _vm.node.attrs,
            false
          )
        ),
        _vm._v(" "),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isModifyingImage && _vm.showResize && !_vm.isMobile,
              expression: "isModifyingImage && showResize && !isMobile"
            }
          ],
          ref: "rightResizeHandler",
          staticClass: "right-resize-handler",
          style: _vm.resizeHandlerStyle
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }