<template>
	<div class="d-flex flex-wrap align-items-top app-checkbox">
		<input :id="elId" v-model="value" type="checkbox" :value="value" @input="$emit('input', $event.target.checked)" />
		<label :for="elId" class="ml-2" style=""
			><slot>{{ label }}</slot></label
		>
	</div>
</template>

<script>
	import { UUID } from '~/store/modules/Global.js'

	export default {
		name: 'AppCheckbox',
		props: {
			value: Boolean,
			label: String,
		},
		data() {
			return { elId: UUID() }
		},
	}
</script>

<style lang="scss" scoped>
	.app-checkbox > * {
		cursor: pointer;
		pointer-events: all;
	}
	input {
		border-radius: 3px;
		appearance: none;
		-moz-appearance: none;
		-webkit-appearance: none;
		cursor: pointer;
		width: 15px;
		height: 15px;
	}
	input[type='checkbox']:checked {
		background-color: var(--stan-primary-primary-color);
		background: var(--stan-primary-primary-color)
			url('data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==') 4px 4px no-repeat;
		background-size: 7px 7px;
	}
	input:not(:checked) {
		border: 1px solid var(--stan-primary-primary-color);
	}
	label {
		max-width: 90%;
		font-size: 0.75rem;
		line-height: 16px;
		margin-bottom: 0;
	}
</style>
