/* eslint-env browser */
const LoadScript = {
	install(Vue) {
		// eslint-disable-next-line no-multi-assign,func-names
		Vue.loadScript = Vue.prototype.$loadScript = function(src, options) {
			// eslint-disable-line no-param-reassign
			return new Promise((resolve, reject) => {
				let shouldAppend = false
				let el = document.querySelector(`script[src="${src}"]`)
				if (!el) {
					el = document.createElement('script')
					el.type = 'text/javascript'
					// el.defer = true
					el.async = true
					el.src = src
					el.setAttribute('data-api', options.api)
					el.setAttribute('data-click', options.click)
					el.setAttribute('data-domain', options.domain)
					el.setAttribute('data-token', options.token)
					shouldAppend = true
				} else if (el.hasAttribute('data-loaded')) {
					resolve(el)
					return
				}
				el.addEventListener('error', reject)
				el.addEventListener('abort', reject)
				el.addEventListener('load', () => {
					el.setAttribute('data-loaded', true)
					resolve(el)
				})
				if (shouldAppend) document.head.appendChild(el)
			})
		}
		// eslint-disable-next-line no-multi-assign,func-names
		Vue.unloadScript = Vue.prototype.$unloadScript = function(src) {
			// eslint-disable-line no-param-reassign
			return new Promise((resolve, reject) => {
				const el = document.querySelector(`script[src="${src}"]`)
				if (!el) {
					reject()
					return
				}
				document.head.removeChild(el)
				resolve()
			})
		}
	},
}

export default LoadScript
