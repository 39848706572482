<template>
	<div class="">
		<div class="button-layout-wrapper mb-3 flex-wrap justify-content-center justify-content-md-start" v-if="showButtonLayout">
			<div class="col-auto p-0">
				<input type="radio" id="button" value="button" v-model="value.layout" />
				<label for="button"> <img src="/images/icons/ic-button.svg" />{{ $t('Button') }}</label>
			</div>
			<div class="col-auto p-0">
				<input type="radio" id="callout" value="callout" v-model="value.layout" />
				<label for="callout"> <img src="/images/icons/ic-callout.svg" />{{ $t('Callout') }}</label>
			</div>
			<div class="col-auto p-0" v-if="showPreview">
				<input type="radio" id="proview" value="preview" v-model="value.layout" />
				<label for="proview"> <img src="/images/icons/ic-video.svg" />{{ $t('Preview') }}</label>
			</div>
		</div>
		<div class="mb-3">
			<FileDropZone
				:title="$t('Button Image')"
				:image="value.image"
				drop-zone-class="header-image-drop-zone"
				@uploaded="payload => $emit('imageUploaded', payload)"
				@delete-file="payload => $emit('delete-file', payload)"
				@cancel-file="payload => $emit('cancel-file', payload)"
				data-test-id="upload-button-file-button"
				:crop-aspect-ratio="1"
				accept="image"
			/>
		</div>
		<div class="c" v-if="['callout', 'preview'].includes(value.layout)">
			<AppInput
				class="form-group mb-3"
				:label="$t('Heading')"
				maxlength="50"
				:placeholder="$t('Input Heading Here')"
				v-model="value.heading"
				required=""
			/>
			<AppInput
				class="form-group mb-3"
				:label="$t('Subheading')"
				maxlength="100"
				:placeholder="$t('Enter a description for your followers here')"
				v-model="value.tagline"
			/>
		</div>
		<AppInput
			class="form-group mb-3"
			:class="{ 'form-error': validation.title.$error }"
			:label="$t('Button Title *')"
			maxlength="30"
			:placeholder="$t('Button Text Here...')"
			v-model="value.title"
		/>
	</div>
</template>
<script>
	import FileDropZone from '~/components/uploads/FileDropZone'

	export default {
		name: 'PageButtonForm',
		props: {
			value: {
				type: Object,
				default: () => {},
			},
			validation: {
				type: Object,
				default: () => {},
			},
			showButtonLayout: {
				type: Boolean,
				default: true,
			},
		},
		components: {
			FileDropZone,
		},
		computed: {
			showPreview() {
				return ['meeting', 'webinar', 'course', 'digital-download'].includes(this.$route.params.slug)
			},
		},
	}
</script>

<style lang="scss" scoped>
	.button-layout-wrapper {
		display: flex;
		align-items: center;
		gap: 20px;
		input[type='radio'] {
			display: none;
		}
		label {
			display: flex;
			align-items: center;
			gap: 10px;
			margin-bottom: 0;
			position: relative;
			&:hover {
				cursor: pointer;
			}
			&::before {
				content: '';
				width: 24px;
				height: 24px;
				border: 2px solid var(--stan-gray-dark-color);
				padding: 1px;
				border-radius: 50%;
				flex: 0 0 auto;
			}
			&::after {
				content: '';
				width: 12px;
				height: 12px;
				position: absolute;
				left: 6px;
				background-color: var(--stan-white);
				padding: 1px;
				border-radius: 50%;
			}
		}
		input:checked + label {
			&::before {
				border-color: var(--stan-primary-primary-color);
			}
			&::after {
				background-color: var(--stan-primary-primary-color);
			}
		}
	}
</style>
