<template>
	<div class="position-relative">
		<div :class="{ 'creator-pro-feature-not-allowed': !isAffiliateShareAllowed }">
			<div class="affiliate-link-resell-rights-section">
				<div class="header d-flex justify-content-between w-100">
					<div class="toggle">
						<AppToggle class="ml-2 mb-0" v-model="value.enabled" />
						<div class="toggle-label">
							<div class="toggle-label-header ">
								<InlineSvg src="/images/icons/ic-logout.svg" class="menu-item-icon" />
								<div class="para-2 text-bold">Resell Rights</div>
								<div class="new-highlighter para-4 text-bold text-white">NEW</div>
							</div>
							<div class="toggle-label-subtext para-3 text-light">
								Allow anyone who purchases to immediately resell your product
							</div>
						</div>
					</div>
					<div class="ml-2">
						<AppButton size="sm" outline @click="navigateToAffiliateAnalytics">
							Affiliate Analytics
						</AppButton>
					</div>
				</div>

				<div class="toggle-expand" v-if="value.enabled">
					<div class="revenue-share">
						<div class="para-4 text-bold">Enter Your Desired Resell / Affiliate %</div>
						<div class="d-flex align-items-center">
							<AppInput
								id="affiliate_share_resell_percent"
								class="mb-1 affiliate_share_percent_input"
								:class="{ 'form-error': validation.percentage.$error }"
								v-model="value.percentage"
								type="number"
								:placeholder="$t('ex. 100')"
								suffix="%"
								:min="percent_min"
								:max="percent_max"
							/>
							<div class="para-3">of sales revenue will go to your reseller</div>
						</div>
						<div>
							<small class="stan-text-danger error" v-if="validation.percentage.$error">
								Percentage must be between {{ percent_min }}% and {{ percent_max }}%
							</small>
						</div>
					</div>
					<div class="affiliate-share-message">
						<div class="icon">
							<InlineSvg src="/images/icons/ic-info.svg" />
						</div>
						<div class="para-3">
							<span class="text-bold">You've turned on Resale Rights.</span> Upon purchase, your buyer will receive a unique affiliate
							link to be able to resell your product at {{ value.percentage }}% profit share. Learn More
						</div>
					</div>
				</div>
			</div>
		</div>
		<AppUpgrade title="Want to distribute Resell Rights for your product?" v-if="!isAffiliateShareAllowed" />
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import { nanoid } from 'nanoid'
	import AppUpgrade from '~/components/shared/AppUpgrade'

	export default {
		name: 'ResellRightsForm',
		components: {
			AppUpgrade,
		},
		props: {
			value: {
				type: Object,
				default: () => {},
			},
			validation: {
				type: Object,
				default: () => {},
			},
			pageId: {
				type: Number,
				default: -1,
			},
		},
		mounted() {},
		data() {
			return {
				percent_min: 1,
				percent_max: 90,
			}
		},
		computed: {
			...mapGetters('Stores', ['getStore']),
			...mapGetters('Auth', ['user']),
			affiliateShareLinkCodeLength() {
				return 6
			},
			isAffiliateShareAllowed() {
				return this.user.subscription_tier_allowed_modules?.indexOf('affiliate-share') !== -1
			},
		},
		methods: {
			...mapActions('Auth', ['fetchUser']),
			dropDownOptions(affiliateShare, pageId) {
				const out = []
				if (pageId > 0) {
					out.push({ label: this.$t('Copy URL'), handler: this.copyAffiliateUrl })
				}
				out.push({ label: this.$t('Delete'), handler: this.deleteAffiliateShare })
				return out
			},
			copyAffiliateUrl(affiliateShare) {
				const url = this.buildLink(affiliateShare.code)
				this.$clipboard(url)
				this.clipboardSuccessHandler()
			},
			deleteAffiliateShare(affiliateShare, index) {
				this.$emit('removeAffiliateShare', index)
			},
			clipboardSuccessHandler() {
				this.$stanNotify({
					type: 'success', // can be 'info', 'warning', 'success', 'error'
					title: this.$t('URL Copied!'),
					duration: 2000,
				})
			},
			getAffiliateCode(affiliateShare) {
				const deletedRecord = this.value.find(link => link.percent === affiliateShare.percent && link.status === 'deleted')
				if (deletedRecord) {
					return deletedRecord.code
				}
				affiliateShare.code = nanoid(this.affiliateShareLinkCodeLength)
				return affiliateShare.code
			},
			getAffiliateShareLink(affiliateShare) {
				const code =
					affiliateShare.code.length === this.affiliateShareLinkCodeLength ? affiliateShare.code : this.getAffiliateCode(affiliateShare)
				return this.buildLink(code)
			},
			buildLink(code) {
				if (this.pageId <= 0) return this.$t('Your affiliate link will be ready once you publish this product.')
				let url = `${this.siteSettings.frontDomain}${this.user.username}/${this.getStore?.slug}/page/${this.pageId}`
				if (this.pageSlug) {
					url = `${this.siteSettings.frontDomain}${this.user.username}/p/${this.pageSlug}`
				}
				return `${url}?al=${code}`
			},
			changePercent(index) {
				const currPercent = this.value[index].percent
				let deletedRecord
				let deletedRecordIndex
				this.value.every((link, index) => {
					if (link.percent === currPercent && link.status === 'deleted') {
						deletedRecord = link
						deletedRecordIndex = index
						return false
					}
					return true
				})

				if (deletedRecord && deletedRecordIndex >= 0) {
					this.value[index] = { ...deletedRecord }
					this.value[index].status = 'active'
					this.$emit('removeAffiliateShare', deletedRecordIndex)
				}
				this.validation.$touch()
			},
			openLink(url) {
				window.open(url, '_blank')
			},
			navigateToAffiliateAnalytics() {
				this.$router.push({ name: 'affiliate_analytics', params: { page_id: this.pageId } })
			},
		},
		watch: {},
	}
</script>

<style lang="scss" scoped>
	.affilate-link-section {
		font-style: normal;
		font-size: 14px;

		.sub-title {
			font-weight: 700;
			line-height: 130%;
			color: var(--stan-blue-5);
		}
		.al-link {
			font-weight: 600;
			line-height: 19px;
			letter-spacing: 0.04em;
			color: var(--stan-primary-primary-color);
			display: grid;
			grid-template-columns: auto auto;
			flex: 10 0;
		}
		.copy-icon::v-deep {
			max-width: 20px;
			path:not(.no-fill),
			rect:not(.no-fill) {
				fill: var(--stan-primary-primary-color);
			}
		}

		.affiliate_share_percent_input {
			min-width: 85px;
		}
	}
	.affiliate-link-resell-rights-section {
		display: flex;
		padding: 20px;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
		align-self: stretch;
		border-radius: 0px 0px 10px 10px;
		border: 1px solid #b8c8ff;
		background: #f9f8ff;

		margin-top: 35px;
		margin-left: -1.25rem;
		margin-right: -1.25rem;
		margin-bottom: -1.5rem;

		.toggle {
			display: flex;
			align-items: flex-start;
			gap: 20px;
			align-self: stretch;

			.toggle-label-header {
				display: flex;
				align-items: center;
				gap: 8px;
				.menu-item-icon {
					width: 18px;
					height: 18px;
				}
				.new-highlighter {
					display: flex;
					padding: 3px 5px;
					align-items: flex-start;
					gap: 10px;
					border-radius: 3px;
					background: var(--Primary-Primary, #6355ff);
				}
			}

			.toggle-label {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 5px;
			}
		}
		.toggle-expand {
			display: inline-flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 24px;
			padding-left: 58px;
			padding-top: 15px;

			.revenue-share {
				display: inline-flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 5px;

				.affiliate_share_percent_input::v-deep {
					.form-control {
						background-color: var(--stan-white) !important;
					}
					width: 100px;
					margin-right: 9px;
				}
			}
			.affiliate-share-message {
				display: flex;
				max-width: 608px;
				padding: 15px;
				align-items: flex-start;
				gap: 10px;
				border-radius: 8px;
				background: rgba(255, 255, 255, 0.5);
				box-shadow: 0px 5px 20px 0px rgba(0, 16, 109, 0.08);
			}
		}
	}
</style>
