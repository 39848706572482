<template>
	<div>
		<StanleyTyping :text="text">
			<div class="d-flex flex-wrap mt-2">
				<AppButton name="learn-vs-creator-product-prompt-monetization-button" @click="action" size="md" outline class="mr-3"
					>Learn about Monetization</AppButton
				>
				<AppButton name="learn-vs-creator-product-prompt-create-product-button" @click="action" size="md" outline class="mr-3"
					>Create Product</AppButton
				>
			</div>
		</StanleyTyping>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				text: 'My goal is to help you create a product that you can sell to your audience. Would you like to learn first or dive right in?',
			}
		},
		methods: {
			action() {
				this.$emit('action')
			},
		},
	}
</script>

<style lang="scss" scoped></style>
