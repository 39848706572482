<template>
	<AppModal
		id="create-invoice-modal"
		title="Send Invoice"
		:subheading="subHeading"
		:loading="loading"
		@cta="sendInvoice"
		@close="reset"
		action="Send"
	>
		<div :class="{ 'form-error': $v.description.$error }" class="py-4">
			<AppInput v-model="description" label="Description" placeholder="eg: My Fulfillment Order" />
		</div>
		<div :class="{ 'form-error': $v.amount.$error }" class="pb-1">
			<AppInput type="number" v-model="amount" :has-precision="true" :label="amountLabel" placeholder="$9.99" />
		</div>
	</AppModal>
</template>
<script>
	import { mapGetters } from 'vuex'
	// eslint-disable-next-line import/extensions
	import { required, between, or } from 'vuelidate/lib/validators'

	export default {
		props: {
			fanName: String,
			currencySymbol: String,
		},
		data() {
			return {
				loading: false,
				description: '',
				amount: '',
			}
		},
		computed: {
			...mapGetters('Auth', ['user']),
			amountLabel() {
				return `Amount (${this.currencySymbol})`
			},
			subHeading() {
				return 'Send a custom payment request, separate from existing subscriptions or payment plans.'
			},
		},
		validations() {
			return {
				description: {
					required,
				},
				amount: {
					required,
					validPriceRange: or(between(0.5, 25000.0), between(0, 0)),
				},
			}
		},
		methods: {
			sendInvoice() {
				this.$v.$touch()
				if (!this.$v.$error) {
					this.$emit('sendInvoice', { description: this.description, amount: this.amount })
					this.loading = true
				}
			},
			reset() {
				this.loading = false
				this.description = ''
				this.amount = ''
				this.$v.$reset()
			},
		},
	}
</script>

<style lang="scss" scoped>
	.amount {
		font-size: 28px;
	}
</style>
