var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.label
      ? _c("label", { staticClass: "control-label" }, [
          _vm._v(_vm._s(_vm.label))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "color-input form-control",
        on: { focusout: _vm.$v.$touch }
      },
      [
        _c(
          "span",
          { staticClass: "color-preview-size" },
          [
            _c(
              "AppDropdownMenu",
              {
                ref: "color-picker-dropdown",
                staticClass: "color-picker-dropdown",
                attrs: { keepOpen: "" },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c("ColorPicker", {
                          attrs: { presets: _vm.colorSuggestions },
                          model: {
                            value: _vm.previewColor,
                            callback: function($$v) {
                              _vm.previewColor = $$v
                            },
                            expression: "previewColor"
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _c("div", {
                  staticClass: "color-preview",
                  style: { background: _vm.previewColor }
                })
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.previewColor,
              expression: "previewColor"
            }
          ],
          attrs: { maxlength: "7" },
          domProps: { value: _vm.previewColor },
          on: {
            focus: _vm.handleFocus,
            blur: _vm.handleBlur,
            input: function($event) {
              if ($event.target.composing) return
              _vm.previewColor = $event.target.value
            }
          }
        })
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isInputFocused && _vm.$v.previewColor.$error,
            expression: "!isInputFocused && $v.previewColor.$error"
          }
        ],
        staticClass: "form-error-text para-3 stan-text-danger mb-3 mt-2"
      },
      [
        _c("InlineSvg", {
          staticClass: "error-icon",
          attrs: { src: "/images/icons/ic-warning-circle.svg" }
        }),
        _vm._v(_vm._s(_vm.$t("Please provide a valid hex code")) + "\n\t")
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "color-suggestions" },
      _vm._l(_vm.colorSuggestions, function(color) {
        return _c("div", {
          key: color,
          staticClass: "color-suggestion",
          class: { selected: _vm.isColorSelected(color) },
          style: { background: color },
          on: { click: () => _vm.setPreviewColour(color) }
        })
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }