<template>
	<div class="add-featured-product-card" :class="{ 'disabled-bg': isDisabled }">
		<div class="product-image-container" :style="`background: ${color} !important;`">
			<img v-show="iconLoaded" :src="image" :alt="title" @load="handleImageLoad" class="featured-icon" />
		</div>
		<div class="description-container">
			<div class="d-flex flex-column justify-content-start h-100">
				<h5 class="header" :class="{ 'disabled-text': isDisabled }">{{ title }}</h5>
				<p class="description" :class="{ 'disabled-text': isDisabled }">{{ description }}</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['image', 'title', 'description', 'color', 'isDisabled'],
		data() {
			return {
				iconLoaded: false,
			}
		},
		methods: {
			handleImageLoad() {
				this.iconLoaded = true
			},
		},
	}
</script>
<style lang="scss" scoped>
	.disabled-text {
		color: var(--stan-disabled-text-color) !important;
	}
	.disabled-bg {
		cursor: not-allowed !important;
	}
	.product-image-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 160px;
		max-height: 160px;
	}
	.add-featured-product-card {
		cursor: pointer;
		max-width: 100%;
		border-radius: 10px;
		overflow: hidden;
	}

	.description-container {
		min-height: 120px;
		padding: 24px;

		.header {
			color: var(--stan-primary-dark-color);
			font-style: normal;
			font-weight: 700;
			margin-bottom: 10px;
			font-size: 18px;
			line-height: 22px;
			letter-spacing: 0.04em;
		}
		.description {
			color: var(--stan-text-light-color);
			font-size: 14px;
			line-height: 20px;
			font-family: Inter;
			font-weight: 400;
			text-overflow: ellipsis;
			margin-bottom: 0;
		}
	}

	.featured-icon {
		width: 100px;
		height: 100px;
	}
</style>
