<template>
	<AppModal
		id="edit-funnel-modal"
		:title="$t('Funnel Details')"
		ariaLabelledby="editFunnel"
		:subheading="$t('Customize the name and URL of your Funnel')"
		:action="$t('Save')"
		:loading="isLoading"
		:disableAction="disabled"
		@cta="saveStore"
		:key="nextTick"
		@close="reset"
	>
		<div class=" pb-3">
			<AppInput
				class="form-group"
				:label="$t('Name')"
				:placeholder="$t('What would you like to name your Funnel?')"
				v-model="name"
				name="funnelName"
			/>
			<div>
				<AppInput
					class="form-group mb-1"
					:label="$t('Funnel URL')"
					:error="urlError"
					id="slug"
					@blur="checkURL"
					inputClass="text-truncate"
					:placeholder="funnelSlug"
					v-model="slug"
				/>
				<small class="funnel-url para-3"
					>{{ `${siteSettings.frontDomain}${username}/` }}<span>{{ slug }}</span></small
				>
			</div>
		</div>
	</AppModal>
</template>
<script>
	import { mapGetters } from 'vuex'
	import axios from 'axios'

	export default {
		props: {
			funnelName: { type: String, default: '' },
			funnelSlug: { type: String, required: true },
			funnelId: { type: Number, required: true },
		},
		data() {
			return {
				urlError: [],
				urlRegPattern: /^[A-Za-z0-9_]*$/,
				name: '',
				slug: '',
				id: '',
				username: '',
				disableButton: false,
				isLoading: false,
				nextTick: -1,
			}
		},
		computed: {
			...mapGetters({ user: 'Auth/user' }),
			disabled() {
				return this.disableButton || this.name.trim().length === 0
			},
		},
		created() {
			this.username = this.user.username
			if (this.username.length > 10) {
				this.username = `${this.username.substr(0, 5)}...${this.username.substr(this.username.length - 3)}`
			}
		},
		mounted() {
			this.nextTick = 0
		},
		watch: {
			nextTick() {
				this.name = this.funnelName
				this.slug = this.funnelSlug
				this.id = this.funnelId
			},
			funnelName() {
				this.name = this.funnelName
			},
			funnelSlug() {
				this.slug = this.funnelSlug
			},
			funnelId() {
				this.id = this.funnelId
			},
		},
		methods: {
			checkURL() {
				if (!this.slug) {
					this.urlError = [this.$t('The URL cannot be empty!')]
				} else if (!this.urlRegPattern.test(this.slug)) {
					this.urlError = [this.$t('Only letters, numbers and underscores (_) are allowed in the URL!')]
				} else {
					this.urlError = []
				}
			},
			async saveStore() {
				this.checkURL()
				if (this.urlError.length > 0) {
					this.$stanNotify({
						type: 'error',
						title: this.$t('Oops.'),
						text: this.urlError[0] || this.$t('Something went wrong. Please try again later.'),
						duration: 5000,
					})
					return
				}
				this.disableButton = true
				this.isLoading = true
				const sendData = {
					store_id: this.id,
					name: this.name,
					type: 'funnel',
					slug: this.slug,
				}
				axios
					.put('v1/stores', sendData)
					.then(() => {
						this.$stanNotify({
							type: 'success',
							title: this.$t('Saved!'),
							text: this.$t('Settings have been saved.'),
							duration: 5000,
						})
						this.$emit('saved')
					})
					.catch(error => {
						const message = error.response && error.response.data ? error.response.data.message : error.message
						this.$logError(error)
						this.$stanNotify({
							type: 'error',
							title: this.$t('Oops.'),
							text: message || this.$t('Something went wrong. Please try again later.'),
							duration: 5000,
						})
					})
					.finally(() => {
						this.disableButton = false
						this.isLoading = false
						$('#edit-funnel-modal').modal('hide')
					})
			},
			reset() {
				this.nextTick++
				this.urlError = []
			},
		},
	}
</script>
<style lang="scss" scoped>
	.funnel-url {
		word-break: break-all;
		span {
			color: var(--stan-primary-primary-color);
		}
	}
</style>
