const moduleRoutes = [
	{
		path: '/oauth',
		name: 'oauth',
		component: () => import('./index').then(m => m.default || m),
		meta: {
			pageTitle: 'Stripe Authentication',
		},
	},
	{
		path: '/oauth/instagram',
		name: 'oauth-instagram',
		component: () => import('./InstagramLoginRedirect').then(m => m.default || m),
		meta: {
			pageTitle: 'Instagram Authentication',
		},
	},
]

export default moduleRoutes
