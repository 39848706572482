import Vue from 'vue'
import Router from 'vue-router'
import { sync } from 'vuex-router-sync'
// eslint-disable-next-line import/extensions
import store from '~/store'
import routes from './routes.js'

Vue.use(Router)

// The middleware for every page of the application.
const globalMiddleware = ['check-auth']

// Load middleware modules dynamically.
// eslint-disable-next-line no-use-before-define
const routeMiddleware = resolveMiddleware(require.context('~/middleware', false, /.*\.js$/))

// eslint-disable-next-line no-use-before-define
const router = createRouter()

sync(store, router)

export default router

async function afterEach(to) {
	// eslint-disable-next-line no-underscore-dangle
	if (window._ctrack) {
		// eslint-disable-next-line no-undef
		_ctrack.trackTime(true, {
			module: to.name,
		})
	}
	await router.app.$nextTick()
	router.app.$loading.finish()
}

function callMiddleware(middleware, to, from, next) {
	const stack = middleware.reverse()

	const nextRoute = (...args) => {
		// Stop if "_next" was called with an argument or the stack is empty.
		if (args.length > 0 || stack.length === 0) {
			if (args.length > 0) {
				router.app.$loading.finish()
			}

			return next(...args)
		}

		const middleware = stack.pop()

		if (typeof middleware === 'function') {
			middleware(to, from, nextRoute)
		} else if (routeMiddleware[middleware]) {
			routeMiddleware[middleware](to, from, nextRoute)
		} else {
			throw Error(`Undefined middleware [${middleware}]`)
		}
		return 1
	}

	nextRoute()
}

function resolveComponents(components) {
	return Promise.all(components.map(component => (typeof component === 'function' ? component() : component)))
}

function getMiddleware(components) {
	const middleware = [...globalMiddleware]

	components
		.filter(c => c.middleware)
		.forEach(component => {
			if (Array.isArray(component.middleware)) {
				middleware.push(...component.middleware)
			} else {
				middleware.push(component.middleware)
			}
		})

	return middleware
}

function scrollBehavior(to, from, savedPosition) {
	if (savedPosition) {
		return savedPosition
	}

	if (to.hash) {
		return { selector: to.hash }
	}

	const [component] = router.getMatchedComponents({ ...to }).slice(-1)

	if (component && component.scrollToTop === false) {
		return {}
	}

	return { x: 0, y: 0 }
}

function resolveMiddleware(requireContext) {
	return requireContext
		.keys()
		.map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
		.reduce((guards, [name, guard]) => ({ ...guards, [name]: guard.default }), {})
}

// Add App Install top banner
function handleAppMetaTag(to) {
	const shouldShowBanner = to.meta && to.meta.showAppBanner
	const existingMeta = document.querySelector('meta[name="apple-itunes-app"]')

	if (shouldShowBanner && !existingMeta) {
		const meta = document.createElement('meta')
		meta.setAttribute('name', 'apple-itunes-app')
		meta.setAttribute('content', 'app-id=6478343472')
		document.head.appendChild(meta)
	}
}

async function beforeEach(to, from, next) {
	store.commit('Global/setBreadcrumbs', [])
	store.commit('Stores/setPreviewTheme', null)
	let components = []

	try {
		// Get the matched components and resolve them.
		components = await resolveComponents(router.getMatchedComponents({ ...to }))
	} catch (error) {
		if (/^Loading( CSS)? chunk (\d)+ failed\./.test(error.message)) {
			window.location.reload(true)
			return
		}
	}

	if (components.length === 0) {
		next()
	}

	// Start the loading bar.
	if (components && components[components.length - 1] && components[components.length - 1].loading !== false) {
		// router.app.$nextTick(() => router.app.$loading.start())
	}

	// Get the middleware for all the matched components.
	const middleware = getMiddleware(components)

	// Check if the route requires the app banner and add it if so
	handleAppMetaTag(to)

	// Call each middleware.
	callMiddleware(middleware, to, from, (...args) => {
		next(...args)
	})
}

function createRouter() {
	const router = new Router({
		scrollBehavior,
		mode: 'history',
		routes: [...routes],
	})

	router.beforeEach(beforeEach)
	router.afterEach(afterEach)

	return router
}
