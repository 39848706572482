var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      attrs: {
        id: "edit-customer-modal",
        title: _vm.$t(_vm.modal_title),
        "aria-labelledby": "editCustomer",
        overflow: true
      }
    },
    [
      _c(
        "form",
        {
          attrs: { id: "edit_customer_form" },
          on: {
            keydown: function($event) {
              return _vm.form.onKeydown($event)
            },
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitModalForm.apply(null, arguments)
            }
          }
        },
        [
          _c("AppInput", {
            staticClass: "form-group",
            attrs: {
              error:
                _vm.formErrors &&
                _vm.formErrors.errors &&
                _vm.formErrors.errors.fullName,
              warning: _vm.ajax_warnings && _vm.ajax_warnings.fullName,
              icon: "/images/icons/ic-person.svg",
              "icon-width": "16px",
              type: "text",
              placeholder: _vm.$t("Name"),
              name: "fullName"
            },
            on: {
              input: function($event) {
                return _vm.onBlurChangeWarning("fullName")
              }
            },
            model: {
              value: _vm.form.fullName,
              callback: function($$v) {
                _vm.$set(_vm.form, "fullName", $$v)
              },
              expression: "form.fullName"
            }
          }),
          _vm._v(" "),
          _c("AppInput", {
            staticClass: "form-group",
            attrs: {
              error: _vm.formErrors.errors && _vm.formErrors.errors.email,
              warning: _vm.ajax_warnings && _vm.ajax_warnings.email,
              icon: "/images/icons/ic-mail.svg",
              placeholder: _vm.$t("Email"),
              type: "email",
              name: "email"
            },
            on: {
              input: function($event) {
                return _vm.onBlurChangeWarning("email")
              }
            },
            model: {
              value: _vm.form.email,
              callback: function($$v) {
                _vm.$set(_vm.form, "email", $$v)
              },
              expression: "form.email"
            }
          }),
          _vm._v(" "),
          _c("AppPhoneInput", {
            ref: "phoneNumber",
            staticClass: "form-group has-country align-items-center",
            attrs: {
              "current-phone": _vm.form.phoneNumber,
              error:
                _vm.formErrors &&
                _vm.formErrors.errors &&
                _vm.formErrors.errors.phoneNumber,
              warning: _vm.ajax_warnings && _vm.ajax_warnings.phoneNumber
            },
            on: { updatePhone: _vm.phoneValidation }
          }),
          _vm._v(" "),
          _c("EditFanTags", {
            attrs: { userFanId: _vm.userFanId, mode: "single-fan" }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-wrap justify-content-end mt-3" },
            [
              _c(
                "AppButton",
                {
                  staticClass: "py-2 md-flat ml-2 mt-1 w-100px",
                  attrs: {
                    name: "close-edit-customer-modal",
                    outline: "",
                    "data-dismiss": "modal"
                  },
                  on: { click: _vm.fillForm }
                },
                [_vm._v(_vm._s(_vm.$t("Close")))]
              ),
              _vm._v(" "),
              _c(
                "AppButton",
                {
                  staticClass: "py-2 md-flat ml-2 mt-1 w-100px",
                  attrs: {
                    name: "save-edit-customer-modal",
                    loading: _vm.loading,
                    type: "Submit"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Save")))]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }