var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.name && _vm.icons[_vm.name]
    ? _c("svg", {
        style: _vm.style,
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg"
        },
        domProps: { innerHTML: _vm._s(_vm.icons[_vm.name]) }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }