var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "add-featured-product-card",
      class: { "disabled-bg": _vm.isDisabled }
    },
    [
      _c(
        "div",
        {
          staticClass: "product-image-container",
          style: `background: ${_vm.color} !important;`
        },
        [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.iconLoaded,
                expression: "iconLoaded"
              }
            ],
            staticClass: "featured-icon",
            attrs: { src: _vm.image, alt: _vm.title },
            on: { load: _vm.handleImageLoad }
          })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "description-container" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-column justify-content-start h-100" },
          [
            _c(
              "h5",
              {
                staticClass: "header",
                class: { "disabled-text": _vm.isDisabled }
              },
              [_vm._v(_vm._s(_vm.title))]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "description",
                class: { "disabled-text": _vm.isDisabled }
              },
              [_vm._v(_vm._s(_vm.description))]
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }