<template>
	<div class="typing-animation">
		<div class="dot-1"></div>
		<div class="dot-2"></div>
		<div class="dot-3"></div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				typing: true,
			}
		},
		mounted() {
			this.startTypingAnimation()
		},
		methods: {
			startTypingAnimation() {
				// Simulate the typing animation
				setInterval(() => {
					this.typing = !this.typing
				}, 500)
			},
		},
	}
</script>

<style>
	.typing-animation {
		display: flex;
		align-items: center;
	}

	.dot-1,
	.dot-2,
	.dot-3 {
		width: 10px;
		height: 10px;
		background-color: #000;
		border-radius: 50%;
		margin: 0 2px;
		animation: typingAnimation 1.5s infinite;
	}

	.dot-2 {
		animation-delay: 0.4s;
	}

	.dot-3 {
		animation-delay: 0.8s;
	}

	@keyframes typingAnimation {
		0% {
			opacity: 0.1;
		}
		50% {
			opacity: 0.3;
		}
		100% {
			opacity: 0.1;
		}
	}
</style>
