<template>
	<div>
		<div class="row" style="min-height:265px;">
			<div class="col-12 p-0" v-if="monthlyEarningsData">
				<VueApexCharts
					:key="earningsDataUpdateTick"
					v-if="monthlyEarningsData"
					height="100%"
					type="bar"
					:options="monthlyEarningsData.options"
					:series="monthlyEarningsData.series"
				>
				</VueApexCharts>
			</div>
		</div>
	</div>
</template>
<script>
	import VueApexCharts from 'vue-apexcharts'
	import axios from 'axios'

	export default {
		name: 'MonthlyEarnings',
		components: {
			VueApexCharts,
		},
		data() {
			return {
				monthlyEarningsData: {
					monthlyEarnings: null,
					thisMonthsEarnings: null,
					options: {
						stroke: { width: [0, 4] },
						colors: ['#899CFF'],
						dataLabels: {
							enabled: true,
							enabledOnSeries: [2],
						},
						labels: [],
						chart: {
							height: 50,
							toolbar: { show: false },
						},
						grid: {
							xaxis: {
								lines: {
									show: false,
								},
							},
							yaxis: {
								lines: {
									show: false,
								},
							},
						},
						yaxis: {
							opposite: false,
							labels: {
								style: {
									colors: '#808EB6',
								},
								formatter(val) {
									return `$${val.toFixed(0)}`
								},
							},
						},
						xaxis: {
							labels: {
								trim: true,
								style: {
									colors: '#808EB6',
								},
							},
							axisBorder: {
								show: false,
							},
							axisTicks: {
								show: false,
							},
						},
						plotOptions: {
							bar: {
								borderRadius: 5,
							},
						},
					},
					series: [
						{
							data: [],
							name: 'Earnings',
						},
					],
				},
				earningsDataUpdateTick: 0,
			}
		},
		mounted() {
			this.populateGraph()
		},
		methods: {
			populateGraph() {
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
				axios.get('v1/referrals/graph', { params: { timezone } }).then(response => {
					this.monthlyEarningsData.options.labels = response.data.labels
					this.monthlyEarningsData.totalEarnings = response.data.totalEarnings
					this.monthlyEarningsData.thisMonthsEarnings = response.data.thisMonthsEarnings || 0
					this.monthlyEarningsData.series[0].data = response.data.data
					this.monthlyEarningsData.activeReferrals = response.data.activeReferrals
					this.monthlyEarningsData.totalReferrals = response.data.totalReferrals
					this.earningsDataUpdateTick += 1
					this.$emit('updated', this.monthlyEarningsData)
				})
			},
		},
	}
</script>
