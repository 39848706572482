<template>
	<span class="pill-wrapper para-3" :class="{ active: active }" @click="$emit('click')">
		<slot></slot>
	</span>
</template>

<script>
	export default {
		name: 'AppPill',
		props: {
			active: Boolean,
		},
		computed: {},
	}
</script>

<style lang="scss" scoped>
	.pill-wrapper {
		cursor: pointer;
		display: flex;
		justify-content: center;
		width: 86px;
		height: 30px;
		padding: 7px 13px;
		gap: 10px;
		border-radius: 20px;
		background-color: var(--stan-gray-soft-color);
		color: var(--stan-text-dark-color);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&.active {
			background-color: var(--stan-primary-primary-color);
			color: var(--stan-white);
		}
	}
</style>
