import axios from 'axios'

/** 
  Set all variable here
*/
export const state = {
	resellProducts: {},
}

export const getters = {
	resellProducts: state => state.resellProducts,
}

export const mutations = {
	setResellProducts(state, payload) {
		state.resellProducts = payload
	},
}

export const actions = {
	fetchResellProducts: ({ commit }) => {
		axios.get('/v1/resell_rights/links').then(response => {
			const resellProducts = response?.data
			commit('setResellProducts', resellProducts)
		})
	},
}
