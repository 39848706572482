<template>
	<img :src="src" :class="{ 'rounded-circle': circle }" :style="style" />
</template>

<script>
	export default {
		name: 'AppImage',
		props: {
			src: String,
			circle: Boolean,
			cover: Boolean,
			width: String,
			height: String,
			borderRadius: { type: String, default: undefined },
		},
		computed: {
			style() {
				const out = {}
				out.height = this.height
				out.width = this.width
				if (this.circle || this.cover) {
					out['object-fit'] = 'cover'
				}
				if (this.borderRadius) out['border-radius'] = this.borderRadius
				return out
			},
		},
	}
</script>
