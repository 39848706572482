var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("StanleyTyping", { attrs: { text: _vm.text } }, [
        _c(
          "div",
          { staticClass: "d-flex flex-wrap" },
          [
            _c(
              "AppButton",
              {
                staticClass: "mr-2 mb-2",
                attrs: {
                  name: "connect-google-calendar-prompt-button",
                  outline: ""
                }
              },
              [_vm._v("Connect Google Calendar")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }