import axios from 'axios'
import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import defaultProductConfig from '~/plugins/add-product-defaults.js'

// eslint-disable-next-line import/no-mutable-exports
const configSettings = {
	siteName: 'Stan',
	env_name: process.env.VUE_APP_ENV_NAME,
	pythonApiUrl: process.env.VUE_APP_API_PYTHON_BASE_URL,
	informalApiUrl: process.env.VUE_APP_INFORMAL_API_BASE_URL,
	noHttpsDomain: process.env.FRONT_DOMAIN_NO_PROTOCOL,
	frontDomain: `https://${process.env.FRONT_DOMAIN_NO_PROTOCOL}`,
	noHttpsLandingDomain: process.env.LANDING_DOMAIN_NO_PROTOCOL,
	landingDomain: `https://${process.env.LANDING_DOMAIN_NO_PROTOCOL}`,
	stripeKey: process.env.VUE_APP_STRIPE_KEY,
	stripePlatformKey: process.env.VUE_APP_PLATFORM_STRIPE_KEY,

	stanSubscriptionCreatorMonthlyPriceIdOld: process.env.VUE_APP_STAN_SUBSCRIPTION_CREATOR_MONTHLY_PRICE_ID_OLD,
	stanSubscriptionCreatorAnnualPriceIdOld: process.env.VUE_APP_STAN_SUBSCRIPTION_CREATOR_ANNUAL_PRICE_ID_OLD,
	stanSubscriptionCreatorProMonthlyPriceIdOld: process.env.VUE_APP_STAN_SUBSCRIPTION_CREATOR_PRO_MONTHLY_PRICE_ID_OLD,
	stanSubscriptionCreatorProAnnualPriceIdOld: process.env.VUE_APP_STAN_SUBSCRIPTION_CREATOR_PRO_ANNUAL_PRICE_ID_OLD,

	stanSubscriptionCreatorMonthlyPriceId: process.env.VUE_APP_STAN_SUBSCRIPTION_CREATOR_MONTHLY_PRICE_ID,
	stanSubscriptionCreatorAnnualPriceId: process.env.VUE_APP_STAN_SUBSCRIPTION_CREATOR_ANNUAL_PRICE_ID,
	stanSubscriptionCreatorProMonthlyPriceId: process.env.VUE_APP_STAN_SUBSCRIPTION_CREATOR_PRO_MONTHLY_PRICE_ID,
	stanSubscriptionCreatorProAnnualPriceId: process.env.VUE_APP_STAN_SUBSCRIPTION_CREATOR_PRO_ANNUAL_PRICE_ID,

	analyticsApi: process.env.VUE_APP_ANALYTICS_API,
	zapierInviteLink: 'https://zapier.com/apps/stan-creator/integrations',
	sentryDsn: process.env.VUE_APP_SENTRY_DSN,
	mouseflowAnalyticsTrackingCode: process.env.MOUSEFLOW_ANALYTICS_TRACKING_CODE || null,
	unsplashApiClientId: process.env.VUE_APP_UNSPLASH_API_CLIENT_ID || null,
	nicheRecommendationPages: defaultProductConfig,
	releaseVersion: process.env.ENV_RELEASE_VERSION,
	assetsDomain: process.env.VUE_APP_IMAGE_ASSETS,
	chatAIApiUrl: process.env.CHAT_AI_API_BASE_URL,
	clarityTrackingCode: process.env.VUE_APP_CLARITY_TRACKING_CODE,
	captchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
	communityDomain: process.env.COMMUNITY_DOMAIN,
	communityApiUrl: process.env.VUE_APP_COMMUNITY_API_BASE_URL,

	instagramClientId: process.env.VUE_APP_INSTAGRAM_CLIENT_ID,
	instagramRedirectUri: process.env.VUE_APP_INSTAGRAM_REDIRECT_URI,
}

axios.defaults.baseURL = configSettings.pythonApiUrl
if (configSettings.env_name === 'staging' || configSettings.env_name === 'production') {
	Sentry.init({
		Vue,
		environment: configSettings.env_name,
		dsn: configSettings.sentryDsn,
		tracesSampleRate: 0,
		release: configSettings.releaseVersion,
		integrations: [],
		beforeSend(event, hint) {
			let ignoreEvent = false
			if (event.exception?.values.length > 0) {
				event.exception?.values.forEach(ex => {
					if (ex.value.indexOf('ResizeObserver') > -1) ignoreEvent = true
					else if (ex.type === 'NavigationDuplicated') {
						ignoreEvent = true
					} else if (ex.type === 'Event') {
						// eslint-disable-next-line no-console
						console.log('Ignoring Event', ex.type, ex.value)
						ignoreEvent = true
					} else if (ex.type === 'TypeError') {
						if (ex.value.indexOf('naturalWidth') > -1) ignoreEvent = true
						if (
							ex.value === "Cannot read properties of null (reading 'classList')" ||
							ex.value === "null is not an object (evaluating 't.target.parentNode.classList'"
						)
							ignoreEvent = true
						// eslint-disable-next-line no-console
						if (ignoreEvent) console.log('Ignoring Error', ex.type, ex.value)
					} else if (ex.type === 'SyntaxError') {
						if (ex.value.indexOf('Unexpected token') > -1) ignoreEvent = true
					} else if (ex.type === 'Error') {
						const httpCode4xxIndex = ex.value.search(/\s4[0-9]{2,2}$/)
						const errorRequestFailedIndex = ex.value.toLowerCase().search('request failed with status code')
						if (ex.value === 'Network Error') ignoreEvent = true
						else if (errorRequestFailedIndex > -1 && httpCode4xxIndex > -1) {
							ignoreEvent = true
						} else if (ex.value === 'undefined') {
							ignoreEvent = true
						} else if (ex.value.indexOf('Navigation cancelled from') !== -1) {
							ignoreEvent = true
						} else if (ex.value.indexOf('Redirected when going from') !== -1) {
							ignoreEvent = true
						}
						if (ignoreEvent) {
							// eslint-disable-next-line no-console
							console.log('Ignoring Error', ex.type, ex.value)
						}
					} else if (hint.originalException === 'Timeout') {
						ignoreEvent = true
					}
				})
			}
			if (ignoreEvent) return null

			return event
		},
	})
}

if (configSettings.env_name === 'production') {
	const d = document
	const g = d.createElement('script')
	const s = d.getElementsByTagName('script')[0]
	g.type = 'text/javascript'
	g.async = true
	g.defer = true
	g.src = 'https://assets.custify.com/assets/track.min.js'
	s.parentNode.insertBefore(g, s)
	const custifyInitInterval = setInterval(() => {
		// eslint-disable-next-line no-underscore-dangle
		if (window._ctrack) {
			// eslint-disable-next-line no-undef
			_ctrack.setAccount('6218e2b7a730772ec7908b4c')
			clearInterval(custifyInitInterval)
		}
	}, 100)
}

// eslint-disable-next-line import/prefer-default-export
export { configSettings }
