<template>
	<AppModal id="import-audience-modal" :title="modalTitle" :staticDataBackdrop="true" :subheading="modalSubheading" @close="closeModal">
		<input type="file" accept="text/csv" @change="filePreview" ref="fileInput" style="display:none" :key="inputKey" />

		<div v-if="fileImportErrorCode || (file && rows.length === 0)" class="pb-3"></div>
		<div v-else>
			<div v-if="!file">
				<div class="single-import mb-5 mt-4 pt-3">
					<AppInput v-model="fan.full_name" placeholder="Name" class="mb-3" :error="error.full_name" @change="error.full_name = []" />
					<AppInput v-model="fan.email" placeholder="Email" class="mb-3" :error="error.email" @change="error.email = []" />
					<AppPhoneInput
						ref="phoneNumber"
						class="form-group has-country align-items-center"
						:error="error.phone_number"
						@updatePhone="updatePhoneNumber"
						:key="tick"
					/>
					<div class="w-100 mt-3">
						<AppButton
							v-if="rows.length === 0 || fileImportErrorCode"
							name="add-contact-audiece-modal"
							:fullWidth="true"
							:loading="csvImportProcessing"
							:disabled="csvImportProcessing"
							class="w-100"
							@click="saveSingleContact"
						>
							Add Contact
						</AppButton>
					</div>
				</div>
				<div class="group-import text-center">
					<h3 class="text-bold mb-3">...or import a list</h3>
					<p class="para-2 text-light">
						Please upload a CSV file with the following columns: Email Address, First Name, Last Name, and Phone Number.
					</p>
					<p class="para-2 text-light mb-0 pb-3">Maximum of 5,000 contacts per account.</p>
				</div>
			</div>
			<div v-else class="my-4">
				<div v-if="rows.length > 0">
					<EditFanTags
						:showTitle="false"
						:searchable="false"
						mode="single-tag"
						@tags-updated="fanTagsUpdated"
						ref="editFanTags"
						class="tag-selection"
					/>
				</div>
			</div>
		</div>
		<div class="w-100 mt-3 highlight-card" v-if="rows.length !== 0 && !fileImportErrorCode">
			<AppCheckbox v-model="termsAgree">
				I acknowledge that I have read and understand the
				<a href="https://assets.stanwith.me/legal/terms-of-service.pdf" target="_new">Terms of Service</a> and the
				<a href="https://assets.stanwith.me/legal/CASL.pdf">Anti-Spam Rules</a>.
			</AppCheckbox>
		</div>
		<div v-if="fileImportErrorHint" v-html="fileImportErrorHint" class="text-center para-2 text-light mb-5"></div>
		<div class="w-100 mt-3">
			<AppButton
				v-if="rows.length === 0 || fileImportErrorCode"
				name="upload-csv-audience-modal"
				outline
				:fullWidth="true"
				:loading="csvImportProcessing"
				:disabled="csvImportProcessing"
				class="w-100"
				@click="openFileUploadDialog"
			>
				Upload
			</AppButton>
			<AppButton
				v-else
				name="confirm-import-audience-modal"
				:fullWidth="true"
				:loading="csvImportProcessing"
				:disabled="csvImportProcessing || !termsAgree"
				class="w-100"
				@click="handleFileUpload"
			>
				Begin Import
			</AppButton>
		</div>
	</AppModal>
</template>
<script>
	import { mapGetters } from 'vuex'
	import AppPhoneInput from '~/components/shared/AppPhoneInput/AppPhoneInput'
	import { configSettings } from '~/plugins/config-settings.js'
	import EditFanTags from './components/EditFanTags'

	export default {
		components: { AppPhoneInput, EditFanTags },

		data() {
			return {
				csvImportProcessing: false,
				file: null,
				headers: ['Email Address', 'First Name', 'Last Name', 'Phone Number'],
				rows: [],
				tag: 'Imported',
				inputKey: 0,
				fileImportErrorCode: undefined,
				fileImportErrorMessage: undefined,
				fileImportErrorHint: undefined,
				fan: { full_name: '', email: '', phone_number: '', user_fan: false },
				error: {
					full_name: [],
					email: [],
					phone_number: [],
				},
				tick: 0,
				stopImport: false,
				termsAgree: false,
				typeformFormId: configSettings.env_name === 'production' ? 'eYoPrkxm' : 'Wj0kQpvj',
			}
		},
		computed: {
			...mapGetters('Auth', ['user']),
			modalTitle() {
				if (this.submitTypeformImportRequest) {
					return 'Request to import a list'
				}
				if (this.fileImportErrorCode) {
					if (this.fileImportErrorCode === 'missing-fields') return 'Could not find contacts.'
					if (this.fileImportErrorCode === 'row-count-exceeded') return 'Too many contacts'
					if (this.fileImportErrorCode === 'partially-imported') return 'Successfully imported some contacts.'
					return 'Oops'
				}
				if (!this.file) return 'Add a contact...'
				if (this.rows.length === 0) return 'Could not find contacts.'
				if (this.rows.length > 0) return `Import ${this.rows.length} contacts?`
				return ''
			},
			modalSubheading() {
				if (this.submitTypeformImportRequest) {
					return ''
				}
				if (this.fileImportErrorCode) {
					return this.fileImportErrorMessage
				}
				if (!this.file) {
					return 'Manually type in user details'
				}
				if (this.rows.length === 0) return 'Double check that your file has at least one contact record.'
				if (this.rows.length > 0) {
					return 'Tag your contacts to find them later!'
				}

				return ''
			},
		},
		methods: {
			openImportHelpArticle() {
				window.open('https://help.stan.store/article/162-how-to-import-your-existing-email-list-into-stan', '_blank')
			},
			typeformFormSubmit() {
				window.open(
					`https://stanforcreators.typeform.com/to/${this.typeformFormId}#email=${this.user.email}&user_id=${this.user.user_id}`,
					'_blank'
				)
			},
			updatePhoneNumber(e) {
				if (e && e.country !== undefined && e.number !== undefined && e.valid === true) {
					this.fan.phone_number = e.number
				} else {
					this.fan.phone_number = ''
				}
			},
			validateFanDetails() {
				let isValid = true
				Object.keys(this.error).forEach(key => {
					this.error[key] = []
				})
				if (this.$commonHelper.stringLength(this.fan.full_name) === 0) {
					isValid = false
					this.error.full_name = ['Name cannot be empty.']
				}
				if (this.$commonHelper.stringLength(this.fan.email) === 0) {
					isValid = false
					this.error.email = ['Email cannot be empty.']
				}
				if (this.error.email.length === 0 && !this.$commonHelper.validateEmail(this.fan.email)) {
					isValid = false
					this.error.email = ['Enter a valid email address']
				}
				if (this.$commonHelper.stringLength(this.fan.phone_number) > 0 && !this.$commonHelper.validatePhone(this.fan.phone_number)) {
					isValid = false
					this.error.phone_number = ['Enter a valid phone number']
				}
				return isValid
			},
			async saveSingleContact() {
				if (this.validateFanDetails()) {
					try {
						await this.$axios.post('v1/users/fans', this.fan)
						this.$stanNotify({
							type: 'Success',
							title: this.$t('Success!'),
							text: `${this.fan.full_name} was successfully imported`,
							duration: 5000,
						})
						this.$emit('import-success')
						this.closeModal()
					} catch (err) {
						this.error = err.response.data.errors || {}
						this.$stanNotify({
							type: 'error',
							title: this.$t('Something went wrong.'),
							text: this.$t('Please contact us at friends@stanwith.me'),
							duration: 5000,
						})
					}
				}
			},
			// open file upload dialog for CSV upload
			openFileUploadDialog() {
				this.$refs.fileInput.click()
			},

			filePreview(event) {
				this.fileImportErrorCode = undefined
				this.fileImportErrorMessage = undefined
				this.fileImportErrorHint = undefined
				this.file = event.target.files[0]
				const reader = new FileReader()
				reader.onload = () => {
					const contents = reader.result
					const lines = contents.split(/\r\n|\n/)
					const headers = lines[0].split(',')
					const rows = []
					lines.slice(1).forEach((line, index) => {
						if (line && line.length) {
							const values = line.split(',')
							rows.push({
								id: index + 1,
								...Object.fromEntries(headers.map((header, index) => [header.replace(/^"|"$/g, ''), values[index]])),
							})
						}
					})
					this.setRows(rows)
				}
				reader.readAsText(this.file)
			},

			setRows(rows) {
				this.rows = rows
			},

			removeFile() {
				this.file = null
				this.inputKey++
			},

			// handles file upload logic
			async handleFileUpload() {
				const exceedsLimit = this.rows.length > 5000 && !this.user.data.ignore_import_limits // account for empty row at the end of file
				if (exceedsLimit) {
					this.fileImportErrorCode = 'row-count-exceeded'
					this.fileImportErrorMessage = undefined
					this.fileImportErrorHint =
						'We had trouble importing your contacts. <a href="https://help.stan.store/article/162-how-to-import-your-existing-email-list-into-stan">See here.</a>'
					this.$stanNotify({
						type: 'error', // can be 'info', 'warning', 'success', 'error'
						title: 'File Exceeds Limit',
						text: 'The file exceeds the maximum row limit of 5000.',
						duration: 3000,
					})
					return
				}
				this.fileImportErrorCode = undefined
				this.fileImportErrorMessage = undefined
				this.fileImportErrorHint = undefined
				this.csvImportProcessing = true
				const formData = new FormData()
				formData.append('file', this.file)
				try {
					await this.$axios
						.post('v1/users/fans/import-csv', formData, {
							params: {
								tag: this.tag,
							},
						})
						.catch(err => {
							this.fileImportErrorCode = err.response?.data?.code
							this.fileImportErrorMessage = err.response?.data?.message
							this.fileImportErrorHint =
								'For more information: <a href="https://help.stan.store/article/162-how-to-import-your-existing-email-list-into-stan">See here.</a>'
							throw new Error('Error importing CSV')
						})
					this.csvImportProcessing = false
					this.$emit('import-success', { numberImported: this.rows.length })
					this.closeModal()
				} catch {
					this.$stanNotify({
						type: 'error', // can be 'info', 'warning', 'success', 'error'
						title: 'Oh no!',
						text: 'There was an issue importing the file.',
						duration: 3000,
					})
					this.csvImportProcessing = false
					this.rows = []
					this.removeFile()
				}
			},
			closeModal() {
				this.tick++
				this.removeFile()
				this.stopImport = false
				Object.keys(this.fan).forEach(key => {
					this.fan[key] = ''
					this.error[key] = []
				})
				this.rows = []
				this.fileImportErrorCode = undefined
				this.fileImportErrorMessage = undefined
				this.fileImportErrorHint = undefined
				this.termsAgree = false
				$('#import-audience-modal').modal('hide')
			},
			fanTagsUpdated(event) {
				if (event.length > 0 && event[0].name) {
					this.tag = event[0].name
				} else {
					this.tag = ''
				}
			},
		},
		watch: {
			termsAgree: {
				handler(newVal) {
					if (newVal) {
						this.$stanAnalytics('import-contact-terms-and-anti-spam-agreed', {
							meta: { user_id: this.user.user_id, username: this.user.username },
							props: {
								terms:
									'I acknowledge that I have read and understand the <a href="https://assets.stanwith.me/legal/terms-of-service.pdf" target="_new">Terms of Service</a> and the <a href="https://assets.stanwith.me/legal/CASL.pdf">Anti-Spam Rules</a>.',
								'terms-agreed': newVal,
								'imported-tags': this.tag,
							},
						})
					}
				},
			},
		},
	}
</script>

<style lang="scss" scoped>
	.group-import {
		width: 90%;
		margin: auto;
	}
	.importing-animation {
		padding: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		svg::v-deep {
			path {
				fill: var(--stan-primary-soft-color);
			}
		}
		svg:first-child {
			width: 78px;
			height: 78px;
			transform: translateX(30px) rotate(8.88deg);
			animation: float1 4s ease-in-out infinite;
		}
		svg:nth-child(2) {
			width: 118px;
			height: 118px;
			animation: float2 6s ease-in-out infinite;
		}
		svg:last-child {
			width: 65px;
			height: 65px;
			transform: translateX(-30px) rotate(-10deg);
			animation: float3 5s ease-in-out infinite;
		}
	}
	@keyframes float1 {
		0% {
			transform: translateX(30px) rotate(8.88deg) translatey(-10px);
		}
		50% {
			transform: translateX(30px) rotate(8.88deg) translatey(0px);
		}
		100% {
			transform: translateX(30px) rotate(8.88deg) translatey(-10px);
		}
	}
	@keyframes float2 {
		0% {
			transform: translatey(0px);
		}
		50% {
			transform: translatey(-20px);
		}
		100% {
			transform: translatey(0px);
		}
	}
	@keyframes float3 {
		0% {
			transform: translateX(-30px) rotate(-10deg) translatey(0px);
		}
		50% {
			transform: translateX(-30px) rotate(-10deg) translatey(-10px);
		}
		100% {
			transform: translateX(-30px) rotate(-10deg) translatey(0px);
		}
	}
	.highlight-card {
		background: var(--stan-gray-soft-color);
		border-radius: 12px;
		padding: 14px;
	}
</style>
