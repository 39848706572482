<template>
	<div class="stanley-chat-message-container">
		<div class="row justify-content-start align-items-start message-box">
			<div class="col-auto pr-0">
				<img class="mt-1 profile-image" :src="user.profile_image" width="40px;" />
			</div>
			<div class="col">
				<div>
					<b>{{ user.full_name }}</b>
				</div>
				<div>
					<slot></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'

	export default {
		computed: {
			...mapGetters('Auth', ['user']),
		},
	}
</script>

<style lang="scss" scoped>
	.profile-image {
		border-radius: 50%;
	}
</style>
