<template>
	<div
		:id="id"
		ref="custom-modal"
		class="modal modal-block"
		:class="[{ fade: fade, 'p-0': position === 'end', 'is-video': isVideo }]"
		data-keyboard="false"
		:data-backdrop="dataBackgroundValue"
		tabindex="-1"
		aria-hidden="true"
		@click="handleClickOutside"
	>
		<div
			:class="[
				sizeClass,
				'modal-dialog',
				{ 'modal-dialog-centered': position === 'center' },
				{ 'fit-content': width },
				{ 'modal-dialog-end move-up': position === 'end' },
			]"
		>
			<Transition name="fade-quick" appear mode="out-in">
				<div
					ref="modalContent"
					class="modal-content add-section-modal"
					:key="String(transitionKey)"
					:class="{ framed: !frame }"
					:style="computedStyle"
				>
					<div v-if="!(isVideo || hideClose)" class="stan-modal-header">
						<div class="close close-modal" data-dismiss="modal" aria-label="Close">
							<InlineSvg class="close-icon" src="/images/icons/ic-close.svg" />
						</div>
					</div>
					<div class="d-flex w-100 justify-content-center modal-heading">
						<InlineSvg v-if="titleIcon" class="mr-2" :src="titleIcon" />
						<h3 v-if="title" class="h3 text-bold">{{ title }}</h3>
					</div>
					<div class="modal-inner-content" :class="{ 'overflow-visible': overflow }">
						<div v-if="subheading" class="modal-subheading para-2">
							{{ subheading }}
						</div>
						<div>
							<slot></slot>
						</div>
						<div class="d-flex flex-column gap v-gap mt-3 pt-1" v-if="action">
							<AppButton
								:name="`cta-button-modal-${id}`"
								:color="actionColor"
								:loading="loading"
								:disabled="disabled"
								@click="ctaClick"
								v-if="action"
								>{{ action }}</AppButton
							>
							<AppButton
								:name="`secondary-cta-button-modal-${id}`"
								class="p-0 h-auto"
								link
								:loading="loading"
								:disabled="disabled"
								:color="secondaryActionColor"
								@click="secondaryCtaClick"
								v-if="secondaryAction"
								>{{ secondaryAction }}</AppButton
							>
						</div>
						<span v-if="footerNote.length" class="w-100 d-flex justify-content-center mt-3 para-4 text-light text-center">
							{{ footerNote }}
						</span>
					</div>
				</div>
			</Transition>
		</div>
	</div>
</template>
<script>
	import commonHelper from '~/global_helper/helpers.js'

	export default {
		name: 'AppModal',
		props: {
			id: {
				type: String,
				default: '',
			},
			transitionKey: {
				default: '',
			},
			subheading: {
				type: String,
				default: undefined,
			},
			action: {
				type: String,
				default: '',
			},
			actionColor: {
				type: String,
				default: 'primary',
			},
			secondaryAction: {
				type: String,
				default: '',
			},
			secondaryActionColor: {
				type: String,
				default: 'primary',
			},
			title: {
				type: String,
				default: '',
			},
			titleIcon: {
				type: String,
				default: '',
			},
			extraLarge: {
				type: Boolean,
				default: false,
				description: 'Used to make the modal look wider',
			},
			large: {
				type: Boolean,
				default: false,
				description: 'Used to make the modal look wider',
			},
			frame: {
				type: Boolean,
				default: false,
				description: 'Used to remove all formatting and offer empty canvas',
			},
			fade: {
				type: Boolean,
				default: true,
				description: 'Whether the modal should fade in/out',
			},
			position: {
				type: String,
				default: 'center',
			},
			loading: {
				type: Boolean,
				default: false,
				description: 'Used to have the button on the modal show loading hollow dot spinners',
			},
			disableAction: {
				type: Boolean,
				default: false,
				description: 'Used to disable the button on the modal',
			},
			isVideo: {
				type: Boolean,
				default: false,
				description: 'Used to indicate if the modal is used as a video player',
			},
			staticDataBackdrop: {
				type: Boolean,
				default: false,
				description: 'Used to prevent the modal from being closed when clicking outside.',
			},
			hideDataBackdrop: {
				type: Boolean,
				default: false,
				description: 'Used to hide the boostrap backgroun',
			},
			hideClose: {
				type: Boolean,
				default: false,
				description: 'Used to hide the close button in case we want a non-dismissable modal.',
			},
			overflow: {
				type: Boolean,
				default: false,
				description: 'Used to determine if the modal should allow overflow.',
			},
			width: {
				type: Number,
				description: 'Sets the modal to a custom width',
			},
			footerNote: {
				type: String,
				description: 'Goes below action button for very small notes.',
				default: '',
			},
		},
		computed: {
			sizeClass() {
				if (this.extraLarge) return 'modal-xl'
				if (this.large) return 'modal-lg'
				return ''
			},
			disabled() {
				return this.disableAction || this.loading
			},
			isMobile() {
				return commonHelper.isMobile()
			},
			dataBackgroundValue() {
				if (this.hideDataBackdrop) {
					return 'false'
				}
				if (this.staticDataBackdrop) {
					return 'static'
				}
				return 'true'
			},
			computedStyle() {
				if (this.width !== null) {
					return { width: `${this.width}px` }
				}

				return null
			},
		},
		watch: {
			showing(newVal) {
				if (newVal && this.isMobile) {
					document.body.classList.add('overflow-hidden')
				} else {
					document.body.classList.remove('overflow-hidden')
				}
			},
		},
		mounted() {
			$(this.$refs['custom-modal']).on('shown.bs.modal', () => {
				this.showing = true
				document.addEventListener('keydown', this.handleEscPressed)
				this.$emit('open')
			})
			$(this.$refs['custom-modal']).on('hidden.bs.modal', () => {
				this.showing = false
				document.removeEventListener('keydown', this.handleEscPressed)
				this.$emit('close')
			})
		},
		methods: {
			ctaClick() {
				this.$emit('cta')
			},
			secondaryCtaClick() {
				this.$emit('secondary-cta')
			},
			handleClickOutside(event) {
				// Only use this as a fallback if the modal background is not visible
				if (this.dataBackgroundValue !== 'false' || !this.$refs?.modalContent) {
					return
				}

				if (this.$refs?.modalContent.contains(event.target)) {
					// event.stopPropagation()
				} else {
					$(this.$refs['custom-modal']).modal('hide')
				}
			},
			handleEscPressed(event) {
				if (event.key === 'Escape') {
					$(this.$refs['custom-modal']).modal('hide')
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	// Allow content scrolling for small screens
	.modal-block {
		overflow: scroll;
	}
	.stan-modal-header {
		position: relative;
		height: 25px;
		.close-modal {
			// position: absolute;
			// right: 0;
			display: flex;
			width: 28px;
			height: 28px;
			background-color: var(--stan-gray-light-color) !important;
			border-radius: 50%;
			cursor: pointer;
			z-index: 9;
			color: var(--stan-primary-primary-color);
			opacity: 1;
			&:hover {
				background-color: var(--stan-gray-soft-color) !important;
			}

			.close-icon {
				margin: auto;
			}
		}
	}
	.modal-open .modal {
		padding: 10px;
	}
	.modal-title {
		position: absolute;
	}
	.modal-dialog-centered {
		margin: 0 auto;

		&.fit-content {
			max-width: max-content;
		}
	}
	.modal-content {
		max-height: 90vh;
		border-radius: 1.25rem;
		border: none;
		&.framed {
			padding: 30px 25px;
		}
		.modal-heading {
			text-align: center;
			margin-bottom: 20px;
		}
	}
	.modal-inner-content {
		overflow: auto;

		.modal-subheading {
			width: 85%;
			text-align: center;
			margin: auto;
			margin-bottom: 10px;
			color: var(--stan-text-light-color);
		}
	}
	.modal-dialog-end {
		display: flex;
		align-items: end;
		min-height: calc(100%);
		margin: 0px;
	}
	.move-up {
		animation: animatebottom 0.5s;
	}
	@keyframes animatebottom {
		from {
			bottom: -400px;
			opacity: 1;
		}

		to {
			bottom: 0;
			opacity: 1;
		}
	}

	.modal-fullscreen-sm-down {
		@media (max-width: 576px) {
			padding: 0px !important;
			.modal-dialog {
				width: 100vw;
				max-width: none;
				height: 100%;
				margin: 0;

				.modal-content {
					height: 100%;
					border: 0;
					max-height: unset;
				}

				.modal-body {
					overflow-y: auto;
				}
			}

			&.is-video {
				.modal-dialog {
					width: 100%;
					height: auto;

					.modal-content {
						height: auto;
					}
				}
			}
		}
	}

	.is-video {
		.modal-content {
			border: none;
		}
	}

	.modal .modal-backdrop {
		color: red;
	}
</style>
