<template>
	<AppModal
		ref="instagramPostsModal"
		title="Select Your Posts"
		ariaLabelledby="modal-instagram-post-options"
		@close="closeModal(true)"
		class="post-picker-modal"
	>
		<div class="modal-content-wrapper">
			<div class="instagram-post-grid">
				<ul class="instagram-post-list">
					<li
						v-for="post in instagramPosts"
						:key="post.id"
						class="instagram-post-item"
						:class="{ selected: isPostSelected(post.id) }"
						@click="toggleSelection(post)"
					>
						<div class="thumbnail-container">
							<img
								:src="post.media_type === 'VIDEO' ? post.thumbnail_url : post.media_url"
								alt="Instagram Post Preview"
								class="post-thumbnail"
							/>
							<div class="selected-overlay"></div>
							<div class="thumbnail-overlay">
								<p class="post-caption">{{ post.caption || 'No Caption' }}</p>
							</div>
							<Transition name="fade">
								<div v-if="isPostSelected(post.id)" class="checkmark">
									<InlineSvg src="images/icons/ic-check-purple.svg" />
								</div>
							</Transition>
						</div>
					</li>
				</ul>
			</div>

			<div class="submit-button-wrapper">
				<AppButton name="select-instagram-posts" @click="clearSelection" :disabled="selectedPosts.length === 0" outline class="clear-button">
					Clear
				</AppButton>
				<AppButton name="select-instagram-posts" @click="saveInstagramPosts" class="cta-button">
					{{ ctaText }}
				</AppButton>
			</div>
		</div>
	</AppModal>
</template>

<script>
	export default {
		data() {
			return {
				selectedPosts: [],
				selectedImages: [],
			}
		},
		computed: {
			modal() {
				return $(this.$refs.instagramPostsModal.$el)
			},
			ctaText() {
				return this.selectedPosts.length > 0 ? `Enable for ${this.selectedPosts.length} Posts` : 'Enable for All Posts'
			},
		},
		props: {
			instagramPosts: {
				type: Array,
				required: true,
			},
			currentlySelected: {
				type: Array,
				required: false,
				default: () => [],
			},
		},
		methods: {
			show() {
				this.modal.modal('show')
			},
			closeModal(cancel = false) {
				this.modal.modal('hide')
				if (cancel) this.selectedPosts = [...this.currentlySelected]
				this.$emit('close-modal')
			},
			toggleSelection(post) {
				const index = this.postIndex(post.id)
				if (index !== -1) {
					this.selectedPosts.splice(index, 1)
				} else {
					this.selectedPosts.push(post)
				}
			},
			saveInstagramPosts() {
				this.$emit('onSubmit', this.selectedPosts)
				this.closeModal()
			},
			clearSelection() {
				this.selectedPosts = []
			},
			postIndex(postId) {
				return this.selectedPosts.findIndex(item => item.id === postId)
			},
			isPostSelected(postId) {
				return this.postIndex(postId) !== -1
			},
		},
		watch: {
			currentlySelected(newVal) {
				this.selectedPosts = [...newVal]
			},
		},
	}
</script>

<style lang="scss" scoped>
	.post-picker-modal::v-deep .modal-content {
		padding: 16px !important;
	}
	.post-picker-modal::v-deep .modal-heading {
		margin-bottom: 10px !important;
	}
	.modal-content-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		overflow: auto;
	}

	.instagram-post-grid {
		width: 100%;
		max-height: 400px;
		@media (max-width: 768px) {
			max-height: 350px;
		}
	}

	.instagram-post-list {
		margin-top: 1rem;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 8px;
		justify-content: center;
		list-style: none;
		padding: 0;
		margin: 0;
		overflow-y: auto;
		max-height: 400px;
		@media (max-width: 768px) {
			max-height: 350px;
			grid-template-columns: repeat(2, 1fr);
		}

		::-webkit-scrollbar {
			display: none;
		}
		scrollbar-width: none;
		-ms-overflow-style: none;
	}
	.instagram-post-item {
		cursor: pointer;
		position: relative;
		border: 3px solid transparent;
		border-radius: 8px;
		overflow: hidden;
		transition: transform 0.3s, border-color 0.3s;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	}

	.instagram-post-item:hover {
		transform: translateY(-3px);
		box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
	}

	.instagram-post-item.selected {
		border-color: var(--stan-primary-primary-color);
		box-shadow: 0 4px 8px rgba(0, 123, 255, 0.4);
		background: rgba(0, 0, 0, 0.5);
		.selected-overlay {
			opacity: 1;
		}
	}

	.thumbnail-container {
		width: 100%;
		height: 100%;
		position: relative;
		border-radius: 8px;
	}

	.post-thumbnail {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.thumbnail-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;
		transition: opacity 0.3s;
		text-align: center;
	}

	.thumbnail-overlay .post-caption {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		line-clamp: 3;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #fff;
		font-size: 0.9rem;
		padding: 0 10px;
		box-sizing: border-box;
	}

	.instagram-post-item:hover .thumbnail-overlay {
		opacity: 1;
	}

	.submit-button-wrapper {
		display: flex;
		gap: 8px;
		margin-top: 1rem;
		width: 100%;
		text-align: center;

		.cta-button {
			flex-grow: 1;
		}
		.clear-button {
			width: 120px;
		}
	}

	.checkmark {
		position: absolute;
		bottom: 10px;
		right: 10px;
		width: 24px;
		height: 24px;
		z-index: 2;
	}

	.instagram-post-item.selected .checkmark {
		display: block;
	}

	.checkmark svg {
		width: 100%;
		height: 100%;
	}
	.selected-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(182, 182, 182, 0.5);
		opacity: 0;
		transition: opacity 0.3s;
		pointer-events: none;
	}
</style>
