var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    key: _vm.tick,
    ref: "fileInput",
    staticStyle: { display: "none" },
    attrs: { type: "file", accept: _vm.accepts, multiple: _vm.multiple },
    on: {
      change: _vm.onFilePicked,
      cancel: function($event) {
        return _vm.$emit("file-selection-cancelled")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }