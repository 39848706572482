<template>
	<AppModal
		id="confirm-change-plan-modal"
		:title="$t('Confirm Payment')"
		:subheading="`${$t('You will be charged the new fee starting')} ${computedStartDateOfNewPlan}`"
		:overflow="true"
		:width="580"
	>
		<div class="content">
			<!-- Header -->
			<div class="header">
				<!-- Price -->
				<div class="price-container">
					<span class="para-3 text-light">{{ `${$t('Your new rate starting')} ${computedStartDateOfNewPlan}` }}</span>
					<div class="price-info">
						<h1 class="text-bold" style="font-size: 40px;">{{ currencySign }}{{ priceTag }}</h1>
						<h3 class="text-light">{{ stringInterval }}</h3>
					</div>
				</div>
				<img src="./assets/pay_confirmation.svg" class="receipt-image" draggable="false" />

				<!-- To / From -->
				<div class="info-container">
					<!-- Labels -->
					<div class="label-container">
						<span class="para-3 text-light">{{ $t('To') }}</span>
						<span class="para-3 text-light">{{ $t('From') }}</span>
					</div>
					<!-- Values -->
					<div class="value-container">
						<span class="para-3 text-dark text-bold">@{{ this.user.username }}</span>
						<span class="para-3 text-dark text-bold">{{ $t('Stan - Your All-in-One Creator Store') }}</span>
					</div>
				</div>
				<!-- Billing info -->
				<div class="billing-info">
					<span v-if="estimateSubscriptionChangeObject.status === 'upgrading'" class="para-3">
						{{ $t('You will be charged a prorated amount of') }}
						<span class="para-3 text-bold">{{ estimateSubscriptionChangeObject.cost }}</span> {{ $t('today if you accept') }}
					</span>
					<span v-else class="para-3">
						{{ $t('Your Stan Subscription is a taxable write-off for your business.') }}
					</span>
				</div>
			</div>

			<!-- Buttons -->
			<div class="footer">
				<AppButton class="action-button" @click="handleUnderstandClick()" :loading="isUpdatingPlan">{{ $t('Yes, I understand') }}</AppButton>
				<AppButton class="action-button" @click="goBack()" :link="true" :disabled="isUpdatingPlan">{{ $t('Go Back') }}</AppButton>
			</div>
		</div>
	</AppModal>
</template>

<script>
	import { mapGetters } from 'vuex'

	export default {
		name: 'ChangePlanConfirmationModal',
		props: {
			tier: {
				default: '',
				type: String,
			},
			interval: {
				default: '',
				type: String,
			},
			currencySign: {
				default: '$',
				type: String,
			},
			isUpdatingPlan: {
				default: false,
				type: Boolean,
			},
			estimateSubscriptionChangeObject: {
				type: Object,
				required: false,
				default: () => ({
					cost: '',
					date: '',
					status: '',
				}),
			},
		},
		computed: {
			...mapGetters('Auth', ['user']),
			priceTag() {
				switch (this.tier) {
					case this.$constants.TIER.CREATOR:
						if (this.interval === this.$constants.TIER_INTERVAL.MONTH) {
							return this.$constants.PLAN_COSTS.CREATOR_MONTH.toFixed(2)
						}

						return (this.$constants.PLAN_COSTS.CREATOR_ANNUAL * 12).toFixed(2)
					case this.$constants.TIER.CREATOR_PRO:
					default:
						if (this.interval === this.$constants.TIER_INTERVAL.MONTH) {
							return this.$constants.PLAN_COSTS.CREATOR_PRO_MONTH.toFixed(2)
						}
						return (this.$constants.PLAN_COSTS.CREATOR_PRO_ANNUAL * 12).toFixed(2)
				}
			},
			stringInterval() {
				if (this.interval === this.$constants.TIER_INTERVAL.MONTH) {
					return '/mo'
				}

				return '/yr'
			},
			computedStartDateOfNewPlan() {
				const today = this.$commonHelper.formatDateShort(new Date(), true, true)
				return today === this.estimateSubscriptionChangeObject.date ? 'today.' : `${this.estimateSubscriptionChangeObject.date}.`
			},
		},
		methods: {
			async handleUnderstandClick() {
				this.$emit('on-confirm-button-click')
			},
			goBack() {
				this.$stanAnalytics('subscription-change-tier-modal-go-back', {
					// User_id and username are optional as non login page should not have user object
					meta: { user_id: this.user?.user_id, username: this.user?.username },
					props: { current_tier: this.tier, current_interval: this.interval },
				})

				$('#change-plan-modal').modal('show')
				$('#confirm-change-plan-modal').modal('hide')
			},
		},
	}
</script>

<style lang="scss" scoped>
	.content {
		display: flex;
		flex-direction: column;
		gap: 30px;
		margin-top: 30px;
	}

	.header {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 30px;
		width: 100%;
		padding: 28px;
		background-color: white;
		box-shadow: var(--stan-box-shadow);
		border-radius: 5px;
	}

	.price-container {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}

	.price-info {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: flex-end;
	}

	.receipt-image {
		position: absolute;
		top: 28px;
		right: 28px;
		height: 70px;
		box-shadow: var(--stan-box-shadow);

		@media (max-width: 991px) {
			display: none;
		}
	}

	.info-container {
		display: flex;
		flex-direction: row;
		gap: 20px;
	}

	.label-container,
	.value-container {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}

	.billing-info {
		width: 100%;
		border-radius: 5px;
		padding: 12px;
		background-color: var(--stan-gray-soft-color);
		display: flex;
		justify-content: center;
		color: var(--stan-text-light-color);
	}

	.footer {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}

	.action-button {
		width: 100%;
	}

	.action-button:last-child {
		width: fit-content;
		align-self: center;
	}
</style>
