<template>
	<AppDropdownMenu
		ref="main-dropdown"
		class="m-2"
		keepOpen
		@showDropdown="showFilter"
		@hideDropdown="applyFilter"
		@bgClick="bgClick"
		:class="{ disabled: disabled }"
	>
		<div class="chip" :class="{ selected: value }">
			<img src="/images/icons/ic-integrations.svg" class="icon" :class="{ rotate: !!isComplete }" @click="reset" />
			<span class="chip-text para-2 text-truncate d-inline-block">
				{{ label }}
				<span v-if="isComplete" class="separator"> | </span>
				<span v-if="isComplete" class="value">{{ displayValue }}</span>
			</span>
		</div>
		<template v-slot:content>
			<div class="justify-content-between px-3 mx-0 align-items-center py-1 my-0 dropdown-container">
				<template v-if="['text', 'number'].includes(type)"
					><div class="w-100 my-2">
						<AppDropdown
							class="form-control"
							ref="inner-dropdown"
							v-model="inputOperator"
							:options="operators"
							placeholder="Select"
							:searchable="false"
							:manual-control="true"
						/>
					</div>
					<AppInput v-if="type === 'text'" :placeholder="label" v-model="inputValue" @blur="bgClick" v-on:keyup.enter="applyFilter(true)" />
					<AppInput v-if="type === 'number'" :placeholder="label" type="number" v-model="inputValue" v-on:keyup.enter="applyFilter(true)" />
				</template>
				<div v-if="type === 'date'" class="stan-flatpickr-theme">
					<FlatPickr
						class="form-control datepicker w-100 my-2"
						placeholder="Start Date"
						v-model="inputValue"
						:disableMobile="true"
						:config="{ monthSelectorType: 'static', static: true, disableMobile: true, altInput: true, altFormat: 'M j, Y' }"
					/>
					<FlatPickr
						class="form-control datepicker w-100"
						placeholder="End Date"
						v-model="secondInputValue"
						:disableMobile="true"
						:config="{ monthSelectorType: 'static', static: true, disableMobile: true, altInput: true, altFormat: 'M j, Y' }"
					/>
				</div>
				<div v-if="type === 'select'" class="w-100 my-2">
					<AppDropdown
						class="form-control"
						ref="inner-select-dropdown"
						v-model="dropdownSelection"
						:options="customDropdownOptions"
						:placeholder="`Select ${label}`"
						:searchable="true"
						:manual-control="true"
						@select="applyFilter(true)"
						:key="dropdownKey"
					/>
				</div>
				<AppButton :name="`apply-app-filter-${label}`" class="my-2 w-100" @click="applyFilter(true)" v-if="type !== 'select'">
					{{ $t('Apply') }}
				</AppButton>
			</div>
		</template>
	</AppDropdownMenu>
</template>

<script>
	import FlatPickr from 'vue-flatpickr-component'
	import 'flatpickr/dist/flatpickr.css'
	import { format, parseISO } from 'date-fns'

	export default {
		name: 'AppFilterChip',
		components: {
			FlatPickr,
		},
		props: {
			cssClasses: { type: String, default: '' },
			id: { type: String, default: '' },
			label: { type: String, default: undefined },
			filterItem: { type: Object, default: () => {} },
			customOperators: { type: Array, default: () => [] },
			customDropdownOptions: { type: Array, default: () => [] },
			customDropdownReduce: { type: String, default: 'label' },
			disabled: { type: Boolean, default: false },
		},
		data() {
			return {
				init: false,
				filters: [],
				operators: [],
				operator: undefined,
				value: undefined,
				secondValue: undefined,
				inputOperator: undefined,
				inputValue: undefined,
				secondInputValue: undefined,
				defaultOperators: {
					text: [
						{ code: 'like', label: this.$t('contains'), urlCode: 'li' },
						{ code: '=', label: this.$t('is equal to'), urlCode: 'eq' },
					],
					number: [
						{ code: '>=', label: this.$t('greater than equal to'), urlCode: 'gte' },
						{ code: '=', label: this.$t('is equal to'), urlCode: 'eq' },
						{ code: '<', label: this.$t('is less than'), urlCode: 'lt' },
						{ code: '>', label: this.$t('is greater than'), urlCode: 'gt' },
					],
					date: [{ code: 'between', label: this.$t('is between'), urlCode: 'bw' }],
					select: [{ code: 'like', label: this.$t('contains'), urlCode: 'li' }],
				},
				dropdownSelection: undefined,
				dropdownKey: 0,
			}
		},
		mounted() {
			this.initFilterChip()
		},
		computed: {
			rootId() {
				return `accordion-${this.name}`
			},
			filterObject() {
				return {
					fieldName: this.filterItem ? this.filterItem.fieldName : undefined,
					operator: this.operator ? this.operator.code : undefined,
					value: this.value,
					secondValue: this.secondValue,
					type: this.type,
				}
			},
			isComplete() {
				return this.operator && (this.value || this.secondValue)
			},
			isInputComplete() {
				return this.inputOperator && (this.inputValue || this.secondInputValue)
			},
			type() {
				return this.filterItem && this.filterItem.type ? this.filterItem.type : 'text'
			},
			displayValue() {
				if (this.type === 'date') {
					if (this.value && this.secondValue) {
						return `${this.formatDate(this.value)} - ${this.formatDate(this.secondValue)}`
					}
					if (this.value && !this.secondValue) {
						return this.formatDate(this.value)
					}
					if (!this.value && this.secondValue) {
						return this.formatDate(this.secondValue)
					}
				}
				if (!this.dropdownSelection) {
					return this.value
				}
				if (this.type === 'select') {
					return this.dropdownSelection.label
				}

				return this.value
			},
		},
		watch: {
			filterItem: {
				deep: true,
				immediate: true,
				handler(newVal) {
					if (!this.init) {
						this.initFilterChip()
					}
					if (newVal && newVal.initFilterValue && Object.keys(newVal.initFilterValue).length > 0 && this.operators.length > 0) {
						this.inputOperator =
							this.operators.find(operator => operator.urlCode === newVal.initFilterValue.operatorCode) || this.operators[0]
						if (newVal.type === 'select') {
							this.dropdownSelection = this.customDropdownOptions.find(
								option => option[this.customDropdownReduce].toString() === newVal.initFilterValue.value
							)
						} else {
							this.inputValue = newVal.initFilterValue.value
							this.secondInputValue = newVal.initFilterValue.secondValue
						}
						this.$nextTick(() => {
							this.applyFilter(newVal.type !== 'date')
						})
					}
				},
			},
			disabled(val) {
				if (val === true) {
					this.reset()
				}
			},
		},
		methods: {
			initFilterChip() {
				if (this.customOperators.length > 0) {
					this.operators = this.customOperators
				} else {
					this.operators = this.defaultOperators[this.filterItem.type]
				}

				this.inputOperator = this.operators[0]

				const calendar = $('.flatpickr-calendar')
				if (calendar.length > 0) {
					for (let i = 0; i < calendar.length; i++) {
						calendar[i].addEventListener('click', e => {
							e.stopPropagation()
						})
					}
				}
				this.init = true
			},
			showFilter() {
				if (this.type === 'select' && this.customDropdownOptions.length > 0) {
					this.$refs['inner-select-dropdown'].show()
				}
			},
			applyFilter(manuallyClose = false) {
				if (this.$refs['main-dropdown'].isShown() || (!this.isComplete && this.filterItem.initFilterValue)) {
					if (this.$refs['inner-dropdown']) this.$refs['inner-dropdown'].hide()
					if (this.type === 'select' && this.dropdownSelection) {
						this.inputValue = this.dropdownSelection[this.customDropdownReduce]
					}
					if (this.isInputComplete) {
						this.operator = this.inputOperator
						this.value = this.inputValue
						this.secondValue = this.secondInputValue

						this.$emit('addFilter', this.computeFilterArray())
					} else {
						this.reset()
					}
					if (manuallyClose) {
						this.close()
					}
				}
			},
			bgClick() {
				if (this.$refs['inner-dropdown']) this.$refs['inner-dropdown'].hide()
			},
			reset(e) {
				if (this.isComplete) {
					this.$emit('removeFilter', this.computeFilterArray())
					this.inputValue = undefined
					this.secondInputValue = undefined
					this.value = undefined
					this.secondValue = undefined
					this.operator = undefined
					this.dropdownSelection = undefined
					this.dropdownKey += 1
					if (e) {
						e.stopPropagation()
					}
				}
				this.close()
			},
			close() {
				this.$refs['main-dropdown'].close()
			},
			computeFilterArray() {
				const fieldName = this.filterItem ? this.filterItem.fieldName : undefined
				const operator = this.operator ? this.operator.code : undefined
				const urlCode = this.operator ? this.operator.urlCode : undefined
				const value = this.value
				const secondValue = this.secondValue
				const type = this.type

				const filters = []
				if (type === 'date') {
					if (value) {
						filters.push({
							fieldName,
							operator: '>=',
							value,
							type,
							urlCode: 'gte',
						})
					}
					if (secondValue) {
						filters.push({
							fieldName,
							operator: '<=',
							value: secondValue,
							type,
							urlCode: 'lte',
						})
					}
				} else {
					filters.push({
						fieldName,
						operator,
						value,
						type,
						urlCode,
					})
				}
				return filters
			},
			formatDate(dateString) {
				const date = parseISO(dateString, this.$constants.DATE_FORMAT.ISO_8601_DATE)
				return format(date, this.$constants.DATE_FORMAT.USER_DATE)
			},
		},
	}
</script>

<style lang="scss" scoped>
	.chip {
		display: flex;
		align-items: center;
		padding: 8px 10px 8px 6px;
		height: 34px;
		border: 1px dashed var(--stan-text-light-color);
		border-radius: 50px;
		&:hover {
			background: var(--stan-gray-soft-color);
		}
	}
	.chip-text {
		font-weight: normal;
		max-width: 300px;
		vertical-align: bottom;
		color: var(--stan-text-light-color);
		.separator {
			color: var(--stan-text-light-color);
		}
		.value {
			color: var(--stan-primary-primary-color);
		}
	}
	.dropdown-container {
		width: 350px;
		max-width: 80vw;
	}
	.icon {
		height: 24px;
		width: 24px;
		margin-right: 4px;
		transition: all linear 0.1s;
	}
	.rotate {
		transform: rotate(45deg);
	}
	.datepicker {
		border: none;
		background-color: var(--stan-gray-light-color) !important;
		cursor: pointer;
	}
	.disabled {
		pointer-events: none;
		opacity: 50%;
	}
</style>
<style lang="scss">
	.flatpickr-calendar {
		box-shadow: var(--stan-box-shadow);
		&.arrowTop:before {
			border-bottom: none;
		}
	}
</style>
