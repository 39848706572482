<template>
	<div>
		<span id="calendar-events-popup" aria-expanded="false"></span>
		<div ref="cell" class="cell-event-wrapper" :class="type">
			<div class="event-dot"></div>
			<div class="event-display-time">{{ count }}</div>
			<div class="event-name">{{ title }}</div>
		</div>
	</div>
</template>

<script>
	import CalendarEventsMixin from './CalendarEventsMixin.js'

	export default {
		name: 'CalendarCellEvent',
		mixins: [CalendarEventsMixin],
		props: {
			type: {
				type: String,
			},
			count: {
				type: Number,
			},
			title: {
				type: String,
			},
		},
	}
</script>

<style lang="scss" scoped>
	.events-popup {
		cursor: pointer;
		min-width: min(410px, 90vw);
		max-height: min(280px, 90vh);
		background: #fff;
		padding: 30px 25px;
		margin: 5px;
		border-radius: 15px;
		box-shadow: var(--stan-box-shadow);

		.events-popup-container {
			overflow: auto;
			max-height: 180px; // 5 rows at 36px each

			.cell-event-wrapper {
				padding: 10px 6px;
				min-height: 36px;
				&.cal_blocked {
					background: initial;
				}
				&.blocked {
					background: initial;
				}

				.event-dot {
					min-width: 12px;
					min-height: 12px;
				}
			}
		}

		.popup-header {
			.month {
				font-size: 14px;
			}
			.day {
				color: var(--stan-text-light-color);
			}

			.close-wrapper {
				margin-top: -6px;
				margin-right: -6px;
				height: 20px;
				width: 20px;
				border-radius: 50%;
				background: var(--stan-gray-light-color);
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;

				.close-icon {
					height: 14px;
					width: 14px;
				}
			}
		}
	}

	.cell-event-wrapper {
		border-radius: 2px;
		width: 100%;
		align-items: center;
		justify-content: flex-start;
		gap: 6px;
		min-height: 14px;
		padding: 3px 6px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		text-wrap: nowrap;

		.event-dot {
			min-width: 8px;
			min-height: 8px;
			border-radius: 50%;
			background: var(--stan-primary-primary-color);

			&.cancelled {
				background: var(--stan-negative-primary-color);
			}
		}

		.event-display-time {
			color: var(--stan-text-light-color);
		}
		.event-name {
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&.cal_blocked {
			.event-dot {
				background: var(--stan-text-light-color);
			}

			background: var(--stan-gray-soft-color);

			> {
				.event-name {
					color: var(--stan-text-light-color);
				}
			}
		}

		&.blocked {
			.event-dot {
				background: var(--stan-danger);
			}

			background: var(--stan-negative-light-color);

			> {
				.event-display-time {
					color: var(--stan-negative-soft-color);
				}
				.event-name {
					color: var(--stan-danger);
				}
			}
		}

		&.more {
			//Only apply to direct children
			> {
				.event-name {
					color: var(--stan-text-light-color);
				}
				.event-dot {
					display: none;
				}
			}
		}
	}
</style>
