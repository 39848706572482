<template>
	<div id="app">
		<Loading ref="loading" />
		<NotificationsSection />
		<RouterView />
	</div>
</template>
<script>
	import { mapActions, mapGetters } from 'vuex'
	import axios from 'axios'
	import Loading from './Loading'
	import NotificationsSection from './NotificationsSection'

	export default {
		components: {
			NotificationsSection,
			Loading,
		},
		mounted() {
			this.$loading = this.$refs.loading
			this.initAppVersionCheck()
			this.trackTraffic()

			/* eslint-disable */
			;(function(c, l, a, r, i, t, y) {
				c[a] =
					c[a] ||
					function() {
						;(c[a].q = c[a].q || []).push(arguments)
					}
				t = l.createElement(r)
				t.async = 1
				t.src = 'https://www.clarity.ms/tag/' + i
				y = l.getElementsByTagName(r)[0]
				y.parentNode.insertBefore(t, y)
				console.log('adding clarity')
			})(window, document, 'clarity', 'script', this.siteSettings.clarityTrackingCode || 'hkwie6hrou')
		},
		data: () => ({
			layout: null,
			defaultLayout: 'default',
			routeUpdateCounter: 0,
			previousScrollY: 0,
			isAppVersionChanged: false,
		}),
		computed: {
			...mapGetters('Auth', ['user']),
			breadcrumbs() {
				this.routeUpdateCounter
				const arr = []
				// Ignore the first parameter (home) as it's not useful for meta tags
				if (this.$route?.meta?.breadcrumbs) {
					for (let i = 0; i < this.$route.meta.breadcrumbs.length; i++) {
						arr.push(this.$route.meta.breadcrumbs[i].title)
					}
				}
				const out = arr.join(' - ') || this.$t('Home')
				return out
			},
		},
		metaInfo() {
			return {
				title: this.breadcrumbs,
				titleTemplate: 'Stan - %s',
			}
		},
		watch: {
			// eslint-disable-next-line func-names
			'$store.state.Auth.user': function(user) {
				if (user) {
					this.fetchIntegrations()
					window.clarity('identify', this.user.username, this.$cookies.get('session_uuid'), 'Vue-Admin', this.$cookies.get('cookie_uuid'))
				}
			},
			$route(newVal) {
				this.$stanAnalytics('admin-page-view', {
					// User_id and username are optional as non login page should not have user object
					meta: { user_id: this.user?.user_id, username: this.user?.username },
					props: { name: newVal.name, path: newVal.path, fullPath: newVal.fullPath, query: newVal.query, params: newVal.params },
				})

				this.routeUpdateCounter += 1
				if (this.isAppVersionChanged) {
					// eslint-disable-next-line no-console
					console.log('refreshing page now ...')
					this.$router.go() // refresh page if detected a version change only when the route changed (to prevent state loss)
				}
			},
		},
		methods: {
			...mapActions('SiteSettingsStore', ['fetchIntegrations']),
			...mapActions('Auth', ['saveSignUpMetadata', 'saveSubscribeFlags']),
			trackTraffic() {
				// Track traffic attribution through cookies to be used in analytics events later
				const url = new URL(window.location.href)
				const utmSource = url.searchParams.get('utm_source')
				const utmMedium = url.searchParams.get('utm_medium')
				const utmCampaign = url.searchParams.get('utm_campaign')
				const ref = url.searchParams.get('ref')

				this.saveSignUpMetadata({ utm_source: utmSource, utm_medium: utmMedium, utm_campaign: utmCampaign, ref })

				let trialDays = parseInt(this.$cookies.get('trial_days'), 10) || 14
				trialDays = parseInt(url.searchParams.get('trialDays'), 10) || trialDays
				if (trialDays === 14 || trialDays === 30) {
					this.$cookies.set('trial_days', trialDays)
					this.$cookies.set('trial_days', trialDays, '7d', null, 'stanwith.me')
					this.$cookies.set('trial_days', trialDays, '7d', null, 'stan.store')
				}

				const subscribeFlags = {}
				let promo = url.searchParams.get('promo')
				promo = promo !== null && promo !== undefined && promo !== ''
				if (promo) subscribeFlags.show_promo = promo
				const code = url.searchParams.get('code')
				if (code) subscribeFlags.discount_code = code

				let annual = url.searchParams.get('annual')
				annual = annual !== null && annual !== undefined && annual !== ''
				if (annual) subscribeFlags.show_annual = annual

				let monthly = url.searchParams.get('monthly')
				monthly = monthly !== null && monthly !== undefined && monthly !== ''
				if (monthly) subscribeFlags.show_monthly = monthly

				const page = url.searchParams.get('page') || this.$cookies.get('page')
				if (page) subscribeFlags.page = page

				this.saveSubscribeFlags(subscribeFlags)
			},
			initAppVersionCheck(frequency = 1000 * 30) {
				const timer = setInterval(() => {
					axios
						.get(`app-version.json?v=${new Date().getTime()}`, { baseURL: window.location.origin })
						.then(response => {
							if (response.data && response.data.timestamp && this.$appVersion.timestamp !== response.data.timestamp) {
								// eslint-disable-next-line no-console
								console.log('change is detected')
								this.isAppVersionChanged = true
								clearInterval(timer) // stop the pulling version check after detected a version change
							}
						})
						.catch(error => {
							console.log(error)
							// this.$logError(error)
						})
				}, frequency)
			},
		},
	}
</script>

<style>
	.text-lg {
		font-size: 1.125rem !important;
	}

	.text-xl {
		font-size: 1.5rem !important;
	}

	.text-sm {
		font-size: 0.875rem !important;
	}

	.text-xs {
		font-size: 0.75rem !important;
	}
</style>
