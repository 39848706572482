const MainContainer = () => import('~/components/layouts/MainContainer').then(m => m.default || m)

const moduleRoutes = {
	path: '/marketing',
	name: 'MarketingIndex',
	redirect: '/marketing',
	component: MainContainer,
	children: [
		{
			path: '',
			name: 'marketing',
			component: () => import('./Marketing').then(m => m.default || m),
			meta: {
				pageTitle: 'Marketing',
				breadcrumbs: [{ title: 'Marketing', active: true }],
				roleSelector: true,
			},
		},
	],
}

export default moduleRoutes
