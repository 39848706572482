var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "revenue-chart" },
    [
      _c("div", { staticClass: "text-label" }, [_vm._t("label")], 2),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-number text-bold" },
        [_vm._t("number")],
        2
      ),
      _vm._v(" "),
      _c("VueApexCharts", {
        ref: "apexChart",
        attrs: {
          width: "100%",
          type: _vm.type,
          options: _vm.computedChartOptions,
          series: _vm.series,
          height: _vm.height
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }