var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "menu-wrapper h-100",
      class: { rounded: _vm.rounded },
      attrs: { id: `dropdown-wrapper-${_vm.id}` }
    },
    [
      _c(
        "div",
        {
          staticClass: "dropdown h-100",
          class: {
            "position-static": _vm.useStaticPosition,
            rounded: _vm.rounded
          },
          attrs: {
            id: `dropdownMenuLink-${_vm.id}`,
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false"
          }
        },
        [
          _c(
            "div",
            { staticClass: "menu-trigger h-100", class: _vm.menuTriggerClass },
            [
              _vm._t("default", function() {
                return [_vm._m(0)]
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "dropdown-content",
              staticClass: "dropdown-menu menu-content",
              class: { "dropdown-menu-right": _vm.rightAlign },
              style: { "min-width": `${_vm.minWidth}px` },
              attrs: {
                "aria-labelledby": `dropdownMenuLink-${_vm.id}`,
                id: "dropdown-content"
              }
            },
            [
              _vm._l(_vm.options, function(option) {
                return _c(
                  "div",
                  {
                    key: option.id,
                    staticClass: "text-center",
                    class: option.class
                  },
                  [
                    option.type === "divider"
                      ? _c("div", { staticClass: "divider" })
                      : _vm.isUseRowComponent
                      ? _c(
                          "div",
                          [
                            _c(_vm.dropdownRowComponent, {
                              tag: "Component",
                              attrs: {
                                id: `option-${option.id}`,
                                option: option
                              },
                              on: { click: _vm.selected }
                            })
                          ],
                          1
                        )
                      : _c(
                          "button",
                          {
                            staticClass:
                              "d-flex align-items-center dropdown-item",
                            class: { "item-type-tag": option.type === "tag" },
                            attrs: { id: `option-${option.id}` },
                            on: {
                              click: function($event) {
                                return _vm.selected(option)
                              }
                            }
                          },
                          [
                            option.type === "tag"
                              ? _c(
                                  "div",
                                  [
                                    _c("AppTag", {
                                      attrs: {
                                        label: option[_vm.label],
                                        backgroundColor:
                                          option[_vm.backgroundColor],
                                        textColor: option[_vm.textColor],
                                        image: option[_vm.image]
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    option.image
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mr-1 col-auto px-0 d-flex"
                                          },
                                          [
                                            _c("AppImage", {
                                              class: {
                                                rounded: _vm.roundedIcons
                                              },
                                              attrs: {
                                                src: option.image,
                                                circle: _vm.circleIcons,
                                                cover: "",
                                                width: _vm.imageWidth
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-truncate label-text para-2",
                                        class: option.class,
                                        style: option.labelStyle
                                      },
                                      [_vm._v(_vm._s(option[_vm.label]))]
                                    )
                                  ]
                                )
                          ]
                        )
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                { on: { click: _vm.checkClose } },
                [_vm._t("content")],
                2
              )
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "menu-trigger d-flex flex-wrap justify-content-center align-items-center ml-auto px-2"
      },
      [
        _c("img", {
          attrs: { src: "/images/stan_dropdown_icon.svg", width: "3.56" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }