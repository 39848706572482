<template>
	<AppModal
		id="delete-product-modal"
		class="delete-modal"
		@close="closeModal"
		action="Yes, Delete Product"
		title="Just Confirming!"
		:subheading="subheading"
		@cta="deleteProduct"
	>
	</AppModal>
</template>

<script>
	import axios from 'axios'

	export default {
		props: {
			page: { type: Object },
			storeId: { type: Number },
			userId: { type: Number },
			username: { type: String },
		},
		computed: {
			subheading() {
				if (this.page?.page_list_title) {
					return `Are you sure you want to delete "${this.page?.page_list_title}"?`
				}
				return 'Are you sure you want to delete this product?'
			},
		},
		methods: {
			async deleteProduct() {
				this.closeModal()
				const pageId = this.page.page_id
				try {
					await axios.delete(`v1/stores/${this.storeId}/pages/${pageId}`)
					this.$stanAnalytics('product-deleted', {
						meta: { user_id: this.userId, username: this.username },
						props: {
							page_id: pageId,
						},
					})
					// eslint-disable-next-line no-underscore-dangle
					if (window._ctrack) {
						// eslint-disable-next-line no-undef
						_ctrack.track('product_deleted')
					}
					this.$stanNotify({
						type: 'success',
						title: this.$t('Done!'),
						text: this.$t('Your product has been deleted.'),
						duration: 5000,
					})
					this.$emit('deleted')
				} catch (err) {
					this.$logError(err)
					this.$stanNotify({
						type: 'error',
						title: this.$t('Something went wrong'),
						text: this.$t('Please contact us at friends@stanwith.me'),
						duration: 5000,
					})
				}
			},
			closeModal() {
				$('#delete-product-modal').modal('hide')
				this.$emit('close')
			},
		},
	}
</script>

<style lang="scss" scoped>
	.delete-modal::v-deep {
		overflow: visible;
		.modal-inner-content {
			overflow: visible;
		}
	}
	.product-card {
		padding: 15px;
		box-shadow: var(--stan-box-shadow);
		background: var(--stan-white);
		position: relative;
		border-radius: 12px;
		flex-wrap: nowrap;
		margin-bottom: 15px;

		&__image {
			background: var(--stan-gray-light-color);
			width: 57px;
			height: 57px;
			border-radius: 5px;
			object-fit: cover;
		}

		&__text {
			display: grid;
			grid-template-columns: auto 1fr;
		}
	}
</style>
