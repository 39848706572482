<template>
	<div v-show="creditCardRequired">
		<CreditCardInput v-if="stripe" :pre-auth="true" @update="setCard($event)" :stripe="stripe" class="my-2"></CreditCardInput>

		<div v-if="stripe_error" id="cardErrorMessage" data-test-id="card-error-message">{{ stripe_error }}</div>
		<AppModal
			ref="modal"
			:staticDataBackdrop="true"
			:hideClose="true"
			title="Looks like you may already have an account!"
			:action="`Yes, Start My ${trialDays}-Day Trial`"
			@cta="createSubscription"
			secondary-action="Login to Existing Account"
			@secondary-cta="goToLoginWithExistingEmail"
		>
			<div class="text-center">
				{{ subheading }}
			</div>
		</AppModal>
	</div>
</template>

<script>
	import axios from 'axios'
	import { mapGetters, mapActions } from 'vuex'

	import CreditCardInput from '~/components/CreditCardInput'
	import CONSTANTS from '../../../global_helper/constants.js'

	export default {
		components: {
			CreditCardInput,
		},
		mounted() {
			this.grecaptcha = window.grecaptcha
			// eslint-disable-next-line no-undef
			this.stripe = Stripe(this.siteSettings.stripePlatformKey)
			this.$stanAnalytics('subscription-creation-attempted', {
				meta: { user_id: this.user.user_id, username: this.user.username },
			})
		},
		props: {
			trialDays: {
				type: Number,
				default: 14,
			},
			priceId: { type: String, default: undefined },
			promoId: { type: String, default: undefined },
			creditCardRequired: { type: Boolean, default: true },
			mustApplyTrial: { type: Boolean, default: false },
		},
		data() {
			return {
				btnDisable: false,
				stripe: null,
				stripe_error: null,
				card: null,
				customerId: null,
				other_emails: [],
			}
		},
		methods: {
			...mapActions('Auth', ['fetchUser', 'logout']),
			setCard(event) {
				this.card = event
			},
			getPlanPrice() {
				switch (this.priceId) {
					case this.siteSettings.stanSubscriptionCreatorMonthlyPriceId:
						return 29.0
					case this.siteSettings.stanSubscriptionCreatorAnnualPriceId:
						return 300.0
					case this.siteSettings.stanSubscriptionCreatorProMonthlyPriceId:
						return 99.0
					case this.siteSettings.stanSubscriptionCreatorProAnnualPriceId:
						return 948.0
					default:
						return 29.0
				}
			},
			async checkCreatePaymentMethodAndSubscribe() {
				this.stripe_error = null
				if (!this.user) {
					console.error('Not authenticated')
					return
				}
				this.$stanAnalytics('subscription-creation-create-payment-method', {
					meta: { user_id: this.user.user_id, username: this.user.username },
				})
				this.loading = true
				this.$emit('loading')
				try {
					this.customerId = await this.getCreateCustomer()

					if (!this.paymentMethodId && this.creditCardRequired) {
						this.paymentMethodId = await this.createPaymentMethod(this.card, this.customerId)
						this.$AdsTracking(
							'CapiAndFBTrack',
							'AddPaymentInfo',
							{
								content_category: 'Signup Process',
								value: this.getPlanPrice(),
								currency: 'USD',
							},
							{},
							{ user_id: this.user.user_id }
						)
					}

					// Only show this alert for new signups
					if (this.paymentMethodId && !this.hasSubscriptionHistory) {
						await this.getExistingAccountsWithPaymentMethod()
						if (this.other_emails && this.other_emails.length > 0) {
							this.$stanAnalytics('subscription-creation-payment-method-has-other-accounts', {
								meta: { user_id: this.user.user_id, username: this.user.username, other_emails: this.other_emails },
							})
							await this.$nextTick()
							// eslint-disable-next-line consistent-return
							return $(this.$refs.modal.$el).modal('show')
						}
					}
					if (this.paymentMethodId || !this.creditCardRequired) {
						this.createSubscription()
					}
				} catch (error) {
					this.$stanNotify({
						type: 'error',
						title: this.$t('Something went wrong'),
						text: error?.response?.data?.message ?? this.$t('Please try again later.'),
					})
					this.enableButton()
					this.loading = false
					this.$logError(error)
					this.$emit('loaded')
				}
			},
			async getCreateCustomer() {
				const { data } = await axios.get('v1/integrations/stripe/get-create-customer')
				return data.customer_id
			},
			async getExistingAccountsWithPaymentMethod() {
				const { data } = await axios.get('/v1/users/payment-method-lookup', { params: { payment_method_id: this.paymentMethodId } })
				this.other_emails = data.other_emails
			},
			async createPaymentMethod(card, customerId) {
				// Check payment method with setup intent
				const token = await this.grecaptcha.enterprise.execute(this.$configSettings.captchaSiteKey, {
					action: 'CreateSetupIntent',
				})

				const setupIntentPayload = {
					token,
					customer_id: customerId,
				}

				const response = await axios.post('/v1/stripe/create-setup-intent', setupIntentPayload)
				const secret = response.data?.secret
				const result = await this.stripe.confirmCardSetup(secret, { payment_method: { card } })

				if (result.error) {
					this.$stanNotify({
						type: 'error',
						title: 'There was an issue',
						text: 'We were unable to validate your payment method. Please try another card.',
					})
					this.$logError(result.error)
					this.enableButton()
					return null
				}
				return result.setupIntent.payment_method
			},
			createSubscription() {
				const { customerId, paymentMethodId, priceId, promoId, mustApplyTrial = false } = this
				if (customerId && priceId) {
					this.$stanAnalytics('subscription-creation-create-subscription', {
						meta: { user_id: this.user.user_id, username: this.user.username },
					})
					let payload = { customerId, priceId, trialDays: this.trialDays }
					if (promoId) {
						payload = { ...payload, ...{ promoId } }
					}
					if (mustApplyTrial) {
						payload = { ...payload, ...{ mustApplyTrial } }
					}
					if (paymentMethodId) {
						payload = { ...payload, ...{ paymentMethodId } }
					}
					if (!this.user.subscriptions?.platform || this.isCurrentSubscriptionAffiliate) {
						axios
							.post('v1/integrations/stripe/create-subscription', payload)
							.then(response => response.data)
							// Normalize the result to contain the object returned by Stripe.
							// Add the additional details we need.
							.then(result => ({ paymentMethodId, priceId, subscription: result }))
							.then(this.onSubscriptionComplete)
							.catch(error => {
								this.paymentMethodId = undefined // In case server error, we are resetting the payment method id
								this.displayError(error)
								this.loading = false
								this.enableButton()
								this.$logError(error)
								this.$stanAnalytics('subscription-creation-failed', {
									meta: { user_id: this.user.user_id, username: this.user.username },
									props: { reason: error.response.data.message },
								})
							})
					} else {
						const payload = { paymentMethodId, customerId }
						axios
							.post('v1/integrations/stripe/update-payment-method-platform', payload)
							.then(() => {
								axios
									.post('v1/users/subscriptions/change-tier', {
										stripe_id: this.user.subscriptions.platform.stripe_id,
										price_id: this.priceId,
									})
									.then(() => {
										this.$stanNotify({
											type: 'success',
											title: this.$t('Success!'),
											text: this.$t('Your subscription payment has been updated.'),
										})
										this.fetchUser().then(() => {
											this.$emit('success')
											this.enableButton()
										})
									})
							})
							.catch(error => {
								this.$stanNotify({
									type: 'error',
									title: this.$t('Something went wrong'),
									text: error?.response?.data?.message ?? this.$t('Please try again later.'),
								})
								this.enableButton()
								this.$logError(error)
							})
					}
				} else {
					this.enableButton()
					this.loading = false
					this.$stanNotify({
						type: 'error',
						title: 'Something went wrong',
						time: 2000,
						text: 'Please reach out to us at friends@stanwith.me.',
					})
					this.$stanAnalytics('subscription-creation-failed', {
						meta: { user_id: this.user.user_id, username: this.user.username },
						props: { reason: `CustomerId (${customerId}) or priceId (${priceId}) not found` },
					})
				}

				$(this.$refs.modal.$el).modal('hide')
			},
			onSubscriptionComplete(result) {
				if (result.subscription && (result.subscription.status === 'active' || result.subscription.status === 'trialing')) {
					this.fetchUser().then(() => {
						const props = {
							email: this.user.email,
							fullName: this.user.full_name,
						}
						if (this.getSignUpMetadata.utm_source) {
							props.utmSource = this.getSignUpMetadata.utm_source
						}
						if (this.getSignUpMetadata.utm_medium) {
							props.utmMedium = this.getSignUpMetadata.utm_medium
						}
						if (this.getSignUpMetadata.utm_campaign) {
							props.utmCampaign = this.getSignUpMetadata.utm_campaign
						}
						this.$stanAnalytics('subscription', {
							meta: { user_id: this.user.user_id, username: this.user.username },
							props,
						})
						this.$emit('success')

						const isYearlySubscription = result.subscription?.plan?.interval === 'year'

						this.$AdsTracking(
							'CapiAndFBTrack',
							'StartTrial',
							{
								content_category: 'Signup Process',
								value: this.getPlanPrice(),
								currency: 'USD',
								subscription_type: isYearlySubscription ? 'Yearly' : 'Monthly',
							},
							{},
							{
								user_id: this.user.user_id,
							}
						)
						this.$AdsTracking('TTTrack', 'PlaceAnOrder')
						this.$stanAnalytics('pixel-Purchase', {
							meta: { user_id: this.user.user_id, username: this.user.username },
						})

						this.$gtag.event('conversion', {
							send_to: CONSTANTS.GOOGLE_AD_CONV_ACTION.PURCHASE,
						})
					})
				} else {
					this.enableButton()
					this.$stanNotify({
						type: 'error',
						title: 'Something went wrong',
						time: 2000,
						text: 'Please reach out to us at friends@stanwith.me.',
					})
				}
			},
			goToLoginWithExistingEmail() {
				this.$stanAnalytics('subscription-creation-go-to-login', {
					meta: { user_id: this.user.user_id, username: this.user.username },
				})

				this.logout()
				this.$router.push({ name: 'login' }).catch(() => {})
				$(this.$refs.modal.$el).modal('hide')
			},
			enableButton() {
				this.$emit('loaded')
			},
			displayError(event) {
				if (event.error && event.error.message) {
					this.stripe_error = event.error.message
				} else {
					this.$stanNotify({
						type: 'error',
						// eslint-disable-next-line quotes
						title: "Couldn't create a subscription",
						time: 2000,
						text: event.response.data.message || 'Please reach out to us at friends@stanwith.me.',
					})
				}
				this.loading = false
			},
		},
		computed: {
			...mapGetters('Auth', ['user', 'getSignUpMetadata', 'hasSubscriptionHistory']),
			subheading() {
				if (!this.other_emails || this.other_emails.length === 0) {
					return ''
				}
				const maskedEmail = this.$commonHelper.maskEmail(this.other_emails[0])
				return `We found an account using the email "${maskedEmail}", are you sure you'd like to create a new account?`
			},
			isCurrentSubscriptionAffiliate() {
				return this.user.subscription_tier === this.$constants.TIER.AFFILIATE
			},
		},
	}
</script>

<style lang="scss" scoped>
	#cardErrorMessage {
		color: var(--stan-danger) !important;
		margin: 0;
		font-size: 12px;
		margin-bottom: 0px !important;
		margin-top: 5px;
	}
</style>
