/* eslint-disable import/extensions */
import axios from 'axios'
import store from '~/store'

export default function trigger(config) {
	// config is an object containing experiment map - implemented this way to accommodate logging
	// multiple experiments in a single call to avoid racing conditions
	const data = {}
	for (const experimentName in config) {
		const group = config[experimentName]
		let variant
		if (!experimentName) {
			console.error(`Invalid experiment name, experiment is not tracked: ${experimentName}`)
			return
		}
		if (!group) {
			console.error(`Invalid experiment group, experiment is not tracked: ${group}`)
			return
		}

		if (group.toLowerCase() === 'control') {
			variant = 0
		} else if (group.toLowerCase() === 'treatment') {
			variant = 1
		} else {
			console.error(`Unknown experiment group ${group}`)
		}
		data[experimentName] = variant

		axios.put('v1/experiments/variant-assignment', { name: experimentName, variant }).catch(error => {
			console.error(error)
		})
	}

	const payload = {
		name: 'experiment',
		type: 'user',
		reference_id: store.getters['Auth/user'].user_id,
		data,
	}

	axios.put('v1/tags', payload).catch(error => {
		console.error(error)
	})
}
