var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["load-wrapper", { "has-mask": _vm.mask }],
      style: _vm.wrapperStyles
    },
    [
      _c("div", { staticClass: "blue-circle" }, [
        _c(
          "div",
          { staticClass: "spinner-border", attrs: { role: "status" } },
          [
            _c("span", { staticClass: "sr-only" }, [
              _vm._v(_vm._s(_vm.$t("Loading...")))
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }