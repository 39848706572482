<template>
	<div>
		<CoursePageDesktopPreview
			v-if="showPreview"
			class="course-preview-wrapper"
			:course="coursePreview"
			:user="user"
			@close="closePreview"
			hideVideo
		/>
		<div v-for="message in messages" :key="message.id">
			<div v-if="message.author === 'stanley'">
				<StanleyTyping v-if="message.template === 'intake'" :text="message.text"></StanleyTyping>
				<StanleyTyping v-if="message.template === 'generating'" :text="message.text" @doneTyping="scrollToBottom">
					<div class="d-flex align-items-center">
						<InfiniteProgressBar
							class="loading-bar my-3"
							:class="{ 'opacity-20': message.status === 'done' }"
							:estimatedSeconds="180"
							:done="message.status === 'done'"
						/>
					</div>
				</StanleyTyping>
				<StanleyTyping v-if="message.template === 'preview'" :text="message.text" :typing="false" @doneTyping="nextStanleyAction">
					<div class="course-preview-container p-3 mt-2">
						<div class="d-flex flex-wrap justify-items-start">
							<div class="px-2">
								<img src="http://localhost:8080/images/add-section/add-product-course.svg" alt="" />
							</div>
							<div class="px-2">
								<h5 class="header mb-3 text-truncate" style="width: 250px;">{{ message.course.data.title }}</h5>
								<div class="d-flex flex-wrap">
									<AppButton class="mr-2 mb-2" @click="createProduct(message.course)">Edit Course</AppButton>
									<AppButton outline class="mr-2 mb-2" @click="toggleCoursePreview(message.course)">Preview</AppButton>
								</div>
							</div>
						</div>
					</div>
				</StanleyTyping>
				<StanleyTyping v-if="message.template === 'feedback'" :text="message.text">
					<div class="d-flex flex-wrap justify-content-start mt-2">
						<AppButton outline size="md" class="mr-2 mb-2" @click="nextStanleyAction('exit')">Maybe next time! I'm done.</AppButton>
					</div>
				</StanleyTyping>
				<StanleyTyping v-if="message.template === 'exit'" :text="message.text"></StanleyTyping>
			</div>
			<StanleyUserMessage v-else>{{ message.text }}</StanleyUserMessage>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'

	// import sampleCourse from '~/assets/sampleCourse'

	export default {
		props: {
			prompt: {
				type: String,
				required: false,
			},
			active: {
				type: Boolean,
				required: false,
			},
		},
		mounted() {
			// this.messages.push(this.messageTempaltes.intake)
		},
		data() {
			return {
				messages: [],
				showPreview: false,
				coursePreview: undefined,
				text: "Give me a moment. I'm drafting your course!",
				textIterate: "How's this version?",
				loading: false,
				// genearationTimeEstimation: 60 * 10, // 10 minutes
				genearationTimeEstimation: 5, // 5 seconds
				generationProgressInterval: undefined,
				generationProgress: 0,
				stage: 'intake',
			}
		},
		methods: {
			...mapActions('Stanley', ['actionSendMessage']),
			exitThread() {
				this.$emit('threadDeactivated', { name: 'ThreadCourseBuilder' })
			},
			generateCourse() {
				this.stage = 'generating'
				const lastUserMessage = this.lastUserMessage

				// Ask Stanley to generate the course
				const payload = {
					inputQuestion: lastUserMessage.text,
					timestampId: lastUserMessage.timestampId,
					pollInterval: 2000, // poll every 2 seconds
					handler: {
						name: 'course-builder',
						params: {
							// mode: 'fast-test',
						},
					},
				}
				if (this.lastCourse) {
					payload.handler.name = 'course-feedback'
					payload.handler.params.course = this.lastCourse
				}
				this.actionSendMessage(payload)

				const message = this.getMessageTemplate('generating')
				let answer

				this.generationProgressInterval = setInterval(() => {
					answer = this.getAnswers[lastUserMessage.timestampId]

					if (answer && answer.handler && answer.handler.params && answer.handler.params.course) {
						clearInterval(this.generationProgressInterval)
						// placeholder for API checks
						this.lastGeneratingMessage.status = 'done'
						this.nextStanleyAction('course-preview', { course: answer.handler.params.course.course })
					}
				}, 1000)
				return message
			},
			nextStanleyAction(action, payload) {
				let message

				if (action) {
					// Perform action as a priority
					if (action === 'exit') {
						message = this.getMessageTemplate('exit')
						this.exitThread()
					} else if (action === 'stop-generating') {
						clearInterval(this.generationProgressInterval)
						this.lastGeneratingMessage.status = 'done'
						message = this.getMessageTemplate('feedback')
						message.text = "Changed your mind? No worries! Just let me know what you'd like to change in the input below."
						this.stage = 'feedback'
					} else if (action === 'course-preview') {
						this.stage = 'preview'
						message = this.getMessageTemplate('preview')
						message.course = payload.course
					} else if (action === 'generating') {
						message = this.generateCourse()
						this.stage = 'generating'
					}
				}

				if (!action) {
					// Perform action based on last message
					if (this.lastMessageTemplate === 'intake' || this.stage === 'intake') {
						message = this.generateCourse()
					} else if (this.lastMessageTemplate === 'preview') {
						message = this.getMessageTemplate('feedback')
						this.stage = 'feedback'
						this.scrollToBottom()
					} else if (this.lastMessageTemplate === 'feedback') {
						message = this.generateCourse()
					}
				}
				message.timestampId = new Date().getTime()
				this.messages.push(message)
			},
			estimateGenerationProgress() {
				let progress = 0
				if (this.lastGeneratingMessage) {
					const timeElapsed = (new Date().getTime() - this.lastGeneratingMessage.timestampId) / 1000
					progress = Math.min(Math.floor((timeElapsed / this.genearationTimeEstimation) * 100), 99)
				}
				return progress
			},
			getMessageTemplate(name) {
				return this.$lodash.cloneDeep(this.messageTempaltes[name])
			},
			createProduct(course) {
				this.$router.push({
					name: `linksite_add_new_product`,
					params: {
						slug: 'course',
						storeId: this.getStore.store_id,
						course,
					},
					query: { niche: this.niche, location: this.$route.query.location },
				})
			},
			scrollToBottom(delay = 100) {
				setTimeout(() => {
					this.$emit('scrollToBottom')
				}, delay)
			},
			logMessage(text) {
				const message = { author: 'user', text, timestampId: new Date().getTime() }
				this.messages.push(message)
				return message
			},
			toggleCoursePreview(course) {
				this.coursePreview = course
				this.showPreview = !this.showPreview
			},
			closePreview() {
				this.showPreview = false
			},
		},
		computed: {
			...mapGetters('Auth', ['user']),
			...mapGetters('Stores', ['getStore']),
			...mapGetters('Stanley', ['getAnswers']),
			userFirstName() {
				return this.user.full_name.split(' ')[0]
			},
			messageTempaltes() {
				return {
					intake: {
						template: 'intake',
						author: 'stanley',
						text: `${this.userFirstName}, tell me more about your course!`,
					},
					preview: {
						template: 'preview',
						author: 'stanley',
						text: `Check this out! 🎉`,
					},
					feedback: {
						template: 'feedback',
						author: 'stanley',
						text: `Don't love it? Tell me what you'd like to change below!`,
					},
					generating: {
						template: 'generating',
						author: 'stanley',
						text: `Hang tight! I'm drafting your course now.`,
						status: 'generating',
					},
					exit: {
						template: 'exit',
						author: 'stanley',
						text: 'Great! Is there anything else I can help you with?',
					},
				}
			},
			lastMessageTemplate() {
				const filteredMessages = this.messages.filter(message => message.author === 'stanley')
				if (!filteredMessages.length) {
					return 'intake'
				}
				return filteredMessages[filteredMessages.length - 1].template
			},
			lastGeneratingMessage() {
				const filteredMessages = this.messages.filter(message => message.author === 'stanley' && message.template === 'generating')
				return filteredMessages[filteredMessages.length - 1]
			},
			lastUserMessage() {
				const filteredMessages = this.messages.filter(message => message.author === 'user')
				return filteredMessages[filteredMessages.length - 1]
			},
			lastCourse() {
				const filteredMessages = this.messages.filter(message => message.author === 'stanley' && message.template === 'preview')
				const lastMessage = filteredMessages[filteredMessages.length - 1]
				if (!lastMessage) {
					return undefined
				}
				return lastMessage.course
			},
		},
		watch: {
			prompt(newValue) {
				if (this.lastMessageTemplate === 'generating') {
					return
				}
				this.logMessage(newValue)

				setTimeout(() => {
					if (this.stage === 'feedback') {
						this.nextStanleyAction('generating')
					} else if (this.stage === 'intake') {
						this.nextStanleyAction('generating')
					}
				}, 1000)
			},
		},
	}
</script>

<style lang="scss" scoped>
	.loading-bar {
		width: 300px;
	}

	.opacity-20 {
		opacity: 0.2;
	}

	.course-preview-container {
		box-shadow: var(--stan-box-shadow);
		border-radius: 8px;
		max-width: 430px;

		.header {
			font-size: 16px;
			font-weight: bold;
		}
	}
</style>
