<template>
	<div class="col-md common-file-drop-zone-wrapper" :class="dropZoneClass">
		<div v-if="title && !ghost" class="row">
			<label class="control-label">{{ title }}</label>
		</div>

		<div class="d-flex flex-column gap-15px">
			<div
				v-if="!ghost"
				draggable
				:class="['common-file-drop-zone drop', isDragging ? 'dropZone-over' : '', { grid: !multiple }]"
				@dragover.prevent="dragOver"
				@dragleave.prevent="dragLeave"
				@drop.prevent="drop($event)"
			>
				<div class="common-file-preview relative" v-if="!multiple">
					<div v-if="enableDownloadFile && !videoPreviewLoading" class="file-preview-overlay" @click="$emit('download-file')">
						<div class="overlay-centered-content">
							<InlineSvg src="/images/icons/ic-digital-download.svg" />
						</div>
					</div>
					<div v-if="accept === 'video'">
						<div v-if="videoPreviewLoading" class="spinner-border video-preview-loader" role="status">
							<span class="sr-only">{{ $t('Loading...') }}</span>
						</div>
						<ImagePreview
							v-if="videoPreviewImage !== ''"
							v-show="!videoPreviewLoading"
							class="preview-image"
							ref="video-preview-img"
							:source="videoPreviewImage"
							:width="93"
							:height="93"
							:quality="80"
							:retry="3"
							static-class="video video-file-drop-zone"
							alt="video preview"
							@load="videoPreviewLoaded"
						/>
						<div v-if="videoPreviewImage !== ''" v-show="!videoPreviewLoading" class="close-btn" @click="deleteFile">
							<InlineSvg src="/images/icons/ic-close.svg" :alt="$t('close button')" />
						</div>
						<img v-if="videoPreviewImage === '' && !videoPreviewLoading" src="/images/funnel_no__video_preview.svg" alt="" />
					</div>
					<div v-else-if="accept === 'image'" data-test-id="common-file-drop-zone-section">
						<div v-if="image !== this.$constants.PUBLIC_RESOURCES.DEFAULT_REVIEW_IMAGE" class="close-btn" @click="deleteFile">
							<InlineSvg src="/images/icons/ic-close.svg" :alt="$t('close button')" />
						</div>
						<img
							v-if="imageSrc === noFileImageSrc"
							:src="imageSrc"
							width="60"
							height="60"
							static-class="image"
							:alt="$t('image preview')"
						/>
						<ImagePreview
							v-else
							class="preview-image"
							:source="imageSrc"
							:width="90"
							:height="90"
							static-class="image"
							:alt="$t('image preview')"
						/>
					</div>
					<div v-else data-test-id="common-file-drop-zone-section">
						<img class="image" src="/images/funnel_no__file_preview.svg" :alt="$t('image preview')" />
					</div>
				</div>
				<div class="common-file-upload common-file-upload-user">
					<div class="w-100 h-100 d-flex align-items-center">
						<div v-if="fileStatus === 0" id="pick-avatar" class="w-100 file-drop-zone" style="position: relative;">
							<div v-if="!uploading || multiple">
								<div class="placeholder-value text-center" :class="{ 'd-md-block d-none': !multiple }">
									<div v-if="dropZonePlaceholder" class="placeholder-value-row para-3 text-light">{{ dropZonePlaceholder }}</div>
									<div v-else>
										<div class="placeholder-value-row para-3 text-light">
											{{ `Drag Your ${acceptFileTypeName} Here` }}
										</div>
									</div>
									<div class="acceptable-dimension para-4" v-if="expectedDimension">{{ expectedDimension }}</div>
								</div>
								<AppButton
									name="common-file-drop-zone-upload-button"
									size="sm"
									:outline="true"
									class="m-auto w-auto"
									:class="{ 'big-button': !showButtonImage }"
									@click="onClickUpload"
								>
									{{ buttonText }}
								</AppButton>
							</div>
						</div>
					</div>
				</div>
				<div v-if="isDragging" class="blue-circle down-arrow">
					<img src="/images/down-arrow.svg" :alt="$t('down arrow')" />
				</div>
				<LoadingSpinner v-if="uploading && !multiple" :mask="true" />
			</div>
			<div v-if="!ghost && filesUploading.length && !hideProgress" class="file-list d-flex flex-column gap-15px">
				<UploadedItemRow
					v-for="(file, index) in filesUploading"
					:key="file.metadata.id"
					:isUploading="(file.progress >= 0 && file.progress < 100) || (file.progress === 100 && !file.src)"
					:uploadProgress="file.progress"
					:fileName="file.name"
					:isErrored="Boolean(file.error)"
					@deleteFile="() => cancelFile(index)"
					:url="file.src"
				/>
			</div>
		</div>
		<FileUpload
			ref="file-drop-zone-file-upload"
			:accepts="inputAccepts"
			:confirm-upload="false"
			:multiple="multiple"
			:options="options"
			:object-type="objectType"
			@assetUp="fileUploaded"
			@filePicked="onFilesPicked"
			@progress="progressUploaded"
			@errorUp="errorFileUploaded"
			@file-selection-cancelled="$emit('cancel-unsplash')"
		/>
		<ImageCropperModal
			v-if="isImageAccepted"
			ref="imageCropperModal"
			:aspect-ratio="cropAspectRatio"
			:file-name="fileName"
			:file-type="fileMimeType"
			@imageCropped="imageCropped"
			@close="imageCropClose"
		/>
		<UnsplashImagePickerModal
			v-if="isImageAccepted"
			ref="unsplash-image-picker-modal"
			@unsplashImagePicked="onUnsplashImagePicked"
			@imageDropped="drop"
			@uploadSelected="onImageUploadOptionPicked"
			@close-modal="$emit('cancel-unsplash')"
		/>
	</div>
</template>
<script>
	import axios from 'axios'
	import FileUpload from '~/stan-vue-shared/components/FileUpload'
	import ImageCropperModal from '~/components/modals/ImageCropperModal'
	import UnsplashImagePickerModal from '~/components/modals/UnsplashImagePickerModal'
	import ImagePreview from '~/stan-vue-shared/components/ImagePreview.js'
	import LoadingSpinner from '../../stan-vue-shared/components/LoadingSpinner'

	export default {
		components: { FileUpload, ImageCropperModal, UnsplashImagePickerModal, ImagePreview, LoadingSpinner },
		props: {
			image: {
				type: String,
				default: '',
			},
			dropZoneClass: {
				type: String,
				default: '',
			},
			title: {
				type: String,
				default: '',
			},
			accept: {
				type: String,
				default: 'file',
				validator: value => ['file', 'video', 'image'].includes(value),
			},
			dropZonePlaceholder: {
				type: String,
				default: '',
			},
			showButtonImage: {
				type: Boolean,
				default: true,
			},
			buttonImageSrc: {
				type: String,
				default: '/images/add-image-icon.svg',
			},
			buttonText: {
				type: String,
				default() {
					if (this.accept === 'image') return `Choose ${this.accept.charAt(0).toUpperCase() + this.accept.slice(1)}`
					return `Upload ${this.accept.charAt(0).toUpperCase() + this.accept.slice(1)}`
				},
			},
			cropAspectRatio: {
				type: Number,
				default: 16 / 9,
			},
			crop: {
				type: Boolean,
				default: true,
			},
			multiple: {
				type: Boolean,
				default: false,
			},
			ghost: {
				type: Boolean,
				default: false,
			},
			hideProgress: {
				type: Boolean,
				default: false,
			},
			options: { type: Object },
			objectType: { type: String, default: 'unknown' },
			expectedDimension: {
				type: String,
				default: '',
			},
			enableDownloadFile: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				uploading: false,
				isDragging: false,
				fileStatus: 0,
				fileType: '',
				fileMimeType: '',
				fileName: null,
				filesUploading: [],
				imageCroppedFlag: false,
				croppedImageSrc: '',
				tempImageSrc: '',
				videoPreviewLoading: false,
			}
		},
		watch: {
			allFilesUploaded() {
				if (this.allFilesUploaded) {
					this.$emit('uploaded-all')
				}
			},
		},
		computed: {
			imageSrc() {
				return this.tempImageSrc || (this.image === '' ? this.noFileImageSrc : this.image)
			},
			acceptFileTypeName() {
				return `${this.accept.charAt(0).toUpperCase()}${this.accept.slice(1)}${this.multiple ? '(s)' : ''}`
			},
			inputAccepts() {
				switch (this.accept) {
					case 'image':
						return 'image/bmp,image/jpeg,image/png,img/svg+xml'
					case 'video':
						return 'video/*'
					default:
						return '*'
				}
			},
			noFileImageSrc() {
				switch (this.accept) {
					case 'image':
						return '/images/funnel_no__image_icon.svg'
					case 'video':
						return '/images/funnel_no__video_preview.svg'
					default:
						return '/images/funnel_no__file_preview.svg'
				}
			},
			videoPreviewImage() {
				return this.image ? `${this.image}/preview.jpg` : ''
			},
			isImageAccepted() {
				return this.accept === 'image'
			},
			allFilesUploaded() {
				return this.filesUploading.filter(f => f.progress < 100).length === 0
			},
		},
		mounted() {
			if (this.multiple) this.accept = 'file'
		},
		methods: {
			onClickUpload() {
				if (this.accept === 'image' && !this.multiple) {
					this.openUnsplashPicker()
				} else {
					this.addFile()
				}
			},
			addFile() {
				this.fileType = ''
				this.$refs['file-drop-zone-file-upload'].onPickFile()
			},
			onFilesPicked(files) {
				if (this.multiple) {
					this.uploading = true
					this.$emit('start-upload', files)
					this.filesUploading = this.filesUploading.concat(
						[...files].map(f => ({ name: f.name, url: '', progress: 0, metadata: f.metadata }))
					)
					this.$refs['file-drop-zone-file-upload'].uploadAllFiles()
				} else {
					const file = files[0]
					const type = file.type.split('/')
					this.fileName = file.name
					this.fileMimeType = file.type
					this.fileType = type[0]
					this.filesUploading = [{ name: file.name, url: '', progress: 0, metadata: file.metadata }]
					if (this.accept === this.fileType || this.accept === 'file') {
						this.uploading = true
						this.$emit('start-upload', files)
						if (this.isImageAccepted && this.crop) {
							this.$refs.imageCropperModal.init(file)
						} else this.$refs['file-drop-zone-file-upload'].uploadAllFiles()
					}
					if (this.accept === 'video') {
						this.videoPreviewLoading = true
					}
				}
			},
			progressUploaded(payload) {
				const file = this.filesUploading.find(f => f.metadata.id === payload.metadata.id)
				if (!file) return
				const i = this.filesUploading.indexOf(file)
				this.filesUploading.splice(i, 1, payload)
				this.$emit('progress', payload)
			},
			fileUploaded(payload) {
				if (this.accept !== this.fileType && this.accept !== 'file') return

				const file = this.filesUploading.find(f => f.metadata.id === payload.metadata.id)
				if (!file) return

				this.$emit('uploaded', payload)

				if (this.croppedImageSrc) {
					this.tempImageSrc = this.croppedImageSrc
				}
				if (!this.multiple) {
					const i = this.filesUploading.indexOf(file)
					this.stopTrackingUpload(i)
				}
			},
			errorFileUploaded(payload) {
				if (this.accept !== this.fileType && this.accept !== 'file') return

				const idx = this.filesUploading.findIndex(f => f.metadata.id === payload.metadata.id)
				if (idx < 0) {
					return
				}
				this.$stanNotify({
					type: 'error', // can be 'info', 'warning', 'success', 'error'
					title: this.$t('Oops.'),
					text: `${payload.error}`,
					duration: 10000,
				})
				payload.progress = -1
				this.filesUploading.splice(idx, 1, payload)
				this.$emit('failed', payload)
				this.$emit('progress', payload)

				if (!this.multiple) {
					this.stopTrackingUpload(idx)
				}
			},
			dragOver() {
				this.fileType = ''
				this.fileMimeType = ''
				this.isDragging = true
				this.fileStatus = 1
			},
			dragLeave() {
				this.isDragging = false
				this.fileStatus = 0
			},
			drop(e) {
				if (this.uploading && !this.multiple) return
				const files = e.dataTransfer.files
				if (!files.length) {
					this.isDragging = false
					this.uploading = false
					this.fileStatus = 0
					return
				}
				const file = files[0]
				const type = file.type.split('/')
				this.fileType = type[0]
				this.fileMimeType = type
				if (this.accept === this.fileType || this.accept === 'file') {
					this.isDragging = false
					this.uploading = true
					this.fileStatus = 0
					const reader = new FileReader()
					reader.onload = f => {
						// f.target.result contains the base64 encoding of the file
						this.file = f.target.result
					}
					reader.readAsDataURL(file)
					this.$refs['file-drop-zone-file-upload'].onFilePicked(e)
				} else {
					this.isDragging = false
					this.uploading = false
					this.fileStatus = 0
				}
			},
			deleteFile() {
				this.tempImageSrc = ''
				this.$emit('delete-file', this.fileName)
			},
			cancelFile(index) {
				this.$emit('cancel-file', this.filesUploading[index])
				this.stopTrackingUpload(index)
			},
			stopTrackingUpload(index) {
				this.filesUploading.splice(index, 1)
				if (this.filesUploading.length === 0) {
					this.fileStatus = 0
					this.uploading = false
				}
			},
			imageCropped(croppedImageDataURL, file) {
				this.imageCroppedFlag = true
				this.croppedImageSrc = croppedImageDataURL
				file.metadata = { ...file.metadata, id: file.name + Date.now() }
				this.filesUploading = [{ name: file.name, url: '', progress: 0, metadata: file.metadata }]
				this.$refs['file-drop-zone-file-upload'].uploadFile(file)
				this.$emit('start-upload', this.filesUploading)
			},
			imageCropClose() {
				this.isDragging = false
				this.fileStatus = 0
				this.$refs['file-drop-zone-file-upload'].clearFile()
				if (!this.imageCroppedFlag) {
					this.$emit('cancel-crop')
					this.stopTrackingUpload(0)
				}
				this.imageCroppedFlag = false
			},
			onImageUploadOptionPicked(option) {
				if (option === 'upload') {
					this.addFile()
				} else if (option === 'unsplash') {
					this.openUnsplashPicker()
				}
			},
			openUnsplashPicker() {
				this.$refs['unsplash-image-picker-modal'].init()
			},
			onUnsplashImagePicked(payload) {
				if (this.crop) {
					const responseOptions = { responseType: 'blob', timeout: 30000 }
					this.uploading = true
					axios.get(payload.src, responseOptions).then(r => {
						const file = r.data
						const type = file.type.split('/')
						this.fileType = type[0]
						this.fileMimeType = file.type
						this.fileName = `image-${Date.now()}.${type[1]}`
						this.$refs.imageCropperModal.init(file)
					})
				} else {
					this.$emit('uploaded', payload)
				}
			},
			videoPreviewLoaded() {
				this.videoPreviewLoading = false
			},
		},
	}
</script>
<style lang="scss" scoped>
	@import 'cropperjs/dist/cropper.css';
	.user-img-drop-zone {
		.common-file-upload-user {
			position: relative !important;
		}
	}

	.add-image-button {
		font-weight: 500;
		position: relative;
		i {
			font-size: 22px;
		}
		input {
			min-height: auto;
		}
	}
	.common-file-drop-zone-wrapper {
		.row {
			margin: 0;
		}
		&.col-md {
			padding-left: 0;
			padding-right: 0;
		}

		.common-file-drop-zone {
			display: grid;
			grid-template-columns: 100fr;
			width: 100%;
			grid-column-gap: 8px;
			border: none;
			border-radius: 8px;
			background-color: var(--stan-gray-light-color);
			position: relative;
			.common-file-preview {
				z-index: 1;
				display: flex;
				margin: 15px;
				overflow: visible;
				align-items: center;
				justify-content: center;
				background: var(--stan-gray-soft-color);
				aspect-ratio: 1;
				position: relative;
				border-radius: 8px;
				.preview-image {
					border-radius: 8px;
					overflow: hidden;
				}

				.close-btn {
					align-items: center;
					justify-content: center;
					position: absolute;
					right: -4px;
					top: -2.5px;
					background: var(--stan-primary-primary-color);
					color: var(--stan-white);
					width: 18px;
					height: 18px;
					border-radius: 50%;
					text-align: center;
					display: flex;
					svg::v-deep {
						width: 12px;
						height: 12px;
						cursor: pointer;
						path,
						rect:not(.no-fill) {
							fill: var(--stan-white);
						}
					}
				}

				.file-preview-overlay {
					cursor: pointer;
					position: absolute;
					width: 100%;
					height: 100%;
					opacity: 0%;
					display: flex;
					border-radius: 8px;
					background: var(--stan-text-dark-color);
					align-items: center;
					justify-content: center;
					transition: 0.2s ease;

					.overlay-centered-content::v-deep {
						svg path {
							fill: var(--stan-white);
							stroke: var(--stan-white);
						}
					}

					&:hover {
						transition: 0.3s ease;
						opacity: 50%;
					}
				}
			}
			.common-file-upload {
				width: 100%;
				position: absolute;
				@media (max-width: 767px) {
					position: relative;
				}
			}
			.file-drop-zone {
				text-align: center;
				color: var(--stan-text-dark-color);
				padding: 22px;
				padding-right: 0;
				.placeholder-value {
					.acceptable-dimension {
						color: var(--stan-gray-dark-color);
					}
					padding-bottom: 10px;
				}
				.upload-btn {
					height: 30px;
					border-color: var(--stan-primary-primary-color);
					font-size: 12px;
					font-weight: 600;
					line-height: 16px;
					color: var(--stan-primary-primary-color);
					padding: 2px 6px;
					i {
						font-weight: bold;
						font-size: 24px;
					}
					&.big-button {
						box-sizing: border-box;
						font-weight: bold;
						font-size: 16px;
						line-height: 22px;
						text-align: center;
						letter-spacing: 0.04em;
						border-radius: 8px;
						@media (min-width: 340px) {
							padding: 8px 16px;
							height: 40px;
						}
						@media (min-width: 576px) {
							border-radius: 13px;
							width: 121px;
							height: 43px;
						}
					}
				}
			}
		}
		&.new {
			.common-file-drop-zone {
				background-color: var(--stan-white);
				border: 1px dashed var(--stan-gray-strike-color);
			}
		}
		&.compact {
			.common-file-drop-zone {
				grid-template-columns: 110px;
				border: none;
				.file-drop-zone {
					padding-left: 0;
					.placeholder-value {
						display: none !important;
					}
				}
				.common-file-preview {
					margin: 0 5px 0 0;
				}
			}
			.file-list {
				display: none;
			}
		}
		@media (min-width: 400px) {
			&:not(.compact) {
				.common-file-drop-zone {
					&.grid {
						grid-template-columns: 123px auto;
					}
					min-height: 120px;

					.common-file-preview {
						@media (min-width: 400px) {
							aspect-ratio: unset;
						}
					}
				}
			}
		}
		.blue-circle {
			align-items: center;
			justify-content: center;
			background: var(--stan-primary-primary-color);
			width: 46px;
			height: 46px;
			text-align: center;
			color: var(--stan-white);
			border-radius: 50%;
			position: absolute;
			left: 50%;
			margin-left: -23px;
			top: 50%;
			display: flex;
			margin-top: -23px;
		}
		.down-arrow {
			margin-top: -23px;
		}
		.dropZone-over {
			background: var(--stan-gray-soft-color);
			position: relative;
			.common-file-preview {
				background: var(--stan-white);
			}
			.common-file-upload {
				display: none;
			}
		}
		&.customer-reviews-drop-zone {
			padding-top: 0;
			.common-file-drop-zone {
				grid-template-columns: 100px auto;
			}
			.common-file-upload {
				.col-xl-9,
				.col-md-12 {
					padding: 0 5px;
					max-width: 100%;
				}
			}
		}
		.spinner-border {
			width: 1.5rem;
			height: 1.5rem;

			&.video-preview-loader {
				color: var(--stan-blue-5);
			}
		}
		.load-wrapper {
			z-index: 12;
			width: 100%;
		}
		.load-wrapper:before {
			border-radius: 10px;
			background: var(--stan-white);
			content: '';
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			left: 0;
		}
	}
	.progress-bar-wrapper {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: var(--stan-primary-dark-color);
		.file-name {
			i {
				font-size: 24px;
				color: var(--stan-primary-primary-color);
			}
		}
		.circle-progress-bar {
			.text-green {
				color: var(--stan-persian-green-3);
			}
			.text-red {
				color: var(--stan-danger);
			}
			.div-hide {
				display: none;
			}
			.legend-custom-style {
				left: -45px !important;
			}
			.cursor-pointer {
				cursor: pointer;
			}
		}
	}
	.filelist-icons::v-deep {
		height: 18px;
		width: 18px;

		svg {
			transform: scale(0.75);
			path {
				fill: var(--stan-gray-dark-color, #bbbbbb);
			}
		}
	}
	.dropdown-item {
		color: var(--stan-primary-dark-color);
		font-size: 12px;
		font-weight: 600;

		&.big-button {
			font-size: 16px;
			font-weight: bold;
		}
	}
	.video-file-drop-zone {
		height: 93px;
	}
</style>
