<template>
	<div
		style="width: 100%; min-height: 62px; border-radius: 8px; display: flex; justify-content: space-between; padding: 15px; align-items: center"
		:style="{
			'background-color': uploadFailed ? 'var(--stan-negative-light-color)' : 'var(--stan-gray-soft-color)',
		}"
	>
		<!-- Status + file name -->
		<div style="display: flex; gap: 10px; align-items:center">
			<InlineSvg v-if="isUploading" src="/images/icons/ic-reload.svg" class="status-icon rotating" />
			<InlineSvg v-else-if="isErrored" src="/images/icons/ic-warning-circle-filled-negative.svg" class="status-icon" />
			<InlineSvg v-else src="/images/icons/ic-check-circle-filled-positive.svg" class="status-icon" />

			<span
				class="para-2 text-bold"
				:class="{
					'text-light': isUploading,
					'stan-text-danger': uploadFailed,
				}"
				>{{ fileName }}</span
			>

			<InlineSvg v-if="isUploading" class="delete-file-icon" src="/images/icons/ic-trash.svg" @click="deleteFile" />
		</div>

		<!-- Action buttons -->
		<div v-if="isUploading" class="para-3 text-light"><span class="text-bold">Uploading...</span> ({{ uploadProgress }}%)</div>
		<div v-else style="display: flex; gap: 10px; align-items: center">
			<span v-if="uploadFailed" class="para-3 stan-text-danger text-bold">
				Failed to upload
			</span>
			<AppButton
				v-else
				name="download-uploaded-product"
				:size="'sm'"
				:outline="true"
				icon="/images/icons/ic-digital-download.svg"
				@click="downloadFile"
			>
				Download
			</AppButton>

			<InlineSvg class="delete-file-icon" src="/images/icons/ic-trash.svg" @click="deleteFile" />
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			fileName: {
				type: String,
				default: '',
			},
			url: {
				type: String,
				default: '',
			},
			isUploading: {
				type: Boolean,
				default: true,
			},
			uploadProgress: {
				type: Number,
				default: 0,
			},
			isErrored: {
				type: Boolean,
				default: false,
			},
		},
		name: 'UploadedItemRow',
		methods: {
			deleteFile() {
				this.$emit('deleteFile')
			},
			downloadFile() {
				const link = document.createElement('a')
				link.href = this.url
				link.download = this.fileName
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			},
		},
		computed: {
			uploadFailed() {
				return !this.isUploading && this.isErrored
			},
		},
	}
</script>

<style lang="scss" scoped>
	$fadeInDuration: 0.3s;
	.delete-file-icon {
		cursor: pointer;
	}

	.loader-icon {
		height: 25px;
		width: 25px;
	}

	.rotating {
		animation: rotate 2s ease-in-out infinite;
		animation-delay: calc($fadeInDuration / 2);
	}

	@keyframes rotate {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
</style>
