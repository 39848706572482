import { mapActions, mapGetters } from 'vuex'
import commonHelper from '~/global_helper/helpers.js'

export default {
	data() {
		return {
			stanPaymentLoading: false,
			stanPaymentPopup: undefined,
		}
	},
	computed: {
		...mapGetters('Auth', ['user']),
		...mapGetters('SiteSettingsStore', ['isStripeConnected', 'isStripeMarketplaceConnected', 'isStanPaymentConnected']),
		isAllowedStanPayment() {
			return this.user.experiments?.stan_payment_service || this.user.experiments?.stan_payment_service_reboot
		},
	},

	methods: {
		...mapActions('SiteSettingsStore', ['fetchStripeConnected', 'fetchIntegrations', 'fetchStanPayment']),
		connectStripe(popupWindowClosedDelay = false) {
			const authPopupWindow = commonHelper.createCenteredPopupWin(
				'/integration-authorization?connect=stripe',
				'Connect Stripe Integration',
				500,
				800
			)
			if (popupWindowClosedDelay) localStorage.setItem('stripe-connect-started', 'True')
			if (authPopupWindow) {
				const timer = setInterval(() => {
					if (authPopupWindow.closed || (popupWindowClosedDelay && localStorage.getItem('stripe-connect-started') === 'False')) {
						this.fetchStripeConnected().then(() => {
							if (this.isStripeConnected) {
								this.$stanAnalytics('stripe-connected', {
									meta: { user_id: this.user.user_id, username: this.user.username },
								})
								this.connectStripeCompleted()
							}
							localStorage.setItem('stripe-connect-started', 'False')
						})
						clearInterval(timer)
					}
				}, 300)
			}
			return authPopupWindow
		},
		connectStripeMarketplace(popupWindowClosedDelay = false) {
			const authPopupWindow = commonHelper.createCenteredPopupWin(
				'/integration-authorization?connect=stripe/marketplace',
				'Connect Stripe Integration',
				500,
				800
			)
			if (popupWindowClosedDelay) localStorage.setItem('stripe-connect-started', 'True')

			if (authPopupWindow) {
				this.stanPaymentPopup = authPopupWindow
				window.addEventListener('message', this.handleMessage)
				const timer = setInterval(() => {
					if (authPopupWindow.closed || (popupWindowClosedDelay && localStorage.getItem('stripe-connect-started') === 'False')) {
						this.fetchIntegrations().then(() => {
							if (this.isStripeMarketplaceConnected) {
								this.$stanAnalytics('stripe-marketplace-connected', {
									meta: { user_id: this.user.user_id, username: this.user.username },
								})
							}
						})
						clearInterval(timer)
					}
				}, 300)
			}
			return authPopupWindow
		},
		connectStripeStandard(popupWindowClosedDelay = false) {
			const authPopupWindow = commonHelper.createCenteredPopupWin(
				'/integration-authorization?connect=stripe/standard',
				'Connect Stripe Integration',
				500,
				800
			)
			if (popupWindowClosedDelay) localStorage.setItem('stripe-connect-started', 'True')

			if (authPopupWindow) {
				this.stanPaymentPopup = authPopupWindow
				window.addEventListener('message', this.handleMessage)
				const timer = setInterval(() => {
					if (authPopupWindow.closed || (popupWindowClosedDelay && localStorage.getItem('stripe-connect-started') === 'False')) {
						this.fetchIntegrations().then(() => {
							if (this.isStripeMarketplaceConnected) {
								this.$stanAnalytics('stripe-standard-connected', {
									meta: { user_id: this.user.user_id, username: this.user.username },
								})
							}
						})
						clearInterval(timer)
					}
				}, 300)
			}
			return authPopupWindow
		},
		connectStripeCompleted() {},
		connectStanPayment() {
			this.stanPaymentLoading = true
			this.$axios
				.post('v1/integrations/stan-payment/generate-link')
				.then(response => {
					if (response.data.onboarding_link) {
						this.stanPaymentPopup = this.connectStanPaymentPopup(response.data.onboarding_link)
						window.addEventListener('message', this.handleMessage)
					}
				})
				.catch(err => {
					// eslint-disable-next-line no-console
					console.log(err)
				})
				.finally(() => {
					this.stanPaymentLoading = false
				})
		},
		handleMessage(event) {
			if (event.data === 'submitSuccess') {
				if (this.stanPaymentPopup) {
					this.stanPaymentPopup.close()
					window.removeEventListener('message', this.handleMessage)
					this.fetchIntegrations()
				}
			}
		},
		connectStanPaymentPopup(onboardingLink, popupWindowClosedDelay = false) {
			const authPopupWindow = commonHelper.createCenteredPopupWin(onboardingLink, 'Connect Stan Payment', 500, 800)
			if (authPopupWindow) {
				const timer = setInterval(() => {
					if (authPopupWindow.closed || (popupWindowClosedDelay && localStorage.getItem('stripe-connect-started') === 'False')) {
						// this.fetchStripeConnected()
						this.fetchStanPayment()
						clearInterval(timer)
					}
				}, 300)
			}
			return authPopupWindow
		},
	},
}
