<template>
	<AppModal
		ref="modal"
		@close="hide"
		:action="`Delete ${tableTotalCustomers} ${customerText}`"
		secondary-action="Keep them!"
		title="Are you sure?"
		@cta="submitModalForm"
		@secondary-cta="hide"
		:loading="loading"
		actionColor="danger"
		secondaryActionColor="light"
	>
		<div v-if="showOneCustomerText" class="modal-subheading para-2">
			By deleting, you'll permanently remove this user from your mailing lists and lead magnets. <b>Undo is not an option.</b>
		</div>
		<div v-else class="modal-subheading para-2">
			By deleting, you'll permanently remove these users from your mailing lists and lead magnets. <b>Undo is not an option.</b>
		</div>

		<div v-if="loading">
			<div class="importing-animation">
				<InlineSvg src="/images/icons/ic-customer.svg" />
				<InlineSvg src="/images/icons/ic-customer.svg" />
				<InlineSvg src="/images/icons/ic-customer.svg" />
			</div>
			<AppProgress :min="0" :max="totalCustomers" :value="processedCount" />
		</div>
	</AppModal>
</template>

<script>
	import { mapGetters } from 'vuex'

	export default {
		name: 'DeleteCustomersModal',
		props: {
			tableTotalCustomers: {
				type: Number,
				default: 0,
			},
			filterArray: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				loading: false,
				numberOfRowsToProcess: 1000,
				processedCount: 0,
				totalDeleted: 0,
				userFanIds: [],
			}
		},
		computed: {
			...mapGetters('Auth', ['user']),
			showOneCustomerText() {
				return this.totalCustomers === 1
			},
			totalCustomers() {
				return this.userFanIds.length
			},
			customerText() {
				return `customer${this.tableTotalCustomers > 1 ? 's' : ''}`
			},
		},
		methods: {
			show() {
				this.resetForm()
				$(this.$refs.modal.$el).modal('show')
			},
			hide() {
				this.resetForm()
				$(this.$refs.modal.$el).modal('hide')
				this.$emit('close')
			},
			async submitModalForm() {
				this.loading = true

				try {
					const response = await this.$axios.get('v1/users/fans/bulk/user_fan_ids', {
						params: {
							page: '1',
							timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
							filter_array: JSON.stringify(this.filterArray),
						},
					})
					this.userFanIds = response.data.user_fan_ids
					this.handleBatch()
				} catch (error) {
					console.error(error)
				}
			},
			async handleBatch() {
				if (this.processedCount === this.totalCustomers) {
					this.hide()
					this.loading = false

					if (this.totalCustomers === this.totalDeleted) {
						return this.$stanNotify({
							type: 'success', // can be 'info', 'warning', 'success', 'error'
							title: 'Success',
							text: `The customers have been deleted`,
							duration: 10000,
						})
					}
					return this.$stanNotify({
						type: 'warning', // can be 'info', 'warning', 'success', 'error'
						title: `${this.totalDeleted} of ${this.totalCustomers} customers deleted`,
						text: `Customers with a purchase history must be deleted manually.`,
						duration: 10000,
					})
				}
				const data = {
					user_fan_ids: this.userFanIds.slice(this.processedCount, this.processedCount + this.numberOfRowsToProcess),
				}

				try {
					const res = await this.$axios.delete(`v1/users/fans/bulk`, { data })
					this.totalDeleted += res.data.processed || 0

					this.processedCount = Math.min(this.totalCustomers, this.processedCount + this.numberOfRowsToProcess)
					this.$stanAnalytics('creator-customers-deleted', {
						meta: { user_id: this.userId, username: this.username },
						props: data,
					})
					return this.handleBatch()
				} catch (error) {
					Object.assign(this.formErrors, error.response.data)
					this.$stanNotify({
						type: 'error', // can be 'info', 'warning', 'success', 'error'
						title: this.$t('Tags could not be created, let us help you with that!'),
						text: error.response.data.message || this.$t('Please email friends@stanwith.me'),
						duration: 4000,
					})
					this.hide()
					return this.$emit('close')
				}
			},
			resetForm() {},
		},
	}
</script>

<style lang="css" scoped>
	.importing-animation {
		padding: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		svg::v-deep {
			path {
				fill: var(--stan-primary-soft-color);
			}
		}
		svg:first-child {
			width: 78px;
			height: 78px;
			transform: translateX(30px) rotate(8.88deg);
			animation: float1 4s ease-in-out infinite;
		}
		svg:nth-child(2) {
			width: 118px;
			height: 118px;
			animation: float2 6s ease-in-out infinite;
		}
		svg:last-child {
			width: 65px;
			height: 65px;
			transform: translateX(-30px) rotate(-10deg);
			animation: float3 5s ease-in-out infinite;
		}
	}
	@keyframes float1 {
		0% {
			transform: translateX(30px) rotate(8.88deg) translatey(-10px);
		}
		50% {
			transform: translateX(30px) rotate(8.88deg) translatey(0px);
		}
		100% {
			transform: translateX(30px) rotate(8.88deg) translatey(-10px);
		}
	}
	@keyframes float2 {
		0% {
			transform: translatey(0px);
		}
		50% {
			transform: translatey(-20px);
		}
		100% {
			transform: translatey(0px);
		}
	}
	@keyframes float3 {
		0% {
			transform: translateX(-30px) rotate(-10deg) translatey(0px);
		}
		50% {
			transform: translateX(-30px) rotate(-10deg) translatey(-10px);
		}
		100% {
			transform: translateX(-30px) rotate(-10deg) translatey(0px);
		}
	}
</style>
