var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("StanleyTyping", {
        attrs: { text: _vm.text },
        on: {
          doneTyping: function($event) {
            _vm.doneTyping = true
          }
        }
      }),
      _vm._v(" "),
      _vm.showExamples && _vm.doneTyping
        ? _c("StanleyExampleQuestions", { on: { userPrompt: _vm.userPrompt } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }