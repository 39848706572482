var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center justify-content-start mb-2",
          class: { "form-error": _vm.validation.stock.$error }
        },
        [
          _c("AppToggle", {
            staticClass: "mr-2",
            attrs: { size: "sm" },
            model: {
              value: _vm.value.manage,
              callback: function($$v) {
                _vm.$set(_vm.value, "manage", $$v)
              },
              expression: "value.manage"
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "para-3 mb-0 d-inline-flex align-items-center",
              attrs: { for: "inventory_stock" }
            },
            [
              _vm._v(
                "\n\t\t\t" + _vm._s(_vm.$t("Limit Quantity")) + "\n\t\t\t"
              ),
              _c(
                "AppTooltip",
                {
                  staticClass: "ml-1 d-inline-flex align-items-center",
                  attrs: {
                    click: "",
                    tip:
                      "Limit the number of products available. This value will update after each purchase. <a href='https://help.stan.store/article/43-limit-quantity-discount-codes' target='_blank'>Learn more</a>",
                    html: true
                  }
                },
                [
                  _c("InlineSvg", {
                    staticStyle: { height: "12px" },
                    attrs: { src: "./images/income/help.svg" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("AppInput", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.value.manage,
            expression: "value.manage"
          }
        ],
        class: { "form-error": _vm.validation.stock.$error },
        attrs: { type: "number", id: "inventory_stock" },
        model: {
          value: _vm.value.stock,
          callback: function($$v) {
            _vm.$set(_vm.value, "stock", _vm._n($$v))
          },
          expression: "value.stock"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }