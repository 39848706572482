<template>
	<div>
		<Banner v-if="bannerImage" :title="headingText" :image="bannerImage" :background-color="userPrimaryColor" />
		<h4 class="title">
			{{ titleText }}
		</h4>
		<ul class="products-list">
			<li v-for="(product, index) in products" :key="index" class="relative">
				<div class="amount-wrapper">
					<span class="d-flex justify-content-between align-items-center divide-bar w-100">
						<span class="product-title pr-16">{{ product.title }} :</span>
						<div class="total-am pl-2">{{ getAmountText(product.price) }}</div>
					</span>
				</div>
			</li>
			<li class="relative">
				<div class="amount-wrapper">
					<span class="d-flex justify-content-between align-items-center divide-bar w-100">
						<span class="total pr-16">Total :</span>
						<div class="total-am pl-2">{{ formattedTotal }}</div>
					</span>
				</div>
			</li>
		</ul>
		<div class="purchase-confirmation-box">
			{{ buttonText }}
		</div>
	</div>
</template>
<script>
	import Banner from './Banner'
	import { formatCurrency } from './utils'
	export default {
		name: 'Summary',
		components: { Banner },
		props: {
			currency: { type: String, default: 'USD' },
			bannerImage: { type: String, default: '' },
			heading: { type: String, default: '' },
			title: { type: String, default: '' },
			button: { type: String, default: '' },
			products: { type: Array, default: () => [] },
			userPrimaryColor: { type: String, default: '#5853ff' },
		},
		computed: {
			headingText() {
				return this.heading || 'Congratulations! Order Complete!'
			},
			titleText() {
				return this.title || 'Thank you for your purchase!'
			},
			buttonText() {
				return this.button || 'A purchase confirmation has been sent to your email.'
			},
			formattedTotal() {
				return formatCurrency(this.total, this.currency)
			},
			total() {
				return this.products.reduce((sum, product) => (sum += this.getAmount(product.price)), 0)
			},
		},
		created() {
			document.documentElement.style.setProperty('--summary-button-color', this.userPrimaryColor)
		},
		methods: {
			getAmountText(price) {
				const grossAmount = this.getAmount(price)
				return grossAmount ? formatCurrency(grossAmount, this.currency) : 'FREE'
			},
			getAmount(price) {
				return price.sale_amount_available && price.sale_amount > 0 ? price.sale_amount : price.amount
			},
		},
	}
</script>
<style lang="scss" scoped>
	.white_page_wrapper {
		padding-top: 0;
		padding-bottom: 0;
	}
	.title {
		font-size: 17px;
		font-weight: bold;
		margin-top: 36px;
		margin-bottom: 36px;
	}
	.page-header,
	.product-price {
		font-family: 'Grobek', sans-serif;
	}
	h4 {
		text-align: center;
		font-weight: 600;
	}
	.products-list {
		padding: 0 20px;
		li {
			list-style: none;
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
	}
	.amount-wrapper {
		position: relative;
		color: #000000;
		z-index: 0;

		.total-am,
		.product-title,
		.total {
			z-index: 1;
		}

		.divide-bar:before {
			content: '';
			height: 2px;
			width: 100%;
			border-bottom: 1px dashed #cad1e1;
			order: 2;
		}
		.total {
			font-weight: bold;
			order: 1;
		}
		.product-title,
		.total {
			font-family: 'Inter', sans-serif;
			font-size: 12px;
		}
		.total-am {
			font-family: 'Grobek', sans-serif;
			font-style: normal;
			font-size: 20px;
			line-height: 130%;
			order: 3;
		}
	}
	.header-footer-wrap {
		max-width: 475px;
		margin: 0 auto;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		position: fixed;
		flex-direction: column;
		justify-content: space-between;
		overflow: hidden;
		z-index: 111;
		pointer-events: none;
	}
	.bottom-toolbar {
		max-width: 100%;
		position: absolute;
		transform: translateZ(0px);
		background: #fff;
		width: 100%;
		margin: 0;
		z-index: 10;
		bottom: 0;
		pointer-events: auto;
	}
	.purchase-confirmation-box {
		font-family: 'Inter', sans-serif;
		border: 1px solid var(--summary-button-color);
		text-align: center;
		font-size: 14px;
		font-weight: bold;
		padding: 22px;
		margin: 20px;
	}
</style>
