const moduleRoutes = [
	{
		path: '/onboarding',
		name: 'onboarding',
		component: () => import('./Onboarding').then(m => m.default || m),
		meta: {
			pageTitle: 'Onboarding',
			breadcrumbs: [{ title: 'Onboarding' }],
		},
	},
]

export default moduleRoutes
