<template>
	<div class="control-funnel-page-wraper">
		<div class="d-flex flex-wrap stan-feedback my-3">
			<a class="cursor-pointer ml-auto" @click="showModalProductFeedback">{{ $t('Improve this page') }}</a>
		</div>
		<div class="d-flex flex-wrap justify-content-between mobile-center-btn-align">
			<div class="d-flex">
				<AppButton
					name="delete-page-button"
					class="mb-2"
					v-if="!isNewPage"
					color="gray"
					outline
					@click="showModalDeletePage"
					:disabled="publishing"
					data-test-id="delete-button"
					icon="/images/icons/ic-trash.svg"
				>
					{{ $t('Delete') }}
				</AppButton>
			</div>
			<div class="d-flex">
				<AppButton
					name="save-draft-button"
					class="mb-2"
					outline
					@click="$emit('save-draft')"
					icon="/images/icons/ic-save.svg"
					:disabled="isDisabled"
					data-test-id="save-draft-button"
				>
					{{ $t(saveDraftButtonText) }}
				</AppButton>
				<AppButton
					name="publish-page-button"
					class="mb-2 ml-2"
					@click="$emit('save-page')"
					:disabled="isDisabled"
					:loading="publishing"
					data-test-id="save-button"
				>
					{{ $t(saveButtonText) }}
				</AppButton>
			</div>
		</div>
		<ModalDeletePage @delete-confirm="$emit('delete-page')" @close-modal="closeDeleteModal" :type="variantToUse" />
	</div>
</template>
<script>
	import { mapActions } from 'vuex'
	import ModalDeletePage from '~/components/modals/ModalDeletePage'

	export default {
		name: 'PageControlButtons',
		components: {
			ModalDeletePage,
		},
		props: {
			status: {
				type: Number,
				default: () => -1,
			},
			publishing: {
				type: Boolean,
				default: () => false,
			},
			isNewPage: {
				type: Boolean,
				default: () => false,
			},
			ready: {
				type: Boolean,
				default: () => false,
			},
			saveButtonText: {
				type: String,
				default: 'Publish',
			},
			saveDraftButtonText: {
				type: String,
				default: 'Save As Draft',
			},
			variantToUse: {
				type: String,
				default: 'default',
			},
		},
		computed: {
			isDisabled() {
				return !this.ready || this.publishing
			},
		},
		data() {
			return {}
		},
		methods: {
			...mapActions('SiteSettingsStore', ['updateProductFeedbackArea']),
			showModalDeletePage() {
				$('#modal-delete-page').modal('show')
			},
			showModalProductFeedback() {
				this.updateProductFeedbackArea('product-edit-page')
				$('#modal-product-feedback').modal('show')
			},
			closeDeleteModal() {},
		},
	}
</script>
<style lang="scss" scoped>
	.control-funnel-page-wraper {
		border-top: 1px solid rgba(0, 0, 0, 0.25);
		padding: 20px 15px 15px 15px;
		width: 100%;
	}
	.outline__button_blue {
		border: 1px solid var(--stan-primary-primary-color) !important;
		color: var(--stan-primary-primary-color) !important;
		background: transparent !important;
	}
	.disabled-content {
		pointer-events: none;
		opacity: 0.6;
	}
	.outline__button_blue {
		border: 1px solid var(--stan-primary-primary-color);
		color: var(--stan-primary-primary-color);
	}
	@media (max-width: 575px) {
		.btn-margin-top {
			margin-top: 10px;
		}
		.mobile-center-btn-align {
			text-align: center;
		}
		.after__450 button {
			margin: 0 !important;
			width: 100%;
			margin-bottom: 10px !important;
		}
		.mobile-center-btn-align {
			.d-flex {
				gap: 10px;
				justify-content: space-between;
				width: 100%;
			}
		}
		.btn-margin-top {
			margin-top: 0px;
		}
	}
	.stan-feedback {
		a {
			text-align: right;
			color: var(--stan-blue-6);
		}
		font-style: italic;
		font-size: 13px;
	}
</style>
