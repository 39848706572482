var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      ref: "imageUploadOptionsModal",
      attrs: {
        fade: false,
        title: _vm.$t("Choose an Image"),
        ariaLabelledby: "modal-image-upload-options"
      },
      on: { close: _vm.closeModal }
    },
    [
      _c(
        "div",
        { staticClass: "mx-3 mb-3" },
        [
          _c(
            "AppButton",
            {
              staticClass: "w-100 my-2",
              attrs: { name: "unsplash-image-upload-option-modal" },
              on: {
                click: function($event) {
                  return _vm.selectOption("unsplash")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("Unsplash")))]
          ),
          _vm._v(" "),
          _c(
            "AppButton",
            {
              staticClass: "w-100 my-2",
              attrs: {
                name: "manual-upload-image-upload-option-modal",
                outline: ""
              },
              on: {
                click: function($event) {
                  return _vm.selectOption("upload")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("Upload")))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }