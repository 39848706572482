/* eslint-disable quotes */
export default {
	'Health & Wellness': {
		goLiveProducts: {
			1: {
				type: 'fulfillment',
				displayedType: 'Custom Client Request',
				image: 'https://assets.stanwith.me/graphics/random/personalizedFitnessPlan.jpg',
				title: 'Personalized Fitness Plans',
				pageData: {
					button: {
						heading: 'Personalized Wellness Audit',
						tagline: 'Review your lifestyle and habits and purge those holding you back.',
						title: 'Request Now',
						image: 'https://assets.stanwith.me/graphics/random/personalizedFitnessPlan.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/personalizedFitnessPlan.jpg',
						title: 'Personalized Wellness Audit',
						description:
							'<p>We start each program with an inventory of your current habits, in the hope of identifying the best and healthiest that will enable your personal development.</p><ul><li><p>Regular Exercise</p></li><li><p>Daily Meditation</p></li><li><p>Interpersonal Connection</p></li></ul>',
					},
				},
			},
			2: {
				type: 'meeting',
				displayedType: 'Calendar Booking',
				image: 'https://assets.stanwith.me/graphics/random/OneonOneTraining.png',
				title: 'Virtual Coaching Sessions',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/1-1-training-session.jpeg',
						heading: '1:1 Training Session',
						tagline: 'Book a private training session with me, on your schedule!',
						title: 'Book Now',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/1-1-training-session.jpeg',
						description:
							"<p>Each training session is focused on identifying and achieving your goals.<br><br>We will create an outline and a process for meeting your definition of success.</p><p>In this 1:1 Training Session, I will personally help you by:</p><ul><li><p>Giving you specific advice on your unique situation</p></li><li><p>Building a plan to reach your goals</p></li><li><p>Walking you through all of your questions</p></li></ul><p><strong>This is for you if you're looking to:</strong></p><ul><li><p>Achieve your Fitness Dreams</p></li><li><p>Have more Energy</p></li><li><p>Get to your Target Weight</p></li></ul>",
						title: '1:1 Training Session',
					},
					submit_title: 'BOOK A CALL',
				},
			},
			3: {
				type: 'fulfillment',
				displayedType: 'Custom Client Request',
				image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
				title: '"Ask Me Anything" About Fitness',
				pageData: {
					button: {
						heading: 'Ask Me Anything',
						tagline: 'Get a personalized video response from me answering your question!',
						title: 'Ask Me Anything',
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
						title: 'Ask Me Anything',
						description: '<p>I’ll send you a personalized video response answering your question to help you achieve your goals!</p>',
						price: {
							amount: 9,
						},
					},
				},
			},
		},
		otherRecommendedProducts: {
			1: {
				type: 'lead-magnet',
				displayedType: 'Freebie / Lead Magnet',
				image: 'https://assets.stanwith.me/graphics/random/health_wellness_lead_magnet.jpeg',
				title: 'Join Your Email List',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/health_wellness_lead_magnet.jpeg',
						heading: 'Get weekly fitness tips in your inbox!',
						tagline: 'Join my newsletter and never miss an update from me!',
						title: 'SUBMIT',
					},
				},
			},
			2: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/diet-food.jpg',
				title: 'Cook Book or Recipe eBook',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/diet-food.jpg',
						heading: 'Download My Cookbook',
						tagline: 'Filled with my FAVORITE recipes that are yummy and will still help you reach your goals',
						title: 'Download Now',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/diet-food.jpg',
						title: 'Download My Cookbook',
						description:
							'<p>This Cookbook [PDF, Video File, URL to a private playlist or document] is filled with 100’s of yummy meals that you can cook every single day.</p><p><strong>This cookbook is for you if:</strong></p><ul><li><p>You’re tired of cooking the same ole’ boring "healthy" meals</p></li><li><p>You have limited time but don’t want to sacrifice nutrition</p></li><li><p>You’re feeling stuck on hitting your weight goals</p></li><li><p>You just need to switch things up!</p></li></ul>',
					},
					bottom_title: 'Start cookin’ today!',
					submit_title: 'PURCHASE',
				},
			},
			3: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/health_wellness_workout_guide.jpeg',
				title: 'Workout Guide',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/health_wellness_workout_guide.jpeg',
						heading: 'Download My Fitness Guide',
						tagline: 'This is the routine I follow every day that will help you reach your goals',
						title: 'Download Now',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/health_wellness_workout_guide.jpeg',
						title: 'Get My Fitness Guide',
						description:
							'<p>This Guide [PDF, Video File, URL to a private playlist or document] will teach you everything you need to achieve your goals.</p><p><strong>This is for you if you’re looking to:</strong></p><ul><li><p>Achieve your Fitness Dreams</p></li><li><p>Have more Energy</p></li><li><p>Get to your Target Weight</p></li></ul>',
					},
					bottom_title: 'Get My Guide',
					submit_title: 'PURCHASE',
				},
			},
			4: {
				type: 'course',
				displayedType: 'eCourse',
				image: 'https://assets.stanwith.me/graphics/random/health_wellness_eCourse.jpg',
				title: 'My 12 Week Workout Program',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/health_wellness_eCourse.jpg',
						heading: 'Join the 12 Wk Program Today',
						tagline: 'Start Your 12 Wk Transformation today with this 12 wk program',
						title: 'Join Now',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/health_wellness_eCourse.jpg',
						title: 'Join the 12 Wk Program Today',
						description:
							'<p>This Program [PDF, Video File, URL to a private playlist or document] will teach you everything you need to achieve your goals.</p><p><strong>This is for you if you’re looking to:</strong></p><ul><li><p>Achieve your Fitness Dreams</p></li><li><p>Have more Energy</p></li><li><p>Get to your Target Weight</p></li></ul>',
					},
					bottom_title: 'Join the Program',
					submit_title: 'PURCHASE',
				},
			},
		},
	},
	'Finance & Real Estate': {
		goLiveProducts: {
			1: {
				type: 'lead-magnet',
				displayedType: 'Freebie / Lead Magnet',
				image: 'https://assets.stanwith.me/graphics/random/finance_realEstate_leadMagnet.jpg',
				title: 'Free "How to Start Investing" E-book',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/finance_realEstate_leadMagnet.jpg',
						heading: 'Get my top 10 tips on how to start investing!',
						tagline: 'Get my tips and never miss a trend or opportunity!',
						title: 'Download',
					},
				},
			},
			2: {
				type: 'meeting',
				displayedType: 'Calendar Booking',
				image: 'https://assets.stanwith.me/graphics/device/meeting.png',
				title: 'Financial Coaching',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
						heading: 'Financial Coaching',
						tagline: 'Book a 1:1 call with me! (30 min)',
						title: 'Book Today',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
						description:
							"<p>I am here to help you achieve your goals.</p><p>On this 1:1 Call, I will personally help you by:</p><ul><li><p>Giving you specific advice to your situation</p></li><li><p>Building a plan to reach your goals</p></li><li><p>Walking you through all of your questions</p></li></ul><p><strong>This is for you if you're looking to:</strong></p><ul><li><p>Achieve Financial Freedom</p></li><li><p>Plan for Retirement</p></li><li><p>Build an Investment Nest Egg</p></li></ul>",
						title: 'Book a 1:1 call with me! (30 min)',
					},
					bottom_title: 'Work 1:1 With Me',
					submit_title: 'SCHEDULE',
				},
			},
			3: {
				type: 'fulfillment',
				displayedType: 'Custom Client Request',
				image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
				title: '"Ask Me Anything" Video Request',
				pageData: {
					button: {
						heading: 'Ask Me Anything',
						tagline: 'Get a personalized video response from me answering your question!',
						title: 'Ask Me Anything',
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
						title: 'Ask Me Anything',
						description: '<p>I’ll send you a personalized video response answering your question to help you achieve your goals!</p>',
						price: {
							amount: 9,
						},
					},
				},
			},
		},
		otherRecommendedProducts: {
			1: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/finance_realEstate_budgeting.jpg',
				title: 'Rental Property Budgeting Template',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/finance_realEstate_budgeting.jpg',
						heading: 'Rental Property Budgeting Template',
						tagline: 'Want to buy a rental property but have no idea how to save towards one? This template is for you!',
						title: 'Download',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/finance_realEstate_budgeting.jpg',
						title: 'Rental Purchase Budgeting Template',
						description:
							'<p>Are you wanting to get into real estate investing but have no idea how to save for it? Especially in this type of economy? Well I got you covered!<br><br>I was in your shoes just under 5 years ago and with my proven budgeting plans, I now own over 5 rental properties making me $___ in passive income each month.<br><br>With this budgeting template you’ll enter in your current income, any debt/ loans, your current biggest spends & what your short term & long term financial goals are. From there, you can calculate a plan based on the average house pricing, your time-length and more.<br><br><strong>This is for you if you’re looking to:</strong></p><ul><li><p>Buy a rental property or home for yourself but have no idea how to save for it</p></li><li><p>Feel in control of your finances, even in a rocky economy</p></li><li><p>Get on top of your spending & have a plan for success!</p></li></ul>',
					},
					bottom_title: 'Prepare For Your First Property!',
				},
			},
			2: {
				type: 'fulfillment',
				displayedType: 'Custom Client Request',
				image: 'https://assets.stanwith.me/graphics/random/personal_budgeting.jpg',
				title: 'Personalized Budgeting Plan',
				pageData: {
					button: {
						heading: 'Personalized Budgeting Plan',
						tagline: 'I’ll send you a custom budgeting plan built just for your financial situation & goals!',
						title: 'Get Yours Today',
						image: 'https://assets.stanwith.me/graphics/random/personal_budgeting.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/personal_budgeting.jpg',
						title: 'Personalized Budgeting Plan',
						description:
							"<p>I will personally review your current financial situation and help you build a plan for success. Submit your current income, any debt/ loans, your current biggest spends & what your short term & long term financial goals are. From there, I’ll assess your situation and send over a custom budgeting excel sheet for you to fill out each month.</p><p><strong>This is for you if you're looking to:</strong></p><ul><li><p>Achieve your financial goals but are getting stuck</p></li><li><p>Have more financial freedom</p></li><li><p>Get on top of your spending & have a plan</p></li></ul><p>To properly review your situation, I may require a follow-up call or email to clarify any of your form submission. Please allow up to 7 business days for your custom budgeting plan to arrive.</p>",
					},
					bottom_title: 'Get Your Finances On Track',
				},
			},
		},
	},
	'Social Media & Marketing': {
		goLiveProducts: {
			1: {
				type: 'meeting',
				displayedType: 'Calendar Booking',
				image: 'https://assets.stanwith.me/graphics/device/meeting.png',
				title: '1:1 Coaching Call',
				pageData: {
					button: {
						heading: '1:1 Coaching Call',
						tagline: 'Book a 1 hr coaching session with me',
						title: 'Book Today',
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
						title: 'Book a 1:1 Coaching Call',
						description:
							'<p>I’m here to help you achieve your social media goals through a 1:1 session where we will discuss your ultimate objective and figure out a plan that works for you and your current situation.</p><p>On this 1:1 Call, I will personally help you by:</p><ul><li><p>Giving you specific recommendations based on where you’re at in your journey</p></li><li><p>Help you build a plan to reach your goals</p></li><li><p>Walk you through all of your questions</p></li></ul><p><strong>This is for you if you’re looking to:</strong></p><ul><li><p>Grow your following</p></li><li><p>Create a strong social presence</p></li><li><p>Learn how to get brand deals</p></li></ul>',
						meeting: {
							duration_minutes: 60,
						},
					},
					bottom_title: 'Work 1:1 With Me',
					submit_title: 'SCHEDULE',
				},
			},
			2: {
				type: 'fulfillment',
				displayedType: 'Custom Client Request',
				image: 'https://assets.stanwith.me/graphics/random/SocialMediaAudit.jpg',
				title: 'Social Media Profile Audit',
				pageData: {
					button: {
						heading: 'TikTok Audit',
						tagline: 'I’ll send you a personalized video reviewing your TikTok profile',
						title: 'Get Your Audit',
						image: 'https://assets.stanwith.me/graphics/random/SocialMediaAudit.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/SocialMediaAudit.jpg',
						title: 'TikTok Audit',
						description:
							'<p>I will personally review your TikTok profile and help you achieve your goals!</p><p><strong>This is for you if you’re looking to:</strong></p><ul><li><p>Grow Your Following</p></li><li><p>Get Viral Videos</p></li><li><p>Monetize your Social Media</p></li></ul>',
					},
					bottom_title: 'Get Your Audit',
				},
			},
			3: {
				type: 'fulfillment',
				displayedType: 'Custom Client Request',
				image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
				title: '"Ask Me Anything" Video Request',
				pageData: {
					button: {
						heading: 'Ask Me Anything',
						tagline: 'Get a personalized video response from me answering your question!',
						title: 'Ask Me Anything',
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
						title: 'Ask Me Anything',
						description: '<p>I’ll send you a personalized video response answering your question to help you achieve your goals!</p>',
						price: {
							amount: 9,
						},
					},
				},
			},
		},
		otherRecommendedProducts: {
			1: {
				type: 'lead-magnet',
				displayedType: 'Freebie / Lead Magnet',
				image: 'https://assets.stanwith.me/graphics/random/grow_followers.jpg',
				title: 'Free "How to Grow Your Following" Guide',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/grow_followers.jpg',
						heading: 'Get my top 10 tips on how to grow your following!',
						tagline: 'Get my guide and never miss a trend or algo tip!',
						title: 'JOIN',
					},
				},
			},
			2: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/build_your_brand.jpg',
				title: 'How To Build Your Brand Toolkit',
				pageData: {
					button: {
						heading: 'Download My Branding Toolkit',
						tagline: 'This toolkit is designed to help YOU build your brand from scratch',
						title: 'Download Now',
						image: 'https://assets.stanwith.me/graphics/random/build_your_brand.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/build_your_brand.jpg',
						title: 'Download My Brand ToolKit',
						description:
							'<p>This Toolkit [PDF, Video File, URL to a private playlist or document] is not just like any other branding guide, ebook, or worksheet out there. Whether you’re starting your business from scratch or already have an existing brand but need a refresh, or you’re somewhere inbetween - this toolkit will help you develop a brand that is true, authentic and represents you in a unique and memorable way.</p><p>This toolkit is for you if:</p><ul><li><p>You need a brand but have no idea where to start</p></li><li><p>You have branding but it’s just not cohesive or working anymore</p></li><li><p>You feel like your brand isn’t actually representative of what your business does</p></li><li><p>You’re looking to learn how you can embody your brand throughout your channels</p></li><li><p>You’re just looking for a facelift!</p></li></ul>',
					},
					bottom_title: 'Start your brand journey today!',
					submit_title: 'PURCHASE',
				},
			},
			3: {
				type: 'course',
				displayedType: 'eCourse',
				image: 'https://assets.stanwith.me/graphics/random/online_course_socialMedia.jpg',
				title: 'How to Market Yourself Course',
				pageData: {
					button: {
						heading: 'My Brand Bootcamp',
						tagline: 'Build your Brand on Social Media with my Brand Bootcamp course!',
						title: 'Sign Up Today',
						image: 'https://assets.stanwith.me/graphics/random/online_course_socialMedia.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/online_course_socialMedia.jpg',
						title: 'Bootcamp for your Social Media Brand',
						description:
							'<p>This Bootcamp [PDF, Video File, URL to a private playlist or document] will teach you everything you need to achieve your goals on social media.</p><p>This bootcamp will include lessons on:</p><ul><li><p>Identifying & solidifying your brand</p></li><li><p>Building a business that embodies your brand</p></li><li><p>Ways to break through the noise on social media</p></li></ul><p><strong>This is for you if:</strong></p><ul><li><p>You want to build a brand from scratch (new biz owners)</p></li><li><p>You have a brand but need help bringing it to social media</p></li><li><p>Your brand needs a refresher</p></li><li><p>Or you’re just looking to refine/ stay knowledgeable in branding</p></li></ul>',
					},
					bottom_title: 'Join the Bootcamp',
					submit_title: 'SIGN UP',
				},
			},
			4: {
				type: 'membership',
				displayedType: 'Recurring Membership',
				image: 'https://assets.stanwith.me/graphics/random/social_membership.jpg',
				title: 'Full Access Membership',
				pageData: {
					button: {
						heading: 'Full Access Membership',
						tagline: 'Join my membership to get full-access to me while you’re building your brand',
						title: 'Join Today',
						image: 'https://assets.stanwith.me/graphics/random/social_membership.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/social_membership.jpg',
						title: 'Join My Membership',
						description:
							'<p>Want weekly access to me and other like-minded individuals to help you reach your social media goals?</p><p><strong>Join my monthly membership and you will get:</strong></p><ul><li><p>Exclusive Access to our Group Chat</p></li><li><p>Weekly Group Calls with Me</p></li><li><p>Daily Tips to help you achieve your goals</p></li></ul><p><strong>This is for you if you’re:</strong></p><ul><li><p>Looking for accountability</p></li><li><p>Looking for help growing your social media</p></li><li><p>Driving more sales for your business</p></li></ul>',
					},
					bottom_title: 'Join My Membership',
					submit_title: 'Subscribe',
				},
			},
		},
	},
	'Business Coaching': {
		goLiveProducts: {
			1: {
				type: 'meeting',
				displayedType: 'Calendar Booking',
				image: 'https://assets.stanwith.me/graphics/device/meeting.png',
				title: '1:1 Coaching Call',
				pageData: {
					button: {
						heading: '1:1 Coaching Call',
						tagline: 'I will personally coach you to help you reach your goals!',
						title: 'Book a 1:1 Session',
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
						title: '1:1 Coaching Call',
						description:
							'<p>I am here to help you achieve your goals.</p><p>On this 1:1 Video Call, I will personally help you:</p><ul><li><p>Give you specific advice to your situation</p></li><li><p>Build a plan to reach your goals</p></li><li><p>Walk you through all of your questions</p></li></ul>',
						price: {
							amount: 39,
						},
					},
					bottom_title: 'Work 1:1 With Me',
					submit_title: 'Book a Session',
				},
			},
			2: {
				type: 'fulfillment',
				displayedType: 'Custom Client Request',
				image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
				title: '"Ask Me Anything" Request',
				pageData: {
					button: {
						heading: 'Ask Me Anything',
						tagline: 'Get a personalized video response from me answering your question!',
						title: 'Ask Me Anything',
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
						title: 'Ask Me Anything',
						description: '<p>I’ll send you a personalized video response answering your question to help you achieve your goals!</p>',
						price: {
							amount: 9,
						},
					},
					submit_title: 'Purchase',
				},
			},
		},
		otherRecommendedProducts: {
			1: {
				type: 'lead-magnet',
				displayedType: 'Freebie / Lead Magnet',
				image: 'https://assets.stanwith.me/graphics/random/free_eBook_Guide_general.jpg',
				title: 'Free eBook or Guide',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/free_eBook_Guide_general.jpg',
						heading: 'Download my FREE Guide',
						tagline: 'Don’t miss out on my tips to help you reach your goals!',
						title: 'Download',
					},
				},
			},
			2: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/templates_toolkits.jpg',
				title: 'Templates, Toolkits, or Processes You Use for Your Own Business',
				pageData: {
					button: {
						heading: 'Get My Productivity Template',
						tagline: 'I use this template everyday to be more efficient in my own business process!',
						title: 'Download',
						image: 'https://assets.stanwith.me/graphics/random/templates_toolkits.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/templates_toolkits.jpg',
						title: 'Get My Productivity Template',
						description:
							'<p>Need help streamlining your own process? Struggling to find the time to keep up with all your different workstreams?</p><p>I built this template to save you time, money and energy. I use this template everyday, and it’s helped me save <strong>hours every day</strong> in managing my team, tracking my to-do’s, and setting goals.</p><p><strong>This template will help you:</strong></p><ul><li><p>Track your To-Do’s all in one place</p></li><li><p>Delegate tasks and keep track of who’s doing what</p></li><li><p>Make your process more efficient and automated</p></li></ul>',
						price: {
							amount: 9,
						},
					},
					bottom_title: 'Get My Template',
					submit_title: 'Download',
				},
			},
			3: {
				type: 'course',
				displayedType: 'eCourse',
				image: 'https://assets.stanwith.me/graphics/random/eCourse_101.jpg',
				title: 'A "How to" Course',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/eCourse_101.jpg',
						heading: 'My 101 Course',
						tagline: 'This course will teach you everything you need to know to achieve your goals!',
						title: 'Get My Course',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/eCourse_101.jpg',
						title: 'My 101 Course',
						description:
							'<p>Need help getting your business off the ground? Not sure how to get your first clients?</p><p><strong>This course will teach you everything you need to know to:</strong></p><ul><li><p>Get your business off the ground</p></li><li><p>Convert your first clients</p></li><li><p>Build the business of your dreams</p></li></ul><p>Course Curriculum:</p><ul><li><p>Lesson 1: How to Find a Market Niche</p></li><li><p>Lesson 2: How to Create a Product for Your Niche</p></li><li><p>Lesson 3: How to Find Your First Clients</p></li><li><p>Lesson 4: How to Scale Your Business</p></li></ul>',
						price: {
							amount: 79,
						},
					},
					bottom_title: 'Join the Program',
					submit_title: 'PURCHASE',
				},
			},
			4: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/how_to_guide_101.jpg',
				title: 'A "How to" Guide',
				pageData: {
					button: {
						heading: 'My 101 Guide',
						tagline: 'This guide will give you a step-by-step to achieve your goals!',
						title: 'Get My Guide',
						image: 'https://assets.stanwith.me/graphics/random/how_to_guide_101.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/how_to_guide_101.jpg',
						title: 'My 101 Guide',
						description:
							'<p>Need help getting your business off the ground? Not sure how to get your first clients?</p><p>This guide will give you a step-by-step to:</p><ul><li><p>Get your business off the ground</p></li><li><p>Convert your first clients</p></li><li><p>Build the business of your dreams</p></li></ul>',
						price: {
							amount: 29,
						},
					},
				},
			},
		},
	},
	'Career & Productivity': {
		goLiveProducts: {
			1: {
				type: 'fulfillment',
				displayedType: 'Custom Client Request',
				image: 'https://assets.stanwith.me/graphics/random/career_productivity_resumeReview.png',
				title: 'Resume / LinkedIn Profile Review',
				pageData: {
					button: {
						heading: 'Resume / Profile Review',
						tagline: 'I will personally review your profile and give you feedback on how to improve it to get hired!',
						title: 'I’ll Review Your Resume',
						image: 'https://assets.stanwith.me/graphics/random/career_productivity_resumeReview.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/career_productivity_resumeReview.png',
						title: 'Resume / Profile Review',
						description:
							'<p>Need help getting the job of your dreams?</p><p>Struggling to make sure your resume is optimized to actually get an interview?</p><p>I will personally audit your resume and give you feedback on:</p><ul><li><p>How to improve your language</p></li><li><p>How to maximize your chance of success</p></li><li><p>How to improve your attractiveness as a candidate</p></li></ul>',
						price: {
							amount: 49,
						},
					},
					submit_title: 'Purchase',
				},
			},
			2: {
				type: 'meeting',
				displayedType: 'Calendar Booking',
				image: 'https://assets.stanwith.me/graphics/device/meeting.png',
				title: '1:1 Coaching',
				pageData: {
					button: {
						heading: '1:1 Coaching',
						tagline: 'I will personally coach you to help you reach your goals!',
						title: 'Book a 1:1 Session',
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
						title: '1:1 Coaching',
						description:
							'<p>I am here to help you achieve your goals.</p><p>On this 1:1 Call, I will personally help you:</p><ul><li><p>Give you specific advice to your situation</p></li><li><p>Build a plan to reach your goals</p></li><li><p>Walk you through all of your questions</p></li></ul>',
						price: {
							amount: 39,
						},
					},
					bottom_title: 'Work 1:1 With Me',
					submit_title: 'Book a Session',
				},
			},
			3: {
				type: 'fulfillment',
				displayedType: 'Custom Client Request',
				image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
				title: '"Ask Me Anything" Request',
				pageData: {
					button: {
						heading: 'Ask Me Anything',
						tagline: 'Get a personalized video response from me answering your question!',
						title: 'Ask Me Anything',
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
						title: 'Ask Me Anything',
						description: '<p>I’ll send you a personalized video response answering your question to help you achieve your goals!</p>',
						price: {
							amount: 9,
						},
					},
					submit_title: 'Purchase',
				},
			},
		},
		otherRecommendedProducts: {
			1: {
				type: 'lead-magnet',
				displayedType: 'Freebie / Lead Magnet',
				image: 'https://assets.stanwith.me/graphics/random/free_eBook_Guide_general.jpg',
				title: 'Free eBook or Guide',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/free_eBook_Guide_general.jpg',
						heading: 'Download my FREE Guide',
						tagline: 'Don’t miss out on my tips to help you reach your goals!',
						title: 'Download',
					},
				},
			},
			2: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/templates_toolkits.jpg',
				title: 'Templates, Toolkits, or Processes You Use for Your Own Business',
				pageData: {
					button: {
						heading: 'Get My Productivity Template',
						tagline: 'I use this template everyday to be more efficient in my own business process!',
						title: 'Download',
						image: 'https://assets.stanwith.me/graphics/random/templates_toolkits.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/templates_toolkits.jpg',
						title: 'Get My Productivity Template',
						description:
							'<p>Need help streamlining your own process?</p><p>Struggling to find the time to keep up with all your different workstreams?</p><p>I built this template to save you time, money and energy. I use this template everyday, and it’s helped me save <strong>hours every day</strong> in managing my team, tracking my to-do’s, and setting goals.<p>This template will help you:</p><ul><li><p>Track your To-Do’s all in one place</p></li><li><p>Delegate tasks and keep track of who’s doing what</p></li><li><p>Make your process more efficient and automated</p></li></ul>',
						price: {
							amount: 9,
						},
					},
					bottom_title: 'Get My Template',
					submit_title: 'Download',
				},
			},
			3: {
				type: 'course',
				displayedType: 'eCourse',
				image: 'https://assets.stanwith.me/graphics/random/eCourse_101.jpg',
				title: 'A "How to" Course',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/eCourse_101.jpg',
						heading: 'My 101 Course',
						tagline: 'This course will teach you everything you need to know to achieve your goals!',
						title: 'Get My Course',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/eCourse_101.jpg',
						title: 'My 101 Course',
						description:
							'<p>Need help getting your first job? Not sure how to get your foot in the door?</p><p><strong>This course will teach you step-by-step how to:</strong></p><ul><li><p>Build a great portfolio and resume</p></li><li><p>Nail your interviews</p></li><li><p>Get your dream job</p></li></ul><p>Course Curriculum:</p><ul><li><p>Lesson 1: How to Find Your Dream Role</p></li><li><p>Lesson 2: How to Differentiate Yourself as a Candidate</p></li><li><p>Lesson 3: How to Cold Email</p></li><li><p>Lesson 4: How to Interview</p></li><li><p>Lesson 5: Job Offer Basics</p></li></ul>',
						price: {
							amount: 79,
						},
					},
					bottom_title: 'Join the Program',
					submit_title: 'PURCHASE',
				},
			},
			4: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/how_to_guide_101.jpg',
				title: 'A "How to" Guide',
				pageData: {
					button: {
						heading: 'My 101 Guide',
						tagline: 'This guide will give you a step-by-step to achieve your goals!',
						title: 'Get My Guide',
						image: 'https://assets.stanwith.me/graphics/random/how_to_guide_101.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/how_to_guide_101.jpg',
						title: 'My 101 Guide',
						description:
							'<p>Need help getting your first job? Not sure how to get your foot in the door?</p><p><strong>This guide will teach you step-by-step how to:</strong></p><ul><li><p>Build a great portfolio and resume</p></li><li><p>Nail your interviews</p></li><li><p>Get your dream job</p></li>',
						price: {
							amount: 29,
						},
					},
					bottom_title: 'Get My Guide',
					submit_title: 'PURCHASE',
				},
			},
		},
	},
	Education: {
		goLiveProducts: {
			1: {
				type: 'meeting',
				displayedType: 'Calendar Booking',
				image: 'https://assets.stanwith.me/graphics/device/meeting.png',
				title: '1:1 Coaching',
				pageData: {
					button: {
						heading: '1:1 Coaching',
						tagline: 'I will personally coach you to help you reach your goals!',
						title: 'Book a 1:1 Session',
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
						title: '1:1 Coaching',
						description:
							'<p>I am here to help you achieve your goals.</p><p>On this 1:1 Call, I will personally help you:</p><ul><li><p>Give you specific advice to your situation</p></li><li><p>Build a plan to reach your goals</p></li><li><p>Walk you through all of your questions</p></li></ul>',
						price: {
							amount: 39,
						},
					},
					bottom_title: 'Work 1:1 With Me',
					submit_title: 'Book a Session',
				},
			},
			2: {
				type: 'fulfillment',
				displayedType: 'Custom Client Request',
				image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
				title: '"Ask Me Anything" Request',
				pageData: {
					button: {
						heading: 'Ask Me Anything',
						tagline: 'Get a personalized video response from me answering your question!',
						title: 'Ask Me Anything',
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
						title: 'Ask Me Anything',
						description: '<p>I’ll send you a personalized video response answering your question to help you achieve your goals!</p>',
						price: {
							amount: 9,
						},
					},
					submit_title: 'Purchase',
				},
			},
		},
		otherRecommendedProducts: {
			1: {
				type: 'lead-magnet',
				displayedType: 'Freebie / Lead Magnet',
				image: 'https://assets.stanwith.me/graphics/random/free_eBook_Guide_general.jpg',
				title: 'Free eBook or Guide',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/free_eBook_Guide_general.jpg',
						heading: 'Download my FREE Guide',
						tagline: 'Don’t miss out on my tips to help you reach your goals!',
						title: 'Download',
					},
				},
			},
			2: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/templates_toolkits.jpg',
				title: 'Templates, Toolkits, or Processes You Use for Your Own Business',
				pageData: {
					button: {
						heading: 'Get My Template',
						tagline: 'I use this template everyday to be more efficient in my own business process!',
						title: 'Download',
						image: 'https://assets.stanwith.me/graphics/random/templates_toolkits.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/templates_toolkits.jpg',
						title: 'Get My Template',
						description:
							'<p>Need help streamlining your own process?</p><p>Struggling to find the time to keep up with all your different workstreams?</p><p>I built this template to save you time, money and energy. I use this template everyday, and it’s helped me save <strong>hours every day</strong> tracking my to-do’s and setting goals.<p>This template will help you:</p><ul><li><p>Track your To-Do’s all in one place</p></li><li><p>Keep track of your progress</p></li><li><p>Make your process more efficient and automated</p></li></ul>',
						price: {
							amount: 9,
						},
					},
					bottom_title: 'Get My Guide',
					submit_title: 'Download',
				},
			},
			3: {
				type: 'course',
				displayedType: 'eCourse',
				image: 'https://assets.stanwith.me/graphics/random/eCourse_101.jpg',
				title: 'A "How to" Course',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/eCourse_101.jpg',
						heading: 'My 101 Course',
						tagline: 'This course will teach you everything you need to know to achieve your goals!',
						title: 'Get My Course',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/eCourse_101.jpg',
						title: 'My 101 Course',
						description:
							'<p>Need help getting started? Not sure where to start with your learning?</p><p><strong>This course will:</strong></p><ul><li><p>Get you started on your learning journey</p></li><li><p>Give you step-by-step guidance throughout your learning</p></li><li><p>Give you tips on being more efficient in your process</p></li></ul>',
						price: {
							amount: 79,
						},
					},
					bottom_title: 'Join the Program',
					submit_title: 'PURCHASE',
				},
			},
			4: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/how_to_guide_101.jpg',
				title: 'A "How to" Guide',
				pageData: {
					button: {
						heading: 'My 101 Guide',
						tagline: 'This guide will give you a step-by-step to achieve your goals!',
						title: 'Get My Guide',
						image: 'https://assets.stanwith.me/graphics/random/how_to_guide_101.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/how_to_guide_101.jpg',
						title: 'My 101 Guide',
						description:
							'<p>Need help getting started? Not sure where to start with your learning?</p><p><strong>This guide will:</strong></p><ul><li><p>Get you started on your learning journey</p></li><li><p>Give you step-by-step guidance throughout your learning</p></li><li><p>Give you tips on being more efficient in your process</p></li></ul>',
						price: {
							amount: 29,
						},
					},
				},
			},
		},
	},
	'Relationship & Dating': {
		goLiveProducts: {
			1: {
				type: 'meeting',
				displayedType: 'Calendar Booking',
				image: 'https://assets.stanwith.me/graphics/device/meeting.png',
				title: '1:1 Relationship Coaching',
				pageData: {
					button: {
						heading: '1:1 Coaching',
						tagline: 'I will personally help you assess your relationship and how to move forward.',
						title: 'Book a 1:1 Session',
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
						title: '1:1 Coaching',
						description:
							'<p>I am here to help you achieve your goals.</p><p>On this 1:1 Call, I will personally help you:</p><ul><li><p>Give you specific advice to your situation</p></li><li><p>Build a plan to reach your goals</p></li><li><p>Walk you through all of your questions</p></li></ul>',
						price: {
							amount: 39,
						},
					},
					bottom_title: 'Work 1:1 With Me',
					submit_title: 'Book a Session',
				},
			},
			2: {
				type: 'fulfillment',
				displayedType: 'Custom Client Request',
				image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
				title: '"Ask Me Anything" Request',
				pageData: {
					button: {
						heading: 'Ask Me Anything',
						tagline: 'Get a personalized video response from me answering your dating question!',
						title: 'Ask Me Anything',
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
						title: 'Ask Me Anything',
						description: '<p>I’ll send you a personalized video response answering your question to help you achieve your goals!</p>',
						price: {
							amount: 9,
						},
					},
					submit_title: 'Purchase',
				},
			},
		},
		otherRecommendedProducts: {
			1: {
				type: 'lead-magnet',
				displayedType: 'Freebie / Lead Magnet',
				image: 'https://assets.stanwith.me/graphics/random/free_eBook_Guide_general.jpg',
				title: 'Free eBook or Guide',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/free_eBook_Guide_general.jpg',
						heading: 'Download my FREE Guide',
						tagline: 'Don’t miss out on my tips to help you reach your goals!',
						title: 'Download',
					},
				},
			},
			2: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/relationship_dating_template_toolkit.jpg',
				title: 'Templates, Toolkits, or Processes You Use for Your Own Business',
				pageData: {
					button: {
						heading: 'Get My Dating Framework',
						tagline: 'This framework will give you a step-by-step guide to evaluating your dating life.',
						title: 'Download',
						image: 'https://assets.stanwith.me/graphics/random/relationship_dating_template_toolkit.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/relationship_dating_template_toolkit.jpg',
						title: 'Get My Dating Framework',
						description:
							'<p>Struggling to get dates with great people? Or figure out if they’re the right one for you?</p><p>This is the framework I’ve used with dozens of clients to help them build the love life of their dreams.</p><p>This framework will help you:</p><ul><li><p>Become the person you want to be</p></li><li><p>Start dating someone you deserve</p></li><li><p>Evaluate your current relationship</p></li></ul>',
						price: {
							amount: 9,
						},
					},
					bottom_title: 'Get My Guide',
					submit_title: 'Download',
				},
			},
			3: {
				type: 'course',
				displayedType: 'eCourse',
				image: 'https://assets.stanwith.me/graphics/random/relationship_dating_eCourse.png',
				title: 'A "How to" Course',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/relationship_dating_eCourse.png',
						heading: 'My Lovemaking 101 Course',
						tagline: 'This course will teach you everything you need to know to have a fulfilling romantic life!',
						title: 'Get My Course',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/relationship_dating_eCourse.png',
						title: 'My Lovemaking 101 Course',
						description:
							'<p>Need help spicing up your romantic life? Miss the honeymoon phase?</p><p><strong>This course will teach you everything you need to know to:</strong></p><ul><li><p>Build a deep connection with your partner</p></li><li><p>Bring passion back into your relationship</p></li><li><p>Spice up your life</p></li></ul>',
						price: {
							amount: 79,
						},
					},
					bottom_title: 'Join the Program',
					submit_title: 'PURCHASE',
				},
			},
			4: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/how_to_guide_101.jpg',
				title: 'A "How to" Guide',
				pageData: {
					button: {
						heading: 'My Dating 101 Guide',
						tagline: 'This guide will give you a step-by-step to achieve your dating goals!',
						title: 'Get My Guide',
						image: 'https://assets.stanwith.me/graphics/random/how_to_guide_101.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/how_to_guide_101.jpg',
						title: 'My 101 Guide',
						description:
							'<p>Need help getting more dates?</p><p>This guide will teach you everything you need to know to:</p><ul><li><p>Connect with strangers</p></li><li><p>Have awesome first dates</p></li><li><p>Find your dream partner</p></li></ul>',
						price: {
							amount: 29,
						},
					},
				},
			},
		},
	},
	'Lifestyle, Beauty & Fashion': {
		goLiveProducts: {
			1: {
				type: 'fulfillment',
				displayedType: 'Custom Client Request',
				image: 'https://assets.stanwith.me/graphics/random/lifestyle_beauty_fashion_audit.png',
				title: 'Beauty / Fashion Audit',
				pageData: {
					button: {
						heading: 'Beauty / Fashion Audit',
						tagline: 'I will help you find the right accessories to perfect your style!',
						title: 'Get an Audit',
						image: 'https://assets.stanwith.me/graphics/random/lifestyle_beauty_fashion_audit.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/lifestyle_beauty_fashion_audit.png',
						title: 'Beauty / Fashion Audit',
						description: 'I’ll send you a personalized video response answering your question to help you achieve your goals!',
						price: {
							amount: 9,
						},
					},
					submit_title: 'Purchase',
				},
			},
			2: {
				type: 'meeting',
				displayedType: 'Calendar Booking',
				image: 'https://assets.stanwith.me/graphics/device/meeting.png',
				title: '1:1 Style Coaching',
				pageData: {
					button: {
						heading: '1:1 Coaching',
						tagline: 'I will personally coach you to help you reach your goals!',
						title: 'Book a 1:1 Session',
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
						title: '1:1 Coaching',
						description:
							'<p>I am here to help you achieve your goals.</p><p>On this 1:1 Call, I will personally help you:</p><ul><li><p>Give you specific advice to your situation</p></li><li><p>Build a plan to reach your goals</p></li><li><p>Walk you through all of your questions</p></li></ul>',
						price: {
							amount: 39,
						},
					},
					bottom_title: 'Work 1:1 With Me',
					submit_title: 'Book a Session',
				},
			},
		},
		otherRecommendedProducts: {
			1: {
				type: 'lead-magnet',
				displayedType: 'Freebie / Lead Magnet',
				image: 'https://assets.stanwith.me/graphics/random/free_eBook_Guide_general.jpg',
				title: 'Free eBook or Guide',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/free_eBook_Guide_general.jpg',
						heading: 'Download my FREE Guide',
						tagline: 'Don’t miss out on my tips to help you reach your goals!',
						title: 'Download',
					},
				},
			},
			2: {
				type: 'course',
				displayedType: 'eCourse',
				image: 'https://assets.stanwith.me/graphics/random/lifestyle_beauty_fashion_eCourse.png',
				title: 'A "How to" Beauty Course',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/lifestyle_beauty_fashion_eCourse.png',
						heading: 'My Styling 101 Course',
						tagline: 'This course will guide you through how to recreate my favorite styles!',
						title: 'Get My Course',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/lifestyle_beauty_fashion_eCourse.png',
						title: 'My Styling 101 Course',
						description:
							'<p>This course has step-by-step guides of my 5 favorite looks for this seasons!</p><p><strong>Each video will walk you through:</strong></p><ul><li><p>The styling techniques I use</p></li><li><p>My favorite affiliate products</p></li><li><p>How to apply this to your own style</p></li></ul>',
						price: {
							amount: 79,
						},
					},
					bottom_title: 'Join the Program',
					submit_title: 'PURCHASE',
				},
			},
			3: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/lifestyle_beauty_fashion_guide.jpg',
				title: 'A "How to Get Paid as an Influencer" Guide',
				pageData: {
					button: {
						heading: '"How to Get Paid as an Influencer" Guide',
						tagline: 'Want to live the life I do? This guide will teach you everything.',
						title: 'Get My Guide',
						image: 'https://assets.stanwith.me/graphics/random/lifestyle_beauty_fashion_guide.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/lifestyle_beauty_fashion_guide.jpg',
						title: '"How to Get Paid as an Influencer" Guide',
						description:
							'<p>Want to get paid to create Lifestyle content? Want to get paid to travel the world? Want to get cool PR you can show your friends?</p><p>This guide will give you a step-by-step to how I:</p><ul><li><p>Built my following</p></li><li><p>Batch my content</p></li><li><p>Got my first brand deals</p></li></ul>',
						price: {
							amount: 19,
						},
					},
				},
			},
			4: {
				type: 'link',
				displayedType: 'Affiliate Link',
				image: 'https://assets.stanwith.me/graphics/random/lifestyle_beauty_fashion_affiliate.jpg',
				title: 'Your Favorite Links',
				pageData: {
					button: {
						title: 'Shop My Favorites',
						heading: 'My Favorite Items!',
						tagline: 'Get my top items that I recommend!',
					},
				},
			},
		},
	},
	'Mindset & Motivation': {
		goLiveProducts: {
			1: {
				type: 'meeting',
				displayedType: 'Calendar Booking',
				image: 'https://assets.stanwith.me/graphics/device/meeting.png',
				title: '1:1 Coaching',
				pageData: {
					button: {
						heading: '1:1 Coaching',
						tagline: 'I will personally coach you to help you reach your goals!',
						title: 'Book a 1:1 Session',
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
						title: '1:1 Coaching',
						description:
							'<p>I am here to help you achieve your goals.</p><p>On this 1:1 Call, I will personally help you:</p><ul><li><p>Give you specific advice to your situation</p></li><li><p>Build a plan to reach your goals</p></li><li><p>Walk you through all of your questions</p></li></ul>',
						price: {
							amount: 39,
						},
					},
					bottom_title: 'Work 1:1 With Me',
					submit_title: 'Book a Session',
				},
			},
			2: {
				type: 'fulfillment',
				displayedType: 'Custom Client Request',
				image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
				title: '"Ask Me Anything" Request',
				pageData: {
					button: {
						heading: 'Ask Me Anything',
						tagline: 'Get a personalized video response from me answering your question!',
						title: 'Ask Me Anything',
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
						title: 'Ask Me Anything',
						description: '<p>I’ll send you a personalized video response answering your question to help you achieve your goals!</p>',
						price: {
							amount: 9,
						},
					},
					submit_title: 'Purchase',
				},
			},
		},
		otherRecommendedProducts: {
			1: {
				type: 'lead-magnet',
				displayedType: 'Freebie / Lead Magnet',
				image: 'https://assets.stanwith.me/graphics/random/free_eBook_Guide_general.jpg',
				title: 'Free eBook or Guide',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/free_eBook_Guide_general.jpg',
						heading: 'Download my FREE Guide',
						tagline: 'Don’t miss out on my tips to help you reach your goals!',
						title: 'Download',
					},
				},
			},
			2: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/templates_toolkits.jpg',
				title: 'Templates, Toolkits, or Processes You Use for Your Own Business',
				pageData: {
					button: {
						heading: 'Get My Productivity Template',
						tagline: 'I use this template everyday to be more efficient in my own business process!',
						title: 'Download',
						image: 'https://assets.stanwith.me/graphics/random/templates_toolkits.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/templates_toolkits.jpg',
						title: 'Get My Productivity Template',
						description:
							'<p>Need help streamlining your own process? Struggling to find the time to keep up with all your different workstreams?</p><p>I built this template to save you time, money and energy. I use this template everyday, and it’s helped me save <strong>hours every day</strong> in managing my team, tracking my to-do’s, and setting goals.</p><p><strong>This template will help you:</strong></p><ul><li><p>Track your To-Do’s all in one place</p></li><li><p>Delegate tasks and keep track of who’s doing what</p></li><li><p>Make your process more efficient and automated</p></li></ul>',
						price: {
							amount: 9,
						},
					},
					bottom_title: 'Get My Template',
					submit_title: 'Download',
				},
			},
			3: {
				type: 'course',
				displayedType: 'eCourse',
				image: 'https://assets.stanwith.me/graphics/random/eCourse_101.jpg',
				title: 'A "How to" Course',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/eCourse_101.jpg',
						heading: 'My 101 Course',
						tagline: 'This course will teach you everything you need to know to achieve your goals!',
						title: 'Get My Course',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/eCourse_101.jpg',
						title: 'My 101 Course',
						description:
							'<p>Need help getting your mindset right? Not sure how to get out of a funk?</p><p><strong>This course will teach you everything you need to know to:</strong></p><ul><li><p>Get out of your head</p></li><li><p>Achieve your goals</p></li><li><p>Build the life of your dreams</p></li></ul>',
						price: {
							amount: 79,
						},
					},
					bottom_title: 'Join the Program',
					submit_title: 'PURCHASE',
				},
			},
			4: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/how_to_guide_101.jpg',
				title: 'A "How to" Guide',
				pageData: {
					button: {
						heading: 'My 101 Guide',
						tagline: 'This guide will give you a step-by-step to achieve your goals!',
						title: 'Get My Guide',
						image: 'https://assets.stanwith.me/graphics/random/how_to_guide_101.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/how_to_guide_101.jpg',
						title: 'My 101 Guide',
						description:
							'<p>Need help getting your mindset right? Not sure how to get out of a funk?</p><p><strong>This guide will teach you everything you need to know to:</strong></p><ul><li><p>Get out of your head</p></li><li><p>Achieve your goals</p></li><li><p>Build the life of your dreams</p></li></ul>',
						price: {
							amount: 29,
						},
					},
				},
			},
		},
	},
	'Spirituality & Psychic': {
		goLiveProducts: {
			1: {
				type: 'fulfillment',
				displayedType: 'Custom Client Request',
				image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
				title: '"Ask Me Anything" Request',
				pageData: {
					button: {
						heading: 'Ask Me Anything',
						tagline: 'Get a personalized video response from me answering your question!',
						title: 'Ask Me Anything',
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
						title: 'Ask Me Anything',
						description: '<p>I’ll send you a personalized video response answering your question to help you achieve your goals!</p>',
						price: {
							amount: 9,
						},
					},
					submit_title: 'Purchase',
				},
			},
			2: {
				type: 'meeting',
				displayedType: 'Calendar Booking',
				image: 'https://assets.stanwith.me/graphics/device/meeting.png',
				title: '1:1 Tarot Reading',
				pageData: {
					button: {
						heading: '1:1 Coaching',
						tagline: 'I will personally help you reach your goals!',
						title: 'Book a 1:1 Session',
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
						title: '1:1 Coaching',
						description:
							'<p>I am here to help you achieve your goals.</p><p>On this 1:1 Call, I will personally help you:</p><ul><li><p>Give you specific advice to your situation</p></li><li><p>Build a plan to reach your goals</p></li><li><p>Walk you through all of your questions</p></li></ul>',
						price: {
							amount: 39,
						},
					},
					bottom_title: 'Work 1:1 With Me',
					submit_title: 'Book a Session',
				},
			},
		},
		otherRecommendedProducts: {
			1: {
				type: 'lead-magnet',
				displayedType: 'Freebie / Lead Magnet',
				image: 'https://assets.stanwith.me/graphics/random/free_eBook_Guide_general.jpg',
				title: 'Free eBook or Guide',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/free_eBook_Guide_general.jpg',
						heading: 'Download my FREE Guide',
						tagline: 'Don’t miss out on my tips to help you reach your goals!',
						title: 'Download',
					},
				},
			},
			2: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/Spirituality_Psychic_meditation.jpg',
				title: 'Guided Meditation (Audio)',
				pageData: {
					button: {
						heading: 'Get My Guided Meditations',
						tagline: 'This meditation will help you manifest your dream life',
						title: 'Download',
						image: 'https://assets.stanwith.me/graphics/random/Spirituality_Psychic_meditation.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/Spirituality_Psychic_meditation.jpg',
						title: 'Get My Guided Meditations',
						description:
							'<p>Want to manifest your dream life? Need help falling asleep? Struggling with anxiety?</p><p>I recorded this guided meditation pack that you can listen to at any time to reset.</p><p>In this meditation pack, you’ll find a meditation for:</p><ul><li><p>Stress/Anxiety</p></li><li><p>Manifestation</p></li><li><p>Sleep</p></li></ul>',
						price: {
							amount: 9,
						},
					},
					bottom_title: 'Get My Guided Meditations',
					submit_title: 'Download',
				},
			},
			3: {
				type: 'course',
				displayedType: 'eCourse',
				image: 'https://assets.stanwith.me/graphics/random/eCourse_101.jpg',
				title: 'A "How to" Course',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/eCourse_101.jpg',
						heading: 'My 101 Course',
						tagline: 'This course will teach you everything you need to know to achieve your goals!',
						title: 'Get My Course',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/eCourse_101.jpg',
						title: 'My 101 Course',
						description:
							'<p>Need help finding your spiritual center? Not sure on where your life path is taking you?</p><p><strong>This course will teach you everything you need to know to:</strong></p><ul><li><p>Ground yourself</p></li><li><p>Figure out your life path</p></li><li><p>Reconnecting with your inner child</p></li></ul>',
						price: {
							amount: 79,
						},
					},
					bottom_title: 'Join the Program',
					submit_title: 'PURCHASE',
				},
			},
			4: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/Spirituality_Psychic_manifest_journal.jpg',
				title: 'A Manifestation Worksheet / Journal',
				pageData: {
					button: {
						heading: 'My Manifestation Worksheet',
						tagline: 'This printable worksheet will give you a daily prompt to manifest your future',
						title: 'Get My Worksheet',
						image: 'https://assets.stanwith.me/graphics/random/Spirituality_Psychic_manifest_journal.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/Spirituality_Psychic_manifest_journal.jpg',
						title: 'My Manifestation Worksheet',
						description:
							'<p>This weekly worksheet has 7 prompts to help you manifest your dream life.</p><p>This worksheet will help you work through:</p><ul><li><p>Your relationship goals</p></li><li><p>Your career goals</p></li><li><p>Your personal happiness and fulfillment</p></li></ul>',
						price: {
							amount: 9,
						},
					},
					bottom_title: 'Get My Worksheet',
					submit_title: 'PURCHASE',
				},
			},
		},
	},
	Other: {
		goLiveProducts: {
			1: {
				type: 'meeting',
				displayedType: 'Calendar Booking',
				image: 'https://assets.stanwith.me/graphics/device/meeting.png',
				title: '1:1 Coaching',
				pageData: {
					button: {
						heading: '1:1 Coaching',
						tagline: 'I will personally coach you to help you reach your goals!',
						title: 'Book a 1:1 Session',
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/device/meeting.png',
						title: '1:1 Coaching',
						description:
							'<p>I am here to help you achieve your goals.</p><p>On this 1:1 Call, I will personally help you:</p><ul><li><p>Give you specific advice to your situation</p></li><li><p>Build a plan to reach your goals</p></li><li><p>Walk you through all of your questions</p></li></ul>',
						price: {
							amount: 39,
						},
					},
					bottom_title: 'Work 1:1 With Me',
					submit_title: 'Book a Session',
				},
			},
			2: {
				type: 'fulfillment',
				displayedType: 'Custom Client Request',
				image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
				title: '"Ask Me Anything" Request',
				pageData: {
					button: {
						heading: 'Ask Me Anything',
						tagline: 'Get a personalized video response from me answering your question!',
						title: 'Ask Me Anything',
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/AMA_general_image.png',
						title: 'Ask Me Anything',
						description: '<p>I’ll send you a personalized video response answering your question to help you achieve your goals!</p>',
						price: {
							amount: 9,
						},
					},
					submit_title: 'Purchase',
				},
			},
		},
		otherRecommendedProducts: {
			1: {
				type: 'lead-magnet',
				displayedType: 'Freebie / Lead Magnet',
				image: 'https://assets.stanwith.me/graphics/random/free_eBook_Guide_general.jpg',
				title: 'Free eBook or Guide',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/free_eBook_Guide_general.jpg',
						heading: 'Download my FREE Guide',
						tagline: 'Don’t miss out on my tips to help you reach your goals!',
						title: 'Download',
					},
				},
			},
			2: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/templates_toolkits.jpg',
				title: 'Templates, Toolkits, or Processes You Use for Your Own Business',
				pageData: {
					button: {
						heading: 'Get My Productivity Template',
						tagline: 'I use this template everyday to be more efficient in my own business process!',
						title: 'Download',
						image: 'https://assets.stanwith.me/graphics/random/templates_toolkits.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/templates_toolkits.jpg',
						title: 'Get My Productivity Template',
						description:
							'<p>Need help streamlining your own process?</p><p>Struggling to find the time to keep up with all your different workstreams?</p><p>I built this template to save you time, money and energy. I use this template everyday, and it’s helped me save <strong>hours every day</strong> in managing my team, tracking my to-do’s, and setting goals.<p>This template will help you:</p><ul><li><p>Track your To-Do’s all in one place</p></li><li><p>Delegate tasks and keep track of who’s doing what</p></li><li><p>Make your process more efficient and automated</p></li></ul>',
						price: {
							amount: 9,
						},
					},
					bottom_title: 'Get My Template',
					submit_title: 'Download',
				},
			},
			3: {
				type: 'course',
				displayedType: 'eCourse',
				image: 'https://assets.stanwith.me/graphics/random/eCourse_101.jpg',
				title: 'A "How to" Course',
				pageData: {
					button: {
						image: 'https://assets.stanwith.me/graphics/random/eCourse_101.jpg',
						heading: 'My 101 Course',
						tagline: 'This course will teach you everything you need to know to achieve your goals!',
						title: 'Get My Course',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/eCourse_101.jpg',
						title: 'My 101 Course',
						description:
							'<p>Need help getting your business off the ground? Not sure how to get your first clients?</p><p><strong>This course will teach you everything you need to know to:</strong></p><ul><li><p>Get your business off the ground</p></li><li><p>Convert your first clients</p></li><li><p>Build the business of your dreams</p></li></ul><p>Course Curriculum:</p><ul><li><p>Lesson 1: How to Find a Market Niche</p></li><li><p>Lesson 2: How to Create a Product for Your Niche</p></li><li><p>Lesson 3: How to Find Your First Clients</p></li><li><p>Lesson 4: How to Scale Your Business</p></li></ul>',
						price: {
							amount: 79,
						},
					},
					bottom_title: 'Join the Program',
					submit_title: 'PURCHASE',
				},
			},
			4: {
				type: 'digital-download',
				displayedType: 'Digital Product',
				image: 'https://assets.stanwith.me/graphics/random/how_to_guide_101.jpg',
				title: 'A "How to" Guide',
				pageData: {
					button: {
						heading: 'My 101 Guide',
						tagline: 'This guide will give you a step-by-step to achieve your goals!',
						title: 'Get My Guide',
						image: 'https://assets.stanwith.me/graphics/random/how_to_guide_101.jpg',
					},
					product: {
						image: 'https://assets.stanwith.me/graphics/random/how_to_guide_101.jpg',
						title: 'My 101 Guide',
						description:
							'<p>Need help getting your business off the ground? Not sure how to get your first clients?</p><p>This guide will give you a step-by-step to:</p><ul><li><p>Get your business off the ground</p></li><li><p>Convert your first clients</p></li><li><p>Build the business of your dreams</p></li></ul>',
						price: {
							amount: 29,
						},
					},
				},
			},
		},
	},
}
