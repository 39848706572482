var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      ref: "modal",
      attrs: {
        action: `Delete ${_vm.tableTotalCustomers} ${_vm.customerText}`,
        "secondary-action": "Keep them!",
        title: "Are you sure?",
        loading: _vm.loading,
        actionColor: "danger",
        secondaryActionColor: "light"
      },
      on: {
        close: _vm.hide,
        cta: _vm.submitModalForm,
        "secondary-cta": _vm.hide
      }
    },
    [
      _vm.showOneCustomerText
        ? _c("div", { staticClass: "modal-subheading para-2" }, [
            _vm._v(
              "\n\t\tBy deleting, you'll permanently remove this user from your mailing lists and lead magnets. "
            ),
            _c("b", [_vm._v("Undo is not an option.")])
          ])
        : _c("div", { staticClass: "modal-subheading para-2" }, [
            _vm._v(
              "\n\t\tBy deleting, you'll permanently remove these users from your mailing lists and lead magnets. "
            ),
            _c("b", [_vm._v("Undo is not an option.")])
          ]),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "importing-animation" },
                [
                  _c("InlineSvg", {
                    attrs: { src: "/images/icons/ic-customer.svg" }
                  }),
                  _vm._v(" "),
                  _c("InlineSvg", {
                    attrs: { src: "/images/icons/ic-customer.svg" }
                  }),
                  _vm._v(" "),
                  _c("InlineSvg", {
                    attrs: { src: "/images/icons/ic-customer.svg" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("AppProgress", {
                attrs: {
                  min: 0,
                  max: _vm.totalCustomers,
                  value: _vm.processedCount
                }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }