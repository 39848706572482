<template>
	<div class="stan-device course-page-preview">
		<div class="site-preview-section mx-auto funnel-page-preview store-theme-page">
			<div class="site__prev__masking">
				<div class="store-layout">
					<div key="basic" class="course-wrapper" v-if="!showCoursesList">
						<div v-if="showHome" class="outline-wrapper">
							<div v-if="bannerImage" class="image-wrapper">
								<AppImage :src="bannerImage" class="banner-image" />
							</div>
							<h1 class="course-title" v-if="course.data.title">{{ course.data.title }}</h1>
							<div class="description" v-if="course.data.description">
								<TipTapViewer v-if="course.data.description" v-model="course.data.description" read-only class="px-5-mod " />
							</div>
							<div class="button-wrapper">
								<div class="controller-button next-button" v-show="canShowNext" @click="firstLesson">
									<div class="controller-button-text align-items-end flex-shrink-full">
										<div class="para-3 button-header-text">First Lesson:</div>
										<div class="para-3 font-semibold button-subheader-text font-ellipsis text-right">
											{{ nextLessonTitle }}
										</div>
									</div>
									<svg
										width="8"
										height="16"
										fill="var(--stan-store-custom-highlight-color)"
										xmlns="http://www.w3.org/2000/svg"
										class=""
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M.258 15.075a.812.812 0 010-1.15l5.734-5.733a.27.27 0 000-.384L.258 2.075a.812.812 0 111.15-1.15L7.14 6.66c.74.74.74 1.941 0 2.682l-5.733 5.734a.812.812 0 01-1.15 0z"
											fill="inherit"
										></path>
									</svg>
								</div>
							</div>
						</div>
						<div v-else-if="currentLesson" class="lesson-details" :class="{ 'module-type-video': lessonHasVideo }">
							<Video
								v-if="lessonHasVideo"
								:video-src="currentLesson.data.video"
								:video-stream-src="currentLesson.data.video_stream"
								:fcp-complete="true"
								:showMask="false"
								class="pages-block py-6"
								key="video"
							/>
							<div class="video-placeholder mb-6" v-if="!hideVideo">
								<div class="video-button-wrapper">
									<div class="video-icon"><img src="https://assets.stanwith.me/graphics/video-play.svg" /></div>
									<span>No Video Yet</span>
								</div>
							</div>

							<h1 class="lesson-title">{{ currentLesson.data.title }}</h1>
							<div class="description">
								<TipTapViewer
									v-if="currentLesson.data.description"
									v-model="currentLesson.data.description"
									read-only
									class="mb-5 px-5-mod "
								/>
								<div class="lesson-support-wrapper" v-if="currentLesson.data.digital_assets.length">
									<h3 class="lesson-support-title">{{ $t('Supporting Materials') }}</h3>
									<ul class="supporting-materials-list">
										<li :key="index" v-for="(asset, index) in currentLesson.data.digital_assets">
											<a>
												<img :src="iconUrl" class="download-icon" />
												<span class="download-text">{{ asset.name }}</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
							<div class="button-wrapper">
								<!-- Back Button -->
								<div class="controller-button prev-button" @click="prevLesson" v-show="!showHome && canShowPrev">
									<svg
										width="8"
										height="15"
										fill="var(--stan-store-custom-highlight-color)"
										xmlns="http://www.w3.org/2000/svg"
										class=""
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M7.438.238a.812.812 0 010 1.149L1.704 7.121a.27.27 0 000 .383l5.734 5.734a.812.812 0 11-1.149 1.149L.555 8.653c-.74-.74-.74-1.94 0-2.681L6.29.238a.812.812 0 011.15 0z"
											fill="inherit"
										></path>
									</svg>
									<div class="controller-button-text align-items-start flex-shrink-full">
										<div class="para-3 button-header-text">Previous Lesson:</div>
										<div class="para-3 font-semibold button-subheader-text text-ellipsis">
											{{ previousLessonTitle }}
										</div>
									</div>
								</div>

								<!-- Next -->
								<div class="controller-button next-button" @click="nextLesson" v-show="!showHome && canShowNext">
									<div class="controller-button-text align-items-end flex-shrink-full">
										<div class="para-3 button-header-text">Next Lesson:</div>
										<div class="para-3 font-semibold button-subheader-text text-ellipsis text-right">
											{{ nextLessonTitle }}
										</div>
									</div>
									<svg
										width="8"
										height="16"
										fill="var(--stan-store-custom-highlight-color)"
										xmlns="http://www.w3.org/2000/svg"
										class=""
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M.258 15.075a.812.812 0 010-1.15l5.734-5.733a.27.27 0 000-.384L.258 2.075a.812.812 0 111.15-1.15L7.14 6.66c.74.74.74 1.941 0 2.682l-5.733 5.734a.812.812 0 01-1.15 0z"
											fill="inherit"
										></path>
									</svg>
								</div>

								<!-- COMPLETE -->
								<div class="controller-button next-button" @click="goToHome()" v-show="!showHome && !canShowNext">
									<div class="controller-button-text align-items-end">
										<div class="para-3 button-header-text">All Done!</div>
										<div class="para-3 font-semibold button-subheader-text text-ellipsis text-right">
											Complete Course
										</div>
									</div>
								</div>
							</div>
						</div>

						<div v-if="currentLesson || showHome" class="course-sidebar-mobile-trigger" @click="toggleShowCoursesList">
							<InlineSvg class="burger-menu-icon" src="/images/preview-icons/menu-burger.svg" />
							<span class="para-1 text-bold font-ellipsis">
								{{ showHome ? course.data.title : currentLesson.data.title }}
							</span>
						</div>
					</div>
					<CourseSidebar
						v-if="showCoursesList"
						:isMobilePreview="true"
						:modules="modules"
						:showCoursesList="showCoursesList"
						:course="course"
						:activeModule="currentModule"
						:activeLesson="currentLesson"
						:chooseLesson="goToLesson"
						@chooseHome="goToHome"
						@toggleShowCoursesList="toggleShowCoursesList"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Color from 'color'
	import TipTapViewer from '~/stan-vue-shared/components/TipTapViewer'
	import Video from '~/stan-vue-shared/components/Video'
	import CONSTANTS from '../../global_helper/constants.js'

	export default {
		name: 'CoursePagePreview',
		components: {
			TipTapViewer,
			Video,
		},
		props: {
			user: { type: Object, default: () => {} },
			course: { type: Object, default: () => {} },
			activeModule: { type: Number, default: 0 },
			activeLesson: { type: Number, default: 0 },
			chooseHome: { type: Function, default: () => {} },
			chooseLesson: { type: Function, default: () => {} },
			prevLesson: { type: Function, default: () => {} },
			nextLesson: { type: Function, default: () => {} },
			firstLesson: { type: Function, default: () => {} },
			showHome: { type: Boolean, default: true },
			canShowNext: { type: Boolean, default: false },
			canShowPrev: { type: Boolean, default: false },
			nextLessonTitle: { type: String, default: '' },
			previousLessonTitle: { type: String, default: '' },
			hideVideo: { type: Boolean, default: true },
		},
		data() {
			return {
				showCoursesList: false,
			}
		},
		computed: {
			modules() {
				return this.course.modules
			},
			currentModule() {
				return this.modules[this.activeModule]
			},
			currentLesson() {
				return this.currentModule?.lessons[this.activeLesson]
			},
			lessonHasVideo() {
				return this.currentLesson?.data.video
			},
			bannerImage() {
				return this.course.data.banner_image_url
			},
			courseData() {
				return this.course.data
			},
			iconUrl() {
				const backgroundColor = this.course?.data?.theme?.background_color ?? CONSTANTS.THEME_DEFAULTS.DEFAULT_BG
				return new Color(backgroundColor).isDark() ? '/images/icons/ic-digital-download-white.svg' : '/images/icons/ic-digital-download.svg'
			},
		},
		mounted() {
			this.initializeTheme()
		},
		watch: {
			activeModule() {
				this.scrollTop()
			},
			activeLesson() {
				this.scrollTop()
			},
			courseData() {
				this.initializeTheme()
			},
		},
		methods: {
			toggleShowCoursesList() {
				this.showCoursesList = !this.showCoursesList
			},
			closeShowCoursesList() {
				this.showCoursesList = false
			},
			goToLesson(_, lesson) {
				this.chooseLesson(lesson.lesson_id)
				this.closeShowCoursesList()
			},
			goToHome() {
				this.chooseHome()
				this.closeShowCoursesList()
			},
			scrollTop() {
				$('.site__prev__masking').animate({ scrollTop: 0 }, 0)
				$('.course-wrapper').animate({ scrollTop: 0 }, 0)
			},
			initializeTheme() {
				const backgroundColor = this.course?.data?.theme?.background_color ?? CONSTANTS.THEME_DEFAULTS.DEFAULT_BG
				const highlightColor = this.course?.data?.theme?.highlight_color ?? CONSTANTS.THEME_DEFAULTS.DEFAULT_HIGHTLIGHT_COLOR
				const titleFont = this.course?.data?.theme?.title_font ?? CONSTANTS.THEME_DEFAULTS.DEFAULT_FONT
				const textColor = new Color(backgroundColor).isDark() ? 'white' : CONSTANTS.THEME_DEFAULTS.DEFAULT_TEXT_COLOR
				const alteredBackgroundColor = new Color(backgroundColor).isDark()
					? new Color(backgroundColor).lighten(0.1).hex()
					: new Color(backgroundColor).darken(0.1).hex()
				const highlightRGB = this.$hexToRGB(highlightColor.replace('#', '') ?? '#5383ff'.replace('#', ''))

				// Different shades of the colours
				const rgbaColor5 = `rgba(${highlightRGB.r}, ${highlightRGB.g}, ${highlightRGB.b}, ${0.05})`
				const rgbaColor10 = `rgba(${highlightRGB.r}, ${highlightRGB.g}, ${highlightRGB.b}, ${0.1})`
				const rgbaColor25 = `rgba(${highlightRGB.r}, ${highlightRGB.g}, ${highlightRGB.b}, ${0.25})`
				const rgbaColor75 = `rgba(${highlightRGB.r}, ${highlightRGB.g}, ${highlightRGB.b}, ${0.75})`

				document.documentElement.style.setProperty('--stan-store-highlight-5-opacity', rgbaColor5)
				document.documentElement.style.setProperty('--stan-store-highlight-10-opacity', rgbaColor10)
				document.documentElement.style.setProperty('--stan-store-highlight-25-opacity', rgbaColor25)
				document.documentElement.style.setProperty('--stan-store-highlight-75-opacity', rgbaColor75)

				document.documentElement.style.setProperty('--stan-store-custom-text-color', textColor)
				document.documentElement.style.setProperty('--stan-store-custom-background-color', backgroundColor)
				document.documentElement.style.setProperty('--stan-store-custom-altered-background-color', alteredBackgroundColor)
				document.documentElement.style.setProperty('--stan-store-custom-highlight-color', highlightColor)
				document.documentElement.style.setProperty('--stan-store-custom-title-font', titleFont)
			},
		},
	}
</script>

<style lang="scss">
	.course-sidebar-mobile-trigger {
		display: none;
		box-shadow: 0px -5px 40px rgba(0, 0, 0, 0.4);
		cursor: pointer;
		text-align: center;
		position: sticky;
		bottom: 0;
		display: flex;
		gap: 12px;
		align-items: center;
		background-color: var(--stan-store-custom-background-color);
		color: var(--stan-store-custom-text-color);
		padding: 18px;
		width: 100%;
	}
	.burger-menu-icon {
		fill: var(--stan-store-custom-text-color) !important;
	}
	.font-ellipsis {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 100%;
	}
	.course-page-preview {
		.site__prev__masking,
		.store-layout,
		.course-wrapper {
			height: 100%;
			position: relative;
		}

		background: var(--stan-store-custom-background-color);
		border-radius: 30px !important;
		color: var(--stan-store-custom-text-color);
	}
	.course-wrapper {
		display: flex;
		flex-direction: column;
		max-width: 1200px;
		margin: auto;
		.course-title {
			padding: 30px 30px 0 30px;
			color: var(--stan-store-custom-highlight-color);
			font-family: var(--stan-store-custom-title-font), 'Grobek', sans-serif !important;
			font-size: 1.75rem;
			font-weight: 800;
			word-break: break-word;
		}
		.description {
			width: 100%;
			padding: 0 30px;
			.ProseMirror {
				word-wrap: break-word;
				white-space: break-spaces;
				-webkit-font-variant-ligatures: none;
				font-variant-ligatures: none;
				font-feature-settings: 'liga' 0;
				position: relative;
				color: var(--stan-store-custom-text-color);
				height: auto;
				::marker {
					color: var(--stan-store-custom-highlight-color);
				}
				p {
					font-size: 1rem !important;
					margin-top: 30px;
					margin-bottom: 0;
				}
				li {
					p {
						margin-top: 1rem;
					}
				}
				ul {
					margin-top: 30px;
				}
			}
		}
		.lesson-details {
			width: 100%;
			padding-bottom: 200px;
			.lesson-title {
				padding: 0 30px;
				color: var(--stan-store-custom-highlight-color);
				font-family: var(--stan-store-custom-title-font), 'Grobek', sans-serif !important;
				font-size: 1.75rem;
				font-weight: 800;
				word-break: break-word;
				&:first-child {
					margin-top: 30px;
				}
			}
			.description {
				.lesson-support-wrapper {
					margin-top: 30px;
					.lesson-support-title {
						font-size: 1.3rem;
						font-family: var(--stan-store-custom-title-font), 'Grobek', sans-serif !important;
						color: var(--stan-store-custom-text-color);
						padding-bottom: 10px;
					}
					.download-icon {
						height: 1.2rem;
						transition: transform 0.3s ease-in-out;
					}
					.download-text {
						transition: all 0.3s ease;
						border-bottom: 1px solid transparent;
						color: var(--stan-store-custom-highlight-color);
					}
					.supporting-materials-list {
						list-style: none;
						padding: 0;
						li {
							a {
								color: $link-blue;
								text-decoration: none;
								position: relative;
								padding-bottom: 2px;

								&:hover {
									filter: brightness(0.8);
									.download-icon {
										animation: bounce 1s ease infinite;
									}
									.download-text {
										border-color: var(--stan-store-custom-highlight-color);
									}
								}
							}
						}
					}
				}
			}
		}

		.outline-wrapper {
			width: 100%;
			margin-bottom: 30px;
			@media (min-width: 768px) {
				flex-grow: 1;
				flex-basis: 0;
			}
			.outline {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				height: 100%;
				padding: 0 30px;
				-ms-overflow-style: none; /* IE and Edge */
				scrollbar-width: none; /* FireFox */
				&::-webkit-scrollbar {
					display: none;
				}
				.title {
					cursor: pointer;
					display: flex;
					gap: 7px;
					align-items: center;
					line-height: 1;
					padding: 15px;
					margin-bottom: 0;
					font-family: var(--stan-store-custom-title-font), 'Grobek', sans-serif !important;
					font-size: 1rem;
					justify-content: space-between;
					padding-left: 0px;
					color: var(--stan-store-custom-text-color);

					.text {
						display: flex;
						align-items: center;
						gap: 8px;
					}
					svg {
						fill: var(--stan-store-custom-text-color);
						stroke: var(--stan-store-custom-text-color);
					}
					.indicator {
						width: 20px;
						height: 20px;
						transform: rotate(-90deg);
					}
				}
				.modules {
					list-style: none;
					border-top: 1px solid var(--stan-store-custom-altered-background-color);
					overflow-y: scroll;
					max-height: 0;
					transition: all linear 0.5s;
					-ms-overflow-style: none; /* IE and Edge */
					scrollbar-width: none; /* FireFox */
					&::-webkit-scrollbar {
						display: none;
					}
					@media (min-width: 768px) {
						max-height: calc(100% - 30px);
					}
					&.show {
						max-height: calc(100vh - 30px);
						@supports (height: 100dvh) {
							max-height: calc(100dvh - 30px);
						}
					}

					& > li {
						padding: 0px;
						padding-top: 15px;
						position: relative;
						cursor: pointer;
						border-bottom: 1px solid var(--stan-store-custom-altered-background-color);

						p {
							margin: 0;
						}
					}
					.course-home-module {
						padding-bottom: 15px;
						.selected {
							color: var(--stan-store-custom-highlight-color);
						}
					}
				}
				.lessons {
					list-style: none;
					max-height: calc(100% - 30px);
					overflow-y: scroll;
					padding: 10px 15px;
					-ms-overflow-style: none; /* IE and Edge */
					scrollbar-width: none; /* FireFox */
					&::-webkit-scrollbar {
						display: none;
					}
					li {
						display: flex;
						padding: 5px 0px;
						align-items: center;
						position: relative;
						gap: 8px;
						cursor: pointer;
						.icon {
							svg {
								width: 22px;
								height: 22px;
								circle {
									fill: var(--stan-store-custom-highlight-color);
								}
							}
						}
						&.selected {
							&::before {
								background: var(--stan-store-custom-highlight-color);
							}
						}

						.animated-check {
							margin-bottom: 4px;
							flex: 0 0 auto;
						}
					}
				}
			}
		}
		.video-wrapper {
			width: 100%;
			padding-top: 63.25% !important;
			height: 0px;
			position: relative;
			margin-bottom: 30px;
			.artplayer-app {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100% !important;
				height: 100% !important;
				.art-video-player {
					.art-poster {
						border-radius: 0 !important;
					}
				}
				.video-image-preview {
					height: 100%;
					border-radius: 0 !important;
				}
				.video-content {
					height: 100%;
					border-radius: 0 !important;
				}
			}
			video {
				width: 100% !important;
				height: 100% !important;
				border-radius: 0 !important;
			}
		}
		.btn {
			display: flex;
			position: relative;
			flex-flow: row nowrap;
			flex-shrink: 0;
			align-items: center;
			justify-content: center;
			z-index: 0;
			width: 120px;
			float: left;
			line-height: 22px;
			min-height: 50px;
			border-radius: 8px;
		}
	}
	.video-button-wrapper {
		display: flex;
		flex-direction: column;
		color: var(--stan-oxford-blue-2);
		gap: 8px;
		align-items: center;
	}

	.video-icon {
		width: 60px;
		height: 60px;
		padding: 15px;
		background-color: var(--stan-oxford-blue-2);
		border-radius: 50%;
		img {
			width: 100%;
			height: 100%;
			margin-left: 3px;
		}
	}

	.video-placeholder {
		width: 100%;
		min-height: 200px;
		aspect-ratio: 1.65;
		display: flex;
		align-items: center;
		justify-content: center;
		background: var(--stan-blue-8);
		margin-bottom: 2rem;
	}
	.py-6 {
		padding-top: 24px !important;
		padding-bottom: 24px !important;
	}
	.mb-6 {
		margin-bottom: 24px !important;
	}
	.inactive {
		color: var(--stan-blue-6);
	}
	.drip-details {
		padding: 0.5rem;
		background-color: #f0f1ff;
		border-radius: 7px;
		line-height: 1;
		margin-left: 0.5rem;
		color: #6574ff;
	}
	@keyframes bounce {
		0%,
		100% {
			transform: translateY(0);
		}
		50% {
			transform: translateY(2px);
		}
	}
</style>

<style lang="scss" scoped>
	.site-preview-section {
		color: var(--stan-store-custom-text-color);
		border-radius: 30px !important;
		background: var(--stan-store-custom-background-color) !important;
	}

	.button-wrapper {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 26px;
		margin-top: 4rem;
		margin-bottom: 4rem;
		grid-template-columns: 1fr;
		padding: 0px 25px 0px 25px;

		.controller-button {
			display: flex;
			flex-direction: row;
			gap: 22px;
			padding: 18px;
			cursor: pointer;
			border-radius: 10px;
			color: var(--stan-store-custom-highlight-color) !important;
			transition: all 0.2s ease;
			align-items: center;

			&.prev-button {
				grid-column: 1;
				background-color: var(--stan-store-highlight-5-opacity) !important;
				color: var(--stan-store-custom-highlight-color) !important;
			}

			&.next-button {
				background-color: var(--stan-store-highlight-10-opacity) !important;
				grid-column: 1;

				&:hover {
					background-color: var(--stan-store-highlight-5-opacity) !important;
				}
			}
		}

		.controller-button-text {
			display: flex;
			flex-direction: column;
			gap: 5px;
			width: 100%;
		}

		.button-header-text {
			color: var(--stan-store-highlight-75-opacity);
		}

		.button-subheader-text {
			color: var(--stan-store-custom-highlight-color);
		}
	}

	.flex-shrink-full {
		flex-shrink: 100;
	}

	.align-items-start {
		align-items: start;
	}

	.align-items-end {
		align-items: end;
	}

	.text-right {
		text-align: right;
	}
</style>
