var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-wrap align-items-top app-checkbox" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value"
          }
        ],
        attrs: { id: _vm.elId, type: "checkbox" },
        domProps: {
          value: _vm.value,
          checked: Array.isArray(_vm.value)
            ? _vm._i(_vm.value, _vm.value) > -1
            : _vm.value
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event.target.checked)
          },
          change: function($event) {
            var $$a = _vm.value,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = _vm.value,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.value = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.value = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.value = $$c
            }
          }
        }
      }),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "ml-2", attrs: { for: _vm.elId } },
        [
          _vm._t("default", function() {
            return [_vm._v(_vm._s(_vm.label))]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }