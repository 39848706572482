var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isMobile
    ? _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "body flex-grow-1 col-12 col-md-5 px-0" }, [
          _c(
            "div",
            [
              _c("AppForm", {
                attrs: {
                  classOverride: "cancellation",
                  center: true,
                  formId: "cancellation-reason-form",
                  responseId: _vm.responseId,
                  questions: _vm.questions
                },
                on: {
                  completed: _vm.completed,
                  questionAnswered: _vm.disableSkip
                }
              }),
              _vm._v(" "),
              _c(
                "Transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [
                  _vm.canSkip
                    ? _c(
                        "AppButton",
                        {
                          staticClass: "w-100 p-1 h-auto",
                          attrs: {
                            name: "skip-feedback-cancellation-modal",
                            size: "large",
                            link: ""
                          },
                          on: { click: _vm.skipped }
                        },
                        [_vm._v("Skip feedback")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "body flex-grow-1 col-12 col-md-5 px-0" }, [
          !_vm.imageReady
            ? _c("div", { staticClass: "image-placeholder" })
            : _vm._e(),
          _vm._v(" "),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.imageReady,
                expression: "imageReady"
              }
            ],
            attrs: { src: "/images/cancellation/user-feedback.svg" },
            on: {
              load: function($event) {
                _vm.imageReady = true
              }
            }
          })
        ])
      ])
    : _c(
        "div",
        {
          staticClass:
            "container mobile-container d-flex justify-content-center align-items-center"
        },
        [
          _c(
            "div",
            { staticClass: "body flex-grow-1 col-12 col-md-5 px-0 h-100" },
            [
              _c(
                "div",
                [
                  _c("AppForm", {
                    attrs: {
                      classOverride: "cancellation",
                      center: true,
                      formId: "cancellation-reason-form",
                      responseId: _vm.responseId,
                      questions: _vm.questions
                    },
                    on: {
                      completed: _vm.completed,
                      questionAnswered: _vm.disableSkip
                    }
                  }),
                  _vm._v(" "),
                  _vm.canSkip
                    ? _c(
                        "AppButton",
                        {
                          staticClass: "p-1 h-auto",
                          attrs: {
                            name: "skip-feedback-cancellation-modal",
                            size: "large",
                            link: ""
                          },
                          on: { click: _vm.skipped }
                        },
                        [_vm._v("Skip feedback")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }