<template>
	<div class="accordion light" :class="cssClasses" :id="rootId">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'AppAccordion',
		props: {
			cssClasses: { type: String, default: '' },
			name: { type: String, default: '' },
		},
		computed: {
			rootId() {
				return `accordion-${this.name}`
			},
		},
	}
</script>

<style></style>
