<template>
	<div class="stanley-chat-message-container">
		<div class="row flex-sm-row justify-content-start align-items-start">
			<div class="col-auto pr-0">
				<img class="mt-1" src="/images/icons/ic-stanley-profile.svg" width="40px;" />
			</div>
			<div class="col">
				<div>
					<b>Stanley</b>
				</div>
				<div style="max-width: 100%;">
					<TypingAnimation v-if="thinking" class="mt-2" />
					<div v-else>
						<span v-html="text" v-if="isHtml" class="content-text"></span>
						<StanleyTypingText v-else :text="text" :typing="typing" @doneTyping="doneTyping" />
					</div>
				</div>
				<div v-show="showSlot">
					<slot></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			text: {
				type: String,
				required: true,
			},
			thinking: {
				type: Boolean,
				default: false,
			},
			typing: {
				type: Boolean,
				default: true,
			},
			isHtml: {
				type: Boolean,
				default: false,
			},
		},
		mounted() {
			if (this.isHtml) {
				this.showSlot = true
			}
		},
		data() {
			return {
				showSlot: false,
			}
		},
		methods: {
			doneTyping() {
				this.$emit('doneTyping')
				setTimeout(() => {
					this.showSlot = true
				}, 100)
			},
		},
	}
</script>

<style lang="scss" scoped>
	.content-text {
		// white-space: pre-wrap;
		white-space: pre-line;
	}
</style>
