// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/images/stanbg.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".backdrop[data-v-0ce2d201]{height:100vh;min-width:100vw;overflow:scroll}@supports(height: 100dvh){.backdrop[data-v-0ce2d201]{height:100dvh}}.backdrop[data-v-0ce2d201]:after{content:\"\";background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:100%;height:100%;position:absolute;pointer-events:none;left:0;top:0;z-index:-1;background-repeat:no-repeat;background-size:100% 100%}", "",{"version":3,"sources":["webpack://src/components/layouts/AuthContainer.vue"],"names":[],"mappings":"AAAA,2BAA2B,YAAY,CAAC,eAAe,CAAC,eAAe,CAAC,0BAA0B,2BAA2B,aAAa,CAAC,CAAC,iCAAiC,UAAU,CAAC,kDAA0C,CAAC,UAAU,CAAC,WAAW,CAAC,iBAAiB,CAAC,mBAAmB,CAAC,MAAM,CAAC,KAAK,CAAC,UAAU,CAAC,2BAA2B,CAAC,yBAAyB","sourcesContent":[".backdrop[data-v-0ce2d201]{height:100vh;min-width:100vw;overflow:scroll}@supports(height: 100dvh){.backdrop[data-v-0ce2d201]{height:100dvh}}.backdrop[data-v-0ce2d201]:after{content:\"\";background:url(~/assets/images/stanbg.svg);width:100%;height:100%;position:absolute;pointer-events:none;left:0;top:0;z-index:-1;background-repeat:no-repeat;background-size:100% 100%}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
