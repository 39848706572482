<template>
	<AppModal
		id="grid-switch-warning-modal"
		title="Heads up!"
		subheading="Unfortunately, Email Magnets cannot yet be placed inside of Grid sections."
		action="OK"
		@cta="closeModal"
		@close="closeModal"
	>
	</AppModal>
</template>
<script>
	export default {
		methods: {
			closeModal() {
				$('#grid-switch-warning-modal').modal('hide')
				this.$emit('close-modal')
			},
		},
	}
</script>
