var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      ref: "insert-video-modal",
      attrs: {
        id: "insert-video-modal",
        title: "Add a Video",
        "class-name": "block-modal fade",
        "close-button": false,
        backdrop: false
      }
    },
    [
      _c(
        "div",
        { staticClass: "d-flex-column justify-content-left col-sm mb-3 mt-3" },
        [
          _c("AppInput", {
            staticClass: "form-group",
            attrs: {
              error: _vm.errorText,
              icon: "/images/icons/ic-link.svg",
              placeholder: "Paste a YouTube, Loom, or Wistia video link here..."
            },
            on: {
              keydown: function($event) {
                _vm.errorText = []
              }
            },
            model: {
              value: _vm.link.href,
              callback: function($$v) {
                _vm.$set(
                  _vm.link,
                  "href",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "link.href"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-column gap v-gap mt-3 pt-1 col-sm" },
        [
          _c(
            "AppButton",
            {
              attrs: {
                name: "cta-button-modal-insert-video-modal",
                disabled: !_vm.link.href
              },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v("Embed Video")]
          ),
          _vm._v(" "),
          _c(
            "AppButton",
            {
              attrs: {
                name: "secondary-cta-button-modal-insert-video-modal",
                outline: "",
                icon: "/images/icons/ic-upload.svg"
              },
              on: { click: _vm.uploadVideo }
            },
            [_vm._v("Upload Your Own\n\t\t")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }