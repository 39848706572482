<template>
	<div
		class="app-floating-button square"
		:disabled="disabled"
		@click="handleClick"
		:class="{ 'show-border': showBorder, 'show-background': showBackground }"
	>
		<div class="button-content">
			<div v-if="icon" class="button-icon">
				<InlineSvg class="icon-svg" :src="icon" />
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'AppButton',
		props: {
			disabled: { type: Boolean, default: false },
			showBorder: { type: Boolean, default: false },
			showBackground: { type: Boolean, default: false },
			icon: { type: String, default: '' },
			color: {
				type: String,
				default: 'primary',
				validator: value => ['primary', 'dark', 'warning', 'danger', 'gray', 'light'].includes(value),
			},
		},
		methods: {
			handleClick() {
				if (!this.disabled && !this.loading) {
					this.$emit('click')
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.app-floating-button {
		position: relative;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		border-radius: 3px;
		border: none;
		background-color: transparent;
		color: var(--stan-white);
		cursor: pointer;
		z-index: 10;

		&.square {
			width: 20px;
			height: 20px;
			max-height: 20px;
			max-width: 20px;
		}

		&.show-border {
			border: 1px solid var(--stan-gray-dark-color);
		}

		&.show-background {
			background-color: var(--stan-gray-soft-color);
		}

		&:hover {
			background-color: var(--stan-gray-soft-color);
		}

		.button-content {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;

			.button-icon {
				transform: scale(0.75);
				aspect-ratio: 1;

				::v-deep {
					.icon-svg {
						path {
							fill: var(--stan-gray-dark-color);
							stroke: var(--stan-gray-dark-color);
						}
					}
				}
			}
		}
	}
</style>
