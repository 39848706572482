export default {
	data() {
		return {
			fileUploaders: [],
		}
	},
	computed: {
		isUploading() {
			return this.fileUploaders.length > 0
		},
	},
	methods: {
		uploadStarted(uploaderName = 'default') {
			const uploaderAlreadyStarted = this.fileUploaders.indexOf(uploaderName) > -1
			if (!uploaderAlreadyStarted) {
				this.fileUploaders.push(uploaderName)
			}
		},
		uploadCompleted(uploaderName = 'default') {
			const i = this.fileUploaders.indexOf(uploaderName)
			if (i > -1) {
				this.fileUploaders.splice(i, 1)
			}
		},
	},
}
