<template>
	<AppModal
		id="booking-block-time-modal"
		@close="closeModal"
		:action="actionText"
		:title="title"
		:subheading="subheading"
		@cta="nextStep"
		:actionColor="primaryActionColor"
		:secondary-action="secondaryActionText"
		@secondary-cta="secondaryNextStep"
		:secondaryActionColor="secondaryActionColor"
		:overflow="true"
		:loading="loading"
		:transitionKey="transitionKey"
		:hideDataBackdrop="hideDataBackdrop"
	>
		<div class="pt-4 pb-4" :key="resetKey">
			<template v-if="showExistingView || showDeleteView">
				<div class="d-flex w-100 justify-content-center align-items-center para-3 existing-date-container">
					<div class="flex-50 d-flex justify-content-end">
						<div class="existing-date-wrapper">
							<label class="control-label">Starting</label>

							<div class="date">
								<InlineSvg src="/images/calendar-input-icon.svg" class="mr-2" />{{ formatExistingDate(startDate, endDate) }}
							</div>
							<div class="time"><InlineSvg src="/images/balance-time.svg" class="mr-2" />{{ formatTime(startTimeValue) }}</div>
						</div>
					</div>
					<div class="divider"></div>
					<div class="flex-50 d-flex justify-content-start">
						<div class="existing-date-wrapper">
							<label class="control-label">Ending</label>
							<div class="date">
								<InlineSvg src="/images/calendar-input-icon.svg" class="mr-2" />{{ formatExistingDate(endDate, startDate) }}
							</div>
							<div class="time"><InlineSvg src="/images/balance-time.svg" class="mr-2" />{{ formatTime(endTimeValue) }}</div>
						</div>
					</div>
				</div>
			</template>
			<template v-if="showUpdateCreateView">
				<div class="slot-section d-flex flex-wrap gap w-100 justify-content-start align-items-start ">
					<div class="flex-50">
						<label class="control-label mb-1">From</label>
						<AppFlatPickr
							ref="calendar"
							custom_class="form-control"
							placeholder="Start Date"
							v-model="startDate"
							:disableMobile="true"
							:min_date="minDateStart"
							@onDateSelect="onDateTimeSelected"
						/>
						<div class="para-3" style="min-width: 85px;">
							<span style="color:var(--stan-text-negative-color)" v-if="errors.startDate">{{ errors.startDate }}</span>
						</div>
						<div class="datetime-dropdown time mt-3">
							<img src="/images/balance-time.svg" class="input-icon mr-2" />
							<AppDropdown
								class="form-control"
								placeholder="Start Time"
								:options="startTimeOptions"
								:searchable="false"
								label="name"
								reduce="value"
								:rightAlign="false"
								:disabled="startTimeOptions.length < 1"
								v-model="startTimeValue"
								@select="onDateTimeSelected"
							/>
						</div>
						<div class="para-3" style="min-width: 85px;">
							<span style="color:var(--stan-text-negative-color)" v-if="errors.startTimeValue">{{ errors.startTimeValue }}</span>
						</div>
					</div>
					<div class="flex-50">
						<label class="control-label mb-1">To</label>
						<AppFlatPickr
							ref="calendar"
							custom_class="form-control"
							placeholder="End Date"
							v-model="endDate"
							:disableMobile="true"
							:min_date="minDateEnd"
							@onDateSelect="onDateTimeSelected"
						/>
						<div class="para-3" style="min-width: 85px;">
							<span style="color:var(--stan-text-negative-color)" v-if="errors.endDate">{{ errors.endDate }}</span>
						</div>
						<div class="datetime-dropdown time mt-3">
							<img src="/images/balance-time.svg" class="input-icon mr-2" />
							<AppDropdown
								class="form-control"
								placeholder="End Time"
								:options="endTimeOptions"
								:searchable="false"
								label="name"
								reduce="value"
								:rightAlign="false"
								:disabled="endTimeOptions.length < 1"
								v-model="endTimeValue"
								@select="onDateTimeSelected"
							/>
						</div>
						<div class="para-3" style="min-width: 85px;">
							<span style="color:var(--stan-text-negative-color)" v-if="errors.endTimeValue">{{ errors.endTimeValue }}</span>
						</div>
					</div>
				</div>
			</template>
		</div>
	</AppModal>
</template>

<script>
	import { format, parse, parseISO, addMinutes, setSeconds, setMilliseconds, isSameDay } from 'date-fns'
	import { getTimesValue, getTimesName, getTimesAPIValue, timesArray } from '~/pages/stores/components/BaseDefaults.js'

	const STATE = {
		EXISTING: 'EXISTING',
		UPDATE: 'UPDATE',
		CREATE: 'CREATE',
		DELETE: 'DELETE',
	}

	export default {
		props: {
			id: { type: Number },
			start: { type: Date },
			end: { type: Date },
			hideDataBackdrop: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				loading: false,
				date: undefined,
				reason: '',
				availability: {},
				errors: {
					startDate: undefined,
					startTimeValue: undefined,
					endDate: undefined,
					endTimeValue: undefined,
				},
				resetKey: 0,
				transitionKey: '', // Used to smoothly transition between modal states
				state: STATE.CREATE,
				startDate: undefined,
				endDate: undefined,
				startTimeValue: undefined,
				endTimeValue: undefined,
			}
		},
		mounted() {
			this.reset()
		},
		computed: {
			title() {
				if (this.showDeleteView) {
					return 'Just Confirming!'
				}

				return 'Block Time'
			},
			subheading() {
				if (this.showUpdateCreateView) {
					return 'Blocking time will result in your calendar being unavailable to book at that specific time.'
				}
				if (this.showDeleteView) {
					return 'If you delete this block, your calendar will become available during this time.'
				}
				return ''
			},
			actionText() {
				if (this.showExistingView) {
					return 'Edit'
				}
				if (this.showDeleteView) {
					return 'Yes, Remove Time Block'
				}

				return 'Save'
			},
			primaryActionColor() {
				if (this.showDeleteView) {
					return 'danger'
				}
				return 'primary'
			},
			secondaryActionText() {
				if (this.showExistingView) {
					return 'Delete'
				}
				if (this.showDeleteView) {
					return 'Never Mind'
				}

				return 'Cancel'
			},
			secondaryActionColor() {
				if (this.showExistingView) {
					return 'danger'
				}

				return 'light'
			},
			showExistingView() {
				return this.state === STATE.EXISTING
			},
			showUpdateCreateView() {
				return this.state === STATE.UPDATE || this.state === STATE.CREATE
			},
			showDeleteView() {
				return this.state === STATE.DELETE
			},
			minDateStart() {
				// We want to make sure the date contains no hours, minutes, seconds. To allow the auto loading
				return format(new Date(), this.$constants.DATE_FORMAT.ISO_8601_DATE)
			},
			minDateEnd() {
				if (this.startDate) {
					return this.startDate
				}
				return this.minDateStart
			},
			startTimeOptions() {
				if (this.startDate) {
					const date = parseISO(this.startDate)
					const slotTime = this.getNextDateOnThe15(new Date())
					const timeValue = getTimesValue(format(slotTime, 'hh:mm a'))
					return this.getDateTimeOptions(date, new Date(), timeValue, true)
				}
				return []
			},
			endTimeOptions() {
				if (this.endDate) {
					const date = parseISO(this.endDate)
					const startDate = parseISO(this.startDate)
					const timeValue = this.startTimeValue || 0
					return this.getDateTimeOptions(date, startDate, timeValue, false)
				}
				return []
			},
		},
		methods: {
			async nextStep() {
				if (this.showExistingView) {
					this.state = STATE.CREATE
					this.transitionKey++
				} else if (this.showUpdateCreateView) {
					this.state = STATE.CREATE
					const isValid = this.validate()
					if (isValid) {
						await this.saveUpdateBlockedSlot()
						this.transitionKey++
					}
				} else if (this.showDeleteView && this.id) {
					await this.deleteBlockedSlot()
					this.transitionKey++
				}
			},
			async secondaryNextStep() {
				if (this.state === STATE.EXISTING) {
					this.state = STATE.DELETE
				} else {
					this.closeModal()
				}
				this.transitionKey++
			},
			formatExistingDate(toFormatDate, otherDate) {
				const date = parseISO(toFormatDate)
				if (date.getFullYear() === parseISO(otherDate).getFullYear()) {
					return format(date, 'EEEE, MMM d, yyyy')
				}
				return format(date, 'EEEE, MMM d')
			},
			formatTime(timeValue) {
				return getTimesName(timeValue)
			},
			validate() {
				let valid = true
				if (!this.startDate) {
					this.errors.startDate = 'Please select a start date'
					valid = false
				}

				if (this.startTimeValue === undefined || this.startTimeValue === null) {
					this.errors.startTimeValue = 'Please select a start time'
					valid = false
				}

				if (!this.endDate) {
					this.errors.endDate = 'Please select a end date'
					valid = false
				}

				if (this.endTimeValue === undefined || this.endTimeValue === null) {
					this.errors.endTimeValue = 'Please select a end time'
					valid = false
				}

				return valid
			},
			async onDateTimeSelected() {
				await this.$nextTick()

				if (this.errors.startDate && this.startDate) {
					this.errors.startDate = undefined
				}

				if (this.errors.startTimeValue && this.startTimeValue) {
					this.errors.startTimeValue = undefined
				}

				if (this.errors.endDate && this.endDate) {
					this.errors.endDate = undefined
				}

				if (this.errors.endTimeValue && this.endTimeValue) {
					this.errors.endTimeValue = undefined
				}

				if (this.startDate && this.endDate && parseISO(this.startDate) > parseISO(this.endDate)) {
					this.endDate = undefined
				}

				// Only important if same day
				if (
					this.startTimeValue &&
					this.endTimeValue &&
					parseISO(this.startDate) === parseISO(this.endDate) &&
					this.startTimeValue >= this.endTimeValue
				) {
					this.endTimeValue = undefined
				}
			},
			async saveUpdateBlockedSlot() {
				try {
					this.loading = true
					const dateFormat = this.$constants.DATE_FORMAT.DATE_TIME_FORMAT_AM_PM
					const startDate = parse(`${this.startDate} ${getTimesAPIValue(this.startTimeValue)}`, dateFormat, new Date())
					const endDate = parse(`${this.endDate} ${getTimesAPIValue(this.endTimeValue)}`, dateFormat, new Date())

					const payload = {
						start: startDate,
						end: endDate,
					}

					if (this.id) {
						payload.id = this.id
					}

					const resp = await this.$axios.post(`/v1/booking/blocked-time`, payload)

					if (resp.status === 200) {
						this.loading = false
						this.$emit('updated')
						this.closeModal()

						this.$stanNotify({
							type: 'success', // can be 'info', 'warning', 'success', 'error'
							title: 'Success',
							text: `Time has been blocked off successfully.`,
							duration: 10000,
						})

						return true
					}
				} catch (err) {
					this.$logError(err)
				}
				this.$stanNotify({
					type: 'error',
					title: this.$t('Something went wrong'),
					text: this.$t('Please contact us at friends@stanwith.me'),
					duration: 5000,
				})
				this.closeModal()
				return false
			},
			async deleteBlockedSlot() {
				try {
					this.loading = true
					const resp = await this.$axios.delete(`/v1/booking/blocked-time/${this.id}`)

					if (resp.status === 200) {
						this.loading = false
						this.$emit('updated')
						this.closeModal()
						return true
					}
				} catch (err) {
					this.$logError(err)
				}
				this.$stanNotify({
					type: 'error',
					title: this.$t('Something went wrong'),
					text: this.$t('Please contact us at friends@stanwith.me'),
					duration: 5000,
				})
				this.closeModal()
				return false
			},

			reset() {
				this.loading = false
				this.errors = {
					startDate: undefined,
					startTimeValue: undefined,
					endDate: undefined,
					endTimeValue: undefined,
				}
				this.state = STATE.CREATE
				this.startDate = undefined
				this.endDate = undefined
				this.startTimeValue = undefined
				this.endTimeValue = undefined

				if (this.id !== undefined && this.id > 0) {
					this.state = STATE.EXISTING
				}
				if (this.start) {
					this.startDate = format(this.start, this.$constants.DATE_FORMAT.ISO_8601_DATE)
					this.startTimeValue = getTimesValue(format(this.start, 'hh:mm a'))
				}

				if (this.end) {
					this.endDate = format(this.end, this.$constants.DATE_FORMAT.ISO_8601_DATE)
					this.endTimeValue = getTimesValue(format(this.end, 'hh:mm a'))
				}

				this.resetKey++
			},
			getDateTimeOptions(date, compareDate, timeValue, inclusive) {
				let allTimeslots = timesArray
				if (isSameDay(date, compareDate) && timeValue !== undefined) {
					allTimeslots = allTimeslots.filter(s => (inclusive ? s.value >= timeValue : s.value > timeValue))
				}
				return allTimeslots
			},
			getNextDateOnThe15(date) {
				const minutes = date.getMinutes()
				const addMin = 15 - (minutes % 15)
				const roundedDate = addMinutes(date, addMin !== 15 ? addMin : 0)
				const nextDate = setSeconds(setMilliseconds(roundedDate, 0), 0)
				return nextDate
			},
			showModal() {
				$('#booking-block-time-modal').modal('show')
				this.$nextTick(() => {
					this.reset()
				})
			},
			closeModal() {
				$('#booking-block-time-modal').modal('hide')
				this.$emit('close')
				const self = this
				setTimeout(() => {
					self.reset()
				}, 100)
			},
		},
	}
</script>

<style lang="scss" scoped>
	.existing-date-container {
		gap: 30px;
		.existing-date-wrapper {
			min-width: min(140px, 90vw);

			.control-label {
				margin-bottom: 7px;
				color: var(--stan-text-light-color);
				font-size: 10px;
			}

			svg {
				margin: 0;
				height: 16px;
				width: 16px;
			}

			.date {
				margin-bottom: 10px;
				svg::v-deep {
					path:not(.no-fill) {
						stroke: var(--stan-text-dark-color);
					}
				}
			}

			.time {
				color: var(--stan-text-light-color);
				svg::v-deep {
					path:not(.no-fill) {
						fill: var(--stan-text-light-color);
					}
				}
			}
		}

		.divider {
			width: 1px;
			height: 55px;
			background: var(--stan-gray-primary-color);
		}
	}
	.form-style-text {
		display: flex;
		padding-left: 32px;
		align-items: center;
	}
	.flex-50 {
		flex: 1 1 0;
	}
	.datetime-dropdown {
		&::after {
			margin-left: -20px;
			margin-right: 20px;
			pointer-events: none;
		}
		&.time {
			position: relative;
			input {
				padding-left: 32px;
			}
			.form-control {
				padding-left: 32px;
			}
			.input-icon {
				z-index: 10;
				top: 50%;
				margin: 0;
				left: 9px;
				transform: translateY(-50%);
				position: absolute;
				pointer-events: none;
				height: 16px;
				width: 16px;
			}
			&::after {
				position: absolute;
				right: 10px;
				pointer-events: none;
				top: 50%;
				transform: translateY(-50%);
				margin: 0;
			}
		}
	}
</style>
