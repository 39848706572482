<template>
	<div class="main pt-3 pb-3" :class="classOverride">
		<Transition name="fade" mode="out-in">
			<AppFormQuestion
				:key="questions[currentQuestionIndex].questionId"
				:questionId="questions[currentQuestionIndex].questionId"
				:questionText="questions[currentQuestionIndex].questionText"
				:options="randomizedOptions"
				:type="questions[currentQuestionIndex].type"
				:required="questions[currentQuestionIndex].required"
				@next="next"
			/>
		</Transition>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import { UUID } from '~/store/modules/Global.js'

	/* Question data object example
			questionId: 'test1',
			questionText: 'How are you?',
			nextQuestionId: <> // used for all questions types but single select
			required: true // Weather we force the user to answer the question to proceed
			options: [
				{
					displayName: 'Yes - I already have a product!',
					dbMap: 'Yes',
					nextQuestionId: 'end', // Will jump to the `end` question id
				},
				{
					displayName: 'No - I need help creating a product!',
					dbMap: 'No',
					// Will go to the next question in the array
				},
			],
			type: 'select',
		*/

	export default {
		props: {
			classOverride: {
				type: String,
				default: '',
			},
			formId: {
				type: String,
				default: '',
			},
			formName: {
				type: String,
				default: '',
			},
			responseId: {
				type: String,
				default: UUID(),
			},
			questions: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				currentQuestionIndex: 0,
				completed: false,
				response: {},
			}
		},
		mounted() {
			// Track if someone navigates away from stan
			this.beforeUnloadListener = () => {
				if (!this.completed) this.sendAnalytic('left_page')
			}
			window.addEventListener('beforeunload', this.beforeUnloadListener)
			this.sendAnalytic('started')
		},
		beforeDestroy() {
			if (!this.completed) this.sendAnalytic('exited')
			window.removeEventListener('beforeunload', this.beforeUnloadListener)
		},
		computed: {
			...mapGetters('Auth', ['user']),
			nextQuestionIndexMap() {
				const questionIdToIndex = new Map()
				this.questions.forEach((question, index) => questionIdToIndex.set(question.questionId, index))

				// Map questionIds to index
				const questionMap = new Map()
				this.questions.forEach((question, questionIndex) => {
					const defaultNextQuestionIndex = questionIndex + 1 < this.questions.length ? questionIndex + 1 : null

					if (question.type === 'select') {
						question.options.forEach(option => {
							const nextQuestionIndex =
								'nextQuestionId' in option ? questionIdToIndex.get(option.nextQuestionId) : defaultNextQuestionIndex
							questionMap.set(this.createQuestionKey(question, option.dbMap), nextQuestionIndex)
						})
					} else {
						const nextQuestionIndex =
							'nextQuestionId' in question ? questionIdToIndex.get(question.nextQuestionId) : defaultNextQuestionIndex
						questionMap.set(this.createQuestionKey(question), nextQuestionIndex)
					}
				})

				return questionMap
			},
			randomizedOptions() {
				const otherOption = [this.questions[this.currentQuestionIndex].options.find(o => (o?.dbMap || '').toLowerCase() === 'other')]
				const shuffledOptions = [...this.questions[this.currentQuestionIndex].options]
					.filter(o => (o?.dbMap || '').toLowerCase() !== 'other')
					.sort(() => Math.random() - 0.5)
				return [...shuffledOptions, ...otherOption]
			},
		},
		methods: {
			createQuestionKey(question, optionDbMap = '') {
				if (question.type === 'select') {
					return `${this.formId}-${question.questionId}-${optionDbMap}`
				}
				return `${this.formId}-${question.questionId}`
			},
			next(answer = [], other) {
				// Save response go to next question
				const currentQuestion = this.questions[this.currentQuestionIndex]
				this.response[currentQuestion.questionId] = answer

				this.sendAnalytic('responded', {
					answer,
					other,
				})
				this.$emit('questionAnswered', {
					questionId: currentQuestion.questionId,
					answer,
				})

				// Only used by select questions
				const optionDbMap = answer.length > 0 ? answer[0] : ''
				const questionKey = this.createQuestionKey(currentQuestion, optionDbMap)
				const nextQuestionId = this.nextQuestionIndexMap.get(questionKey)

				if (nextQuestionId == null) {
					this.completed = true
					this.sendAnalytic('completed')
					this.$emit('completed', this.response)
				} else {
					this.currentQuestionIndex = nextQuestionId
					this.sendAnalytic('started')
				}
			},
			sendAnalytic(state, props = {}) {
				const currentQuestion = this.questions[this.currentQuestionIndex]
				const data = {
					meta: {
						user_id: this.user.user_id,
						username: this.user.username,
					},
					props: {
						...props,
						state,
						formId: this.formId,
						formName: this.formName,
						responseId: this.responseId,
						questionId: currentQuestion.questionId,
						questionText: currentQuestion.questionText,
						questionOptions: currentQuestion.options,
						questionType: currentQuestion.type,
					},
				}

				this.$stanAnalytics(this.formId, data)
			},
		},
	}
</script>

<style lang="scss" scoped>
	.main {
		max-height: 100vh;
		@supports (height: 100dvh) {
			max-height: 100dvh;
		}
		overflow-y: scroll;

		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* FireFox */

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.app-tabs {
		@media (max-width: 575px) {
			margin-left: -15px;
			margin-right: -15px;
		}
	}
</style>
