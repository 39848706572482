<template>
	<div class="container mobile-container">
		<div class="body flex-column flex-grow-1 col-12 col-md-5 px-0 h-100">
			<div>
				<h3 class="modal-heading">How's the cancellation process?</h3>
			</div>

			<!-- Choose Smileys -->
			<div class="row align-items-start justify-content-center d-md-flex pb-4 mb-2">
				<div
					class="emoji p-1 cursor-pointer "
					:class="{ grayscale: satisfaction && satisfaction != 'bad' }"
					@click="selectSatisfaction('bad')"
				>
					<h1 style="font-size: 60px; line-height: 60px;">🙁</h1>
				</div>
				<div
					class="emoji p-1 cursor-pointer "
					:class="{ grayscale: satisfaction && satisfaction != 'okay' }"
					@click="selectSatisfaction('okay')"
				>
					<h1 style="font-size: 60px; line-height: 60px;">😐</h1>
				</div>
				<div
					class="emoji p-1 cursor-pointer "
					:class="{ grayscale: satisfaction && satisfaction != 'good' }"
					@click="selectSatisfaction('good')"
				>
					<h1 style="font-size: 60px; line-height: 60px;">🙂</h1>
				</div>
			</div>
			<!-- General Feedback -->
			<div class="py-0 mb-3" style="width:min(395px, 100%);">
				<div>
					<!-- update to match questionaire -->
					<textarea
						class="form-control para-2 styled-input"
						rows="4"
						name="feedback"
						v-model="feedback"
						placeholder="Is there anything that stood out?"
					/>
				</div>
				<small class="stan-text-danger error" v-if="requiredError">
					Please select a rating
				</small>
			</div>
			<!-- Make full width to match rest of flow -->
			<AppButton name="save-cancellation-flow-survey" size="large" @click="nextStep">Share your feedback</AppButton>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				stepName: 'cancellation flow survey',
				satisfaction: null,
				feedback: '',
				requiredError: false,
			}
		},
		mounted() {
			this.start()
		},
		methods: {
			start() {
				this.$emit('start', { eventName: 'cancellation-flow-survey', props: { step_name: this.stepName } })
			},
			selectSatisfaction(value) {
				this.requiredError = false
				this.satisfaction = value
			},
			nextStep() {
				if (!this.satisfaction) {
					this.requiredError = true
					return
				}
				this.$emit('log-analytics', {
					eventName: 'cancellation-flow-survey-rating',
					props: { step_name: this.stepName, value: this.satisfaction },
				})

				this.$emit('log-analytics', {
					eventName: 'cancellation-flow-survey-feedback',
					props: { step_name: this.stepName, value: this.feedback },
				})
				this.$emit('next-step')
			},
			reset() {
				this.satisfaction = null
				this.feedback = ''
				this.requiredError = false
			},
		},
	}
</script>

<style lang="scss" scoped>
	.styled-input {
		padding: 15px;
	}
	.emoji {
		-webkit-transition: -webkit-filter 100ms linear;
	}
	.emoji:hover {
		filter: opacity(0.7);
	}
	.grayscale {
		filter: brightness(0.75);
	}
</style>
