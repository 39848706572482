import axios from 'axios'
import { format, parseISO, addDays } from 'date-fns'
import CONSTANTS from '~/global_helper/constants.js'
import helpers from '~/global_helper/helpers.js'

function parseRawEvents(date, event) {
	const out = {}
	if (event?.booking_id) {
		out.booking_id = Number(event.booking_id)
	}
	if (event?.id) {
		out.id = Number(event.id)
	}
	if (event?.type) {
		out.type = event.type
	}
	if (event?.title) {
		out.title = event.title
	}
	if (event?.booking_status !== undefined) {
		out.status = event.booking_status
	}
	if (event?.booking_cal_ref_id) {
		out.cal_ref_id = event.booking_cal_ref_id
	}
	if (event?.cal_ref_id) {
		out.cal_ref_id = event.cal_ref_id
	}

	out.start_utc = new Date(event.start_utc)
	out.end_utc = new Date(event.end_utc)

	const tomorrowDate = addDays(date, 1)
	if (out.start_utc < date && out.end_utc < tomorrowDate) {
		// Is ending today
		out.displayTime = `To ${helpers.displayTimeFormat(out.end_utc)}`
		out.displayTimeFull = `${helpers.displayTimeFormat(date)} - ${helpers.displayTimeFormat(out.end_utc)}`
	} else if (out.start_utc <= date && out.end_utc >= tomorrowDate) {
		// If this event is then entire day
		out.displayTime = `All Day`
		out.displayTimeFull = `All Day`
	} else {
		out.displayTime = helpers.displayTimeFormat(out.start_utc)
		out.displayTimeFull = `${helpers.displayTimeFormat(out.start_utc)} - ${helpers.displayTimeFormat(out.end_utc)}`
	}

	return out
}

function addEventSpreadOverMultipleDays(event, eventsMap) {
	// Check and show events over multiple days if needed
	const durationDays = Math.ceil((parseISO(event.end_utc).getTime() - parseISO(event.start_utc).getTime()) / (24 * 60 * 60 * 1000))

	// Ensure we have no hours / minutes offset
	let date = parseISO(format(parseISO(event.start_utc), CONSTANTS.DATE_FORMAT.ISO_8601_DATE))
	for (let i = 0; i < durationDays; i++) {
		const dateKey = format(date, CONSTANTS.DATE_FORMAT.ISO_8601_DATE)
		const eventsList = eventsMap.has(dateKey) ? eventsMap.get(dateKey) : []
		eventsList.push(parseRawEvents(date, event))
		eventsMap.set(dateKey, eventsList)
		date = addDays(date, 1)
	}
}

function calculateEventsMapHelper(bookings, blockedEvents) {
	const eventsMap = new Map()

	const bookingsArrayUse = bookings?.data ? bookings.data : []
	bookingsArrayUse.forEach(booking => {
		addEventSpreadOverMultipleDays(booking, eventsMap)
	})

	const blockedEventssArrayUse = blockedEvents?.data ? blockedEvents.data : []
	blockedEventssArrayUse.forEach(blockedTimeEvent => {
		const dateKey = format(parseISO(blockedTimeEvent.start_utc), CONSTANTS.DATE_FORMAT.ISO_8601_DATE)
		const eventsList = eventsMap.has(dateKey) ? eventsMap.get(dateKey) : []

		// Filter out blocked events created by stan calendar
		const curCalEventRefId = blockedTimeEvent?.cal_ref_id
		const stanEventIndex = eventsList.findIndex(e => e?.cal_ref_id === curCalEventRefId)
		if (stanEventIndex === -1) {
			addEventSpreadOverMultipleDays(blockedTimeEvent, eventsMap)
		}
	})

	// Sort
	eventsMap.forEach((events, dateKey) => {
		eventsMap.set(
			dateKey,
			events.sort((a, b) => a.start_utc - b.start_utc)
		)
	})

	return eventsMap
}

export const state = {
	bookings: [],
	bookingsRefreshing: false,
	blockedTimes: [],
	blockedTimesRefreshing: false,
	eventsMap: new Map(),
	eventsMapRefreshing: false,
	referenceDate: format(new Date(), CONSTANTS.DATE_FORMAT.ISO_8601_DATE),
}

export const getters = {
	bookings: state => state.bookings,
	getBookingsRefreshing: state => state.bookingsRefreshing,
	blockedTimes: state => state.bookings,
	getBlockedTimesRefreshing: state => state.blockedTimesRefreshing,
	eventsMap: state => state.eventsMap,
	getEventsMapRefreshing: state => state.eventsMapRefreshing,
	referenceDate: state => state.referenceDate,
}

export const mutations = {
	setBookings(state, payload) {
		state.bookings = payload
	},
	setBookingsRefreshing(state, flag) {
		state.bookingsRefreshing = flag
	},
	setBlockedTimes(state, payload) {
		state.blockedTimes = payload
	},
	setBlockedTimesRefreshing(state, payload) {
		state.blockedTimesRefreshing = payload
	},
	setEventsMapRefreshing(state, payload) {
		state.eventsMapRefreshing = payload
	},
	setReferenceDate(state, newDate) {
		state.referenceDate = newDate
	},
	calculateEventsMapHelper(state) {
		state.eventsMap = calculateEventsMapHelper(state.bookings, state.blockedTimes)
	},
}

export const actions = {
	fetchBookings: ({ commit }, payload) => {
		axios
			.get('v1/booking', {
				params: {
					page: payload.page || null,
					pageSize: payload.pageSize || null,
					bookingId: payload.bookingId || null,
					tense: payload.tense || null,
					keyword: payload.keyword,
					timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					filter_array: JSON.stringify(payload.filterArray),
				},
			})
			.then(response => {
				commit('setBookings', response.data)
				commit('setBookingsRefreshing', false)
			})
			.catch(error => {
				console.error(error)
				commit('setBookingsRefreshing', false)
			})
	},
	fetchBlockedTimes: ({ commit }, { start, end }) => {
		axios
			.get('v1/booking/blocked-time', {
				params: {
					timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					start,
					end,
				},
			})
			.then(response => {
				commit('setBlockedTimes', response.data)
				commit('setBlockedTimesRefreshing', false)
			})
			.catch(error => {
				console.error(error)
				commit('setBlockedTimesRefreshing', false)
			})
	},
}
