var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overflow-visible" }, [
    _c("div", { staticClass: "stan-notification", class: _vm.notifyClass }, [
      _c(
        "div",
        { staticClass: "stan-icon", class: _vm.notifyClass },
        [
          _c("InlineSvg", { attrs: { src: `/images/icons/${_vm.notifyIcon}` } })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "notify-content" }, [
        _c("span", { staticClass: "para-2 text-bold notify-content-title" }, [
          _vm._v("\n\t\t\t\t" + _vm._s(_vm.data.title) + "\n\t\t\t")
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "para-2 notify-content-text" }, [
          _vm._v("\n\t\t\t\t" + _vm._s(_vm.data.text) + "\n\t\t\t")
        ]),
        _vm._v(" "),
        _vm.notifyType === "fact"
          ? _c(
              "span",
              {
                staticClass: "para-2 notify-content-link",
                on: {
                  click: function($event) {
                    return _vm.interactWithFact()
                  }
                }
              },
              [_vm._v("\n\t\t\t\tView Analytics ➡️\n\t\t\t")]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", [
        _vm.notifyType !== "fact"
          ? _c(
              "button",
              {
                staticClass: "notify-close",
                class: _vm.notifyClass,
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_vm._v("\n\t\t\t\tOK\n\t\t\t")]
            )
          : _c(
              "button",
              {
                staticClass: "notify-close",
                class: _vm.notifyClass,
                on: {
                  click: function($event) {
                    return _vm.closeFact()
                  }
                }
              },
              [
                _c("InlineSvg", {
                  attrs: { src: `/images/icons/ic-close.svg` }
                })
              ],
              1
            )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }