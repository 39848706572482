var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "stanley-chat-message-container" }, [
    _c(
      "div",
      {
        staticClass: "row justify-content-start align-items-start message-box"
      },
      [
        _c("div", { staticClass: "col-auto pr-0" }, [
          _c("img", {
            staticClass: "mt-1 profile-image",
            attrs: { src: _vm.user.profile_image, width: "40px;" }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c("div", [_c("b", [_vm._v(_vm._s(_vm.user.full_name))])]),
          _vm._v(" "),
          _c("div", [_vm._t("default")], 2)
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }