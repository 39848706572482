var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "app-button button-wrap",
      class: [
        _vm.sizeClass,
        { rounded: _vm.rounded },
        { disabled: _vm.disabled },
        { outline: _vm.outline || _vm.link },
        { loading: _vm.loading },
        { "hide-outline": _vm.link },
        `button-color-${_vm.color}`,
        { "fit-content": _vm.fitContent }
      ],
      attrs: { name: _vm.name, "aria-label": _vm.name, disabled: _vm.disabled },
      on: { click: _vm.handleClick }
    },
    [
      _vm.loading
        ? _c("CustomHollowDotsSpinner", {
            staticClass: "loader",
            attrs: {
              "animation-duration": 1000,
              "dot-size": _vm.dotSize,
              color: _vm.loaderColor
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "button-content",
          class: [_vm.contentSizeClass, { hide: _vm.loading }]
        },
        [
          _vm.icon
            ? _c(
                "div",
                { staticClass: "button-icon" },
                [
                  _c("InlineSvg", {
                    staticClass: "icon-svg",
                    attrs: { src: _vm.icon }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }