var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppBanner",
    {
      staticClass: "w-100 position-relative overflow-hidden pop-animation",
      attrs: {
        title: _vm.title,
        icon: _vm.icon,
        rightIcon: null,
        severity: "stan-info"
      }
    },
    [
      _c("AppProgress", {
        staticClass: "position-absolute",
        staticStyle: {
          "max-height": "4px",
          bottom: "0",
          left: "0",
          right: "0"
        },
        attrs: {
          barColor: "var(--stan-primary-soft-color)",
          progressColor: "var(--stan-primary-primary-color)",
          value: _vm.progress
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }