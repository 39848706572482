<template>
	<div class="d-flex flex-nowrap">
		<AppTooltip v-if="tip.tip" class="d-inline" :click="tip.click" :hover="tip.hover" :tip="tip.tip" :html="tip.html" :direction="tip.direction">
			<div v-if="this.labelText" class="tag-container text-truncate mr-2" :class="{ 'w-100': fullWidth, round: rounded }" :style="style">
				<InlineSvg v-if="image" :src="image" :fill="svgFill" class="icon-image" /> {{ labelText }}
			</div>
		</AppTooltip>
		<div v-else-if="this.labelText" class="tag-container text-truncate mr-2" :class="{ 'w-100': fullWidth, round: rounded }" :style="style">
			<InlineSvg v-if="image" :src="image" :fill="svgFill" class="icon-image" /> {{ labelText }}
		</div>
	</div>
</template>
<script>
	export default {
		name: 'AppTag',
		props: {
			fullWidth: { type: Boolean, default: true },

			// use type, value and field if you want to use a preset
			type: { type: String, default: undefined },
			value: { type: [String, Number], default: undefined },
			field: { type: String, default: undefined },

			// if no preset is specified or a preset cannot be determined,
			// label, backgroundColor and textColor will be used
			label: { type: String, default: '' },
			backgroundColor: { type: String, default: 'white' },
			textColor: { type: String, default: 'black' },
			image: { type: String, default: undefined },
			toolTip: { type: [Object, String], default: undefined }, // {click: Boolean, hover: Boolean, tip: String, html: Boolean, direction: top/left/bottom/right}
			rounded: { type: Boolean, default: false },
			fontWeight: { type: Number, default: 600 },
			svgFill: { type: String },
		},
		data() {
			return {
				presets: {
					product: {
						lead: {
							label: 'Lead',
							backgroundColor: 'rgba(0, 132, 198, 0.1)',
							textColor: '#0084C6',
						},
						booking: {
							label: 'Meeting',
							backgroundColor: 'rgba(0, 198, 164, 0.1)',
							textColor: '#00C6A4',
						},
						'digital-download': {
							label: 'Digital Download',
							backgroundColor: 'rgba(248, 89, 24, 0.1)',
							textColor: 'var(--stan-orange-4)',
						},
						membership: {
							label: 'Membership',
							backgroundColor: 'rgba(158, 61, 255, 0.1)',
							textColor: '#9E3DFF',
						},
						course: {
							label: 'Course',
							backgroundColor: 'rgba(101, 116, 255, 0.1)',
							textColor: '#6574FF',
						},
						webinar: {
							label: 'Webinar',
							backgroundColor: 'rgba(95, 201, 203, 0.1)',
							textColor: 'rgba(95, 201, 203, 1)',
						},
						fulfillment: {
							label: 'Fulfillment',
							backgroundColor: 'rgba(95, 201, 203, 0.1)',
							textColor: 'rgba(95, 201, 203, 1)',
						},
						'affiliate-fee': {
							label: 'Affiliate',
							backgroundColor: 'var(--stan-gray-primary-color)',
							textColor: 'var(--stan-text-dark-color)',
						},
						affiliate: {
							label: 'Affiliate',
							backgroundColor: 'var(--stan-gray-primary-color)',
							textColor: 'var(--stan-text-dark-color)',
						},
						invoice: {
							label: 'Invoice',
							backgroundColor: 'var(--stan-gray-primary-color)',
							textColor: 'var(--stan-text-dark-color)',
						},
						community: {
							label: 'Community',
							backgroundColor: 'rgba(231, 242, 254, 1)', // #E7F2FE
							textColor: 'rgba(87, 131, 255, 1)', // #5783FF
						},
					},
					subscription: {
						totalSubscriptions: {
							label: 'Subscription',
							backgroundColor: 'rgba(58, 54, 219, 0.1)',
							textColor: '#3A36DB',
						},
					},
					membershipStatus: {
						0: {
							label: 'Cancelled',
							backgroundColor: 'rgba(225, 231, 245, 1)',
							textColor: '#48638C',
						},
						1: {
							label: 'Active',
							backgroundColor: 'rgba(0, 198, 164, 0.1)',
							textColor: '#00C6A4',
						},
						2: {
							label: 'Paused',
							backgroundColor: 'rgba(255, 247, 225, 1)',
							textColor: '#FFBB00',
						},
					},
					subscriptionStatus: {
						Active: {
							label: 'Active',
							backgroundColor: 'rgba(0, 198, 164, 0.1)',
							textColor: '#00C6A4',
						},
						Inactive: {
							label: 'Inactive',
							backgroundColor: 'rgba(248, 89, 24, 0.1)',
							textColor: '#F85918',
						},
					},
					courseProgressStatus: {
						'Not Started': {
							label: 'Not Started',
							backgroundColor: '#F3F6FD',
							textColor: '#8F9CB9',
						},
						'In Progress': {
							label: 'In Progress',
							backgroundColor: '#F3F6FD',
							textColor: '#6574FF',
						},
						Completed: {
							label: 'Completed',
							backgroundColor: '#DEFFFB',
							textColor: '#00C6A4',
						},
						Revoked: {
							label: 'Revoked',
							backgroundColor: '#FFDEE2',
							textColor: '#FF487E',
						},
					},
					invoiceStatus: {
						paid: {
							label: 'Paid',
							backgroundColor: 'rgba(0, 198, 164, 0.1)',
							textColor: '#00C6A4',
						},
						refunded: {
							label: 'Refunded',
							backgroundColor: 'rgba(0, 198, 164, 0.1)',
							textColor: '#00C6A4',
						},
						draft: {
							label: 'Draft',
							backgroundColor: 'rgba(248, 89, 24, 0.1)',
							textColor: '#F85918',
						},
						open: {
							label: 'Open',
							backgroundColor: 'rgba(248, 89, 24, 0.1)',
							textColor: '#F85918',
						},
						void: {
							label: 'Void',
							backgroundColor: 'rgba(248, 89, 24, 0.1)',
							textColor: '#F85918',
						},
						uncollectible: {
							label: 'Uncollectible',
							backgroundColor: 'rgba(248, 89, 24, 0.1)',
							textColor: '#F85918',
						},
					},
					emailRecipientStatus: {
						OPEN: {
							label: 'Opened',
							backgroundColor: '#EBEBFF',
							textColor: '#6355FF',
						},
						CLICK: {
							label: 'Clicked',
							backgroundColor: '#E4FFF9',
							textColor: '#01B88C',
						},
						SEND: {
							label: 'Sent',
							backgroundColor: '#EEF1F5',
							textColor: '#808EB6',
						},
						DELIVERY: {
							label: 'Sent',
							backgroundColor: '#EEF1F5',
							textColor: '#808EB6',
						},
						COMPLAINT: {
							label: 'Sent',
							backgroundColor: '#EEF1F5',
							textColor: '#808EB6',
						},
						BOUNCE: {
							label: 'Sent',
							backgroundColor: '#EEF1F5',
							textColor: '#808EB6',
						},
					},
					affiliateSellerStatus: {
						deleted: {
							label: 'Deleted',
							backgroundColor: 'rgba(225, 231, 245, 1)',
							textColor: '#48638C',
						},
						active: {
							label: 'Active',
							backgroundColor: 'rgba(0, 198, 164, 0.1)',
							textColor: '#00C6A4',
						},
						not_reseller: {
							label: 'Not Reseller',
							backgroundColor: 'rgba(255, 247, 225, 1)',
							textColor: '#FFBB00',
						},
					},
				},
			}
		},
		computed: {
			preset() {
				let val
				if (this.type === 'product' && this.value && this.presets[this.type]) {
					val = this.presets[this.type][this.value]
				} else if (this.type === 'subscription' && this.field && this.presets[this.type]) {
					val = this.presets[this.type][this.field]
					if (this.value > 0) {
						val.label = `${this.value} ${this.$t('Subscription')}${this.value > 1 ? 's' : ''}`
					} else {
						val.label = undefined
					}
				} else if (this.type === 'subscriptionStatus' && this.value && this.presets[this.type]) {
					val = this.presets[this.type][this.value]
				} else if (this.type === 'membershipStatus' && this.presets[this.type]) {
					val = this.presets[this.type][this.value]
				} else if (this.type === 'courseProgressStatus' && this.presets[this.type]) {
					val = this.presets[this.type][this.value]
				} else if (this.type === 'invoiceStatus' && this.value && this.presets[this.type]) {
					val = this.presets[this.type][this.value]
				} else if (this.type === 'emailRecipientStatus' && this.value && this.presets[this.type]) {
					val = this.presets[this.type][this.value]
				} else if (this.type === 'affiliateSellerStatus' && this.value && this.presets[this.type]) {
					val = this.presets[this.type][this.value]
				} else if (this.type === 'referrer' && this.value) {
					val = {
						label: this.value,
						backgroundColor: 'var(--stan-gray-primary-color)',
						textColor: 'var(--stan-text-dark-color)',
					}
				}

				return val
			},
			labelText() {
				if (this.preset) {
					return this.preset.label
				}
				return this.label
			},
			style() {
				if (this.preset) {
					return {
						background: this.preset.backgroundColor,
						color: this.preset.textColor,
						'font-weight': this.fontWeight,
					}
				}
				return {
					background: this.backgroundColor,
					color: this.textColor,
					'font-weight': this.fontWeight,
				}
			},
			tip() {
				if (typeof this.toolTip === 'object' && this.toolTip) {
					return {
						click: !!this.toolTip?.click,
						hover: !!this.toolTip?.hover,
						tip: this.toolTip?.tip,
						html: !!this.toolTip?.html,
						direction: this.toolTip?.direction,
					}
				}
				return {
					click: false,
					hover: true,
					tip: this.toolTip,
					html: false,
				}
			},
		},
	}
</script>

<style lang="scss" scoped>
	.tag-container {
		font-weight: 600;
		font-size: 12px;
		line-height: 24px;
		letter-spacing: 0.04em;
		padding: 0px 6px;
		height: 24px;
		border-radius: 4px;
		vertical-align: middle;
		width: min-content;
		&.round {
			border-radius: 10px;
			line-height: inherit;
			padding: 5px 8px;
			height: auto;
		}

		.icon-image {
			transform: translateY(-1px);
			max-height: 16px;
			max-width: 16px;
		}
	}
</style>
