<template>
	<AppModal
		:fade="false"
		ref="imageUploadOptionsModal"
		:title="$t('Choose an Image')"
		ariaLabelledby="modal-image-upload-options"
		@close="closeModal"
	>
		<div class="mx-3 mb-3">
			<AppButton name="unsplash-image-upload-option-modal" class="w-100 my-2" @click="selectOption('unsplash')">{{ $t('Unsplash') }}</AppButton>
			<AppButton name="manual-upload-image-upload-option-modal" outline class="w-100 my-2" @click="selectOption('upload')">{{
				$t('Upload')
			}}</AppButton>
		</div>
	</AppModal>
</template>
<script>
	export default {
		data() {
			return {}
		},
		computed: {
			modal() {
				return $(this.$refs.imageUploadOptionsModal.$el)
			},
		},
		methods: {
			init() {
				this.modal.modal('show')
			},
			selectOption(option) {
				this.closeModal()
				this.$emit('optionPicked', option)
			},
			closeModal() {
				this.modal.modal('hide')
				this.$emit('close-modal')
			},
		},
	}
</script>
