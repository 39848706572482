var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass: "d-flex align-items-center",
      attrs: { "aria-label": "breadcrumb transparent" }
    },
    [
      _c(
        "ol",
        { staticClass: "breadcrumb transparent mb-0 px-0 d-inline-flex" },
        _vm._l(_vm.breadcrumbs, function(item, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "breadcrumb-item h2 text-bold",
              class: { active: item.active },
              attrs: { "aria-current": "page" }
            },
            [
              item.to
                ? _c(
                    "a",
                    {
                      staticClass: "p-0 m-0",
                      on: {
                        click: function($event) {
                          return _vm.$emit(
                            "activeTab",
                            item.to,
                            item.query,
                            item.params
                          )
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t(item.title)))]
                  )
                : _c("span", [_vm._v(_vm._s(_vm.$t(item.title)))])
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }