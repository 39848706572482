var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-header" }, [
    _vm.image
      ? _c(
          "div",
          { staticClass: "page-header__image", style: _vm.bannerImageStyle },
          [
            _c("img", {
              attrs: {
                src: _vm.emptyImage,
                alt: "empty image",
                height: "315px",
                width: "100%"
              }
            })
          ]
        )
      : _c("div", {
          staticClass: "page-header__image",
          staticStyle: { height: "100vw" }
        }),
    _vm._v(" "),
    _vm.showBack
      ? _c(
          "div",
          {
            staticClass: "page-header__back-button",
            on: {
              click: function($event) {
                return _vm.$emit("back")
              }
            }
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 11.95 11",
                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                  stroke: _vm.backgroundColor
                }
              },
              [
                _c("path", {
                  attrs: {
                    d: "M7.05852 9.5L2.94138 5L7.05852 0.5",
                    fill: "none",
                    "stroke-width": "1"
                  }
                })
              ]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.title
      ? _c("div", { staticClass: "page-header__title" }, [
          _vm._v(_vm._s(_vm.title))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }