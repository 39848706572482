var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "stanley-chat-message-container" }, [
    _c(
      "div",
      {
        staticClass: "row flex-sm-row justify-content-start align-items-start"
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "max-width": "100%" } },
            [
              _vm.thinking
                ? _c("TypingAnimation", { staticClass: "mt-2" })
                : _c(
                    "div",
                    [
                      _vm.isHtml
                        ? _c("span", {
                            staticClass: "content-text",
                            domProps: { innerHTML: _vm._s(_vm.text) }
                          })
                        : _c("StanleyTypingText", {
                            attrs: { text: _vm.text, typing: _vm.typing },
                            on: { doneTyping: _vm.doneTyping }
                          })
                    ],
                    1
                  )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showSlot,
                  expression: "showSlot"
                }
              ]
            },
            [_vm._t("default")],
            2
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-auto pr-0" }, [
      _c("img", {
        staticClass: "mt-1",
        attrs: { src: "/images/icons/ic-stanley-profile.svg", width: "40px;" }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("b", [_vm._v("Stanley")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }