const MainContainer = () => import('~/components/layouts/MainContainer').then(m => m.default || m)
const moduleRoutes = []

const storesModuleRoutes = {
	path: '/stores',
	name: 'StoresSectionIndex',
	redirect: '/stores',
	component: MainContainer,
	children: [
		{
			path: '',
			name: 'linksites',
			component: () => import('./Store').then(m => m.default || m),
			meta: {
				pageTitle: 'Store',
				breadcrumbs: [{ title: 'My Store', active: true }],
				showAppBanner: true,
			},
		},
		{
			path: ':storeId/page/create',
			name: 'linksite_add_page',
			component: () => import('./add_new_page').then(m => m.default || m),
			meta: {
				pageTitle: 'Choose Product Type',
				breadcrumbs: [
					{ title: 'My Store', to: 'linksites' },
					{ title: 'Choose Product Type', active: true },
				],
			},
		},
		{
			path: ':storeId/page/:slug/create',
			name: 'linksite_add_new_product',
			component: () => import('./new_or_edit_page').then(m => m.default || m),
			meta: {
				pageTitle: 'Add new page',
				breadcrumbs: [
					{ title: 'My Store', to: 'linksites' },
					{ title: 'Add New Product', active: true },
				],
			},
		},
		{
			path: ':storeId/page/:slug/:pageId',
			name: 'linksite_edit_page',
			component: () => import('./new_or_edit_page').then(m => m.default || m),
			meta: {
				pageTitle: 'Edit Product',
				breadcrumbs: [
					{ title: 'My Store', to: 'linksites' },
					{ title: 'Edit Product', active: true },
				],
			},
		},
	],
}
const funnelModuleRoutes = [
	{
		path: '/funnels',
		name: 'FunnelsSectionIndex',
		redirect: '/funnels',
		component: MainContainer,
		children: [
			{
				path: '',
				name: 'funnels',
				component: () => import('./funnels_list').then(m => m.default || m),
				meta: {
					pageTitle: 'Funnels',
					breadcrumbs: [{ title: 'Funnels', active: true }],
				},
			},
			{
				path: ':storeId',
				name: 'funnel_details',
				component: () => import('./Funnel').then(m => m.default || m),
				meta: {
					pageTitle: 'Funnel Details',
					breadcrumbs: [{ title: 'Funnels', to: 'funnels' }],
				},
			},
			{
				path: ':storeId/page/create',
				name: 'funnel_add_page',
				component: () => import('./add_new_page').then(m => m.default || m),
				meta: {
					pageTitle: 'Choose Page Type',
					breadcrumbs: [
						{ title: 'Funnels', to: 'funnels' },
						{ title: 'Choose Page Type', active: true },
					],
				},
			},
			{
				path: ':storeId/page/:slug/create',
				name: 'funnel_add_new_product',
				component: () => import('./new_or_edit_page').then(m => m.default || m),
				meta: {
					pageTitle: 'Add New Page',

					breadcrumbs: [
						{ title: 'Funnels', to: 'funnels' },
						{ title: 'Add New Page', active: true },
					],
				},
			},
			{
				path: ':storeId/page/:slug/:pageId',
				name: 'funnel_edit_page',
				component: () => import('./new_or_edit_page').then(m => m.default || m),
				meta: {
					pageTitle: 'Edit Page',
					breadcrumbs: [
						{ title: 'Funnels', to: 'funnels' },
						{ title: 'Edit Page', active: true },
					],
				},
			},
		],
	},
]
export default moduleRoutes.concat(storesModuleRoutes, funnelModuleRoutes)
