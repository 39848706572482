<template>
	<div class="legend">
		<div class="legend-item" v-for="(label, index) in labels" :key="index">
			<div class="legend-color" :style="{ backgroundColor: colors[index] }"></div>
			<div class="legend-label-and-value">
				<label class="key" :style="`max-width: ${titleCharacterLimit}ch`">{{ label }}</label>
				<label class="value">
					<b>{{ series[index] }}</b>
				</label>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AppLegend',
		props: {
			series: {
				type: Array,
				required: true,
			},
			labels: {
				type: Array,
				required: true,
			},
			colors: {
				type: Array,
				required: true,
			},
			titleCharacterLimit: {
				type: Number,
				default: 20,
			},
		},
	}
</script>

<style scoped lang="scss">
	.legend {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: flex-start;
		gap: 0.75rem;

		.legend-item {
			display: grid;
			grid-template-columns: 15px auto;
			grid-template-areas: 'color label-and-value';
			gap: 0.75rem;
			align-items: center;

			.legend-color {
				grid-area: color;
				width: 15px;
				height: 15px;
				border-radius: 50%;
			}

			.legend-label-and-value {
				grid-area: label-and-value;
				display: flex;
				gap: 1ch;
				justify-content: space-between;

				label {
					margin: 0;
				}

				.key {
					font-size: 1rem;
					font-weight: 300;

					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.value {
					font-size: 1rem;
					font-weight: 600;
				}
			}
		}
	}
</style>
