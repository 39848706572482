var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      attrs: {
        id: "create-invoice-modal",
        title: "Send Invoice",
        subheading: _vm.subHeading,
        loading: _vm.loading,
        action: "Send"
      },
      on: { cta: _vm.sendInvoice, close: _vm.reset }
    },
    [
      _c(
        "div",
        {
          staticClass: "py-4",
          class: { "form-error": _vm.$v.description.$error }
        },
        [
          _c("AppInput", {
            attrs: {
              label: "Description",
              placeholder: "eg: My Fulfillment Order"
            },
            model: {
              value: _vm.description,
              callback: function($$v) {
                _vm.description = $$v
              },
              expression: "description"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pb-1", class: { "form-error": _vm.$v.amount.$error } },
        [
          _c("AppInput", {
            attrs: {
              type: "number",
              "has-precision": true,
              label: _vm.amountLabel,
              placeholder: "$9.99"
            },
            model: {
              value: _vm.amount,
              callback: function($$v) {
                _vm.amount = $$v
              },
              expression: "amount"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }