<template>
	<AppModal :title="$t('Go ahead, we’re listening! 🎤')" id="modal-product-feedback" action="Submit" @cta="saveFeedback" @close="closeModal">
		<div class="w-full mx-auto py-0">
			<textarea
				class="form-control styled-input"
				rows="3"
				name="feedback"
				v-model="feedback"
				:placeholder="$t('What can we do to make your experience better?')"
			/>
		</div>
	</AppModal>
</template>
<script>
	import { mapGetters } from 'vuex'

	export default {
		data() {
			return {
				disableButtons: false,
				feedback: '',
			}
		},
		computed: {
			...mapGetters('SiteSettingsStore', ['getProductFeedbackArea']),
			...mapGetters('Auth', ['user']),
		},
		methods: {
			saveFeedback() {
				this.disableButtons = true
				if (this.feedback && this.feedback !== '') {
					this.$stanAnalytics('product-feedback', {
						meta: { user_id: this.user.user_id, username: this.user.username },
						props: { product_area: this.getProductFeedbackArea, feedback: this.feedback },
					})
					this.feedback = ''
				}
				this.disableButtons = false
				this.closeModal()
			},
			closeModal() {
				$('#modal-product-feedback').modal('hide')
				this.$emit('close-modal')
			},
		},
	}
</script>

<style lang="scss" scoped>
	.icon {
		color: var(--stan-primary-primary-color);
		width: 36px;
		min-width: 36px;
		height: 36px;
		font-size: 36px !important;
		margin-bottom: 0.75rem;
	}
	.title {
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 27px;
		letter-spacing: 0.04em;
		color: var(--stan-primary-dark-color);
	}
	.button {
		min-width: 35%;
	}
</style>

<style>
	.md-overlay {
		z-index: 13;
	}
</style>
