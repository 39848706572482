import Cookies from 'js-cookie'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { unsupportedTimezoneMap } from '~/stan-vue-shared/components/utils.js'

function stanIntlDateTimeFormat(original, state) {
	const originalResolvedOptions = original.resolvedOptions()
	original.resolvedOptions = () => {
		originalResolvedOptions.timeZone = state.timezone
		return originalResolvedOptions
	}
	return () => original
}

/* eslint-disable no-bitwise */
export const state = {
	ip: undefined,
	showNavigation: false,
	showPreview: false,
	breadcrumbs: [],
	timezone: 'Etc/UTC',
}

export const getters = {
	getIPAddress: state => state.ip,
	showNavigation: state => state.showNavigation,
	showPreview: state => state.showPreview,
	getBreadcrumbs: state => state.breadcrumbs,
	getTimezone: state => state.timezone,
}

export const mutations = {
	setIPAddress(state, ip) {
		state.ip = ip
	},
	setShowNavigation(state, flag) {
		state.showNavigation = flag
	},
	setShowPreview(state, payload) {
		state.showPreview = payload
	},
	setBreadcrumbs(state, payload) {
		state.breadcrumbs = payload
	},
	setTimezone(state) {
		const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
		const original = Intl.DateTimeFormat()
		Intl.DateTimeFormat = stanIntlDateTimeFormat(original, state)
		state.timezone = tz in unsupportedTimezoneMap ? unsupportedTimezoneMap[tz] : tz
	},
}

export const UUID = () => {
	const lut = []
	for (let i = 0; i < 256; i++) {
		lut[i] = (i < 16 ? '0' : '') + i.toString(16)
	}
	const d0 = (Math.random() * 0xffffffff) | 0
	const d1 = (Math.random() * 0xffffffff) | 0
	const d2 = (Math.random() * 0xffffffff) | 0
	const d3 = (Math.random() * 0xffffffff) | 0
	return `${lut[d0 & 0xff] + lut[(d0 >> 8) & 0xff] + lut[(d0 >> 16) & 0xff] + lut[(d0 >> 24) & 0xff]}-${lut[d1 & 0xff]}${lut[(d1 >> 8) & 0xff]}-${
		lut[((d1 >> 16) & 0x0f) | 0x40]
	}${lut[(d1 >> 24) & 0xff]}-${lut[(d2 & 0x3f) | 0x80]}${lut[(d2 >> 8) & 0xff]}-${lut[(d2 >> 16) & 0xff]}${lut[(d2 >> 24) & 0xff]}${
		lut[d3 & 0xff]
	}${lut[(d3 >> 8) & 0xff]}${lut[(d3 >> 16) & 0xff]}${lut[(d3 >> 24) & 0xff]}`
}

export const getSessionUUID = () => {
	// We save session for 10 minutes and renew every time there's an event to be tracked
	const sessionUuid = Cookies.get('session_uuid') || UUID()
	const in10Minutes = 1 / 24 / 6 // js-cookies uses days as base unit
	Cookies.set('session_uuid', sessionUuid, { expires: in10Minutes })
	Cookies.set('session_uuid', sessionUuid, { domain: 'stanwith.me', expires: in10Minutes })
	Cookies.set('session_uuid', sessionUuid, { domain: 'stan.store', expires: in10Minutes })
	return sessionUuid
}

export const getCookieUUID = () => {
	const scookieUuid = Cookies.get('cookie_uuid') || UUID()
	const in1Year = 1 * 365 // js-cookies uses days as base unit
	Cookies.set('cookie_uuid', scookieUuid, { expires: in1Year })
	Cookies.set('cookie_uuid', scookieUuid, { domain: 'stanwith.me', expires: in1Year })
	Cookies.set('cookie_uuid', scookieUuid, { domain: 'stan.store', expires: in1Year })
	return scookieUuid
}

export const fingerprint = async () => {
	const fpPromise = FingerprintJS.load({
		apiKey: 'aU1GDRIYQrCN7pwkbzy4',
		monitoring: false,
	})
	const fp = await fpPromise
	const result = await fp.get()

	return result.visitorId
}

export const getVisitorID = async () => {
	const visitorId = Cookies.get('visitor_id_pro') || (await fingerprint())

	// js-cookies uses days as base unit
	Cookies.set('visitor_id_pro', visitorId, { expires: 365, domain: 'stanwith.me' })
	Cookies.set('visitor_id_pro', visitorId, { expires: 365, domain: 'stan.store' })
	Cookies.set('visitor_id_pro', visitorId, { expires: 365 })
	return visitorId
}
