import { mapGetters } from 'vuex'
import { format, parseISO, parse, addHours, addMinutes, setSeconds, setMilliseconds } from 'date-fns'
import { getTimesAPIValue } from '~/pages/stores/components/BaseDefaults.js'

export default {
	data() {
		return {
			CALENDAR_TYPE: {
				EVENT: 'event',
				CAL_BLOCKED: 'cal_blocked',
				BLOCKED: 'blocked',
				MORE: 'more',
			},
		}
	},
	computed: {
		...mapGetters('Bookings', ['referenceDate']),
	},
	methods: {
		YYYYMMDD(date) {
			return format(date, this.$constants.DATE_FORMAT.ISO_8601_DATE)
		},
		isPresentFuture(yyyymmddDate) {
			return parseISO(this.YYYYMMDD(new Date())) <= parseISO(yyyymmddDate)
		},
		isToday(yyyymmddDate) {
			return this.YYYYMMDD(new Date()) === yyyymmddDate
		},
		isThisMonth(yyyymmddDate) {
			return parseISO(yyyymmddDate).getMonth() === parseISO(this.referenceDate).getMonth()
		},
		getNextDateOnThe15(date) {
			const minutes = date.getMinutes()
			const addMin = 15 - (minutes % 15)
			const roundedDate = addMinutes(date, addMin !== 15 ? addMin : 0)
			const nextDate = setSeconds(setMilliseconds(roundedDate, 0), 0)
			return nextDate
		},
		createBlockTimeEvent(yyyymmddDate) {
			if (!this.isPresentFuture(yyyymmddDate) || !this.isThisMonth(yyyymmddDate)) {
				return undefined
			}

			const blockedTimeSlot = {
				id: undefined,
				start: undefined,
				end: undefined,
			}

			// Default slot 9am for selected date, unless it is the current date then we take the next possible slot
			blockedTimeSlot.start = parse(`${yyyymmddDate} ${getTimesAPIValue(9)}`, this.$constants.DATE_FORMAT.DATE_TIME_FORMAT_AM_PM, new Date())
			if (this.isToday(yyyymmddDate)) {
				blockedTimeSlot.start = this.getNextDateOnThe15(new Date())
			}
			blockedTimeSlot.end = addHours(blockedTimeSlot.start, 1)

			return blockedTimeSlot
		},
	},
}
