var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { style: _vm.scssVars }, [
    _c("div", { staticClass: "col column editor__content" }, [
      _c("div", {
        staticClass: "ProseMirror",
        domProps: { innerHTML: _vm._s(_vm.value) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }