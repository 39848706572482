import axios from 'axios'
import helper from '~/global_helper/helpers.js'

/* eslint-disable import/extensions */

export const state = {
	storeTheme: 'default',
	storePrimaryColor: null,
	storeSecondaryColor: null,
	storePrimaryFont: null,
	storeLoading: true,
	store: null,
	storeSections: [],
	storePages: [],
	storeAllPages: [],
	storeLandingPages: [],
	StoreStorePages: [],
	storePublishedPages: [],
	storeType: null,
	storeId: -1,
	funnels: [],
	previewTheme: null,
	features: {},
	emailTemplates: {},
	storePagesWithSections: [],
}

export const getters = {
	getStoreTheme: state => state.storeTheme,
	getStorePrimaryColor: state => state.storePrimaryColor,
	getStoreSecondaryColor: state => state.storeSecondaryColor,
	getStoreLoading: state => state.storeLoading,
	getStore: state => state.store,
	getStoreSections: state => state.storeSections,
	getStorePages: state => state.storePages,
	getStoreLandingPages: state => state.storeLandingPages,
	getStoreAllPages: state => state.storeAllPages,
	getStorePublishedPages: state => state.storePublishedPages,
	getStoreType: state => state.storeType,
	getStoreId: state => state.storeId,
	getStorePrimaryFont: state => state.storePrimaryFont,
	getFunnels: state => state.funnels,
	getPreviewTheme: state => state.previewTheme,
	getStoreFeatures: state => state.features,
	getEmailTemplates: state => state.emailTemplates,
	getStorePagesWithSections: state => state.storePagesWithSections,
}

export const mutations = {
	setStoreTheme(state, theme) {
		state.storeTheme = theme
	},
	setStorePrimaryColor(state, color) {
		state.storePrimaryColor = color
	},
	setStoreSecondaryColor(state, color) {
		state.storeSecondaryColor = color
	},
	setStorePrimaryFont(state, font) {
		state.storePrimaryFont = font
	},
	setStoreLoading(state, flag) {
		state.storeLoading = flag
	},
	setStore(state, store) {
		state.store = store
	},
	setStoreSections(state, storeSections) {
		state.storeSections = storeSections
	},
	setStorePages(state, storePages) {
		state.storePages = storePages
	},
	setStorePagesWithSections(state, storePagesWithSections) {
		state.storePagesWithSections = storePagesWithSections
	},
	setStoreAllPages(state, pages) {
		state.storeAllPages = pages
	},
	setStoreLandingPages(state, pages) {
		state.storeLandingPages = pages
	},
	setStoreType(state, storeType) {
		state.storeType = storeType
	},
	setStoreId(state, storeId) {
		state.storeId = storeId
	},
	setStorePublishedPages(state, pages) {
		state.storePublishedPages = pages.filter(f => f.status === 2 && (!f.data.location || f.data.location === 'store'))
	},
	setFunnels(state, funnels) {
		state.funnels = funnels
	},
	setPreviewTheme(state, theme) {
		state.previewTheme = theme
	},
	setStoreFeatures(state) {
		const values = {}
		state.features = values
	},
	setEmailTemplates(state, templates) {
		state.emailTemplates = templates
	},
	pushNewSection(state, section) {
		state.storePagesWithSections.push(section)
	},
	removeNewSection(state) {
		state.storePagesWithSections.push()
	},
}

export const actions = {
	fetchStore: ({ commit }, payload) => {
		const params = { type: payload?.storeType || 'linksite' }
		const storeId = payload?.storeId || 'self'
		commit('setStoreLoading', true)
		axios
			.get(`v1/stores/${storeId}`, { params })
			.then(response => {
				const store = response.data.store || response.data.stores[0]
				if (store?.data) {
					if (store.data.primary_color) {
						helper.addThemeColorsToDocument(
							store.data.theme,
							store.data.primary_color,
							store.data.secondary_color,
							store.data.primary_font
						)
					}
				}
				commit('setStoreTheme', store.data.theme || 'default')
				commit('setStoreSections', store.sections)
				commit('setStoreAllPages', store.pages)
				commit(
					'setStorePages',
					store.pages.filter(p => p.data.location !== 'landing')
				)
				commit(
					'setStoreLandingPages',
					store.pages.filter(p => p.data.location === 'landing')
				)
				commit('setStorePublishedPages', store.pages)
				commit('setStoreLoading', false)
				commit('setStoreType', store.type)
				commit('setStore', store)
				commit('setStoreId', store.store_id)
				commit('setStorePrimaryColor', store.data.primary_color)
				commit('setStoreSecondaryColor', store.data.secondary_color)
				commit('setStorePrimaryFont', store.data.primary_font)
				commit('setStoreFeatures', store.data)

				let pagesWithSections = []

				pagesWithSections = [...store.pages.filter(p => p.data.location !== 'landing'), ...store.sections]

				commit(
					'setStorePagesWithSections',
					pagesWithSections.sort((a, b) => a.order - b.order)
				)
			})
			.catch(error => {
				// eslint-disable-next-line no-console
				console.error(error)
				commit('setStoreLoading', false)
				commit('setStore', null)
			})
	},
	fetchFunnels: ({ commit }, payload) => {
		const params = { type: payload?.storeType || 'funnel' }
		const storeId = payload?.storeId || 'self'
		axios
			.get(`v1/stores/${storeId}`, { params })
			.then(response => {
				const funnels = response.data.stores
				commit('setFunnels', funnels)
			})
			.catch(error => {
				// eslint-disable-next-line no-console
				console.error(error)
			})
	},
	updatePreviewTheme: ({ commit }, theme) => {
		commit('setPreviewTheme', theme)
	},
	fetchEmailTemplates: ({ commit }) => {
		axios
			.get(`v1/pages/email-templates`)
			.then(response => {
				const emailTemplates = response.data.emailTemplates
				commit('setEmailTemplates', emailTemplates)
			})
			.catch(error => {
				// eslint-disable-next-line no-console
				console.error(error)
			})
	},
}
