var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: _vm.fields.length, staticClass: "form-group mb-1" },
    [
      _c("span", { staticClass: "control-label" }, [
        _vm._v(_vm._s(_vm.$t("Fields")))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "stan-form-card mb-3 mt-2 shadow-none" },
        [
          _c("AppInput", {
            staticClass: "input-no-border",
            attrs: {
              icon: "/images/form-fields/text.svg",
              type: "text",
              value: _vm.$t("Name"),
              disabled: ""
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "stan-form-card mb-3 shadow-none" },
        [
          _c("AppInput", {
            staticClass: "input-no-border",
            attrs: {
              icon: "/images/form-fields/email.svg",
              type: "text",
              value: _vm.$t("Email"),
              disabled: ""
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.phoneField
        ? _c(
            "div",
            {
              staticClass: "stan-form-card mb-3 shadow-none phone-bg disabled",
              class: {
                "phone-disabled": _vm.phoneField && !_vm.phoneField.enabled,
                "form-error":
                  _vm.phoneFieldValidation && _vm.phoneFieldValidation.$error
              }
            },
            [
              _c("div", { staticClass: "d-flex align-items-center header" }, [
                _c(
                  "div",
                  { staticClass: "form-details" },
                  [
                    _c("AppInput", {
                      staticClass: "col px-0 input-no-border",
                      attrs: {
                        icon: "/images/form-fields/phone.svg",
                        "input-class": "mr-3",
                        disabled: true
                      },
                      model: {
                        value: _vm.phoneField.content,
                        callback: function($$v) {
                          _vm.$set(_vm.phoneField, "content", $$v)
                        },
                        expression: "phoneField.content"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "action-items align-items-center d-none d-md-flex mr-3 pr-0"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-end mr-3"
                      },
                      [
                        _c("span", { staticClass: "stan-label pr-1" }, [
                          _vm._v(" " + _vm._s(_vm.$t("Required")) + " ")
                        ]),
                        _vm._v(" "),
                        _c("AppToggle", {
                          attrs: {
                            id: "checkbox-phone",
                            disabled: !_vm.phoneField.enabled,
                            small: true
                          },
                          model: {
                            value: _vm.phoneField.required,
                            callback: function($$v) {
                              _vm.$set(_vm.phoneField, "required", $$v)
                            },
                            expression: "phoneField.required"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-end"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "p-0",
                            attrs: { type: "button" },
                            on: { click: _vm.togglePhone }
                          },
                          [
                            _vm.phoneField.enabled
                              ? _c("InlineSvg", {
                                  staticClass: "cursor-pointer delete-icon",
                                  attrs: {
                                    src: "/images/form-fields/visible.svg"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.phoneField.enabled
                              ? _c("InlineSvg", {
                                  staticClass: "cursor-pointer delete-icon",
                                  attrs: {
                                    src: "/images/form-fields/not-visible.svg"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "action-items d-flex align-items-center mb-2 d-md-none pr-3"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-end pr-3"
                    },
                    [
                      _c("span", { staticClass: "stan-label pr-1" }, [
                        _vm._v(" " + _vm._s(_vm.$t("Required")) + " ")
                      ]),
                      _vm._v(" "),
                      _c("AppToggle", {
                        attrs: {
                          id: "checkbox-phone-mob",
                          disabled: !_vm.phoneField.enabled,
                          small: true
                        },
                        model: {
                          value: _vm.phoneField.required,
                          callback: function($$v) {
                            _vm.$set(_vm.phoneField, "required", $$v)
                          },
                          expression: "phoneField.required"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-end"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "p-0",
                          attrs: { type: "button" },
                          on: { click: _vm.togglePhone }
                        },
                        [
                          _vm.phoneField.enabled
                            ? _c("InlineSvg", {
                                staticClass: "cursor-pointer delete-icon",
                                attrs: {
                                  src: "/images/form-fields/visible.svg"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.phoneField.enabled
                            ? _c("InlineSvg", {
                                staticClass: "cursor-pointer delete-icon",
                                attrs: {
                                  src: "/images/form-fields/not-visible.svg"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Draggable",
        _vm._b(
          {
            attrs: {
              delay: 250,
              delayOnTouchOnly: true,
              draggable: ".draggable-section-node",
              handle: ".drag-handle"
            },
            model: {
              value: _vm.fields,
              callback: function($$v) {
                _vm.fields = $$v
              },
              expression: "fields"
            }
          },
          "Draggable",
          { animation: 0, ghostClass: "ghost" },
          false
        ),
        _vm._l(
          _vm.fields.filter(field => field.type !== "phone"),
          function(field, i) {
            return _c(
              "div",
              {
                key: `field-${i}`,
                staticClass: "d-flex align-items-start draggable-section-node"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "drag-handle d-flex align-items-center cursor-pointer"
                  },
                  [_c("InlineSvg", { attrs: { src: "/images/six-dots.svg" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "stan-form-card mb-3 shadow-none col",
                    class: {
                      "form-error":
                        _vm.fieldsValidation[i] &&
                        _vm.fieldsValidation[i].$error
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center header" },
                      [
                        _c(
                          "div",
                          { staticClass: "form-details" },
                          [
                            field.type === "text"
                              ? _c("AppInput", {
                                  staticClass:
                                    "col px-0 input-no-border form-field-type-input",
                                  attrs: {
                                    icon: "/images/form-fields/text.svg",
                                    "input-class": "mr-3",
                                    placeholder: _vm.$t("Short answer title...")
                                  },
                                  model: {
                                    value: field.content,
                                    callback: function($$v) {
                                      _vm.$set(field, "content", $$v)
                                    },
                                    expression: "field.content"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            field.type === "phone"
                              ? _c("AppInput", {
                                  staticClass:
                                    "col px-0 input-no-border form-field-type-input",
                                  attrs: {
                                    icon: "/images/form-fields/phone.svg",
                                    "input-class": "mr-3",
                                    disabled: field.type === "phone"
                                  },
                                  model: {
                                    value: field.content,
                                    callback: function($$v) {
                                      _vm.$set(field, "content", $$v)
                                    },
                                    expression: "field.content"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            field.type === "dropdown"
                              ? _c("AppInput", {
                                  staticClass:
                                    "col px-0 input-no-border form-field-type-input",
                                  attrs: {
                                    icon: "/images/form-fields/dropdown.svg",
                                    "input-class": "mr-3",
                                    placeholder: _vm.$t("Dropdown title...")
                                  },
                                  model: {
                                    value: field.content,
                                    callback: function($$v) {
                                      _vm.$set(field, "content", $$v)
                                    },
                                    expression: "field.content"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            field.type === "checkbox"
                              ? _c("AppInput", {
                                  staticClass:
                                    "col px-0 input-no-border form-field-type-input",
                                  attrs: {
                                    icon: "/images/form-fields/checkbox.svg",
                                    "input-class": "mr-3",
                                    placeholder: _vm.$t("Checkbox title...")
                                  },
                                  model: {
                                    value: field.content,
                                    callback: function($$v) {
                                      _vm.$set(field, "content", $$v)
                                    },
                                    expression: "field.content"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            field.type === "radio"
                              ? _c("AppInput", {
                                  staticClass:
                                    "col px-0 input-no-border form-field-type-input",
                                  attrs: {
                                    icon: "/images/form-fields/radio.svg",
                                    "input-class": "mr-3",
                                    placeholder: _vm.$t(
                                      "Multiple choice title..."
                                    )
                                  },
                                  model: {
                                    value: field.content,
                                    callback: function($$v) {
                                      _vm.$set(field, "content", $$v)
                                    },
                                    expression: "field.content"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "action-items align-items-center d-none d-md-flex mr-3 pr-0"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-end mr-3"
                              },
                              [
                                _c("span", { staticClass: "stan-label pr-1" }, [
                                  _vm._v(" " + _vm._s(_vm.$t("Required")) + " ")
                                ]),
                                _vm._v(" "),
                                _c("AppToggle", {
                                  attrs: { id: "checkbox" + i, small: true },
                                  model: {
                                    value: field.required,
                                    callback: function($$v) {
                                      _vm.$set(field, "required", $$v)
                                    },
                                    expression: "field.required"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-end"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "p-0",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeField(i)
                                      }
                                    }
                                  },
                                  [
                                    _c("InlineSvg", {
                                      staticClass: "cursor-pointer delete-icon",
                                      attrs: {
                                        src: "/images/form-fields/delete.svg"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    field.type === "radio" ||
                    field.type === "checkbox" ||
                    field.type === "dropdown"
                      ? _c("div", { staticClass: "d-flex" }, [
                          _c(
                            "div",
                            { staticClass: "options px-3 pb-3 w-100" },
                            [
                              _c(
                                "Draggable",
                                _vm._b(
                                  {
                                    staticClass: "w-100",
                                    attrs: {
                                      delay: 100,
                                      delayOnTouchOnly: true,
                                      draggable: ".option",
                                      handle: ".option-handle"
                                    },
                                    model: {
                                      value: field.options,
                                      callback: function($$v) {
                                        _vm.$set(field, "options", $$v)
                                      },
                                      expression: "field.options"
                                    }
                                  },
                                  "Draggable",
                                  { animation: 0, ghostClass: "ghost" },
                                  false
                                ),
                                [
                                  _vm._l(field.options, function(option, o) {
                                    return _c(
                                      "div",
                                      {
                                        key: `option-${o}`,
                                        staticClass:
                                          "option d-flex stan-option-card mt-2"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "option-handle d-flex align-items-center ml-2 cursor-pointer"
                                          },
                                          [
                                            _c("InlineSvg", {
                                              attrs: {
                                                src: "/images/six-dots.svg"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("AppInput", {
                                          staticClass:
                                            "op-test px-0 input-no-border flex-grow-1",
                                          attrs: {
                                            "input-class": "mr-3",
                                            placeholder: `Option ${o + 1}`
                                          },
                                          model: {
                                            value: option.value,
                                            callback: function($$v) {
                                              _vm.$set(option, "value", $$v)
                                            },
                                            expression: "option.value"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "option-action-items d-flex align-items-center"
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "p-0",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.removeOption(
                                                      field,
                                                      o
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("InlineSvg", {
                                                  staticClass:
                                                    "cursor-pointer option-close-icon",
                                                  attrs: {
                                                    src:
                                                      "/images/close-icon.svg"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "add-option d-flex justify-content-end"
                                    },
                                    [
                                      _c(
                                        "AppButton",
                                        {
                                          staticClass: "w-100 mt-2",
                                          attrs: {
                                            name: "add-option-form-fields",
                                            outline: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addOption(field)
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("+ Add Option")))]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "action-items d-flex align-items-center mb-2 d-md-none pr-3"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-end pr-3"
                          },
                          [
                            _c("span", { staticClass: "stan-label pr-1" }, [
                              _vm._v(" " + _vm._s(_vm.$t("Required")) + " ")
                            ]),
                            _vm._v(" "),
                            _c("AppToggle", {
                              attrs: { id: "checkbox" + i, small: true },
                              model: {
                                value: field.required,
                                callback: function($$v) {
                                  _vm.$set(field, "required", $$v)
                                },
                                expression: "field.required"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-end"
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "p-0",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeField(i)
                                  }
                                }
                              },
                              [
                                _c("InlineSvg", {
                                  staticClass: "cursor-pointer delete-icon",
                                  attrs: {
                                    src: "/images/form-fields/delete.svg"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          }
        ),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "AppDropdownMenu",
            {
              staticClass: "add-field-menu",
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dropdown-item cursor-pointer d-flex flex-wrap p-2 align-items-center",
                          on: {
                            click: function($event) {
                              return _vm.addField("text")
                            }
                          }
                        },
                        [
                          _c("InlineSvg", {
                            staticClass: "mr-2",
                            attrs: { src: "/images/form-fields/text.svg" }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Text")])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "dropdown-item cursor-pointer d-flex flex-wrap p-2 align-items-center",
                          on: {
                            click: function($event) {
                              return _vm.addField("radio")
                            }
                          }
                        },
                        [
                          _c("InlineSvg", {
                            staticClass: "mr-2",
                            attrs: { src: "/images/form-fields/radio.svg" }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Multiple choice")])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "dropdown-item cursor-pointer d-flex flex-wrap p-2 align-items-center",
                          on: {
                            click: function($event) {
                              return _vm.addField("dropdown")
                            }
                          }
                        },
                        [
                          _c("InlineSvg", {
                            staticClass: "mr-2",
                            attrs: { src: "/images/form-fields/dropdown.svg" }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Dropdown")])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "dropdown-item cursor-pointer d-flex flex-wrap p-2 align-items-center",
                          on: {
                            click: function($event) {
                              return _vm.addField("checkbox")
                            }
                          }
                        },
                        [
                          _c("InlineSvg", {
                            staticClass: "mr-2",
                            attrs: { src: "/images/form-fields/checkbox.svg" }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "p2" }, [
                            _vm._v("Checkboxes")
                          ])
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "AppButton",
                {
                  staticClass: "w-100",
                  attrs: { name: "add-fields-form-field", outline: "" }
                },
                [_vm._v(_vm._s(_vm.$t("+ Add Field")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }