<template>
	<div v-bind:class="className" class="pb-3">
		<div style="background-image: url('/images/device/placeholder-new.svg')" class="funnel-skeleton_top">
			<!-- <div class="discount">20%</div> -->
			<div class="title">
				<div></div>
				<div></div>
			</div>
		</div>
		<div class="funnel_body">
			<!-- <div class="price">
				$0.00
			</div> -->
			<div class="list-text-skeleton li-none-style">
				<div></div>
				<div></div>
				<div></div>
			</div>
			<div class="list-text-skeleton">
				<ul>
					<li></li>
					<li></li>
					<li></li>
				</ul>
			</div>
			<div class="funnel-thumnail">
				<svg width="2560" height="1690" viewBox="0 0 256 169" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="0.243652" y="0.447266" width="254.966" height="168.072" rx="20" fill="#F3F6FD" />
					<path d="M163.742 83.9115L110.577 114.607L110.577 53.2163L163.742 83.9115Z" fill="#09BB71" />
				</svg>
			</div>
		</div>
		<div class="button-section">
			<a> &nbsp;</a>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'PreviewSkeleton',
		data() {
			return {
				className: 'funnel-skeleton',
			}
		},
	}
</script>
<style lang="scss" scoped>
	.funnel-skeleton {
		.funnel-skeleton_top {
			position: relative;
			width: 100%;
			padding-top: 68%;
			background-size: 100%;
			background-repeat: no-repeat;
			background-color: var(--stan-blue-8);
			img {
				width: 100%;
			}
			.discount {
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				position: absolute;
				top: 40px;
				right: 20px;
				background-color: var(--stan-store-secondary-color);
				padding: 0 8px;
				color: var(--stan-white);
			}
			.title {
				position: absolute;
				bottom: 20px;
				left: 20px;
				right: 20px;
				max-width: 100%;
				div {
					background-color: var(--stan-store-primary-color);
					height: 28px;
					margin-bottom: 3px;
					&:nth-child(2) {
						width: 90px;
						max-width: 90px;
					}
				}
				span {
					padding: 0px;
					font-size: 30px;
					color: var(--stan-white);
					background-color: var(--stan-store-primary-color);
					display: inline-block;
					font-weight: bold;
					letter-spacing: 0.04em;
					display: inline;
				}
			}
		}
		.funnel_body {
			.price {
				background-color: var(--stan-store-secondary-color);
				color: var(--stan-white);
				display: inline-block;
				font-size: 24px;
				font-weight: bold;
				padding: 0px 5px;
				margin-left: 20px;
				margin-top: 20px;
			}
			ul {
				padding-left: 40px;
				// margin: 20px 0;
				li {
					width: 112px;
					position: relative;
					&:before {
						content: '';
						position: absolute;
						background-color: #ced6e7;
						height: 8px;
						width: 100%;
						top: 10px;
						display: block;
					}
					&::marker {
						color: var(--stan-store-secondary-color);
						font-size: 16px;
					}
					&:nth-child(2) {
						width: 50px;
					}
					&:nth-child(3) {
						width: 50px;
					}
				}
			}
			.li-none-style {
				padding: 10px 20px;
				div {
					background-color: #ced6e7;
					height: 8px;
					width: 100%;
					margin: 7px 0px;
					&:nth-child(2) {
						width: 70%;
					}
					&:nth-child(3) {
						width: 80%;
					}
				}
			}
		}
		.funnel-thumnail {
			padding: 0 20px;
			svg {
				margin: 0 auto;
				display: inherit;
				max-width: 100%;
				max-height: 210px;
				@media (max-width: 440px) {
					max-height: calc((100vw - 40px) * 0.5);
				}
				path {
					fill: var(--stan-store-secondary-color);
				}
			}
		}
		.button-section {
			border-radius: 100px;
			margin-top: 33px;
			padding: 0 20px;
			a {
				height: 55px;
				border-radius: 100px;
				margin: 0 auto;
				width: 100%;
				text-align: center;
				display: block;
				background-color: var(--stan-store-primary-color);
				color: var(--stan-white);
				padding: 10px;
			}
		}
	}
	.small-preview {
		.funnel-skeleton_top {
			padding-top: 60%;
			position: relative;
			width: 100%;
			background-size: 100%;
			img {
				width: 100%;
			}
			.discount {
				font-family: 'Grobek';
				font-style: normal;
				font-weight: bold;
				font-size: 10px;
				position: absolute;
				top: 8px;
				right: 10px;
				background-color: var(--stan-store-secondary-color);
				padding: 0 3px;
				color: var(--stan-white);
			}
			.title {
				position: absolute;
				bottom: 10px;
				left: 10px;
				max-width: 75px;
				div {
					background-color: var(--stan-store-primary-color);
					height: 11px;
					margin-bottom: 3px;
					&:nth-child(2) {
						width: 90px;
						max-width: 90px;
					}
				}
				span {
					font-family: 'Grobek';
					padding: 0px;
					color: var(--stan-white);
					background-color: var(--stan-store-primary-color);
					font-weight: bold;
					letter-spacing: 0.04em;
					display: inline;
					font-style: normal;
					font-size: 12px;
				}
			}
		}
		.funnel_body {
			.price {
				font-family: 'Grobek';
				background-color: var(--stan-store-secondary-color);
				color: var(--stan-white);
				display: inline-block;
				font-size: 10px;
				font-weight: bold;
				padding: 0px 3px;
				margin-left: 10px;
				margin-top: 8px;
			}
			ul {
				padding-left: 25px;
				margin-top: -2px;
				margin-bottom: 18px;
				li {
					width: 58px;
					position: relative;
					height: 10px;
					&:before {
						content: '';
						position: absolute;
						background-color: #ced6e7;
						height: 4px;
						width: 100%;
						top: 10px;
						display: block;
					}
					&::marker {
						color: var(--stan-store-secondary-color);
						font-size: 12px;
					}
					&:nth-child(2) {
						width: 26px;
					}
					&:nth-child(3) {
						width: 45px;
					}
				}
			}
			.li-none-style {
				padding: 0px 10px;
				padding-top: 5px;
				div {
					background-color: #ced6e7;
					height: 4px;
					width: 100%;
					margin: 3px 0px;
					&:nth-child(2) {
						width: 70%;
					}
					&:nth-child(3) {
						width: 80%;
					}
				}
			}
		}
		.funnel-thumnail {
			padding: 0 10px;
			svg {
				margin: 0 auto;
				display: inherit;
				max-width: 100%;
				max-height: 93px;
				path {
					fill: var(--stan-store-secondary-color);
				}
			}
		}
		.button-section {
			border-radius: 100px;
			margin: 15px 0px;
			padding: 0 10px;
			a {
				height: 25px;
				border-radius: 100px;
				margin: 0 auto;
				width: 100%;
				text-align: center;
				display: block;
				background-color: var(--stan-store-primary-color);
				color: var(--stan-white);
				padding: 6px;
				font-size: 12px;
			}
		}
	}
</style>
