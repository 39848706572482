var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main pt-3 pb-3", class: _vm.classOverride },
    [
      _c(
        "Transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c("AppFormQuestion", {
            key: _vm.questions[_vm.currentQuestionIndex].questionId,
            attrs: {
              questionId: _vm.questions[_vm.currentQuestionIndex].questionId,
              questionText:
                _vm.questions[_vm.currentQuestionIndex].questionText,
              options: _vm.randomizedOptions,
              type: _vm.questions[_vm.currentQuestionIndex].type,
              required: _vm.questions[_vm.currentQuestionIndex].required
            },
            on: { next: _vm.next }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }