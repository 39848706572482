<template>
	<div class="d-flex justify-content-between align-items-center row-container">
		<div class="d-flex flex-row gap-10px align-items-center">
			<div class="para-3 text-light">
				{{ this.title }}
			</div>
		</div>
		<span class="para-1 text-light text-bold">{{ amount }}</span>
	</div>
</template>

<script>
	export default {
		name: 'CashOutTimeLineDateRow',
		props: {
			title: {
				type: String,
				default: '',
			},
			amount: {
				type: String,
				default: '',
			},
		},
	}
</script>

<style lang="scss" scoped>
	.row-container {
		padding-left: 10px;
	}
</style>
