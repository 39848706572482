<template>
	<div class="position-relative">
		<div class="order-bump-section">
			<div class="d-flex align-items-center justify-content-start enable-community-integration">
				<div class="enable-toggle">
					<AppToggle v-model="value.enabled" />
				</div>
				<div>
					<InlineSvg src="/images/email-icon.svg" />
				</div>
				<div>
					<label class="text-bold mr-2 mb-0">Invite Customers to Community</label>
					<label class="w-100 d-block mb-2 hint-text">Upon purchase, customer will also receive an invite to join your Community.</label>
				</div>
			</div>
			<div v-if="value.enabled">
				<div class="d-flex justify-content-start community-settings flex-row mr-3">
					<div>
						<InlineSvg src="/images/icons/ic-check-circle.svg" />
					</div>
					<div class="d-flex flex-column details">
						<div v-if="communityName">
							All Customers who purchase this product will receive an invite email to join your Community: <b>{{ communityName }}</b>
						</div>
						<div v-else>
							All Customers who purchase this product will receive an invite email to join your Community!
						</div>
						<div class="diagram">
							<img src="/images/community-product-integration-diagram.png" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'

	export default {
		name: 'CommunityIntegrationForm',
		props: {
			value: {
				type: Object,
				default: () => {},
			},
		},
		computed: {
			...mapGetters('Auth', ['user']),
			...mapGetters('Communities', ['communityName']),
		},
	}
</script>

<style lang="scss" scoped>
	.enable-community-integration {
		gap: 14px;
		margin-bottom: 22px;
		.enable-toggle {
			padding-right: 4px;
		}
		.hint-text {
			font-weight: 400 !important;
			font-size: 12px !important;
			line-height: 16px !important;
			color: #808eb6 !important;
		}
	}
	.community-settings {
		background-color: var(--stan-primary-light-color);
		padding: 15px;
		gap: 10px;
		border-radius: 8px;

		.details {
			gap: 15px;

			.diagram {
				border-radius: 8px;
				border: solid 2px #fff;
			}
		}
	}
</style>
