<template>
	<div class="creator-pro-modal-overlay">
		<div class="d-flex flex-wrap justify-content-center h-100">
			<div class="creator-pro-modal container">
				<div class="d-flex flex-column justify-content-center flex-wrap h-100">
					<p align="center" class="modal-heading">Welcome to Creator Pro! 🚀</p>
					<p align="center" class="modal-subheading">
						For the next 14 days you will have access to all of Stan's features! At the end of the trial your account will automatically
						go back to Creator. You can upgrade to Creator Pro anytime!
					</p>
					<AppButton name="close-creator-pro-trial-started-modal" class="button-wrapper mx-auto" @click="close()"
						>Get back to it!</AppButton
					>

					<div class="close close-modal" data-dismiss="modal" aria-label="Close" @click="close()">
						<InlineSvg class="close-icon" src="/images/icons/ic-close.svg" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		methods: {
			close() {
				this.$emit('close')
			},
		},
	}
</script>

<style lang="scss" scoped>
	.button-wrapper {
		min-width: min(90vw, 400px) !important;
		max-width: 400px;
	}

	.modal-heading {
		color: var(--stan-text-dark-color);
		text-align: center;
		font-family: Plus Jakarta Sans;
		font-size: 44px;
		font-style: normal;
		font-weight: 800;
		line-height: 110%; /* 48.4px */
		letter-spacing: -0.44px;
		margin-bottom: 40px;

		@media (max-width: 768px) {
			font-size: 28px;
			font-style: normal;
			font-weight: 800;
			line-height: 110%; /* 30.8px */
			letter-spacing: -0.28px;
		}
	}

	.modal-subheading {
		color: var(--stan-text-light-color);
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 300;
		line-height: 130%;
		letter-spacing: -0.2px;
		margin-bottom: 60px;

		@media (max-width: 768px) {
			font-size: 16px;
			font-style: normal;
			font-weight: 300;
			line-height: 130%;
			letter-spacing: -0.2px;
		}
	}

	/* Style the overlay */
	.creator-pro-modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: white; /* add transparency to the overlay */
		z-index: 999; /* make sure the overlay is on top of everything else */
	}

	.creator-pro-modal {
		background-color: white;
		padding: 20px;
	}

	.close-modal {
		position: absolute;
		right: 15px;
		top: 15px;
		display: flex;
		width: 28px;
		height: 28px;
		background-color: var(--stan-gray-light-color) !important;
		border-radius: 50%;
		cursor: pointer;
		z-index: 9;
		color: var(--stan-primary-primary-color);
		opacity: 1;
		&:hover {
			background-color: var(--stan-gray-soft-color) !important;
		}

		.close-icon {
			margin: auto;
		}
	}
</style>
