<template>
	<AppSection :number="step" title="Select image">
		<FileDropZone
			:image="value.image"
			class="new"
			drop-zone-class="header-image-drop-zone"
			drop-zone-placeholder="Thumbnail"
			expected-dimension="400x400"
			@start-upload="payload => $emit('start-upload', payload)"
			@uploaded="payload => $emit('imageUploaded', payload)"
			@uploaded-all="payload => $emit('uploaded-all', payload)"
			@delete-file="payload => $emit('delete-file', payload)"
			@cancel-file="payload => $emit('cancel-file', payload)"
			data-test-id="upload-button-file-button"
			:crop-aspect-ratio="1"
			accept="image"
		/>
	</AppSection>
</template>
<script>
	export default {
		props: {
			step: {
				type: String,
				required: true,
			},
			value: {
				type: Object,
				default: () => {},
			},
		},
	}
</script>
<style lang="scss"></style>
