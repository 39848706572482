import Vue from 'vue'
import { HasError, AlertError, AlertSuccess } from 'vform'
import StarRating from 'vue-star-rating'

import HollowDotsSpinner from '~/stan-vue-shared/components/HollowDotsSpinner'

const requireComponent = require.context('~/components', true, /\.vue$/)
const uniqueKeys = {}

requireComponent.keys().forEach(fileName => {
	const componentConfig = requireComponent(fileName)
	const componentName = fileName
		.split('/')
		.pop()
		.replace(/\.\w+$/, '')

	// Make sure the component has a unique name property
	if (uniqueKeys[componentName]) {
		throw new Error(`Duplicate component name found: ${componentName}`)
	} else {
		uniqueKeys[componentName] = true
	}

	Vue.component(componentName, componentConfig.default || componentConfig)
})
;[HasError, AlertError, AlertSuccess, HollowDotsSpinner].forEach(Component => {
	Vue.component(Component.name, Component)
})

Vue.component('StarRating', StarRating)
