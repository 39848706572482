var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "flex-direction": "column",
        "background-color": "var(--stan-store-custom-background-color)",
        "border-radius": "10px",
        "box-shadow": "var(--stan-box-shadow)"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex flex-column align-items-center",
          staticStyle: { padding: "15px 15px 0px 15px", cursor: "pointer" },
          on: { click: _vm.toggleShowLessons }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row justify-content-between align-items-center w-100",
              staticStyle: { "margin-bottom": "15px" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-row align-items-center",
                  staticStyle: { gap: "12px", "min-width": "100%" }
                },
                [
                  _c("div", { staticClass: "incompleted-module-circle" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column",
                      staticStyle: { gap: "4px", "flex-shrink": "100" }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "para-4",
                          staticStyle: {
                            color: "var(--stan-store-custom-highlight-color)"
                          }
                        },
                        [_vm._v("Module " + _vm._s(_vm.moduleNumber))]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "para-3 font-semibold" }, [
                        _vm._v(_vm._s(_vm.module.data.title))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        gap: "8px",
                        "flex-grow": "1",
                        "place-content": "end",
                        "align-items": "center"
                      }
                    },
                    [
                      _c("div", { staticClass: "module-tracker-container" }, [
                        _vm.isModuleBeingDripped
                          ? _c(
                              "span",
                              { staticClass: "module-dripped-container" },
                              [
                                _c("span", { staticClass: "para-4" }, [
                                  _vm._v(
                                    "🔒 " +
                                      _vm._s(
                                        _vm.module.data.drip_settings.days
                                      ) +
                                      "d"
                                  )
                                ])
                              ]
                            )
                          : _c("span", { staticClass: "para-4" }, [
                              _vm._v("0/" + _vm._s(_vm.module.lessons.length))
                            ])
                      ]),
                      _vm._v(" "),
                      !_vm.isModuleBeingDripped
                        ? _c(
                            "span",
                            [
                              _vm.showLessons
                                ? _c("InlineSvg", {
                                    staticStyle: {
                                      fill:
                                        "var(--stan-store-custom-highlight-color)"
                                    },
                                    attrs: {
                                      src:
                                        "/images/preview-icons/chevron-up.svg"
                                    }
                                  })
                                : _c("InlineSvg", {
                                    staticStyle: {
                                      fill:
                                        "var(--stan-store-custom-highlight-color)"
                                    },
                                    attrs: {
                                      src:
                                        "/images/preview-icons/chevron-down.svg"
                                    }
                                  })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "Transition",
        {
          attrs: { name: "expand" },
          on: { enter: _vm.enter, leave: _vm.leave }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showLessons && !_vm.isModuleBeingDripped,
                  expression: "showLessons && !isModuleBeingDripped"
                }
              ],
              ref: "lessonsContainer",
              staticClass: "lessons-container"
            },
            [
              _c("div", { staticClass: "divider-bar" }),
              _vm._v(" "),
              _vm._l(_vm.module.lessons, function(lesson) {
                return _c(
                  "div",
                  {
                    key: lesson.lesson_id,
                    staticClass: "lesson-item",
                    class: {
                      "lesson-selected": _vm.isActiveLesson(lesson.lesson_id),
                      "lesson-unselected": !_vm.isActiveLesson(lesson.lesson_id)
                    },
                    on: {
                      click: function($event) {
                        return _vm.handleChooseLesson(
                          _vm.moduleNumber - 1,
                          lesson
                        )
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "lesson-icon",
                        class: {
                          "lesson-icon-selected": _vm.isActiveLesson(
                            lesson.lesson_id
                          ),
                          "lesson-icon-unselected": !_vm.isActiveLesson(
                            lesson.lesson_id
                          )
                        }
                      },
                      [
                        lesson.data.video
                          ? _c("InlineSvg", {
                              staticClass: "stroke-white",
                              attrs: {
                                src: "/images/preview-icons/video-camera.svg"
                              }
                            })
                          : _c("InlineSvg", {
                              staticClass: "fill-white",
                              attrs: {
                                src: "/images/preview-icons/text-lines.svg"
                              }
                            })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex flex-column",
                        staticStyle: { gap: "4px" }
                      },
                      [
                        _c("span", { staticClass: "para-3" }, [
                          _vm._v(_vm._s(lesson.data.title))
                        ])
                      ]
                    )
                  ]
                )
              })
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }