var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      attrs: {
        id: "affiliateShareOptionModal",
        title: "Affiliate Share Settings",
        large: true,
        secondaryAction: "cancel"
      },
      on: { "secondary-cta": _vm.cancel, open: _vm.modalOpen }
    },
    [
      _c("div", { staticClass: "modal-main-container" }, [
        _c("div", { staticClass: "toggle w-100 mt-3" }, [
          _c(
            "div",
            [
              _c("AppToggle", {
                staticClass: "mb-0 app-toggle",
                model: {
                  value:
                    _vm.value.resell_rights.affiliate_terms_and_condition
                      .enabled,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.value.resell_rights.affiliate_terms_and_condition,
                      "enabled",
                      $$v
                    )
                  },
                  expression:
                    "value.resell_rights.affiliate_terms_and_condition.enabled"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "para-2 text-bold" }, [
              _vm._v("Add Affiliate Terms & Conditions")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "toggle-label-subtext para-3 text-light" },
              [
                _vm._v(
                  "\n\t\t\t\t\tEnable this to require your affiliates to first accept a Terms and Conditions before they can resell your product.\n\t\t\t\t"
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "toggle-label" }, [
              _c(
                "div",
                { staticClass: "form-group mt-4 mb-2 w-100" },
                [
                  _c("TipTapEditor", {
                    key: _vm.tick,
                    staticClass: "tiptap-editor",
                    attrs: {
                      disabled: !_vm.value.resell_rights
                        .affiliate_terms_and_condition.enabled,
                      height: 160,
                      showImage: false,
                      "default-value": _vm.defaultAffiliateTermsAndCondition
                    },
                    model: {
                      value:
                        _vm.value.resell_rights.affiliate_terms_and_condition
                          .text,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.value.resell_rights.affiliate_terms_and_condition,
                          "text",
                          $$v
                        )
                      },
                      expression:
                        "value.resell_rights.affiliate_terms_and_condition.text"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _vm.isAffiliateLegacyAllowed
          ? _c("div", { staticClass: "mt-5 mb-4 para-2 text-bold" }, [
              _vm._v("\n\t\t\tLegacy Affiliate Links\n\t\t")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isAffiliateLegacyAllowed
          ? _c("div", { staticClass: "toggle w-100" }, [
              _c(
                "div",
                { staticClass: "private-links" },
                [
                  _c("AffiliateShareForm", {
                    attrs: {
                      pageId: _vm.pageId,
                      "page-slug": _vm.pageSlug,
                      validation: _vm.validation.affiliate_shares,
                      showHelperText: false
                    },
                    on: {
                      addAffiliateShare: _vm.addAffiliateShare,
                      removeAffiliateShare: index =>
                        _vm.removeAffiliateShare(index)
                    },
                    model: {
                      value: _vm.value.affiliate_shares,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "affiliate_shares", $$v)
                      },
                      expression: "value.affiliate_shares"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-wrap justify-content-end mt-4" },
        [
          _c(
            "AppButton",
            {
              staticClass: "py-2 md-flat ml-2 mt-1",
              staticStyle: { width: "100px" },
              attrs: { name: "close-affiliate-share-modal" },
              on: { click: _vm.update }
            },
            [_vm._v("\n\t\t\tClose\n\t\t")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }