<template>
	<AppModal
		id="cancel-booking-modal"
		@close="closeModal"
		action="Yes, Cancel Appointment"
		title="Just Confirming!"
		:subheading="subheadingText"
		@cta="cancelBooking"
		actionColor="danger"
		secondary-action="Never Mind"
		@secondary-cta="closeModal"
		secondaryActionColor="light"
		:loading="loading"
	>
		<div v-if="loading">
			<div class="importing-animation"></div>
		</div>
	</AppModal>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'

	export default {
		props: {
			bookingId: { type: Number },
			title: { type: String },
			attendees: { type: String },
		},
		data() {
			return {
				loading: false,
			}
		},
		computed: {
			...mapGetters('Bookings', ['bookings']),
			subheadingText() {
				return `Are you sure you want to cancel "${this.title}" appointment with ${this.attendees}?`
			},
		},
		methods: {
			...mapActions('Auth', ['user']),

			async cancelBooking() {
				this.loading = true
				try {
					const payload = {
						timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
						reason: this.$t('Cancelled from admin by user_id:') + this.user.user_id,
					}
					const response = await this.$axios.post(`v1/booking/cancel/${this.bookingId}`, payload)

					if (response.status === 200) {
						this.$stanNotify({
							type: 'success',
							title: this.$t('Success'),
							text: this.$t('The appointment has been cancelled'),
							duration: 5000,
						})
						this.$emit('close')
					} else {
						this.closeModal()
					}
				} catch (err) {
					this.$logError(err)
					this.$stanNotify({
						type: 'error',
						title: this.$t('Something went wrong'),
						text: this.$t('Please contact us at friends@stanwith.me'),
						duration: 5000,
					})
					this.loading = false
					this.closeModal()
				}
			},
			showModal() {
				$('#cancel-booking-modal').modal('show')
			},
			closeModal() {
				$('#cancel-booking-modal').modal('hide')
			},
		},
	}
</script>
