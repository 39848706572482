var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "AppModal",
        {
          attrs: { id: "change-plan-modal", title: "Select Plan", width: 710 }
        },
        [
          _c(
            "div",
            [
              [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column gap-28px" },
                  [
                    _c("PlanIntervalSelector", {
                      attrs: { interval: _vm.newSubscriptionInterval },
                      on: { toggleInterval: _vm.togglePlanInterval }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "main-container" },
                      [
                        _c("PlanDetailCard", {
                          attrs: {
                            isCurrentPlan: _vm.isCurrentPlan(
                              _vm.$constants.TIER.CREATOR
                            ),
                            tier: _vm.$constants.TIER.CREATOR,
                            selected:
                              _vm.newSubscriptionTier ===
                              _vm.$constants.TIER.CREATOR,
                            interval: _vm.newSubscriptionInterval
                          },
                          on: {
                            onCardClick: function($event) {
                              return _vm.handlePlanClick(
                                _vm.$constants.TIER.CREATOR
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("PlanDetailCard", {
                          attrs: {
                            isCurrentPlan: _vm.isCurrentPlan(
                              _vm.$constants.TIER.CREATOR_PRO
                            ),
                            tier: _vm.$constants.TIER.CREATOR_PRO,
                            selected:
                              _vm.newSubscriptionTier ===
                              _vm.$constants.TIER.CREATOR_PRO,
                            interval: _vm.newSubscriptionInterval
                          },
                          on: {
                            onCardClick: function($event) {
                              return _vm.handlePlanClick(
                                _vm.$constants.TIER.CREATOR_PRO
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "AppButton",
                      {
                        staticClass: "w-100",
                        attrs: {
                          loading: !_vm.user || _vm.refreshingEstimate,
                          disabled:
                            (!_vm.hasPlanBeenEdited &&
                              !_vm.subscriptionIsCancelled) ||
                            !_vm.user ||
                            _vm.refreshingEstimate
                        },
                        on: {
                          click: function($event) {
                            return _vm.triggerConfirmModal()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.buttonText))]
                    )
                  ],
                  1
                )
              ]
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c("ChangePlanConfirmationModal", {
        attrs: {
          tier: _vm.newSubscriptionTier,
          interval: _vm.newSubscriptionInterval,
          isUpdatingPlan: _vm.isUpdatingPlan,
          estimateSubscriptionChangeObject: _vm.estimateSubscriptionChangeObject
        },
        on: { "on-confirm-button-click": _vm.handleConfirmaButtonClick }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }