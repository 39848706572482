<template>
	<AppModal
		ref="modal"
		title="Discount Code Options"
		:subheading="modalSubheading"
		:overflow="true"
		action="Apply Changes"
		secondary-action="Cancel"
		@cta="submitModalForm"
		@secondary-cta="hide"
	>
		<div>
			<form v-show="ready">
				<!-- Duration -->
				<div v-if="enableDuration" :class="{ 'form-error': errors.duration }">
					<label class="control-label">Duration</label>
					<AppDropdown
						class="form-control mb-1 p-0 h-auto px-2"
						v-model="duration"
						:options="durationOptions"
						:searchable="false"
						placeholder="Please Choose"
						reduce="value"
					/>
				</div>
				<!-- Expiry -->
				<div class="stan-flatpickr-theme" :class="{ 'form-error': errors.expirationDate }">
					<label class="control-label">Expiration Date</label>
					<!-- Ability to set to null -->
					<div class="date-wrapper">
						<div v-if="isCodeExpired">
							<div class="expired">
								EXPIRED
							</div>
						</div>

						<div style="width:100%" v-else>
							<FlatPickr
								class="form-control date-picker"
								placeholder="No Expiry"
								v-model="expirationDate"
								:config="{
									monthSelectorType: 'static',
									static: true,
									minDate: dateTomorrow,
									disableMobile: true,
									altInput: true,
									altFormat: 'M j, Y',
								}"
							/>
						</div>

						<InlineSvg v-show="!!expirationDate" src="/images/icons/ic-close.svg" @click="clearDate" />
					</div>
				</div>
				<div :class="{ 'form-error': errors.timezone }">
					<label class="control-label">Time Zone</label>
					<div>
						<AppDropdown
							v-model="timezone"
							class="form-control mb-1 p-0 h-auto px-2"
							:disabled="!expirationDate || isCodeExpired"
							:options="timeZonesList"
							placeholder="Time Zone"
							reduce="name"
							label="label"
							ref="timezone-dropdown"
							:dropdownRowComponent="timezoneRowComponent"
							:dropdownAutoClose="false"
							:manualControl="true"
						/>
					</div>
				</div>
			</form>
		</div>
	</AppModal>
</template>
<script>
	import FlatPickr from 'vue-flatpickr-component'
	import AppTimeZoneRow from '~/components/shared/AppTimeZoneRow'
	import { timeZonesRawList } from '~/pages/stores/components/BaseDefaults.js'

	export default {
		components: {
			FlatPickr,
		},
		props: {
			enableDuration: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				discount_code: null,
				timezone: null,
				expirationDate: null,
				duration: null,
				ready: false,
				errors: {},
				durationOptions: [
					{ value: 0, label: 'Unlimited' },
					{ value: 1, label: '1 Month' },
					{ value: 2, label: '2 Months' },
					{ value: 3, label: '3 Months' },
					{ value: 4, label: '4 Months' },
					{ value: 5, label: '5 Months' },
					{ value: 6, label: '6 Months' },
					{ value: 7, label: '7 Months' },
					{ value: 8, label: '8 Months' },
					{ value: 9, label: '9 Months' },
					{ value: 10, label: '10 Months' },
					{ value: 11, label: '11 Months' },
					{ value: 12, label: '12 Months' },
				],
				timezoneRowComponent: AppTimeZoneRow,
			}
		},
		computed: {
			modalSubheading() {
				return `Changes to ${this.discount_code?.code || 'discount'} code will be saved on page save`
			},
			dateTomorrow() {
				const dateTomorrow = new Date(new Date().getTime() + 60 * 1000 * 60 * 24)
				dateTomorrow.setHours(0, 0, 0, 0)
				return dateTomorrow
			},
			timeZonesList() {
				return timeZonesRawList
			},
			isCodeExpired() {
				if (this.expirationDate != null) {
					return this.$commonHelper.isDateInPast(this.expirationDate, this.timezone)
				}
				return false
			},
		},

		methods: {
			show(discountCode) {
				this.discount_code = discountCode
				this.resetForm()
				this.ready = true
				$(this.$refs.modal.$el).modal('show')
			},
			hide() {
				$(this.$refs.modal.$el).modal('hide')
				this.$emit('close')
			},
			clearDate(e) {
				this.expirationDate = null
				// Edge case where bootstrap autoclose doesnt work
				this.$refs['timezone-dropdown'].hide(e)
			},
			validate() {
				let passedValidation = true
				this.errors = {}

				if (this.enableDuration && (this.duration < 0 || this.duration > 12 || this.duration === undefined)) {
					this.errors.duration = true
					passedValidation = false
				}

				if (this.expirationDate) {
					if (!this.timezone) {
						this.errors.timezone = true
						passedValidation = false
					}
					if (this.$commonHelper.isDateInPast(this.expirationDate, this.timezone)) {
						this.errors.expirationDate = true
						passedValidation = false
					}
				}

				return passedValidation
			},
			resetForm() {
				this.ready = false
				this.timezone = this.discount_code?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
				this.expirationDate = this.discount_code?.valid_until || null
				this.duration = this.discount_code?.duration || 0
				this.errors = {}
			},
			submitModalForm() {
				if (this.validate()) {
					const out = {
						duration: this.duration,
						valid_until: null,
						timezone: null,
					}
					if (this.expirationDate) {
						out.valid_until = this.expirationDate
						out.timezone = this.timezone
					}
					this.$emit('onChange', out)
					this.hide()
				}
			},
		},
	}
</script>

<style lang="scss" scoped>
	.control-label {
		width: 100%;
		margin-top: 1rem;
	}
	.date-wrapper {
		display: flex;
		align-items: center;

		svg {
			cursor: pointer;
		}
	}

	.expired {
		position: relative;
		display: flex;
		align-items: center;
		min-height: 48px;
		color: red;
	}
</style>
