<template>
	<div class="position-relative">
		<div :class="{ 'creator-pro-feature-not-allowed': !isAffiliateShareAllowed }">
			<div class="affiliate-link-subheader mb-3 text-sm">
				<div class="para-2">
					Allow your customers to resell this product and earn commission for each sale they make. <a href="#">Learn More</a>
				</div>
				<div class="ml-2 analytics-button d-md-flex d-none" v-if="pageId !== -1">
					<AppButton size="sm" outline @click="navigateToAffiliateAnalytics">Affiliate Analytics</AppButton>
				</div>
			</div>

			<div class="affiliate-link-resell-rights-section mb-1">
				<div class="header d-flex justify-content-between w-100">
					<div class="toggle">
						<AppToggle class="ml-2 mb-0 mt-1 app-toggle" v-model="value.resell_rights.enabled" @valueChange="toggleResellRights" />
						<div class="toggle-label">
							<div class="toggle-label-header ">
								<div class="para-2 text-bold">Enable Affiliate Share</div>
								<div class="new-highlighter para-4 text-bold text-white">NEW</div>
							</div>
							<div class="toggle-label-subtext para-3 text-light">
								All customers who buy this product can resell at {{ value.resell_rights.percentage }}% commission.
							</div>
						</div>
					</div>
				</div>

				<div class="toggle-expand" v-if="value.resell_rights.enabled">
					<div class="revenue-share">
						<div class="para-4 text-bold">Affiliate Commission %</div>
						<div class="d-flex align-items-center">
							<AppInput
								id="affiliate_share_resell_percent"
								class="mb-1 affiliate_share_percent_input"
								:class="{ 'form-error': validation.resell_rights.percentage.$error }"
								v-model="value.resell_rights.percentage"
								type="number"
								:placeholder="$t('ex. 100')"
								suffix="%"
								:min="percent_min"
								:max="percent_max"
							/>
							<div class="ml-3">
								<InlineSvg src="/images/icons/ic-options.svg" class="settings_color cursor-pointer" @click="openAdnvaedOption" />
							</div>
						</div>
						<div>
							<small class="stan-text-danger error" v-if="validation.resell_rights.percentage.$error">
								Percentage must be between {{ percent_min }}% and {{ percent_max }}%
							</small>
						</div>
					</div>
					<div class="affiliate-share-message" v-if="false">
						<div class="icon">
							<InlineSvg src="/images/icons/ic-info.svg" />
						</div>
						<div class="para-3">
							<span class="text-bold">You've turned on Affiliate Share.</span> Upon purchase, your buyer will receive a unique affiliate
							link to be able to resell your product at {{ value.resell_rights.percentage }}% profit share. <a href="#">Learn More</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<AppUpgrade title="Want to distribute Resell Rights for your product?" v-if="!isAffiliateShareAllowed" />
		<AffiliateShareOptionsModal
			v-model="value"
			:pageId="pageId"
			:page-slug="pageSlug"
			:validation="validation"
			@addAffiliateShare="addAffiliateShare"
			@removeAffiliateShare="index => removeAffiliateShare(index)"
			id="AffiliateShareOptionsModal"
		/>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import AppUpgrade from '~/components/shared/AppUpgrade'
	import AffiliateShareOptionsModal from '~/components/modals/AffiliateShareOptionsModal'

	export default {
		name: 'AffiliateShareV2Form',
		components: {
			AppUpgrade,
			AffiliateShareOptionsModal,
		},
		props: {
			value: {
				type: Object,
				default: () => {},
			},
			validation: {
				type: Object,
				default: () => {},
			},
			pageId: {
				type: Number,
				default: -1,
			},
			pageSlug: {
				type: String,
				default: undefined,
			},
		},
		mounted() {},
		data() {
			return {
				percent_min: 1,
				percent_max: 90,
			}
		},
		computed: {
			...mapGetters('Stores', ['getStore']),
			...mapGetters('Auth', ['user']),
			affiliateShareLinkCodeLength() {
				return 6
			},
			isAffiliateShareAllowed() {
				return this.user.subscription_tier_allowed_modules?.indexOf('affiliate-share') !== -1
			},
		},
		methods: {
			...mapActions('Auth', ['fetchUser']),
			openLink(url) {
				window.open(url, '_blank')
			},
			navigateToAffiliateAnalytics() {
				this.$router.push({ name: 'affiliate_analytics', params: { page_id: this.pageId } })
			},
			openAdnvaedOption() {
				this.$nextTick(() => {
					$('#AffiliateShareOptionsModal').modal('show')
				})
			},
			addAffiliateShare() {
				this.$emit('addAffiliateShare')
			},
			removeAffiliateShare(index) {
				this.$emit('removeAffiliateShare', index)
			},
			toggleResellRights() {
				if (this.value.resell_rights.enabled) {
					this.$stanAnalytics('affiliate-share-toggle-enabled', {
						meta: { user_id: this.user?.user_id, username: this.user?.username },
						props: { page_id: this.pageId },
					})
				} else {
					this.$stanAnalytics('affiliate-share-toggle-disabled', {
						meta: { user_id: this.user?.user_id, username: this.user?.username },
						props: { page_id: this.pageId },
					})
				}
			},
		},
		watch: {},
	}
</script>

<style lang="scss" scoped>
	.affilate-link-section {
		font-style: normal;
		font-size: 14px;

		.sub-title {
			font-weight: 700;
			line-height: 130%;
			color: var(--stan-blue-5);
		}
		.al-link {
			font-weight: 600;
			line-height: 19px;
			letter-spacing: 0.04em;
			color: var(--stan-primary-primary-color);
			display: grid;
			grid-template-columns: auto auto;
			flex: 10 0;
		}
		.copy-icon::v-deep {
			max-width: 20px;
			path:not(.no-fill),
			rect:not(.no-fill) {
				fill: var(--stan-primary-primary-color);
			}
		}

		.affiliate_share_percent_input {
			min-width: 85px;
		}
	}
	.affiliate-link-subheader {
		display: flex;
		gap: 30px;
		.analytics-button {
			min-width: 130px;
		}
	}
	.affiliate-link-resell-rights-section {
		display: flex;
		padding: 20px;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
		align-self: stretch;

		margin-left: -1.25rem;
		margin-right: -1.25rem;
		margin-bottom: -1.5rem;

		.toggle {
			display: flex;
			align-items: flex-start;
			gap: 20px;
			align-self: stretch;
			.app-toggle {
				min-width: 30.22px;
			}
			.toggle-label-header {
				display: flex;
				align-items: center;
				gap: 8px;
				.menu-item-icon {
					width: 18px;
					height: 18px;
				}
				.new-highlighter {
					display: flex;
					padding: 3px 5px;
					align-items: flex-start;
					gap: 10px;
					border-radius: 3px;
					background: var(--Primary-Primary, #6355ff);
				}
			}

			.toggle-label {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 5px;
			}
		}
		.toggle-expand {
			display: inline-flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 14px;
			padding-left: 58px;
			padding-top: 15px;

			.revenue-share {
				display: inline-flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 5px;

				.affiliate_share_percent_input::v-deep {
					width: 100px;
					margin-right: 9px;
				}
			}
			.affiliate-share-message {
				display: flex;
				max-width: 608px;
				padding: 15px;
				align-items: flex-start;
				gap: 10px;
				border-radius: 8px;
				background: rgba(255, 255, 255, 0.5);
				box-shadow: 0px 5px 20px 0px rgba(0, 16, 109, 0.08);
			}
		}
	}
	.settings_color::v-deep {
		rect:not(.no-fill),
		path:not(.no-fill) {
			fill: var(--stan-primary-primary-color);
		}
	}
</style>
