<template>
	<AppModal
		id="grid-add-warning-modal"
		title="Uh-oh!"
		subheading="Unfortunately, Email Magnets cannot yet be placed inside of Grid sections. We suggest changing the layout to Stack, or moving the product to a different section!"
		action="OK"
		@cta="closeModal"
		@close="closeModal"
	>
	</AppModal>
</template>
<script>
	export default {
		methods: {
			closeModal() {
				$('#grid-add-warning-modal').modal('hide')
				this.$emit('close-modal')
			},
		},
	}
</script>
