var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "dropdown-toggle app-flat-pickr datetime-dropdown new stan-flatpickr-theme",
      class: { loading: _vm.loading }
    },
    [
      _c("img", {
        staticClass: "input-icon mr-2",
        attrs: { src: "/images/calendar-input-icon.svg" }
      }),
      _vm._v(" "),
      _c("FlatPickr", {
        staticClass: "form-control",
        attrs: {
          placeholder: _vm.placeholder,
          disableMobile: true,
          disabled: _vm.disabled,
          config: _vm.config
        },
        on: { input: _vm.onDateChange },
        model: {
          value: _vm.internalValue,
          callback: function($$v) {
            _vm.internalValue = $$v
          },
          expression: "internalValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }