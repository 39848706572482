// eslint-disable-next-line import/extensions
import store from '~/store'
import CONSTANTS from '~/global_helper/constants.js'

export default async (to, from, next) => {
	if (!store.getters['Auth/hasSubscriptionHistory'] && !store.getters['Auth/isAdminLoggedInAsUser'] && to.name !== 'subscribe') {
		next({ name: 'subscribe' })
	} else if (
		store.getters['Auth/user']?.subscription_tier === 'affiliate' &&
		!to.matched.reduce((prevValue, route) => prevValue || CONSTANTS.AFFILIATE_MENUITEMS.some(menu => menu.routeName === route.name), false)
	) {
		next({ name: 'affiliate' })
	} else {
		next()
	}
}
