<template>
	<!-- // Short version of Calendar Month view is 590px tall -->
	<div :class="{ short }">
		<div class="calendar-container header">
			<div v-for="day in days" class="calendar-cell para-3" :key="day">
				{{ day }}
			</div>
		</div>

		<Transition name="fade" mode="out-in">
			<div v-if="loading" :key="`${loading}-load`" class="calendar-container body">
				<div class="loading-cell">
					<HollowDotsSpinner :dot-size="18" :dots-num="3" color="var(--stan-primary-primary-color)" />
				</div>
			</div>
			<div v-else :key="`${loading}-cal`" class="calendar-container body">
				<div
					v-for="(data, i) in calendarGridData"
					class="calendar-cell"
					:class="{
						clickable: isThisMonth(data.yyyymmddDate),
					}"
					:key="data.yyyymmddDate"
					:ref="`${i}-${data.yyyymmddDate}-day`"
					@click="event => emptySpaceClicked(event, data.yyyymmddDate, data.popupRef)"
				>
					<div
						class="date-number"
						:class="{
							'cur-date': isToday(data.yyyymmddDate),
							'this-month': isThisMonth(data.yyyymmddDate),
						}"
					>
						<p class="para-3 ">{{ data.day }}</p>
					</div>
					<div class="events-container para-4" v-if="isThisMonth(data.yyyymmddDate)">
						<div v-for="(event, i) in getEventsStats(data.yyyymmddDate)" :key="i">
							<CalendarCellEvent :dateYYYYMMDD="data.yyyymmddDate" v-bind="event" />
						</div>
					</div>

					<AppPopup :ref="data.popupRef" :triggerRef="`${i}-${data.yyyymmddDate}-day`" aria-labelledby="calendar-events-popup">
						<div class="events-popup  para-3">
							<div class="d-flex justify-content-between pb-3 align-items-start popup-header">
								<div class="d-flex align-items-baseline">
									<div class="month">
										<b>{{ data.monthDay }}</b>
									</div>
									<div class="day">&nbsp;{{ data.dayOfWeek }}</div>
								</div>
								<div class="d-flex align-items-center">
									<div
										v-if="isPresentFuture(data.yyyymmddDate)"
										class="blockTimeButton d-flex align-items-center h5 text-bold"
										@click="blockTimeClicked(data.yyyymmddDate, data.popupRef)"
									>
										<InlineSvg src="/images/icons/ic-plus.svg" />
										Block Time
									</div>
									<div class="close-wrapper">
										<InlineSvg class="close-icon" src="/images/icons/ic-close.svg" @click="closePopup(data.popupRef)" />
									</div>
								</div>
							</div>
							<div class="events-popup-container d-flex flex-column">
								<div
									class="event-wrapper"
									:class="popUpEvent.type"
									v-for="(popUpEvent, i) in allDisplayEventsForDay(data.yyyymmddDate)"
									@click="onPopupEventClick(data.popupRef, data.yyyymmddDate, popUpEvent)"
									:key="i"
								>
									<div class="event-dot" :class="{ cancelled: popUpEvent.status == 0 }"></div>
									<div class="event-display-time">{{ popUpEvent.displayTimeFull }}</div>
									<div class="event-name">{{ popUpEvent.title }}</div>
								</div>
							</div>
						</div>
					</AppPopup>
				</div>
			</div>
		</Transition>
	</div>
</template>
<script>
	import { debounce as _debounce } from 'lodash'
	import { mapActions, mapGetters, mapMutations } from 'vuex'
	import { parseISO, format } from 'date-fns'
	import CalendarEventsMixin from './CalendarEventsMixin.js'
	import CalendarCellEvent from './CalendarCellEvent'

	// By default will show upcoming appointments via preset date filter
	export default {
		mixins: [CalendarEventsMixin],
		components: {
			CalendarCellEvent,
		},
		emits: ['filterChanged', 'triggerBlockTimeModal'],
		props: {
			filterArray: {
				type: Array,
				default: () => [],
			},
			short: {
				type: Boolean,
				default: false,
				description:
					'By default each day has 3 rows of entries, this reduces that count to 2 and removes unnecessary spacing to make the calendar itself shorter to fit better in small places.',
			},
			showBookings: {
				type: Boolean,
				default: true,
			},
			showBlocks: {
				type: Boolean,
				default: true,
			},
		},
		data() {
			return {
				days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
				monthChangeLoad: true,
			}
		},
		mounted() {
			// Check if we have the booking query filter
			const queryFiltersParts = this.$commonHelper.parseQueryFilters(this.$route.query, {
				'booking-start': {
					label: 'Date',
					type: 'date',
				},
			})
			let curDate = new Date()
			if ('booking-start' in queryFiltersParts && queryFiltersParts['booking-start'].value) {
				curDate = parseISO(queryFiltersParts['booking-start'].value)
			}
			this.setReferenceDate(this.YYYYMMDD(new Date(curDate.getFullYear(), curDate.getMonth(), 15)))
			this.updateDataCaller()
		},
		computed: {
			...mapGetters('Bookings', [
				'bookings',
				'getBookingsRefreshing',
				'getBlockedTimesRefreshing',
				'getEventsMapRefreshing',
				'eventsMap',
				'referenceDate',
			]),
			...mapGetters('Auth', ['user', 'isAdminLoggedInAsUser']),
			loading() {
				return this.getBookingsRefreshing || this.getBlockedTimesRefreshing || this.eventsMapRefreshing || this.monthChangeLoad
			},
			calendarStartDayOfMonth() {
				const curDate = parseISO(this.referenceDate)
				// Sunday - Saturday : 0 - 6
				const startDayOfMonth = new Date(curDate.getFullYear(), curDate.getMonth(), 1).getDay()
				return startDayOfMonth
			},
			calendarRowsForMonth() {
				const curDate = parseISO(this.referenceDate)
				const daysInMonth = new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0).getDate()
				// deduct number of days that can be shown in first row
				const rows = Math.ceil((daysInMonth - (7 - this.calendarStartDayOfMonth)) / 7)
				return rows + 1
			},
			calendarGridData() {
				const out = []

				// Treat as one long wrapping snake, offset the start based on the day of the week the 1st lands on
				for (let calRow = 0; calRow < this.calendarRowsForMonth; calRow++) {
					for (let calCol = 0; calCol < 7; calCol++) {
						// Generate date by offsetting calendar position from start of current month
						const dayOfMonth = calRow * 7 + calCol - this.calendarStartDayOfMonth + 1 // `dayOfMonth = 1` on first of current month
						const curDate = parseISO(this.referenceDate)
						const calDate = new Date(curDate.getFullYear(), curDate.getMonth(), dayOfMonth)

						// calendar object
						const calObj = {
							day: calDate.getDate(),
							yyyymmddDate: this.YYYYMMDD(calDate),
							popupRef: `popup${this.YYYYMMDD(calDate)}`,
							monthDay: format(calDate, 'MMMM do'),
							dayOfWeek: format(calDate, 'EEEE'),
						}

						out.push(calObj)
					}
				}

				return out
			},
		},
		watch: {
			referenceDate() {
				this.updateDataCaller()
			},
			getBookingsRefreshing() {
				this.debounceCalculateEvents()
			},
			getBlockedTimesRefreshing() {
				this.debounceCalculateEvents()
			},
		},
		created() {},
		methods: {
			...mapActions('Bookings', ['fetchBookings', 'fetchBlockedTimes']),
			...mapMutations('Bookings', [
				'setBookingsRefreshing',
				'setBlockedTimesRefreshing',
				'calculateEventsMapHelper',
				'setEventsMapRefreshing',
				'setReferenceDate',
			]),
			blockClicked(yyyymmddDate, id) {
				if (!this.isPresentFuture(yyyymmddDate) || !this.isThisMonth(yyyymmddDate) || !this.eventsMap.has(yyyymmddDate)) {
					return
				}

				const events = this.eventsMap.get(yyyymmddDate)
				const blockEvent = events.find(e => e?.id === id && e.type === 'blocked_event')

				const blockedTimeSlot = {
					id: blockEvent.id,
					start: blockEvent.start_utc,
					end: blockEvent.end_utc,
				}

				this.$emit('triggerBlockTimeModal', blockedTimeSlot)
			},
			blockTimeClicked(yyyymmddDate, ref) {
				const blockTimeSlot = this.createBlockTimeEvent(yyyymmddDate)
				if (blockTimeSlot) {
					this.$emit('triggerBlockTimeModal', blockTimeSlot)
					this.$refs[ref][0].togglePopup()
				}
			},
			emptySpaceClicked(event, yyyymmddDate, ref) {
				if (!this.isThisMonth(yyyymmddDate)) {
					return
				}

				if (this.getEvents(yyyymmddDate).length === 0) {
					this.blockTimeClicked(yyyymmddDate, ref)
					return
				}
				// If calendar cell, or calendar cell entry clicked open the popup to view data
				// Parent element is checked on `cell-event-wrapper` since the children title date etc. may be clicked
				if (
					event &&
					(event?.srcElement.classList.contains('calendar-cell') ||
						event?.srcElement.classList.contains('events-container') ||
						event?.srcElement.classList.contains('cell-event-wrapper') ||
						event?.srcElement?.parentElement?.classList.contains('cell-event-wrapper'))
				) {
					this.$refs[ref][0].togglePopup()
				}
			},
			closePopup(ref) {
				this.$refs[ref][0].togglePopup()
			},
			onPopupEventClick(ref, dateYYYYMMDD, event) {
				if (event.type === this.CALENDAR_TYPE.EVENT) {
					this.$stanAnalytics('appointments-selected', {
						meta: { user_id: this.userId, username: this.username },
						props: {
							booking_id: event.id,
							from_popup: true,
							tab: this.MONTH_TAB_ID,
						},
					})
					this.$router.push({
						name: 'appointment_details',
						params: { bookingId: event.id },
					})
				} else if (event.type === this.CALENDAR_TYPE.BLOCKED) {
					this.blockClicked(dateYYYYMMDD, event.id)
					this.closePopup(ref)
				}
			},
			getEvents(dateKey) {
				if (this.eventsMap.has(dateKey)) {
					return this.eventsMap.get(dateKey)
				}
				return []
			},
			getEventsStats(dateKey) {
				// Aggregate count of events to show
				const events = this.getEvents(dateKey)

				let bookingCount = 0
				let blocksCount = 0

				for (const event of events) {
					if (event.booking_id) {
						bookingCount++
					} else {
						blocksCount++
					}
				}

				const out = []
				if (bookingCount > 0) {
					out.push({
						type: this.CALENDAR_TYPE.EVENT,
						count: bookingCount,
						title: bookingCount > 1 ? 'Appointments' : 'Appointment',
					})
				}
				if (blocksCount > 0) {
					out.push({
						type: this.CALENDAR_TYPE.CAL_BLOCKED,
						count: blocksCount,
						title: blocksCount > 1 ? 'Blocked Times' : 'Blocked Time',
					})
				}

				return out
			},
			allDisplayEventsForDay(dateKey) {
				const events = this.getEvents(dateKey)
				return events.map(e => this.makeDisplayEvent(e))
			},
			makeDisplayEvent(event) {
				let out = {}
				if (event.booking_id) {
					out = {
						type: this.CALENDAR_TYPE.EVENT,
						id: event.booking_id,
						status: event.status,
						title: event.title,
						displayTime: event.displayTime,
						displayTimeFull: event.displayTimeFull,
					}
				} else if (event.type === 'blocked_event') {
					out = {
						type: this.CALENDAR_TYPE.BLOCKED,
						id: event.id,
						status: undefined,
						title: event.title || 'Block',
						displayTime: event.displayTime,
						displayTimeFull: event.displayTimeFull,
					}
				} else {
					out = {
						type: this.CALENDAR_TYPE.CAL_BLOCKED,
						id: undefined,
						status: undefined,
						title: event.title || 'Gcal Block',
						displayTime: event.displayTime,
						displayTimeFull: event.displayTimeFull,
					}
				}

				return out
			},
			async incrementMonth(numMonths) {
				this.monthChangeLoad = true
				this.setEventsMapRefreshing(true)
				await this.$nextTick()
				// 15 day offset to avoid possible issues with rollover days on mont edges, feb 29th + 1 month etc.
				const newDate = parseISO(this.referenceDate)
				newDate.setMonth(newDate.getMonth() + numMonths)
				this.setReferenceDate(this.YYYYMMDD(new Date(newDate.getFullYear(), newDate.getMonth(), 15)))
			},
			refreshBookingsData() {
				this.updateDataCaller()
			},
			// eslint-disable-next-line func-names
			updateDataCaller: _debounce(async function() {
				this.setEventsMapRefreshing(true)
				this.monthChangeLoad = false

				const curDate = parseISO(this.referenceDate)
				const startDate = this.YYYYMMDD(new Date(curDate.getFullYear(), curDate.getMonth(), 1))
				const endDate = this.YYYYMMDD(new Date(curDate.getFullYear(), curDate.getMonth() + 1, 1))

				// Get bookings
				const baseFilters = this.filterArray.filter(f => f.fieldName !== 'booking-start')
				let filterArrayUse = [
					...baseFilters,
					{ fieldName: 'booking-start', operator: '>=', value: startDate, type: 'date', urlCode: 'gte' },
					{ fieldName: 'booking-start', operator: '<=', value: endDate, type: 'date', urlCode: 'lte' },
				]
				// When switching months and applying filters, we update to keep a coheisve experience
				this.$emit('filterChanged', filterArrayUse, false)

				filterArrayUse = [...filterArrayUse, { fieldName: 'booking-status', operator: '=', value: '2', type: 'select', urlCode: 'eq' }]
				if (this.showBookings) {
					this.setBookingsRefreshing(true)
					this.fetchBookings({ filterArray: filterArrayUse })
				}
				if (this.showBlocks) {
					this.setBlockedTimesRefreshing(true)
					this.fetchBlockedTimes({ start: startDate, end: endDate })
				}
			}, 300),
			// eslint-disable-next-line func-names
			debounceCalculateEvents: _debounce(function() {
				if (this.getBookingsRefreshing || this.getBlockedTimesRefreshing) {
					return
				}
				this.calculateEventsMapHelper()
				this.$nextTick(() => {
					this.setEventsMapRefreshing(false)
				})
			}, 200),
		},
	}
</script>
<style lang="scss" scoped>
	.short {
		.calendar-container {
			&.header {
				margin-top: 0px;
				margin-bottom: 0px;
			}

			&.body {
				grid-template-rows: repeat(6, 90px);
			}
		}
	}

	.calendar-container {
		display: grid;
		// 7 days in a week
		grid-template-columns: repeat(7, minmax(0, 1fr));

		&.header {
			margin-top: 32px;
			margin-bottom: 8px;
			grid-template-rows: 48px;

			.calendar-cell {
				background: var(--stan-gray-light-color);
				display: flex;
				align-items: center;
				justify-content: center;

				color: var(--stan-text-light-color);
			}
		}

		&.body {
			// Worst case number of weeks to show
			grid-template-rows: repeat(6, 90px);

			.loading-cell {
				grid-area: 1 / 1 / 7 / 8;
				align-items: center;
				justify-content: center;
				display: flex;
			}

			.calendar-cell {
				border: 1.5px solid var(--stan-gray-primary-color);

				&.header {
					background: var(--stan-gray-light-color);
				}

				.date-number {
					margin: 8px;
					width: 22px;
					height: 22px;
					border-radius: 50%;
					background: #fff;
					display: flex;
					justify-content: center;
					align-items: center;
					text-align: center;
					color: var(--stan-gray-strike-color);

					p {
						margin: 0;
						font-size: 0.75rem;
						line-height: 0.75rem;
					}

					&.this-month {
						color: var(--stan-text-light-color);
					}

					&.this-month.cur-date {
						color: #fff;
						background: var(--stan-primary-primary-color);
					}
				}

				&.clickable {
					cursor: pointer;
				}

				.events-container {
					height: 50px;
					overflow: hidden;
					display: flex;
					flex-direction: column;
					gap: 5px;
					padding: 0px 8px;
				}
			}
		}
	}

	.events-popup {
		min-width: min(410px, 90vw);
		max-height: min(280px, 90vh);
		background: #fff;
		padding: 30px 25px;
		margin: 5px;
		border-radius: 15px;
		box-shadow: var(--stan-box-shadow);

		.events-popup-container {
			overflow: auto;
			max-height: 180px; // 5 rows at 36px each

			.event-wrapper {
				padding: 10px 6px;
				min-height: 36px;

				.event-dot {
					min-width: 12px;
					min-height: 12px;
				}

				&:hover {
					background: var(--stan-primary-light-color);
				}

				.event-dot {
					min-width: 12px;
					min-height: 12px;
					border-radius: 50%;
					background: var(--stan-primary-primary-color);

					&.cancelled {
						background: var(--stan-negative-primary-color);
					}
				}

				.event-display-time {
					color: var(--stan-text-light-color);
				}
				.event-name {
					overflow: hidden;
					text-overflow: ellipsis;
				}

				&.cal_blocked {
					cursor: default;

					.event-dot {
						background: var(--stan-text-light-color);
					}
					&:hover {
						background: initial;
					}

					> {
						.event-name {
							color: var(--stan-text-light-color);
						}
					}
				}

				&.blocked {
					.event-dot {
						background: var(--stan-danger);
					}
					&:hover {
						background: var(--stan-negative-light-color);
					}

					> {
						.event-display-time {
							color: var(--stan-negative-soft-color);
						}
						.event-name {
							color: var(--stan-danger);
						}
					}
				}
			}
		}
		.event-wrapper {
			border-radius: 2px;
			width: 100%;
			align-items: center;
			justify-content: flex-start;
			gap: 6px;
			min-height: 14px;
			padding: 3px 6px;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			text-wrap: nowrap;
			cursor: pointer;
		}

		.popup-header {
			cursor: default;
			.month {
				font-size: 14px;
			}
			.day {
				color: var(--stan-text-light-color);
			}

			.close-wrapper {
				height: 20px;
				width: 20px;
				border-radius: 50%;
				background: var(--stan-gray-light-color);
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				cursor: pointer;
				&:hover {
					background: var(--stan-primary-light-color);
				}

				.close-icon {
					height: 14px;
					width: 14px;
				}
			}
		}
	}

	.blockTimeButton {
		cursor: pointer;
		font-size: 12px;
		padding-right: 12px;
		gap: 3px;
		color: var(--stan-primary-primary-color);
		svg::v-deep {
			height: 20px;
			width: 20px;
			path:not(.no-fill),
			rect:not(.no-fill) {
				fill: var(--stan-primary-primary-color);
			}
		}
	}
</style>
