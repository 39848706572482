const MainContainer = () => import('~/components/layouts/MainContainer').then(m => m.default || m)

const moduleRoutes = {
	path: '/audience',
	name: 'audienceIndex',
	redirect: '/audience',
	component: MainContainer,
	children: [
		{
			path: '',
			name: 'audience',
			component: () => import('./Audience').then(m => m.default || m),
			meta: {
				pageTitle: 'My Customers',
				breadcrumbs: [{ title: 'My Customers', active: true }],
			},
		},
		{
			path: 'details/:userFanId',
			name: 'customer_details',
			component: () => import('./CustomerDetails').then(m => m.default || m),
			meta: {
				pageTitle: 'Overview',
				breadcrumbs: [
					{ title: 'My Customers', to: 'audience' },
					{ title: 'Overview', active: true },
				],
			},
		},
	],
}

export default moduleRoutes
