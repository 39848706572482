import axios from 'axios'

export const state = {
	customers: {},
	customersRefreshing: false,
}

export const getters = {
	getCustomers: state => state.customers,
	getCustomersRefreshing: state => state.customersRefreshing,
}

export const mutations = {
	setCustomers(state, payload) {
		state.customers = payload
	},
	setCustomersRefreshing(state, flag) {
		state.customersRefreshing = flag
	},
}

export const actions = {
	async fetchCustomers({ commit }, payload) {
		axios
			.get('v1/orders/audience', {
				params: {
					page: payload.page,
					pageSize: payload.pageSize || 10,
					filter_array: JSON.stringify(payload.filterArray),
					timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				},
			})
			.then(response => {
				const customers = response.data
				commit('setCustomers', customers)
				commit('setCustomersRefreshing', false)
			})
			.catch(error => {
				console.error(error)
				commit('setCustomersRefreshing', false)
			})
	},
}
