const MainContainer = () => import('~/components/layouts/MainContainer').then(m => m.default || m)

const moduleRoutes = {
	path: '/referrals',
	name: 'ReferralsIndex',
	redirect: '/referrals',
	component: MainContainer,
	children: [
		{
			path: '',
			name: 'referrals',
			component: () => import('./Referrals').then(m => m.default || m),
			meta: {
				pageTitle: 'Referrals',
				breadcrumbs: [{ title: 'Referrals', active: true }],
			},
		},
		{
			path: 'metrics',
			name: 'referral_metrics',
			component: () => import('./ReferralMetrics').then(m => m.default || m),
			meta: {
				pageTitle: 'Referral Metrics',
				breadcrumbs: [{ title: 'Referral Metrics', active: true }],
			},
		},
		{
			path: ':page',
			name: 'paginate_referrals',
			component: () => import('./Referrals').then(m => m.default || m),
			meta: {
				pageTitle: 'Referrals',
				breadcrumbs: [{ title: 'Referrals', active: true }],
			},
		},
	],
}

export default moduleRoutes
