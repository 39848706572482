var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("StanleyTyping", { attrs: { text: _vm.text } }, [
        _c(
          "div",
          {
            staticClass: "embed-responsive embed-responsive-16by9 video-frame",
            attrs: { id: "video-play-wrapper" }
          },
          [
            _c("iframe", {
              staticClass: "embed-responsive-item",
              attrs: {
                src: _vm.video_uri,
                frameborder: "0",
                allow: "encrypted-media; gyroscope; picture-in-picture",
                allowfullscreen: ""
              }
            })
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }