<template>
	<div :class="`stan-banner ${severity} ${!buttons.length ? 'cursor-pointer' : ''}`" @click.self="fullClick">
		<div class="banner-content">
			<span class="banner-icon" v-if="icon" @click="fullClick">
				<InlineSvg class="icon-svg" :src="icon" />
			</span>

			<div class="text-container" @click="fullClick">
				<span class="title h4 text-bold" v-if="title">
					{{ title }}
				</span>
				<span class="subtitle para-2" v-if="subtitle">
					{{ subtitle }}
				</span>
			</div>

			<div id="slot-container">
				<slot> </slot>
			</div>

			<div class="button-container">
				<AppButton
					v-for="(button, index) in buttons"
					:key="index"
					v-bind="button"
					@click="button.click"
					class="button"
					:loading="ctaLoading"
					>{{ button.name }}</AppButton
				>
				<div class="action-icon" v-if="!buttons.length && rightIcon" @click="fullClick">
					<InlineSvg class="icon-svg" :src="rightIcon" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'AppBanner',
		props: {
			icon: {
				type: String,
				default: '',
			},
			title: {
				type: String,
				default: '',
			},
			subtitle: {
				type: String,
				default: '',
			},
			rightIcon: {
				type: String,
				default: '/images/icons/ic-arrow-right.svg',
			},
			severity: {
				type: String,
				default: 'info',
				validate: () => ['info', 'warning'].includes(this.level),
			},
			buttons: {
				type: Array,
				default: () => [],
			},
			ctaLoading: {
				type: Boolean,
				default: false,
			},
		},
		methods: {
			fullClick() {
				if (!this.buttons.length) this.$emit('click')
			},
		},
	}
</script>

<style lang="scss" scoped>
	.stan-banner {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 15px;

		padding: 1.25rem;
		margin-bottom: 2rem;

		&.info {
			background-color: var(--stan-gray-soft-color);
		}

		&.warning {
			background-color: var(--stan-yellow-4);
			border: 1px solid var(--stan-yellow-1);
		}

		&.stan-info {
			background-color: var(--stan-primary-light-color);
		}

		.banner-content {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			gap: 15px;
			.banner-icon {
				transform: translateY(-1px);
				aspect-ratio: 1;
				display: none;
				@media (min-width: 768px) {
					display: block;
				}

				.icon-svg::v-deep {
					height: 24px;
					width: 24px;
					path {
						fill: var(--stan-text-dark-color);
					}
				}
			}
			.text-container {
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				width: 50%;
				flex-grow: 100;
				gap: 8px;

				@media (max-width: 768px) {
					width: 100%;
				}
				.title {
					line-height: 150%;
					vertical-align: center;
					letter-spacing: 0;
				}
				.subtitle {
					color: var(--stan-text-light-color);
				}
			}
			.button-container {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				column-gap: 15px;
				row-gap: 8px;
				justify-content: flex-end;
				flex: 0 0 auto;

				@media (max-width: 450px) {
					flex-direction: column;
				}
				.action-icon::v-deep {
					path {
						fill: var(--stan-primary-primary-color);
					}
				}
			}
		}
	}
</style>
