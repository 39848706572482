/*
feature name: <kebab-case> --> analytics table

const flagConfig = {
	'feature-1-name': { probability: 0.5 }, <-- percentage of probability (from 0 to 1)
	'feature-2-name': { probability: 0.5, deps: ['feature-1-name'] }, <-- dependencies
}

usage:
	this.$isFlag('feature-1-name')
*/

import Cookies from 'js-cookie'

const flagConfig = {
	'example-flag': { probability: 0.5 },
}

// Intermediate independent state without considering for dependencies [DO NOT USE OUTSIDE OF THIS SCRIPT]
for (const key of Object.keys(flagConfig)) {
	if (flagConfig[key] === undefined) {
		console.error('Feature flag is not defined: ', key)
		flagConfig[key].state = false
	} else {
		flagConfig[key].state = Math.random() <= flagConfig[key].probability
	}
}

const checkFlag = name => {
	const flagData = flagConfig[name]
	const deps = flagData.deps || []

	if (Cookies.get(name)) {
		return Cookies.get(name) === 'true'
	}

	// Turn off flag if it doesn't have dependencies
	for (let i = 0; i < deps.length; i++) {
		if (flagConfig[deps[i]] === undefined) {
			console.error('Unknown flag dependency', deps[i])
			return false
		}
		if (flagConfig[deps[i]].state === false) {
			return false
		}
	}

	try {
		if (!flagData) {
			console.error('Unknown feature flag, ', name)
			return false
		}
		return flagData.state
	} catch (error) {
		console.error(error)
		return false
	}
}

const computedFlags = {}
const computedFlagData = {}

for (const key of Object.keys(flagConfig)) {
	const value = checkFlag(key)
	computedFlags[key] = value
	computedFlagData[key] = {
		experiment_variant: value ? 1 : 0,
	}
	Cookies.set(key, value)
}

// eslint-disable-next-line no-underscore-dangle
const isFlag_ = name => {
	if (computedFlags[name] === undefined) {
		console.error('Feature flag is not defined: ', name)
		return false
	}
	return computedFlags[name]
}

export const getFlags = () => computedFlagData
export const isFlag = isFlag_

export default isFlag
