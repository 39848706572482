<template>
	<AppModal id="manage-membership-modal" :action="membership.buttonText" :title="modalTitle" @cta="updateSubscription" :loading="loading">
		<div class="d-flex my-2">
			<div class="col-12">
				<p class="sub-text text-center">
					{{ `Are you sure you want to ${membership.action} ${fanName}'s subscription to ` }}
					<span class="bold-text">{{ membership.productTitle }}</span
					>?
				</p>
			</div>
		</div>
	</AppModal>
</template>

<script>
	export default {
		props: {
			membership: { type: Object, required: true },
			fanName: { type: String, required: true },
		},
		data() {
			return {
				loading: false,
				modalTitle: this.$t('Just Confirming!'),
			}
		},
		computed: {
			successMessage() {
				// eslint-disable-next-line quotes
				return `${this.$t("We've sent a confirmation email to")} ${this.fanName}`
			},
		},
		methods: {
			updateSubscription() {
				this.loading = true
				const payload = {
					action: this.membership.actionKey,
					subscription_id: this.membership.subscriptionId,
				}
				this.$axios
					.post('v1/membership/manage', payload)
					.then(() => {
						this.$stanNotify({
							type: 'success',
							title: this.$t('Great!'),
							text: this.successMessage,
						})
					})
					.catch(err => {
						const errorMessage = err.response.data.message.concat(' ', this.$t('Please contact us at friends@stanwith.me'))
						this.$stanNotify({
							type: 'error',
							title: this.$t('Something went wrong'),
							text: errorMessage,
						})
						this.$logError(err)
					})
					.finally(() => {
						this.$emit('updated')
						$('#manage-membership-modal').modal('hide')
						this.loading = false
					})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.bold-text {
		font-weight: bold;
		color: var(--stan-primary-primary-color);
	}
</style>
