<template>
	<AppModal
		id="booking-reschedule-modal"
		@close="closeModal"
		:action="actionText"
		:title="headingText"
		:subheading="subheadingText"
		@cta="nextStep"
		:secondary-action="secondaryActionText"
		@secondary-cta="closeModal"
		secondaryActionColor="light"
		:overflow="true"
		:loading="loading"
	>
		<Transition name="fade" mode="out-in">
			<template v-if="step === 1">
				<div>
					<div class="slot-section d-flex flex-wrap gap w-100 justify-content-start align-items-start pb-3">
						<div class="flex-50 ">
							<label class="control-label mb-1">Date</label>
							<AppFlatPickr
								ref="calendar"
								custom_class="form-control"
								placeholder="Select Date"
								v-model="date"
								:disableMobile="true"
								:enable_dates="availableDates"
								:loading="loadingAvailabilty"
								@onMonthChange="onMonthChange"
								@onDateSelect="onDateSelected"
							/>
							<div class="para-3" style="min-width: 85px;">
								<span style="color:var(--stan-text-negative-color)" v-if="errors.date">{{ errors.date }}</span>
							</div>
						</div>
					</div>
					<div class="slot-section d-flex flex-wrap gap w-100 justify-content-start align-items-start pb-3">
						<div class="flex-50">
							<label class="control-label mb-1">From</label>
							<div class="datetime-dropdown new">
								<img src="/images/balance-time.svg" class="input-icon mr-2" />
								<AppDropdown
									class="form-control"
									placeholder="Start Time"
									:options="fromTimeOptions"
									:searchable="false"
									label="name"
									reduce="value"
									:rightAlign="false"
									:disabled="fromTimeOptions.length < 1"
									v-model="start_time"
									@select="onTimeSlotSelected"
								/>
							</div>
							<div class="para-3" style="min-width: 85px;">
								<span style="color:var(--stan-text-negative-color)" v-if="errors.time">{{ errors.time }}</span>
							</div>
						</div>
						<div class="flex-50">
							<label class="control-label mb-1">To</label>
							<div class="datetime-dropdown new">
								<img src="/images/balance-time.svg" class="input-icon mr-2" />
								<AppDropdown
									class="form-control"
									placeholder="End Time"
									:searchable="false"
									:options="toTimeOptions"
									label="name"
									reduce="value"
									:rightAlign="false"
									:disabled="toTimeOptions.length < 2"
									v-model="end_time"
								/>
							</div>
							<div class="para-3" style="min-width: 85px;">
								<span style="color:var(--stan-text-negative-color)" v-if="errors.time">{{ errors.time }}</span>
							</div>
						</div>
					</div>
					<div>
						<label class="control-label">Reason (optional)</label>
						<textarea class="form-control mb-2 mb-xl-0" placeholder="Reason..." rows="3" v-model="reason" />
					</div>
				</div>
			</template>
		</Transition>
	</AppModal>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import { format } from 'date-fns'
	import { getTimesValue } from '~/pages/stores/components/BaseDefaults.js'

	export default {
		props: {
			bookingId: { type: Number },
			title: { type: String },
			attendees: { type: String },
		},
		data() {
			return {
				loading: false,
				loadingAvailabilty: false,
				step: 1,
				date: undefined,
				start_time: undefined,
				end_time: undefined,
				reason: '',
				availability: {},
				errors: {
					date: undefined,
					time: undefined,
				},
			}
		},
		mounted() {
			this.reset()
		},
		computed: {
			...mapGetters('Bookings', ['bookings']),
			...mapGetters('Stores', ['getStore', 'getFunnels']),
			actionText() {
				return this.step === 1 ? 'Reschedule' : 'Thanks'
			},
			secondaryActionText() {
				return this.step === 1 ? 'Never Mind' : undefined
			},
			headingText() {
				return this.step === 1 ? 'Reschedule Your Meeting' : `Meeting Successfully Rescheduled`
			},
			subheadingText() {
				return this.step === 1 ? `"${this.title}" with ${this.attendees}` : `We sent a confirmation to ${this.attendees}`
			},
			availableDates() {
				const dates = Object.keys(this.availability)
				const out = dates.filter(d => this.availability[d].length > 0)
				return out
			},
			fromTimeOptions() {
				if (this.date && this.availability && this.availability[this.date]) {
					// eslint-disable-next-line arrow-body-style
					return this.availability[this.date].map(avail => {
						return { name: avail.startTime, value: avail.startTime }
					})
				}
				return []
			},
			toTimeOptions() {
				if (this.date && this.start_time && this.availability && this.availability[this.date]) {
					// Attempt to create options by combining consecutive periods
					let curDateAvailability = this.availability[this.date]
					const startIndex = curDateAvailability.findIndex(avail => avail.startTime === this.start_time)
					curDateAvailability = curDateAvailability.slice(startIndex, curDateAvailability.length)

					// Max range is 480 minutes -> 8 hours -> (endValue-StartValue) <= 8
					const startValue = getTimesValue(this.start_time)

					// Get's continuous list of slots so we can create the maximum range
					const toOptions = curDateAvailability.reduce((acc, slot) => {
						const hoursSinceStart = getTimesValue(slot.endTime) - startValue
						if (
							hoursSinceStart <= 8 &&
							(acc.length === 0 || getTimesValue(acc[acc.length - 1].endTime) >= getTimesValue(slot.startTime))
						) {
							acc.push(slot)
						}
						return acc
					}, [])

					return toOptions.map(avail => {
						const hoursSinceStart = getTimesValue(avail.endTime) - startValue
						return { name: `${avail.endTime} (${hoursSinceStart * 60}min)`, value: avail.endTime }
					})
				}
				return []
			},
		},
		methods: {
			...mapActions('Auth', ['user']),
			async nextStep() {
				this.loading = true
				if (this.step === 1) {
					const isValid = this.validate()
					if (isValid) {
						const success = await this.rescheduleMeeting()
						if (success) {
							this.step = 2
						}
					}
				} else {
					this.closeModal()
				}
				this.loading = false
			},
			validate() {
				let valid = true
				if (!this.date) {
					this.errors.date = 'Please select a date'
					valid = false
				}

				if (!this.start_time || !this.end_time) {
					this.errors.time = 'Please select a time slot'
					valid = false
				}
				return valid
			},
			onTimeSlotSelected(selectedStartTime) {
				this.start_time = selectedStartTime

				if (this.date && this.availability && this.availability[this.date]) {
					const slot = this.availability[this.date].find(avail => avail.startTime === selectedStartTime)
					this.end_time = slot.endTime
					this.errors.time = undefined
				}
			},
			onDateSelected() {
				if (this.date) {
					this.errors.date = undefined
				}
			},
			onMonthChange({ year, month }) {
				this.getAvailabilty(year, month)
			},
			async getAvailabilty(year, month) {
				try {
					this.loadingAvailabilty = true

					const startMonth = format(new Date(year, month, 1), this.$constants.DATE_FORMAT.ISO_8601_DATE)
					const endMonth = format(new Date(year, month + 1, 0), this.$constants.DATE_FORMAT.ISO_8601_DATE)
					const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

					const params = {
						start: startMonth,
						end: endMonth,
						timezone,
					}

					const resp = await this.$axios.get(`v1/booking/availability/${this.bookingId}`, {
						params,
					})

					if (resp.status === 200) {
						this.availability = { ...this.availability, ...resp.data.available_slots }
						this.loadingAvailabilty = false
						return true
					}
				} catch (err) {
					this.$logError(err)
				}
				this.$stanNotify({
					type: 'error',
					title: this.$t('Something went wrong'),
					text: this.$t('Please contact us at friends@stanwith.me'),
					duration: 5000,
				})
				this.closeModal()
				return false
			},
			async rescheduleMeeting() {
				try {
					const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

					const payload = {
						timezone,
						start: `${this.date} ${this.start_time}`,
						end: `${this.date} ${this.end_time}`,
						date: this.date,
						reschedule_reason: this.reason,
					}
					await this.$axios.post(`v1/booking/reschedule/${this.bookingId}`, payload)
					this.$emit('rescheduled')
					return true
				} catch (err) {
					this.$logError(err)
					// For 400 errors we do not close the module just let the creator know
					if (err.response.status === 400) {
						this.$stanNotify({
							type: 'error',
							title: 'Sorry',
							text: err.response.data.message,
							duration: 5000,
						})
						this.reset(true)
						return false
					}
				}
				this.$stanNotify({
					type: 'error',
					title: this.$t('Something went wrong'),
					text: this.$t('Please contact us at friends@stanwith.me'),
					duration: 5000,
				})
				this.loading = false
				this.closeModal()
				return false
			},
			reset(partial = false) {
				this.loading = false
				this.step = 1
				this.date = undefined
				this.start_time = undefined
				this.end_time = undefined
				this.reason = partial ? this.reason : ''
				this.errors = {
					date: undefined,
					time: undefined,
				}
				this.$refs?.calendar?.reset()
			},
			showModal() {
				const curDate = new Date()
				this.getAvailabilty(curDate.getFullYear(), curDate.getMonth())
				$('#booking-reschedule-modal').modal('show')
			},
			closeModal() {
				$('#booking-reschedule-modal').modal('hide')
				const self = this
				setTimeout(() => {
					self.reset()
				}, 100)
			},
		},
	}
</script>

<style lang="scss" scoped>
	.form-style-text {
		display: flex;
		padding-left: 32px;
		align-items: center;
	}
	.flex-50 {
		flex: 1 1 0;
	}
	.datetime-dropdown {
		&::after {
			margin-left: -20px;
			margin-right: 20px;
			pointer-events: none;
		}
		&.new {
			position: relative;
			input {
				padding-left: 32px;
			}
			.form-control {
				padding-left: 32px;
			}
			.input-icon {
				z-index: 10;
				top: 50%;
				margin: 0;
				left: 9px;
				transform: translateY(-50%);
				position: absolute;
				pointer-events: none;
				height: 16px;
				width: 16px;
			}
			&::after {
				position: absolute;
				right: 10px;
				pointer-events: none;
				top: 50%;
				transform: translateY(-50%);
				margin: 0;
			}
		}
	}
</style>
