const moduleRoutes = [
	{
		path: '/unsubscribe',
		name: 'unsubscribe',
		component: () => import('./unsubscribe').then(m => m.default || m),
	},
	{
		path: '/update_billing',
		name: 'update_billing',
		component: () => import('./billing/update_billing').then(m => m.default || m),
	},
	{
		path: '/update_billing/success',
		name: 'success',
		component: () => import('./billing/success').then(m => m.default || m),
	},
]

export default moduleRoutes
