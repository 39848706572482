var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      ref: "imageCropModal",
      attrs: { title: _vm.$t("Crop Image") },
      on: { close: _vm.close }
    },
    [
      _c("div", [
        _c("div", { staticClass: "canvas-wrapper" }, [
          _c(
            "canvas",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading"
                }
              ],
              ref: "imageCropperCanvas",
              attrs: { id: "canvas" }
            },
            [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(
                    _vm.$t(
                      "Your browser does not support the HTML5 canvas element."
                    )
                  ) +
                  "\n\t\t\t"
              )
            ]
          ),
          _vm._v(" "),
          _vm.loading
            ? _c(
                "div",
                { staticClass: "d-flex h-100" },
                [
                  _c("HollowDotsSpinner", {
                    attrs: {
                      "dot-size": 18,
                      "dots-num": 3,
                      color: "var(--stan-primary-primary-color)"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex mt-3 justify-content-between" },
          [
            _c(
              "AppButton",
              {
                staticClass: "w-100px",
                attrs: { name: "reset-image-cropper-modal", outline: "" },
                on: { click: _vm.reset }
              },
              [_vm._v(_vm._s(_vm.$t("Reset")))]
            ),
            _vm._v(" "),
            _c(
              "AppButton",
              {
                staticClass: "w-150px",
                attrs: { name: "crop-image-cropper-modal" },
                on: { click: _vm.crop }
              },
              [_vm._v(_vm._s(_vm.$t("Crop")))]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }