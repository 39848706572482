<template>
	<ContentPlaceholders :rounded="rounded">
		<ContentPlaceholdersHeading v-if="heading" :img="img" />
		<ContentPlaceholdersText :lines="lines" />
	</ContentPlaceholders>
</template>

<script>
	export default {
		props: {
			rounded: { type: Boolean, default: false },
			img: { type: Boolean, default: false },
			lines: { type: Number, default: 0 },
			heading: { type: Boolean, default: true },
		},
	}
</script>
<style lang="scss" scoped>
	.vue-content-placeholders-is-animated::v-deep {
		&.vue-content-placeholders-heading__title::before,
		&.vue-content-placeholders-img::before,
		&.vue-content-placeholders-heading__subtitle::before {
			-webkit-animation-duration: 3s;
			animation-duration: 3s;
		}

		.vue-content-placeholders-text__line,
		.vue-content-placeholders-heading__title,
		.vue-content-placeholders-heading__subtitle {
			border-radius: 0.5rem;
		}
	}
</style>
