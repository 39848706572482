import { render, staticRenderFns } from "./ProductSelectionModal.vue?vue&type=template&id=bf5fa798&scoped=true"
import script from "./ProductSelectionModal.vue?vue&type=script&lang=js"
export * from "./ProductSelectionModal.vue?vue&type=script&lang=js"
import style0 from "./ProductSelectionModal.vue?vue&type=style&index=0&id=bf5fa798&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf5fa798",
  null
  
)

export default component.exports