<template>
	<div>
		<div class="menu-wrapper w-100 h-100 rounded desktop-menu d-none d-lg-block" id="dropdown-wrapper-account-desktop">
			<AppPopup ref="popup" aria-labelledby="dropdownMenuLink-account" triggerRef="popup-trigger">
				<div class="menu-content">
					<div class="d-flex justify-content-start align-items-center h4 text-bold current-account">
						<img :src="getProfileImage(profileImage)" class="mr-3 menu-item-icon" />
						<div>
							<p class="col pl-0 m-0 username">{{ username }}</p>
							<p class="col pl-0 m-0 email para-3">{{ email }}</p>
						</div>
					</div>

					<div class="other-accounts-wrapper">
						<template v-for="(data, key) in accounts">
							<div
								v-if="key != current_account_key"
								:key="key"
								class="dropdown-item d-flex justify-content-start align-items-center my-0 h4 text-bold other-accounts  mb-4"
								@click.prevent="switchAccounts(key)"
							>
								<img :src="getProfileImage(data.image)" class="mr-3 menu-item-icon" />
								<div>
									<p class="col pl-0 m-0 username">{{ data.username }}</p>
									<p class="col pl-0 m-0 email para-3">{{ data.email }}</p>
								</div>
							</div>
						</template>
						<div class="dropdown-item d-flex justify-content-start align-items-center my-0 h4 text-bold p-0">
							<InlineSvg src="/images/icons/ic-integrations.svg" class="mr-2 menu-item-icon" />
							<p class="col pl-0" @click.prevent="addAccount">Add Account</p>
						</div>
					</div>

					<hr class="mb-4 mt-4" />
					<div class="dropdown-item d-flex justify-content-start align-items-center my-0 h4 text-bold p-0">
						<InlineSvg src="/images/icons/ic-logout.svg" class="mr-2 menu-item-icon" />
						<p class="col pl-0" @click.prevent="logout">{{ logoutText }}</p>
					</div>
				</div>
			</AppPopup>

			<div
				class="dropdown h-100 dropup"
				id="dropdownMenuLink-account"
				ref="popup-trigger"
				aria-haspopup="true"
				aria-expanded="false"
				@click="togglePopup"
			>
				<div class="my-0 menu-item-link h4 text-bold">
					<img :src="getProfileImage(profileImage)" class="mr-2 menu-item-icon" />
					<p class="col pl-0 m-0">{{ username }}</p>
				</div>
			</div>
		</div>
		<div class="mobile-menu w-100">
			<div class="mobile-apps-header h3 text-bold w-100" style="margin-top:60px;">
				Accounts
			</div>
			<div class="app current-account-mobile">
				<a class="d-flex justify-content-start align-items-center my-0 menu-item-link h4 text-bold">
					<img :src="getProfileImage(profileImage)" class="mr-2 menu-item-icon" />
					<div>
						<p class="col pl-0 m-0 username app-text">{{ username }}</p>
						<p class="col pl-0 m-0 email app-text para-3">{{ email }}</p>
					</div>
				</a>
			</div>

			<template v-for="(data, key) in accounts">
				<div class="app" v-if="key != current_account_key" :key="key" @click.prevent="switchAccounts(key)">
					<a class="d-flex justify-content-start align-items-center my-0 menu-item-link h4 text-bold">
						<img :src="getProfileImage(data.image)" class="mr-2 menu-item-icon" />
						<div>
							<p class="col pl-0 m-0 username app-text">{{ data.username }}</p>
							<p class="col pl-0 m-0 email app-text para-3">{{ data.email }}</p>
						</div>
					</a>
				</div>
			</template>

			<div class="app">
				<a class="d-flex justify-content-start align-items-center my-0 menu-item-link h4 text-bold" @click.prevent="addAccount">
					<div class="app-icon">
						<InlineSvg src="/images/icons/ic-integrations.svg" class="menu-item-icon" />
					</div>
					<div class="app-text para-2 text-bold text-dark">
						Add Account
					</div>
				</a>
			</div>
			<div class="app">
				<a class="d-flex justify-content-start align-items-center my-0 menu-item-link h4 text-bold" @click.prevent="logout">
					<div class="app-icon">
						<InlineSvg src="/images/icons/ic-logout.svg" class="menu-item-icon" />
					</div>
					<div class="app-text para-2 text-bold text-dark">
						{{ logoutText }}
					</div>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions, mapMutations } from 'vuex'

	export default {
		name: 'AccountMenu',
		methods: {
			...mapMutations('Auth', ['prepareToAddAccount']),
			...mapActions('Auth', ['fetchUser']),
			togglePopup() {
				this.$refs?.popup.togglePopup()
			},
			getProfileImage(imageUrl) {
				if (imageUrl) {
					return imageUrl
				}
				return 'images/default-user.svg'
			},
			switchAccounts(key) {
				const switchToUsername = this.accounts[key]?.username
				this.$stanAnalytics('accounts-switch', {
					meta: { user_id: this.user.user_id, username: this.user.username },
					props: {
						total_accounts: this.creatorsRealAccounts.length,
						user_ids: this.creatorsRealAccounts.map(data => data?.user_id),
						switch_to_username: switchToUsername,
					},
				})
				this.$router.push({ name: 'switch_account', params: { accountKey: key } })
			},
			async logout() {
				$('body').removeClass('menu-active')
				clearInterval(this.t)
				await this.$store.dispatch('Auth/logout')
				this.$router.push({ name: 'login_page' }).catch(() => {})
			},
			addAccount() {
				this.$stanAnalytics('accounts-add-click', {
					meta: { user_id: this.user.user_id, username: this.user.username },
					props: { total_accounts: this.creatorsRealAccounts.length, user_ids: this.creatorsRealAccounts.map(data => data?.user_id) },
				})

				clearInterval(this.t)
				// Remove current user object
				this.prepareToAddAccount()
				// Go to login
				this.$router.push({ name: 'login' })
			},
		},
		computed: {
			...mapGetters('Auth', ['user', 'accounts', 'current_account_key']),
			creatorsRealAccounts() {
				return Object.keys(this.accounts)
					.filter(key => !this.accounts[key]?.tmpLoginAsAdmin)
					.map(key => this.accounts[key])
			},
			logoutText() {
				if (Object.keys(this.accounts).length > 1) {
					return 'Logout From All Accounts'
				}
				return 'Logout'
			},
			username() {
				return this.user.username
			},
			email() {
				return this.user.email
			},
			profileImage() {
				return this.user?.data.profile_image
			},
		},
	}
</script>

<style lang="scss" scoped>
	p {
		margin: 0;
	}

	.app {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;

		.app-text {
			color: var(--stan-text-dark-color);
		}

		background: #ffffff;
		box-shadow: 0px 15px 50px rgba(0, 16, 109, 0.07);
		border-radius: 10px;
		a {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 10px;
			gap: 13px;
			width: 100%;

			.app-icon {
				padding: 8px;
				display: flex;
				width: 36px;
				height: 36px;
				background: var(--stan-primary-light-color);
				box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
				border-radius: 8px;
				svg {
					width: 20px;
					height: 20px;
					padding: 0px;
				}
			}
		}
		&.open {
			background: var(--stan-primary-primary-color);
			.app-icon {
				background: var(--stan-primary-strike-color);

				svg::v-deep {
					path:not(.no-fill) {
						fill: var(--stan-white);
					}
				}
			}
			.app-text {
				color: var(--stan-white);
			}
		}
	}

	.current-account-mobile {
		background: var(--stan-primary-light-color);
		img {
			border: 2px solid var(--stan-text-dark-color);
			padding: 1px;
		}
	}

	.current-account {
		margin-bottom: 28px;
		img {
			height: 52px;
			width: 52px;
			border-radius: 50%;
		}
		div {
			max-width: calc(100% - 50px);
		}
		p {
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.username {
			color: var(--stan-text-dark-color);
			font-size: 18px;
		}

		.email {
			color: var(--stan-text-light-color);
			font-size: 12px;
		}
	}

	.other-accounts-wrapper {
		overflow-y: auto;
		overflow-x: hidden;

		// 300pxs the min height of the dropdown
		max-height: calc(max(300px, 75vh) - 240px);
	}
	.other-accounts {
		img {
			width: 38px !important;
			height: 38px !important;
			border-radius: 50%;
			border: 2px solid transparent;
			padding: 1px;
		}

		div {
			max-width: calc(100% - 30px);
		}
		p {
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&:hover::v-deep {
			img {
				border: 2px solid var(--stan-primary-primary-color);
				padding: 1px;
			}
			.username {
				color: var(--stan-primary-primary-color);
			}

			.email {
				color: var(--stan-primary-primary-color);
			}
		}

		.username {
			color: var(--stan-text-dark-color);
			font-size: 16px;
		}

		.email {
			color: var(--stan-text-light-color);
			font-size: 12px;
		}
	}

	.menu-wrapper {
		background-color: transparent !important;
		border: none !important;
		flex: 1 0 0%;

		.menu-content {
			padding: 30px;
			z-index: 2;
			border: none;
			background: var(--stan-white);
			box-shadow: var(--stan-box-shadow);
			border-radius: 10px;
			width: 320px;
			max-width: 320px;
			// 300px is the min height of the dropdown
			max-height: max(300px, 75vh);

			.dropdown-item {
				cursor: pointer;
				color: var(--stan-text-dark-color);
				padding-left: 0;
				padding-right: 0;
				border-radius: 8px;
				&:hover {
					background-color: #fff;
					color: var(--stan-primary-primary-color);
					.menu-item-icon::v-deep {
						path:not(.no-fill),
						rect:not(.no-fill) {
							fill: var(--stan-primary-primary-color);
						}
					}
				}

				.label-text {
					color: var(--stan-text-dark-color);
				}
				img {
					max-width: 100%;
					height: 18px;
					margin-right: 5px;
				}
			}
			.dropdown-item:active {
				background: var(--stan-primary-color);
				color: var(--stan-white);
				&.item-type-tag {
					background: transparent;
				}
			}
		}
	}

	.menu-item-link {
		overflow: hidden;
		padding: 15px 5px;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 12px 0px 14px 25px;
		img {
			width: 36px;
			height: 36px;
			border-radius: 50%;
		}
		p {
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&:hover {
			cursor: pointer;
			color: var(--stan-primary-primary-color);
		}
	}

	.desktop-menu {
		@media (min-width: 992px) {
			position: relative;
			min-width: none;
		}
	}

	.mobile-menu {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0px;
		gap: 15px;

		@media (min-width: 992px) {
			position: fixed;
			top: 100%;
			display: none;
		}
	}
</style>
