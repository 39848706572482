const countryCodes = [
	{
		name: 'Afghanistan',
		image: 'images/flag/af.webp',
		label: 'AF +93',
		code: 'AF',
		dialCode: '+93',
	},
	{
		name: 'Albania',
		image: 'images/flag/al.webp',
		label: 'AL +355',
		code: 'AL',
		dialCode: '+355',
	},
	{
		name: 'Algeria',
		image: 'images/flag/dz.webp',
		label: 'DZ +213',
		code: 'DZ',
		dialCode: '+213',
	},
	{
		name: 'American Samoa',
		image: 'images/flag/as.webp',
		label: 'AS +1684',
		code: 'AS',
		dialCode: '+1684',
	},
	{
		name: 'Andorra',
		image: 'images/flag/ad.webp',
		label: 'AD +376',
		code: 'AD',
		dialCode: '+376',
	},
	{
		name: 'Angola',
		image: 'images/flag/ao.webp',
		label: 'AO +244',
		code: 'AO',
		dialCode: '+244',
	},
	{
		name: 'Anguilla',
		image: 'images/flag/ai.webp',
		label: 'AI +1264',
		code: 'AI',
		dialCode: '+1264',
	},
	{
		name: 'Antarctica',
		image: 'images/flag/aq.webp',
		label: 'AQ +672',
		code: 'AQ',
		dialCode: '+672',
	},
	{
		name: 'Antigua & Barbuda',
		image: 'images/flag/ag.webp',
		label: 'AG +1268',
		code: 'AG',
		dialCode: '+1268',
	},
	{
		name: 'Argentina',
		image: 'images/flag/ar.webp',
		label: 'AR +54',
		code: 'AR',
		dialCode: '+54',
	},
	{
		name: 'Armenia',
		image: 'images/flag/am.webp',
		label: 'AM +374',
		code: 'AM',
		dialCode: '+374',
	},
	{
		name: 'Aruba',
		image: 'images/flag/aw.webp',
		label: 'AW +297',
		code: 'AW',
		dialCode: '+297',
	},
	{
		name: 'Australia',
		image: 'images/flag/au.webp',
		label: 'AU +61',
		code: 'AU',
		dialCode: '+61',
	},
	{
		name: 'Austria',
		image: 'images/flag/at.webp',
		label: 'AT +43',
		code: 'AT',
		dialCode: '+43',
	},
	{
		name: 'Azerbaijan',
		image: 'images/flag/az.webp',
		label: 'AZ +994',
		code: 'AZ',
		dialCode: '+994',
	},
	{
		name: 'Bahamas',
		image: 'images/flag/bs.webp',
		label: 'BS +1242',
		code: 'BS',
		dialCode: '+1242',
	},
	{
		name: 'Bahrain',
		image: 'images/flag/bh.webp',
		label: 'BH +973',
		code: 'BH',
		dialCode: '+973',
	},
	{
		name: 'Bangladesh',
		image: 'images/flag/bd.webp',
		label: 'BD +880',
		code: 'BD',
		dialCode: '+880',
	},
	{
		name: 'Barbados',
		image: 'images/flag/bb.webp',
		label: 'BB +1246',
		code: 'BB',
		dialCode: '+1246',
	},
	{
		name: 'Belarus',
		image: 'images/flag/by.webp',
		label: 'BY +375',
		code: 'BY',
		dialCode: '+375',
	},
	{
		name: 'Belgium',
		image: 'images/flag/be.webp',
		label: 'BE +32',
		code: 'BE',
		dialCode: '+32',
	},
	{
		name: 'Belize',
		image: 'images/flag/bz.webp',
		label: 'BZ +501',
		code: 'BZ',
		dialCode: '+501',
	},
	{
		name: 'Benin',
		image: 'images/flag/bj.webp',
		label: 'BJ +229',
		code: 'BJ',
		dialCode: '+229',
	},
	{
		name: 'Bermuda',
		image: 'images/flag/bm.webp',
		label: 'BM +1441',
		code: 'BM',
		dialCode: '+1441',
	},
	{
		name: 'Bhutan',
		image: 'images/flag/bt.webp',
		label: 'BT +975',
		code: 'BT',
		dialCode: '+975',
	},
	{
		name: 'Bolivia',
		image: 'images/flag/bo.webp',
		label: 'BO +591',
		code: 'BO',
		dialCode: '+591',
	},
	{
		name: 'Bosnia and Herzegovina',
		image: 'images/flag/ba.webp',
		label: 'BA +387',
		code: 'BA',
		dialCode: '+387',
	},
	{
		name: 'Botswana',
		image: 'images/flag/bw.webp',
		label: 'BW +267',
		code: 'BW',
		dialCode: '+267',
	},
	{
		name: 'Brazil',
		image: 'images/flag/br.webp',
		label: 'BR +55',
		code: 'BR',
		dialCode: '+55',
	},
	{
		name: 'British Indian Ocean Territory',
		image: 'images/flag/io.webp',
		label: 'IO +246',
		code: 'IO',
		dialCode: '+246',
	},
	{
		name: 'Brunei Darussalam',
		image: 'images/flag/bn.webp',
		label: 'BN +673',
		code: 'BN',
		dialCode: '+673',
	},
	{
		name: 'Bulgaria',
		image: 'images/flag/bg.webp',
		label: 'BG +359',
		code: 'BG',
		dialCode: '+359',
	},
	{
		name: 'Burkina Faso',
		image: 'images/flag/bf.webp',
		label: 'BF +226',
		code: 'BF',
		dialCode: '+226',
	},
	{
		name: 'Burundi',
		image: 'images/flag/bi.webp',
		label: 'BI +257',
		code: 'BI',
		dialCode: '+257',
	},
	{
		name: 'Cambodia',
		image: 'images/flag/kh.webp',
		label: 'KH +855',
		code: 'KH',
		dialCode: '+855',
	},
	{
		name: 'Cameroon',
		image: 'images/flag/cm.webp',
		label: 'CM +237',
		code: 'CM',
		dialCode: '+237',
	},
	{
		name: 'Canada',
		image: 'images/flag/ca.webp',
		label: 'CA +1',
		code: 'CA',
		dialCode: '+1',
	},
	{
		name: 'Cape Verde',
		image: 'images/flag/cv.webp',
		label: 'CV +238',
		code: 'CV',
		dialCode: '+238',
	},
	{
		name: 'Cayman Islands',
		image: 'images/flag/ky.webp',
		label: 'KY +1345',
		code: 'KY',
		dialCode: '+1345',
	},
	{
		name: 'Central African Republic',
		image: 'images/flag/cf.webp',
		label: 'CF +236',
		code: 'CF',
		dialCode: '+236',
	},
	{
		name: 'Chad',
		image: 'images/flag/td.webp',
		label: 'TD +235',
		code: 'TD',
		dialCode: '+235',
	},
	{
		name: 'Chile',
		image: 'images/flag/cl.webp',
		label: 'CL +56',
		code: 'CL',
		dialCode: '+56',
	},
	{
		name: 'China',
		image: 'images/flag/cn.webp',
		label: 'CN +86',
		code: 'CN',
		dialCode: '+86',
	},
	{
		name: 'Christmas Island',
		image: 'images/flag/cx.webp',
		label: 'CX +61',
		code: 'CX',
		dialCode: '+61',
	},
	{
		name: 'Cocos (Keeling) Islands',
		image: 'images/flag/cc.webp',
		label: 'CC +61',
		code: 'CC',
		dialCode: '+61',
	},
	{
		name: 'Colombia',
		image: 'images/flag/co.webp',
		label: 'CO +57',
		code: 'CO',
		dialCode: '+57',
	},
	{
		name: 'Comoros',
		image: 'images/flag/km.webp',
		label: 'KM +269',
		code: 'KM',
		dialCode: '+269',
	},
	{
		name: 'Congo - Brazzaville',
		image: 'images/flag/cg.webp',
		label: 'CG +242',
		code: 'CG',
		dialCode: '+242',
	},
	{
		name: 'Congo - Kinshasa',
		image: 'images/flag/cd.webp',
		label: 'CD +243',
		code: 'CD',
		dialCode: '+243',
	},
	{
		name: 'Cook Islands',
		image: 'images/flag/ck.webp',
		label: 'CK +682',
		code: 'CK',
		dialCode: '+682',
	},
	{
		name: 'Costa Rica',
		image: 'images/flag/cr.webp',
		label: 'CR +506',
		code: 'CR',
		dialCode: '+506',
	},
	{
		name: 'Croatia',
		image: 'images/flag/hr.webp',
		label: 'HR +385',
		code: 'HR',
		dialCode: '+385',
	},
	{
		name: 'Cuba',
		image: 'images/flag/cu.webp',
		label: 'CU +53',
		code: 'CU',
		dialCode: '+53',
	},
	{
		name: 'Curaçao',
		image: 'images/flag/cw.webp',
		label: 'CW +599',
		code: 'CW',
		dialCode: '+599',
	},
	{
		name: 'Cyprus',
		image: 'images/flag/cy.webp',
		label: 'CY +357',
		code: 'CY',
		dialCode: '+357',
	},
	{
		name: 'Czech Republic',
		image: 'images/flag/cz.webp',
		label: 'CZ +420',
		code: 'CZ',
		dialCode: '+420',
	},
	{
		name: 'Côte dIvoire / Ivory Coast',
		image: 'images/flag/ci.webp',
		label: 'CI +225',
		code: 'CI',
		dialCode: '+225',
	},
	{
		name: 'Denmark',
		image: 'images/flag/dk.webp',
		label: 'DK +45',
		code: 'DK',
		dialCode: '+45',
	},
	{
		name: 'Djibouti',
		image: 'images/flag/dj.webp',
		label: 'DJ +253',
		code: 'DJ',
		dialCode: '+253',
	},
	{
		name: 'Dominica',
		image: 'images/flag/dm.webp',
		label: 'DM +1767',
		code: 'DM',
		dialCode: '+1767',
	},
	{
		name: 'Dominican Republic',
		image: 'images/flag/do.webp',
		label: 'DO +1',
		code: 'DO',
		dialCode: '+1',
	},
	{
		name: 'Ecuador',
		image: 'images/flag/ec.webp',
		label: 'EC +593',
		code: 'EC',
		dialCode: '+593',
	},
	{
		name: 'Egypt',
		image: 'images/flag/eg.webp',
		label: 'EG +20',
		code: 'EG',
		dialCode: '+20',
	},
	{
		name: 'El Salvador',
		image: 'images/flag/sv.webp',
		label: 'SV +503',
		code: 'SV',
		dialCode: '+503',
	},
	{
		name: 'Equatorial Guinea',
		image: 'images/flag/gq.webp',
		label: 'GQ +240',
		code: 'GQ',
		dialCode: '+240',
	},
	{
		name: 'Eritrea',
		image: 'images/flag/er.webp',
		label: 'ER +291',
		code: 'ER',
		dialCode: '+291',
	},
	{
		name: 'Estonia',
		image: 'images/flag/ee.webp',
		label: 'EE +372',
		code: 'EE',
		dialCode: '+372',
	},
	{
		name: 'Eswatini',
		image: 'images/flag/sz.webp',
		label: 'SZ +268',
		code: 'SZ',
		dialCode: '+268',
	},
	{
		name: 'Ethiopia',
		image: 'images/flag/et.webp',
		label: 'ET +251',
		code: 'ET',
		dialCode: '+251',
	},
	{
		name: 'Falkland Islands (Malvinas)',
		image: 'images/flag/fk.webp',
		label: 'FK +500',
		code: 'FK',
		dialCode: '+500',
	},
	{
		name: 'Faroe Islands',
		image: 'images/flag/fo.webp',
		label: 'FO +298',
		code: 'FO',
		dialCode: '+298',
	},
	{
		name: 'Fiji',
		image: 'images/flag/fj.webp',
		label: 'FJ +679',
		code: 'FJ',
		dialCode: '+679',
	},
	{
		name: 'Finland',
		image: 'images/flag/fi.webp',
		label: 'FI +358',
		code: 'FI',
		dialCode: '+358',
	},
	{
		name: 'France',
		image: 'images/flag/fr.webp',
		label: 'FR +33',
		code: 'FR',
		dialCode: '+33',
	},
	{
		name: 'French Guiana',
		image: 'images/flag/gf.webp',
		label: 'GF +594',
		code: 'GF',
		dialCode: '+594',
	},
	{
		name: 'French Polynesia',
		image: 'images/flag/pf.webp',
		label: 'PF +689',
		code: 'PF',
		dialCode: '+689',
	},
	{
		name: 'Gabon',
		image: 'images/flag/ga.webp',
		label: 'GA +241',
		code: 'GA',
		dialCode: '+241',
	},
	{
		name: 'Gambia',
		image: 'images/flag/gm.webp',
		label: 'GM +220',
		code: 'GM',
		dialCode: '+220',
	},
	{
		name: 'Georgia',
		image: 'images/flag/ge.webp',
		label: 'GE +995',
		code: 'GE',
		dialCode: '+995',
	},
	{
		name: 'Germany',
		image: 'images/flag/de.webp',
		label: 'DE +49',
		code: 'DE',
		dialCode: '+49',
	},
	{
		name: 'Ghana',
		image: 'images/flag/gh.webp',
		label: 'GH +233',
		code: 'GH',
		dialCode: '+233',
	},
	{
		name: 'Gibraltar',
		image: 'images/flag/gi.webp',
		label: 'GI +350',
		code: 'GI',
		dialCode: '+350',
	},
	{
		name: 'Greece',
		image: 'images/flag/gr.webp',
		label: 'GR +30',
		code: 'GR',
		dialCode: '+30',
	},
	{
		name: 'Greenland',
		image: 'images/flag/gl.webp',
		label: 'GL +299',
		code: 'GL',
		dialCode: '+299',
	},
	{
		name: 'Grenada',
		image: 'images/flag/gd.webp',
		label: 'GD +1473',
		code: 'GD',
		dialCode: '+1473',
	},
	{
		name: 'Guadeloupe',
		image: 'images/flag/gp.webp',
		label: 'GP +590',
		code: 'GP',
		dialCode: '+590',
	},
	{
		name: 'Guam',
		image: 'images/flag/gu.webp',
		label: 'GU +1671',
		code: 'GU',
		dialCode: '+1671',
	},
	{
		name: 'Guatemala',
		image: 'images/flag/gt.webp',
		label: 'GT +502',
		code: 'GT',
		dialCode: '+502',
	},
	{
		name: 'Guinea',
		image: 'images/flag/gn.webp',
		label: 'GN +224',
		code: 'GN',
		dialCode: '+224',
	},
	{
		name: 'Guinea-Bissau',
		image: 'images/flag/gw.webp',
		label: 'GW +245',
		code: 'GW',
		dialCode: '+245',
	},
	{
		name: 'Guyana',
		image: 'images/flag/gy.webp',
		label: 'GY +592',
		code: 'GY',
		dialCode: '+592',
	},
	{
		name: 'Haiti',
		image: 'images/flag/ht.webp',
		label: 'HT +509',
		code: 'HT',
		dialCode: '+509',
	},
	{
		name: 'Holy See (Vatican City State)',
		image: 'images/flag/va.webp',
		label: 'VA +379',
		code: 'VA',
		dialCode: '+379',
	},
	{
		name: 'Honduras',
		image: 'images/flag/hn.webp',
		label: 'HN +504',
		code: 'HN',
		dialCode: '+504',
	},
	{
		name: 'Hong Kong',
		image: 'images/flag/hk.webp',
		label: 'HK +852',
		code: 'HK',
		dialCode: '+852',
	},
	{
		name: 'Hungary',
		image: 'images/flag/hu.webp',
		label: 'HU +36',
		code: 'HU',
		dialCode: '+36',
	},
	{
		name: 'Iceland',
		image: 'images/flag/is.webp',
		label: 'IS +354',
		code: 'IS',
		dialCode: '+354',
	},
	{
		name: 'India',
		image: 'images/flag/in.webp',
		label: 'IN +91',
		code: 'IN',
		dialCode: '+91',
	},
	{
		name: 'Indonesia',
		image: 'images/flag/id.webp',
		label: 'ID +62',
		code: 'ID',
		dialCode: '+62',
	},
	{
		name: 'Iran',
		image: 'images/flag/ir.webp',
		label: 'IR +98',
		code: 'IR',
		dialCode: '+98',
	},
	{
		name: 'Iraq',
		image: 'images/flag/iq.webp',
		label: 'IQ +964',
		code: 'IQ',
		dialCode: '+964',
	},
	{
		name: 'Ireland',
		image: 'images/flag/ie.webp',
		label: 'IE +353',
		code: 'IE',
		dialCode: '+353',
	},
	{
		name: 'Israel',
		image: 'images/flag/il.webp',
		label: 'IL +972',
		code: 'IL',
		dialCode: '+972',
	},
	{
		name: 'Italy',
		image: 'images/flag/it.webp',
		label: 'IT +39',
		code: 'IT',
		dialCode: '+39',
	},
	{
		name: 'Jamaica',
		image: 'images/flag/jm.webp',
		label: 'JM +1876',
		code: 'JM',
		dialCode: '+1876',
	},
	{
		name: 'Japan',
		image: 'images/flag/jp.webp',
		label: 'JP +81',
		code: 'JP',
		dialCode: '+81',
	},
	{
		name: 'Jordan',
		image: 'images/flag/jo.webp',
		label: 'JO +962',
		code: 'JO',
		dialCode: '+962',
	},
	{
		name: 'Kazakhstan',
		image: 'images/flag/kz.webp',
		label: 'KZ +77',
		code: 'KZ',
		dialCode: '+77',
	},
	{
		name: 'Kenya',
		image: 'images/flag/ke.webp',
		label: 'KE +254',
		code: 'KE',
		dialCode: '+254',
	},
	{
		name: 'Kiribati',
		image: 'images/flag/ki.webp',
		label: 'KI +686',
		code: 'KI',
		dialCode: '+686',
	},
	{
		name: 'Kuwait',
		image: 'images/flag/kw.webp',
		label: 'KW +965',
		code: 'KW',
		dialCode: '+965',
	},
	{
		name: 'Kyrgyzstan',
		image: 'images/flag/kg.webp',
		label: 'KG +996',
		code: 'KG',
		dialCode: '+996',
	},
	{
		name: 'Laos',
		image: 'images/flag/la.webp',
		label: 'LA +856',
		code: 'LA',
		dialCode: '+856',
	},
	{
		name: 'Latvia',
		image: 'images/flag/lv.webp',
		label: 'LV +371',
		code: 'LV',
		dialCode: '+371',
	},
	{
		name: 'Lebanon',
		image: 'images/flag/lb.webp',
		label: 'LB +961',
		code: 'LB',
		dialCode: '+961',
	},
	{
		name: 'Lesotho',
		image: 'images/flag/ls.webp',
		label: 'LS +266',
		code: 'LS',
		dialCode: '+266',
	},
	{
		name: 'Liberia',
		image: 'images/flag/lr.webp',
		label: 'LR +231',
		code: 'LR',
		dialCode: '+231',
	},
	{
		name: 'Libya',
		image: 'images/flag/ly.webp',
		label: 'LY +218',
		code: 'LY',
		dialCode: '+218',
	},
	{
		name: 'Liechtenstein',
		image: 'images/flag/li.webp',
		label: 'LI +423',
		code: 'LI',
		dialCode: '+423',
	},
	{
		name: 'Lithuania',
		image: 'images/flag/lt.webp',
		label: 'LT +370',
		code: 'LT',
		dialCode: '+370',
	},
	{
		name: 'Luxembourg',
		image: 'images/flag/lu.webp',
		label: 'LU +352',
		code: 'LU',
		dialCode: '+352',
	},
	{
		name: 'Macau',
		image: 'images/flag/mo.webp',
		label: 'MO +853',
		code: 'MO',
		dialCode: '+853',
	},
	{
		name: 'Madagascar',
		image: 'images/flag/mg.webp',
		label: 'MG +261',
		code: 'MG',
		dialCode: '+261',
	},
	{
		name: 'Malawi',
		image: 'images/flag/mw.webp',
		label: 'MW +265',
		code: 'MW',
		dialCode: '+265',
	},
	{
		name: 'Malaysia',
		image: 'images/flag/my.webp',
		label: 'MY +60',
		code: 'MY',
		dialCode: '+60',
	},
	{
		name: 'Maldives',
		image: 'images/flag/mv.webp',
		label: 'MV +960',
		code: 'MV',
		dialCode: '+960',
	},
	{
		name: 'Mali',
		image: 'images/flag/ml.webp',
		label: 'ML +223',
		code: 'ML',
		dialCode: '+223',
	},
	{
		name: 'Malta',
		image: 'images/flag/mt.webp',
		label: 'MT +356',
		code: 'MT',
		dialCode: '+356',
	},
	{
		name: 'Marshall Islands',
		image: 'images/flag/mh.webp',
		label: 'MH +692',
		code: 'MH',
		dialCode: '+692',
	},
	{
		name: 'Martinique',
		image: 'images/flag/mq.webp',
		label: 'MQ +596',
		code: 'MQ',
		dialCode: '+596',
	},
	{
		name: 'Mauritania',
		image: 'images/flag/mr.webp',
		label: 'MR +222',
		code: 'MR',
		dialCode: '+222',
	},
	{
		name: 'Mauritius',
		image: 'images/flag/mu.webp',
		label: 'MU +230',
		code: 'MU',
		dialCode: '+230',
	},
	{
		name: 'Mexico',
		image: 'images/flag/mx.webp',
		label: 'MX +52',
		code: 'MX',
		dialCode: '+52',
	},
	{
		name: 'Micronesia',
		image: 'images/flag/fm.webp',
		label: 'FM +691',
		code: 'FM',
		dialCode: '+691',
	},
	{
		name: 'Moldova',
		image: 'images/flag/md.webp',
		label: 'MD +373',
		code: 'MD',
		dialCode: '+373',
	},
	{
		name: 'Monaco',
		image: 'images/flag/mc.webp',
		label: 'MC +377',
		code: 'MC',
		dialCode: '+377',
	},
	{
		name: 'Mongolia',
		image: 'images/flag/mn.webp',
		label: 'MN +976',
		code: 'MN',
		dialCode: '+976',
	},
	{
		name: 'Montenegro',
		image: 'images/flag/me.webp',
		label: 'ME +382',
		code: 'ME',
		dialCode: '+382',
	},
	{
		name: 'Montserrat',
		image: 'images/flag/ms.webp',
		label: 'MS +1664',
		code: 'MS',
		dialCode: '+1664',
	},
	{
		name: 'Morocco',
		image: 'images/flag/ma.webp',
		label: 'MA +212',
		code: 'MA',
		dialCode: '+212',
	},
	{
		name: 'Mozambique',
		image: 'images/flag/mz.webp',
		label: 'MZ +258',
		code: 'MZ',
		dialCode: '+258',
	},
	{
		name: 'Myanmar',
		image: 'images/flag/mm.webp',
		label: 'MM +95',
		code: 'MM',
		dialCode: '+95',
	},
	{
		name: 'Namibia',
		image: 'images/flag/na.webp',
		label: 'NA +264',
		code: 'NA',
		dialCode: '+264',
	},
	{
		name: 'Nauru',
		image: 'images/flag/nr.webp',
		label: 'NR +674',
		code: 'NR',
		dialCode: '+674',
	},
	{
		name: 'Nepal',
		image: 'images/flag/np.webp',
		label: 'NP +977',
		code: 'NP',
		dialCode: '+977',
	},
	{
		name: 'Netherlands',
		image: 'images/flag/nl.webp',
		label: 'NL +31',
		code: 'NL',
		dialCode: '+31',
	},
	{
		name: 'New Caledonia',
		image: 'images/flag/nc.webp',
		label: 'NC +687',
		code: 'NC',
		dialCode: '+687',
	},
	{
		name: 'New Zealand',
		image: 'images/flag/nz.webp',
		label: 'NZ +64',
		code: 'NZ',
		dialCode: '+64',
	},
	{
		name: 'Nicaragua',
		image: 'images/flag/ni.webp',
		label: 'NI +505',
		code: 'NI',
		dialCode: '+505',
	},
	{
		name: 'Niger',
		image: 'images/flag/ne.webp',
		label: 'NE +227',
		code: 'NE',
		dialCode: '+227',
	},
	{
		name: 'Nigeria',
		image: 'images/flag/ng.webp',
		label: 'NG +234',
		code: 'NG',
		dialCode: '+234',
	},
	{
		name: 'Niue',
		image: 'images/flag/nu.webp',
		label: 'NU +683',
		code: 'NU',
		dialCode: '+683',
	},
	{
		name: 'Norfolk Island',
		image: 'images/flag/nf.webp',
		label: 'NF +672',
		code: 'NF',
		dialCode: '+672',
	},
	{
		name: 'North Korea',
		image: 'images/flag/kp.webp',
		label: 'KP +850',
		code: 'KP',
		dialCode: '+850',
	},
	{
		name: 'North Macedonia',
		image: 'images/flag/mk.webp',
		label: 'MK +389',
		code: 'MK',
		dialCode: '+389',
	},
	{
		name: 'Northern Mariana Islands',
		image: 'images/flag/mp.webp',
		label: 'MP +1670',
		code: 'MP',
		dialCode: '+1670',
	},
	{
		name: 'Norway',
		image: 'images/flag/no.webp',
		label: 'NO +47',
		code: 'NO',
		dialCode: '+47',
	},
	{
		name: 'Oman',
		image: 'images/flag/om.webp',
		label: 'OM +968',
		code: 'OM',
		dialCode: '+968',
	},
	{
		name: 'Pakistan',
		image: 'images/flag/pk.webp',
		label: 'PK +92',
		code: 'PK',
		dialCode: '+92',
	},
	{
		name: 'Palau',
		image: 'images/flag/pw.webp',
		label: 'PW +680',
		code: 'PW',
		dialCode: '+680',
	},
	{
		name: 'Panama',
		image: 'images/flag/pa.webp',
		label: 'PA +507',
		code: 'PA',
		dialCode: '+507',
	},
	{
		name: 'Papua New Guinea',
		image: 'images/flag/pg.webp',
		label: 'PG +675',
		code: 'PG',
		dialCode: '+675',
	},
	{
		name: 'Paraguay',
		image: 'images/flag/py.webp',
		label: 'PY +595',
		code: 'PY',
		dialCode: '+595',
	},
	{
		name: 'Peru',
		image: 'images/flag/pe.webp',
		label: 'PE +51',
		code: 'PE',
		dialCode: '+51',
	},
	{
		name: 'Philippines',
		image: 'images/flag/ph.webp',
		label: 'PH +63',
		code: 'PH',
		dialCode: '+63',
	},
	{
		name: 'Pitcairn',
		image: 'images/flag/pn.webp',
		label: 'PN +872',
		code: 'PN',
		dialCode: '+872',
	},
	{
		name: 'Poland',
		image: 'images/flag/pl.webp',
		label: 'PL +48',
		code: 'PL',
		dialCode: '+48',
	},
	{
		name: 'Portugal',
		image: 'images/flag/pt.webp',
		label: 'PT +351',
		code: 'PT',
		dialCode: '+351',
	},
	{
		name: 'Puerto Rico',
		image: 'images/flag/pr.webp',
		label: 'PR +1',
		code: 'PR',
		dialCode: '+1',
	},
	{
		name: 'Qatar',
		image: 'images/flag/qa.webp',
		label: 'QA +974',
		code: 'QA',
		dialCode: '+974',
	},
	{
		name: 'Reunion',
		image: 'images/flag/re.webp',
		label: 'RE +262',
		code: 'RE',
		dialCode: '+262',
	},
	{
		name: 'Romania',
		image: 'images/flag/ro.webp',
		label: 'RO +40',
		code: 'RO',
		dialCode: '+40',
	},
	{
		name: 'Russia',
		image: 'images/flag/ru.webp',
		label: 'RU +7',
		code: 'RU',
		dialCode: '+7',
	},
	{
		name: 'Rwanda',
		image: 'images/flag/rw.webp',
		label: 'RW +250',
		code: 'RW',
		dialCode: '+250',
	},
	{
		name: 'Samoa',
		image: 'images/flag/ws.webp',
		label: 'WS +685',
		code: 'WS',
		dialCode: '+685',
	},
	{
		name: 'San Marino',
		image: 'images/flag/sm.webp',
		label: 'SM +378',
		code: 'SM',
		dialCode: '+378',
	},
	{
		name: 'Sao Tome and Principe',
		image: 'images/flag/st.webp',
		label: 'ST +239',
		code: 'ST',
		dialCode: '+239',
	},
	{
		name: 'Saudi Arabia',
		image: 'images/flag/sa.webp',
		label: 'SA +966',
		code: 'SA',
		dialCode: '+966',
	},
	{
		name: 'Senegal',
		image: 'images/flag/sn.webp',
		label: 'SN +221',
		code: 'SN',
		dialCode: '+221',
	},
	{
		name: 'Serbia',
		image: 'images/flag/rs.webp',
		label: 'RS +381',
		code: 'RS',
		dialCode: '+381',
	},
	{
		name: 'Seychelles',
		image: 'images/flag/sc.webp',
		label: 'SC +248',
		code: 'SC',
		dialCode: '+248',
	},
	{
		name: 'Sierra Leone',
		image: 'images/flag/sl.webp',
		label: 'SL +232',
		code: 'SL',
		dialCode: '+232',
	},
	{
		name: 'Singapore',
		image: 'images/flag/sg.webp',
		label: 'SG +65',
		code: 'SG',
		dialCode: '+65',
	},
	{
		name: 'Slovakia',
		image: 'images/flag/sk.webp',
		label: 'SK +421',
		code: 'SK',
		dialCode: '+421',
	},
	{
		name: 'Slovenia',
		image: 'images/flag/si.webp',
		label: 'SI +386',
		code: 'SI',
		dialCode: '+386',
	},
	{
		name: 'Solomon Islands',
		image: 'images/flag/sb.webp',
		label: 'SB +677',
		code: 'SB',
		dialCode: '+677',
	},
	{
		name: 'Somalia',
		image: 'images/flag/so.webp',
		label: 'SO +252',
		code: 'SO',
		dialCode: '+252',
	},
	{
		name: 'South Africa',
		image: 'images/flag/za.webp',
		label: 'ZA +27',
		code: 'ZA',
		dialCode: '+27',
	},
	{
		name: 'South Georgia',
		image: 'images/flag/gs.webp',
		label: 'GS +500',
		code: 'GS',
		dialCode: '+500',
	},
	{
		name: 'South Korea',
		image: 'images/flag/kr.webp',
		label: 'KR +82',
		code: 'KR',
		dialCode: '+82',
	},
	{
		name: 'South Sudan',
		image: 'images/flag/ss.webp',
		label: 'SS +211',
		code: 'SS',
		dialCode: '+211',
	},
	{
		name: 'Spain',
		image: 'images/flag/es.webp',
		label: 'ES +34',
		code: 'ES',
		dialCode: '+34',
	},
	{
		name: 'Sri Lanka',
		image: 'images/flag/lk.webp',
		label: 'LK +94',
		code: 'LK',
		dialCode: '+94',
	},
	{
		name: 'St. Helena',
		image: 'images/flag/sh.webp',
		label: 'SH +290',
		code: 'SH',
		dialCode: '+290',
	},
	{
		name: 'St. Kitts & Nevis',
		image: 'images/flag/kn.webp',
		label: 'KN +1869',
		code: 'KN',
		dialCode: '+1869',
	},
	{
		name: 'St. Lucia',
		image: 'images/flag/lc.webp',
		label: 'LC +1758',
		code: 'LC',
		dialCode: '+1758',
	},
	{
		name: 'St. Pierre & Miquelon',
		image: 'images/flag/pm.webp',
		label: 'PM +508',
		code: 'PM',
		dialCode: '+508',
	},
	{
		name: 'St. Vincent & Grenadines',
		image: 'images/flag/vc.webp',
		label: 'VC +1784',
		code: 'VC',
		dialCode: '+1784',
	},
	{
		name: 'Sudan',
		image: 'images/flag/sd.webp',
		label: 'SD +249',
		code: 'SD',
		dialCode: '+249',
	},
	{
		name: 'Suriname',
		image: 'images/flag/sr.webp',
		label: 'SR +597',
		code: 'SR',
		dialCode: '+597',
	},
	{
		name: 'Svalbard and Jan Mayen',
		image: 'images/flag/sj.webp',
		label: 'SJ +47',
		code: 'SJ',
		dialCode: '+47',
	},
	{
		name: 'Sweden',
		image: 'images/flag/se.webp',
		label: 'SE +46',
		code: 'SE',
		dialCode: '+46',
	},
	{
		name: 'Switzerland',
		image: 'images/flag/ch.webp',
		label: 'CH +41',
		code: 'CH',
		dialCode: '+41',
	},
	{
		name: 'Syrian Arab Republic',
		image: 'images/flag/sy.webp',
		label: 'SY +963',
		code: 'SY',
		dialCode: '+963',
	},
	{
		name: 'Taiwan',
		image: 'images/flag/tw.webp',
		label: 'TW +886',
		code: 'TW',
		dialCode: '+886',
	},
	{
		name: 'Tajikistan',
		image: 'images/flag/tj.webp',
		label: 'TJ +992',
		code: 'TJ',
		dialCode: '+992',
	},
	{
		name: 'Tanzania',
		image: 'images/flag/tz.webp',
		label: 'TZ +255',
		code: 'TZ',
		dialCode: '+255',
	},
	{
		name: 'Thailand',
		image: 'images/flag/th.webp',
		label: 'TH +66',
		code: 'TH',
		dialCode: '+66',
	},
	{
		name: 'Timor-Leste',
		image: 'images/flag/tl.webp',
		label: 'TL +670',
		code: 'TL',
		dialCode: '+670',
	},
	{
		name: 'Togo',
		image: 'images/flag/tg.webp',
		label: 'TG +228',
		code: 'TG',
		dialCode: '+228',
	},
	{
		name: 'Tokelau',
		image: 'images/flag/tk.webp',
		label: 'TK +690',
		code: 'TK',
		dialCode: '+690',
	},
	{
		name: 'Tonga',
		image: 'images/flag/to.webp',
		label: 'TO +676',
		code: 'TO',
		dialCode: '+676',
	},
	{
		name: 'Trinidad and Tobago',
		image: 'images/flag/tt.webp',
		label: 'TT +1868',
		code: 'TT',
		dialCode: '+1868',
	},
	{
		name: 'Tunisia',
		image: 'images/flag/tn.webp',
		label: 'TN +216',
		code: 'TN',
		dialCode: '+216',
	},
	{
		name: 'Turkey',
		image: 'images/flag/tr.webp',
		label: 'TR +90',
		code: 'TR',
		dialCode: '+90',
	},
	{
		name: 'Turkmenistan',
		image: 'images/flag/tm.webp',
		label: 'TM +993',
		code: 'TM',
		dialCode: '+993',
	},
	{
		name: 'Turks and Caicos Islands',
		image: 'images/flag/tc.webp',
		label: 'TC +1649',
		code: 'TC',
		dialCode: '+1649',
	},
	{
		name: 'Tuvalu',
		image: 'images/flag/tv.webp',
		label: 'TV +688',
		code: 'TV',
		dialCode: '+688',
	},
	{
		name: 'Uganda',
		image: 'images/flag/ug.webp',
		label: 'UG +256',
		code: 'UG',
		dialCode: '+256',
	},
	{
		name: 'Ukraine',
		image: 'images/flag/ua.webp',
		label: 'UA +380',
		code: 'UA',
		dialCode: '+380',
	},
	{
		name: 'United Arab Emirates',
		image: 'images/flag/ae.webp',
		label: 'AE +971',
		code: 'AE',
		dialCode: '+971',
	},
	{
		name: 'United Kingdom',
		image: 'images/flag/gb.webp',
		label: 'GB +44',
		code: 'GB',
		dialCode: '+44',
	},
	{
		name: 'United States',
		image: 'images/flag/us.webp',
		label: 'US +1',
		code: 'US',
		dialCode: '+1',
	},
	{
		name: 'Uruguay',
		image: 'images/flag/uy.webp',
		label: 'UY +598',
		code: 'UY',
		dialCode: '+598',
	},
	{
		name: 'Uzbekistan',
		image: 'images/flag/uz.webp',
		label: 'UZ +998',
		code: 'UZ',
		dialCode: '+998',
	},
	{
		name: 'Vanuatu',
		image: 'images/flag/vu.webp',
		label: 'VU +678',
		code: 'VU',
		dialCode: '+678',
	},
	{
		name: 'Venezuela',
		image: 'images/flag/ve.webp',
		label: 'VE +58',
		code: 'VE',
		dialCode: '+58',
	},
	{
		name: 'Vietnam',
		image: 'images/flag/vn.webp',
		label: 'VN +84',
		code: 'VN',
		dialCode: '+84',
	},
	{
		name: 'Virgin Islands, British',
		image: 'images/flag/vg.webp',
		label: 'VG +1284',
		code: 'VG',
		dialCode: '+1284',
	},
	{
		name: 'Virgin Islands, U.S.',
		image: 'images/flag/vi.webp',
		label: 'VI +1340',
		code: 'VI',
		dialCode: '+1340',
	},
	{
		name: 'Wallis and Futuna',
		image: 'images/flag/wf.webp',
		label: 'WF +681',
		code: 'WF',
		dialCode: '+681',
	},
	{
		name: 'Yemen',
		image: 'images/flag/ye.webp',
		label: 'YE +967',
		code: 'YE',
		dialCode: '+967',
	},
	{
		name: 'Zambia',
		image: 'images/flag/zm.webp',
		label: 'ZM +260',
		code: 'ZM',
		dialCode: '+260',
	},
	{
		name: 'Zimbabwe',
		image: 'images/flag/zw.webp',
		label: 'ZW +263',
		code: 'ZW',
		dialCode: '+263',
	},
]

export default countryCodes
