const MainContainer = () => import('~/components/layouts/MainContainer').then(m => m.default || m)

const moduleRoutes = {
	path: '/creator',
	name: 'CustomerIndex',
	component: MainContainer,
	children: [
		{
			path: '',
			name: 'creator',
			component: () => import('./Creator').then(m => m.default || m),
			meta: {
				pageTitle: 'Creator',
				breadcrumbs: [{ title: 'Creator', active: true }],
			},
		},
		{
			path: 'login-as',
			name: 'loginAs',
			component: () => import('./LoginAs').then(m => m.default || m),
		},
	],
}

export default moduleRoutes
