<template>
	<div class="d-flex align-items-center dropdown-item" @click="selectCountry(option)">
		<div class="mr-1 col-auto px-0 d-flex">
			<AppImage :src="option.image" class="rounded" :width="'23px'" cover :height="'16px'" />
		</div>

		<div class="d-flex gap-5px">
			<div class="text-truncate label-text para-2">
				{{ option.name }}
			</div>
			<div class="additional-text para-2">
				{{ option.dialCode }}
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'PhoneNumberDropdownItem',
		props: {
			option: {
				type: Object,
				default: () => {},
			},
		},
		methods: {
			selectCountry(country) {
				this.$emit('click', country)
			},
		},
	}
</script>

<style lang="scss" scoped>
	.dropdown-item {
		color: var(--stan-text-dark-color);
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		border-radius: 8px;
		height: 38px !important;
		cursor: pointer;
		@media (max-width: 767px) {
			padding-left: 1.5rem !important;
		}
		&:hover {
			background-color: var(--stan-gray-soft-color);
		}
		.label-text {
			color: var(--stan-text-dark-color);
		}
		.additional-text {
			color: var(--stan-text-light-color);
		}
		img {
			max-width: 100%;
			height: 18px;
			margin-right: 5px;
		}
	}
</style>
