var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AppModal", {
    attrs: {
      id: "delete-store-modal",
      action: _vm.$t("Yes, Delete Funnel"),
      title: _vm.$t("Just Confirming!"),
      subheading: _vm.$t("Are you sure you want to delete this funnel?")
    },
    on: { close: _vm.closeModal, cta: _vm.deleteFunnel }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }