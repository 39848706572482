<template>
	<div v-if="!isMobile" class="container">
		<div class="body flex-grow-1 col-12 col-md-5 px-0">
			<div>
				<AppForm
					:classOverride="'cancellation'"
					:center="true"
					formId="cancellation-reason-form"
					:responseId="responseId"
					:questions="questions"
					@completed="completed"
					@questionAnswered="disableSkip"
				/>
				<Transition name="fade" mode="out-in">
					<AppButton name="skip-feedback-cancellation-modal" v-if="canSkip" size="large" class="w-100 p-1 h-auto" link @click="skipped"
						>Skip feedback</AppButton
					>
				</Transition>
			</div>
		</div>

		<div class="body flex-grow-1 col-12 col-md-5 px-0">
			<div v-if="!imageReady" class="image-placeholder" />
			<img v-show="imageReady" src="/images/cancellation/user-feedback.svg" @load="imageReady = true" />
		</div>
	</div>
	<div v-else class="container mobile-container d-flex justify-content-center align-items-center">
		<div class="body flex-grow-1 col-12 col-md-5 px-0 h-100">
			<div>
				<AppForm
					:classOverride="'cancellation'"
					:center="true"
					formId="cancellation-reason-form"
					:responseId="responseId"
					:questions="questions"
					@completed="completed"
					@questionAnswered="disableSkip"
				/>
				<AppButton name="skip-feedback-cancellation-modal" v-if="canSkip" size="large" class="p-1 h-auto" link @click="skipped"
					>Skip feedback</AppButton
				>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import { UUID } from '~/store/modules/Global.js'

	export default {
		components: {},
		props: {
			hasTakenFreeMonth: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				stepName: 'request connect stan team member',
				offerName: 'connect with stan team member',
				imageReady: false,
				canSkip: true,
				responseId: UUID(),
				questions: [
					{
						questionId: 'cancellation_reason',
						questionText: 'Why do you want to cancel?',
						required: true,
						options: [
							{
								displayName: 'Missing features',
								dbMap: 'missing_features',
								nextQuestionId: 'q_missing_feature_cancel',
							},
							{
								displayName: 'Leaving for another product',
								dbMap: 'leaving_for_another_product',
								nextQuestionId: 'q_leaving_for',
							},
							{
								displayName: "I don't have a product to sell",
								dbMap: 'no_product_to_sell',
								nextQuestionId: 'q_helpful_during_store_setup',
							},
							{
								displayName: "I haven't made enough money",
								dbMap: 'have_not_made enough money',
								nextQuestionId: 'q_anything_we_could_have_done_to_help',
							},
							{
								displayName: 'I need help setting up my store',
								dbMap: 'need_help_setting_up_store',
								nextQuestionId: 'q_struggling_with_store_setup',
							},
							{
								displayName: 'The subscription fee',
								dbMap: 'subscription_fee',
								nextQuestionId: 'q_instead_of_subscription_fee',
							},
							{
								displayName: 'Other',
								dbMap: 'other',
								nextQuestionId: 'q_anything_we_could_have_done_to_help',
							},
						],
						type: 'select',
					},
					{
						questionId: 'q_struggling_with_store_setup',
						questionText: 'What part of the setup are you struggling with?',
						nextQuestionId: 'q_leaving_for',
						required: true,
						options: [
							{
								displayName: "I don't like the look of it",
								dbMap: 'do_not_like_the_look_of_it',
							},

							{
								displayName: "I'm struggling creating a product",
								dbMap: 'struggling_creating_a_product',
							},

							{
								displayName: 'Integrating Stan with another tool',
								dbMap: 'integrating_with_another_tool',
							},
							{
								displayName: 'Other',
								dbMap: 'other',
							},
						],
						type: 'multiSelect',
					},
					{
						questionId: 'q_anything_we_could_have_done_to_help',
						questionText: 'How could we help?',
						nextQuestionId: 'q_leaving_for',
						required: true,
						options: [
							{
								displayName: '1:1 Coaching',
								dbMap: 'coaching',
							},

							{
								displayName: 'More tutorials / videos',
								dbMap: 'tutorials',
							},
							{
								displayName: 'Provide better recommendations',
								dbMap: 'recommendations',
							},
							{
								displayName: 'Live Chat in-app',
								dbMap: 'live_chat',
							},
							{
								displayName: 'Create a first draft of a product for you',
								dbMap: 'draft_product',
							},
							{
								displayName: 'Other',
								dbMap: 'other',
							},
						],
						type: 'multiSelect',
					},
					{
						questionId: 'q_instead_of_subscription_fee',
						questionText: 'What would you prefer over the subscription fee?',
						required: true,
						options: [
							{
								displayName: 'Transaction fee instead of price',
								dbMap: 'transaction_fee',
								nextQuestionId: 'q_leaving_for',
							},

							{
								displayName: "Free plan until you've made your first $1",
								dbMap: 'free_plan_until_first_dollar',
								nextQuestionId: 'q_leaving_for',
							},
							{
								displayName: 'Other',
								dbMap: 'other',
								nextQuestionId: 'q_leaving_for',
							},
						],
						type: 'select',
					},
					{
						questionId: 'q_helpful_during_store_setup',
						questionText: 'What would have been helpful during your store setup process?',
						nextQuestionId: 'q_leaving_for',
						required: true,
						options: [
							{
								displayName: 'Review and audit my Stan Store',
								dbMap: 'audit_store',
							},

							{
								displayName: 'Give product recommendations',
								dbMap: 'product_recommendations',
							},
							{
								displayName: '1:1 call or live chat to help',
								dbMap: 'live_chat',
							},
							{
								displayName: 'Share example stores of Creators in my niche',
								dbMap: 'examples_of_stores',
							},
							{
								displayName: 'Create a first draft of a product for you',
								dbMap: 'first_draft',
							},
							{
								displayName: 'Other',
								dbMap: 'other',
							},
						],
						type: 'multiSelect',
					},
					{
						questionId: 'q_missing_feature_cancel',
						questionText: 'Which missing feature is causing you to cancel?',
						nextQuestionId: 'q_leaving_for',
						required: true,
						options: [
							{
								displayName: 'Custom domains',
								dbMap: 'custom_domains',
							},

							{
								displayName: 'More design flexibility',
								dbMap: 'design_flexibility',
							},
							{
								displayName: 'More product flexibility',
								dbMap: 'product_flexibility',
							},
							{
								displayName: 'Better store themes',
								dbMap: 'better_store themes',
							},
							{
								displayName: 'Ability to remove Stan branding',
								dbMap: 'remove_stan_branding',
							},
							{
								displayName: 'Other',
								dbMap: 'other',
							},
						],
						type: 'multiSelect',
					},
					{
						questionId: 'q_leaving_for',
						questionText: 'Which software are you leaving for?',
						nextQuestionId: 'q_decide_to_switch_to_them',
						required: true,
						options: [
							{
								displayName: 'Linktree',
								dbMap: 'linktree',
							},
							{
								displayName: 'Beacons',
								dbMap: 'beacons',
							},
							{
								displayName: 'Snipfeed',
								dbMap: 'snipfeed',
							},
							{
								displayName: 'Kajabi',
								dbMap: 'kajabi',
							},
							{
								displayName: 'Pensight',
								dbMap: 'pensight',
							},
							{
								displayName: 'Milkshake',
								dbMap: 'milkshake',
							},
							{
								displayName: 'Carrd',
								dbMap: 'carrd',
							},
							{
								displayName: 'Wix',
								dbMap: 'wix',
							},
							{
								displayName: 'Calendly',
								dbMap: 'calendly',
							},
							{
								displayName: 'Systeme.io',
								dbMap: 'systeme_io',
							},
							{
								displayName: 'Other',
								dbMap: 'other',
							},
						],
						type: 'multiSelect',
					},
					{
						questionId: 'q_decide_to_switch_to_them',
						questionText: 'Why did you switch to them?',
						nextQuestionId: 'q_text_come_back',
						required: true,
						options: [
							{
								displayName: 'Price',
								dbMap: 'price',
							},
							{
								displayName: 'Design flexibility',
								dbMap: 'design_flexibility',
							},
							{
								displayName: 'Custom domains',
								dbMap: 'custom_domains',
							},
							{
								displayName: 'Easier to use',
								dbMap: 'easier_to_use',
							},
							{
								displayName: 'Better conversions',
								dbMap: 'better_conversions',
							},
							{
								displayName: 'Other',
								dbMap: 'other',
							},
						],
						type: 'multiSelect',
					},
					{
						questionId: 'q_text_come_back',
						questionText: 'Would anything bring you back to Stan?',
						options: [
							{
								displayName: 'Let us know here!',
							},
						],
						type: 'text',
					},
				],
			}
		},
		mounted() {
			this.start()
		},
		computed: {
			...mapGetters('Auth', ['user']),
			isMobile() {
				return this.$commonHelper.isMobile()
			},
		},
		methods: {
			disableSkip() {
				this.canSkip = false
			},
			start() {
				this.$emit('start', {
					eventName: 'cancellation-survey-started',
					props: { step_name: this.stepName, respons_id: this.responseId },
				})
			},
			completed() {
				const data = {
					eventName: 'cancellation-survey-completed',
					props: { step_name: this.stepName, respons_id: this.responseId },
				}
				this.$emit('next-step', data)
				this.reset()
			},

			skipped() {
				const data = {
					eventName: 'cancellation-survey-skipped',
					props: { step_name: this.stepName, respons_id: this.responseId },
				}
				this.$emit('next-step', data)
				this.reset()
			},
			reset() {
				this.imageReady = false
				this.canSkip = true
			},
		},
	}
</script>

<style lang="scss" scoped>
	.cancellation::v-deep {
		.title {
			text-align: center;
			color: var(--stan-text-dark-color);
			text-align: center;
			font-family: Plus Jakarta Sans;
			font-size: 44px;
			font-style: normal;
			font-weight: 800;
			line-height: 110%; /* 48.4px */
			letter-spacing: -0.44px;
			margin-bottom: 40px !important;

			@media (max-width: 768px) {
				font-size: 28px;
				font-style: normal;
				font-weight: 800;
				line-height: 110%; /* 30.8px */
				letter-spacing: -0.28px;
			}
		}
	}
</style>
