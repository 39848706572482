var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isMobile
    ? _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "body flex-grow-1 col-12 col-md-5 px-0" }, [
          _c("div", [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column align-items-center gap v-gap pt-1"
              },
              [
                _c(
                  "AppButton",
                  {
                    attrs: {
                      name: "go-back-one-free-month-accepted-modal",
                      size: "large"
                    },
                    on: { click: _vm.nextStep }
                  },
                  [_vm._v("Go back to account")]
                )
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "body flex-grow-1 col-12 col-md-5 px-0" }, [
          !_vm.imageReady
            ? _c("div", { staticClass: "image-placeholder" })
            : _vm._e(),
          _vm._v(" "),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.imageReady,
                expression: "imageReady"
              }
            ],
            attrs: { src: "/images/cancellation/calendar.svg" },
            on: {
              load: function($event) {
                _vm.imageReady = true
              }
            }
          })
        ])
      ])
    : _c(
        "div",
        {
          staticClass:
            "container mobile-container d-flex justify-content-center align-items-center"
        },
        [
          _c("div", { staticClass: "body flex-column flex-gap-50 h-100" }, [
            _c("div", { staticClass: "body flex-shrink-1 px-0" }, [
              !_vm.imageReady
                ? _c("div", { staticClass: "image-placeholder" })
                : _vm._e(),
              _vm._v(" "),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.imageReady,
                    expression: "imageReady"
                  }
                ],
                attrs: { src: "/images/cancellation/calendar.svg" },
                on: {
                  load: function($event) {
                    _vm.imageReady = true
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "body px-0" }, [
              _c("div", [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column align-items-center gap v-gap pt-1"
                  },
                  [
                    _c(
                      "AppButton",
                      {
                        attrs: {
                          name: "go-back-one-free-month-accepted-modal",
                          size: "large"
                        },
                        on: { click: _vm.nextStep }
                      },
                      [_vm._v("Go back to account")]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ]
      )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "modal-body w-full py-0 d-flex flex-column align-items-center"
      },
      [
        _c("h3", { staticClass: "modal-heading" }, [
          _vm._v("Yay! FREE month applied! ✅")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-subheading" }, [
          _vm._v(
            "\n\t\t\t\t\tYou now have an extra 30 days to see the full value of Stan. Check your email for details.\n\t\t\t\t"
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "modal-body w-full py-0 d-flex flex-column align-items-center"
      },
      [
        _c("h3", { staticClass: "modal-heading" }, [
          _vm._v("Yay! FREE month applied! ✅")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-subheading" }, [
          _vm._v(
            "\n\t\t\t\t\t\tYou now have an extra 30 days to see the full value of Stan. Check your email for details.\n\t\t\t\t\t"
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }