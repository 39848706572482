<template>
	<div class="form-group">
		<div class="d-flex align-items-center justify-content-start mb-2" :class="{ 'form-error': validation.stock.$error }">
			<AppToggle size="sm" v-model="value.manage" class="mr-2" />
			<label for="inventory_stock" class="para-3 mb-0 d-inline-flex align-items-center">
				{{ $t('Limit Quantity') }}
				<AppTooltip
					class="ml-1 d-inline-flex align-items-center"
					click
					tip="Limit the number of products available. This value will update after each purchase. <a href='https://help.stan.store/article/43-limit-quantity-discount-codes' target='_blank'>Learn more</a>"
					:html="true"
				>
					<InlineSvg src="./images/income/help.svg" style="height: 12px" />
				</AppTooltip>
			</label>
		</div>
		<AppInput
			v-show="value.manage"
			type="number"
			v-model.number="value.stock"
			id="inventory_stock"
			:class="{ 'form-error': validation.stock.$error }"
		/>
	</div>
</template>
<script>
	export default {
		name: 'InventoryForm',
		props: {
			value: {
				type: Object,
				default: () => {},
			},
			validation: {
				type: Object,
				default: () => {},
			},
		},
	}
</script>
<style lang="scss" scoped>
	small {
		color: var(--stan-blue-5);
	}
</style>
