<template>
	<div>
		<StanleyTyping :text="texts[0]" @doneTyping="counter++"></StanleyTyping>
		<StanleyTyping v-if="counter >= 1" :text="texts[1]" @doneTyping="counter++"></StanleyTyping>
		<StanleyTyping v-if="counter >= 2" :text="texts[2]">
			<div class="d-flex flex-wrap mt-2">
				<AppButton name="thread-onboarding-welcome-yes-button" @click="counter++" size="md" outline class="mr-3 mb-2"
					>Yes! How do I monetize?</AppButton
				>
			</div>
		</StanleyTyping>
		<StanleyTyping v-if="counter >= 3" :text="texts[3]">
			<div id="video-play-wrapper" class="embed-responsive embed-responsive-16by9 video-frame mt-2" style="max-width: 100%; width: 400px;">
				<iframe
					class="embed-responsive-item"
					:src="video_uri"
					frameborder="0"
					allow="encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
				></iframe>
			</div>
		</StanleyTyping>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.$emit('threadDeactivated', { name: 'ThreadOnboardingWelcome' })
		},
		data() {
			return {
				counter: 0,
				texts: [
					"Hey there! I'm Stanley, your AI Assistant.",
					'You can use the message promt below 👇 to ask me anything about Stan or building your Creator Business! ',
					'Would you like me to tell you more about Monetization?',
					"Here's a quick YouTube video to help you get started!",
				],
				video_uri: 'https://www.youtube.com/embed/K5UqPovzt_g?autoplay=1&rel=0',
			}
		},
	}
</script>

<style lang="scss" scoped></style>
