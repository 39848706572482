import axios from 'axios'

/* eslint-disable import/extensions */

export const state = {
	isAutomationsLoading: false,
	automations: [],
	instagramBusinessAccounts: [],
	instagramPostsMetadata: [],
}

export const getters = {
	isAutomationsLoading: state => state.isAutomationsLoading,
	getAutomations: state => state.automations,
	getInstagramBusinessAccounts: state => state.instagramBusinessAccounts,
	getInstagramPostsMetadata: state => state.instagramPostsMetadata,
}

export const mutations = {
	setAutomations(state, automations) {
		state.automations = automations
	},
	setAutomationLoading(state, isLoading) {
		state.isAutomationsLoading = isLoading
	},
	setInstagramBusinessAccounts(state, accounts) {
		state.instagramBusinessAccounts = accounts
	},
	setInstagramPostsMetadata(state, metadata) {
		state.instagramPostsMetadata = metadata
	},
}

export const actions = {
	fetchAutomations: async ({ commit }) => {
		commit('setAutomationLoading', true)
		await axios
			.get('v1/automations')
			.then(res => {
				commit('setAutomations', res.data.automations ?? [])
				commit('setInstagramBusinessAccounts', res.data.instagram_business_accounts)
			})
			.catch(error => {
				// eslint-disable-next-line no-console
				console.error(error)
				commit('setAutomations', [])
			})
			.finally(() => {
				commit('setAutomationLoading', false)
			})
	},
	fetchInstagramPosts: ({ commit }) => {
		axios
			.get('v1/automations/instagram-posts-metadata')
			.then(res => {
				commit('setInstagramPostsMetadata', res.data)
			})
			.catch(() => {
				commit('setInstagramPostsMetadata', [])
			})
	},
}
