var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "saving-status", class: { saved: _vm.saveState.isSaving } },
    [
      _c(
        "div",
        { staticClass: "saving-icon" },
        [
          _vm.saveState.isSaving
            ? _c("InlineSvg", {
                staticClass: "saving rotating",
                attrs: { src: "/images/icons/ic-reload.svg" }
              })
            : _vm.saveState.isSaved
            ? _c("InlineSvg", {
                staticClass: "saved",
                attrs: { src: "/images/icons/ic-check-circle.svg" }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "saving-text" }, [
        _vm.saveState.isSaving
          ? _c("p", { staticClass: "saving para-2" }, [_vm._v("Saving...")])
          : _vm.saveState.isSaved
          ? _c("p", { staticClass: "saved para-2" }, [_vm._v("Saved")])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }