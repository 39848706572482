<template>
	<AppModal
		:id="id"
		:title="contentUse.title"
		:subheading="contentUse.subheading"
		:action="contentUse.actionLabel"
		actionColor="danger"
		:secondaryAction="contentUse.secondaryActionLabel"
		secondaryActionColor="light"
		@cta="deletePage"
		@close="closeModal"
		@secondary-cta="closeModal"
	>
	</AppModal>
</template>
<script>
	export default {
		props: {
			id: {
				type: String,
				default: 'modal-delete-page',
			},
			type: {
				type: String,
				default: 'default',
			},
		},
		computed: {
			contentUse() {
				const item =
					{
						default: 'product',
						course: 'course',
						module: 'module',
						lesson: 'lesson',
					}[this.type] || 'item'

				return {
					title: 'Are you sure?',
					subheading: `Are you sure you want to delete this ${item}?`,
					actionLabel: 'Confirm deletion',
					secondaryActionLabel: 'Close',
				}
			},
		},
		methods: {
			deletePage() {
				this.$emit('delete-confirm')
				$('#modal-delete-page').modal('hide')
				this.$emit('close-modal')
			},
			closeModal() {
				$(`#${this.id}`).modal('hide')
				this.$emit('close-modal')
			},
		},
	}
</script>
