<template>
	<AppModal id="edit-customer-modal" :title="$t(modal_title)" aria-labelledby="editCustomer" :overflow="true">
		<form id="edit_customer_form" @keydown="form.onKeydown($event)" @submit.prevent="submitModalForm">
			<AppInput
				v-model="form.fullName"
				class="form-group"
				:error="formErrors && formErrors.errors && formErrors.errors.fullName"
				:warning="ajax_warnings && ajax_warnings.fullName"
				icon="/images/icons/ic-person.svg"
				icon-width="16px"
				type="text"
				:placeholder="$t('Name')"
				name="fullName"
				@input="onBlurChangeWarning('fullName')"
			/>
			<AppInput
				v-model="form.email"
				class="form-group"
				:error="formErrors.errors && formErrors.errors.email"
				:warning="ajax_warnings && ajax_warnings.email"
				icon="/images/icons/ic-mail.svg"
				:placeholder="$t('Email')"
				type="email"
				name="email"
				@input="onBlurChangeWarning('email')"
			/>
			<AppPhoneInput
				ref="phoneNumber"
				class="form-group has-country align-items-center"
				:current-phone="form.phoneNumber"
				:error="formErrors && formErrors.errors && formErrors.errors.phoneNumber"
				:warning="ajax_warnings && ajax_warnings.phoneNumber"
				@updatePhone="phoneValidation"
			/>
			<EditFanTags :userFanId="userFanId" mode="single-fan" />
			<!-- <div class="w-100 mt-3 highlight-card">
				<AppCheckbox :value="true" label="Subscribe to marketing emails" />
			</div> -->
			<div class="d-flex flex-wrap justify-content-end mt-3">
				<AppButton name="close-edit-customer-modal" outline class="py-2 md-flat ml-2 mt-1 w-100px" data-dismiss="modal" @click="fillForm">{{
					$t('Close')
				}}</AppButton>
				<AppButton name="save-edit-customer-modal" :loading="loading" class="py-2 md-flat ml-2 mt-1 w-100px" type="Submit">{{
					$t('Save')
				}}</AppButton>
			</div>
		</form>
	</AppModal>
</template>
<script>
	import axios from 'axios'
	import Form from 'vform'
	import helper from '~/global_helper/helpers.js'
	import AppPhoneInput from '~/components/shared/AppPhoneInput/AppPhoneInput'
	import EditFanTags from './components/EditFanTags'

	export default {
		components: {
			AppPhoneInput,
			EditFanTags,
		},
		props: {
			customerDetails: {
				type: Object,
				required: true,
			},
			userFanId: {
				type: Number,
				required: false,
			},
			user: {
				type: Object,
			},
		},
		computed: {
			isEmailFlowAllowed() {
				return this.user.subscription_tier_allowed_modules?.indexOf('email-flows') !== -1
			},
		},
		data: () => ({
			form: new Form({
				userFanId: '',
				fullName: '',
				email: '',
				phoneNumber: '',
			}),
			modal_title: 'Edit Customer',
			formErrors: {
				message: '',
				status: null,
				errors: {},
			},
			ajax_warnings: {},
			loading: false,
		}),
		watch: {
			customerDetails() {
				this.fillForm()
			},
		},
		created() {
			this.fillForm()
		},
		methods: {
			phoneValidation(e) {
				if (e && e.country !== undefined && e.number !== undefined && e.valid === true) {
					this.form.phoneNumber = e.number
				} else {
					this.form.phoneNumber = ''
				}
				this.onBlurChangeWarning('phoneNumber')
			},

			validateForm() {
				let formStatus = true
				const errorObject = {
					status: 'error',
					message: this.$t('The given data was invalid.'),
					errors: {},
				}

				if (helper.stringLength(this.form.fullName) === 0) {
					Object.assign(errorObject.errors, {
						fullName: [this.$t('Please enter a Name!')],
					})
					formStatus = false
				}
				if (helper.stringLength(this.form.email) === 0) {
					Object.assign(errorObject.errors, {
						email: [this.$t('Please enter a valid Email!')],
					})
					formStatus = false
				}
				if (helper.stringLength(this.form.email) !== 0 && !helper.validateEmail(this.form.email)) {
					Object.assign(errorObject.errors, {
						email: [this.$t('Please enter a valid Email!')],
					})
					formStatus = false
				}

				if (helper.stringLength(this.form.phoneNumber) > 0 && !helper.validatePhone(this.form.phoneNumber)) {
					Object.assign(errorObject.errors, {
						phoneNumber: [this.$t('Please enter a valid Phone Number!')],
					})
					formStatus = false
				}

				Object.assign(this.formErrors, errorObject)
				return formStatus
			},

			submitModalForm() {
				this.loading = true
				if (Object.keys(this.ajax_warnings).length === 0) {
					$('#edit-customer-modal').modal('hide')
					this.$emit('close')
					this.$stanNotify({
						type: 'success', // can be 'info', 'warning', 'success', 'error'
						title: this.$t('Changes saved!'),
						duration: 2000,
					})
					this.loading = false
					return
				}
				const status = this.validateForm()
				if (status) {
					const payload = {
						full_name: this.form.fullName,
						email: this.form.email,
						phone_number: this.form.phoneNumber,
					}

					axios
						.patch(`v1/users/fans/${this.userFanId}`, payload)
						.then(response => {
							this.$stanNotify({
								type: 'success', // can be 'info', 'warning', 'success', 'error'
								title: this.$t('Changes saved!'),
								text: response.data.message,
								duration: 2000,
							})
							$('#edit-customer-modal').modal('hide')
							this.$emit('close')
						})
						.catch(error => {
							Object.assign(this.formErrors, error.response.data)
							this.$stanNotify({
								type: 'error', // can be 'info', 'warning', 'success', 'error'
								title: this.$t('Let us help you with that!'),
								text: error.response.data.message || this.$t('Please email friends@stanwith.me'),
								duration: 4000,
							})
						})
						.finally(() => {
							this.loading = false
						})
				} else {
					this.loading = false
				}
			},

			resetForm() {
				this.formErrors.errors = []
				this.ajax_warnings = {}
				this.form.keys().forEach(key => {
					this.form[key] = null
				})
			},

			fillForm() {
				this.resetForm()
				this.form.keys().forEach(key => {
					this.form[key] = this.customerDetails[key]
				})
			},

			onBlurChangeWarning(field) {
				if (this.customerDetails && this.form && this.customerDetails[field] !== this.form[field]) {
					this.ajax_warnings[field] = this.$t('The field has changed.')
				} else {
					delete this.ajax_warnings[field]
				}
			},
		},
	}
</script>
<style scoped lang="scss"></style>
