var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("StanleyTyping", { attrs: { text: _vm.text } }, [
        _c(
          "div",
          { staticClass: "mt-2" },
          [
            _c(
              "AppButton",
              {
                attrs: {
                  name: "profile-image-prompt-update-profile-image-button",
                  outline: "",
                  size: "md"
                },
                on: { click: _vm.action }
              },
              [_vm._v("Update Profile Image")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }