var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex justify-content-between align-items-center row-container"
    },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-row gap-10px align-items-center" },
        [
          _c("div", { staticClass: "para-3 text-light" }, [
            _vm._v("\n\t\t\t" + _vm._s(this.title) + "\n\t\t")
          ])
        ]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "para-1 text-light text-bold" }, [
        _vm._v(_vm._s(_vm.amount))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }