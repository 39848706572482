<template>
	<div>
		<div class="chips-container" :key="filtersKey">
			<div v-for="(item, index) in filters" :key="index" class="chip-wrapper">
				<AppFilterChip
					v-if="checkShow(item)"
					:label="item.label"
					:filterItem="item"
					:id="`filter-${index}`"
					:type="item.type"
					:customDropdownOptions="item.customDropdownOptions"
					:customDropdownReduce="item.customDropdownReduce"
					:customOperators="item.customOperators"
					@addFilter="addFilter"
					@removeFilter="removeFilter"
					:disabled="item.disabled"
				></AppFilterChip>
			</div>
			<AppButton name="clear-filters" v-if="showClearFliters" class="m-2 w-auto" outline size="small" @click="clearFilters"
				>Clear Filters</AppButton
			>
		</div>
		<div class="d-flex">
			<AppButton name="download-csv-app-filter" v-if="showCsvDownload" class="m-2 w-auto" outline size="small" @click="$emit('downloadCsv')"
				>Download CSV</AppButton
			>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AppFilters',
		props: {
			filters: { type: Array, default: () => [] },
			showCsvDownload: { type: Boolean, default: true },
		},
		data() {
			return {
				filterArray: [],
				filtersKey: 0,
			}
		},
		computed: {
			showClearFliters() {
				// When hidden filters are being used, do not show `clear filters option for those fields`
				const checkArr = this.filterArray.filter(fArr => {
					const filter = this.filters.find(f => f.fieldName === fArr.fieldName)
					return this.checkShow(filter)
				})
				return checkArr.length > 0
			},
		},
		methods: {
			checkShow(filter) {
				return !filter?.hideFilter
			},
			addFilter(filterObjectArray) {
				this.filterArray = this.filterArray.filter(f => f.fieldName !== filterObjectArray[0].fieldName)
				filterObjectArray.forEach(f => {
					this.filterArray.push(f)
				})
				this.filterChanged(this.filterArray)
			},
			removeFilter(filterObjectArray) {
				this.filterArray = this.filterArray.filter(f => f.fieldName !== filterObjectArray[0].fieldName)
				this.filterChanged(this.filterArray)
			},
			clearFilters() {
				this.filterArray = []
				this.filtersKey += 1
				this.filterChanged(this.filterArray)
				this.$emit('resetFilters')
			},
			filterChanged(filterArray) {
				this.$emit('filterChanged', filterArray)
			},
		},
	}
</script>

<style lang="scss" scoped>
	.main-container {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		min-height: 50px;
		.clear-button {
			min-width: 80px;
		}
	}
	.chips-container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	.chip-wrapper {
		max-width: 90vw;
	}
</style>
