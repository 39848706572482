<template>
	<div class="col-12 zapier-connect d-flex align-items-center">
		<img src="/images/zapier_logo.png" class="col-auto px-0" />
		<p class="mb-0 ml-2 col px-0">
			{{ $t('Connect to hundreds of other platforms using') }}
			<span @click="connectZapier">Zapier</span>
		</p>
	</div>
</template>
<script>
	import { configSettings } from '~/plugins/config-settings.js'

	export default {
		name: 'ZapierConnect',

		props: {
			title: { type: String, default: null },
		},
		data() {
			return {
				zapierLink: configSettings.zapierInviteLink,
			}
		},
		methods: {
			connectZapier() {
				window.open(this.zapierLink, '_blank')
			},
		},
	}
</script>
<style lang="scss" scoped>
	// zapier connect bg
	.zapier-connect {
		background: var(--stan-white-2);
		border: 1px solid var(--stan-green-1);
		box-sizing: border-box;
		border-radius: 5px;
		padding: 12px;
		display: flex;
		align-items: center;
		img {
			width: 32px;
		}
		p {
			width: 100%;
			line-height: 18px;
			letter-spacing: 0.04em;
			font-size: 14px;
			color: var(--stan-green-3);
			font-weight: 600 !important;
			span {
				cursor: pointer;
				font-style: normal;
				font-weight: 700 !important;
				text-decoration: underline;
			}
		}
	}
</style>
