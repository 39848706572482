var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppSection",
    { attrs: { number: _vm.step, title: "Add text" } },
    [
      ["callout", "preview"].includes(_vm.value.layout)
        ? _c(
            "div",
            { staticClass: "c" },
            [
              _c("AppInput", {
                staticClass: "form-group mb-3 pb-2",
                attrs: {
                  label: "Title",
                  maxlength: "50",
                  placeholder: _vm.$t("Input Heading Here"),
                  required: ""
                },
                model: {
                  value: _vm.value.heading,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "heading", $$v)
                  },
                  expression: "value.heading"
                }
              }),
              _vm._v(" "),
              _c("AppInput", {
                staticClass: "form-group mb-3 pb-2",
                attrs: {
                  label: "Subtitle",
                  maxlength: "100",
                  placeholder: "Enter a description for your followers here"
                },
                model: {
                  value: _vm.value.tagline,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "tagline", $$v)
                  },
                  expression: "value.tagline"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("AppInput", {
        staticClass: "form-group mb-0",
        class: { "form-error": _vm.validation.title.$error },
        attrs: {
          label: "Button*",
          maxlength: "30",
          placeholder: "Button Text Here..."
        },
        model: {
          value: _vm.value.title,
          callback: function($$v) {
            _vm.$set(_vm.value, "title", $$v)
          },
          expression: "value.title"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }