<template>
	<div>
		<StanleyTyping :text="text">
			<div class="d-flex flex-wrap">
				<AppButton name="empty-store-prompt-create-lead-magnet-button" class="mr-2 mb-2" outline>Create Lead Magnet</AppButton>
				<AppButton name="empty-store-prompt-explore-product-types-button" class="mr-2 mb-2" outline>Explore Product Types</AppButton>
			</div>
		</StanleyTyping>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				text: "Your store looks a bit empty! Why don't we add some products to start generating leads?",
			}
		},
	}
</script>

<style lang="scss" scoped></style>
