var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppSection",
    { attrs: { number: _vm.step, title: "Select image" } },
    [
      _c("FileDropZone", {
        staticClass: "new",
        attrs: {
          image: _vm.value.image,
          "drop-zone-class": "header-image-drop-zone",
          "drop-zone-placeholder": "Thumbnail",
          "expected-dimension": "400x400",
          "data-test-id": "upload-button-file-button",
          "crop-aspect-ratio": 1,
          accept: "image"
        },
        on: {
          "start-upload": payload => _vm.$emit("start-upload", payload),
          uploaded: payload => _vm.$emit("imageUploaded", payload),
          "uploaded-all": payload => _vm.$emit("uploaded-all", payload),
          "delete-file": payload => _vm.$emit("delete-file", payload),
          "cancel-file": payload => _vm.$emit("cancel-file", payload)
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }