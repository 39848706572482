<template>
	<div>
		<StanleyTyping :text="text">
			<div id="video-play-wrapper" class="embed-responsive embed-responsive-16by9 video-frame">
				<iframe
					class="embed-responsive-item"
					:src="video_uri"
					frameborder="0"
					allow="encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
				></iframe>
			</div>
		</StanleyTyping>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				text: "Here's a quick YouTube video to help you get started!",
				video_uri: 'https://www.youtube.com/embed/K5UqPovzt_g?autoplay=1&rel=0',
			}
		},
	}
</script>

<style lang="scss" scoped>
	.video-frame {
		max-width: 457px;
		border-radius: 8px;
	}
</style>
