const MainContainer = () => import('~/components/layouts/MainContainer').then(m => m.default || m)

const moduleRoutes = []

const accountSettingsRoutes = {
	path: '/account_settings',
	name: 'AccountSettingsIndex',
	component: MainContainer,
	redirect: '/account_settings',
	children: [
		{
			path: '',
			name: 'account_settings',
			component: () => import('./index').then(m => m.default || m),
			meta: {
				pageTitle: 'Account Settings',
				breadcrumbs: [{ title: 'Account Settings', active: true }],
			},
		},
	],
}

const bookingSettingsRoutes = {
	path: '/integrations',
	name: 'IntegrationsIndex',
	component: MainContainer,
	redirect: '/integrations',
	children: [
		{
			path: '',
			name: 'integrations',
			component: () => import('./integrations').then(m => m.default || m),
			meta: {
				pageTitle: 'Integrations',
				breadcrumbs: [{ title: 'Integrations', active: true }],
			},
		},
	],
}

const authorizationRoutes = [
	{
		path: '/integration-authorization',
		name: 'integration_authorization',
		component: () => import('./integration-authorization').then(m => m.default || m),
	},
]

export default moduleRoutes.concat(accountSettingsRoutes, bookingSettingsRoutes, authorizationRoutes)
