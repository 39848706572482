<template>
	<Paginate
		:value="page"
		:page-count="total"
		:page-range="3"
		:margin-pages="2"
		:click-handler="paginateHandle"
		:prev-text="'←'"
		:next-text="'→'"
		:container-class="'pagination'"
		:page-class="'page-item'"
	>
	</Paginate>
</template>

<script>
	import Paginate from 'vuejs-paginate'

	export default {
		name: 'AppPaginate',
		components: { Paginate },
		props: {
			page: {
				type: Number,
			},
			total: {
				type: Number,
			},
		},
		methods: {
			paginateHandle(event) {
				this.$emit('paginateHandle', event)
			},
		},
	}
</script>

<style lang="scss" scoped>
	.pagination::v-deep {
		margin-bottom: 0;
		li {
			a {
				width: 35px;
				height: 35px;
				border-radius: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid var(--stan-grey-2);
				margin: 0 2px;
				color: var(--stan-blue-5);
				&:focus {
					outline: none;
				}
			}
			&.active {
				a {
					background: var(--stan-primary-dark-color);
					color: white;
					border-color: var(--stan-primary-dark-color);
				}
			}
			&:focus {
				outline: none;
			}
		}
	}

	.pagination::v-deep {
		margin-bottom: 0;
		justify-content: center;
		align-items: center;
		font-style: normal;
		font-weight: normal;
		@media (max-width: 767px) {
			margin: 10px auto;
		}
		li {
			color: var(--stan-primary-dark-color);
			a {
				width: 35px;
				height: 35px;
				border-radius: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 2px;
				font-size: 16px;
				line-height: 20px;
				@media (max-width: 767px) {
					width: 27px;
					height: 27px;
					font-size: 12px;
				}
			}
			&.active {
				a {
					background: var(--stan-primary-primary-color);
					color: white;
				}
			}
			&:focus {
				outline: none;
			}
			&.active:hover {
				color: white;
			}
		}
		.page-item a {
			color: var(--stan-primary-dark-color);
		}
	}
</style>
