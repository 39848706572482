<template>
	<AppModal :id="id" :title="$t('Add Product Link')" @close="closeModal" hideClose>
		<div class="overflow-visible">
			<div class="mb-3 mt-3">
				<AppInput id="searchInput" v-model="searchInput" placeholder="Search..." icon="/images/icons/ic-search.svg" />
			</div>
			<div class="products-container overflow-visible">
				<div style="height: 200px; overflow: auto;">
					<div v-for="product in productOptions" :key="product.id" @click="confirmSelection(product)">
						<div class="d-flex flex-wrap justify-content-start product-container py-2 para-2 align-items-center">
							<img :src="product.image" class="product-icon" />
							<div class="text-truncate pl-3" style="max-width: 70%">
								{{ product.label }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</AppModal>
</template>
<script>
	import { mapGetters } from 'vuex'

	export default {
		props: {
			id: {
				type: String,
				default: 'product-selection-modal',
			},
		},
		data() {
			return {
				selectedProduct: null,
				searchInput: '',
			}
		},
		computed: {
			...mapGetters('Auth', ['user']),
			...mapGetters('Stores', ['getStore', 'getFunnels']),
			productOptions() {
				const fullList = [...this.$commonHelper.buildProductList(this.getStore, [], null, false, true, this.user, 2)] // not passing funnels
				const filteredList = fullList.filter(p => p.label.toLowerCase().includes(this.searchInput.toLowerCase()))
				return filteredList
			},
		},
		mounted() {
			$('.modal').on('shown.bs.modal', () => {
				$('#searchInput').focus()
			})
		},
		methods: {
			confirmSelection(product) {
				this.$emit('confirm-selection', product)
				this.closeModal()
			},
			closeModal() {
				$(`#${this.id}`).modal('hide')
				this.$emit('close-modal')
				setTimeout(() => {
					this.searchInput = ''
				}, 1000)
			},
		},
	}
</script>

<style lang="scss" scoped>
	.product-container {
		.product-icon {
			border-radius: 5px;
			max-width: 36px;
			aspect-ratio: 1;
		}
		min-height: 40px;
	}
	.product-container:hover {
		cursor: pointer;
		background-color: var(--stan-gray-light-color);
		border-radius: 8px;
	}
</style>
