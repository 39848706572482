<template>
	<div class="position-relative">
		<div class="order-bump-section">
			<div class="d-flex align-items-center justify-content-start enable-reminders">
				<div class="reminder-toggle">
					<AppToggle v-model="value.email_reminder_enabled" />
				</div>
				<div>
					<InlineSvg src="/images/email-icon.svg" />
				</div>
				<div>
					<label class="text-bold mr-2 mb-0">{{ $t('Email Reminders') }}</label>
					<label class="w-100 d-block mb-2 hint-text"> Send a reminder email before specified times.</label>
				</div>
			</div>
			<div v-if="value.email_reminder_enabled">
				<div class="d-flex justify-content-start email-settings">
					<div class="form-group mb-3 w-100" :class="{ 'form-error': validation.email_subject.$error }">
						<label class="control-label">{{ $t('Subject') }}</label>
						<TipTapEditor
							:key="editorTick"
							v-model="value.email_subject"
							class="tiptap-editor"
							:height="28"
							:hideMenuBar="true"
							:variableItemsData="variablesSubject"
							:defaultValue="templateSubject"
							:enableDrop="false"
							:enableYoutube="false"
						/>
					</div>
					<div class="form-group mb-3 w-100" :class="{ 'form-error': validation.email_body.$error }">
						<label class="control-label">{{ $t('Body') }}</label>
						<TipTapEditor
							:key="editorTick"
							v-model="value.email_body"
							class="tiptap-editor flexible"
							:extended="true"
							:showImage="false"
							:enableYoutube="false"
							:variableItemsData="variablesBody"
							:defaultValue="templateBody"
						/>
					</div>
				</div>
				<div class="d-flex email-timings">
					<div :class="{ 'form-error': validation.timings.$error }">
						<label class="control-label">{{ $t('Timing') }}</label>
					</div>
					<div v-for="(timing, i) in value.timings" :key="i" class="timing">
						<AppInput
							v-model="value.timings[i].offset"
							type="number"
							@blur="validation.$touch"
							:class="{ 'form-error': validation.timings.$each[i].offset.$error }"
						/>
						<AppDropdown
							:searchable="false"
							:options="[
								{ label: 'minute(s) before', value: 'minutes' },
								{ label: 'hour(s) before', value: 'hours' },
								{ label: 'day(s) before', value: 'days' },
							]"
							reduce="value"
							v-model="value.timings[i].unit"
							@blur="validation.$touch"
							class="form-control"
							:class="{ 'form-error': validation.timings.$each[i].unit.$error }"
						/>
						<div>
							<button class="p-0" type="button" @click="removeTiming(i)">
								<InlineSvg src="/images/form-fields/delete.svg" class="cursor-pointer delete-icon" />
							</button>
						</div>
					</div>
					<AppButton name="add-reminder-meeting-reminder-form" outline class="w-100" @click="addNewTiming">
						{{ $t('+ Add Reminder') }}
					</AppButton>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import TipTapEditor from '~/components/shared/TipTapEditor'

	export default {
		name: 'MeetingReminderForm',
		components: {
			TipTapEditor,
		},
		props: {
			value: {
				type: Object,
				default: () => {},
			},
			validation: {
				type: Object,
				default: () => {},
			},
		},
		mounted() {
			this.hydrateFromTemplate()
		},
		data() {
			return {
				emailTemplateKey: 'fan_meeting_email_reminder',
				applicableProductTypes: ['digital-download', 'course'],
				accordionId: 'meeting-reminders-accordion',
				dropdownComponentKey: 0,
				defaultTiming: {
					offset: 2,
					unit: 'hours',
				},
				editorTick: 0,
			}
		},
		computed: {
			...mapGetters('Auth', ['user']),
			...mapGetters('Stores', ['getEmailTemplates']),
			templateBody() {
				return this.getEmailTemplates?.[this.emailTemplateKey]?.default.body
			},
			variablesBody() {
				return this.getEmailTemplates?.[this.emailTemplateKey]?.variables.body
			},
			templateSubject() {
				return this.getEmailTemplates?.[this.emailTemplateKey]?.default.subject
			},
			variablesSubject() {
				return this.getEmailTemplates?.[this.emailTemplateKey]?.variables.subject
			},
		},
		methods: {
			hydrateFromTemplate() {
				if (!this.value.email_body) this.value.email_body = this.templateBody
				if (!this.value.email_subject) this.value.email_subject = this.templateSubject

				if (!this.value.timings || this.value.timings.length === 0) {
					this.value.timings = [
						{ offset: 1, unit: 'hours' },
						{ offset: 24, unit: 'hours' },
					]
				}
			},
			addNewTiming() {
				this.value.timings.push(this.$lodash.cloneDeep(this.defaultTiming))
			},
			removeTiming(index) {
				this.value.timings.splice(index, 1)
				if (this.value.timings.length === 0) this.addNewTiming()
			},
			setDefaultTemplate() {
				if (this.value.email_reminder_enabled) {
					this.hydrateFromTemplate()
					this.editorTick++
				}
			},
		},
		watch: {
			dropdownEnabled() {
				this.checkEnabled()
			},
			'value.email_reminder_enabled': {
				immediate: true,
				handler() {
					const interval = setInterval(() => {
						this.setDefaultTemplate()
						clearInterval(interval)
					}, 100)
				},
			},
		},
	}
</script>

<style lang="scss" scoped>
	.enable-reminders {
		gap: 14px;
		margin-bottom: 22px;
		.reminder-toggle {
			padding-right: 4px;
		}
		.hint-text {
			font-weight: 400 !important;
			font-size: 12px !important;
			line-height: 16px !important;
			color: #808eb6 !important;
		}
	}
	.email-settings {
		flex-direction: column;
	}
	.email-timings {
		flex-direction: column;
		gap: 15px;
		width: 70%;
		@media (max-width: 575px) {
			width: 100%;
		}
		.timing {
			display: flex;
			flex-direction: row;
			gap: 15px;
			align-items: center;
		}
		.delete-icon {
			height: 18px;
			width: 18px;
			margin-bottom: 1px;
		}
	}
</style>
