<template>
	<div class="transaction-form pay-form-wrapper card-payment-bg">
		<div class="form-bar">
			<form id="payment-form" class="form-border-radius row no-gutters my-2 sentry-mask" data-test-id="payment-form">
				<div class="col-12">
					<!-- eslint-disable-next-line vue/valid-v-model -->
					<div
						:id="`card-element-number${stripeElementIdSuffix}`"
						class="p-3 position-relative card-element-number"
						data-test-id="card-number"
					/>
					<div class="d-flex position-absolute card-icon cart-type">
						<span id="visa" class="opacity-0"><img src="images/Visa.svg"/></span>
						<span id="amex" class="opacity-0"><img src="images/Amex.svg"/></span>
						<span id="discover" class="opacity-0"><img src="images/Discover.svg"/></span>
						<span id="mastercard" class="opacity-0"><img src="images/Mastercard.svg"/></span>
					</div>
				</div>
				<div class="col-6">
					<div :id="`card-element-expiry${stripeElementIdSuffix}`" class="p-3 card-element-expiry" data-test-id="card-expiry" />
				</div>
				<div class="col-6">
					<div :id="`card-element-cvc${stripeElementIdSuffix}`" class="p-3 position-relative card-element-cvc" data-test-id="card-cvc" />
					<div class="position-absolute card-icon">
						<span><img src="images/cvc.svg"/></span>
					</div>
				</div>
			</form>
		</div>
		<Transition name="fade" mode="out-in">
			<h4 v-if="preAuth && showCardMessages" class="m-0" key="1">
				You might see a $1 pre-authorization charge on your statement. Don't worry! This will be immediately refunded! Powered by
				<InlineSvg src="images/gray-stripe.svg" class="px-1" />
			</h4>
			<h4 v-else key="2" class="m-0">Secure Payment Powered by <InlineSvg src="images/gray-stripe.svg" class="px-1" /></h4>
		</Transition>
	</div>
</template>
<style lang="scss">
	#payment-form {
		min-width: 310px;
		@media (max-width: 460px) {
			min-width: inherit;
		}
		min-width: 307px;
	}
	.card-element-cvc {
		height: 50px !important;
	}

	.card-element-expiry {
		border-right: 1px solid var(--stan-gray-dark-color);
		height: 50px !important;
	}

	.card-element-number {
		border-bottom: 1px solid var(--stan-gray-dark-color);
		height: 50px !important;
	}

	.card-icon {
		z-index: 1;
		right: 12px;
		top: 12px;
		span {
			padding: 0px 0px;
		}
	}

	.card-payment-bg {
		h3 {
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 19px;
			letter-spacing: 0.04em;
			color: var(--stan-black);
			i {
				font-size: 18px;
			}
		}
		h4 {
			font-weight: normal;
			font-size: 10px;
			line-height: 130%;
			color: var(--stan-blue-5);
		}
	}

	.form-border-radius {
		background: var(--stan-white);
		border: 1px solid var(--stan-gray-dark-color);
		box-sizing: border-box;
		box-shadow: 0px 1px 10px -2px rgba(62, 98, 134, 0.1);
		border-radius: 5px;
	}

	.opacity-1 {
		opacity: 1;
	}

	.opacity-0 {
		opacity: 0.3;
	}
	@media (max-width: 450px) {
		.opacity-0 {
			display: none;
		}
	}
</style>

<script>
	export default {
		name: 'CreditCardInput',
		props: ['stripe', 'card', 'cardNumberPlaceholder', 'theme', 'stripeElementIdSuffix', 'primaryColor', 'placeholderColor', 'preAuth'],
		mounted() {
			this.createCardField()
			const form = document.getElementById('payment-form')
			form.addEventListener('submit', ev => {
				ev.preventDefault()
			})
		},
		data() {
			return {
				elements: null,
				brand: null,
				showCardMessages: false,
				cardError: [],
			}
		},
		methods: {
			createCardField() {
				const elements = this.stripe.elements()
				const thisObject = this
				let elementStyles = {
					base: {
						color: '#000',
						fontWeight: 400,
						background: 'var(--stan-white)',
						fontFamily: '"Inter", sans-serif',
						fontSize: '14px',
						fontSmoothing: 'antialiased',

						':focus': {
							color: '#131F60',
						},

						'::placeholder': {
							color: '#9BACC8',
						},

						':focus::placeholder': {
							color: '#CFD7DF',
						},
					},
					invalid: {
						color: '#ff0000',
						':focus': {
							color: '#FA755A',
						},
						'::placeholder': {
							color: '#FFCCA5',
						},
					},
				}

				const elementClasses = {
					focus: 'focus',
					empty: 'empty',
					invalid: 'invalid',
				}
				if (this.theme === 'stone') {
					// replace the stripe form classes for Stone theme
					elementStyles = {
						base: {
							color: 'var(--stan-white)',
							fontWeight: 400,
							background: '#161616',
							fontFamily: '"Inter", sans-serif',
							fontSize: '14px',
							fontSmoothing: 'antialiased',
							':focus': {
								color: 'var(--stan-white)',
							},
							'::placeholder': {
								color: '#9E9E9E',
							},
							':focus::placeholder': {
								color: '#7D7D7D',
							},
						},
						invalid: {
							color: '#ff0000',
							':focus': {
								color: '#FA755A',
							},
							'::placeholder': {
								color: '#FFCCA5',
							},
						},
					}
				} else if (this.theme === 'minima' || this.theme === 'material') {
					elementStyles = {
						base: {
							color: this.primaryColor,
							fontWeight: 400,
							background: this.placeholderColor,
							fontFamily: '"Inter", sans-serif',
							fontSize: '14px',
							fontSmoothing: 'antialiased',
							':focus': {
								color: this.primaryColor,
							},
							'::placeholder': {
								color: this.placeholderColor,
							},
							':focus::placeholder': {
								color: this.placeholderColor,
							},
						},
						invalid: {
							color: '#ff0000',
							':focus': {
								color: '#FA755A',
							},
							'::placeholder': {
								color: '#FFCCA5',
							},
						},
					}
				} else if (this.theme === 'nightview') {
					elementStyles = {
						base: {
							color: 'var(--stan-white)',
							fontWeight: 400,
							background: '#161616',
							fontFamily: '"Inter", sans-serif',
							fontSize: '15px',
							fontSmoothing: 'antialiased',
							':focus': {
								color: 'var(--stan-white)',
							},
							'::placeholder': {
								color: 'var(--stan-white)55',
							},
							':focus::placeholder': {
								color: 'var(--stan-white)88',
							},
						},
						invalid: {
							color: '#ff0000',
							':focus': {
								color: '#FA755A',
							},
							'::placeholder': {
								color: '#FFCCA5',
							},
						},
					}
				}

				const cardNumber = elements.create('cardNumber', {
					placeholder: this.cardNumberPlaceholder,
					style: elementStyles,
					classes: elementClasses,
				})
				cardNumber.mount(`#card-element-number${this.stripeElementIdSuffix}`)

				const cardExpiry = elements.create('cardExpiry', {
					style: elementStyles,
					classes: elementClasses,
				})
				cardExpiry.mount(`#card-element-expiry${this.stripeElementIdSuffix}`)

				const cardCvc = elements.create('cardCvc', {
					style: elementStyles,
					classes: elementClasses,
				})
				cardCvc.mount(`#card-element-cvc${this.stripeElementIdSuffix}`)
				const cardElements = [cardNumber, cardExpiry, cardCvc]
				cardElements.forEach((element, idx) => {
					element.on('change', event => {
						if (event.error) {
							if (idx === 0) thisObject.cardError.push({ cardNumber: event.error.message })
							if (idx === 1) thisObject.cardError.push({ cardYear: event.error.message })
							if (idx === 2) thisObject.cardError.push({ cardCvc: event.error.message })
						} else {
							thisObject.cardError.forEach((error, index) => {
								if (idx === 0) thisObject.cardError[index].cardNumber = ''
								if (idx === 1) thisObject.cardError[index].cardYear = ''
								if (idx === 2) thisObject.cardError[index].cardCvc = ''
							})
						}
						if (idx === 0) {
							if (thisObject && thisObject.brand && document.getElementById(thisObject.brand)) {
								document.getElementById(thisObject.brand).className = 'opacity-0'
							}
							if (event.brand !== 'unknown' && event.brand !== undefined) {
								document.getElementById(event.brand).className = 'opacity-1'
								thisObject.brand = event.brand
							}
							thisObject.showCardMessages = event.complete
						}
					})
				})
				this.$emit('update', cardNumber)
			},
		},
	}
</script>
