import axios from 'axios'

function addAnalyticsToFlows(flows, flowAnalytics) {
	flows.forEach(flow => {
		flow.analytics = flowAnalytics[flow.flow_id]?.analytics
		flow.flow_emails.forEach(flowEmail => {
			flowEmail.analytics = flowAnalytics[flow.flow_id]?.emails[flowEmail.flow_email_id]
		})
	})
	return flows
}

function jsonDeepCopy(arr) {
	return JSON.parse(JSON.stringify(arr))
}

/* eslint-disable import/extensions */

export const state = {
	isFlowLoading: false,
	flows: [],
	flowAnalytics: {},
	flowCount: 0,
	reputationAnalytics: {},
}

export const getters = {
	getFlows: state => state.flows.filter(f => f.type === 'event-triggered').sort((a, b) => b.flow_id - a.flow_id) || [],
	getBroadcasts: state => state.flows.filter(f => f.type === 'user-triggered').sort((a, b) => b.flow_id - a.flow_id) || [],
	getFlow: state => id => state.flows.filter(f => f.flow_id === Number(id))[0],
	getFlowCount: state => state.flowCount,
	getReputationAnalytics: state => state.reputationAnalytics,
	isFlowLoading: state => state.isFlowLoading,
}

export const mutations = {
	setFlows(state, flows) {
		if (Object.keys(state.flowAnalytics).length) {
			const newFlows = jsonDeepCopy(flows)
			state.flows = addAnalyticsToFlows(newFlows, state.flowAnalytics)
		} else state.flows = flows
	},
	setReputationAnalytics(state, reputationAnalytics) {
		state.reputationAnalytics = reputationAnalytics
	},
	setFlowAnalytics(state, flowAnalytics) {
		state.flowAnalytics = flowAnalytics
		const flows = jsonDeepCopy(state.flows)
		state.flows = addAnalyticsToFlows(flows, flowAnalytics)
	},
	setFlowLoading(state, isLoading) {
		state.isFlowLoading = isLoading
	},
	setFlowCount(state, count) {
		state.flowCount = count
	},
	deleteFlow(state, flowId) {
		const flows = jsonDeepCopy(state.flows)
		state.flows = flows.filter(flow => flow.flow_id !== flowId)
	},
	updateFlowStatus(state, payload) {
		const flows = jsonDeepCopy(state.flows)
		state.flows = flows.map(flow => {
			if (flow.flow_id === payload.flow_id) {
				flow.status = payload.status
			}
			return flow
		})
	},
	upsertFlowEmail(state, payload) {
		const flowEmailData = payload.flowEmailData
		const flowData = payload.flowData
		const flows = jsonDeepCopy(state.flows)
		const index = flows.findIndex(flow => flow.flow_id === flowEmailData.flow_id)
		if (index < 0 && flowData) {
			// add the new flow to flows first
			flows.push(flowData)
		}
		// update an existing flow
		state.flows = flows.map(flow => {
			if (flow.flow_id === flowEmailData.flow_id) {
				const flowEmailIndex = flow.flow_emails.findIndex(flowEmail => flowEmail.flow_email_id === flowEmailData.flow_email_id)
				if (flowEmailIndex > -1) flow.flow_emails[flowEmailIndex] = flowEmailData
				else flow.flow_emails.push(flowEmailData)
			}
			return flow
		})
	},
	deleteFlowEmail(state, payload) {
		const flowId = payload.flowId
		const flowEmailId = payload.flowEmailId

		const flows = jsonDeepCopy(state.flows)
		state.flows = flows.map(flow => {
			if (flow.flow_id === flowId) {
				const flowEmailIndex = flow.flow_emails.findIndex(flowEmail => flowEmail.flow_email_id === flowEmailId)
				if (flowEmailIndex > -1) {
					flow.flow_emails.splice(flowEmailIndex, 1)
				}
			}
			return flow
		})
	},
}

export const actions = {
	fetchFlows: async ({ commit, dispatch, state }) => {
		commit('setFlowLoading', true)
		await axios
			.get('v1/flows')
			.then(res => {
				const flows = res.data.flows
				if (flows.length > 0 && state.flows.length > 0 && state.flows.length !== flows.length) dispatch('fetchFlowAnalytics')
				commit('setFlows', res.data.flows)
				commit('setReputationAnalytics', res.data.reputationAnalytics)
				commit('setFlowLoading', false)
			})
			.catch(error => {
				// eslint-disable-next-line no-console
				console.error(error)
				commit('setFlows', [])
				commit('setFlowLoading', false)
			})
	},
	deleteFlow: ({ commit }, flowId) => {
		commit('deleteFlow', flowId)
	},
	updateFlowStatus({ commit }, payload) {
		commit('updateFlowStatus', payload)
	},
	fetchFlowAnalytics: ({ commit }) => {
		axios
			.get('v1/flows/analytics')
			.then(res => {
				commit('setFlowAnalytics', res.data.analytics)
			})
			.catch(error => {
				// eslint-disable-next-line no-console
				console.error(error)
			})
	},
	fetchFlowCount: ({ commit }) => {
		axios
			.get('v1/flows/count')
			.then(res => {
				commit('setFlowCount', res.data.count)
			})
			.catch(error => {
				// eslint-disable-next-line no-console
				console.error(error)
			})
	},
	upsertFlowEmail({ commit }, payload) {
		commit('upsertFlowEmail', payload)
	},
	deleteFlowEmail({ commit }, payload) {
		commit('deleteFlowEmail', payload)
	},
}
