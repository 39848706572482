<template>
	<AppModal ref="imageCropModal" @close="close" :title="$t('Crop Image')">
		<div>
			<div class="canvas-wrapper">
				<canvas v-show="!loading" ref="imageCropperCanvas" id="canvas">
					{{ $t('Your browser does not support the HTML5 canvas element.') }}
				</canvas>
				<div class="d-flex h-100" v-if="loading">
					<HollowDotsSpinner :dot-size="18" :dots-num="3" color="var(--stan-primary-primary-color)" />
				</div>
			</div>
			<div class="d-flex mt-3 justify-content-between">
				<AppButton name="reset-image-cropper-modal" outline class="w-100px" @click="reset">{{ $t('Reset') }}</AppButton>
				<AppButton name="crop-image-cropper-modal" class="w-150px" @click="crop">{{ $t('Crop') }}</AppButton>
			</div>
		</div>
	</AppModal>
</template>
<script>
	import Cropper from 'cropperjs'

	export default {
		props: {
			aspectRatio: { type: Number, default: 16 / 9 }, // 0 for unlimited aspect ratio
			fileName: String,
			fileType: { type: String, default: 'image/png' },
		},
		data() {
			return {
				cropper: null,
				loading: false,
			}
		},
		computed: {
			modal() {
				return $(this.$refs.imageCropModal.$el)
			},
		},
		methods: {
			init(file) {
				this.loading = true
				const self = this
				const canvas = this.$refs.imageCropperCanvas
				const context = canvas.getContext('2d')
				context.clearRect(0, 0, canvas.width, canvas.height)
				const reader = new FileReader()
				this.modal.appendTo('body').modal('show')

				// eslint-disable-next-line func-names
				reader.onload = function(evt) {
					const img = new Image()
					// eslint-disable-next-line func-names
					img.onload = function() {
						context.canvas.height = img.height
						context.canvas.width = img.width
						context.drawImage(img, 0, 0)
						if (self.cropper) self.cropper.destroy()
						self.cropper = new Cropper(canvas, {
							aspectRatio: self.aspectRatio || undefined,
							autoCropArea: 1,
							viewMode: 2,
							background: false,
							guides: false,
						})
						setTimeout(() => {
							window.dispatchEvent(new Event('resize'))
							self.cropper.reset()
							self.loading = false
						}, 200)
					}
					img.src = evt.target.result
				}
				reader.readAsDataURL(file)
			},
			crop() {
				// Get a string base 64 data url
				const croppedImageDataURL = this.cropper.getCroppedCanvas().toDataURL(this.fileType)
				this.$emit('imageCropped', croppedImageDataURL, this.dataURLtoFile(croppedImageDataURL, this.fileName))
				this.modal.modal('hide')
			},
			reset() {
				this.cropper.reset()
			},
			close() {
				const canvas = this.$refs.imageCropperCanvas
				const context = canvas.getContext('2d')
				context.clearRect(0, 0, canvas.width, canvas.height)
				if (this.cropper) this.cropper.destroy()
				this.$emit('close')
			},
			dataURLtoFile(dataurl, filename) {
				const arr = dataurl.split(',')
				const mime = arr[0].match(/:(.*?);/)[1]
				const bstr = atob(arr[1])
				let n = bstr.length
				const u8arr = new Uint8Array(n)

				while (n--) {
					u8arr[n] = bstr.charCodeAt(n)
				}

				return new File([u8arr], filename, { type: mime })
			},
		},
	}
</script>

<style lang="scss">
	.image-crop-modal {
		.modal-dialog {
			.modal-content {
				.modal-header {
					padding: 15px;
					.close-modal {
						top: 5px;
						right: 0px;
					}
				}
				.modal-body {
					padding: 15px;
					padding-top: 0;
				}
			}
		}
	}
	.canvas-wrapper {
		width: 100%;
		display: block;
		height: 100vh;
		@supports (height: 100dvh) {
			height: 100dvh;
		}
		max-height: 350px;
	}
</style>
