var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-row bg-white align-items-center pill-container"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center icon-container justify-content-center",
          class: {
            "creator-pro-icon": _vm.isPro,
            "creator-icon": !_vm.isPro
          }
        },
        [_c("span", { staticClass: "para-2" }, [_vm._v(_vm._s(_vm.icon))])]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex flex-column" }, [
        _c("span", { staticClass: "text-dark para-3 text-bold" }, [
          _vm._v(_vm._s(_vm.header))
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "text-light para-4" }, [
          _vm._v(_vm._s(_vm.description))
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }