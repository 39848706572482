var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "col-md common-file-drop-zone-wrapper",
      class: _vm.dropZoneClass
    },
    [
      _vm.title && !_vm.ghost
        ? _c("div", { staticClass: "row" }, [
            _c("label", { staticClass: "control-label" }, [
              _vm._v(_vm._s(_vm.title))
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex flex-column gap-15px" }, [
        !_vm.ghost
          ? _c(
              "div",
              {
                class: [
                  "common-file-drop-zone drop",
                  _vm.isDragging ? "dropZone-over" : "",
                  { grid: !_vm.multiple }
                ],
                attrs: { draggable: "" },
                on: {
                  dragover: function($event) {
                    $event.preventDefault()
                    return _vm.dragOver.apply(null, arguments)
                  },
                  dragleave: function($event) {
                    $event.preventDefault()
                    return _vm.dragLeave.apply(null, arguments)
                  },
                  drop: function($event) {
                    $event.preventDefault()
                    return _vm.drop($event)
                  }
                }
              },
              [
                !_vm.multiple
                  ? _c("div", { staticClass: "common-file-preview relative" }, [
                      _vm.enableDownloadFile && !_vm.videoPreviewLoading
                        ? _c(
                            "div",
                            {
                              staticClass: "file-preview-overlay",
                              on: {
                                click: function($event) {
                                  return _vm.$emit("download-file")
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "overlay-centered-content" },
                                [
                                  _c("InlineSvg", {
                                    attrs: {
                                      src:
                                        "/images/icons/ic-digital-download.svg"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.accept === "video"
                        ? _c(
                            "div",
                            [
                              _vm.videoPreviewLoading
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "spinner-border video-preview-loader",
                                      attrs: { role: "status" }
                                    },
                                    [
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v(_vm._s(_vm.$t("Loading...")))
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.videoPreviewImage !== ""
                                ? _c("ImagePreview", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.videoPreviewLoading,
                                        expression: "!videoPreviewLoading"
                                      }
                                    ],
                                    ref: "video-preview-img",
                                    staticClass: "preview-image",
                                    attrs: {
                                      source: _vm.videoPreviewImage,
                                      width: 93,
                                      height: 93,
                                      quality: 80,
                                      retry: 3,
                                      "static-class":
                                        "video video-file-drop-zone",
                                      alt: "video preview"
                                    },
                                    on: { load: _vm.videoPreviewLoaded }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.videoPreviewImage !== ""
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.videoPreviewLoading,
                                          expression: "!videoPreviewLoading"
                                        }
                                      ],
                                      staticClass: "close-btn",
                                      on: { click: _vm.deleteFile }
                                    },
                                    [
                                      _c("InlineSvg", {
                                        attrs: {
                                          src: "/images/icons/ic-close.svg",
                                          alt: _vm.$t("close button")
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.videoPreviewImage === "" &&
                              !_vm.videoPreviewLoading
                                ? _c("img", {
                                    attrs: {
                                      src:
                                        "/images/funnel_no__video_preview.svg",
                                      alt: ""
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm.accept === "image"
                        ? _c(
                            "div",
                            {
                              attrs: {
                                "data-test-id": "common-file-drop-zone-section"
                              }
                            },
                            [
                              _vm.image !==
                              this.$constants.PUBLIC_RESOURCES
                                .DEFAULT_REVIEW_IMAGE
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "close-btn",
                                      on: { click: _vm.deleteFile }
                                    },
                                    [
                                      _c("InlineSvg", {
                                        attrs: {
                                          src: "/images/icons/ic-close.svg",
                                          alt: _vm.$t("close button")
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.imageSrc === _vm.noFileImageSrc
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.imageSrc,
                                      width: "60",
                                      height: "60",
                                      "static-class": "image",
                                      alt: _vm.$t("image preview")
                                    }
                                  })
                                : _c("ImagePreview", {
                                    staticClass: "preview-image",
                                    attrs: {
                                      source: _vm.imageSrc,
                                      width: 90,
                                      height: 90,
                                      "static-class": "image",
                                      alt: _vm.$t("image preview")
                                    }
                                  })
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              attrs: {
                                "data-test-id": "common-file-drop-zone-section"
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "image",
                                attrs: {
                                  src: "/images/funnel_no__file_preview.svg",
                                  alt: _vm.$t("image preview")
                                }
                              })
                            ]
                          )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "common-file-upload common-file-upload-user" },
                  [
                    _c(
                      "div",
                      { staticClass: "w-100 h-100 d-flex align-items-center" },
                      [
                        _vm.fileStatus === 0
                          ? _c(
                              "div",
                              {
                                staticClass: "w-100 file-drop-zone",
                                staticStyle: { position: "relative" },
                                attrs: { id: "pick-avatar" }
                              },
                              [
                                !_vm.uploading || _vm.multiple
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "placeholder-value text-center",
                                            class: {
                                              "d-md-block d-none": !_vm.multiple
                                            }
                                          },
                                          [
                                            _vm.dropZonePlaceholder
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "placeholder-value-row para-3 text-light"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.dropZonePlaceholder
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "placeholder-value-row para-3 text-light"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            `Drag Your ${_vm.acceptFileTypeName} Here`
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  )
                                                ]),
                                            _vm._v(" "),
                                            _vm.expectedDimension
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "acceptable-dimension para-4"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.expectedDimension
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "AppButton",
                                          {
                                            staticClass: "m-auto w-auto",
                                            class: {
                                              "big-button": !_vm.showButtonImage
                                            },
                                            attrs: {
                                              name:
                                                "common-file-drop-zone-upload-button",
                                              size: "sm",
                                              outline: true
                                            },
                                            on: { click: _vm.onClickUpload }
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t" +
                                                _vm._s(_vm.buttonText) +
                                                "\n\t\t\t\t\t\t\t"
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.isDragging
                  ? _c("div", { staticClass: "blue-circle down-arrow" }, [
                      _c("img", {
                        attrs: {
                          src: "/images/down-arrow.svg",
                          alt: _vm.$t("down arrow")
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.uploading && !_vm.multiple
                  ? _c("LoadingSpinner", { attrs: { mask: true } })
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.ghost && _vm.filesUploading.length && !_vm.hideProgress
          ? _c(
              "div",
              { staticClass: "file-list d-flex flex-column gap-15px" },
              _vm._l(_vm.filesUploading, function(file, index) {
                return _c("UploadedItemRow", {
                  key: file.metadata.id,
                  attrs: {
                    isUploading:
                      (file.progress >= 0 && file.progress < 100) ||
                      (file.progress === 100 && !file.src),
                    uploadProgress: file.progress,
                    fileName: file.name,
                    isErrored: Boolean(file.error),
                    url: file.src
                  },
                  on: { deleteFile: () => _vm.cancelFile(index) }
                })
              }),
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("FileUpload", {
        ref: "file-drop-zone-file-upload",
        attrs: {
          accepts: _vm.inputAccepts,
          "confirm-upload": false,
          multiple: _vm.multiple,
          options: _vm.options,
          "object-type": _vm.objectType
        },
        on: {
          assetUp: _vm.fileUploaded,
          filePicked: _vm.onFilesPicked,
          progress: _vm.progressUploaded,
          errorUp: _vm.errorFileUploaded,
          "file-selection-cancelled": function($event) {
            return _vm.$emit("cancel-unsplash")
          }
        }
      }),
      _vm._v(" "),
      _vm.isImageAccepted
        ? _c("ImageCropperModal", {
            ref: "imageCropperModal",
            attrs: {
              "aspect-ratio": _vm.cropAspectRatio,
              "file-name": _vm.fileName,
              "file-type": _vm.fileMimeType
            },
            on: { imageCropped: _vm.imageCropped, close: _vm.imageCropClose }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isImageAccepted
        ? _c("UnsplashImagePickerModal", {
            ref: "unsplash-image-picker-modal",
            on: {
              unsplashImagePicked: _vm.onUnsplashImagePicked,
              imageDropped: _vm.drop,
              uploadSelected: _vm.onImageUploadOptionPicked,
              "close-modal": function($event) {
                return _vm.$emit("cancel-unsplash")
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }