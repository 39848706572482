<template>
	<div>
		<StanleyTyping :text="text" @doneTyping="doneTyping = true"></StanleyTyping>
		<StanleyExampleQuestions v-if="showExamples && doneTyping" @userPrompt="userPrompt" />
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'

	export default {
		props: {
			prompt: {
				type: String,
				required: false,
			},
			active: {
				type: Boolean,
				required: false,
			},
		},
		mounted() {
			this.mountedTs = new Date().getTime()
		},
		data() {
			return {
				showExamples: true,
				doneTyping: false,
				mountedTs: undefined,
				options: [
					{
						text: 'What can Stanley do for me?',
					},
					{
						text: 'How can I earn more money on Stan?',
					},
					{
						text: 'How can I setup my direct deposit through Stan with Stripe?',
					},
					{
						text: 'Create a detailed 10 step guide to get started as a content creator on TikTok!',
					},
				],
			}
		},
		methods: {
			userPrompt(option) {
				this.showExamples = false
				this.$emit('userPrompt', option)
			},
		},
		computed: {
			...mapGetters('Auth', ['user']),
			...mapGetters('Stanley', ['getAnswers']),
			firstName() {
				return this.user.full_name.split(' ')[0]
			},
			text() {
				return `${this.firstName}, how may I help you today?`
			},
		},
		watch: {
			prompt(newValue) {
				if (this.active) {
					this.userPrompt({ type: 'user', text: newValue })
				}
			},
		},
	}
</script>

<style lang="scss" scoped></style>
