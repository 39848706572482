export default {
	props: {
		range: {
			type: Number,
			default: 14,
		},
		periodLength: {
			type: Number,
			default: 1,
		},
		endDate: {
			type: Date,
			default: () => new Date(),
		},
	},
	data: () => ({
		data: null,
		formattedData: null,
	}),
	watch: {
		periodLength(newType, oldType) {
			if (newType !== oldType) {
				this.fetchData().then(this.formatData)
			}
		},
		range(newRange, oldRange) {
			if (newRange && newRange !== oldRange) {
				this.fetchData().then(this.formatData)
			}
		},
		endDate(newEndDate, oldEndDate) {
			if (newEndDate && newEndDate !== oldEndDate) {
				this.fetchData().then(this.formatData)
			}
		},
	},
	methods: {
		// eslint-disable-next-line no-empty-function
		async fetchData() {},
		// eslint-disable-next-line no-empty-function
		async formatData() {},
	},
}
