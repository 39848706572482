<template>
	<AppModal id="editCreatorPasswordAndReferrerModal" title="Edit Password or Referrer" @close="getFormData()">
		<form id="creator_referrer_form" @submit.prevent="submitModalForm">
			<AppInput
				v-model="form.ref"
				class="form-group align-items-center"
				:label="$t('Referrer')"
				:error="ajax_error && ajax_error.errors && ajax_error.errors.ref"
				:placeholder="$t('Referrer')"
				name="ref"
			/>
			<AppInput
				v-model="form.password"
				class="form-group"
				:label="$t('Password')"
				:error="ajax_error && ajax_error.errors && ajax_error.errors.password"
				:placeholder="$t('Password')"
				type="password"
				name="password"
			/>
			<AppInput
				v-model="form.password_confirmation"
				class="form-group"
				:label="$t('Confirm Password')"
				:error="ajax_error && ajax_error.errors && ajax_error.errors.password_confirmation"
				:placeholder="$t('Confirm Password')"
				type="password"
				name="password_confirmation"
			/>
			<div class="d-flex flex-wrap justify-content-end mt-3">
				<AppButton
					name="close-modal-edit-creator-referrer"
					outline
					class="py-2 md-flat ml-2 mt-1"
					style="width: 100px"
					type="button"
					data-dismiss="modal"
					>{{ $t('Close') }}</AppButton
				>
				<AppButton name="save-modal-edit-creator-referrer" class="py-2 md-flat ml-2 mt-1" style="width: 100px" type="Submit">{{
					$t('Save')
				}}</AppButton>
			</div>
		</form>
	</AppModal>
</template>
<script>
	import helper from '~/global_helper/helpers.js'

	export default {
		props: {
			userId: {
				type: Number,
				required: true,
			},
		},
		data() {
			return {
				editCreatorData: {},
				ajax_warnings: {},
				ajax_error: {
					message: '',
					status: null,
					errors: {},
				},
				form: {},
				loading: false,
				sentOtp: false,
			}
		},
		mounted() {
			this.resetWarningsAndErrors()
			this.resetForm()
			this.getFormData()
		},
		methods: {
			getFormData() {
				this.resetWarningsAndErrors()
				this.resetForm()
				this.fetchUserData()
			},
			resetForm() {
				this.form = {
					id: '',
					ref: '',
					password: '',
					password_confirmation: '',
				}
			},
			resetWarningsAndErrors() {
				this.ajax_error.errors = []
				this.ajax_warnings = {}
			},
			submitModalForm() {
				this.loading = true
				const status = this.validateForm()
				if (status) {
					const payload = {
						id: this.form.id,
						ref: this.form.ref,
						password: this.form.password,
						password_confirmation: this.form.password_confirmation,
					}
					this.$axios
						.put('v1/admin/creator_password_referrer', payload)
						.then(response => {
							if (response && response.data && response.data.status === 'error') {
								Object.assign(this.ajax_error, response.data)
							} else {
								this.editCreatorData = null
								this.getFormData()
								$('#editCreatorPasswordAndReferrerModal').modal('hide')
								this.$stanNotify({
									type: 'success', // can be 'info', 'warning', 'success', 'error'
									title: this.$t('Creator'),
									text: `${response.data.message}`,
									duration: 2000,
								})
							}
						})
						.catch(error => {
							this.$logError(error)
							Object.assign(this.ajax_error, error.response.data)
						})
						.finally(() => {
							this.loading = false
						})
				} else {
					this.loading = false
				}
			},
			onBlurChangeWarning(field) {
				if (this.editCreatorData && this.form && this.editCreatorData[field] !== this.form[field]) {
					const merged = {}
					merged[field] = this.$t('The field has changed.')
					this.ajax_warnings = { ...this.ajax_warnings, ...merged }
				} else {
					const merged = { ...this.ajax_warnings }
					merged[field] = null
					Object.assign(this.ajax_warnings, { ...merged })
				}

				Object.assign(this.ajax_warnings, { ...this.ajax_warnings })
			},
			fetchUserData() {
				this.$axios
					.get(`v1/admin/get-user/${this.userId}`)
					.then(response => {
						this.editCreatorData = response.data
						Object.keys(this.form).forEach(key => {
							this.form[key] = this.editCreatorData[key]
						})
					})
					.catch(error => {
						this.ajax_error = error
						this.$logError(error)
					})
			},
			validateForm() {
				this.resetWarningsAndErrors()
				let formStatus = true
				const errorObject = {
					status: 'error',
					message: this.$t('The given data was invalid.'),
					errors: {},
				}

				if (helper.stringLength(this.form.ref) === 0 && helper.stringLength(this.form.password) === 0) {
					Object.assign(errorObject.errors, {
						ref: [this.$t('Please ensure the referrer username is 5 - 48 characters long')],
						password: [this.$t('Password must be at least 6 characters!')],
					})
					formStatus = false
				}

				if (helper.stringLength(this.form.ref) > 0) {
					if (!helper.validateUsername(this.form.ref)) {
						Object.assign(errorObject.errors, {
							ref: [this.$t('The referrer may only contain letters, numbers, dashes & underscores!')],
						})
						formStatus = false
					}

					if (!helper.validateUsernameLength(this.form.ref)) {
						Object.assign(errorObject.errors, {
							ref: [this.$t('Please ensure the referrer username is 5 - 48 characters long')],
						})
						formStatus = false
					}
				}

				if (helper.stringLength(this.form.password) > 0) {
					if (helper.stringLength(this.form.password) < 6) {
						Object.assign(errorObject.errors, {
							password: [this.$t('Password must be at least 6 characters!')],
						})
						formStatus = false
					} else if (helper.stringLength(this.form.password) > 48) {
						Object.assign(errorObject.errors, {
							password: [this.$t('Password must be less than 48 characters!')],
						})
						formStatus = false
					}

					if (helper.stringLength(this.form.password_confirmation) === 0) {
						Object.assign(errorObject.errors, {
							password_confirmation: [this.$t('Please enter a matching password!')],
						})
						formStatus = false
					}

					if (!helper.compareStrings(this.form.password, this.form.password_confirmation)) {
						Object.assign(errorObject.errors, {
							password_confirmation: [this.$t('Password and confirm password does not match!')],
						})
						formStatus = false
					}
				}

				Object.assign(this.ajax_error, errorObject)
				return formStatus
			},
		},
	}
</script>

<style lang="scss" scoped>
	label {
		color: var(--stan-primary-dark-color);
		font-size: 14px;
	}
</style>
