var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "block block--pill" }, [
    _c(
      "button",
      {
        staticClass: "block__button--pill",
        on: {
          click: function($event) {
            return _vm.$emit("buttonClick")
          }
        }
      },
      [
        _vm.image
          ? _c(
              "span",
              { staticClass: "block__image--pill" },
              [
                _c("image-preview", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading"
                    }
                  ],
                  attrs: {
                    source: _vm.image,
                    width: 433,
                    height: 433,
                    alt: "block image"
                  },
                  on: { isLoading: _vm.handleLoading }
                }),
                _vm._v(" "),
                _vm.isLoading ? _c("LoadingSpinner") : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "block__content" }, [
          _c("div", { staticClass: "block__text--pill" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _vm._v(" "),
          _vm.isPrice
            ? _c(
                "div",
                { staticClass: "price-review" },
                [
                  _c("Price", {
                    attrs: {
                      currency: _vm.user.data.currency,
                      amount: _vm.price.amount,
                      "sale-amount": _vm.price.sale_amount_available
                        ? _vm.price.sale_amount
                        : 0
                    }
                  }),
                  _vm._v(" "),
                  _vm.showDiscount
                    ? _c("PriceOff", {
                        attrs: {
                          amount: _vm.price.amount,
                          "sale-amount": _vm.price.sale_amount_available
                            ? _vm.price.sale_amount
                            : 0
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isReviews
                    ? _c(
                        "div",
                        {
                          staticClass: "rating",
                          style: { "--avg-rating": _vm.avgReviews }
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "13",
                                height: "13",
                                viewBox: "0 0 13 13",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M6.6574 0L8.75579 4.11181L13.3148 4.83688L10.0527 8.10319L10.7719 12.6631L6.6574 10.57L2.5429 12.6631L3.26212 8.10319L0 4.83688L4.559 4.11181L6.6574 0Z",
                                  fill: "#FEC337"
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.avgReviews))])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.quantity != undefined
            ? _c("div", { staticClass: "inventory" }, [
                _c(
                  "span",
                  {
                    class: {
                      "in-stock": _vm.isInStock,
                      "out-of-stock": !_vm.isInStock,
                      "low-stock": _vm.isLowStock
                    }
                  },
                  [_vm._v(_vm._s(_vm.quantity_text))]
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-none block__icon--pill" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "23",
                height: "23",
                viewBox: "0 0 23 23",
                fill: "none"
              }
            },
            [
              _c("path", {
                attrs: {
                  d: "M13.8291 5.68359L19.6462 11.5007L13.8291 17.3178",
                  stroke: "#171616",
                  "stroke-width": "1.2",
                  "stroke-miterlimit": "10",
                  "stroke-linecap": "square"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M3.35449 11.5H18.4832",
                  stroke: "#171616",
                  "stroke-width": "1.2",
                  "stroke-miterlimit": "10",
                  "stroke-linecap": "square",
                  "stroke-linejoin": "round"
                }
              })
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }