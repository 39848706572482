var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "table-wrapper d-none d-md-block" }, [
      _c("div", { staticClass: "table-wrapper-header" }, [
        _c(
          "div",
          { staticClass: "stan-icon-wrapper" },
          [
            _c("InlineSvg", {
              staticClass: "stan-icon mr-2",
              attrs: { src: _vm.icon }
            }),
            _vm._v(" "),
            _c("h3", { staticClass: "text-bold" }, [_vm._v(_vm._s(_vm.title))])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-wrap algin-items-center" },
        [
          _vm.filters.length > 0
            ? _c("AppFilters", {
                class: this.$slots["options"] ? "dropdownEnabled" : "",
                attrs: {
                  filters: _vm.filters,
                  showCsvDownload: _vm.showCsvDownload
                },
                on: {
                  filterChanged: _vm.filterChanged,
                  downloadCsv: function($event) {
                    return _vm.$emit("downloadCsv")
                  },
                  resetFilters: _vm.resetFilters
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("options")
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-container", class: { loading: _vm.isLoading } },
        [
          _c(
            "table",
            {
              staticClass: "para-2",
              staticStyle: { "table-layout": "fixed", width: "100%" }
            },
            [
              _c(
                "tr",
                [
                  _vm._l(_vm.tableColumns, function(col, i) {
                    return _c(
                      "th",
                      {
                        key: `col-${i}`,
                        staticClass: "text-truncate align-self-center",
                        class: { "text-right": col.headerRightAlign },
                        style: {
                          width: col.width
                        }
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" + _vm._s(col.label) + "\n\t\t\t\t\t"
                        )
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.showActionCell
                    ? _c("th", {
                        staticClass: "controls",
                        class: { "has-action-button": _vm.hasActionButton }
                      })
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _vm._l(_vm.items, function(row, j) {
                return _c(
                  "tr",
                  {
                    key: `row-${j}`,
                    class: { pointer: _vm.rowClickable },
                    on: {
                      click: function($event) {
                        if ($event.target !== $event.currentTarget) return null
                        return _vm.moreDetails(row)
                      }
                    }
                  },
                  [
                    _vm._l(_vm.tableColumns, function(col, k) {
                      return _c(
                        "td",
                        {
                          key: `data-${j}-${k}`,
                          on: {
                            click: function($event) {
                              return _vm.moreDetails(row)
                            }
                          }
                        },
                        [
                          !col.html
                            ? _c(
                                "div",
                                {
                                  staticClass: "d-flex",
                                  class: col.classObject
                                },
                                [
                                  col.tags && col.tags.length > 0
                                    ? _c(
                                        "div",
                                        { staticClass: "d-flex" },
                                        _vm._l(col.tags, function(tag, n) {
                                          return _c("AppTag", {
                                            key: `tag-desktop-${n}`,
                                            attrs: {
                                              type: tag.type,
                                              field: tag.field,
                                              value: _vm.getNestedValue(
                                                row,
                                                tag.field
                                              ),
                                              label: _vm.getNestedValue(
                                                row,
                                                tag.label
                                              ),
                                              toolTip: _vm.getNestedValue(
                                                row,
                                                tag.toolTip
                                              ),
                                              backgroundColor:
                                                tag.backgroundColor,
                                              textColor: tag.textColor
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.getNestedValue(row, col.progress) >= 0
                                    ? _c(
                                        "div",
                                        { staticClass: "progress-tracker" },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "progress-tracker-total",
                                            staticStyle: { width: "100%" }
                                          }),
                                          _vm._v(" "),
                                          _c("div", {
                                            staticClass:
                                              "progress-tracker-complete",
                                            style: `width: ${_vm.getNestedValue(
                                              row,
                                              col.progress
                                            )}%`
                                          })
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !(
                                    typeof _vm.getNestedValue(
                                      row,
                                      col.field
                                    ) === "object"
                                  ) && !col.tagOnly
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-truncate align-self-center",
                                          class: col.classObject
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.getNestedValue(
                                                  row,
                                                  col.field
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      )
                                    : typeof _vm.getNestedValue(
                                        row,
                                        col.field
                                      ) === "object" && !col.tagOnly
                                    ? _c("div", [
                                        !row[col.field].toolTip
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-truncate align-self-center",
                                                class:
                                                  row[col.field].classObject
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.getNestedValue(
                                                        row,
                                                        col.field
                                                      ).value
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t"
                                                )
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-truncate align-self-center",
                                                class:
                                                  row[col.field].classObject
                                              },
                                              [
                                                _c(
                                                  "AppTooltip",
                                                  {
                                                    staticClass: "d-inline",
                                                    attrs: {
                                                      hover: "",
                                                      direction: "bottom",
                                                      tip:
                                                        row[col.field].toolTip,
                                                      html: true,
                                                      hideDelay:
                                                        row[col.field]
                                                          .toolTipHideDelay
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.getNestedValue(
                                                            row,
                                                            col.field
                                                          ).value
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                    _vm.getNestedValue(
                                                      row,
                                                      col.field
                                                    ).icon
                                                      ? _c("InlineSvg", {
                                                          attrs: {
                                                            src: _vm.getNestedValue(
                                                              row,
                                                              col.field
                                                            ).icon,
                                                            width: "16",
                                                            height: "16"
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            : _c("div", {
                                class: col.classObject,
                                staticStyle: {
                                  "max-height": "100px",
                                  "overflow-y": "auto"
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.getNestedValue(row, col.field)
                                  )
                                }
                              })
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _vm.showActionCell
                      ? _c(
                          "td",
                          {
                            on: {
                              click: function($event) {
                                if ($event.target !== $event.currentTarget)
                                  return null
                                return _vm.moreDetails(row)
                              }
                            }
                          },
                          [
                            _vm.rowHasActions(row)
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-content-end",
                                    on: {
                                      click: function($event) {
                                        if (
                                          $event.target !== $event.currentTarget
                                        )
                                          return null
                                        return _vm.moreDetails(row)
                                      }
                                    }
                                  },
                                  [
                                    row.actionButton
                                      ? _c(
                                          "AppButton",
                                          {
                                            staticClass: "overflow-hidden mr-2",
                                            attrs: {
                                              name: `action-button-${row.actionButton.label}`,
                                              disabled: !row.actionButton
                                                .enabled,
                                              size: "sm",
                                              outline: row.actionButton.outline,
                                              customColor:
                                                row.actionButton.customColor,
                                              danger: row.actionButton.danger,
                                              icon: row.actionButton.image
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.actionButtonClickHandler(
                                                  $event,
                                                  row
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-content-center"
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      row.actionButton.label
                                                    ) + " "
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.dropdownMenu && row.dropdownMenu.enabled
                                      ? _c("AppDropdownMenu", {
                                          staticClass: "mx-1",
                                          attrs: {
                                            options: row.dropdownMenu.options,
                                            useStaticPosition: true,
                                            "manual-control": true
                                          },
                                          on: {
                                            selected: function($event) {
                                              return _vm.dropdownSelectHandler(
                                                $event,
                                                row
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  ],
                  2
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "table-wrapper-header justify-content-end" },
            [
              _c(
                "div",
                { staticClass: "para-2 table-wrapper-header-actions" },
                [
                  _c(
                    "div",
                    { staticClass: "header-custom-slot" },
                    [_vm._t("header-custom")],
                    2
                  ),
                  _vm._v(" "),
                  _vm.totalPages
                    ? _c(
                        "div",
                        { staticClass: "table-pagination" },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(`Page ${_vm.page} of ${_vm.totalPages}`) +
                              "\n\t\t\t\t\t\t"
                          ),
                          _c("InlineSvg", {
                            class: {
                              disabled: _vm.page === 1 || _vm.isLoading
                            },
                            attrs: {
                              id: "previous-page",
                              src: "images/icons/ic-chevron-up.svg"
                            },
                            on: { click: _vm.previousPage }
                          }),
                          _vm._v(" "),
                          _c("InlineSvg", {
                            class: {
                              disabled:
                                _vm.page === _vm.totalPages || _vm.isLoading
                            },
                            attrs: {
                              id: "next-page",
                              src: "images/icons/ic-chevron-up.svg"
                            },
                            on: { click: _vm.nextPage }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ]
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-block d-md-none" }, [
      _c("div", { staticClass: "table-wrapper" }, [
        _c("div", { staticClass: "table-container" }, [
          _c("div", { staticClass: "mobile-headers border-bottom p-3" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "text-truncate align-self-center px-0",
                  class: _vm.mobileFirstColumnClass
                },
                [
                  _c("div", [
                    _vm._v(_vm._s(_vm.mobileColumns.firstColumn.label))
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "text-truncate align-self-center",
                  class: _vm.mobileSecondColumnClass
                },
                [
                  _c("div", [
                    _vm._v(_vm._s(_vm.mobileColumns.secondColumn.label))
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.showThirdColumnLabel
                ? _c(
                    "div",
                    {
                      staticClass: "text-truncate align-self-center",
                      class: _vm.mobileThirdColumnClass
                    },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.mobileColumns.thirdColumn.label))
                      ])
                    ]
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mobile-items" },
            _vm._l(_vm.items, function(row, j) {
              return _c(
                "div",
                {
                  key: `row-${j}-m`,
                  staticClass: "mobile-item border-bottom p-3",
                  on: {
                    click: function($event) {
                      if ($event.target !== $event.currentTarget) return null
                      return _vm.moreDetails(row)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "row item-toggle",
                      on: {
                        click: function($event) {
                          return _vm.moreDetails(row)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "px-0",
                          class: _vm.mobileFirstColumnClass
                        },
                        [
                          _vm.mobileColumns.firstColumn.tags &&
                          _vm.mobileColumns.firstColumn.tags.length > 0
                            ? _c(
                                "div",
                                { staticClass: "d-inline-block" },
                                _vm._l(
                                  _vm.mobileColumns.firstColumn.tags,
                                  function(tag, n) {
                                    return _c("AppTag", {
                                      key: `tag-mobile-${n}`,
                                      attrs: {
                                        type: tag.type,
                                        field: tag.field,
                                        value: _vm.getNestedValue(
                                          row,
                                          tag.field
                                        ),
                                        label: _vm.getNestedValue(
                                          row,
                                          tag.label
                                        ),
                                        toolTip: _vm.getNestedValue(
                                          row,
                                          tag.toolTip
                                        ),
                                        backgroundColor: tag.backgroundColor,
                                        textColor: tag.textColor
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.mobileColumns.firstColumn.isHTML
                            ? _c("div", {
                                class:
                                  _vm.mobileColumns.firstColumn.classObject,
                                staticStyle: { "line-height": "24px" },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.getNestedValue(
                                      row,
                                      _vm.mobileColumns.firstColumn.field
                                    )
                                  )
                                }
                              })
                            : _c(
                                "div",
                                {
                                  class:
                                    _vm.mobileColumns.firstColumn.classObject,
                                  staticStyle: { "line-height": "24px" }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.getNestedValue(
                                          row,
                                          _vm.mobileColumns.firstColumn.field
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t\t\t"
                                  )
                                ]
                              )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { class: _vm.mobileSecondColumnClass }, [
                        _vm.mobileColumns.secondColumn.isHTML
                          ? _c("div", {
                              staticClass: "text-truncate align-self-center",
                              class: _vm.mobileColumns.secondColumn.classObject,
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.getNestedValue(
                                    row,
                                    _vm.mobileColumns.secondColumn.field
                                  )
                                )
                              }
                            })
                          : _c(
                              "div",
                              {
                                staticClass: "text-truncate align-self-center",
                                class:
                                  _vm.mobileColumns.secondColumn.classObject
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.getNestedValue(
                                        row,
                                        _vm.mobileColumns.secondColumn.field
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t\t\t"
                                )
                              ]
                            ),
                        _vm._v(" "),
                        _vm.mobileColumns.secondColumn.tags &&
                        _vm.mobileColumns.secondColumn.tags.length > 0
                          ? _c("div", { staticClass: "d-inline-flex" }, [
                              _c(
                                "div",
                                { staticClass: "d-inline-flex flex-wrap" },
                                _vm._l(
                                  _vm.mobileColumns.secondColumn.tags,
                                  function(tag, n) {
                                    return _c("AppTag", {
                                      key: `tag-mobile2-${n}`,
                                      class: {
                                        "mt-2": !_vm.mobileColumns.secondColumn
                                          .progress
                                      },
                                      attrs: {
                                        type: tag.type,
                                        field: tag.field,
                                        value: _vm.getNestedValue(
                                          row,
                                          tag.field
                                        ),
                                        label: _vm.getNestedValue(
                                          row,
                                          tag.label
                                        ),
                                        backgroundColor: tag.backgroundColor,
                                        textColor: tag.textColor,
                                        toolTip: _vm.getNestedValue(
                                          row,
                                          tag.toolTip
                                        )
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.moreDetails(row)
                                        }
                                      }
                                    })
                                  }
                                ),
                                1
                              ),
                              _vm._v(" "),
                              _vm.getNestedValue(
                                row,
                                _vm.mobileColumns.secondColumn.progress
                              ) >= 0
                                ? _c(
                                    "div",
                                    { staticClass: "progress-tracker" },
                                    [
                                      _c("div", {
                                        staticClass: "progress-tracker-total",
                                        staticStyle: { width: "100%" }
                                      }),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass:
                                          "progress-tracker-complete",
                                        style: `width: ${_vm.getNestedValue(
                                          row,
                                          _vm.mobileColumns.secondColumn
                                            .progress
                                        )}%`
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "px-0",
                          class: _vm.mobileThirdColumnClass
                        },
                        [
                          !_vm.showDetails(row) && _vm.mobileColumns.thirdColumn
                            ? _c(
                                "div",
                                {
                                  class:
                                    _vm.mobileColumns.thirdColumn.classObject,
                                  staticStyle: { height: "21px" }
                                },
                                [
                                  _c("div", [
                                    !(
                                      typeof _vm.getNestedValue(
                                        row,
                                        _vm.mobileColumns.thirdColumn.field
                                      ) === "object"
                                    )
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-truncate text-right d-flex justify-content-end align-self-center"
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.getNestedValue(
                                                    row,
                                                    _vm.mobileColumns
                                                      .thirdColumn.field
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            )
                                          ]
                                        )
                                      : typeof _vm.getNestedValue(
                                          row,
                                          _vm.mobileColumns.thirdColumn.field
                                        ) === "object"
                                      ? _c("div", [
                                          !row[
                                            _vm.mobileColumns.thirdColumn.field
                                          ].toolTip
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-truncate d-flex justify-content-end align-self-center",
                                                  class:
                                                    row[
                                                      _vm.mobileColumns
                                                        .thirdColumn.field
                                                    ].classObject
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.getNestedValue(
                                                          row,
                                                          _vm.mobileColumns
                                                            .thirdColumn.field
                                                        ).value
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-truncate d-flex justify-content-end align-self-center",
                                                  class:
                                                    row[
                                                      _vm.mobileColumns
                                                        .thirdColumn.field
                                                    ].classObject
                                                },
                                                [
                                                  _c(
                                                    "AppTooltip",
                                                    {
                                                      staticClass: "d-inline",
                                                      attrs: {
                                                        hover: "",
                                                        direction: "bottom",
                                                        tip:
                                                          row[
                                                            _vm.mobileColumns
                                                              .thirdColumn.field
                                                          ].toolTip
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getNestedValue(
                                                            row,
                                                            _vm.mobileColumns
                                                              .thirdColumn.field
                                                          ).value
                                                        ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                        ])
                                      : _vm._e()
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.mobileColumns.showExpandArrow
                            ? _c(
                                "div",
                                { staticClass: "d-flex justify-content-end" },
                                [
                                  _c("img", {
                                    staticClass: "expand-arrow",
                                    class: {
                                      "rotate-90": !_vm.showDetails(row)
                                    },
                                    attrs: {
                                      src: "/images/dropdown-arrow.svg",
                                      width: "16px"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.moreDetails(row)
                                      }
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  row.actionButton
                    ? _c(
                        "AppButton",
                        {
                          staticClass: "mt-3 w-100",
                          attrs: {
                            name: `action-button-${row.actionButton.label}`,
                            disabled: !row.actionButton.enabled,
                            outline: row.actionButton.outline,
                            customColor: row.actionButton.customColor,
                            danger: row.actionButton.danger,
                            icon: row.actionButton.image,
                            size: "md"
                          },
                          on: {
                            click: function($event) {
                              return _vm.actionButtonClickHandler($event, row)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-content-center" },
                            [
                              _c("span", [
                                _vm._v(_vm._s(row.actionButton.label) + " ")
                              ])
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showDetails(row)
                    ? _c(
                        "div",
                        { staticClass: "row row-expanded" },
                        [
                          _vm._l(_vm.mobileExpansionColumns, function(eCol, p) {
                            return _c(
                              "div",
                              {
                                key: `row-${p}-e`,
                                staticClass:
                                  "col-12 text-truncate align-self-center"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between my-2"
                                  },
                                  [
                                    _c("div", { staticClass: "my-auto" }, [
                                      _vm._v(_vm._s(eCol.label))
                                    ]),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "my-auto dotted-line"
                                    }),
                                    _vm._v(" "),
                                    !eCol.html
                                      ? _c("div", { class: eCol.classObject }, [
                                          !(
                                            typeof _vm.getNestedValue(
                                              row,
                                              eCol.field
                                            ) === "object"
                                          )
                                            ? _c(
                                                "div",
                                                { class: eCol.classObject },
                                                [
                                                  eCol.tags &&
                                                  eCol.tags.length > 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass: "d-flex"
                                                        },
                                                        _vm._l(
                                                          eCol.tags,
                                                          function(tag, n) {
                                                            return _c(
                                                              "AppTag",
                                                              {
                                                                key: `tag-mobile3-${n}`,
                                                                attrs: {
                                                                  type:
                                                                    tag.type,
                                                                  field:
                                                                    tag.field,
                                                                  value: _vm.getNestedValue(
                                                                    row,
                                                                    tag.field
                                                                  ),
                                                                  label: _vm.getNestedValue(
                                                                    row,
                                                                    tag.label
                                                                  ),
                                                                  toolTip: _vm.getNestedValue(
                                                                    row,
                                                                    tag.toolTip
                                                                  ),
                                                                  backgroundColor:
                                                                    tag.backgroundColor,
                                                                  textColor:
                                                                    tag.textColor
                                                                }
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !eCol.tagOnly
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.getNestedValue(
                                                                row,
                                                                eCol.field
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              )
                                            : typeof _vm.getNestedValue(
                                                row,
                                                eCol.field
                                              ) === "object"
                                            ? _c("div", [
                                                !row[eCol.field].toolTip
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-truncate align-self-center",
                                                        class:
                                                          row[eCol.field]
                                                            .classObject
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.getNestedValue(
                                                                row,
                                                                eCol.field
                                                              ).value
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        )
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        class:
                                                          row[eCol.field]
                                                            .classObject
                                                      },
                                                      [
                                                        _c(
                                                          "AppTooltip",
                                                          {
                                                            staticClass:
                                                              "d-inline",
                                                            attrs: {
                                                              hover: "",
                                                              direction:
                                                                "bottom",
                                                              tip:
                                                                row[eCol.field]
                                                                  .toolTip
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getNestedValue(
                                                                  row,
                                                                  eCol.field
                                                                ).value
                                                              ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                              ])
                                            : _vm._e()
                                        ])
                                      : _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.getNestedValue(
                                                row,
                                                eCol.field
                                              )
                                            )
                                          }
                                        })
                                  ]
                                )
                              ]
                            )
                          }),
                          _vm._v(" "),
                          row.actionButton
                            ? _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  row.actionButton
                                    ? _c(
                                        "AppButton",
                                        {
                                          staticClass: "w-100 mx-auto",
                                          attrs: {
                                            name: `action-button-${row.actionButton.label}`,
                                            disabled: !row.actionButton.enabled,
                                            outline: row.actionButton.outline,
                                            customColor:
                                              row.actionButton.customColor,
                                            danger: row.actionButton.danger,
                                            icon: row.actionButton.image
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.actionButtonClickHandler(
                                                $event,
                                                row
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "span",
                                              { staticClass: "mr-1" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    row.actionButton.label
                                                  ) + " "
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          row.dropdownMenu && row.dropdownMenu.enabled
                            ? _c(
                                "div",
                                { staticClass: "col-12" },
                                _vm._l(row.dropdownMenu.options, function(
                                  option,
                                  o
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: `option-button-${o}`,
                                      staticClass: "my-2"
                                    },
                                    [
                                      _c(
                                        "AppButton",
                                        {
                                          staticClass: "w-100 mx-auto",
                                          attrs: {
                                            name: `dropdown-button-${option.label}`,
                                            outline: true
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.dropdownSelectHandler(
                                                $event,
                                                row,
                                                option
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "span",
                                              { staticClass: "mr-1" },
                                              [
                                                _vm._v(
                                                  _vm._s(option.label) + " "
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _vm.totalPages
        ? _c(
            "div",
            { staticClass: "table-pagination mt-4 text-center" },
            [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(`Page ${_vm.page} of ${_vm.totalPages}`) +
                  "\n\t\t\t"
              ),
              _c("InlineSvg", {
                class: { disabled: _vm.page === 1 },
                attrs: {
                  id: "previous-page",
                  src: "images/icons/ic-chevron-up.svg"
                },
                on: { click: _vm.previousPage }
              }),
              _vm._v(" "),
              _c("InlineSvg", {
                class: { disabled: _vm.page === _vm.totalPages },
                attrs: {
                  id: "next-page",
                  src: "images/icons/ic-chevron-up.svg"
                },
                on: { click: _vm.nextPage }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }