var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      ref: "link-window",
      attrs: {
        title: !_vm.attributes.href
          ? _vm.$t("Insert Link")
          : _vm.$t("Edit Link"),
        "class-name": "block-modal fade",
        "close-button": false,
        backdrop: false
      }
    },
    [
      _c(
        "div",
        { staticClass: "d-flex-column justify-content-left col-sm" },
        [
          _c("AppInput", {
            staticClass: "form-group",
            attrs: {
              icon: "/images/icons/ic-edit.svg",
              placeholder: _vm.$t("Name")
            },
            on: {
              input: _vm.$v.link.text.$touch,
              blur: _vm.$v.link.text.$touch
            },
            model: {
              value: _vm.link.text,
              callback: function($$v) {
                _vm.$set(
                  _vm.link,
                  "text",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "link.text"
            }
          }),
          _vm._v(" "),
          _vm.$v.link.text.$error && !_vm.$v.link.text.minLength
            ? _c("div", { staticClass: "stan-text-danger mb-3" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.$t("Link name is required")) +
                    "\n\t\t"
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("AppInput", {
            staticClass: "form-group",
            attrs: {
              icon: "/images/icons/ic-link.svg",
              placeholder: "https://..."
            },
            model: {
              value: _vm.link.href,
              callback: function($$v) {
                _vm.$set(
                  _vm.link,
                  "href",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "link.href"
            }
          }),
          _vm._v(" "),
          _vm.$v.link.href.$error
            ? _c("div", { staticClass: "stan-text-danger" }, [
                _vm._v("\n\t\t\t" + _vm._s(_vm.errorText) + "\n\t\t")
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-wrap justify-content-between mt-3 px-3" },
        [
          _c(
            "AppButton",
            {
              staticClass: "py-2 md-flat mt-1",
              attrs: {
                name: "tiptap-link-window-remove-link-button",
                outline: "",
                "data-dismiss": "modal"
              },
              on: { click: _vm.removeLink }
            },
            [_vm._v(_vm._s(_vm.$t("Remove Hyperlink")))]
          ),
          _vm._v(" "),
          _c(
            "AppButton",
            {
              staticClass: "py-2 md-flat ml-2 mt-1 w-100px",
              attrs: { name: "tiptap-link-window-save" },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v(_vm._s(_vm.$t("Save")))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }