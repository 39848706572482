var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "stan-section" },
    [
      _c("div", [
        _c(
          "div",
          {
            staticClass: "stan-heading gap",
            class: {
              "stan-margin-bottom":
                !_vm.subtext || _vm.subtextPlacement !== "bottom"
            }
          },
          [
            _vm.number
              ? _c("div", { staticClass: "para-2 text-bold number" }, [
                  _vm._v(_vm._s(_vm.number))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "para-1 text-bold title" }, [
              _c("div", [
                _c("span", [_vm._v(_vm._s(_vm.title))]),
                _vm._v(" "),
                _vm.subtext && _vm.subtextPlacement === "right"
                  ? _c("span", { staticClass: "para-3 text-light" }, [
                      _vm._v(_vm._s(_vm.subtext))
                    ])
                  : _vm._e()
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _vm.subtext && _vm.subtextPlacement === "bottom"
          ? _c(
              "p",
              {
                staticClass:
                  "para-3 text-light stan-margin-bottom header-offset"
              },
              [_vm._v(_vm._s(_vm.subtext))]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }