<template>
	<AppModal id="createModal" title="Secure Edit Customer">
		<form id="creator_form" @submit.prevent="submitModalForm" :key="stateChangeKey">
			<div class="d-flex flex-wrap align-items-center justify-content-between mt-3">
				<div class="d-flex justify-content-between gap w-100">
					<label class="control-label">One Time Passcode</label>
				</div>
				<div class="d-flex align-items-center">
					<AppInput
						v-model="form.otp"
						class="form-group"
						:error="ajax_error && ajax_error.errors && ajax_error.errors.otp"
						:warning="ajax_warnings.otp"
						:placeholder="$t('XXXXXXX')"
						:uppercase="true"
						style="width: 300px"
						name="otp"
					/>
					<AppButton name="request-otp" class="py-2 md-flat ml-2 mb-3" outline style="width: 100px" type="button" @click="sendOTP()"
						>{{ $t('New Code') }}
					</AppButton>
				</div>
			</div>
			<AppInput
				v-model="form.username"
				class="form-group"
				:label="$t('Username')"
				:error="ajax_error && ajax_error.errors && ajax_error.errors.username"
				:warning="ajax_warnings && ajax_warnings.username"
				:placeholder="$t('Username')"
				name="username"
				@change="onBlurChangeWarning('username')"
			/>
			<AppInput
				v-model="form.full_name"
				class="form-group"
				:label="$t('Full Name')"
				:error="ajax_error && ajax_error.errors && ajax_error.errors.full_name"
				:warning="ajax_warnings && ajax_warnings.full_name"
				:placeholder="$t('Your Name')"
				name="full_name"
				@change="onBlurChangeWarning('full_name')"
			/>
			<AppInput
				v-model="form.email"
				class="form-group"
				:label="$t('Email')"
				:error="ajax_error.errors && ajax_error.errors.email"
				:warning="ajax_warnings && ajax_warnings.email"
				:placeholder="$t('Email')"
				name="email"
				@change="onBlurChangeWarning('email')"
			/>
			<AppInput
				v-model="form.phone_number"
				class="form-group has-country align-items-center"
				:label="$t('Phone Number')"
				:error="ajax_error && ajax_error.errors && ajax_error.errors.phone_number"
				:warning="ajax_warnings && ajax_warnings.phone_number"
				:placeholder="$t('Phone')"
				name="phone_number"
				@change="onBlurChangeWarning('phone_number')"
			/>
			<div class="d-flex flex-wrap justify-content-end mt-3">
				<AppButton
					name="close-modal-edit-creator"
					outline
					class="py-2 md-flat ml-2 mt-1"
					style="width: 100px"
					type="button"
					data-dismiss="modal"
					>{{ $t('Close') }}</AppButton
				>
				<AppButton name="save-modal-edit-creator" class="py-2 md-flat ml-2 mt-1" style="width: 100px" type="Submit">{{
					$t('Save')
				}}</AppButton>
			</div>
		</form>
	</AppModal>
</template>
<script>
	import helper from '~/global_helper/helpers.js'

	export default {
		props: {
			userId: {
				type: Number,
				required: true,
			},
		},
		data() {
			return {
				editCreatorData: {},
				ajax_warnings: {},
				ajax_error: {
					message: '',
					status: null,
					errors: {},
				},
				form: {},
				loading: false,
				stateChangeKey: 0,
			}
		},
		mounted() {
			this.resetWarningsAndErrors()
			this.resetForm()
			this.getFormData()
		},
		methods: {
			getFormData() {
				this.resetWarningsAndErrors()
				this.resetForm()
				this.fetchUserData()
			},
			resetForm() {
				this.form = {
					otp: '',
					id: '',
					full_name: '',
					username: '',
					email: '',
					phone_number: '',
					country_code: '',
				}
			},
			resetWarningsAndErrors() {
				this.ajax_error.errors = []
				this.ajax_warnings = {}
				this.stateChangeKey += 1
			},
			submitModalForm() {
				this.loading = true
				const status = this.validateForm()
				if (status) {
					const payload = {
						otp: this.form.otp?.trim(),
						id: this.form.id,
						full_name: this.form.full_name,
						username: this.form.username,
						email: this.form.email,
						phone_number: this.form.phone_number,
						country_code: this.form.country_code,
					}
					const otpPayload = {
						otp: this.form.otp?.trim(),
						email: this.editCreatorData.email,
					}
					this.$axios
						.post('v1/authentication/validate-otp', otpPayload)
						.then(response => {
							if (response && response.data && response.data.status === 'error') {
								this.$logError(response.data)
								Object.assign(this.ajax_error, response.data)
							} else {
								this.$axios
									.put('v1/admin/creator', payload)
									.then(response => {
										if (response && response.data && response.data.status === 'error') {
											Object.assign(this.ajax_error, response.data)
										} else {
											this.editCreatorData = null
											this.getFormData()
											this.$emit('updated')
											$('#createModal').modal('hide')
											this.$stanNotify({
												type: 'success', // can be 'info', 'warning', 'success', 'error'
												title: this.$t('Creator'),
												text: `${response.data.message}`,
												duration: 2000,
											})
										}
									})
									.catch(error => {
										this.$logError(error)
										Object.assign(this.ajax_error, error.response.data)
									})
									.finally(() => {
										this.loading = false
									})
							}
						})
						.catch(error => {
							this.$logError(error)
							Object.assign(this.ajax_error, error.response.data)
						})
						.finally(() => {
							this.loading = false
						})
				} else {
					this.loading = false
				}
			},
			onBlurChangeWarning(field) {
				if (this.editCreatorData && this.form && this.editCreatorData[field] !== this.form[field]) {
					const merged = {}
					merged[field] = this.$t('The field has changed.')
					this.ajax_warnings = { ...this.ajax_warnings, ...merged }
				} else {
					const merged = { ...this.ajax_warnings }
					merged[field] = null
					Object.assign(this.ajax_warnings, { ...merged })
				}

				Object.assign(this.ajax_warnings, { ...this.ajax_warnings })
				this.stateChangeKey += 1
			},
			onSelect({ dialCode }) {
				this.form.country_code = dialCode
			},
			fetchUserData() {
				this.$axios
					.get(`v1/admin/get-user/${this.userId}`)
					.then(response => {
						this.editCreatorData = response.data
						Object.keys(this.form).forEach(key => {
							this.form[key] = this.editCreatorData[key]
						})
					})
					.catch(error => {
						this.ajax_error = error
						this.$logError(error)
					})
			},
			phoneValidation(e) {
				if (e && e.country !== undefined && e.number !== undefined && e.valid === true) {
					this.form.phone_number = e.number
				} else {
					this.form.phone_number = ''
				}
			},
			sendOTP() {
				// refresh form data
				this.resetWarningsAndErrors()
				this.form.otp = ''
				this.$axios
					.post('v1/authentication/generate-otp', { email: this.editCreatorData.email })
					.then(response => {
						if (response && response.data && response.data.status === 'error') {
							this.$logError(response.data)
							Object.assign(this.ajax_error, response.data)
						} else {
							// Inform the user we have sent the code to both Email and number
							this.ajax_warnings.otp = this.$t(response.data.message)
						}

						this.stateChangeKey += 1
					})
					.catch(error => {
						this.ajax_error = error
						this.$logError(error)
					})
			},
			validateForm() {
				this.resetWarningsAndErrors()
				let formStatus = true
				const errorObject = {
					status: 'error',
					message: this.$t('The given data was invalid.'),
					errors: {},
				}

				if (helper.stringLength(this.form.otp?.trim()) !== 7) {
					Object.assign(errorObject.errors, {
						otp: [this.$t('Invalid code format')],
					})
					formStatus = false
				}

				if (helper.stringLength(this.form.username) === 0) {
					Object.assign(errorObject.errors, {
						username: [this.$t('Please enter a username!')],
					})
					formStatus = false
				}

				if (!helper.validateUsername(this.form.username)) {
					Object.assign(errorObject.errors, {
						username: [this.$t('Your username may only contain letters, numbers, dashes & underscores!')],
					})
					formStatus = false
				}

				if (!helper.validateUsernameLength(this.form.username)) {
					Object.assign(errorObject.errors, {
						username: [this.$t('Please ensure your username is 5 - 48 characters long')],
					})
					formStatus = false
				}

				if (helper.stringLength(this.form.full_name) === 0) {
					Object.assign(errorObject.errors, {
						full_name: [this.$t('Please enter Your Name!')],
					})
					formStatus = false
				}
				if (helper.stringLength(this.form.email) === 0) {
					Object.assign(errorObject.errors, {
						email: [this.$t('Please enter a valid Email!')],
					})
					formStatus = false
				}
				if (helper.stringLength(this.form.email) !== 0 && !helper.validateEmail(this.form.email)) {
					Object.assign(errorObject.errors, {
						email: [this.$t('Please enter a valid Email!')],
					})
					formStatus = false
				}
				if (!helper.validatePhone(this.form.phone_number) || helper.stringLength(this.form.phone_number) === 0) {
					Object.assign(errorObject.errors, {
						phone_number: [this.$t('Please enter a valid Phone Number!')],
					})
					formStatus = false
				}

				Object.assign(this.ajax_error, errorObject)
				return formStatus
			},
		},
	}
</script>

<style lang="scss" scoped>
	label {
		color: var(--stan-primary-dark-color);
		font-size: 14px;
	}
</style>
