<template>
	<div class="d-flex flex-column justify-content-center" :style="this.generalClass">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'AppCircle',
		props: {
			color: { type: String, default: 'white' },
			background: { type: String, default: 'primary' },
			size: { type: Number, default: 25 },
			font_size: { type: Number, default: 14 },
		},
		computed: {
			background_color() {
				switch (this.background) {
					case 'primary':
						return 'var(--primary-color)'
					case 'secondary':
						return 'var(--secondary-color)'
					default:
						return this.background
				}
			},
			generalClass() {
				const generalProps = {
					height: `${this.size}px`,
					width: `${this.size}px`,
					'text-align': 'center',
					'align-items': 'center',
					'border-radius': `${this.size / 2}px`,
					'background-color': this.background_color,
					'font-size': `${this.font_size}px`,
					color: this.color,
				}
				return generalProps
			},
		},
	}
</script>
