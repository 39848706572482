<template>
	<div :class="wrapperClasses" :id="elementId" data-toggle="tooltip" :data-placement="direction" :data-delay="delay">
		<slot v-if="$slots.default"></slot>
		<InlineSvg v-else class="info-icon" src="images/info.svg" />
	</div>
</template>
<script>
	import { UUID } from '~/store/modules/Global.js'

	export default {
		name: 'AppTooltip',
		props: {
			click: Boolean,
			hover: Boolean,
			tip: { type: String, default: '' },
			direction: { type: String, default: 'top', validator: value => ['top', 'left', 'bottom', 'right'].includes(value) },
			html: Boolean,
			hideDelay: { type: Number, default: 0 },
		},
		data() {
			return {
				elementId: UUID(),
			}
		},
		mounted() {
			const options = {}
			const triggers = ['focus']
			if (this.hover) triggers.push('hover')
			if (this.click) triggers.push('click')
			options.html = this.html
			options.trigger = triggers.join(' ')
			options.title = this.getTip
			this.triggerElement.tooltip(options)
			this.triggerElement.on('show.bs.tooltip', () => {
				document.addEventListener('click', this.handleClickOutside)
			})
			this.triggerElement.on('hide.bs.tooltip', () => {
				document.removeEventListener('click', this.handleClickOutside)
			})
			this.triggerElement.on('shown.bs.tooltip', () => {
				const tooltips = $('.tooltip-inner')
				if (tooltips.length > 0) {
					for (let i = 0; i < tooltips.length; i++) {
						tooltips[i].addEventListener('click', this.handleClickInside)
					}
				}
			})
			this.triggerElement.on('hidden.bs.tooltip', () => {
				const tooltips = $('.tooltip-inner')
				if (tooltips.length > 0) {
					for (let i = 0; i < tooltips.length; i++) {
						tooltips[i].removeEventListener('click', this.handleClickInside)
					}
				}
			})
		},
		beforeDestroy() {
			this.triggerElement.tooltip('dispose')
		},
		computed: {
			wrapperClasses() {
				const classes = ['app-tooltip', `${this.sizeClass}`]
				if (this.hover) {
					classes.push('tooltip-hover')
				}
				if (this.click) classes.push('tooltip-click')
				return classes.join(' ')
			},
			triggerElement() {
				return $(`#${this.elementId}`)
			},
			delay() {
				return `{
						"show": "0",
						"hide": "${this.hideDelay.toString()}"
					}`
			},
		},
		methods: {
			handleClickInside(event) {
				event.stopPropagation()
			},
			handleClickOutside() {
				this.triggerElement.tooltip('hide')
			},
			getTip() {
				return this.tip
			},
		},
	}
</script>
<style lang="scss" scoped>
	.app-tooltip {
		position: relative;
		&:hover {
			cursor: pointer;
		}
	}
	.info-icon {
		height: 20px;
		width: 20px;
		path:first-of-type {
			fill: var(--stan-blue-5);
		}
	}
</style>
<style lang="scss">
	.tooltip {
		&.show {
			opacity: 1;
		}
		.tooltip-inner {
			background-color: var(--stan-white);
			color: var(--stan-blue-5);
			box-shadow: 0px 0px 4px rgb(0 29 109 / 15%);
		}
		&.bs-tooltip-top {
			.arrow::before {
				border-top-color: var(--stan-white) !important;
			}
			.arrow::after {
				content: '';
				position: absolute;
				bottom: 5px;
				z-index: -1;
				border: 5px solid var(--stan-white);
				transform-origin: 2px 8px;
				transform: rotate(45deg);
				box-shadow: 2px 2px 4px 0px rgb(0 29 109 / 15%);
			}
		}
		&.bs-tooltip-right {
			.arrow::before {
				border-right-color: var(--stan-white) !important;
			}
			.arrow::after {
				content: '';
				position: absolute;
				left: 100%;
				z-index: -1;
				border: 5px solid var(--stan-white);
				transform-origin: 0 0;
				transform: rotate(45deg);
				box-shadow: 2px 2px 4px 0px rgb(0 29 109 / 15%);
			}
		}
		&.bs-tooltip-left {
			.arrow::before {
				border-left-color: var(--stan-white) !important;
			}
			.arrow::after {
				content: '';
				position: absolute;
				right: -2px;
				z-index: -1;
				border: 5px solid var(--stan-white);
				transform-origin: 0 0;
				transform: rotate(45deg);
				box-shadow: 2px 2px 4px 0px rgb(0 29 109 / 15%);
			}
		}
		&.bs-tooltip-bottom {
			.arrow::before {
				border-bottom-color: var(--stan-white) !important;
			}
			.arrow::after {
				content: '';
				position: absolute;
				bottom: -10px;
				z-index: -1;
				border: 5px solid var(--stan-white);
				transform-origin: 0 0;
				transform: rotate(-45deg);
				box-shadow: 2px 2px 4px 0px rgb(0 29 109 / 15%);
			}
		}
	}
</style>
