// eslint-disable-next-line import/extensions
import store from '~/store'
import CONSTANTS from '~/global_helper/constants.js'

export default (to, from, next) => {
	if (to.name === 'auth' || to.name === 'switch_account' || to.name === 'roadmap_affiliate_login') {
		next()
		return
	}
	if (
		to.name === 'login' ||
		to.name === 'forgot_password' ||
		to.name === 'reset_password' ||
		to.name === 'reset-password' ||
		to.name === 'subscribe' ||
		to.name === 'register'
	) {
		if (store.getters['Auth/check'] && !store.getters['Auth/current_account_key']) {
			next()
			return
		}
	}

	if (store.getters['Auth/check']) {
		if (store.getters['Auth/user']?.subscription_tier === 'affiliate' && !CONSTANTS.AFFILIATE_MENUITEMS.some(menu => menu.route === to.name)) {
			next({ name: 'affiliate', replace: true })
		} else {
			next({ name: 'dashboard', replace: true })
		}
	} else if (
		store.getters['Auth/check'] &&
		store.getters['Auth/user']?.subscription_tier === 'affiliate' &&
		!CONSTANTS.AFFILIATE_MENUITEMS.some(menu => menu.route === to.name)
	) {
		next({ name: 'affiliate', replace: true })
	}

	next()
}
