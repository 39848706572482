var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      attrs: {
        id: "manage-membership-modal",
        action: _vm.membership.buttonText,
        title: _vm.modalTitle,
        loading: _vm.loading
      },
      on: { cta: _vm.updateSubscription }
    },
    [
      _c("div", { staticClass: "d-flex my-2" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("p", { staticClass: "sub-text text-center" }, [
            _vm._v(
              "\n\t\t\t\t" +
                _vm._s(
                  `Are you sure you want to ${_vm.membership.action} ${_vm.fanName}'s subscription to `
                ) +
                "\n\t\t\t\t"
            ),
            _c("span", { staticClass: "bold-text" }, [
              _vm._v(_vm._s(_vm.membership.productTitle))
            ]),
            _vm._v("?\n\t\t\t")
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }