var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    class: { "rounded-circle": _vm.circle },
    style: _vm.style,
    attrs: { src: _vm.src }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }