<template>
	<AppModal
		ref="modal"
		title="Add to Email Flow "
		:subheading="$t(modalSubheading)"
		aria-labelledby="AudienceAddToEmailFlowModal"
		:overflow="true"
		:loading="loading"
		action="Add to Email Flow"
		secondary-action="Never Mind"
		@cta="submitModalForm"
		@secondary-cta="hide"
	>
		<Transition name="fade" mode="out-in">
			<div v-if="loading" key="loading">
				<div class="importing-animation">
					<InlineSvg src="/images/icons/ic-customer.svg" />
					<InlineSvg src="/images/icons/ic-customer.svg" />
					<InlineSvg src="/images/icons/ic-customer.svg" />
				</div>
				<AppProgress :min="0" :max="totalCustomers" :value="processedCount" />
			</div>
			<div v-else key="content">
				<form id="audience_bulk_tagging_form">
					<div class="overflow-visible">
						<div class="overflow-visible border">
							<AppDropdown
								class="email-flow-dropdown"
								:value="selectedFlow"
								:options="flowsList"
								:searchable="false"
								placeholder="Select an email flow"
								image-width="23px"
								:right-align="false"
								display-label="name"
								label="name"
								:AppDropdown="true"
								:small="false"
								:rounded-icons="true"
								@select="selectFlow"
							>
							</AppDropdown>
						</div>
						<p class="para-3 text-danger mb-2" v-show="formErrors && formErrors.emailFlowSelectionErrors">
							{{ formErrors.emailFlowSelectionErrors }}
						</p>
					</div>
				</form>
			</div>
		</Transition>
	</AppModal>
</template>
<script>
	import { mapGetters, mapActions } from 'vuex'

	export default {
		props: {
			tableTotalCustomers: {
				type: Number,
				default: 0,
			},
			filterArray: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				formErrors: {},
				loading: false,
				selectedFlow: null,
			}
		},
		created() {
			this.resetForm()
		},
		computed: {
			...mapGetters('Auth', ['user']),
			...mapGetters('Flows', ['getFlows']),
			modalSubheading() {
				return `Add ${this.tableTotalCustomers} ${this.customerText} to Email Flow`
			},
			customerText() {
				return `customer${this.tableTotalCustomers > 1 ? 's' : ''}`
			},
			flowsList() {
				if (this.getFlows.length) {
					const publishedFlows = this.getFlows.filter(flow => flow?.status === this.$constants.FLOW_STATUS.PUBLISHED)
					return publishedFlows.map(flow => ({ name: flow?.data?.name, flow_id: flow.flow_id, image: '/images/icons/ic-flows.svg' }))
				}
				return []
			},
		},
		methods: {
			...mapActions('Auth', ['fetchUser']),
			show() {
				this.resetForm()
				$(this.$refs.modal.$el).modal('show')
			},
			hide() {
				$(this.$refs.modal.$el).modal('hide')
				this.$emit('close')
				setTimeout(this.resetForm, 250)
			},
			selectFlow(flow) {
				this.selectedFlow = flow
			},
			validateForm() {
				let formStatus = true
				const errorObject = {}

				if (!this.selectedFlow) {
					errorObject.emailFlowSelectionErrors = 'Please select a flow!'
					formStatus = false
				}

				Object.assign(this.formErrors, errorObject)
				return formStatus
			},
			async submitModalForm() {
				this.loading = true

				const status = this.validateForm()

				if (status && this.selectedFlow.flow_id) {
					try {
						const responseFans = await this.$axios.get('v1/users/fans/bulk/user_fan_ids', {
							params: {
								page: '1',
								timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
								filter_array: JSON.stringify(this.filterArray),
							},
						})
						const data = {
							user_fan_ids: responseFans.data.user_fan_ids || [],
						}
						await this.$axios.post(`v1/flows/${this.selectedFlow.flow_id}/recipient-list/add`, data)

						setTimeout(() => {
							this.hide()

							this.$stanNotify({
								type: 'success', // can be 'info', 'warning', 'success', 'error'
								title: 'Success',
								text: `${responseFans.data.user_fan_ids.length} ${this.customerText} added to flow`,
								duration: 10000,
							})
						}, 250)
					} catch (error) {
						console.error(error)
						this.$stanNotify({
							type: 'error', // can be 'info', 'warning', 'success', 'error'
							title: this.$t('Could not add fans to flow, let us help you with that!'),
							text: error.response.data.message || this.$t('Please email friends@stanwith.me'),
							duration: 4000,
						})
						this.hide()
						this.$emit('close')
					}
				} else {
					this.loading = false
				}
			},
			resetForm() {
				this.formErrors = {}
				this.loading = false
				this.selectedFlow = null
			},
		},
	}
</script>

<style lang="scss" scoped>
	.border {
		border-radius: 8px;
	}

	.email-flow-dropdown::v-deep {
		.menu-wrapper .dropdown {
			&.is-open {
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
			}
			padding: 10px;
		}
	}

	.importing-animation {
		padding: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		svg::v-deep {
			path {
				fill: var(--stan-primary-soft-color);
			}
		}
		svg:first-child {
			width: 78px;
			height: 78px;
			transform: translateX(30px) rotate(8.88deg);
			animation: float1 4s ease-in-out infinite;
		}
		svg:nth-child(2) {
			width: 118px;
			height: 118px;
			animation: float2 6s ease-in-out infinite;
		}
		svg:last-child {
			width: 65px;
			height: 65px;
			transform: translateX(-30px) rotate(-10deg);
			animation: float3 5s ease-in-out infinite;
		}
	}
	@keyframes float1 {
		0% {
			transform: translateX(30px) rotate(8.88deg) translatey(-10px);
		}
		50% {
			transform: translateX(30px) rotate(8.88deg) translatey(0px);
		}
		100% {
			transform: translateX(30px) rotate(8.88deg) translatey(-10px);
		}
	}
	@keyframes float2 {
		0% {
			transform: translatey(0px);
		}
		50% {
			transform: translatey(-20px);
		}
		100% {
			transform: translatey(0px);
		}
	}
	@keyframes float3 {
		0% {
			transform: translateX(-30px) rotate(-10deg) translatey(0px);
		}
		50% {
			transform: translateX(-30px) rotate(-10deg) translatey(-10px);
		}
		100% {
			transform: translateX(-30px) rotate(-10deg) translatey(0px);
		}
	}
</style>
