<template>
	<AppModal id="send-opt-in-email-success-modal" action="Okay!" @cta="closeModal" @close="closeModal">
		<div class="pb-1 d-flex w-100 justify-content-center"><InlineSvg src="/images/emojis/party-popper.svg" /></div>
		<div class="m-4 text-bold text-center">{{ sendToText }}</div>
		<div class="m-4 text-light para-2 text-center">
			Your audience will start receiving emails from you as soon as they opt-in.
		</div>
	</AppModal>
</template>
<script>
	export default {
		props: {
			numberSent: {
				type: Number,
				default: 0,
			},
		},
		computed: {
			sendToText() {
				return `Sent to ${this.numberSent} ${this.numberSent > 1 ? 'contacts' : 'contact'}`
			},
		},
		methods: {
			closeModal() {
				$('#send-opt-in-email-success-modal').modal('hide')
			},
		},
	}
</script>

<style lang="scss" scoped></style>
