var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.label
      ? _c("label", { staticClass: "control-label" }, [
          _vm._v(_vm._s(_vm.label))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex h-100 px-1 control-background align-items-center"
      },
      [
        _c("AppDropdown", {
          key: _vm.dropdownKey,
          staticClass: "col-4 p-0 rounded-left",
          attrs: {
            options: _vm.countries,
            searchable: false,
            placeholder: _vm.$t("Country"),
            "image-width": "18px",
            border: false,
            "right-align": false,
            "display-label": "dialCode",
            label: "name",
            small: true,
            "rounded-icons": true,
            dropdownRowComponent: _vm.phonenumberMenuItem
          },
          on: { input: _vm.updatePhoneNumber },
          model: {
            value: _vm.country,
            callback: function($$v) {
              _vm.country = $$v
            },
            expression: "country"
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "col-8 p-0 rounded-right" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.phoneNumber,
                expression: "phoneNumber"
              }
            ],
            staticClass: "form-control border-0 rounded-right",
            attrs: { placeholder: _vm.$t("Phone Number") },
            domProps: { value: _vm.phoneNumber },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) return
                  _vm.phoneNumber = $event.target.value
                },
                _vm.updatePhoneNumber
              ]
            }
          })
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "para-3" }, [
      _vm.hasError
        ? _c("span", { staticClass: "text-bold error-message" }, [
            _c("i"),
            _vm._v(_vm._s(_vm.error[0]))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.warning
        ? _c("span", { staticStyle: { color: "#00cfcc" } }, [
            _vm.hasError ? _c("br") : _vm._e(),
            _c("i"),
            _vm._v(_vm._s(_vm.warning))
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }