<template>
	<div class="dropdown-toggle app-flat-pickr datetime-dropdown new stan-flatpickr-theme" :class="{ loading: loading }">
		<img src="/images/calendar-input-icon.svg" class="input-icon mr-2" />
		<FlatPickr
			class="form-control"
			:placeholder="placeholder"
			v-model="internalValue"
			:disableMobile="true"
			:disabled="disabled"
			:config="config"
			@input="onDateChange"
		/>
	</div>
</template>
<script>
	import FlatPickr from 'vue-flatpickr-component'
	import 'flatpickr/dist/flatpickr.css'

	export default {
		name: 'AppFlatPickr',
		components: {
			FlatPickr,
		},
		props: {
			value: {
				type: String,
				default: '',
			},
			placeholder: {
				type: String,
				default: '',
			},
			loading: {
				type: Boolean,
				default: false,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			enable_dates: {
				type: Array || undefined,
				default: undefined,
			},
			disable_dates: {
				type: Array || undefined,
				default: undefined,
			},
			min_date: {
				type: [String, Date],
				default: '',
			},
		},
		data() {
			return {
				loadingg: false,
				internalValue: this.value,
				calendarInstance: undefined,
			}
		},
		computed: {
			config() {
				const base = { monthSelectorType: 'static', static: true, disableMobile: true, altInput: true, altFormat: 'M j, Y' }
				if (this.enable_dates) {
					base.enable = this.enable_dates
				}
				if (this.disable_dates) {
					base.disable = this.disable_dates
				}
				if (this.min_date) {
					base.minDate = this.min_date
				}
				if (this.value) {
					base.defaultDate = this.value
				}
				base.onReady = [this.setupLoadingIndicator]
				base.onMonthChange = [this.onMonthChange]
				return base
			},
		},
		methods: {
			onMonthChange(selectedDates, dateStr, instance) {
				this.$emit('onMonthChange', { year: instance.currentYear, month: instance.currentMonth })
			},
			onDateChange() {
				this.$emit('input', this.internalValue)
				this.$emit('onDateSelect', this.internalValue)
			},
			setupLoadingIndicator(selectedDates, dateStr, instance) {
				this.calendarInstance = instance

				// Create and insert the loading indicator into the Flatpickr calendar
				const loading = document.createElement('div')
				loading.setAttribute('class', 'loading-wrapper')
				const holloDotSpinner = document.createElement('div')
				holloDotSpinner.setAttribute('class', 'hollow-dots-spinner')
				for (let i = 0; i < 3; i++) {
					const loadingIndicator = document.createElement('div')
					loadingIndicator.setAttribute('class', 'dot')
					const delayModifier = 0.3
					const basicDelay = 1000
					loadingIndicator.style = `animationDelay: ${basicDelay * i * delayModifier}ms`
					holloDotSpinner.appendChild(loadingIndicator)
				}
				loading.appendChild(holloDotSpinner)
				instance.calendarContainer.appendChild(loading)
			},
			reset() {
				this.calendarInstance.clear()
			},
		},
	}
</script>

<style lang="scss">
	// The alt date format styling cannot be touched with scoped CSS
	.app-flat-pickr {
		input {
			padding-left: 32px;
		}
	}
</style>
<style lang="scss" scoped>
	.datetime-dropdown {
		position: relative;
		&::after {
			margin-left: -20px;
			margin-right: 20px;
			pointer-events: none;
		}

		.input-icon {
			z-index: 10;
			top: 50%;
			margin: 0;
			left: 9px;
			transform: translateY(-50%);
			position: absolute;
			pointer-events: none;
			height: 16px;
			width: 16px;
		}
		&::after {
			position: absolute;
			right: 10px;
			pointer-events: none;
			top: 50%;
			transform: translateY(-50%);
			margin: 0;
		}
	}
	.loading.stan-flatpickr-theme::v-deep {
		.loading-wrapper {
			background: #ffffffbd;
			z-index: 3;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;

			.hollow-dots-spinner,
			.hollow-dots-spinner * {
				box-sizing: border-box;
			}
			.hollow-dots-spinner {
				display: flex;
				justify-content: center;
				margin: auto;
				height: 15px;
				width: calc(30px * 3);
				min-width: calc(30px * 3);
			}
			.hollow-dots-spinner .dot {
				width: 15px;
				height: 15px;
				margin: 0 calc(15px / 2);
				border: calc(15px / 5) solid #3a36db;
				border-radius: 50%;
				float: left;
				transform: scale(0);
				animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
			}
			.hollow-dots-spinner .dot:nth-child(1) {
				animation-delay: calc(300ms * 1);
			}
			.hollow-dots-spinner .dot:nth-child(2) {
				animation-delay: calc(300ms * 2);
			}
			.hollow-dots-spinner .dot:nth-child(3) {
				animation-delay: calc(300ms * 3);
			}
			@keyframes hollow-dots-spinner-animation {
				50% {
					transform: scale(1);
					opacity: 1;
				}
				100% {
					opacity: 0;
				}
			}
		}
	}
</style>
