<template>
	<div class="sidebar-container">
		<div class="course-sidebar" :class="{ show: showCoursesList, 'w-full': isMobilePreview }">
			<!-- Course Title Banner -->
			<div v-if="course.data.title" class="title-banner-container">
				<!-- Name -->
				<span class="font-bold">{{ course.data.title }}</span>
			</div>

			<!-- Progress Bar -->
			<div class="progress-bar-container">
				<!-- Overlay both bars -->
				<div style="position: relative">
					<div
						class="user-progress-bar"
						:style="{
							width: '0%',
						}"
					/>
					<div class="progress-bar-background" />
				</div>
				<span class="para-3 font-semibold" style="color: var(--stan-store-custom-highlight-color)">0% complete</span>
			</div>

			<!-- Home + Module List -->
			<div class="d-flex flex-column list-container lesson-container">
				<!-- Home -->
				<div class="home-button" @click="handleChooseHome()">
					<div class="course-home-container">
						<InlineSvg class="burger-menu-icon" src="/images/preview-icons/home.svg" style="fill: white !important;" />
					</div>
					<span class="para-3 font-semibold">Course Home</span>
				</div>

				<!-- Modules -->
				<ModuleLessonsContainer
					v-for="(module, index) in filteredModuleList"
					:moduleNumber="index + 1"
					:key="module.module_id"
					:module="module"
					:activeLesson="activeLesson"
					@chooseLesson="chooseLesson"
				/>
			</div>
		</div>

		<div
			:class="[{ 'course-sidebar-mobile-trigger': !isMobilePreview, 'course-sidebar-mobile-trigger-preview': isMobilePreview }]"
			@click="handleShowCoursesList"
		>
			<InlineSvg class="burger-menu-icon" src="/images/preview-icons/menu-burger.svg" />
			<span class="para-1 text-bold font-ellipsis">
				{{ course.data.title }}
			</span>
		</div>
	</div>
</template>

<script>
	import ModuleLessonsContainer from './ModuleLessonsContainer'

	export default {
		components: {
			ModuleLessonsContainer,
		},
		props: {
			course: {
				type: Object,
				required: true,
				default: undefined,
			},
			modules: {
				type: Array,
				required: true,
				default: undefined,
			},
			activeModule: {
				type: Object,
				default: undefined,
			},
			activeLesson: {
				type: Object,
				default: undefined,
			},
			chooseLesson: {
				type: Function,
				required: true,
			},
			showCoursesList: {
				type: Boolean,
				default: true,
			},
			isMobilePreview: {
				type: Boolean,
				default: false,
			},
		},
		methods: {
			handleShowCoursesList() {
				this.$emit('toggleShowCoursesList')
			},
			handleChooseHome() {
				this.$emit('chooseHome')
			},
		},
		computed: {
			currentActiveModuleTitle() {
				return this.activeLesson?.title
			},
			totalLessonCount() {
				let totalLessonCount = 0
				for (const module of this.modules) {
					totalLessonCount += module.lessons.length
				}

				return totalLessonCount
			},
			filteredModuleList() {
				return this.modules.filter(module => module.status !== 2)
			},
		},
	}
</script>

<style scoped>
	.d-flex {
		display: flex !important;
	}
	.flex-column {
		flex-direction: column;
	}

	.para-1 {
		font-family: 'Inter', sans;
		font-size: 16px;
		line-height: 150%;
	}

	.para-3 {
		font-family: 'Inter', sans;
		font-size: 12px;
		line-height: 115%;
	}

	.font-semibold {
		font-weight: 600;
	}

	.font-bold {
		font-weight: 700;
	}

	.font-ellipsis {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 100%;
	}

	/* Sidebar Container */
	.sidebar-container {
		min-width: 325px;
		position: relative;
		display: flex;
		overflow-x: visible;

		@media (max-width: 768px) {
			position: relative;
			display: flex;
			flex-direction: column;
			min-width: 100%;
		}
	}

	.w-full {
		width: 100% !important;
	}

	/* Sidebar */
	.course-sidebar {
		height: 100%;
		width: 325px;
		overflow-y: auto;
		position: fixed;

		background-color: var(--stan-store-highlight-10-opacity);
		color: var(--stan-store-custom-text-color);
		flex-grow: 1;

		transition: all 0.3s ease-in-out;
		transform: translateY(100%);

		@media (max-width: 768px) {
			min-width: 100%;
			height: 100%;
			padding-bottom: 60px;
			z-index: 9990;
			top: 0;
		}

		&.show {
			transform: translateY(0) !important;
		}

		.lesson-container {
			gap: 20px;
			margin-top: 25px;
			margin-bottom: 4rem;
			padding: 0px 25px 25px 25px;
			overflow: visible;
		}
	}

	.course-sidebar-mobile-trigger-preview {
		box-shadow: 0px -5px 40px rgba(0, 0, 0, 0.4);
		cursor: pointer;
		z-index: 9999;
		text-align: center;
		position: fixed;
		bottom: 0;
		display: flex;
		gap: 12px;
		align-items: center;
		background-color: var(--stan-store-custom-background-color);
		color: var(--stan-store-custom-text-color);
		padding: 18px;
		width: 100%;
	}

	.course-sidebar-mobile-trigger {
		display: none;
		box-shadow: 0px -5px 40px rgba(0, 0, 0, 0.4);
		cursor: pointer;
		z-index: 9999;
		@media (max-width: 768px) {
			text-align: center;
			position: fixed;
			bottom: 0;
			display: flex;
			gap: 12px;
			align-items: center;
			background-color: var(--stan-store-custom-background-color);
			color: var(--stan-store-custom-text-color);
			padding: 18px;
			width: 100%;
		}
	}

	.course-home-container {
		min-height: 24px;
		height: 24px;
		min-width: 24px;
		width: 24px;
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--stan-store-custom-highlight-color);
	}

	.title-banner-container {
		font-family: var(--stan-store-custom-title-font);
		padding: 15px;
		background-color: white;
		box-shadow: var(--stan-box-shadow);
		background-color: var(--stan-store-custom-background-color);
		border-radius: 10px;
		gap: 12px;
		margin: 25px;
	}

	/* Buttons */
	.home-button {
		padding: 15px;
		background-color: white;
		box-shadow: var(--stan-box-shadow);
		background-color: var(--stan-store-custom-background-color);
		transition: all 0.2s ease;

		&:hover {
			background-color: var(--stan-store-highlight-5-opacity) !important;
		}

		border-radius: 10px;
		display: flex;
		height: fit-content;
		align-items: center;
		gap: 12px;
		cursor: pointer;
	}

	.chevron-container {
		display: flex;
		flex-direction: row;
		gap: 50px;
		min-width: fit-content;
	}
	/* Progress Bar */
	.progress-bar-container {
		display: flex;
		flex-direction: column;
		margin-top: 25px;
		margin-left: 25px;
		margin-right: 25px;
		gap: 10px;

		.user-progress-bar {
			position: absolute;
			border-radius: 25px;
			height: 5px;
			background-color: var(--stan-store-custom-highlight-color);
			transition: width 0.3s ease !important;
		}

		.progress-bar-background {
			width: 100%;
			border-radius: 25px;
			height: 5px;
			background-color: var(--stan-store-custom-highlight-color);
			opacity: 0.15;
		}
	}

	/* width */
	::-webkit-scrollbar {
		width: 0px;
		visibility: hidden;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: transparent;
		visibility: hidden;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: white;
		border-radius: 0px;
		visibility: hidden;
	}

	/* width */
	::-webkit-scrollbar {
		width: 0px;
		visibility: hidden;
	}
</style>
