<template>
	<div>
		<div ref="anchor"></div>
		<div v-if="requirePopupCloseForAction && renderPopup" class="popup-cover-dom"></div>
		<div v-if="renderPopup" class="popup-menu" :aria-labelledby="ariaLabelledby" ref="popup-content" id="popup-content">
			<slot />
		</div>
	</div>
</template>

<script>
	import Popper from 'popper.js'
	import { debounce as _debounce } from 'lodash'

	export default {
		name: 'AppPopup',
		props: {
			ariaLabelledby: {
				type: String,
				default: 'popup',
			},
			locationRef: {
				type: HTMLDivElement,
				default: null,
			},
			triggerRef: {
				type: String,
				default: '',
			},
			requirePopupCloseForAction: {
				type: Boolean,
				default: false,
				description:
					'By default clicking out of the popup will perform the action tha is clicked, if this is true then the first click outside will close the popup, then the next click will perform the action',
			},
		},
		data() {
			return {
				popperInstance: null,
				dropDownVisible: false,
				renderPopup: false,
			}
		},
		methods: {
			// eslint-disable-next-line func-names
			close: _debounce(function() {
				this.checkHidePopup()
			}, 100),
			// eslint-disable-next-line func-names
			show: _debounce(function() {
				if (!this.isShown()) {
					this.togglePopup()
				}
			}, 100),
			// eslint-disable-next-line func-names
			togglePopup: _debounce(async function() {
				if (!this.checkHidePopup() && !this.popperInstance) {
					// Render popup so we can show it
					this.renderPopup = true
					await this.$nextTick()
					this.$emit('show')
					const anchorRef = this.locationRef ? this.locationRef : this.$refs?.anchor
					this.popperInstance = new Popper(anchorRef, this.$refs['popup-content'], {
						placement: 'top',
						positionFixed: true,
						modifiers: {
							offset: {
								enabled: true,
								offset: '80px, 10px',
							},
							flip: {
								enabled: false,
							},
							hide: {
								enabled: true,
								fn: data => {
									if (data.offsets.reference.bottom > window.innerHeight) {
										this.checkHidePopup()
									}
									return data
								},
							},
							preventOverflow: {
								enabled: true,
								boundariesElement: 'viewport',
							},
						},
					})
					this.$refs['popup-content'].classList.add('show')
					this.dropDownVisible = true

					document.addEventListener('click', this.closePopupOnClickOutside)
					document.addEventListener('keydown', this.closePopupOnEscPressed)
				}
			}, 100),
			// eslint-disable-next-line func-names
			closePopupOnClickOutside(event) {
				// Do not close when the specfied trigger in the parent is clicked
				let triggerRef = this.$parent.$refs[this.triggerRef]
				if (triggerRef) {
					if (!Array.isArray(triggerRef)) {
						triggerRef = [triggerRef]
					}

					for (const ref of triggerRef) {
						if (ref && ref?.contains(event.target)) {
							return
						}
					}
				}
				if (!this.$refs['popup-content']?.contains(event.target)) {
					this.checkHidePopup()
				}
			},
			closePopupOnEscPressed(event) {
				if (event.key === 'Escape') {
					this.checkHidePopup()
				}
			},
			checkHidePopup() {
				if (this.isShown()) {
					this.$refs['popup-content'].classList.remove('show')
					if (this.popperInstance) {
						this.popperInstance.destroy()
						this.popperInstance = null
					}
					this.checkHidePopup.dropDownVisible = false

					document.removeEventListener('click', this.closePopupOnClickOutside)
					document.removeEventListener('keydown', this.closePopupOnEscPressed)

					this.renderPopup = false
					return true
				}
				return false
			},
			isShown() {
				return this.$refs['popup-content']?.classList.value.indexOf('show') > -1
			},
		},
	}
</script>

<style lang="scss" scoped>
	.popup-cover-dom {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 99;
		cursor: default;
	}
	.popup-menu {
		background-color: transparent !important;
		border: none !important;
		flex: 1 0 0%;
		z-index: 100;
	}
</style>
